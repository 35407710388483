import { axiosAPI } from '../axiosAPI'
import { IProntuarioSexualidadeForm, IProntuarioSexualidadeListagem } from './types'

export const prontuarioSexualidadeCreateAPI = async (
  idProntuario: number,
  values: IProntuarioSexualidadeForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariosexualidade',
      {
        id_prontuario: idProntuario,
        prejudicada: values.prejudicada,
        motivo: values.motivo,
        nao_aplica: values.naoAplica,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioSexualidadeForm, IProntuarioSexualidadeListagem }
