import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IEmpresaListagem } from '../../../../api/cadastro/empresasAPI';
import { empresaListarAPI } from '../../../../api/cadastro/empresasAPI';

interface TEmpresasContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  empresas: IEmpresaListagem[],
  setEmpresas: (newEmpresas: IEmpresaListagem[]) => void,
  selecionado: IEmpresaListagem | null,
  setSelecionado: (novoSelecionado: IEmpresaListagem | null) => void,
  loadEmpresas: () => void
}

interface TEmpresasProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  empresas: [],
  setEmpresas: () => {
    throw new Error('setEmpresas não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  loadEmpresas: () => {
    throw new Error('loadEmpresas não implementada');
  },
};

export const EmpresasContext = createContext<TEmpresasContext>(defaultState);

export const EmpresasProvider = ({children}: TEmpresasProviderProps) => {
  const [empresas, setEmpresas] = useState<IEmpresaListagem[]>(defaultState.empresas);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IEmpresaListagem | null>(defaultState.selecionado)

  const loadEmpresas = () => {
    setCarregando(true)
    empresaListarAPI()
        .then((data) => setEmpresas(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadEmpresas();
  }, []);

  const providerValues: TEmpresasContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    empresas,
    setEmpresas,
    loadEmpresas,
  };

  return (
    <EmpresasContext.Provider value={providerValues}>
      {children}
    </EmpresasContext.Provider>
  );
};
