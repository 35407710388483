import { axiosAPI } from '../axiosAPI'
import { IProntuarioRegistroComplementarCVCForm, IProntuarioRegistroComplementarForm, IProntuarioRegistroComplementarITUForm, IProntuarioRegistroComplementarListagem, IProntuarioRegistroComplementarPAVForm } from './types'

export const prontuarioRegistroComplementarCreateAPI = async (
  idProntuario: number,
  values: IProntuarioRegistroComplementarForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioregistrocomplementar',
      {
        id_prontuario: idProntuario,
        eventos_adversos: values.eventosAdversos,
        ocorrencias: values.ocorrencias,
      },
  )
}

export const prontuarioRegistroComplementarCVCCreateAPI = async (
  idProntuario: number,
  values: IProntuarioRegistroComplementarCVCForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariorgcomplementarcvc',
      {
        id_prontuario: idProntuario,
        conectores_precisam_trocar: values.conectoresPrecisamTrocar,
        curativo_precisam_trocar: values.curativoPrecisamTrocar,
        equipamentos_com_data: values.equipamentosComData,
        fluxo_uninario: values.fluxoUninario,
        curativo_sujo: values.curativoSujo,
        higenienizacao_maos: values.higenienizacaoMaos,
        uso_gorro_mascara: values.usoGorroMascara,
        sf0_09: values.sf0_09,
        clorexina: values.clorexina,
        gaze: values.gaze,
        filme_transparente: values.filmeTransparente,
        mal_aderido: values.malAderido,
        presenca_sangue: values.presencaSangue,
        presenca_exsudato: values.presencaExsudato,
        troca_rotina: values.trocaRotina,
        opcao_filme: values.opcaoFilme,
        sem_sinais_flosgisticos: values.semSinaisFlosgisticos,
        presenca_sinais_flosgisticos: values.presencaSinaisFlosgisticos,
      },
  )
}

export const prontuarioRegistroComplementarITUCreateAPI = async (
  idProntuario: number,
  values: IProntuarioRegistroComplementarITUForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariorgcomplementaritu',
      {
        id_prontuario: idProntuario,
        svn: values.svn,
        svn_especificar: values.svnEspecificar,
        sistema_drenagem: values.sistemaDrenagem,
        bolsa_coletora: values.bolsaColetora,
        fluxo_uninario: values.fluxoUninario,
        cateter_fixado: values.cateterFixado,
        realizada_higiene: values.realizadaHigiene,
        limite_capacidade_bolsa: values.limiteCapacidadeBolsa,
        ureta_integra: values.uretaIntegra,
        ureta_integra_especificar: values.uretaIntegraEspecificar,
        urina_aspecto_normal: values.urinaAspectoNormal,
        urina_aspecto_especificar: values.urinaAspectoEspecificar,
      },
  )
}

export const prontuarioRegistroComplementarPAVCreateAPI = async (
  idProntuario: number,
  values: IProntuarioRegistroComplementarPAVForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioregistrocomplementarpav',
      {
        id_prontuario: idProntuario,
        higiene_oral_realizada: values.higieneOralRealizada,
        cabeceira_leito_30_45: values.cabeceiraLeito_30_45,
        ventilorio_desprezados: values.ventilorioDesprezados,
        higiene_maos: values.higieneMaos,
        tot_fixado: values.totFixado,
        doenca_pulmonar: values.doencaPulmonar,
        rx_torax: values.rxTorax,
        filtrado_opacificacao: values.filtradoOpacificacao,
        teve_febre_maior_38: values.teveFebreMaior_38,
        leocopenia_menor_4000: values.leocopeniaMenor_4000,
        leococitose_maior_12000: values.leococitoseMaior_12000,
      },
  )
}

export type { IProntuarioRegistroComplementarForm, IProntuarioRegistroComplementarListagem }
