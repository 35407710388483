import React, { useState } from 'react'
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { Modal } from 'react-bootstrap';

import { PlanoCuidadoAtividadesForm } from './PlanoCuidadoAtividadesForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../../../api/prontuario/types';
import { PlanoCuidadoAtividadesTabela } from './PlanoCuidadoAtividadesTabela';
import Button from '../../../../../../../components/Button';

interface IPlanoCuidadoAtividadesProps {
  idPlanoCuidado: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
}

const PlanoCuidadoAtividades = (props: IPlanoCuidadoAtividadesProps) => {
  const [modalAtividadesAberto, setModalAtividadesAberto] = useState<boolean>(false)

  return (
    <>
      <MdFormatListBulletedAdd size={20} style={{ cursor: 'pointer' }} onClick={() => setModalAtividadesAberto(true)}/>

      <Modal
        show={modalAtividadesAberto}
        size='xl'
        onHide={() => setModalAtividadesAberto(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439'}}>
            Atividades do plano de cuidado
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#304439', maxHeight: '420px', overflowY: 'auto' }}>
          <PlanoCuidadoAtividadesForm
            idPlanoCuidado={props.idPlanoCuidado}
            grupo={props.grupo}
            idDiagnostico={props.idDiagnostico}
          />

          <PlanoCuidadoAtividadesTabela
            idPlanoCuidado={props.idPlanoCuidado}
            grupo={props.grupo}
            idDiagnostico={props.idDiagnostico}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalAtividadesAberto(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { PlanoCuidadoAtividades }
export default PlanoCuidadoAtividades
