import React, { ReactElement, ReactFragment } from 'react'
import styled, { css } from 'styled-components'

/** Interface extendida para adicionar descrição das props */
interface ButtonProps {
  /** Tipos de cores */
  variant?: 'primary' | 'secondary' | 'light' | 'success' | 'danger' | 'new',

  type?: "button" | "submit" | "reset",

  href?: string,

  className?: string,

  /** Define se o botão está desabilitado */
  disabled?: boolean,

  /** Define se o botão possui um estado de ativo */
  active?: boolean,

  /** Oculpa todo o espaço horizontal disponível */
  block?: boolean,

  /** Cria um botão circular */
  round?: boolean,

  /** Estilo transparente e sem borda */
  flat?: boolean,

  /** Estilo com padding menor e mesmo tamanho de fonte */
  dense?: boolean,

  /** Não define o texto capitalizado */
  noUppercase?: boolean,

  children: string | ReactElement | ReactFragment,

  // Ref do componente de origem
  ref?: any,

  onClick?: (e?: any) => void,

  /** Tipo de redirecionamento de rota */
  target?: '_blank' | '_parent' | '_self' | '_top',

  /** alterar styles direto no componente */
  style?: any
}

type ButtonPropsStyled = ButtonProps

export const ButtonStyle = css < ButtonPropsStyled >`
  all: unset;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  display: inline-block;
  padding: 12px 24px;
  cursor: pointer;
  position: relative;
  border-radius: 9px;

  > svg {
    display: inline-block;
  }

  ${(props: ButtonPropsStyled) => props.round && css`
    border-radius: 50%;
    min-height: 2.625em;
    min-width: 2.625em;
    padding: 0;
  `}

  ${(props: ButtonPropsStyled) => props.block && css`
    display: flex;
    width: 100%;
  `}

  /* Colors */
  ${(props: ButtonPropsStyled) => props.variant === 'primary' && css`
    background-color: ${props.disabled ? '#999999' : '#C19B13'};
    color: #FFFFFF;

    ${(props: ButtonPropsStyled) => props.active && css`
      background-color: '#C19B13';
    `}

    &:hover {
      ${!props.disabled && css`
        background-color: '#C19B13' ;
      `}
    }
  `}

  ${(props: ButtonPropsStyled) => props.variant === 'danger' && css`
    background-color: ${props.disabled ? '#999999' : '#CF3636'};
    color: #FFFFFF;

    ${(props: ButtonPropsStyled) => props.active && css`
      background-color: '#CF3636';
    `}

    &:hover {
      ${!props.disabled && css`
        background-color: '#CF3636';
      `}
    }
  `}

  ${(props: ButtonPropsStyled) => props.variant === 'secondary' && css`
    background-color: ${props.disabled ? '#999999' : '#969696'};
    color: #FFFFFF;

    ${(props: ButtonPropsStyled) => props.active && css`
      background-color: '#CF3636';
    `}

    &:hover {
      ${!props.disabled && css`
        background-color: '#CF3636';
      `}
    }
  `}

  ${(props: ButtonPropsStyled) => props.variant === 'new' && css`
    background-color: ${props.disabled ? '#999999' : '#304439'};
    color: #FFFFFF;

    ${(props: ButtonPropsStyled) => props.active && css`
      background-color: '#304439';
    `}

    &:hover {
      ${!props.disabled && css`
        background-color: '#304439';
      `}
    }
  `}

  ${(props: ButtonPropsStyled) => props.style && css`
    ${(props: ButtonPropsStyled) => props.style}
  `}
`
const StyledButton = styled.button < ButtonPropsStyled >`${ButtonStyle}`

/**
 * The only true button.
 *
 * @param {ButtonProps} props Props do botão
 * @returns {StyledButton} Retorna um botão do react-bootstrap
 */
const Button = React.forwardRef(({ type, href, ...props }: ButtonProps, ref) => {
  const _type = type

  return (
    <StyledButton
      ref={ref}
      type={_type}
      {...props}
    />
  )
})

Button.displayName = 'Button'
Button.defaultProps = {
  variant: 'primary',
  type: 'button',
}

export default Button
