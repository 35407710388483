import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { ITipoInfusaoListagem, tipoInfusaoListarAPI } from '../../../../api/cadastro/tiposInfusoesAPI';

interface TTiposInfusoesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  tiposInfusoes: ITipoInfusaoListagem[],
  setTiposInfusoes: (newTiposInfusoes: ITipoInfusaoListagem[]) => void,
  selecionado: ITipoInfusaoListagem | null,
  setSelecionado: (novoSelecionado: ITipoInfusaoListagem | null) => void,
  loadTiposInfusoes: () => void
}

interface TTiposInfusoesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  tiposInfusoes: [],
  setTiposInfusoes: () => {
    throw new Error('setTiposInfusoes não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  loadTiposInfusoes: () => {
    throw new Error('loadTiposInfusoes não implementada');
  },
};

export const TiposInfusoesContext = createContext<TTiposInfusoesContext>(defaultState);

export const TiposInfusoesProvider = ({children}: TTiposInfusoesProviderProps) => {
  const [tiposInfusoes, setTiposInfusoes] = useState<ITipoInfusaoListagem[]>(defaultState.tiposInfusoes);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<ITipoInfusaoListagem | null>(defaultState.selecionado)

  const loadTiposInfusoes = () => {
    setCarregando(true)
    tipoInfusaoListarAPI()
        .then((data) => setTiposInfusoes(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadTiposInfusoes();
  }, []);

  const providerValues: TTiposInfusoesContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    tiposInfusoes,
    setTiposInfusoes,
    loadTiposInfusoes,
  };

  return (
    <TiposInfusoesContext.Provider value={providerValues}>
      {children}
    </TiposInfusoesContext.Provider>
  );
};
