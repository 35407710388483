import { ChangeEventHandler, FocusEventHandler } from 'react'
import { InputStyled } from '../styles'

interface IInputProps {
  id: string,
  label?: string,
  value?: string | number,
  placeholder?: string,
  style?: object,
  onChange: ChangeEventHandler<HTMLInputElement>,
  onBlur?: FocusEventHandler<HTMLInputElement>,
}

const Input = (props: IInputProps) => {
  return (
    <div style={{ marginBottom: '8px' }}>
      {props.label && (
        <label className="form-input-label form-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <InputStyled
        className="form-input form-control"
        style={{ ...props.style, marginRight: '6px' }}
        type="text"
        id={props.id}
        name={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  )
}

export { Input }
export default Input
