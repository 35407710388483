import { useEffect, useState } from "react"
import { Alert, Table } from "react-bootstrap"
import styled from "styled-components"
import { HiOutlineMagnifyingGlass } from "react-icons/hi2"
import { FiSend } from "react-icons/fi"
import { RiMailLine, RiMailOpenLine } from "react-icons/ri"

import { getUserLocalStorage } from "../../../../context/auth_provider/util"
import { IMensagens, getMensagens } from "../../../../api/mensagem"
import Checkbox from "../../../../components/checkbox/Checkbox"
import MensagemVizualizar from "./MensagemVizualizar"
import MensagemForm from "./MensagemForm"


const DivStyled = styled.div`
  font-size: 22px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
`

const MensagensListagem = () => {
  const [dadosMensagens, setDadosMensagens] = useState<IMensagens>()
  const [recebidas, setRecebidas] = useState<boolean>(true)
  const [enviadas, setEnviadas] = useState<boolean>(true)
  const [consultar, setConsultar] = useState<number>(0)
  const user = getUserLocalStorage()

  useEffect(() => {
    getMensagens(enviadas, recebidas).then(data => setDadosMensagens(data)).catch()
  }, [consultar])

  return (
    <> 
      <MensagemForm
        atualizarMensagem={() => setConsultar(prev => prev + 1)}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '30%', justifyContent: 'space-between' }}>
          <Checkbox
            id="recebidas"
            label="Recebidas"
            style={{ marginRight: '6px', color: "black" }}
            checked={recebidas}
            onChange={() => setRecebidas(prev => !prev)}
          />

          <Checkbox
            id="enviadas"
            label="Enviadas"
            style={{ marginRight: '6px'}}
            checked={enviadas}
            onChange={() => setEnviadas(prev => !prev)}
          />

          <HiOutlineMagnifyingGlass
            size={30}
            style={{ cursor: 'pointer', marginTop: '18px', color: "black" }}
            onClick={() => setConsultar(prev => prev + 1)}
          />
        </div>

        <DivStyled style={{ marginRight: '18px', color: "black"  }}>Não lidas: {dadosMensagens?.totalMensagensNaoLidas || 0}</DivStyled>
      </div>

      {dadosMensagens && dadosMensagens.mensagens.length > 0 && (
        <Table style={{ background: '#fff', borderRadius: '8px' }}>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Assunto</th>
              <th />
              <th />
            </tr>
          </thead>

          <tbody>
            {dadosMensagens && dadosMensagens.mensagens.map(msg => (
              <tr key={msg.id} style={{ fontWeight: (msg.lida || msg.destinatario === -1) ? '400' : '700'}}>
                <td>
                  {msg.destinatario === user?.id && msg.lida && (<RiMailOpenLine color="green" />)}
                  {msg.destinatario === user?.id && !msg.lida && (<RiMailLine />)}
                  {msg.destinatario !== user?.id && (<FiSend />)}
                </td>
                <td>{msg.assunto}</td>

                <td>
                  <MensagemVizualizar
                    mensagem={msg}
                    atualizarMensagem={() => setConsultar(prev => prev + 1)}
                  />
                </td>
                {msg.remetente === -1 ? (
                  <td>
                    <MensagemForm
                      mensagem={msg}
                      responder
                      atualizarMensagem={() => setConsultar(prev => prev + 1)}
                    />
                  </td>
                ) : (<td />)}
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {dadosMensagens && dadosMensagens.mensagens.length === 0 && (
        <Alert variant="warning">Não existem mensagens para os filtros selecionados!</Alert>
      )}
    </>
  )
}

export { MensagensListagem }
export default MensagensListagem
