import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { TiposPeleProvider } from './context';
import { TiposPeleContainerForm } from './TiposPeleContainerForm';

export interface ITiposPeleForm {
  nome: string,
  ativo: boolean,
}

const TiposPele = () => {
  return (
    <TiposPeleProvider>
      <DivMainStyled>
        <DivCardStyled>
          <TiposPeleContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </TiposPeleProvider>
  )
}

export { TiposPele }
export default TiposPele
