import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { useFormikContext } from 'formik'

import {
  IProntuarioPlanoCuidadoResultadoForm,
} from '../../../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import { SelectAsync } from '../../../../../../../components/select-async'
import { getAllToSelectDiagnosticoAPI } from '../../../../../../../api/cadastro/diagnosticosAPI'
import { getAllToSelectResultadoAPI } from '../../../../../../../api/cadastro/resultadosAPI'
import { getAllToSelectIndicadorAPI } from '../../../../../../../api/cadastro/indicadoresAPI'
import { InputStyled } from '../../../../../../../components/styles'
import { SelectGrupo } from '../../../../../../../components'
import { ProntuarioContext } from '../../../../context'
import { getApenasAlterados } from '../../../helper/getGruposAlterados'
import Button from '../../../../../../../components/Button'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../../../api/prontuario/types'

interface IPlanoCuidadoResultadosFormCamposProps {
  editar?: boolean,
}

const PlanoCuidadoResultadosFormCampos = (props: IPlanoCuidadoResultadosFormCamposProps) => {
  const [keyIndicador, setKeyIndicador] = useState<number>(0)
  const [keyEsperado, setKeyEsperado] = useState<number>(0)
  const [keyGrupoAlterou, setKeyGrupoAlterou] = useState<number>(0)
  const { values, handleChange, setFieldValue, submitForm } = useFormikContext<IProntuarioPlanoCuidadoResultadoForm>()
  
  const [mensagem, setMensagem] = useState("")
  const { prontuario } = useContext(ProntuarioContext)

  useEffect(() => {
    setKeyIndicador(prev => prev + 1)
  }, [values.idResultado])

  useEffect(() => {
    setKeyGrupoAlterou(prev => prev + 1)
  }, [values.grupo])

  return (
    <>
      <Row>
        <Col md={12}>
          <SelectGrupo
            value={values.grupo}
            gruposAlterados={getApenasAlterados(prontuario)}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <SelectAsync
            key={keyGrupoAlterou}
            id='idDiagnostico'
            label='Diagnóstico de enfermagem'
            emptyLabel='Selecione...'
            value={values.idDiagnostico || -1}
            onChange={handleChange}
            api={() => getAllToSelectDiagnosticoAPI(values.grupo)}
          />
        </Col>

        <Col md={6}>
          <SelectAsync
            key={keyGrupoAlterou}
            id='idResultado'
            label='Resultado'
            emptyLabel='Selecione...'
            value={values.idResultado || -1}
            onChange={handleChange}
            api={() => getAllToSelectResultadoAPI(values.grupo)}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: '16px' }}>
        <Col md={6}>
          <SelectAsync
            key={`${keyIndicador}-${keyGrupoAlterou}`}
            id='idIndicador'
            label='Indicador'
            emptyLabel='Selecione...'
            value={values.idIndicador || -1}
            onChange={handleChange}
            getSelectedData={(dados) => {
              if (dados && dados.valorEsperado) {
                setFieldValue('esperado', dados.valorEsperado, false)
                setKeyEsperado(prev => prev + 1)
              }
            }}
            api={() => getAllToSelectIndicadorAPI(values.grupo, values.idResultado)}
          />
        </Col>

        <Col md={3}>
          <div style={{marginBottom: '16px', color: '#304439'}}>
            <label className="form-check-label form-label" htmlFor='valor'>
              Valor (1-5)
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='valor'
              name='valor'
              value={values.valor || ''}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={2}>
          <div style={{marginBottom: '16px', color: '#304439'}}>
            <label className="form-check-label form-label" htmlFor='esperado'>
              Esperado
            </label>

            <InputStyled
              key={keyEsperado}
              type='text'
              className='form-input form-control w-100'
              id='esperado'
              name='esperado'
              value={values.esperado || ''}
              onChange={handleChange}
            />
          </div>
        </Col>

        {!props.editar && (
          <Col md={1}>
            <MdFormatListBulletedAdd
              size={34}
              style={{ marginTop: '27px', cursor: 'pointer' }}
              color='#304439'
              onClick={() =>{ 
                if (values.idDiagnostico != -1) {
                  if (values.idResultado != -1) {
                    if (values.idIndicador != -1) {
                      if ((values.valor     != -1) &&  values.valor != null) {
                        if((values.valor < 6 && values.valor > 0 )){
                          if (values.esperado  != -1) {
                            setMensagem("")
                            submitForm()
                            }
                            else{
                              setMensagem("Insira o valor esperado");
                            }
                          } else{
                          setMensagem("Insira um valor entre 1 e 5");
                        } 
                      } 
                      else {
                        setMensagem("Insira o valor numero no valor");
                      }
                    }
                    else {
                      setMensagem("Insira o indicador");
                    }

                  }
                  else {
                    setMensagem("Selecione um  Resultado");
                  }
                }
                else {
                  setMensagem("Selecione um diagnostico");
                } 
              }      
               
            } 
            />
          </Col>
        )}
      </Row>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
      {props.editar && (
        <Row>
          <Col md={12} style={{ textAlign: 'right'}}>
            <Button onClick={() => submitForm()}>Gravar</Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export { PlanoCuidadoResultadosFormCampos }
export default PlanoCuidadoResultadosFormCampos
