import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IDispositivoListagem } from '../../../../api/cadastro/dispositivosAPI';
import { dispositivoListarAPI } from '../../../../api/cadastro/dispositivosAPI';

interface TDispositivosContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  dispositivos: IDispositivoListagem[],
  setDispositivos: (newDispositivos: IDispositivoListagem[]) => void,
  selecionado: IDispositivoListagem | null,
  setSelecionado: (novoSelecionado: IDispositivoListagem | null) => void,
  loadDispositivos: () => void
}

interface TDispositivosProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  dispositivos: [],
  setDispositivos: () => {
    throw new Error('setDispositivos não implementada');
  },
  loadDispositivos: () => {
    throw new Error('loadDispositivos não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const DispositivosContext = createContext<TDispositivosContext>(defaultState);

export const DispositivosProvider = ({children}: TDispositivosProviderProps) => {
  const [dispositivos, setDispositivos] = useState<IDispositivoListagem[]>(defaultState.dispositivos);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IDispositivoListagem | null>(defaultState.selecionado)

  const loadDispositivos = () => {
    setCarregando(true)
    dispositivoListarAPI()
        .then((data) => setDispositivos(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadDispositivos();
  }, []);

  const providerValues: TDispositivosContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    dispositivos,
    setDispositivos,
    loadDispositivos,
  };

  return (
    <DispositivosContext.Provider value={providerValues}>
      {children}
    </DispositivosContext.Provider>
  );
};
