import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

export const SelectSearchStyled = styled(AsyncSelect)`
  background-color: #212529;
  color: #ffffff;
  width: 100%;

  :focus {
    background-color: #212529;
    color: #ffffff;
  }

  :checked {
    background-color: #212529;
    color: #ffffff;
  }

  :disabled {
    background-color: #E5E4E2;
    color: #FFF7E6
  }
`;
