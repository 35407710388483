import { format } from "date-fns"

export const verificarAvaliadoNoDia = (ultimaAvaliacao: Date | null | undefined): boolean => {
  if (ultimaAvaliacao) {
    return (
      format(
        new Date(ultimaAvaliacao),
        'dd/MM/yyyy'
      ) === format(new Date(), 'dd/MM/yyyy')
    )
  }

  return false
}
