import { axiosAPI } from '../axiosAPI'
import { AbdomeEnum, AceitacaoEnum, IProntuarioNutricaoForm, IProntuarioNutricaoListagem, RhaEnum, TipoSondaEnum } from './types'

export const prontuarioNutricaoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioNutricaoForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarionutricao',
      {
        id_prontuario: idProntuario,
        oral: values.oral,
        aceitacao: values.aceitacao !== AceitacaoEnum.NENHUM ? values.aceitacao : null,
        tipo: values.tipo,
        vazao_ml: values.vazaoMl,
        tipo_sonda: values.tipoSonda !== TipoSondaEnum.NENHUM ? values.tipoSonda : null,
        data_insercao: values.dataInsercao,
        npt: values.npt,
        npt_etapa: values.nptEtapa,
        vazao_ml2: values.vazaoMl2,
        horario_inicio: values.horarioInicio,
        npt_local: values.nptLocal,
        dieta_zero: values.dietaZero,
        dias: values.dias,
        abdome: values.abdome !== AbdomeEnum.NENHUM ? values.abdome : null,
        rha: values.rha !== RhaEnum.NENHUM ? values.rha : null,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioNutricaoForm, IProntuarioNutricaoListagem }
