import { axiosAPI } from '../axiosAPI'
import { IProntuarioControleInfusaoForm, IProntuarioControleInfusaoListagem } from './types'

export const prontuarioControleInfusaoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioControleInfusaoForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariocontroleinfusao',
      {
        id_prontuario: idProntuario,
        descricao: values.descricao,
        mlh: values.mlh,
        id_tipo_infusao: values.idTipoInfusao,
      },
  )
}

export const prontuarioControleInfusaoDeleteAPI = async (
  idProntuarioControleInfusao: number,
): Promise<void> => {
  await axiosAPI.delete(
      `prontuariocontroleinfusao/delete?id=${idProntuarioControleInfusao}`,
  )
}

export type { IProntuarioControleInfusaoForm, IProntuarioControleInfusaoListagem }
