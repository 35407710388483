import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MdOutlineOpenInNew } from 'react-icons/md'
import { BsBandaid } from 'react-icons/bs'
import { Formik, useFormikContext } from 'formik'
import { Col, Modal, Row } from 'react-bootstrap'

import { EscalaBradenEnum, IProntuarioIntegridadeFisicaForm } from '../../../../../../api/prontuario/types'
import Button from '../../../../../../components/Button'
import { InputStyled, TableStyled } from '../../../../../../components/styles'

const DivSelectStyled = styled.div `
  background-color: #FFFFFF;
  width: 100%;
  color: #000;
  border: 1px solid #ced4da;
  padding: 12px 14px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

interface IEscalaBraden {
  percentualSensorial: string | null;
  umidade: string | null;
  atividade: string | null;
  mobilidade: string | null;
  nutricao: string | null;
  ficcao: string | null;
}

const EscalaBraden = () => {
  const [modalEscalaBraden, setModalEscalaBraden] = useState<boolean>(false)
  const [valoresIniciais, setValoresIniciais] = useState<IEscalaBraden>()
  const [total, setTotal] = useState<number>(0)

  const {handleChange, values, setFieldValue} = useFormikContext<IProntuarioIntegridadeFisicaForm>()

  useEffect(() => {
    if (modalEscalaBraden) {
      setValoresIniciais({
        percentualSensorial: values.percentualSensorial,
        umidade: values.umidade,
        atividade: values.atividade,
        mobilidade: values.mobilidade,
        nutricao: values.nutricao,
        ficcao: values.ficcao,
      })
    }
  }, [modalEscalaBraden])

  useEffect(() => {
    setTotal(
      Number(values.atividade || 0) +
      Number(values.ficcao || 0) +
      Number(values.mobilidade || 0) +
      Number(values.nutricao || 0) +
      Number(values.percentualSensorial || 0) +
      Number(values.umidade || 0)
    )
  }, [
    values.atividade,
    values.ficcao,
    values.mobilidade,
    values.nutricao,
    values.percentualSensorial,
    values.umidade
  ])

  const getEscala = () => {
    if (total < 12) {
      return EscalaBradenEnum.RISCO_SEVERO_MENOR_11
    }
    if (total < 15 && total > 11) {
      return EscalaBradenEnum.RISCO_MODERADO_12_14
    }
    if (total < 17 && total > 14) {
      return EscalaBradenEnum.RISCO_BRANDO_15_16
    }

    return ''
  }

  useEffect(() => {
    setFieldValue('escalaBraden', getEscala(), false)
  }, [total])

  const handleCancelar = () => {
    setFieldValue('percentualSensorial', valoresIniciais?.percentualSensorial, false)
    setFieldValue('umidade', valoresIniciais?.umidade, false)
    setFieldValue('atividade', valoresIniciais?.atividade, false)
    setFieldValue('mobilidade', valoresIniciais?.mobilidade, false)
    setFieldValue('nutricao', valoresIniciais?.nutricao, false)
    setFieldValue('ficcao', valoresIniciais?.ficcao, false)
  }

  const getEscalaTexto = () => {
    switch (values.escalaBraden) {
      case EscalaBradenEnum.RISCO_MODERADO_12_14:
        return 'Risco moderado'
    
      case EscalaBradenEnum.RISCO_SEVERO_MENOR_11:
        return 'Risco severo'

      case EscalaBradenEnum.RISCO_BRANDO_15_16:
        return 'Risco brando'
      
      default:
        return 'Risco brando'
    }
  }

  return (
    <>
      <div style={{ marginTop: '16px', marginBottom: '16px' }}>
        <label className="form-check-label form-label">
          Escala Braden
        </label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DivSelectStyled onClick={() => setModalEscalaBraden(true)}>
            {total < 15 ? (<>{getEscalaTexto()}</>) : (<div />)}
            <MdOutlineOpenInNew size={22}/>
          </DivSelectStyled>
          {total < 15 && (<BsBandaid color='#FD2424' style={{ marginLeft: '8px' }} size={26}/>)}
        </div>
      </div>

      <Modal show={modalEscalaBraden} onHide={() => setModalEscalaBraden(false)} size='xl'>
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            ESCALA BRADEN
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TableStyled>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Somar + 1</th>
                <th>Somar + 2</th>
                <th>Somar + 3</th>
                <th>Somar + 4</th>
              </tr>
            </thead>

            <tbody>
              <tr key='percepção_sensorial'>
                <td>Percepção sensorial</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='totalmenteLimitado'
                    name='percentualSensorial'
                    value={'1'}
                    checked={values.percentualSensorial === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="totalmenteLimitado">
                    Totalmente limitado
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='muitoLimitado'
                    name='percentualSensorial'
                    value={'2'}
                    checked={values.percentualSensorial  === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="muitoLimitado">
                    Muito limitado
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='levementeLimitado'
                    name='percentualSensorial'
                    value={'3'}
                    checked={values.percentualSensorial  === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="levementeLimitado">
                    Levemente limitado
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='nenhumaLimitacao'
                    name='percentualSensorial'
                    value={'4'}
                    checked={values.percentualSensorial  === '4'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="nenhumaLimitacao">
                    Nenhuma limitação
                  </label>
                </td>
              </tr>

              <tr key='umidade'>
                <td>Umidade</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='excessiva'
                    name='umidade'
                    value={'1'}
                    checked={values.umidade === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="excessiva">
                    Excessiva
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='muita'
                    name='umidade'
                    value={'2'}
                    checked={values.umidade === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="muita">
                    Muita
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='ocasional'
                    name='umidade'
                    value={'3'}
                    checked={values.umidade === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="ocasional">
                    Ocasional
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='rara'
                    name='umidade'
                    value={'4'}
                    checked={values.umidade === '4'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="rara">
                    Rara
                  </label>
                </td>
              </tr>

              <tr key='atividade'>
                <td>Atividade</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='atividade'
                    name='atividade'
                    value={'1'}
                    checked={values.atividade === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="atividade">
                    Acamado
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='conficadoCadeira'
                    name='atividade'
                    value={'2'}
                    checked={values.atividade === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="conficadoCadeira">
                    Confinado a cadeira
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='deambulaOcasionalmente'
                    name='atividade'
                    value={'3'}
                    checked={values.atividade === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="deambulaOcasionalmente">
                    Deambula ocasionalmente
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='deambulaFrequentimente'
                    name='atividade'
                    value={'4'}
                    checked={values.atividade === '4'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="deambulaFrequentimente">
                    Deambula frequentimente
                  </label>
                </td>
              </tr>

              <tr key='mobilidade'>
                <td>Mobilidade</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='imóvel'
                    name='mobilidade'
                    value={'1'}
                    checked={values.mobilidade === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="imóvel">
                    Imóvel
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='mobilidadeMuitoLimitado'
                    name='mobilidade'
                    value={'2'}
                    checked={values.mobilidade === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="mobilidadeMuitoLimitado">
                    Muito limitado
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='discretaLimitacao'
                    name='mobilidade'
                    value={'3'}
                    checked={values.mobilidade === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="discretaLimitacao">
                    Discreta limitação
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='semLimitacao'
                    name='mobilidade'
                    value={'4'}
                    checked={values.mobilidade === '4'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="semLimitacao">
                    Sem limitação
                  </label>
                </td>
              </tr>

              <tr key='nutricao'>
                <td>Nutrição</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='deficiente'
                    name='nutricao'
                    value={'1'}
                    checked={values.nutricao === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="deficiente">
                    Deficiente
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='inadequada'
                    name='nutricao'
                    value={'2'}
                    checked={values.nutricao === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="inadequada">
                    Inadequada
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='adequada'
                    name='nutricao'
                    value={'3'}
                    checked={values.nutricao === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="adequada">
                    Adequada
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='excelente'
                    name='nutricao'
                    value={'4'}
                    checked={values.nutricao === '4'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="excelente">
                    Excelente
                  </label>
                </td>
              </tr>

              <tr key='ficcao'>
                <td>Ficção e cisalhamento</td>
                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='problema'
                    name='ficcao'
                    value={'1'}
                    checked={values.ficcao === '1'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="problema">
                    Problema
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='problemaPotencial'
                    name='ficcao'
                    value={'2'}
                    checked={values.ficcao === '2'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="problemaPotencial">
                    Problema potencial
                  </label>
                </td>

                <td>
                  <input
                    type='radio'
                    style={{ marginRight: '8px' }}
                    id='semProblema'
                    name='ficcao'
                    value={'3'}
                    checked={values.ficcao === '3'}
                    onChange={handleChange}
                  />

                  <label className="form-check-label form-label mb-0" htmlFor="semProblema">
                    Sem problema
                  </label>
                </td>

                <td />
              </tr>
            </tbody>
          </TableStyled>

          <TableStyled>
            <tbody>
              <tr>
                <td>Total</td>
                <td>Risco brando - 15 a 16</td>
                <td>Risco moderado - 12 a 14</td>
                <td>Risco severo{` <`}11</td>
              </tr>
            </tbody>
          </TableStyled>
          <Row>
            <Col>
              <div style={{marginBottom: '16px', width: '20%', marginLeft: 'auto', marginRight: 'auto' }}>
                <label className="form-check-label form-label" htmlFor='riscoQueda' style={{ color: '#304439'}}>
                  Soma total
                </label>

                <InputStyled
                  type='number'
                  className='form-input form-control w-100'
                  id='total'
                  name='total'
                  value={String(total) || ''}
                  disabled
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => setModalEscalaBraden(false)}>Confirmar</Button>
          <Button variant='secondary' style={{ marginLeft: '12px' }} onClick={() => handleCancelar()}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { EscalaBraden }
export default EscalaBraden
