import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { IndicadoresProvider } from './context';
import { IndicadoresContainerForm } from './IndicadoresContainerForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../api/prontuario/types';

export interface IIndicadoresForm {
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  valorEsperado?: number,
  resultado: number,
}

const Indicadores = () => {
  return (
    <IndicadoresProvider>
      <DivMainStyled>
        <DivCardStyled>
          <IndicadoresContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </IndicadoresProvider>
  )
}

export { Indicadores }
export default Indicadores
