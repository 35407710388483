import React, { ReactElement } from 'react';
import styled from 'styled-components';

const PageFooterStyled = styled.footer `
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A9E2C3;
  height: 60px;

  position: sticky;
  width: 100%;
  bottom: 0;
`;

interface PageFooterProps {
  children: ReactElement | JSX.Element
}

export const PageFooter = (props: PageFooterProps) => {
  return (
    <PageFooterStyled>
      {props.children}
    </PageFooterStyled>
  );
};
