import { axiosAPI } from '../axiosAPI'
import { IProntuarioSonoRepousoForm, IProntuarioSonoRepousoListagem } from './types'

export const prontuarioSonoRepousoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioSonoRepousoForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariosonorepouso',
      {
        id_prontuario: idProntuario,
        sono_alterado: values.sonoAlterado,
        motivo: values.motivo,
        usa_medicamento_dormir: values.usaMedicamentoDormir,
        medicamento_dormir: values.medicamentoDormir,
        naoAplica: values.naoAplica,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioSonoRepousoForm, IProntuarioSonoRepousoListagem }
