
export interface IProntuarioRegistroComplementarListagem {
  id: number;
  eventosAdversos: string | null;
  ocorrencias: string | null;
}

export interface IProntuarioRegistroComplementarListagemBackend {
  id: number;
  eventos_adversos: string | null;
  ocorrencias: string | null;
}

export interface IProntuarioRegistroComplementarForm {
  eventosAdversos: string | null;
  ocorrencias: string | null;
}

export enum ItuNecessidadeEnum {
  BALANCO_HIBRIDO = 'BALANCO_HIBRIDO',
  SEDACAO = 'SEDACAO',
  POS_OP = 'POS_OP',
  OUTRO = 'OUTRO',
}

export enum SimNaoNaoSimplesEnum {
  SIM = 'SIM',
  NAO = 'NAO',
}

export enum SimNaoNaoAplicaEnum {
  SIM = 'SIM',
  NAO = 'NAO',
  NAO_APLICA = 'NAO_APLICA',
}

export enum CateterLocalAdequaoEnum {
  SIM = 'SIM',
  NAO = 'NAO',
  SIM_APOS_ORIENTACOES = 'SIM_APOS_ORIENTACOES',
  NAO_APLICA = 'NAO_APLICA',
}

export interface IProntuarioRegistroComplementarITUListagem {
  id: number;
  svn: ItuNecessidadeEnum;
  svnEspecificar: string | null;
  sistemaDrenagem: SimNaoNaoAplicaEnum;
  bolsaColetora: SimNaoNaoAplicaEnum;
  fluxoUninario: SimNaoNaoAplicaEnum;
  cateterFixado: CateterLocalAdequaoEnum;
  realizadaHigiene: SimNaoNaoAplicaEnum;
  limiteCapacidadeBolsa: SimNaoNaoAplicaEnum;
  uretaIntegra: SimNaoNaoSimplesEnum;
  uretaIntegraEspecificar: string | null;
  urinaAspectoNormal: SimNaoNaoSimplesEnum;
  urinaAspectoEspecificar: string | null;
}

export interface IProntuarioRegistroComplementarITUListagemBackend {
  id: number;
  svn: ItuNecessidadeEnum;
  svn_especificar: string | null;
  sistema_drenagem: SimNaoNaoAplicaEnum;
  bolsa_coletora: SimNaoNaoAplicaEnum;
  fluxo_uninario: SimNaoNaoAplicaEnum;
  cateter_fixado: CateterLocalAdequaoEnum;
  realizada_higiene: SimNaoNaoAplicaEnum;
  limite_capacidade_bolsa: SimNaoNaoAplicaEnum;
  ureta_integra: SimNaoNaoSimplesEnum;
  ureta_integra_especificar: string | null;
  urina_aspecto_normal: SimNaoNaoSimplesEnum;
  urina_aspecto_especificar: string | null;
}

export interface IProntuarioRegistroComplementarITUForm {
  svn: ItuNecessidadeEnum;
  svnEspecificar: string | null;
  sistemaDrenagem: SimNaoNaoAplicaEnum;
  bolsaColetora: SimNaoNaoAplicaEnum;
  fluxoUninario: SimNaoNaoAplicaEnum;
  cateterFixado: CateterLocalAdequaoEnum;
  realizadaHigiene: SimNaoNaoAplicaEnum;
  limiteCapacidadeBolsa: SimNaoNaoAplicaEnum;
  uretaIntegra: SimNaoNaoSimplesEnum;
  uretaIntegraEspecificar: string | null;
  urinaAspectoNormal: SimNaoNaoSimplesEnum;
  urinaAspectoEspecificar: string | null;
}

export interface IProntuarioRegistroComplementarCVCListagem {
  id: number;
  conectoresPrecisamTrocar: SimNaoNaoAplicaEnum;
  curativoPrecisamTrocar: SimNaoNaoAplicaEnum;
  equipamentosComData: SimNaoNaoAplicaEnum;
  fluxoUninario: SimNaoNaoAplicaEnum;
  curativoSujo: SimNaoNaoAplicaEnum;
  higenienizacaoMaos: boolean;
  usoGorroMascara: boolean;
  sf0_09: boolean;
  clorexina: boolean;
  gaze: boolean;
  filmeTransparente: boolean;
  malAderido: boolean;
  presencaSangue: boolean;
  presencaExsudato: boolean;
  trocaRotina: boolean;
  opcaoFilme: boolean;
  semSinaisFlosgisticos: boolean;
  presencaSinaisFlosgisticos: boolean;
}

export interface IProntuarioRegistroComplementarCVCListagemBackend {
  id: number;
  conectores_precisam_trocar: SimNaoNaoAplicaEnum;
  curativo_precisam_trocar: SimNaoNaoAplicaEnum;
  equipamentos_com_data: SimNaoNaoAplicaEnum;
  fluxo_uninario: SimNaoNaoAplicaEnum;
  curativo_sujo: SimNaoNaoAplicaEnum;
  higenienizacao_maos: boolean;
  uso_gorro_mascara: boolean;
  sf0_09: boolean;
  clorexina: boolean;
  gaze: boolean;
  filme_transparente: boolean;
  mal_aderido: boolean;
  presenca_sangue: boolean;
  presenca_exsudato: boolean;
  troca_rotina: boolean;
  opcao_filme: boolean;
  sem_sinais_flosgisticos: boolean;
  presenca_sinais_flosgisticos: boolean;
}

export interface IProntuarioRegistroComplementarCVCForm {
  conectoresPrecisamTrocar: SimNaoNaoAplicaEnum;
  curativoPrecisamTrocar: SimNaoNaoAplicaEnum;
  equipamentosComData: SimNaoNaoAplicaEnum;
  fluxoUninario: SimNaoNaoAplicaEnum;
  curativoSujo: SimNaoNaoAplicaEnum;
  higenienizacaoMaos: boolean;
  usoGorroMascara: boolean;
  sf0_09: boolean;
  clorexina: boolean;
  gaze: boolean;
  filmeTransparente: boolean;
  malAderido: boolean;
  presencaSangue: boolean;
  presencaExsudato: boolean;
  trocaRotina: boolean;
  opcaoFilme: boolean;
  semSinaisFlosgisticos: boolean;
  presencaSinaisFlosgisticos: boolean;
}

export interface IProntuarioRegistroComplementarPAVListagem {
  id: number;
  higieneOralRealizada: SimNaoNaoAplicaEnum;
  cabeceiraLeito_30_45: SimNaoNaoAplicaEnum;
  ventilorioDesprezados: SimNaoNaoAplicaEnum;
  higieneMaos: SimNaoNaoAplicaEnum;
  totFixado: SimNaoNaoAplicaEnum;
  doencaPulmonar: boolean;
  rxTorax: boolean;
  filtradoOpacificacao: SimNaoNaoAplicaEnum;
  teveFebreMaior_38: SimNaoNaoSimplesEnum;
  leocopeniaMenor_4000: SimNaoNaoSimplesEnum;
  leococitoseMaior_12000: SimNaoNaoSimplesEnum;
}

export interface IProntuarioRegistroComplementarPAVListagemBackend {
  id: number;
  higiene_oral_realizada: SimNaoNaoAplicaEnum;
  cabeceira_leito_30_45: SimNaoNaoAplicaEnum;
  ventilorio_desprezados: SimNaoNaoAplicaEnum;
  higiene_maos: SimNaoNaoAplicaEnum;
  tot_fixado: SimNaoNaoAplicaEnum;
  doenca_pulmonar: boolean;
  rx_torax: boolean;
  filtrado_opacificacao: SimNaoNaoAplicaEnum;
  teve_febre_maior_38: SimNaoNaoSimplesEnum;
  leocopenia_menor_4000: SimNaoNaoSimplesEnum;
  leococitose_maior_12000: SimNaoNaoSimplesEnum;
}

export interface IProntuarioRegistroComplementarPAVForm {
  higieneOralRealizada: SimNaoNaoAplicaEnum;
  cabeceiraLeito_30_45: SimNaoNaoAplicaEnum;
  ventilorioDesprezados: SimNaoNaoAplicaEnum;
  higieneMaos: SimNaoNaoAplicaEnum;
  totFixado: SimNaoNaoAplicaEnum;
  doencaPulmonar: boolean;
  rxTorax: boolean;
  filtradoOpacificacao: SimNaoNaoAplicaEnum;
  teveFebreMaior_38: SimNaoNaoSimplesEnum;
  leocopeniaMenor_4000: SimNaoNaoSimplesEnum;
  leococitoseMaior_12000: SimNaoNaoSimplesEnum;
}
