import { axiosAPI } from '../axiosAPI'
import { IProntuarioComunicacaoEducacaoForm, IProntuarioComunicacaoEducacaoListagem } from './types'

export const prontuarioComunicacaoEducacaoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioComunicacaoEducacaoForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariocomunicacaoeducacao',
      {
        id_prontuario: idProntuario,
        comunicacao: values.comunicacao,
        conhece_problema_saude: values.conheceProblemaSaude,
        afonia: values.afonia,
        dislalia: values.dislalia,
        disartria: values.disartria,
        disfasia: values.disfasia,
        afasia: values.afasia,
        sem_alteracao: values.semAlteracao,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioComunicacaoEducacaoForm, IProntuarioComunicacaoEducacaoListagem }
