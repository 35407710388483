import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'

import { IProntuarioPlanoCuidadoForm } from '../../../../../api/prontuario/types'
import { SelectGrupo } from '../../../../../components'
import { SelectAsync } from '../../../../../components/select-async'
import { getAllToSelectDiagnosticoAPI } from '../../../../../api/cadastro/diagnosticosAPI'
import { ProntuarioContext } from '../../context'
import { getApenasAlterados } from '../helper/getGruposAlterados'
import { Select } from '../../../../../components/select/Select';

const PlanoCuidadoFormCampos = () => {
  const [keyDiagnostico, setKeyDiagnostico] = useState<number>(0)
  const [desabledDiagnostico, setDesabledDiagnostico] = useState(true)
  const { values, handleChange, submitForm } = useFormikContext<IProntuarioPlanoCuidadoForm>()
  const { prontuario, carregando } = useContext(ProntuarioContext) 
  const [mensagem, setMensagem] = useState("")
  useEffect(() => {
    setKeyDiagnostico(prev => prev + 1)
  }, [values.grupo])
 
  return (
    <>
      <Row>
        <Col md={5}>
          <SelectGrupo
            value={values.grupo}
            gruposAlterados={getApenasAlterados(prontuario)}
            onChange={(e)=> {setDesabledDiagnostico(false); handleChange(e) }} 
          />
        </Col> 
        <Col md={6}>
          <SelectAsync
            key={keyDiagnostico}
            id='idDiagnostico'
            label='Diagnóstico de enfermagem'
            emptyLabel='Selecione...'
            value={(values.idDiagnostico) || -1}
            onChange={handleChange} 
            api={() => getAllToSelectDiagnosticoAPI(values.grupo)}
            disabled={desabledDiagnostico}
          />
        </Col>

        <Col md={1}>
          {carregando ? (
            <>
            <Spinner animation="border" style={{ marginTop: '27px'}}  />
            </>
          ) : (
            <> 
            <MdFormatListBulletedAdd
              size={44}
              style={{ marginTop: '27px', cursor: 'pointer' }}
              color='#304439'  
              onClick={() => {
                if(!desabledDiagnostico){
                  if( values.idDiagnostico.valueOf() !== -1){
                    submitForm(); 
                    setMensagem("")
                  } 
                  else{ 
                  setMensagem("Informe o diagnostico")
                  }
                }
                else{
                  setMensagem("Informe todos os dados")
                }
              }}
              />
              </>
          )}
        </Col>
      </Row>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
    </>
  )
}

export { PlanoCuidadoFormCampos }
export default PlanoCuidadoFormCampos
