import React, { useContext, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui'
import Swal from 'sweetalert2'
import { FaPlus } from 'react-icons/fa'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { cpf } from 'cpf-cnpj-validator'

import { IPacienteListagem, IPacienteProntuario, getAllToSelectPacienteAPI, pacienteCreateAPI } from '../../../api/pacientes'
import Button from '../../../components/Button'
import { SelectSearchAsync } from '../../../components/select-search-async'
import { prontuarioReinternacaoAPI } from '../../../api/prontuario'
import { PacientesContext } from '../context'
import PacientesFormCampos from './PacientesFormCampos'
import PacientesFormBotoes from './PacientesFormBotoes'

export interface IPacienteForm {
  nome: string | null,
  numeroSUS: string | null,
  sexo?: string | null,
  dataNascimento?: Date | null,
  email?: string | null,
  telefone?: string | null,
  contato?: string | null,
  estadoCivil?: string | null,
}

interface IPaciente extends IPacienteListagem, IPacienteProntuario {}

interface IPacientesModalFormProps {
  paciente?: IPaciente,
  modoEdicao?: boolean,
}

const ModalBodyStyled = styled(Modal.Body)`
  padding: 12px 26px;
  max-height: 480px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #bebcbc;
    border-radius: 4px;
  }
`

const PacientesModalForm = (props: IPacientesModalFormProps) => {
  const { carregando, loadPacientes, setCarregando, filtros } = useContext(PacientesContext)
  const [pacienteSelecionadoId, setPacienteSelecionadoId] = useState<number | undefined>()

  const [modalAberto, setModalAberto] = useState<boolean>(false)

  const initialValues: IPacienteForm = {
    sexo: props.paciente?.sexo || null,
    nome: props.paciente?.nome || null,
    dataNascimento: props.paciente?.dataNascimento ? new Date(props.paciente?.dataNascimento) : null,
    email: props.paciente?.email || null,
    telefone: props.paciente?.telefone || null,
    contato: props.paciente?.contato || null,
    estadoCivil: props.paciente?.estadoCivil || null,
    numeroSUS: props.paciente?.numeroSUS || null,
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').nullable()
      .when(
        [],
        { 
          is: () => !!props.paciente ? true : false,
          then: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').nullable().required('Campo obrigatório!')
        }
      ),
    numeroSUS: Yup.string()
      .uppercase()
      .nullable()
      .when(
        [],
        { 
          is: () => !!props.paciente ? true : false,
          then: Yup.string()
            .min(11, 'Muito curto!')
            .max(11, 'Muito longo!')
            .test(
              'cpf_validation',
              'CPF inválido!',
              (value) => value ? cpf.isValid(value) : false,
            )
            .required('Campo obrigatório!'),
        }
      ),
    email: Yup.string().email('Email inválido').nullable()
  })

  const handleSubmit = (
    values: IPacienteForm,
    actions: FormikHelpers<IPacienteForm>
  ) => {
    console.log(props);
    if (!props.paciente && values.nome && values.numeroSUS) {
      setCarregando(true)
      pacienteCreateAPI(
        values.nome,
        values.numeroSUS,
        values.sexo,
        values.dataNascimento,
        values.email,
        values.telefone,
        values.contato,
        values.estadoCivil,
      )
        .then(() => {
          loadPacientes(filtros)
          actions.resetForm()
          setModalAberto(false)
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Paciente criado com sucesso e pronto para admissão!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          })
        })
        .finally(() => setCarregando(false))
    } else if (pacienteSelecionadoId) {
      setCarregando(true)

      prontuarioReinternacaoAPI(pacienteSelecionadoId)
        .then(() => {
          loadPacientes(filtros)
          actions.resetForm()
          setModalAberto(false)
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Paciente pronto para admissão!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          })
        })
        .finally(() => setCarregando(false))
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Nenhum paciente foi adicionado! Tente novamente mais tarde...',
      })
    }
  }

  return (
    <>
      <Button
        variant='new'
        onClick={() => setModalAberto(true)}
      >
      <FaPlus color='white' size={12} style={{ marginRight: '8px' }} />
        Nova entrada
      </Button>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Modal
          show={modalAberto}
          size='lg'
          onHide={() => setModalAberto(false)}
        >
          <Modal.Header>
            <Modal.Title style={{ color: '#304439' }}>
              Entrada de paciente
            </Modal.Title>
          </Modal.Header>

          <ModalBodyStyled>
            <div style={{ color: '#304439', backgroundColor: '#EDE38E', padding: '12px 18px' }}>
              <SelectSearchAsync
                label='Selecione um paciente'
                api={getAllToSelectPacienteAPI}
                name='paciente'
                colorOption='#304439'
                placeholder='Pesquise pelo número do CPF...'
                noOptionsMessage='Não foi encontrado nenhum paciente'
                strong
                onChange={(selected) => {
                  if (selected) {
                    setPacienteSelecionadoId(selected.value)
                  } else {
                    setPacienteSelecionadoId(undefined)
                  }
                }}
              />
              
              <br />
              <hr />

              {pacienteSelecionadoId ? (
                <strong>Paciente selecionado</strong>
              ) : (
                <>
                  <strong>Ou adicione um novo paciente</strong>

                  <BlockUi tag="div" blocking={carregando}>
                    <PacientesFormCampos pacienteSelecionado={!!pacienteSelecionadoId} />
                  </BlockUi>
                </>
              )}
            </div>
          </ModalBodyStyled>

          <Modal.Footer>
            <PacientesFormBotoes
              adicionar={!pacienteSelecionadoId}
              setModalAberto={(novoValor) => setModalAberto(novoValor)}
            />
          </Modal.Footer>
        </Modal>
      </Formik>
    </>
  )
}

export { PacientesModalForm }
export default PacientesModalForm
