import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { TiposInfusoesContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';

const TiposInfusoesListagem = () => {
  const { tiposInfusoes, selecionado, setSelecionado } = useContext(TiposInfusoesContext);

  return (
    <>
      {tiposInfusoes && tiposInfusoes.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro tipo de infusão.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {tiposInfusoes && tiposInfusoes.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {tiposInfusoes && tiposInfusoes.length > 0 && tiposInfusoes.map((tipoInfusao => (
                <tr
                  key={tipoInfusao.id}
                  style={{ border: selecionado?.id === tipoInfusao.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(tipoInfusao)}
                >
                  <td>{tipoInfusao.nome}</td>
                  <td>{tipoInfusao.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { TiposInfusoesListagem }
export default TiposInfusoesListagem
