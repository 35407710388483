import { IProntuarioEliminacoesFisiologicasListagem, IProntuarioEliminacoesFisiologicasListagemBackend } from "../types";

export const parseProntuarioEliminacoesFisiologicasBackToFront = (
  dadosBackend: IProntuarioEliminacoesFisiologicasListagemBackend
): IProntuarioEliminacoesFisiologicasListagem => ({
  id: dadosBackend.id,
  wc: dadosBackend.wc,
  frauda: dadosBackend.frauda,
  svd: dadosBackend.svd,
  svdLocal: dadosBackend.svd_local,
  svdData: dadosBackend.svd_data,
  sva: dadosBackend.sva,
  disUrinario: dadosBackend.dis_urinario,
  outros: dadosBackend.outros,
  outrosDescricao: dadosBackend.outros_descricao,
  limpida: dadosBackend.limpida,
  concentrada: dadosBackend.concentrada,
  piuria: dadosBackend.piuria,
  coluria: dadosBackend.coluria,
  hematuria: dadosBackend.hematuria,
  aspOutros: dadosBackend.asp_outros,
  aspOutrosDescricao: dadosBackend.asp_outros_descricao,
  intesWc: dadosBackend.intes_wc,
  intesFrauda: dadosBackend.intes_frauda,
  colostomia: dadosBackend.colostomia,
  ileostomia: dadosBackend.ileostomia,
  intesOutros: dadosBackend.intes_outros,
  intesOutrosDescricao: dadosBackend.intes_outros_descricao,
  genitaliaIntegra: dadosBackend.genitalia_integra,
  normal: dadosBackend.normal,
  diareia: dadosBackend.diareia,
  pastoso: dadosBackend.pastoso,
  endurecido: dadosBackend.endurecido,
  constipacao: dadosBackend.constipacao,
  constipacaoDias: dadosBackend.constipacao_dias,
  observacao: dadosBackend.observacao,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
  descricaoUrinaria: dadosBackend.descricao_urinaria,
  descricaoIntestinal: dadosBackend.descricao_intestinal,
})
