import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import {
  ITipoPeleListagem,
  tipoPeleListarAPI,
} from '../../../../api/cadastro/tiposPeleAPI';

interface TTiposPeleContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  tiposPele: ITipoPeleListagem[],
  setTiposPele: (newTiposPele: ITipoPeleListagem[]) => void,
  selecionado: ITipoPeleListagem | null,
  setSelecionado: (novoSelecionado: ITipoPeleListagem | null) => void,
  loadTiposPele: () => void
}

interface TTiposPeleProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  tiposPele: [],
  setTiposPele: () => {
    throw new Error('setTiposPele não implementada');
  },
  loadTiposPele: () => {
    throw new Error('loadTiposPele não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const TiposPeleContext = createContext<TTiposPeleContext>(defaultState);

export const TiposPeleProvider = ({children}: TTiposPeleProviderProps) => {
  const [tiposPele, setTiposPele] = useState<ITipoPeleListagem[]>(defaultState.tiposPele);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<ITipoPeleListagem | null>(defaultState.selecionado)

  const loadTiposPele = () => {
    setCarregando(true)
    tipoPeleListarAPI()
        .then((data) => setTiposPele(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadTiposPele();
  }, []);

  const providerValues: TTiposPeleContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    tiposPele,
    setTiposPele,
    loadTiposPele,
  };

  return (
    <TiposPeleContext.Provider value={providerValues}>
      {children}
    </TiposPeleContext.Provider>
  );
};
