import React, { useContext } from 'react'
import { Formik, FormikHelpers } from 'formik'
import Swal from 'sweetalert2'

import {
  GrupoProntuarioPlanoCuidadoEnum,
  IProntuarioPlanoCuidadoAtividade,
} from '../../../../../../../api/prontuario/types'
import { ProntuarioContext } from '../../../../context'
import {
  IProntuarioPlanoCuidadoAtividadeForm,
  prontuarioPlanoCuidadoAtividadeCreateAPI,
  prontuarioPlanoCuidadoAtividadeEditAPI,
} from '../../../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import PlanoCuidadoAtividadesFormCampos from './PlanoCuidadoAtividadesFormCampos'

interface IPlanoCuidadoAtividadesFormProps {
  idPlanoCuidado: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
  editar?: boolean,
  dadosAtividade?: IProntuarioPlanoCuidadoAtividade,
  aoFinalizar?: () => void,
}

const PlanoCuidadoAtividadesForm = (props: IPlanoCuidadoAtividadesFormProps) => {
  const { prontuario, loadProntuario } = useContext(ProntuarioContext) 
  const initialValues: IProntuarioPlanoCuidadoAtividadeForm = {
    grupo: props.grupo,
    idDiagnostico: props.idDiagnostico,
    idAtividade: props.editar && props.dadosAtividade ? props.dadosAtividade.idAtividade : -1,
    idIntervencao: props.editar && props.dadosAtividade ? props.dadosAtividade.idIntervencao : -1,
    idAprazamento: props.editar && props.dadosAtividade ? props.dadosAtividade.idAprazamento : -1,
  }

  const handleSubmit = (
    values: IProntuarioPlanoCuidadoAtividadeForm,
    actions: FormikHelpers<IProntuarioPlanoCuidadoAtividadeForm>
  ) => {
    if (prontuario) {
      if (!props.editar) {
        prontuarioPlanoCuidadoAtividadeCreateAPI(props.idPlanoCuidado, values)
          .then(() => {
            loadProntuario(prontuario.id)
            actions.resetForm()
            if (props.aoFinalizar) props.aoFinalizar()
            Swal.fire({
              icon: 'success',
              title: 'Ok...',
              text: 'Atividade inserido com sucesso!',
              confirmButtonColor: '#1A8C01',
            })
          })
          .catch(err => {
            console.log(err)
  
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.error,
            });
          })
      } else if (props.dadosAtividade) {
        prontuarioPlanoCuidadoAtividadeEditAPI(props.dadosAtividade.id, values)
          .then(() => {
            loadProntuario(prontuario.id)
            actions.resetForm()
            if (props.aoFinalizar) props.aoFinalizar()
            Swal.fire({
              icon: 'success',
              title: 'Ok...',
              text: 'Atividade editada com sucesso!',
              confirmButtonColor: '#1A8C01',
            })
          })
          .catch(err => {
            console.log(err) 
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.error,
            });
          })
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit} >
      <PlanoCuidadoAtividadesFormCampos editar={props.editar} />
    </Formik>
  )
}

export { PlanoCuidadoAtividadesForm }
export default PlanoCuidadoAtividadesForm
