import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioComunicacaoEducacaoForm,
  prontuarioComunicacaoEducacaoCreateAPI,
} from '../../../../../api/prontuario/prontuarioComunicacaoEducacaoAPI'
import { ComunicacaoEnum, GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import { Select, Switch } from '../../../../../components'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { verificarAvaliadoNoDia } from '../../util'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

interface IProntuarioComunicacaoEducacaoProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioComunicacaoEducacao = (props: IProntuarioComunicacaoEducacaoProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.COMUNICACAO_EDUCACAO)

  const initialValues: IProntuarioComunicacaoEducacaoForm = {
    comunicacao: prontuario?.prontuarioComunicacaoEducacao[0]?.comunicacao || null,
    conheceProblemaSaude: prontuario?.prontuarioComunicacaoEducacao[0]?.conheceProblemaSaude || null,
    afonia: prontuario?.prontuarioComunicacaoEducacao[0]?.afonia || null,
    dislalia: prontuario?.prontuarioComunicacaoEducacao[0]?.dislalia || null,
    disartria: prontuario?.prontuarioComunicacaoEducacao[0]?.disartria || null,
    disfasia: prontuario?.prontuarioComunicacaoEducacao[0]?.disfasia || null,
    afasia: prontuario?.prontuarioComunicacaoEducacao[0]?.afasia || null,
    semAlteracao: prontuario?.prontuarioComunicacaoEducacao[0]?.semAlteracao || null,
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioComunicacaoEducacao[0]?.avaliado),
    alterado: prontuario?.prontuarioComunicacaoEducacao[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioComunicacaoEducacaoForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioComunicacaoEducacaoCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de comunicação/educação para a saúde/aprendizagem incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('religiosidade'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioComunicacaoEducacao[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioComunicacaoEducacao[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('comunicacaoEducacao')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Comunicação/Educação para a saúde/Aprendizagem</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'comunicacaoEducacao') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'comunicacaoEducacao') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row style={{ marginBottom: '16px' }}>
                <Col md={3}>
                  <Select
                    label='Comunicação'
                    emptyLabel='Selecione...'
                    id='comunicacao'
                    value={values.comunicacao || ''}
                    options={[
                      { label: 'Adequada', value: ComunicacaoEnum.ADEQUADA },
                      { label: 'Dificuldade', value: ComunicacaoEnum.DIFICULDADE },
                      { label: 'Uso de linguagem verbal', value: ComunicacaoEnum.USO_LINGUEAGEM_VERBAL },
                      { label: 'Uso de linguagem não verbal', value: ComunicacaoEnum.USO_LINGUEAGEM_NAO_VERBAL },
                    ]}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={3}>
                  <Switch
                    label='Conhece seu problema de saúde'
                    name='conheceProblemaSaude'
                    checked={values.conheceProblemaSaude || false}
                    onChange={(value) => setFieldValue('conheceProblemaSaude', value, false)}
                  />
                </Col>
              </Row>

              <Row style={{ border: '1px solid #BCA15C', borderRadius: '12px'}}>
                <Col md={12} style={{ color: '#304439', fontSize: '14px', fontWeight: '700', marginTop: '6px' }}>
                  Fala/Linguaguem
                </Col>
                <Col md={1}>
                  <Switch
                    label='Afonia'
                    name='afonia'
                    checked={values.afonia || false}
                    onChange={(value) => setFieldValue('afonia', value, false)}
                  />
                </Col>
                <Col md={1}>
                  <Switch
                    label='Dislalia'
                    name='dislalia'
                    checked={values.dislalia || false}
                    onChange={(value) => setFieldValue('dislalia', value, false)}
                  />
                </Col>
                <Col md={1}>
                  <Switch
                    label='Disartria'
                    name='disartria'
                    checked={values.disartria || false}
                    onChange={(value) => setFieldValue('disartria', value, false)}
                  />
                </Col>
                <Col md={1}>
                  <Switch
                    label='Disfasia'
                    name='disfasia'
                    checked={values.disfasia || false}
                    onChange={(value) => setFieldValue('disfasia', value, false)}
                  />
                </Col>
                <Col md={1}>
                  <Switch
                    label='Afasia'
                    name='afasia'
                    checked={values.afasia || false}
                    onChange={(value) => setFieldValue('afasia', value, false)}
                  />
                </Col>
                <Col md={2}>
                  <Switch
                    label='Sem alterações'
                    name='semAlteracao'
                    checked={values.semAlteracao || false}
                    onChange={(value) => setFieldValue('semAlteracao', value, false)}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '16px' }}>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioComunicacaoEducacao }
export default ProntuarioComunicacaoEducacao
