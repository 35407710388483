import { axiosAPI } from '../axiosAPI'
import { IProntuarioEliminacoesFisiologicasForm, IProntuarioEliminacoesFisiologicasListagem } from './types'

export const prontuarioEliminacoesFisiologicasCreateAPI = async (
  idProntuario: number,
  values: IProntuarioEliminacoesFisiologicasForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioeliminacaofisio',
      {
        id_prontuario: idProntuario,
        wc: values.wc,
        frauda: values.frauda,
        svd: values.svd,
        svd_local: values.svdLocal,
        svd_data: values.svdData,
        sva: values.sva,
        dis_urinario: values.disUrinario,
        outros: values.outros,
        outros_descricao: values.outrosDescricao,
        limpida: values.limpida,
        concentrada: values.concentrada,
        piuria: values.piuria,
        coluria: values.coluria,
        hematuria: values.hematuria,
        asp_outros: values.aspOutros,
        asp_outros_descricao: values.aspOutrosDescricao,
        intes_wc: values.intesWc,
        intes_frauda: values.intesFrauda,
        colostomia: values.colostomia,
        ileostomia: values.ileostomia,
        intes_outros: values.intesOutros,
        intes_outros_descricao: values.intesOutrosDescricao,
        genitalia_integra: values.genitaliaIntegra,
        normal: values.normal,
        diareia: values.diareia,
        pastoso: values.pastoso,
        endurecido: values.endurecido,
        constipacao: values.constipacao,
        constipacao_dias: values.constipacaoDias,
        observacao: values.observacao,
        avaliado: values.avaliado,
        alterado: values.alterado,
        descricao_urinaria: values.descricaoUrinaria,
        descricao_intestinal: values.descricaoIntestinal,
      },
  )
}

export type { IProntuarioEliminacoesFisiologicasForm, IProntuarioEliminacoesFisiologicasListagem }
