import styled from "styled-components";

export const Range = styled.input`
  ::-webkit-slider-runnable-track {
    background: #304439;
    border-radius: 4px;
  }

  ::-webkit-slider-thumb {
    background: #304439;
    box-shadow: none;
  }
`
