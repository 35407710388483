import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { IPacienteListagem, IPacienteListagemBackend, IPacientesFiltros } from './types'
import { parsePacienteBackToFront } from './parser'
import { IPacienteInfoForm } from '../../pages/pacientes/components/PacientesModalInfo'

export const pacienteListarAPI = async (filtros: IPacientesFiltros): Promise<IPacienteListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IPacienteListagemBackend[]> = await axiosAPI.get(
      'paciente',
      {
        params: {
          id_empresa: user.idEmpresa,
          id_usuario: user.id,
          nome: filtros.nome,
          clinicaMedica: filtros.clinicaMedica,
          clinicaCirurgica: filtros.clinicaCirurgica,
          uti: filtros.uti,
          saiu: filtros.saiu,
        }
      },
  )

  return response.data.map(dado => parsePacienteBackToFront(dado))
}

export const pacienteSemAltaListarAPI = async (filtros: IPacientesFiltros): Promise<IPacienteListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IPacienteListagemBackend[]> = await axiosAPI.get(
      'paciente/listpacientessemalta',
      {
        params: {
          id_empresa: user.idEmpresa,
          id_usuario: user.id,
          nome: filtros.nome,
          clinica_medica: filtros.clinicaMedica,
          clinica_cirurgica: filtros.clinicaCirurgica,
          uti: filtros.uti,
          saiu: filtros.saiu,
        }
      },
  )

  return response.data.map(dado => parsePacienteBackToFront(dado))
}

export const pacienteEmAtendimentoListarAPI = async (filtros: IPacientesFiltros): Promise<IPacienteListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IPacienteListagemBackend[]> = await axiosAPI.get(
      'paciente/listpacientesematendimento',
      {
        params: {
          id_empresa: user.idEmpresa,
          id_usuario: user.id,
          nome: filtros.nome,
          clinica_medica: filtros.clinicaMedica,
          clinica_cirurgica: filtros.clinicaCirurgica,
          uti: filtros.uti,
          saiu: filtros.saiu,
        }
      },
  )

  return response.data.map(dado => parsePacienteBackToFront(dado))
}

export const getAllToSelectPacienteAPI = async (numeroSUS?: string): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IPacienteListagem[]> = await axiosAPI.get(
      'paciente',
      { params: { id_empresa: user.idEmpresa, numero_sus: numeroSUS } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}

export const getAllToSelectPacienteEmAntendimentoAPI = async (nome?: string): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IPacienteListagemBackend[]> = await axiosAPI.get(
      'paciente/listpacientesematendimento',
      { params: { id_empresa: user.idEmpresa, nome: nome } }
  )

  const options: ISelectChoice[] = []
  if (response.data) {
    response.data.forEach((item) => {
      if (item.Prontuario.length > 0) {
        options.push({
          label: `${item.nome} - Prontuário: ${item.Prontuario[0]?.id}`,
          value: item.id,
          prontuarioId: item.Prontuario[0]?.id,
          pacienteNome: item.nome
        })
      }
    })
  }

  return options
}

export const pacienteCreateAPI = async (
  nome: string,
  numeroSUS: string,
  sexo?: string | null,
  dataNascimento?: Date | null,
  email?: string | null,
  telefone?: string | null,
  contato?: string | null,
  estadoCivil?: string | null,
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'paciente',
      {
        id_empresa: user.idEmpresa,
        nome,
        numero_sus: numeroSUS,
        sexo,
        data_nascimento: dataNascimento,
        email,
        telefone,
        contato,
        estado_civil: estadoCivil,
      },
  )
}

export const pacienteUpdateAPI = async (
    id: number,
    idProntuario: number,
    values: IPacienteInfoForm
): Promise<void> => {
    await axiosAPI.put(
      'paciente/edit_com_prontuario',
      {
        id,
        id_prontuario: idProntuario,
        nome: values.nome,
        sexo: values.sexo,
        email: values.email,
        telefone: values.telefone,
        contato: values.contato,
        estado_civil: values.estadoCivil,
        data_nascimento: values.dataNascimento,
        numero_sus: values.numeroSUS,
        idLeito: values.idLeito,
        dh_admissao: values.dhAdmissao,
        proc_residencia: values.procResidencia,
        proc_rua: values.procRua,
        proc_serv_saude: values.procServSaude,
        proc_setor: values.procSetor,
        proc_setor_nome: values.procSetorNome,
        isol_protetor: values.isolProtetor,
        isol_respiratorio: values.isolRespiratorio,
        isol_contato: values.isolContato,
        isol_vigilancia: values.isolVigilancia,
        risco_queda: values.riscoQueda,
        risco_lesao_pressao: values.riscoLesaoPressao,
        risco_alergia: values.riscoAlergia,
        risco_ressecamento_ocular: values.riscoRessecamentoOcular,
        queixa_principal: values.queixaPrincipal,
        comorbidades: values.comorbidades,
        medicacao_uso: values.medicacaoUso,
        ocorrencias: values.ocorrencias,
        eventos_adversos: values.eventosAdversos,
      },
  )
}
