import React from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { GiFalling } from 'react-icons/gi'
import { BsBandaid, BsFillEyeFill } from 'react-icons/bs'
import { MdSick } from 'react-icons/md'

import { CheckboxStyled, InputStyled, TextAreaStyled } from '../../../components/styles'
import { DateTime, Select } from '../../../components'
import { IPacienteInfoForm } from './PacientesModalInfo'
import { SelectAsync } from '../../../components/select-async'
import { getAllToSelectLeitoAPI } from '../../../api/cadastro/leitosAPI'
import { DivComponentsStyled, ListGroupHeadStyled, ListGroupItemStyled } from '../prontuario/components/styles'
import { FaAsterisk } from 'react-icons/fa'

interface IPacientesFormCamposInfoProps {
  modoEdicao: boolean,
}
const PacientesFormCamposInfo = (props: IPacientesFormCamposInfoProps) => {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } = useFormikContext<IPacienteInfoForm>()
  console.log( values.idLeito || "Vazio")
  
  return (
    <div>
      <Row>
        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='nome'>
              Nome <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='nome'
              name='nome'
              value={values.nome || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={3}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='numeroSUS'>
              CPF <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label> 
            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='numeroSUS'
              name='numeroSUS'
              value={values.numeroSUS || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.numeroSUS && touched.numeroSUS && (
              <div style={{color: '#FF264D'}}>{errors.numeroSUS}</div>
            )}
          </div>
        </Col>

        <Col md={3}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='telefone'>
              Telefone
            </label> 
            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='telefone'
              name='telefone'
              value={values.telefone || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row> 
      <Row>
        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='email'>
              Email
            </label> 
            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='email'
              name='email'
              value={values.email || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='contato'>
              Pessoa p/ contato
            </label>

            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='contato'
              name='contato'
              value={values.contato || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <DateTime
            id='dataNascimento'
            label='Data de nascimento'
            maxDate={new Date()}
            selected={values.dataNascimento}
            disabled={!props.modoEdicao}
          />
        </Col>

        <Col md={4}>
          <Select
            label='Sexo'
            id='sexo'
            value={values.sexo || ''}
            disabled={!props.modoEdicao}
            options={[
              { label: 'Masculino', value: 'masculino' },
              { label: 'Feminino', value: 'feminino' },
            ]}
            onChange={handleChange}
          />
        </Col>

        <Col md={4}>
          <Select
            label='Estado civil'
            id='estadoCivil'
            value={values.estadoCivil || ''}
            disabled={!props.modoEdicao}
            options={[
              { label: 'Solteiro', value: 'solteiro' },
              { label: 'Casado', value: 'casado' },
              { label: 'Separado', value: 'separado' },
              { label: 'Divorciado', value: 'divorciado' },
              { label: 'Viúvo', value: 'viuvo' },
            ]}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='idProntuario'>
              # Prontuário
            </label> 
            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='idProntuario'
              name='idProntuario'
              value={values.idProntuario || ''}
              disabled
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={4}>
          <SelectAsync 
            emptyLabel='Selecione'
            id='idLeito'
            label='Leito'
            value={values.idLeito || 0} // value={values.idLeito || -1} 
            onChange={handleChange}
            api={getAllToSelectLeitoAPI}
            disabled={!props.modoEdicao}
          />
        </Col>

        <Col md={4}>
          <DateTime
            id='dhAdmissao'
            label='Data/hora admissão'
            maxDate={new Date()}
            selected={values.dhAdmissao}
            disabled={!props.modoEdicao}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <ListGroup>
            <ListGroupHeadStyled>
              Situação
            </ListGroupHeadStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cliMedica'
                  name='tipoSituacao'
                  value={'cliMedica'}
                  checked={values.tipoSituacao === 'cliMedica'}
                  onBlur={handleBlur}
                  disabled={!props.modoEdicao}
                  onChange={(value) => {
                    setFieldValue('cliMedica', true, false)
                    setFieldValue('cliCirurgica', false, false)
                    setFieldValue('uti', false, false)
                    setFieldValue('reinternacao', false, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cliMedica">
                  Clín. médica
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cliCirurgica'
                  name='tipoSituacao'
                  value={'cliCirurgica'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoSituacao === 'cliCirurgica'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('cliMedica', false, false)
                    setFieldValue('cliCirurgica', true, false)
                    setFieldValue('uti', false, false)
                    setFieldValue('reinternacao', false, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cliCirurgica">
                  Clín. cirúrgica
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='uti'
                  name='tipoSituacao'
                  value={'uti'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoSituacao === 'uti'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('cliMedica', false, false)
                    setFieldValue('cliCirurgica', false, false)
                    setFieldValue('uti', true, false)
                    setFieldValue('reinternacao', false, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="uti">
                  UTI
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='reinternacao'
                  name='tipoSituacao'
                  value={'reinternacao'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoSituacao === 'reinternacao'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('cliMedica', false, false)
                    setFieldValue('cliCirurgica', false, false)
                    setFieldValue('uti', false, false)
                    setFieldValue('reinternacao', true, false)
                    handleChange(value)
                  }}
                />
                  Reinternação
                <label className="form-check-label form-label mb-0" htmlFor="reinternacao">
                
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
          </ListGroup>
        </Col>

        <Col md={4}>
          <ListGroup>
            <ListGroupHeadStyled>
              Procedência
            </ListGroupHeadStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='procResidencia'
                  name='tipoProcedencia'
                  value={'procResidencia'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoProcedencia === 'procResidencia'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('procResidencia', true, false)
                    setFieldValue('procRua', false, false)
                    setFieldValue('procServSaude', false, false)
                    setFieldValue('procSetor', false, false)
                    setFieldValue('procSetorNome', null, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="residencia">
                  Residência
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='procRua'
                  name='tipoProcedencia'
                  value={'procRua'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoProcedencia === 'procRua'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('procResidencia', false, false)
                    setFieldValue('procRua', true, false)
                    setFieldValue('procServSaude', false, false)
                    setFieldValue('procSetor', false, false)
                    setFieldValue('procSetorNome', null, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="procRua">
                  Rua
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='procServSaude'
                  name='tipoProcedencia'
                  value={'procServSaude'}
                  disabled={!props.modoEdicao}
                  checked={values.tipoProcedencia === 'procServSaude'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('procResidencia', false, false)
                    setFieldValue('procRua', false, false)
                    setFieldValue('procServSaude', true, false)
                    setFieldValue('procSetor', false, false)
                    setFieldValue('procSetorNome', null, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="procServSaude">
                  Serviço de saúde
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='procSetor'
                  name='tipoProcedencia'
                  disabled={!props.modoEdicao}
                  value={'procSetor'}
                  checked={values.tipoProcedencia === 'procSetor'}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    setFieldValue('procResidencia', false, false)
                    setFieldValue('procRua', false, false)
                    setFieldValue('procServSaude', false, false)
                    setFieldValue('procSetor', true, false)
                    handleChange(value)
                  }}
                />

                <label className="form-check-label form-label mb-0" htmlFor="procSetor">
                  Setor
                </label>

                <div style={{ marginLeft: '12px' }}>
                  <InputStyled
                    name='procSetorNome'
                    id='procSetorNome'
                    placeholder='Setor?'
                    disabled={!props.modoEdicao}
                    value={values.procSetorNome || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </DivComponentsStyled>
            </ListGroupItemStyled>
          </ListGroup>
        </Col>

        <Col md={4}>
          <ListGroup>
            <ListGroupHeadStyled>
              Isolamento
            </ListGroupHeadStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <CheckboxStyled
                  className="form-check-input form-control"
                  style={{ marginRight: '6px' }}
                  type="checkbox"
                  id="isolProtetorCheckbox"
                  name='isolProtetor'
                  checked={values.isolProtetor}
                  disabled={!props.modoEdicao}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="isolProtetorCheckbox">
                  Protetor
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <CheckboxStyled
                  className="form-check-input form-control"
                  style={{ marginRight: '6px' }}
                  type="checkbox"
                  id="isolRespiratorioCheckbox"
                  name='isolRespiratorio'
                  checked={values.isolRespiratorio}
                  disabled={!props.modoEdicao}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="isolRespiratorioCheckbox">
                  Respiratório
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <CheckboxStyled
                  className="form-check-input form-control"
                  style={{ marginRight: '6px' }}
                  type="checkbox"
                  id="isolContatoCheckbox"
                  name='isolContato'
                  checked={values.isolContato}
                  disabled={!props.modoEdicao}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="isolContatoCheckbox">
                  Contato
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
            <ListGroupItemStyled>
              <DivComponentsStyled>
                <CheckboxStyled
                  className="form-check-input form-control"
                  style={{ marginRight: '6px' }}
                  type="checkbox"
                  id="isolVigilanciaCheckbox"
                  name='isolVigilancia'
                  checked={values.isolVigilancia}
                  disabled={!props.modoEdicao}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="isolVigilanciaCheckbox">
                  Vigilância
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
          </ListGroup>
        </Col>
      </Row>

      <div style={{ fontSize: '22px', fontWeight: '700' }}>
        Risco
      </div>
      <Row>
        <Col md={12} style={{ display: 'flex', alignItems: 'center' }}>
          <DivComponentsStyled>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="riscoQuedaCheckbox"
              name='riscoQueda'
              checked={values.riscoQueda}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label mb-0" htmlFor="riscoQuedaCheckbox">
              <GiFalling /> Queda
            </label>
          </DivComponentsStyled>

          <DivComponentsStyled>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="riscoLesaoPressaoCheckbox"
              name='riscoLesaoPressao'
              checked={values.riscoLesaoPressao}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label mb-0" htmlFor="riscoLesaoPressaoCheckbox">
              <BsBandaid /> Lesão pressão
            </label>
          </DivComponentsStyled>

          <DivComponentsStyled>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="riscoAlergiaCheckbox"
              name='riscoAlergia'
              checked={values.riscoAlergia}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label mb-0" htmlFor="riscoAlergiaCheckbox">
              <MdSick /> Alergia
            </label>
          </DivComponentsStyled>

          <DivComponentsStyled>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="riscoRessecamentoOcularCheckbox"
              name='riscoRessecamentoOcular'
              checked={values.riscoRessecamentoOcular}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label mb-0" htmlFor="riscoRessecamentoOcularCheckbox">
              <BsFillEyeFill /> Ressecamento ocular
            </label>
          </DivComponentsStyled>
        </Col>
      </Row>

      <Row style={{ marginTop: '14px' }}>
        <Col md={12}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='queixaPrincipal'>
              Queixa principal
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='queixaPrincipal'
              name='queixaPrincipal'
              value={values.queixaPrincipal || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='comorbidades'>
              Comorbidades
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='comorbidades'
              name='comorbidades'
              value={values.comorbidades || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='medicacaoUso'>
              Medicação em uso
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='medicacaoUso'
              name='medicacaoUso'
              value={values.medicacaoUso || ''}
              disabled={!props.modoEdicao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='eventosAdversos'>
              Eventos adversos
            </label>

            <TextAreaStyled
              className='form-input form-control w-100'
              id='eventosAdversos'
              name='eventosAdversos'
              value={values.eventosAdversos || ''}
              disabled={!props.modoEdicao}
              rows={3}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='ocorrencias'>
              Ocorrências
            </label>

            <TextAreaStyled
              className='form-input form-control w-100'
              id='ocorrencias'
              name='ocorrencias'
              value={values.ocorrencias || ''}
              disabled={!props.modoEdicao}
              rows={3}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export { PacientesFormCamposInfo }
export default PacientesFormCamposInfo
