import React, {FocusEvent, FormEvent, ReactElement, useEffect, useState} from 'react';
import {FaAsterisk} from 'react-icons/fa';
import {SelectStyled} from '../styles';

const NULL_NUMBER = -1;

interface SelectProps {
  id: string,
  label: string,
  name?: string,
  className?: string,
  emptyLabel?: string,
  required?: boolean,
  disabled?: boolean,
  icone?: ReactElement, 
  value: number | string | null,
  options: ISelectChoice[],
  onlyOptions?: string[],
  onChange: (e: FormEvent<HTMLSelectElement>) => void,
  onBlur?: (e: FocusEvent<any, Element>) => void,
}

export interface ISelectChoice {
  label: string,
  value?: number | string
}

export const Select = (props: SelectProps) => {
  const [options, setOptions] = useState<ISelectChoice[]>(props.options);

  useEffect(() => {
    if (props.onlyOptions && props.onlyOptions.length > 0) {
      setOptions(prev => prev.filter(op => props.onlyOptions?.includes(op.value ? String(op.value) : '') || op.value === NULL_NUMBER))
    }
  }, [props.options, props.onlyOptions])

  return (
    <div style={{   display: 'flex', flexDirection: 'column', width: '100%', color: '#304439', marginBottom: '16px' }}>
      <label htmlFor={props.id} className="form-label">
        {props.label} {props.required && !props.disabled ? (<sup><FaAsterisk color='#FF264D' size={8} /></sup>) : ''}
      </label>

      <div style={{   display: 'flex', alignItems: 'center' }}>
        <SelectStyled
          id={props.id}
          name={props.name}
          className={props.className}
          value={props.value || undefined}
          disabled={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
        >
          {props.emptyLabel && (
            <option
              value={NULL_NUMBER}
              key={`${props.emptyLabel}`}
            >
              {props.emptyLabel}
            </option>
          )}
          {options && options.map((op) => (
            <option value={op.value || undefined} key={`${op.label}-${op.value}`}>{op.label}</option>
          ))}
        </SelectStyled>

        {props.icone && (
          <div style={{ marginLeft: '12px' }}>
            {props.icone}
          </div>
        )}
      </div>
    </div>
  );
};

Select.propsDefault = {
  disabled: false,
  required: false,
};
