import { axiosAPI } from '../axiosAPI'
import { IProntuarioPercepcaoSentidosForm, IProntuarioPercepcaoSentidosListagem } from './types'

export const prontuarioPercepcaoSentidosCreateAPI = async (
  idProntuario: number,
  values: IProntuarioPercepcaoSentidosForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariopercepcaosentidos',
      {
        id_prontuario: idProntuario,
        dor: values.dor,
        local_dor: values.localDor,
        escala_dor: values.escalaDor,
        medicamento_dor: values.medicamentoDor,
        resolutividade: values.resolutividade,
        escala_ccpot: values.escalaCcpot,
        expressao_facial: values.expressaoFacial,
        tensao_muscular: values.tensaoMuscular,
        movimento_corporal: values.movimentoCorporal,
        adaptacao_ventilador: values.adaptacaoVentilador,
        visao: values.visao,
        audicao: values.audicao,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioPercepcaoSentidosForm, IProntuarioPercepcaoSentidosListagem }
