import React from 'react';

interface PageFilterProps {
  children: JSX.Element
}

export const PageFilter = ({children}: PageFilterProps) => {
  return (
    <>
      <hr style={{color: 'white'}}/>
      {children}
      <hr style={{color: 'white'}}/>
    </>
  );
};
