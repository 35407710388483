import React from 'react'
import { FaUser } from 'react-icons/fa';

import { Page } from '../../components/page';
import { PacientesProvider } from './context';
import { PacientesListagem, PacientesFiltros, PacientesModalForm } from './components';
import { PassagemPlantao, PassagemPlantaoProvider } from '../profissionais/components/passagem_plantao';

const Pacientes = () => {
  return (
    <PacientesProvider>
      <Page>
        <Page.Header
          icon={FaUser}
          title='Pacientes'
          buttons={<PacientesModalForm />}
        />

        <Page.Filter>
          <PacientesFiltros />
        </Page.Filter>

        <Page.Body>
          <PacientesListagem />
        </Page.Body>

        <Page.Footer>
          <PassagemPlantaoProvider>
            <PassagemPlantao />
          </PassagemPlantaoProvider>
        </Page.Footer>
  
      </Page>
    </PacientesProvider>
  )
}

export { Pacientes }
export default Pacientes
