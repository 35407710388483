import React, { useState } from 'react'
import { FaUserTie } from 'react-icons/fa'
import { Tab, Tabs } from 'react-bootstrap' 
import { Page } from '../../components/page'
import { MensagensListagem } from './mensagem'
import { Dashboard } from './dashboard'
import { DashboardProvider } from './context'

const AreaGestor = () => {
  const [tabKey, setTabKey] = useState<string>('dashboard')
  return (
    <Page>
      <Page.Header
        icon={FaUserTie}
        title='Área do gestor' 
      /> 
      <Page.Body>
        <Tabs 
        style={{color: "white" }} 
          activeKey={tabKey} 
          onSelect={(k) => { if (k){ setTabKey(k)} }}
          className="mb-1"  
        >
          <Tab eventKey="dashboard" title="Dashboard">
            <DashboardProvider >
              <Dashboard  />
            </DashboardProvider>
          </Tab> 
          <Tab eventKey="mensagens" title="Mensagens"  >
            <MensagensListagem />
          </Tab>
        </Tabs>
      </Page.Body>
    </Page>
  )
}

export { AreaGestor }
export default AreaGestor
