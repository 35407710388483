import React, { useContext, useEffect, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { HiOutlineTrash } from 'react-icons/hi'

import { getAllToSelectPacienteEmAntendimentoAPI } from '../../../../../../api/pacientes'
import {
  pontoProfissionalProntuarioCriarAPI,
  pontoProfissionalProntuarioDeleteAPI,
} from '../../../../../../api/profissionais/pontoProfissionalAPI'
import { SelectSearchAsync } from '../../../../../../components/select-search-async'
import { TableStyled } from '../../../../../../components/styles'
import { PassagemPlantaoContext } from '../../context'
import { UsuarioPontoProntuarioEditObservacao } from './UsuarioPontoProntuarioEditObservacao'
import { useFormikContext } from 'formik'
import { IBaterPontoEditForm } from '../../PassagemPlantao'
import Checkbox from '../../../../../../components/checkbox/Checkbox'
import { ISelectChoice } from '../../../../../../components/select-async'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`
interface IUsuarioPontoProntuarioEditFormProps {
  handleFecharModal: () => void,
}

const UsuarioPontoProntuarioEditForm = (props: IUsuarioPontoProntuarioEditFormProps) => {
  const [prontuarioSelecionadoId, setProntuarioSelecionadoId] = useState<number>(-1)
  const [todosPacientes, setTodosPacientes] = useState<ISelectChoice[]>([])
  const [primeiroAcesso, setPrimeiroAcesso] = useState<boolean>(true)

  const { ultimoPonto, loadUltimoPonto } = useContext(PassagemPlantaoContext)
  const { values, handleChange } = useFormikContext<IBaterPontoEditForm>()

  const getPacientes = () => {
    getAllToSelectPacienteEmAntendimentoAPI()
      .then(dadosTodosClientes => setTodosPacientes(dadosTodosClientes))
  }

  useEffect(() => {
    getPacientes()
  }, [])

  const handleIncluirTodosPacientes = () => {
    ultimoPonto?.usuarioPontoProntuario.forEach(async i => {
      await pontoProfissionalProntuarioDeleteAPI(i.id)
    })

    todosPacientes.forEach(async (paciente, idx) => {
      if (ultimoPonto) {
        await pontoProfissionalProntuarioCriarAPI(paciente.prontuarioId, ultimoPonto.id)
        
        if (idx === (todosPacientes.length - 1)) loadUltimoPonto()
      }
    })
  }

  const handleExcluirTodosPacientes = () => {
    ultimoPonto?.usuarioPontoProntuario.forEach(async (paciente, idx) => {
      await pontoProfissionalProntuarioDeleteAPI(paciente.id)

      if (idx === (ultimoPonto?.usuarioPontoProntuario.length - 1)) loadUltimoPonto()
    })
  }

  useEffect(() => {
    if (!primeiroAcesso) {
      if (values.adicionarTodos) {
        handleIncluirTodosPacientes()
      } else {
        handleExcluirTodosPacientes()
      }
    } else {
      setPrimeiroAcesso(false)
    }
  }, [values.adicionarTodos])

  const handleInserirNovoPaciente = () => {
    const prontuariosIncluidos = ultimoPonto?.usuarioPontoProntuario.map(i => i.prontuario.id)
    if (prontuariosIncluidos?.includes(prontuarioSelecionadoId)) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops',
        text: 'Paciente já vinculado',
        confirmButtonColor: '#304439',
      })
      return
    }

    if (ultimoPonto && prontuarioSelecionadoId > 0) {
      pontoProfissionalProntuarioCriarAPI(prontuarioSelecionadoId, ultimoPonto.id)
        .then()
        .finally(() => loadUltimoPonto())
    }
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <Checkbox
            id='adicionarTodos'
            label='Adicionar todos pacientes na passagem de plantão'
            checked={values.adicionarTodos}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <RowStyled>
        <Col md={11}>
          <SelectSearchAsync
            label='Selecione um paciente'
            colorLabel='#fff'
            colorOption='#304439'
            color
            api={getAllToSelectPacienteEmAntendimentoAPI}
            name='paciente'
            placeholder='Pesquise pelo nome do paciente...'
            noOptionsMessage='Não foi encontrado nenhum paciente'
            strong
            desabilitado={values.adicionarTodos}
            onChange={(selected) => {
              if (selected) {
                setProntuarioSelecionadoId(selected.prontuarioId)
              }
            }}
          />
        </Col>

        {values.adicionarTodos ? (
          <Col md={1}>
          <MdFormatListBulletedAdd
            color='#6b6b6b'
            size={44}
            style={{ marginTop: '32px' }}
          />
        </Col>
        ) : (
          <Col md={1}>
            <MdFormatListBulletedAdd
              color='#fff'
              size={44}
              style={{ marginTop: '32px', cursor: 'pointer' }}
              onClick={handleInserirNovoPaciente}
            />
          </Col>
        )}
      </RowStyled>

      {ultimoPonto?.usuarioPontoProntuario && ultimoPonto.usuarioPontoProntuario.length > 0 ? (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {ultimoPonto.usuarioPontoProntuario.map((usuarioPontoProntuario, index) => (
              <tr key={index}>
                <td>{usuarioPontoProntuario.prontuario.paciente?.nome} - Prontuário: {usuarioPontoProntuario.prontuario.id}</td>
                <td style={{ width: '1px', padding: '10px' }}>
                  <UsuarioPontoProntuarioEditObservacao usuarioPontoProntuario={usuarioPontoProntuario} />
                </td>
                <td style={{ width: '1px', padding: '10px 20px 10px 5px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={26}
                    style={{ cursor: 'pointer' }}
                    onClick={() => pontoProfissionalProntuarioDeleteAPI(usuarioPontoProntuario.id)
                      .then(() => {
                        loadUltimoPonto()
                        Swal.fire({
                          icon: 'success',
                          title: 'Ok...',
                          text: 'Dado apagado com sucesso!',
                          confirmButtonColor: '#1A8C01',
                        })
                      })
                      .catch(err => console.error(err))}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      ) : (
        <Alert variant='light' style={{ marginTop: '12px' }}>
          Ainda não foram incluídos pacientes. Inclua o primeiro preenchendo os dados acima.
        </Alert>
      )}
    </>
  )
}

export { UsuarioPontoProntuarioEditForm }
export default UsuarioPontoProntuarioEditForm
