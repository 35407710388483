import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'
import styled from 'styled-components'
import parse from 'html-react-parser'

import Button from '../../../../components/Button'
import { IMensagem, marcarMensagemComoLida } from '../../../../api/mensagem'


interface IMensagemVizualizarProps {
  mensagem: IMensagem,
  atualizarMensagem: () => void
}

export const DivStyled = styled.div`
  background-color: #CECECE;
  width: 100%;
  color: #000000;
  border: 1px solid #ced4da;
  padding: 12px 14px;
  border-radius: 12px;
`;

const MensagemVizualizar = (props: IMensagemVizualizarProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false)

  return (
    <>
      <FaEye
        onClick={() => setModalAberto(true)}
        style={{ cursor: 'pointer' }}
        color={props.mensagem.lida ? 'green' : ''}
      />

      <Modal
        // style={{ background: '#fff' }}
        show={modalAberto}
        onHide={() => setModalAberto(false)}
      >
        <Modal.Header style={{ color: '#6F4200' }}>
          <Modal.Title>
            Visualizar mensagem
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#6F4200' }}>
          <div>
            Unidade
            <DivStyled>{props.mensagem.empresa.nome}</DivStyled>
          </div>

          <div style={{ margin: '12px 0' }}>
            Assunto
            <DivStyled>{props.mensagem.assunto}</DivStyled>
          </div> 
          <div>
            Mensagem
            <DivStyled>
              {parse(props.mensagem.descricao)}
            </DivStyled>
          </div>
        </Modal.Body>

        {props.mensagem.remetente === -1 && (
          <Modal.Footer>
            <Button
              disabled={props.mensagem.lida}
              onClick={() => {
                marcarMensagemComoLida(props.mensagem.id)
                .then(() => props.atualizarMensagem())
              }}  >
              Marcar como visto
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

export { MensagemVizualizar }
export default MensagemVizualizar
