import React from 'react'
import { GiFalling } from 'react-icons/gi'
import { BsBandaid, BsFillEyeFill } from 'react-icons/bs'
import { MdSick } from 'react-icons/md'

import isolamentoWhite from '../../../../../images/isolamentoWhite.png'
import isolamentoMarrom from '../../../../../images/isolamentoMarrom.png'
import { IProntuarioListagem } from '../../../../../api/prontuario'
import { RiH3 } from 'react-icons/ri'

interface ISelosProps {
  prontuario: IProntuarioListagem | null,
  colorido?: boolean,
}

const Selos = (props: ISelosProps) => {
  return (
    <>
    <div style={{marginBottom: 12}}>
        
      {props.prontuario?.riscoLesaoPressao && <BsBandaid size={36} style={{ marginRight: '4px' }} />}
      {props.prontuario?.riscoRessecamentoOcular && <BsFillEyeFill size={30} style={{ marginRight: '4px' }} />}
      {props.prontuario?.riscoAlergia && <MdSick size={36} style={{ marginRight: '4px' }} />}
      {props.prontuario?.riscoQueda && <GiFalling size={36} style={{ marginRight: '4px' }} />}
      {props.prontuario?.riscoContagio
        ? props.colorido
        ? (
            <>
            <img src={isolamentoWhite} alt='isolamento' style={{}} height={30} width={30} />
          
            </>
            )
            : (
              <>  <img src={isolamentoMarrom} alt='isolamento' style={{}} height={30} width={30} /></>
              )
              : (<></>)
            }
            </div>
    </>
  )
}

export { Selos }
export default Selos
