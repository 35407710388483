import { axiosFileAPI } from '../axiosFileAPI';
import {
  EscalaBradenEnum,
  IProntuarioIntegridadeFisicaForm,
  IProntuarioIntegridadeFisicaListagem,
 } from './types'

export const prontuarioIntegridadeFisicaCreateAPI = async (
  idProntuario: number,
  values: IProntuarioIntegridadeFisicaForm,
): Promise<void> => {
  const formData = new FormData();
  formData.append('id_prontuario', String(idProntuario));
  formData.append('observacao', values.observacao || '');
  formData.append('dreno_front', values.dreno ? 'true' : 'false');
  formData.append('drenagem_ml', values.drenagemMl || '');
  formData.append('local', values.local || '');
  formData.append('data_insercao_front', values.dataInsercao ? JSON.stringify(values.dataInsercao) : '');
  formData.append('escala_braden_front', values.escalaBraden ? values.escalaBraden : 'null');
  formData.append('percentual_sensorial', values.percentualSensorial || '');
  formData.append('umidade', values.umidade || '');
  formData.append('atividade', values.atividade || '');
  formData.append('mobilidade', values.mobilidade || '');
  formData.append('nutricao', values.nutricao || '');
  formData.append('ficcao', values.ficcao || ''); 
  formData.append('lesoes_front', JSON.stringify(values.lesoes.map(i => ({
    grau: i.grau,
    id_tipo_lesao: i.idTipoLesao,
    avaliacao: {
      avaliacao: i.avaliacao.avaliacao,
      tamanho_aproximado: i.avaliacao.tamanhoAproximado,
      aspecto: i.avaliacao.aspecto,
      aspecto_outro: i.avaliacao.aspectoOutro,
      quantidade: i.avaliacao.quantidade,
      odor: i.avaliacao.odor,
      aspecto_borda: i.avaliacao.aspectoBorda,
      aspecto_leito: i.avaliacao.aspectoLeito,
      sinais_flogisticos: i.avaliacao.sinaisFlogisticos,
      higiene_lesao: i.avaliacao.higieneLesao,
      higiene_lesao_outro: i.avaliacao.higieneLesaoOutro,
      cobertura_primaria: i.avaliacao.coberturaPrimaria,
      cobertura_secundaria: i.avaliacao.coberturaSecundaria,
      fotos: i.avaliacao.fotos.map(f => ({
        foto: f.foto,
        foto_endereco: f.fotoEndereco
      }))
    }
  })))); 
  values.lesoes.forEach((ava, index) => {
    ava.avaliacao.fotos.forEach(f => {
      if (f.foto) {
        formData.append('files', f.foto)
        // formData.append('avaliacoes_front', JSON.stringify({nome_arquivo: ava.fotoEndereco, avaliacao: ava.avaliacao}))
      }
    })
  })

  if(values.idTipoPele != null){
    if(values.idTipoPele != -1)
      formData.append('id_tipo_pele', values.idTipoPele ? String(values.idTipoPele) : '');
    else{ 
      formData.append('id_tipo_pele', '');
    }
  } 
  else{ 
    formData.append('id_tipo_pele', '');
  }
  formData.append('avaliado_front', values.avaliado ? JSON.stringify(values.avaliado) : '');
  formData.append('alterado_front', values.alterado ? 'true' : 'false');
  console.log(JSON.stringify(formData))
  
  const bla = await axiosFileAPI.post(
      'prontuarioaintegridadefisica',
      formData,
  ) 
}

export type { IProntuarioIntegridadeFisicaForm, IProntuarioIntegridadeFisicaListagem }
export { EscalaBradenEnum }