import { IRespiracaoListagem } from "../../cadastro/respiracoesAPI";

export enum SecrecaoTipoEnum {
  ESBRANQUEADA = 'ESBRANQUEADA',
  AMARELADA = 'AMARELADA',
  ESVERDEADA = 'ESVERDEADA',
  SANQUINOLENTA = 'SANQUINOLENTA',
  OUTROS = 'OUTROS',
}

export enum TosseTipoEnum {
  SECA = 'SECA',
  CHEIA_C_EXPECTORACAO = 'CHEIA_C_EXPECTORACAO',
  CHEIA_S_EXPECTORACAO = 'CHEIA_S_EXPECTORACAO',
}

export interface IProntuarioFuncaoRespiratoriaListagem {
  id: number;
  co2Ambiente: boolean | null;
  cateterNasal: boolean | null;
  outroDispositivo: string | null;
  cateterNasalLmin: number | null;
  mv: boolean | null;
  mvPerc: number | null;
  vni: boolean | null;
  vniTipo: string | null;
  auscutaPulmonar: string | null;
  tot: boolean | null;
  totNumero: number | null;
  totDias: number | null;
  mnrLmin: number | null;
  secrecao: boolean | null;
  secrecaoTipo: SecrecaoTipoEnum | null;
  tqt: boolean | null;
  tqtNumero: number | null;
  tqtDias: number | null;
  mnr: boolean | null;
  tosse: boolean | null;
  tosseTipo: TosseTipoEnum | null;
  vmi: boolean | null;
  vmiDesmami: boolean | null;
  gasometria: boolean | null;
  ph: string | null;
  hco3: string | null;
  mviModo: string | null;
  peep: string | null;
  fio2: string | null;
  frProgramada: string | null;
  pco2: string | null;
  so2: string | null;
  po2: string | null;
  idRespiracao: number | null;
  avaliado: Date | null;
  alterado: boolean | null;
  respiracao: IRespiracaoListagem | null;
}

export interface IProntuarioFuncaoRespiratoriaListagemBackend {
  id: number;
  co2_ambiente: boolean | null;
  cateter_nasal: boolean | null;
  outro_dispositivo: string | null;
  cateter_nasal_lmin: number | null;
  mv: boolean | null;
  mv_perc: number | null;
  vni: boolean | null;
  vni_tipo: string | null;
  auscuta_pulmonar: string | null;
  tot: boolean | null;
  tot_numero: number | null;
  tot_dias: number | null;
  mnr_lmin: number | null;
  secrecao: boolean | null;
  secrecao_tipo: SecrecaoTipoEnum | null;
  tqt: boolean | null;
  tqt_numero: number | null;
  tqt_dias: number | null;
  mnr: boolean | null;
  tosse: boolean | null;
  tosse_tipo: TosseTipoEnum | null;
  vmi: boolean | null;
  vmi_desmami: boolean | null;
  gasometria: boolean | null;
  ph: string | null;
  hco3: string | null;
  mvi_modo: string | null;
  peep: string | null;
  fio2: string | null;
  fr_programada: string | null;
  pco2: string | null;
  so2: string | null;
  po2: string | null;
  id_respiracao: number | null;
  avaliado: Date | null;
  alterado: boolean | null;
  Respiracao: IRespiracaoListagem | null;
}

export interface IProntuarioFuncaoRespiratoriaForm {
  co2Ambiente: boolean | null;
  cateterNasal: boolean | null;
  outroDispositivo: string | null;
  cateterNasalLmin: number | null;
  mv: boolean | null;
  mvPerc: number | null;
  vni: boolean | null;
  vniTipo: string | null;
  auscutaPulmonar: string | null;
  tot: boolean | null;
  totNumero: number | null;
  totDias: number | null;
  mnrLmin: number | null;
  secrecao: boolean | null;
  secrecaoTipo: SecrecaoTipoEnum | null;
  tqt: boolean | null;
  tqtNumero: number | null;
  tqtDias: number | null;
  mnr: boolean | null;
  tosse: boolean | null;
  tosseTipo: TosseTipoEnum | null;
  vmi: boolean | null;
  vmiDesmami: boolean | null;
  gasometria: boolean | null;
  ph: string | null;
  hco3: string | null;
  mviModo: string | null;
  peep: string | null;
  fio2: string | null;
  frProgramada: string | null;
  pco2: string | null;
  so2: string | null;
  po2: string | null;
  idRespiracao: number | null;
  avaliado: Date | null;
  stAvaliado: boolean | null;
  alterado: boolean | null;
}