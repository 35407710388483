import { GrupoProntuarioPlanoCuidadoEnum } from "../../../../../api/prontuario/types";

export const opcoesGrupo = [
  { value: -1, label: 'Selecione...'},
  { value: GrupoProntuarioPlanoCuidadoEnum.COMUNICACAO_EDUCACAO, label: 'Comunicação/Educação para a saúde/Aprendizagem'},
  { value: GrupoProntuarioPlanoCuidadoEnum.CONTROLE_INFUSAO, label: 'Controle de infusão'},
  { value: GrupoProntuarioPlanoCuidadoEnum.CUIDADO_CORPORAL, label: 'Cuidado corporal'},
  { value: GrupoProntuarioPlanoCuidadoEnum.ELIMINACAO_FISIOLOGICA, label: 'Eliminações fisiológicas'},
  { value: GrupoProntuarioPlanoCuidadoEnum.EXAME_FISICO, label: 'Exame físico'},
  { value: GrupoProntuarioPlanoCuidadoEnum.FUNCAO_CARDIOVASCULAR, label: 'Função cardiovascular'},
  { value: GrupoProntuarioPlanoCuidadoEnum.FUNCAO_NEUROLOGICA, label: 'Função neurológica/Avaliação ocular'},
  { value: GrupoProntuarioPlanoCuidadoEnum.FUNCAO_RESPIRATORIA, label: 'Função respiratória'},
  { value: GrupoProntuarioPlanoCuidadoEnum.INTEGRIDADE_FISICA, label: 'Integridade física'},
  { value: GrupoProntuarioPlanoCuidadoEnum.NUTRICAO, label: 'Nutrição'},
  { value: GrupoProntuarioPlanoCuidadoEnum.PERCEPCAO_SENTIDOS, label: 'Percepção dos órgãos dos sentidos'},
  { value: GrupoProntuarioPlanoCuidadoEnum.REGISTRO_COMPLEMENTAR, label: 'Registro complementar'},
  { value: GrupoProntuarioPlanoCuidadoEnum.RELIGIOSIDADE_ESPIRITUALIDADE, label: 'Religiosidade'},
  { value: GrupoProntuarioPlanoCuidadoEnum.SEG_FISICA_EMOCIONAL, label: 'Segurança física e emocional'},
  { value: GrupoProntuarioPlanoCuidadoEnum.SEXUALIDADE, label: 'Sexualidade'},
  { value: GrupoProntuarioPlanoCuidadoEnum.SONO_REPOUSO, label: 'Sono/Repouso'},
  { value: GrupoProntuarioPlanoCuidadoEnum.TERAPIA_ENDOVENOSA, label: 'Terapia endovenosa'},
]

const dadosGrupo = {
  'COMUNICACAO_EDUCACAO': 'Comunicação/Educação para a saúde/Aprendizagem',
  'CONTROLE_INFUSAO': 'Controle de infusão',
  'CUIDADO_CORPORAL': 'Cuidado corporal',
  'ELIMINACAO_FISIOLOGICA': 'Eliminações fisiológicas',
  'EXAME_FISICO': 'Exame físico',
  'FUNCAO_CARDIOVASCULAR': 'Função cardiovascular',
  'FUNCAO_NEUROLOGICA': 'Função neurológica/Avaliação ocular',
  'FUNCAO_RESPIRATORIA': 'Função respiratória',
  'INTEGRIDADE_FISICA': 'Integridade física',
  'NUTRICAO': 'Nutrição',
  'PERCEPCAO_SENTIDOS': 'Percepção dos órgãos dos sentidos',
  'REGISTRO_COMPLEMENTAR': 'Registro complementar',
  'RELIGIOSIDADE_ESPIRITUALIDADE': 'Religiosidade',
  'SEG_FISICA_EMOCIONAL': 'Segurança física e emocional',
  'SEXUALIDADE': 'Sexualidade',
  'SONO_REPOUSO': 'Sono/Repouso',
  'TERAPIA_ENDOVENOSA': 'Terapia endovenosa',
}

export const parseGrupoPlanoCuidado = (grupo: GrupoProntuarioPlanoCuidadoEnum) => {
  return dadosGrupo[grupo]
}
