import React from 'react'
import { useFormikContext } from 'formik'

import Button from '../../../components/Button'
import { IPacienteForm } from './PacientesModalForm'

interface IPacientesFormBotoesProps {
  setModalAberto: (novoValor: boolean) => void,
  adicionar?: boolean
}

const PacientesFormBotoes = (props: IPacientesFormBotoesProps) => {
  const { handleSubmit } = useFormikContext<IPacienteForm>()

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Button
        variant='secondary'
        onClick={() => props.setModalAberto(false)}
      >
        Cancelar
      </Button>

      <Button
        variant='primary'
        onClick={() => handleSubmit()}
      >
        {props.adicionar ? 'Adicionar e dar entrada' : 'Dar entrada'}
      </Button>
    </div>
  )
}

export { PacientesFormBotoes }
export default PacientesFormBotoes
