import React, { CSSProperties, ChangeEventHandler, FocusEvent } from 'react'
import { CheckboxStyled } from '../styles'

interface ICheckboxProps {
  id: string,
  label: string,
  checked: boolean,
  style?: CSSProperties,
  semMargemVertical?: boolean,
  onChange: ChangeEventHandler<HTMLInputElement>,
  onBlur?: (e: FocusEvent<any, Element>) => void,
}

const Checkbox = (props: ICheckboxProps) => {
  return (
    <div style={{ marginBottom: props.semMargemVertical ? '' : '16px', display: 'flex', alignItems: 'center', marginTop: props.semMargemVertical ? '' : '28px' }}>
      <CheckboxStyled
        className="form-check-input form-control"
        style={{ ...props.style }}
        type="checkbox"
        id={props.id}
        name={props.id}
        checked={props.checked}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />

      <label className="form-check-label form-label" htmlFor={props.id} style={{ marginBottom: '0px', color: 'black'}}>
        {props.label}
      </label>
    </div>
  )
}

export default Checkbox