import { IProntuarioSonoRepousoListagem, IProntuarioSonoRepousoListagemBackend } from "../types";

export const parseProntuarioSonoRepousoBackToFront = (dadosBackend: IProntuarioSonoRepousoListagemBackend): IProntuarioSonoRepousoListagem => ({
  id: dadosBackend.id,
  sonoAlterado: dadosBackend.sono_alterado,
  motivo: dadosBackend.motivo,
  usaMedicamentoDormir: dadosBackend.usa_medicamento_dormir,
  medicamentoDormir: dadosBackend.medicamento_dormir,
  naoAplica: dadosBackend.nao_aplica,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
