import React, { useEffect, useState } from 'react'
import { PDFViewer, Document, View, Page, Text, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { isDesktop } from 'react-device-detect'
import logo from '../../../../../../images/sol_logo.png'
import logoHospital from '../../../../../../images/logohospitaltelecila.jpg'
import alergia from '../../../../../../images/alergia.png'
import isolamento from '../../../../../../images/isolamentoPreto.png'
import lesao from '../../../../../../images/lesao.png'
import ocular from '../../../../../../images/ocular.png'
import queda from '../../../../../../images/queda.png'
import { useAuth } from '../../../../../../context/auth_provider/useAuth'
import { relatorioPassagemPlantao } from '../../../../../../api/relatorios/relatoriosAPI'
import { IRelatorioPassagemPlantaoBackend } from '../../../../../../api/relatorios/types'
import Button from '../../../../../../components/Button'
import { IUser } from '../../../../../../context/auth_provider/types'
import { IBaterPontoForm } from '../../../../../../api/profissionais/pontoProfissionalAPI'

const styles = StyleSheet.create({
  dataHeader: {
    position: 'absolute',
    top: 2,
    textAlign: 'right',
    right: 5,
    fontSize: 10,
  },
  HeaderStyled: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'row'
  },
  ContainerStyled: {
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: '15 10 10',
    position: 'relative',
    margin: '20 20',
  },

  LabelStyled: {
    fontSize: 10,
    fontWeight: 400,
  },

  ValueStyled: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 8,
  },
  ImagesStyle: {
    position: "absolute",
    top: 12,
    left: 16,
    height: 50,
    width: 60,
    marginLeft: 15,
  },
  ImagesStyle2: {
    position: 'absolute',
    top: 12,
    right: 16,
    height: 50,
    width: 50,
    marginRight: 15,
  },
  Sumario: { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  SumarioStyled: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },

  DadosSumarioStyled: { 
    marginBottom: 8,
    fontSize: 11,
    fontWeight: 500,
    letterSpacing: 0,
  },

  TituloStyled: {
    position: 'absolute',
    top: -8,
    left: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 700,
  }
})

export interface IPassagemPlantaoRelatorioCamposProps {
  idsPacientes: number[],
  adicionarTodos: boolean,
  dadosPassagemPlantao?: IBaterPontoForm,
}

interface IDocumentProps {
  dadosRelatorio: IRelatorioPassagemPlantaoBackend[],
  user: IUser,
  dadosPassagemPlantao?: IBaterPontoForm,
}

const Relatorio = (props: IDocumentProps) => {
/*
  const [dados, setDados] =useState([[]])
  
  const { logout, user } = useAuth()
  // useEffect(()=>{
  //   function chunkArray(array: any, size: number) {
  //     const chunkedArr = [];
  //     for (let i = 0; i < array.length; i += size) {
  //         chunkedArr.push(array.slice(i, i + size));
  //     }
  //     return chunkedArr;
  // }
  // console.log("Antes:________________________________________________________")
  // console.log(props.dadosRelatorio)
  // console.log("Depois:________________________________________________________")
  // console.log(setDados(chunkArray(props.dadosRelatorio, 2)))
  // setDados(chunkArray(props.dadosRelatorio, 2))
  // // setDados('')
  // },[])
*/  

  const getObservacao = (idPaciente: number, obsBanco: string | null) => {
    if (obsBanco) {
      return obsBanco
    }

    const paciente = props.dadosPassagemPlantao?.usuarioPontoProntuario.find(item => item.pacienteId === idPaciente)
    //console.log('paciente', paciente)
    //console.log('paciente', paciente)
    return paciente?.observacoes || ''
  }

  return (
    <Document>
      <Page size='A4' style={{ paddingBottom: '50px' }}>
        <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}>
            <Image src={require("../../../../../../images/solamarelo.png")} style={styles.ImagesStyle} cache />
          <Text style={styles.dataHeader}>
            {format((new Date()), 'dd/MM/yyyy hh:mm:ss')}
          </Text>
          <View style={styles.HeaderStyled}>
            <View style={styles.Sumario}>
              <Text style={styles.SumarioStyled}>PASSAGEM DE PLANTÃO - SBAR</Text>
              <Text style={styles.DadosSumarioStyled}>{props.user?.enderecoEmpresa || '-'}</Text>
              <Text style={styles.DadosSumarioStyled}>{props.user?.contatoEmpresa || '-'} </Text>
            </View>
          </View>
            <Image src={require("../../../../../../images/logohospitaltelecila.png")} style={styles.ImagesStyle2} cache />
        </View>
        {props.dadosRelatorio && props.dadosRelatorio.map((dado, index) => (
          <>
          <View style={{height: index === 0 || index %2 ? 0 :160}}> 
          </View>
            <View key={dado.paciente_nome} style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '5 5 ' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Leito
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.leito_nome || '-'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.LabelStyled}>
                    Paciente
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.paciente_nome || '-'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.LabelStyled}>
                    Idade
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.paciente_idade || '-'}
                  </Text>
                </View>
                <View>
                  <Text style={styles.LabelStyled}>
                    Admissão
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.dh_admissao || '-'}
                  </Text>
                </View> 
                <View>
                  <Text style={styles.LabelStyled}>
                    Dias internação
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.dias_internacao || '-'}
                  </Text>
                </View>
              </View> 
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',  }}>
                <View style={{ display: 'flex', flexDirection: 'row', }}>
                  <Text style={{ ...styles.LabelStyled, marginRight: 4 }}>
                    Queixa principal:
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.queixa_principal || '-'}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', }}>
                  <Text style={{ ...styles.LabelStyled, marginRight: 4 }}>
                    Sinais vitais:
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.ssvv_pa || '-'}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row',}}>

                <Text style={{ ...styles.LabelStyled, marginRight: 4 }}>
                  Escala da Dor:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0,marginRight: 4  }}>
                  {dado.escala_dor || '-'}
                </Text> 
                </View>
                <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, marginRight: 4  }}>
                  Precauções:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {dado.precaucoes || '-'}
                </Text>
                </View>
              </View>
           
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '2 5 3 5' }}>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, width: '90px' }}>
                  Risco de queda:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '40px' }}>
                  {dado.risco_queda ? 'Sim' : 'Não'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, width: '65px' }}>
                  Consciência:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '300px' }}>
                  {dado.consciente || '-'}
                </Text>
              </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '2 5 3 5' }}>
              <View style={{ display: 'flex', flexDirection: 'row',}}>
                <Text style={{ ...styles.LabelStyled, marginRight:4 }}>
                  GLASGOW/ RAMSAY/ RASS:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '350px' }}>
                  {dado.glasgow_ramsay_rass || '-'}
                </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '2 5 3 5' }}>
              <View style={{ display: 'flex', flexDirection: 'row',}}>

                <Text style={{ ...styles.LabelStyled, marginRight:4 }}>
                  Escala Braden:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '200px' }}>
                  {dado.escala_braden || '-'}
                </Text>
              </View>
                {/* <Text style={{ ...styles.LabelStyled, width: '65px' }}>
                Indicadores:
              </Text>
              <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '200px' }}>
                {dado.indicadores || '-'}
              </Text> */}
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '2 5 3 5' }}>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, marginRight:4 }}>
                  Edema:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, width: '150px' }}>
                  {dado.edema || '-'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, marginRight:4 }}>
                  Nutrição:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0,  }}>
                  {dado.nutricao || '-'}
                </Text>
              </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '2 5 0 5' }}>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled, width: '40px' }}>
                  Lesão:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0,  }}>
                  {`${dado.tipo_lesao} - ${dado.lesao_grau_local}` || '-'}
                </Text>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row',}}> 
                <Text style={{ ...styles.LabelStyled,  marginRight:4 }}>
                  Eliminações:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {dado.eliminacoes || '-'}
                </Text>
              </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', margin: '2 5 0 5' }}>
                <Text style={{ ...styles.LabelStyled,  marginRight:4 }}>
                  Antibióticos:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {dado.antibioticos || '-'}
                </Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', margin: '2 5 0 5' }}>
                <Text style={{ ...styles.LabelStyled, width: '110px' }}>
                  Controle de infusões:
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {dado.controle_infusao_descricao || '-'}
                </Text>
                <View style={{ display: 'flex', flexDirection: 'row', margin: '2 5 0 5' }}>
                  <Text style={{ ...styles.LabelStyled, width: '90px' }}>
                    Dreno e local:
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {dado.dreno ? 'Sim' : 'Não'}
                  </Text>
                </View>
              </View>
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View>
                  <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0, width: '150px' }}>
                    <Text style={styles.TituloStyled}>Riscos</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', minWidth: 80 }}>
                    {dado.risco_lesao_pressao && (
                        <Image src={require("../../../../../../images/lesao.png")} style={{ width: '15px', marginRight: '10px' }} />
                      )}

                      {dado.risco_ressecamento_ocular && (
                        <Image src={require("../../../../../../images/ocular.png")} style={{ width: '15px', marginRight: '10px',   }} />
                      )}

                      {dado.risco_alergia && (
                        <Image src={require("../../../../../../images/alergia.png")} style={{ width: '15px', marginRight: '10px' }} />
                      )}

                      {dado.risco_queda && (
                        <Image src={require("../../../../../../images/queda.png")} style={{ width: '15px', marginRight: '10px', transformOriginY: '180deg' }} />
                      )}

                      {dado.risco_contagio && (
                        <Image src={require("../../../../../../images/isolamento.png")} style={{ width: '15px', marginRight: '10px' }} />
                      )}  
                    </View>
                  </View>
                  <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0, width: '150px' }}>
                    <Text style={styles.TituloStyled}>Prontuário</Text>
                    <Text style={{ fontSize: '12px', minWidth: 80 }}>{dado.id_prontuario}</Text>
                  </View>
                </View>
                <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0, width: '360px' }}>
                  <Text style={styles.TituloStyled}>Complementar</Text>
                  <Text style={{ fontSize: '12px' }}>{dado.complemento}</Text>
                </View>
              </View>
            
            </View>

            <View style={{ ...styles.ContainerStyled, marginRight: 8, marginLeft: 8, marginBottom: 0, width: '97%' }}>
              <Text style={ styles.TituloStyled }>Observação</Text>
              <Text style={{ fontSize: '12px', minWidth: 80 }}>{getObservacao(dado.paciente_id ? dado.paciente_id : -1, dado.observacao)}</Text>
            </View>
          </>
        ))}
        <Text style={{ textAlign: 'center', marginBottom: 4 }}>
          _______________________________
        </Text>
        <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
          {props.user?.nome || 'SEM NOME'}
        </Text>
        <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
          Assinatura/Carimbo
        </Text>
      </Page>
    </Document>
  )
}

const PassagemPlantaoRelatorioCampos = (props: IPassagemPlantaoRelatorioCamposProps) => {
  console.log(JSON.stringify(props))
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorioPassagemPlantaoBackend[]>([])
  const { user } = useAuth();
  const getDadosRelatorio = () => {
    relatorioPassagemPlantao(props.idsPacientes, props.adicionarTodos)
      .then(resultado => setDadosRelatorio(resultado))
      .catch(console.error)
  }

  useEffect(() => {
    getDadosRelatorio()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idsPacientes])

  return (
    <>
      <Button variant='new' style={{ marginBottom: '16px' }}>
        <PDFDownloadLink
          document={
            <Relatorio dadosRelatorio={dadosRelatorio} user={user} dadosPassagemPlantao={props.dadosPassagemPlantao} />
          }
          fileName="rel-passagem-plantao.pdf"
          style={{ textDecoration: 'none', color: '#fff' }}
        >
          {({ loading }) =>
            loading ? 'Gerando seu documento...' : 'Fazer download do relatório de passagem de plantão!'
          }
        </PDFDownloadLink>
      </Button>
      {isDesktop && (
        <PDFViewer width='100%' height='80%' showToolbar>
          <Relatorio dadosRelatorio={dadosRelatorio} user={user} dadosPassagemPlantao={props.dadosPassagemPlantao} />
        </PDFViewer>
      )}
    </>
  )
}

export { PassagemPlantaoRelatorioCampos }
export default PassagemPlantaoRelatorioCampos
