import { AxiosResponse } from 'axios'

import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { IProntuarioDadosAdmissaoForm, IProntuarioListagem, IProntuarioListagemBackend } from './types';
import { parseProntuarioBackToFront } from './parser';

export const prontuarioListarAPI = async (): Promise<IProntuarioListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IProntuarioListagemBackend[]> = await axiosAPI.get(
      'prontuario',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data.map(dado => parseProntuarioBackToFront(dado))
}

export const prontuarioGetByIdAPI = async (id: number): Promise<IProntuarioListagem> => {
  const response: AxiosResponse<IProntuarioListagemBackend> = await axiosAPI.get(
      'prontuario/listbyid',
      { params: { id } },
  )

  return parseProntuarioBackToFront(response.data)
}

export const getAllToSelectProntuarioAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IProntuarioListagem[]> = await axiosAPI.get(
      'prontuario',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nomeProntuario || '', value: item.id}
    })
  }

  return response.data
}

export const prontuarioCreateAPI = async (pacienteId: number): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'prontuario',
      {
        id_empresa: user.idEmpresa,
        id_paciente: pacienteId
      },
  )
}

export const prontuarioReinternacaoAPI = async (pacienteId: number): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'prontuario/reinternacao',
      {
        id_empresa: user.idEmpresa,
        id_paciente: pacienteId
      },
  )
}

export const prontuarioUpdateAPI = async (
    id: number,
    values: IProntuarioDadosAdmissaoForm,
): Promise<void> => {
  await axiosAPI.put(
      'prontuario/edit',
      {
        id,
        dh_admissao: values.dhAdmissao,
        cli_medica: values.cliMedica,
        cli_cirurgica: values.cliCirurgica,
        uti: values.uti,
        proc_residencia: values.procResidencia,
        proc_rua: values.procRua,
        proc_serv_saude: values.procServSaude,
        proc_setor: values.procSetor,
        proc_setor_nome: values.procSetorNome,
        isol_protetor: values.isolProtetor,
        isol_respiratorio: values.isolRespiratorio,
        isol_contato: values.isolContato,
        isol_vigilancia: values.isolVigilancia,
        risco_lesao_pressao: values.riscoLesaoPressao,
        risco_ressecamento_ocular: values.riscoRessecamentoOcular,
        risco_alergia: values.riscoAlergia,
        risco_queda: values.riscoQueda,
        queixa_principal: values.queixaPrincipal,
        comorbidades: values.comorbidades,
        medicacao_uso: values.medicacaoUso,
        id_leito: values.idLeito,
        nome_prontuario: values.nomeProntuario,
      },
  )
}

export enum MotivoAltaEnum {
  OBITO = 'OBITO',
  ALTA_HOSPITAL_EXTERNO = 'ALTA_HOSPITAL_EXTERNO',
  ALTA_CLINICA_CIRURGICA = 'ALTA_CLINICA_CIRURGICA',
  ALTA_CLINICA_MEDICA = 'ALTA_CLINICA_MEDICA'
}

export const prontuarioAltaAPI = async (
  id: number,
  observacao: string,
  motivoAlta: MotivoAltaEnum | null,
  hospitalExterno: string | null
): Promise<void> => {
  await axiosAPI.put(
    'prontuario/edit',
    {
      id,
      dh_saida: new Date(),
      observacao_alta: observacao,
      motivo_alta: motivoAlta,
      hospital_externo: hospitalExterno
    },
)
}

export const prontuarioDesfazerAltaAPI = async (
  id: number,
): Promise<void> => {
  await axiosAPI.put(
    'prontuario/desfazersaida',
    {
      id
    },
)
}

export type { IProntuarioListagem, IProntuarioDadosAdmissaoForm }