import { useState } from 'react'
import { RiQuestionAnswerLine } from 'react-icons/ri'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import { FiSend } from 'react-icons/fi'
import Swal from 'sweetalert2'

import Button from '../../../../components/Button'
import { MensagemFormCampos } from './MensagemFormCampos'
import { IMensagem, IMensagemForm, mensagemEnviar } from '../../../../api/mensagem'

interface IMensagemFormrProps {
  mensagem?: IMensagem,
  atualizarMensagem: () => void,
  responder?: boolean,
}

const MensagemForm = (props: IMensagemFormrProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false)

  const getAssunto = () => {
    return `Re: ${props.mensagem?.assunto}`
  }

  const getDescricao = () => { 
    return `
      <div>
        ---------------------------------------------------------
        <div style={{ margin-left: '30px'}}>
          ${props.mensagem?.descricao}
        </div>
        ---------------------------------------------------------
      </div>
      <br />
    `
  }

  const initialValues: IMensagemForm = {
    assunto: props.responder ? getAssunto() : null,
    descricao: props.responder ? getDescricao() : null,
    tipoMensagem: props.responder && props.mensagem?.problema ? 'problema' : 'sugestao',
  }

  const handleSubmit = (values: IMensagemForm) => {
    mensagemEnviar(values).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Ok...',
        text: 'Mensagem enviada com sucesso!',
        confirmButtonColor: '#1A8C01',
      })
      props.atualizarMensagem()
      setModalAberto(false)
    })
    .catch((err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.error,
      })
    })
  }

  return (
    <>
      {props.responder ? (
        <RiQuestionAnswerLine
          onClick={() => setModalAberto(true)}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <div style={{ width: '100%', textAlign: 'end'}}>
          <Button onClick={() => setModalAberto(true)} style={{background: "#24563b"}}>
            <>
              <FiSend /> Nova mensagem
            </>
          </Button>
        </div>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Modal
            show={modalAberto}
            onHide={() => setModalAberto(false)}
          >
            <Modal.Header style={{ color: '#6F4200' }}>
              <Modal.Title>
                Enviar mensagem
              </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ color: '#6F4200' }}>
              <MensagemFormCampos />
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={handleSubmit}>
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export { MensagemForm }
export default MensagemForm
