import React from 'react'
import { BiDislike, BiLike } from 'react-icons/bi'
import { BsHourglassSplit } from 'react-icons/bs'
import { MdOutlineDoNotDisturbOff } from 'react-icons/md'

interface IFeedbackPlanoCuidadoProps {
  plano: {
    feedbackLike: boolean,
    feedbackDeslike: boolean,
    feedbackOlho: boolean,
    feedbackLupa: boolean,
  }
}

const FeedbackPlanoCuidado = (props: IFeedbackPlanoCuidadoProps) => {
  return (
    <div>
      <BiLike
        size={26}
        style={{ marginRight: '14px', cursor: 'pointer', color: `${props.plano.feedbackLike ? '#fff' : '#8b8080'}` }}
      />

      <BiDislike
        size={26}
        style={{ marginRight: '14px', cursor: 'pointer', color: `${props.plano.feedbackDeslike ? '#fff' : '#8b8080'}` }}
      />

      <MdOutlineDoNotDisturbOff
        size={26}
        style={{ marginRight: '14px', cursor: 'pointer', color: `${props.plano.feedbackOlho ? '#fff' : '#8b8080'}` }}
      />

      <BsHourglassSplit
        size={26}
        style={{ cursor: 'pointer', color: `${props.plano.feedbackLupa ? '#fff' : '#8b8080'}` }}
      />
    </div>
  )
}

export { FeedbackPlanoCuidado }
export default FeedbackPlanoCuidado
