import React, { FormEvent, useState } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'

import { IProntuarioTerapiaEndovenosaForm, ITerapiaEndovenosaATB } from '../../../../../../api/prontuario/types'
import { InputStyled, TableStyled } from '../../../../../../components/styles'
import { HiOutlineTrash } from 'react-icons/hi'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`

const AtbQualQtdDias = () => {
  const [atb, setAtb] = useState<ITerapiaEndovenosaATB>({
    id: Math.random() * -1,
    descricao: null,
    dias: null
  })

  const { values, setFieldValue } = useFormikContext<IProntuarioTerapiaEndovenosaForm>()

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const name: string = e.currentTarget.getAttribute('name') as string;
    const value = e.currentTarget.value;
    setAtb({...atb, [name]: value});
  };

  const handleInserirAtb = () => {
    if (atb.descricao && atb.dias) {
      const novoValorEdemasLD: ITerapiaEndovenosaATB[] = values.terapiaEndovenosaATB
      novoValorEdemasLD.push(atb)
  
      setFieldValue('terapiaEndovenosaATB', novoValorEdemasLD, false)
      setAtb({
        id: Math.random() * -1,
        descricao: null,
        dias: null
      })
    }
  }

  const removerAtb = (id: number) => {
    const novoValorAtb: ITerapiaEndovenosaATB[] = values.terapiaEndovenosaATB.filter(i => i.id !== id)
    setFieldValue('terapiaEndovenosaATB', novoValorAtb, false)
  }

  return (
    <div>
      <RowStyled>
        <Col md={5}>
          <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
            <label className="form-check-label form-label" htmlFor='descricao'>
              ATB descrição
            </label>
            <InputStyled
              style={{ width: '100%' }}
              id='descricao'
              name='descricao'
              value={atb.descricao || ''}
              onChange={e => handleInputOnChange(e)}
            />
          </div>
        </Col>

        <Col md={5}>
          <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
            <label className="form-check-label form-label" htmlFor='dias'>
              ATB dias
            </label>
            <InputStyled
              style={{ width: '100%' }}
              id='dias'
              name='dias'
              value={atb.dias || ''}
              onChange={e => handleInputOnChange(e)}
            />
          </div>
        </Col>

        <Col md={2}>
          <MdFormatListBulletedAdd
            color='#fff'
            size={44}
            style={{ marginTop: '32px', cursor: 'pointer' }}
            onClick={() => handleInserirAtb()}
          />
        </Col>
      </RowStyled>

      {values.terapiaEndovenosaATB && values.terapiaEndovenosaATB.length > 0 && (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.terapiaEndovenosaATB.map((atb, index) => (
              <tr key={index}>
                <td>{atb.descricao}</td>
                <td>{atb.dias} - dias</td>
                <td style={{ width: '1px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={22}
                    style={{ cursor: 'pointer' }}
                    onClick={() => removerAtb(atb.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      )}
    </div>
  )
}

export { AtbQualQtdDias }
export default AtbQualQtdDias
