import React from 'react';

interface PageBodyProps {
  children: JSX.Element
}

export const PageBody = ({children}: PageBodyProps) => {
  return (
    <>
      {children}
    </>
  );
};
