import { axiosAPI } from '../axiosAPI'
import { IProntuarioSegFisicaEmocionalForm, IProntuarioSegFisicaEmocionalListagem } from './types'

export const prontuarioSegFisicaEmocionalCreateAPI = async (
  idProntuario: number,
  values: IProntuarioSegFisicaEmocionalForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariosegemocional',
      {
        id_prontuario: idProntuario,
        tranquilo: values.tranquilo,
        agitado: values.agitado,
        agressivo: values.agressivo,
        ansiedade: values.ansiedade,
        medo: values.medo,
        solicitante: values.solicitante,
        nao_aplica: values.naoAplica,
        alergia: values.temAlergias,
        alergias: values.alergias,
        precaucoes: values.precaucoes,
        historico_queda: values.historicoQueda,
        diagnostico_secundario: values.diagnosticoSecundario,
        terapia_endovenosa: values.terapiaEndovenosa,
        auxilio_deambulacao: values.auxilioDeambulacao,
        marcha: values.marcha,
        estado_mental: values.estadoMental,
        acompanhamento: values.acompanhamento,
        caps: values.caps,
        diagnostico_psiquiatra: values.diagnosticoPsiquiatra,
        adesao_tratamento: values.adesaoTratamento,
        relacao_familia: values.relacaoFamilia,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioSegFisicaEmocionalForm, IProntuarioSegFisicaEmocionalListagem }
