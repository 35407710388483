import { IProntuarioTerapiaEndovenosaListagem, IProntuarioTerapiaEndovenosaListagemBackend } from "../types";

export const parseProntuarioTerapiaEndovenosaBackToFront = (
  dadosBackend: IProntuarioTerapiaEndovenosaListagemBackend
): IProntuarioTerapiaEndovenosaListagem => ({
  id: dadosBackend.id,
  antibioticos: dadosBackend.antibioticos,
  descricao: dadosBackend.descricao,
  local: dadosBackend.local,
  data: dadosBackend.data,
  bic: dadosBackend.bic,
  hidratacao: dadosBackend.hidratacao,
  atb: dadosBackend.atb,
  idDispositivo: dadosBackend.id_dispositivo,
  terapiaEndovenosaATB: dadosBackend.TerapiaEndovenosaATB,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
  dispositivo: dadosBackend.dispositivo,
})
