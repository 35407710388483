import { IPacienteProntuario, IPacienteProntuarioBackend } from "../types";

export const parsePacienteProntuarioBackToFront = (dadosBackend: IPacienteProntuarioBackend): IPacienteProntuario => ({
  dhAdmissao: dadosBackend.dh_admissao,
  dhSaida: dadosBackend.dh_saida,
  cliMedica: dadosBackend.cli_medica,
  cliCirurgica: dadosBackend.cli_cirurgica,
  uti: dadosBackend.uti,
  reinternacao: dadosBackend.reinternacao,
  procResidencia: dadosBackend.proc_residencia,
  procRua: dadosBackend.proc_rua,
  procServSaude: dadosBackend.proc_serv_saude,
  procSetor: dadosBackend.proc_setor,
  procSetorNome: dadosBackend.proc_setor_nome,
  isolProtetor: dadosBackend.isol_protetor,
  isolRespiratorio: dadosBackend.isol_respiratorio,
  isolContato: dadosBackend.isol_contato,
  isolVigilancia: dadosBackend.isol_vigilancia,
  riscoLesaoPressao: dadosBackend.risco_lesao_pressao,
  riscoRessecamentoOcular: dadosBackend.risco_ressecamento_ocular,
  riscoAlergia: dadosBackend.risco_alergia,
  riscoQueda: dadosBackend.risco_queda,
  queixaPrincipal: dadosBackend.queixa_principal,
  comorbidades: dadosBackend.comorbidades,
  medicacaoUso: dadosBackend.medicacao_uso,
  registroComplementar: dadosBackend.registro_complementar,
  idLeito: dadosBackend.id_leito,
  idDIH: dadosBackend.id_dih,
})
