import { IProntuarioCuidadoCorporalListagem, IProntuarioCuidadoCorporalListagemBackend } from "../types";

export const parseProntuarioCuidadoCorporalBackToFront = (dadosBackend: IProntuarioCuidadoCorporalListagemBackend): IProntuarioCuidadoCorporalListagem => ({
  id: dadosBackend.id,
  dependencia: dadosBackend.dependencia,
  corporalPrejudicada: dadosBackend.corporal_prejudicada,
  oralPrejudicada: dadosBackend.oral_prejudicada,
  intimaPrejudicada: dadosBackend.intima_prejudicada,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
