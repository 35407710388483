import { IProntuarioControleInfusaoListagem, IProntuarioControleInfusaoListagemBackend } from "../types";

export const parseProntuarioControleInfusaoBackToFront = (
  dadosBackend: IProntuarioControleInfusaoListagemBackend
): IProntuarioControleInfusaoListagem => ({
  id: dadosBackend.id,
  descricao: dadosBackend.descricao,
  mlh: dadosBackend.mlh,
  idTipoInfusao: dadosBackend.id_tipo_infusao,
  tipoInfusao: dadosBackend.tipo_infusao,
})
