import { useFormikContext } from "formik"
import { Col, ListGroup, Row } from "react-bootstrap"

import { DivComponentsStyled, ListGroupHeadStyled, ListGroupItemStyled } from "../../../pacientes/prontuario/components/styles"
import { Input, TextHTML } from "../../../../components"
import { IMensagemForm } from "../../../../api/mensagem"

const MensagemFormCampos = () => {
  const { values, handleBlur, handleChange } = useFormikContext<IMensagemForm>()

  return (
    <>
      <Row>
        <Col md={12}>
          <ListGroup>
            <ListGroupHeadStyled>
              Tipo da mensagem
            </ListGroupHeadStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='sugestao'
                  name='tipoMensagem'
                  value={'sugestao'}
                  checked={values.tipoMensagem === 'sugestao'}
                  onBlur={handleBlur}
                  onChange={(value) => handleChange(value)}
                />

                <label className="form-check-label form-label mb-0" htmlFor="sugestao">
                  Dúvida ou sugestão
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>

            <ListGroupItemStyled>
              <DivComponentsStyled>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='problema'
                  name='tipoMensagem'
                  value={'problema'}
                  checked={values.tipoMensagem === 'problema'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="problema">
                  Problema
                </label>
              </DivComponentsStyled>
            </ListGroupItemStyled>
          </ListGroup>
        </Col>
      </Row>

      <Row style={{ marginTop: '15px' }}>
        <Col md={12}>
          <Input
            id="assunto"
            label="Assunto"
            value={values.assunto || undefined}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <TextHTML
            id="descricao"
            label="Mensagem"
            value={values.descricao || undefined}
          />
          {/* <TextArea
            id="descricao"
            label="Mensagem"
            value={values.descricao || undefined}
            linhas={6}
            onChange={handleChange}
            onBlur={handleBlur}
          /> */}
        </Col>
      </Row>
    </>
  )
}

export { MensagemFormCampos }
export default MensagemFormCampos
