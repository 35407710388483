import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { Col, Modal, Row } from 'react-bootstrap'

import { IPacienteListagem } from '../../../api/pacientes'
import { MotivoAltaEnum, prontuarioAltaAPI, prontuarioDesfazerAltaAPI } from '../../../api/prontuario'
import Button from '../../../components/Button'
import { TextAreaStyled } from '../../../components/styles'
import { PacientesContext } from '../context'
import { DropdownItemStyled } from './PacienteAcoes'
import { PacienteAltaRelatorio } from './relatorio'

import { Input, Select } from '../../../components'

interface IPacienteAcaoAltaUnidadeProps {
  paciente: IPacienteListagem,
  liberaAlta: boolean,
}

interface IPacienteAcaoAltaUnidadeForm {
  observacao: string,
  motivoAlta: MotivoAltaEnum | null,
  hospitalExterno: string | null
}

const PacienteAcaoAltaUnidade = (props: IPacienteAcaoAltaUnidadeProps) => {
  const [modalAltaAberto, setModalAltaAberto] = useState<boolean>(false)
  const { loadPacientes, filtros } = useContext(PacientesContext);

  const initialValues: IPacienteAcaoAltaUnidadeForm = {
    observacao: '',
    motivoAlta: null,
    hospitalExterno: null
  }

  const handleSubmit = (values: IPacienteAcaoAltaUnidadeForm) => {

    props.liberaAlta ? 
      prontuarioAltaAPI(
        props.paciente.prontuario[0].id,
        values.observacao,
        values.motivoAlta,
        values.hospitalExterno
      )
        .then(() => {
          loadPacientes(filtros)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Paciente recebeu saída/alta com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
          setModalAltaAberto(false)
        })
        .catch(err => console.log(err))
      :
        prontuarioDesfazerAltaAPI(
          props.paciente.prontuario[0].id,
        )
          .then(() => {
            loadPacientes(filtros)
            Swal.fire({
              icon: 'success',
              title: 'Ok...',
              text: 'Saída/Alta do paciente desfeita com sucesso!',
              confirmButtonColor: '#1A8C01',
            })
            setModalAltaAberto(false)
          })
          .catch(err => console.log(err))
  }

  return (
    <>
      <DropdownItemStyled
        disabled={!props.paciente.prontuario[0].dhAdmissao}
        onClick={() => setModalAltaAberto(true)}
      >
        { props.liberaAlta ? 'Alta da unidade' : 'Desfazer Alta!'}
      </DropdownItemStyled>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, submitForm, resetForm }) => (
          <Modal
            show={modalAltaAberto}
            onHide={() => setModalAltaAberto(false)}
          >
            <Modal.Header>
              <Modal.Title style={{ color: '#304439' }}>
              { props.liberaAlta ? 'Alta da unidade' : 'Desfazer Alta!'} - {props.paciente.nome}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div style={{ color: '#304439', backgroundColor: '#EDE38E', padding: '12px 18px' }}>
                <Row>
                  <Col md={12}>
                    <div style={{marginBottom: '16px'}}>
                      <label className="form-check-label form-label" htmlFor='observacao'>
                        Observação
                      </label>

                      <TextAreaStyled
                        className='form-input form-control w-100'
                        id='observacao'
                        name='observacao'
                        value={values.observacao || ''}
                        rows={3}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={!props.liberaAlta}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Select
                      id="motivoAlta"
                      value={values.motivoAlta}
                      emptyLabel='Selecione...'
                      options={[
                        {label: 'Veio a óbito', value: MotivoAltaEnum.OBITO },
                        {label: 'Alta para clínica cirúrgica', value: MotivoAltaEnum.ALTA_CLINICA_CIRURGICA },
                        {label: 'Alta para clínica médica', value: MotivoAltaEnum.ALTA_CLINICA_MEDICA },
                        {label: 'Alta para hospital externo', value: MotivoAltaEnum.ALTA_HOSPITAL_EXTERNO }
                      ]}
                      label="Motivo da saida do paciente"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>

                {values.motivoAlta === MotivoAltaEnum.ALTA_HOSPITAL_EXTERNO && (
                  <Row>
                    <Col md={12}>
                      <Input
                        id="hospitalExterno"
                        value={values.hospitalExterno || ''}
                        label="Foi para qual hospital"
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  resetForm()
                  setModalAltaAberto(false)
                }}
              >
                Cancelar
              </Button>

              <PacienteAltaRelatorio
                idPaciente={props.paciente.id}
                textoOBS={values.observacao}
              />

              <Button
                style={{ marginLeft: '8px' }}
                onClick={submitForm}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export { PacienteAcaoAltaUnidade }
export default PacienteAcaoAltaUnidade
