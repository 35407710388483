import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'

import { DivAccordionStyled, DivInternaStyled } from '../styles'
import {
  RegistroComplementarCVC,
  RegistroComplementarDados,
  RegistroComplementarITU,
  RegistroComplementarPAV,
} from './components'

interface IProntuarioRegistroComplementarProps {
  accordionOpen: string[],
  setAccordionOpen: (d: string) => void
}

const ProntuarioRegistroComplementar = (props: IProntuarioRegistroComplementarProps) => {
  const [tabAtiva, setTabAtiva] = useState<string | null>('dados')
  return (
    <section style={{ marginBottom: '8px' }}>
      <DivAccordionStyled
        alterado={false}
        onClick={() => props.setAccordionOpen('registroComplementar')}
      >
        <div></div>
        <div>Registro complementar</div>
        <div style={{ cursor: 'pointer' }}>
          {props.accordionOpen.find(i => i === 'registroComplementar') ? (
            <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
          ) : (
            <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
          )}
        </div>
      </DivAccordionStyled>

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'registroComplementar') ? '' : 'none'}`}}>
        <Tabs
          activeKey={tabAtiva || 'dados'}
          id='tabs-registro-complementar'
          onSelect={(tab) => setTabAtiva(tab)}
          className='tabs-sol'
        >
          <Tab eventKey='dados' title='Dados' style={{ color: '#304439'}}>
            <RegistroComplementarDados />
          </Tab>

          <Tab eventKey='itu' title='ITU associado ao uso de SVD' style={{ color: '#304439'}}>
            <RegistroComplementarITU />
          </Tab>

          <Tab eventKey='ics' title='ICS associado ao uso CVC' style={{ color: '#304439'}}>
            <RegistroComplementarCVC />
          </Tab>

          <Tab eventKey='pav' title='PAV associado a VM' style={{ color: '#304439'}}>
            <RegistroComplementarPAV />
          </Tab>
        </Tabs>
        
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioRegistroComplementar }
export default ProntuarioRegistroComplementar
