import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { RespiracoesProvider } from './context';
import { RespiracoesContainerForm } from './RespiracoesContainerForm';

export interface IRespiracoesForm {
  nome: string,
  ativo: boolean,
}

const Respiracoes = () => {
  return (
    <RespiracoesProvider>
      <DivMainStyled>
        <DivCardStyled>
          <RespiracoesContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </RespiracoesProvider>
  )
}

export { Respiracoes }
export default Respiracoes
