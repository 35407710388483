import React, { useContext, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import { Alert, Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import Swal from 'sweetalert2'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'
import styled from 'styled-components'

import {
  IProntuarioControleInfusaoForm,
  prontuarioControleInfusaoCreateAPI,
  prontuarioControleInfusaoDeleteAPI,
} from '../../../../../api/prontuario/prontuarioControleInfusaoAPI'
import { InputStyled, TableStyled } from '../../../../../components/styles'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { SelectAsync } from '../../../../../components/select-async'
import { getAllToSelectTipoInfusaoAPI } from '../../../../../api/cadastro/tiposInfusoesAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`

interface IProntuarioControleInfusaoProps {
  accordionOpen: string[],
  setAccordionOpen: (d: string) => void,
}

const ProntuarioControleInfusao = (props: IProntuarioControleInfusaoProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.CONTROLE_INFUSAO)
  const [mensagem, setMensagem] = useState("")
  const initialValues: IProntuarioControleInfusaoForm = {
    descricao: null,
    mlh: null,
    idTipoInfusao: -1,
    nomeTipoInfusao: '',
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioControleInfusaoForm, actions: FormikHelpers<IProntuarioControleInfusaoForm>) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioControleInfusaoCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          actions.resetForm()
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Controle de infusão incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      <DivAccordionStyled
        alterado={false}
        onClick={() => props.setAccordionOpen('controleInfusao')}
      >
        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
          {planoCuidado ? (
            <FeedbackPlanoCuidado
              plano={{
                feedbackLike: planoCuidado.feedbackLike,
                feedbackDeslike: planoCuidado.feedbackDeslike,
                feedbackOlho: planoCuidado.feedbackOlho,
                feedbackLupa: planoCuidado.feedbackLupa,
              }}
            />
          ) : ( <div />)}

          <div style={{ textAlign: 'center'}}>Controle de infusão</div>

          <div style={{ cursor: 'pointer', textAlign: 'right' }}>
            {props.accordionOpen.find(i => i === 'controleInfusao') ? (
              <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
            ) : (
              <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
            )}
          </div>
        </div>
      </DivAccordionStyled>

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'controleInfusao') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, submitForm }) => (
            <>
              <RowStyled>
                <Col md={5}>
                  <SelectAsync
                    id='idTipoInfusao'
                    label='Tipo infusão'
                    emptyLabel='Selecione...'
                    styleLabel={{ color: '#fff' }}
                    value={values.idTipoInfusao || -1}
                    onChange={handleChange}
                    api={getAllToSelectTipoInfusaoAPI}
                  />
                </Col>

                <Col md={3}>
                  <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
                    <label className="form-check-label form-label" htmlFor='descricao'>
                      Descrição
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='descricao'
                      name='descricao'
                      value={values.descricao || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
                    <label className="form-check-label form-label" htmlFor='mlh'>
                      ML/h
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='mlh'
                      name='mlh'
                      value={values.mlh || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={1}>
                { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px', color:'white'}} />
                  ) : (
                    <MdFormatListBulletedAdd
                      color='#fff'
                      size={44}
                      style={{ marginTop: '42px', cursor: 'pointer' }}
                      onClick={() =>{ 
                        if (values.idTipoInfusao != -1) {
                          if (values.descricao != '' && values.descricao != null) {
                            if (values.mlh != '' && values.mlh != null) {
                              setMensagem("")
                        
                              submitForm()
                            } else {
                              setMensagem("Especifique o mililitros por hora")
                            }
                          } else {
                            setMensagem("Adicione uma descrição")
                          } 
                        }
                        else {
                          setMensagem("Selecione um tipo infusão")
                        } 
                      }} 
                    />
                  )}
                </Col>
              </RowStyled>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
              {prontuario?.prontuarioControleInfusao && prontuario?.prontuarioControleInfusao.length > 0 ? (
                <TableStyled style={{ marginTop: '6px' }}>
                  <tbody>
                    {prontuario?.prontuarioControleInfusao.map((infusao, index) => (
                      <tr key={index}>
                        <td>{infusao.tipoInfusao.nome}</td>
                        <td>{infusao.descricao}</td>
                        <td>{infusao.mlh}</td>
                        <td style={{ width: '1px' }}>
                          <HiOutlineTrash
                            color='#FD2424'
                            size={22}
                            style={{ cursor: 'pointer' }}
                            onClick={() => prontuarioControleInfusaoDeleteAPI(infusao.id)
                              .then(() => {
                                loadProntuario(prontuario.id)
                                Swal.fire({
                                  icon: 'success',
                                  title: 'Ok...',
                                  text: 'Dado apagado com sucesso!',
                                  confirmButtonColor: '#1A8C01',
                                })
                              })
                              .catch(err => console.error(err))}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableStyled>
              ) : (
                <Alert variant='light' style={{ marginTop: '12px' }}>Ainda não foram incluídas infusões. Inclua a primeira preenchendo os dados acima.</Alert>
              )}
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioControleInfusao }
export default ProntuarioControleInfusao
