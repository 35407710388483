import { IProntuarioReligiosidadeListagem, IProntuarioReligiosidadeListagemBackend } from "../types";

export const parseProntuarioReligiosidadeBackToFront = (dadosBackend: IProntuarioReligiosidadeListagemBackend): IProntuarioReligiosidadeListagem => ({
  id: dadosBackend.id,
  religiao: dadosBackend.religiao,
  sentimentoFe: dadosBackend.sentimento_fe,
  necessidadeSuporte: dadosBackend.necessidade_suporte,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
