interface IOpcoes { rota: string, titulo: string, subTitulo: string, soPercentual: boolean }
export const opcoesDashboard: IOpcoes[] = [
  {
    rota: 'diagnosticoenfermagem',
    titulo: '1. Percentual de Diagnósticos de Enfermagem',
    subTitulo: 'Percentual dos diagnósticos de enfermagem por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'intervencoesenfermagem',
    titulo: '2. Percentual de Intervenções de Enfermagem',
    subTitulo: 'Percentual das intervenções de enfermagem realizadas por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'resultadoenfermagem',
    titulo: '3. Percentual de Resultados de Enfermagem',
    subTitulo: 'Percentual de resultados de enfermagem utilizados por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'tempopermanenciauti',
    titulo: '4. Tempo de permanência na UTI',
    subTitulo: 'Média de tempo em dias de internação dos pacientes na UTI por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'quantidadealtasmotivo',
    titulo: '5. Percentual de altas',
    subTitulo: 'Percentual de altas dos pacientes na UTI por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'quantidadeobitos',
    titulo: '6. Percentual de óbitos',
    subTitulo: 'Percentual de óbtos na UTI por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'reinternacao24horas',
    titulo: '7. Percentual de reinternação em 24 horas',
    subTitulo: 'Percentual de pacientes que receberam altas mas foram reinternados em um período de até 24hs por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'utilizacaovm',
    titulo: '8. Percentual de uso de VMI',
    subTitulo: 'Percentual de pacientes que usaram ventilação mecânica invasiva na UTI por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'sucessodesmame',
    titulo: '9. Percentual de sucesso do desmame da VMI',
    subTitulo: 'Percentual de pacientes que entraram em desmame da VMI e progrediram para O2 ambiente ou outros, na avaliação do dia seguinte por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'pacientesadmitidoslpp',
    titulo: '10. Percentual de pacientes admitidos com LPP',
    subTitulo: 'Percentual de pacientes que foram admitidos na UTI com lesão por pressão por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'aparecimentolpp',
    titulo: '11. Percentual de aparecimento de LPP',
    subTitulo: 'Percentual de pacientes que desenvolveram lesão por pressão após o período mínimo de 24h de internação na UTI por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'medialpp',
    titulo: '12. Tempo para aparecimenento de LPP',
    subTitulo: 'Média de tempo em dias do aparecimento de lesão por pressão nos pacientes internados por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'efetividadeprevencaolpp',
    titulo: '13. Percentual de efetividade na prevenção de LPP',
    subTitulo: 'Percentual de pacientes que não desenvolveram lesão por pressão durante a internação por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'utilizacaocvc',
    titulo: '14. Percentual de uso de cateter venoso central',
    subTitulo: 'Percentual de pacientes que estão em uso de CVC por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'utilizacaosvd',
    titulo: '15. Percentual de uso de sonda vesical de demora',
    subTitulo: 'Percentual de pacientes que estão em uso de SVD por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'utilizacaosne',
    titulo: '16. Percentual de uso de sonda nasoenteral',
    subTitulo: 'Percentual de pacientes que estão em uso de SNE por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'riscoquedas',
    titulo: '17. Percentual de classificação do risco de quedas',
    subTitulo: 'Percentual de pacientes que apresentam risco de quedas baixo, médio e/ou alto por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'riscolpp',
    titulo: '18. Percentual de classificação do risco de LPP',
    subTitulo: 'Percentual de pacientes que apresentam risco de lesão por pressão brando, moderado e/ou severo por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'riscoressecamento',
    titulo: '19. Risco de ressecamento ocular',
    subTitulo: 'Percentual de pacientes que apresentam risco de ressecamento ocular por período avaliado.',
    soPercentual: true
  },
  {
    rota: 'taxaalergia',
    titulo: '20. Percentual de alergia',
    subTitulo: 'Percentual de pacientes que apresetam alergias por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'taxaisolamento',
    titulo: '21. Percentual de isolamento',
    subTitulo: 'Percentual de pacientes que estiveram em isolamento de contato, gotículas ou aerossol por período avaliado.',
    soPercentual: false
  },
  {
    rota: 'taxaeventosadversos',
    titulo: '22. Percentual de eventos adversos',
    subTitulo: 'Percentual de pacientes que sofrem eventos adversos por período avaliado.',
    soPercentual: false
  },
]
