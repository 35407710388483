import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { EdemasContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';


const EdemasListagem = () => {
  const { edemas, selecionado, setSelecionado } = useContext(EdemasContext);

  return (
    <>
      {edemas && edemas.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Edema.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {edemas && edemas.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {edemas && edemas.length > 0 && edemas.map((edema => (
                <tr
                  key={edema.id}
                  style={{ border: selecionado?.id === edema.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(edema)}
                >
                  <td>{edema.nome}</td>
                  <td>{edema.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { EdemasListagem }
export default EdemasListagem
