import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface IEdemaListagem {
  id: number,
  nome: string,
  ativo: boolean,
}

export const edemaListarAPI = async (): Promise<IEdemaListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IEdemaListagem[]> = await axiosAPI.get(
      'edema',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectEdemaAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IEdemaListagem[]> = await axiosAPI.get(
      'edema',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const edemaCreateAPI = async (nome: string, ativo: boolean): Promise<IEdemaListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'edema',
      { nome, ativo, id_empresa: user.idEmpresa },
  )

  return response.data
}

export const edemaUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
): Promise<IEdemaListagem[]> => {
  const response = await axiosAPI.put(
      'edema/edit',
      {id, nome, ativo},
  )

  return response.data
}

export const edemaDeleteAPI = async (id: number): Promise<IEdemaListagem[]> => {
  const response = await axiosAPI.delete('edema/delete', { params: { id } })

  return response.data
}
