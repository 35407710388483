import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { AtividadesContext } from '../context';
import { parseGrupoPlanoCuidado } from '../../../pacientes/prontuario/components/plano_cuidados/util';
import { TableContainer, TableStyled } from '../../../../components/styles';

const AtividadesListagem = () => {
  const { atividades, selecionado, setSelecionado } = useContext(AtividadesContext);

  return (
    <>
      {atividades && atividades.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua a primeira Atividade.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {atividades && atividades.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Grupo</th>
                <th>Intervenção</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {atividades && atividades.length > 0 && atividades.map((atividade => (
                <tr
                  key={atividade.id}
                  style={{ border: selecionado?.id === atividade.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(atividade)}
                >
                  <td>{atividade.descricao}</td>
                  <td>{!!atividade.grupo && atividade.grupo !== -1 ? parseGrupoPlanoCuidado(atividade.grupo) : ''}</td>
                  <td>{atividade.intervencao?.nome}</td>
                  <td>{atividade.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { AtividadesListagem }
export default AtividadesListagem
