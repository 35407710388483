import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../prontuario/types'
import { IIntervencaoListagem } from './intervencoesAPI'

export interface IAtividadeListagem {
  id: number,
  descricao: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  intervencao: IIntervencaoListagem,
}

export const atividadeListarAPI = async (): Promise<IAtividadeListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IAtividadeListagem[]> = await axiosAPI.get(
      'atividade',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectAtividadeAPI = async (grupo: GrupoProntuarioPlanoCuidadoEnum | -1, intervencao?: number): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IAtividadeListagem[]> = await axiosAPI.get(
      'atividade',
      { params: {
        id_empresa: user.idEmpresa,
        grupo: grupo !== -1 ? grupo : undefined,
        id_intervencao: intervencao && intervencao > 0 ? intervencao : undefined,
      } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.descricao, value: item.id}
    })
  }

  return response.data
}


export const atividadeCreateAPI = async (
  descricao: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  intervencao: number
): Promise<IAtividadeListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'atividade',
      {
        descricao,
        ativo,
        id_empresa: user.idEmpresa,
        grupo: grupo !== -1 ? grupo : null,
        id_intervencao: intervencao > 0 ? intervencao : null,
      },
  )

  return response.data
}

export const atividadeUpdateAPI = async (
    id: number,
    descricao: string,
    ativo: boolean,
    grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
    intervencao: number
): Promise<IAtividadeListagem[]> => {
  const response = await axiosAPI.put(
      'atividade/edit',
      {
        id,
        descricao,
        ativo, grupo: grupo !== -1 ? grupo : null,
        id_intervencao: intervencao > 0 ? intervencao : null,
      },
  )

  return response.data
}

export const atividadeDeleteAPI = async (id: number): Promise<IAtividadeListagem[]> => {
  const response = await axiosAPI.delete('atividade/delete', { params: { id } })

  return response.data
}
