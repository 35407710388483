import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IDiagnosticoListagem } from '../../../../api/cadastro/diagnosticosAPI';
import { diagnosticoListarAPI } from '../../../../api/cadastro/diagnosticosAPI';

interface TDiagnosticosContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  diagnosticos: IDiagnosticoListagem[],
  setDiagnosticos: (newDiagnosticos: IDiagnosticoListagem[]) => void,
  selecionado: IDiagnosticoListagem | null,
  setSelecionado: (novoSelecionado: IDiagnosticoListagem | null) => void,
  loadDiagnosticos: () => void
}

interface TDiagnosticosProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  diagnosticos: [],
  setDiagnosticos: () => {
    throw new Error('setDiagnosticos não implementada');
  },
  loadDiagnosticos: () => {
    throw new Error('loadDiagnosticos não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const DiagnosticosContext = createContext<TDiagnosticosContext>(defaultState);

export const DiagnosticosProvider = ({children}: TDiagnosticosProviderProps) => {
  const [diagnosticos, setDiagnosticos] = useState<IDiagnosticoListagem[]>(defaultState.diagnosticos);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IDiagnosticoListagem | null>(defaultState.selecionado)

  const loadDiagnosticos = () => {
    setCarregando(true)
    diagnosticoListarAPI()
        .then((data) => setDiagnosticos(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadDiagnosticos();
  }, []);

  const providerValues: TDiagnosticosContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    diagnosticos,
    setDiagnosticos,
    loadDiagnosticos,
  };

  return (
    <DiagnosticosContext.Provider value={providerValues}>
      {children}
    </DiagnosticosContext.Provider>
  );
};
