/* eslint-disable max-len */
import React from 'react';
import './logo3Css.css';

export const LogoNav = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="14.318mm"
      height="14.3183mm"
      version="1.1"
      viewBox="0 0 165.29 165.29"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        width: '50px',
        height: '50px',
      }}
    >
      <defs>
        <clipPath id="id0">
          <path d="M80.55 11c40.72,0 73.74,33.01 73.74,73.74 0,40.72 -33.01,73.74 -73.74,73.74 -40.72,0 -73.74,-33.01 -73.74,-73.74 0,-40.72 33.01,-73.74 73.74,-73.74zm0 11c34.65,0 62.73,28.09 62.73,62.73 0,34.65 -28.09,62.73 -62.73,62.73 -34.65,0 -62.73,-28.09 -62.73,-62.73 0,-34.65 28.09,-62.73 62.73,-62.73z"/>
        </clipPath>
        <clipPath id="id1">
          <path d="M80.55 16.22c37.85,0 68.52,30.68 68.52,68.53 0,37.84 -30.68,68.52 -68.52,68.52 -37.85,0 -68.52,-30.68 -68.52,-68.52 0,-37.85 30.68,-68.53 68.52,-68.53zm0 5.79c34.65,0 62.73,28.09 62.73,62.73 0,34.65 -28.09,62.73 -62.73,62.73 -34.65,0 -62.73,-28.09 -62.73,-62.73 0,-34.65 28.09,-62.73 62.73,-62.73z"/>
        </clipPath>
        <clipPath id="id2">
          <path d="M80.55 22.01c34.65,0 62.73,28.09 62.73,62.73 0,34.65 -28.09,62.73 -62.73,62.73 -34.65,0 -62.73,-28.09 -62.73,-62.73 0,-34.65 28.09,-62.73 62.73,-62.73z"/>
        </clipPath>
        <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="28.82" y1="84.74" x2="132.28" y2="84.74">
          <stop offset="0" style={{stopOpacity: '1', stopColor: '#4E6187'}}/>
          <stop offset="1" style={{stopOpacity: '1', stopColor: '#243963'}}/>
        </linearGradient>
      </defs>
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2110990870304">
          <path className="fil0" d="M80.55 0c46.8,0 84.74,37.94 84.74,84.74 0,22.29 -8.61,42.57 -22.69,57.7l-7.51 -8.08c11.93,-13.1 19.2,-30.51 19.2,-49.62 0,-40.72 -33.01,-73.74 -73.74,-73.74 -17.2,0 -33.03,5.89 -45.57,15.76l-7.51 -8.08c14.53,-11.69 32.99,-18.69 53.08,-18.69z"/>
          <path className="fil0" d="M128.79 148.96c-6.32,4.74 -13.37,8.6 -20.94,11.37 -8.52,3.12 -17.71,4.86 -27.3,4.96l-0.8 0.01c-21.92,0 -41.75,-8.97 -56.16,-23.43 -14.4,-14.45 -23.37,-34.38 -23.59,-56.3l-0.01 -0.82c0,-10.57 2.04,-20.66 5.74,-29.92 3.37,-8.41 8.11,-16.12 13.95,-22.85 -0.41,-0.68 -0.65,-1.47 -0.65,-2.33 0,-2.46 1.99,-4.45 4.45,-4.45 2.46,0 4.45,1.99 4.45,4.45 0,2.46 -1.99,4.45 -4.45,4.45 -0.87,0 -1.68,-0.25 -2.36,-0.68 -5.65,6.53 -10.23,14.01 -13.49,22.15 -3.61,9.01 -5.59,18.85 -5.59,29.16l0 0.8c0.21,21.37 8.96,40.8 23,54.88 14.03,14.08 33.35,22.82 54.71,22.82l0.79 0c9.34,-0.09 18.3,-1.8 26.62,-4.84 7.37,-2.7 14.24,-6.46 20.41,-11.09 -0.19,-0.47 -0.3,-0.98 -0.3,-1.52 0,-2.22 1.8,-4.03 4.03,-4.03 2.22,0 4.03,1.8 4.03,4.03 0,2.22 -1.8,4.03 -4.03,4.03 -0.95,0 -1.82,-0.33 -2.5,-0.87z"/>
          <g>
            <g style={{clipPath: 'url(#id0)'}}>
              <polygon className="fil1" points="80.55,84.74 189.53,74.68 189.82,78.7 "/>
              <polygon id="1" className="fil2" points="80.55,84.74 189.77,78.03 189.94,81.38 "/>
              <polygon id="2" className="fil3" points="80.55,84.74 189.91,80.71 189.99,84.07 "/>
              <polygon id="3" className="fil4" points="80.55,84.74 189.97,83.4 189.97,86.75 "/>
              <polygon id="4" className="fil5" points="80.55,84.74 189.97,86.08 189.89,89.44 "/>
              <polygon id="5" className="fil6" points="80.55,84.74 189.91,88.77 189.74,92.12 "/>
              <polygon id="6" className="fil7" points="80.55,84.74 189.77,91.45 189.53,94.8 "/>
              <polygon id="7" className="fil8" points="80.55,84.74 189.58,94.13 189.25,97.47 "/>
              <polygon id="8" className="fil8" points="80.55,84.74 189.31,96.8 188.9,100.13 "/>
              <polygon id="9" className="fil9" points="80.55,84.74 188.98,99.47 188.49,102.79 "/>
              <polygon id="10" className="fil10" points="80.55,84.74 188.59,102.12 188.02,105.43 "/>
              <polygon id="11" className="fil11" points="80.55,84.74 188.13,104.77 187.48,108.06 "/>
              <polygon id="12" className="fil11" points="80.55,84.74 187.61,107.41 186.87,110.68 "/>
              <polygon id="13" className="fil12" points="80.55,84.74 187.02,110.03 186.2,113.28 "/>
              <polygon id="14" className="fil13" points="80.55,84.74 186.37,112.63 185.47,115.87 "/>
              <polygon id="15" className="fil14" points="80.55,84.74 185.65,115.22 184.68,118.43 "/>
              <polygon id="16" className="fil15" points="80.55,84.74 184.87,117.79 183.82,120.98 "/>
              <polygon id="17" className="fil16" points="80.55,84.74 184.03,120.34 182.9,123.5 "/>
              <polygon id="18" className="fil17" points="80.55,84.74 183.12,122.87 181.92,126 "/>
              <polygon id="19" className="fil18" points="80.55,84.74 182.16,125.37 180.87,128.48 "/>
              <polygon id="20" className="fil19" points="80.55,84.74 181.13,127.86 179.77,130.92 "/>
              <polygon id="21" className="fil19" points="80.55,84.74 180.04,130.31 178.6,133.35 "/>
              <polygon id="22" className="fil20" points="80.55,84.74 178.89,132.74 177.38,135.74 "/>
              <polygon id="23" className="fil20" points="80.55,84.74 177.68,135.14 176.1,138.1 "/>
              <polygon id="24" className="fil21" points="80.55,84.74 176.42,137.51 174.76,140.43 "/>
              <polygon id="25" className="fil22" points="80.55,84.74 175.09,139.84 173.37,142.72 "/>
              <polygon id="26" className="fil23" points="80.55,84.74 173.71,142.15 171.92,144.98 "/>
              <polygon id="27" className="fil23" points="80.55,84.74 172.28,144.42 170.41,147.21 "/>
              <polygon id="28" className="fil24" points="80.55,84.74 170.78,146.65 168.85,149.39 "/>
              <polygon id="29" className="fil24" points="80.55,84.74 169.24,148.84 167.24,151.54 "/>
              <polygon id="30" className="fil25" points="80.55,84.74 167.64,151 165.57,153.65 "/>
              <polygon id="31" className="fil26" points="80.55,84.74 165.99,153.12 163.86,155.71 "/>
              <polygon id="32" className="fil27" points="80.55,84.74 164.28,155.19 162.09,157.74 "/>
              <polygon id="33" className="fil27" points="80.55,84.74 162.53,157.23 160.27,159.72 "/>
              <polygon id="34" className="fil28" points="80.55,84.74 160.72,159.22 158.41,161.65 "/>
              <polygon id="35" className="fil28" points="80.55,84.74 158.87,161.16 156.5,163.54 "/>
              <polygon id="36" className="fil29" points="80.55,84.74 156.97,163.06 154.54,165.38 "/>
              <polygon id="37" className="fil30" points="80.55,84.74 155.03,164.92 152.54,167.17 "/>
              <polygon id="38" className="fil31" points="80.55,84.74 153.04,166.72 150.5,168.91 "/>
              <polygon id="39" className="fil31" points="80.55,84.74 151,168.47 148.41,170.6 "/>
              <polygon id="40" className="fil32" points="80.55,84.74 148.93,170.18 146.28,172.24 "/>
              <polygon id="41" className="fil33" points="80.55,84.74 146.81,171.83 144.11,173.83 "/>
              <polygon id="42" className="fil34" points="80.55,84.74 144.65,173.43 141.91,175.36 "/>
              <polygon id="43" className="fil35" points="80.55,84.74 142.46,174.98 139.67,176.84 "/>
              <polygon id="44" className="fil36" points="80.55,84.74 140.22,176.47 137.39,178.26 "/>
              <polygon id="45" className="fil37" points="80.55,84.74 137.96,177.9 135.08,179.63 "/>
              <polygon id="46" className="fil38" points="80.55,84.74 135.65,179.29 132.73,180.94 "/>
              <polygon id="47" className="fil39" points="80.55,84.74 133.31,180.61 130.35,182.19 "/>
              <polygon id="48" className="fil39" points="80.55,84.74 130.95,181.87 127.95,183.38 "/>
              <polygon id="49" className="fil40" points="80.55,84.74 128.55,183.08 125.51,184.52 "/>
              <polygon id="50" className="fil41" points="80.55,84.74 126.12,184.23 123.05,185.59 "/>
              <polygon id="51" className="fil42" points="80.55,84.74 123.66,185.32 120.56,186.6 "/>
              <polygon id="52" className="fil42" points="80.55,84.74 121.18,186.35 118.05,187.56 "/>
              <polygon id="53" className="fil43" points="80.55,84.74 118.68,187.31 115.52,188.44 "/>
              <polygon id="54" className="fil44" points="80.55,84.74 116.15,188.22 112.96,189.27 "/>
              <polygon id="55" className="fil45" points="80.55,84.74 113.6,189.06 110.38,190.04 "/>
              <polygon id="56" className="fil46" points="80.55,84.74 111.03,189.84 107.79,190.74 "/>
              <polygon id="57" className="fil47" points="80.55,84.74 108.44,190.56 105.18,191.37 "/>
              <polygon id="58" className="fil48" points="80.55,84.74 105.83,191.21 102.56,191.94 "/>
              <polygon id="59" className="fil49" points="80.55,84.74 103.21,191.8 99.92,192.45 "/>
              <polygon id="60" className="fil50" points="80.55,84.74 100.58,192.32 97.27,192.9 "/>
              <polygon id="61" className="fil50" points="80.55,84.74 97.93,192.78 94.61,193.27 "/>
              <polygon id="62" className="fil51" points="80.55,84.74 95.28,193.18 91.94,193.59 "/>
              <polygon id="63" className="fil51" points="80.55,84.74 92.61,193.5 89.27,193.83 "/>
              <polygon id="64" className="fil52" points="80.55,84.74 89.94,193.77 86.59,194.01 "/>
              <polygon id="65" className="fil53" points="80.55,84.74 87.26,193.96 83.91,194.13 "/>
              <polygon id="66" className="fil54" points="80.55,84.74 84.58,194.1 81.22,194.18 "/>
              <polygon id="67" className="fil54" points="80.55,84.74 81.89,194.16 78.53,194.16 "/>
              <polygon id="68" className="fil55" points="80.55,84.74 79.21,194.16 75.85,194.08 "/>
              <polygon id="69" className="fil55" points="80.55,84.74 76.52,194.1 73.17,193.93 "/>
              <polygon id="70" className="fil56" points="80.55,84.74 73.84,193.96 70.49,193.72 "/>
              <polygon id="71" className="fil57" points="80.55,84.74 71.16,193.77 67.82,193.44 "/>
              <polygon id="72" className="fil58" points="80.55,84.74 68.49,193.5 65.16,193.09 "/>
              <polygon id="73" className="fil58" points="80.55,84.74 65.82,193.17 62.5,192.68 "/>
              <polygon id="74" className="fil59" points="80.55,84.74 63.17,192.78 59.86,192.21 "/>
              <polygon id="75" className="fil59" points="80.55,84.74 60.52,192.32 57.23,191.67 "/>
              <polygon id="76" className="fil60" points="80.55,84.74 57.89,191.8 54.61,191.06 "/>
              <polygon id="77" className="fil61" points="80.55,84.74 55.26,191.21 52.01,190.39 "/>
              <polygon id="78" className="fil62" points="80.55,84.74 52.66,190.56 49.42,189.66 "/>
              <polygon id="79" className="fil63" points="80.55,84.74 50.07,189.84 46.86,188.87 "/>
              <polygon id="80" className="fil64" points="80.55,84.74 47.5,189.06 44.31,188.01 "/>
              <polygon id="81" className="fil65" points="80.55,84.74 44.95,188.22 41.79,187.09 "/>
              <polygon id="82" className="fil66" points="80.55,84.74 42.42,187.31 39.29,186.1 "/>
              <polygon id="83" className="fil67" points="80.55,84.74 39.92,186.35 36.81,185.06 "/>
              <polygon id="84" className="fil67" points="80.55,84.74 37.43,185.32 34.36,183.96 "/>
              <polygon id="85" className="fil68" points="80.55,84.74 34.98,184.23 31.94,182.79 "/>
              <polygon id="86" className="fil69" points="80.55,84.74 32.55,183.08 29.55,181.57 "/>
              <polygon id="87" className="fil70" points="80.55,84.74 30.15,181.87 27.19,180.29 "/>
              <polygon id="88" className="fil70" points="80.55,84.74 27.78,180.61 24.86,178.95 "/>
              <polygon id="89" className="fil71" points="80.55,84.74 25.45,179.28 22.57,177.56 "/>
              <polygon id="90" className="fil71" points="80.55,84.74 23.14,177.9 20.31,176.11 "/>
              <polygon id="91" className="fil72" points="80.55,84.74 20.87,176.47 18.08,174.6 "/>
              <polygon id="92" className="fil73" points="80.55,84.74 18.64,174.97 15.9,173.04 "/>
              <polygon id="93" className="fil74" points="80.55,84.74 16.44,173.43 13.75,171.43 "/>
              <polygon id="94" className="fil74" points="80.55,84.74 14.29,171.83 11.64,169.76 "/>
              <polygon id="95" className="fil75" points="80.55,84.74 12.17,170.18 9.58,168.05 "/>
              <polygon id="96" className="fil75" points="80.55,84.74 10.09,168.47 7.55,166.28 "/>
              <polygon id="97" className="fil76" points="80.55,84.74 8.06,166.72 5.57,164.46 "/>
              <polygon id="98" className="fil77" points="80.55,84.74 6.07,164.91 3.64,162.6 "/>
              <polygon id="99" className="fil78" points="80.55,84.74 4.13,163.06 1.75,160.69 "/>
              <polygon id="100" className="fil79" points="80.55,84.74 2.23,161.16 -0.09,158.73 "/>
              <polygon id="101" className="fil80" points="80.55,84.74 0.38,159.22 -1.88,156.73 "/>
              <polygon id="102" className="fil81" points="80.55,84.74 -1.43,157.23 -3.62,154.69 "/>
              <polygon id="103" className="fil82" points="80.55,84.74 -3.18,155.19 -5.31,152.6 "/>
              <polygon id="104" className="fil83" points="80.55,84.74 -4.89,153.12 -6.95,150.47 "/>
              <polygon id="105" className="fil83" points="80.55,84.74 -6.54,151 -8.54,148.3 "/>
              <polygon id="106" className="fil84" points="80.55,84.74 -8.14,148.84 -10.07,146.1 "/>
              <polygon id="107" className="fil85" points="80.55,84.74 -9.69,146.65 -11.55,143.86 "/>
              <polygon id="108" className="fil86" points="80.55,84.74 -11.18,144.41 -12.97,141.58 "/>
              <polygon id="109" className="fil86" points="80.55,84.74 -12.61,142.15 -14.34,139.27 "/>
              <polygon id="110" className="fil87" points="80.55,84.74 -14,139.84 -15.65,136.92 "/>
              <polygon id="111" className="fil88" points="80.55,84.74 -15.32,137.5 -16.9,134.54 "/>
              <polygon id="112" className="fil89" points="80.55,84.74 -16.59,135.14 -18.09,132.14 "/>
              <polygon id="113" className="fil90" points="80.55,84.74 -17.79,132.74 -19.23,129.7 "/>
              <polygon id="114" className="fil91" points="80.55,84.74 -18.94,130.31 -20.3,127.24 "/>
              <polygon id="115" className="fil92" points="80.55,84.74 -20.03,127.85 -21.31,124.75 "/>
              <polygon id="116" className="fil93" points="80.55,84.74 -21.06,125.37 -22.27,122.24 "/>
              <polygon id="117" className="fil93" points="80.55,84.74 -22.02,122.87 -23.15,119.71 "/>
              <polygon id="118" className="fil93" points="80.55,84.74 -22.93,120.34 -23.98,117.15 "/>
              <polygon id="119" className="fil94" points="80.55,84.74 -23.77,117.79 -24.75,114.58 "/>
              <polygon id="120" className="fil94" points="80.55,84.74 -24.55,115.22 -25.45,111.98 "/>
              <polygon id="121" className="fil95" points="80.55,84.74 -25.27,112.63 -26.08,109.37 "/>
              <polygon id="122" className="fil96" points="80.55,84.74 -25.92,110.02 -26.65,106.75 "/>
              <polygon id="123" className="fil97" points="80.55,84.74 -26.51,107.4 -27.16,104.11 "/>
              <polygon id="124" className="fil97" points="80.55,84.74 -27.03,104.77 -27.61,101.46 "/>
              <polygon id="125" className="fil98" points="80.55,84.74 -27.49,102.12 -27.98,98.8 "/>
              <polygon id="126" className="fil98" points="80.55,84.74 -27.89,99.47 -28.3,96.13 "/>
              <polygon id="127" className="fil99" points="80.55,84.74 -28.21,96.8 -28.54,93.46 "/>
              <polygon id="128" className="fil99" points="80.55,84.74 -28.48,94.13 -28.72,90.78 "/>
              <polygon id="129" className="fil100" points="80.55,84.74 -28.67,91.45 -28.84,88.1 "/>
              <polygon id="130" className="fil100" points="80.55,84.74 -28.81,88.77 -28.89,85.41 "/>
              <polygon id="131" className="fil101" points="80.55,84.74 -28.87,86.08 -28.87,82.72 "/>
              <polygon id="132" className="fil102" points="80.55,84.74 -28.87,83.4 -28.79,80.04 "/>
              <polygon id="133" className="fil103" points="80.55,84.74 -28.81,80.71 -28.64,77.36 "/>
              <polygon id="134" className="fil103" points="80.55,84.74 -28.67,78.03 -28.43,74.68 "/>
              <polygon id="135" className="fil104" points="80.55,84.74 -28.48,75.35 -28.15,72.01 "/>
              <polygon id="136" className="fil104" points="80.55,84.74 -28.21,72.68 -27.8,69.34 "/>
              <polygon id="137" className="fil104" points="80.55,84.74 -27.89,70.01 -27.39,66.69 "/>
              <polygon id="138" className="fil105" points="80.55,84.74 -27.49,67.35 -26.92,64.05 "/>
              <polygon id="139" className="fil106" points="80.55,84.74 -27.03,64.71 -26.38,61.42 "/>
              <polygon id="140" className="fil107" points="80.55,84.74 -26.51,62.07 -25.77,58.8 "/>
              <polygon id="141" className="fil108" points="80.55,84.74 -25.92,59.45 -25.1,56.2 "/>
              <polygon id="142" className="fil109" points="80.55,84.74 -25.27,56.85 -24.37,53.61 "/>
              <polygon id="143" className="fil110" points="80.55,84.74 -24.55,54.26 -23.58,51.05 "/>
              <polygon id="144" className="fil111" points="80.55,84.74 -23.77,51.69 -22.72,48.5 "/>
              <polygon id="145" className="fil111" points="80.55,84.74 -22.93,49.14 -21.8,45.98 "/>
              <polygon id="146" className="fil112" points="80.55,84.74 -22.02,46.61 -20.82,43.48 "/>
              <polygon id="147" className="fil113" points="80.55,84.74 -21.06,44.11 -19.77,41 "/>
              <polygon id="148" className="fil114" points="80.55,84.74 -20.03,41.63 -18.67,38.56 "/>
              <polygon id="149" className="fil114" points="80.55,84.74 -18.94,39.17 -17.5,36.13 "/>
              <polygon id="150" className="fil115" points="80.55,84.74 -17.79,36.74 -16.28,33.74 "/>
              <polygon id="151" className="fil116" points="80.55,84.74 -16.58,34.34 -15,31.38 "/>
              <polygon id="152" className="fil117" points="80.55,84.74 -15.32,31.97 -13.66,29.05 "/>
              <polygon id="153" className="fil118" points="80.55,84.74 -14,29.64 -12.27,26.76 "/>
              <polygon id="154" className="fil119" points="80.55,84.74 -12.61,27.33 -10.82,24.5 "/>
              <polygon id="155" className="fil120" points="80.55,84.74 -11.18,25.06 -9.31,22.27 "/>
              <polygon id="156" className="fil121" points="80.55,84.74 -9.68,22.83 -7.75,20.09 "/>
              <polygon id="157" className="fil122" points="80.55,84.74 -8.14,20.64 -6.14,17.94 "/>
              <polygon id="158" className="fil122" points="80.55,84.74 -6.54,18.48 -4.47,15.83 "/>
              <polygon id="159" className="fil123" points="80.55,84.74 -4.89,16.36 -2.76,13.77 "/>
              <polygon id="160" className="fil123" points="80.55,84.74 -3.18,14.28 -0.99,11.74 "/>
              <polygon id="161" className="fil124" points="80.55,84.74 -1.43,12.25 0.83,9.76 "/>
              <polygon id="162" className="fil125" points="80.55,84.74 0.38,10.26 2.69,7.83 "/>
              <polygon id="163" className="fil126" points="80.55,84.74 2.23,8.32 4.6,5.94 "/>
              <polygon id="164" className="fil126" points="80.55,84.74 4.13,6.42 6.56,4.1 "/>
              <polygon id="165" className="fil127" points="80.55,84.74 6.07,4.56 8.56,2.31 "/>
              <polygon id="166" className="fil127" points="80.55,84.74 8.06,2.76 10.6,0.57 "/>
              <polygon id="167" className="fil128" points="80.55,84.74 10.1,1.01 12.69,-1.12 "/>
              <polygon id="168" className="fil129" points="80.55,84.74 12.17,-0.7 14.82,-2.76 "/>
              <polygon id="169" className="fil130" points="80.55,84.74 14.29,-2.35 16.99,-4.35 "/>
              <polygon id="170" className="fil130" points="80.55,84.74 16.45,-3.95 19.19,-5.88 "/>
              <polygon id="171" className="fil131" points="80.55,84.74 18.64,-5.5 21.43,-7.36 "/>
              <polygon id="172" className="fil132" points="80.55,84.74 20.88,-6.99 23.71,-8.78 "/>
              <polygon id="173" className="fil133" points="80.55,84.74 23.15,-8.42 26.02,-10.15 "/>
              <polygon id="174" className="fil134" points="80.55,84.74 25.45,-9.81 28.37,-11.46 "/>
              <polygon id="175" className="fil135" points="80.55,84.74 27.78,-11.13 30.75,-12.71 "/>
              <polygon id="176" className="fil136" points="80.55,84.74 30.15,-12.4 33.15,-13.91 "/>
              <polygon id="177" className="fil137" points="80.55,84.74 32.55,-13.6 35.59,-15.04 "/>
              <polygon id="178" className="fil138" points="80.55,84.74 34.98,-14.75 38.05,-16.11 "/>
              <polygon id="179" className="fil138" points="80.55,84.74 37.44,-15.84 40.54,-17.12 "/>
              <polygon id="180" className="fil139" points="80.55,84.74 39.92,-16.87 43.05,-18.08 "/>
              <polygon id="181" className="fil139" points="80.55,84.74 42.42,-17.83 45.58,-18.96 "/>
              <polygon id="182" className="fil140" points="80.55,84.74 44.95,-18.74 48.14,-19.79 "/>
              <polygon id="183" className="fil141" points="80.55,84.74 47.5,-19.58 50.71,-20.56 "/>
              <polygon id="184" className="fil142" points="80.55,84.74 50.07,-20.36 53.31,-21.26 "/>
              <polygon id="185" className="fil142" points="80.55,84.74 52.66,-21.08 55.92,-21.89 "/>
              <polygon id="186" className="fil143" points="80.55,84.74 55.27,-21.73 58.54,-22.47 "/>
              <polygon id="187" className="fil143" points="80.55,84.74 57.89,-22.32 61.18,-22.97 "/>
              <polygon id="188" className="fil144" points="80.55,84.74 60.52,-22.84 63.83,-23.42 "/>
              <polygon id="189" className="fil145" points="80.55,84.74 63.17,-23.3 66.49,-23.79 "/>
              <polygon id="190" className="fil146" points="80.55,84.74 65.82,-23.69 69.16,-24.11 "/>
              <polygon id="191" className="fil146" points="80.55,84.74 68.49,-24.02 71.83,-24.35 "/>
              <polygon id="192" className="fil147" points="80.55,84.74 71.16,-24.29 74.51,-24.53 "/>
              <polygon id="193" className="fil147" points="80.55,84.74 73.84,-24.48 77.19,-24.65 "/>
              <polygon id="194" className="fil148" points="80.55,84.74 76.52,-24.62 79.88,-24.7 "/>
              <polygon id="195" className="fil149" points="80.55,84.74 79.21,-24.68 82.57,-24.68 "/>
              <polygon id="196" className="fil150" points="80.55,84.74 81.89,-24.68 85.25,-24.6 "/>
              <polygon id="197" className="fil151" points="80.55,84.74 84.58,-24.62 87.93,-24.45 "/>
              <polygon id="198" className="fil152" points="80.55,84.74 87.26,-24.48 90.61,-24.24 "/>
              <polygon id="199" className="fil153" points="80.55,84.74 89.94,-24.29 93.28,-23.96 "/>
              <polygon id="200" className="fil154" points="80.55,84.74 92.61,-24.02 95.94,-23.61 "/>
              <polygon id="201" className="fil155" points="80.55,84.74 95.28,-23.69 98.6,-23.2 "/>
              <polygon id="202" className="fil155" points="80.55,84.74 97.93,-23.3 101.24,-22.73 "/>
              <polygon id="203" className="fil156" points="80.55,84.74 100.58,-22.84 103.87,-22.19 "/>
              <polygon id="204" className="fil157" points="80.55,84.74 103.21,-22.32 106.49,-21.58 "/>
              <polygon id="205" className="fil158" points="80.55,84.74 105.84,-21.73 109.09,-20.91 "/>
              <polygon id="206" className="fil158" points="80.55,84.74 108.44,-21.08 111.68,-20.18 "/>
              <polygon id="207" className="fil159" points="80.55,84.74 111.03,-20.36 114.24,-19.39 "/>
              <polygon id="208" className="fil160" points="80.55,84.74 113.6,-19.58 116.79,-18.53 "/>
              <polygon id="209" className="fil161" points="80.55,84.74 116.15,-18.74 119.31,-17.61 "/>
              <polygon id="210" className="fil162" points="80.55,84.74 118.68,-17.83 121.81,-16.62 "/>
              <polygon id="211" className="fil163" points="80.55,84.74 121.18,-16.87 124.29,-15.58 "/>
              <polygon id="212" className="fil164" points="80.55,84.74 123.67,-15.84 126.73,-14.48 "/>
              <polygon id="213" className="fil165" points="80.55,84.74 126.12,-14.75 129.16,-13.32 "/>
              <polygon id="214" className="fil166" points="80.55,84.74 128.55,-13.6 131.55,-12.09 "/>
              <polygon id="215" className="fil166" points="80.55,84.74 130.95,-12.4 133.91,-10.81 "/>
              <polygon id="216" className="fil167" points="80.55,84.74 133.32,-11.13 136.24,-9.47 "/>
              <polygon id="217" className="fil168" points="80.55,84.74 135.65,-9.8 138.53,-8.08 "/>
              <polygon id="218" className="fil169" points="80.55,84.74 137.96,-8.42 140.79,-6.63 "/>
              <polygon id="219" className="fil169" points="80.55,84.74 140.23,-6.99 143.02,-5.12 "/>
              <polygon id="220" className="fil170" points="80.55,84.74 142.46,-5.5 145.2,-3.56 "/>
              <polygon id="221" className="fil170" points="80.55,84.74 144.65,-3.95 147.35,-1.95 "/>
              <polygon id="222" className="fil171" points="80.55,84.74 146.81,-2.35 149.46,-0.28 "/>
              <polygon id="223" className="fil172" points="80.55,84.74 148.93,-0.7 151.52,1.43 "/>
              <polygon id="224" className="fil173" points="80.55,84.74 151.01,1.01 153.55,3.2 "/>
              <polygon id="225" className="fil173" points="80.55,84.74 153.04,2.76 155.53,5.02 "/>
              <polygon id="226" className="fil174" points="80.55,84.74 155.03,4.57 157.46,6.88 "/>
              <polygon id="227" className="fil174" points="80.55,84.74 156.97,6.42 159.35,8.79 "/>
              <polygon id="228" className="fil175" points="80.55,84.74 158.87,8.32 161.19,10.75 "/>
              <polygon id="229" className="fil176" points="80.55,84.74 160.72,10.26 162.98,12.75 "/>
              <polygon id="230" className="fil177" points="80.55,84.74 162.53,12.25 164.72,14.79 "/>
              <polygon id="231" className="fil177" points="80.55,84.74 164.28,14.29 166.41,16.88 "/>
              <polygon id="232" className="fil178" points="80.55,84.74 165.99,16.36 168.05,19.01 "/>
              <polygon id="233" className="fil178" points="80.55,84.74 167.64,18.48 169.64,21.18 "/>
              <polygon id="234" className="fil179" points="80.55,84.74 169.24,20.64 171.17,23.38 "/>
              <polygon id="235" className="fil180" points="80.55,84.74 170.79,22.83 172.65,25.62 "/>
              <polygon id="236" className="fil181" points="80.55,84.74 172.28,25.06 174.07,27.9 "/>
              <polygon id="237" className="fil182" points="80.55,84.74 173.71,27.33 175.44,30.21 "/>
              <polygon id="238" className="fil183" points="80.55,84.74 175.09,29.64 176.75,32.56 "/>
              <polygon id="239" className="fil184" points="80.55,84.74 176.42,31.98 178,34.94 "/>
              <polygon id="240" className="fil185" points="80.55,84.74 177.69,34.34 179.19,37.34 "/>
              <polygon id="241" className="fil186" points="80.55,84.74 178.89,36.74 180.33,39.78 "/>
              <polygon id="242" className="fil186" points="80.55,84.74 180.04,39.17 181.4,42.24 "/>
              <polygon id="243" className="fil187" points="80.55,84.74 181.13,41.63 182.41,44.73 "/>
              <polygon id="244" className="fil188" points="80.55,84.74 182.16,44.11 183.36,47.24 "/>
              <polygon id="245" className="fil189" points="80.55,84.74 183.12,46.61 184.25,49.77 "/>
              <polygon id="246" className="fil189" points="80.55,84.74 184.03,49.14 185.08,52.33 "/>
              <polygon id="247" className="fil190" points="80.55,84.74 184.87,51.69 185.84,54.9 "/>
              <polygon id="248" className="fil191" points="80.55,84.74 185.65,54.26 186.55,57.5 "/>
              <polygon id="249" className="fil192" points="80.55,84.74 186.37,56.85 187.18,60.11 "/>
              <polygon id="250" className="fil193" points="80.55,84.74 187.02,59.46 187.76,62.73 "/>
              <polygon id="251" className="fil194" points="80.55,84.74 187.61,62.08 188.26,65.37 "/>
              <polygon id="252" className="fil195" points="80.55,84.74 188.13,64.71 188.71,68.02 "/>
              <polygon id="253" className="fil196" points="80.55,84.74 188.59,67.36 189.08,70.68 "/>
              <polygon id="254" className="fil196" points="80.55,84.74 188.98,70.01 189.4,73.35 "/>
              <polygon id="255" className="fil1" points="80.55,84.74 189.31,72.68 189.58,75.35 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id1)'}}>
              <polygon className="fil197" points="80.55,84.74 -16.37,84.14 -16.29,80.58 "/>
              <polygon id="1" className="fil197" points="80.55,84.74 -16.3,81.17 -16.15,78.2 "/>
              <polygon id="2" className="fil197" points="80.55,84.74 -16.18,78.8 -15.96,75.83 "/>
              <polygon id="3" className="fil198" points="80.55,84.74 -16.01,76.43 -15.72,73.47 "/>
              <polygon id="4" className="fil199" points="80.55,84.74 -15.78,74.06 -15.41,71.11 "/>
              <polygon id="5" className="fil200" points="80.55,84.74 -15.48,71.7 -15.05,68.76 "/>
              <polygon id="6" className="fil200" points="80.55,84.74 -15.14,69.34 -14.63,66.41 "/>
              <polygon id="7" className="fil200" points="80.55,84.74 -14.73,67 -14.15,64.08 "/>
              <polygon id="8" className="fil200" points="80.55,84.74 -14.26,64.67 -13.61,61.77 "/>
              <polygon id="9" className="fil200" points="80.55,84.74 -13.74,62.35 -13.02,59.46 "/>
              <polygon id="10" className="fil201" points="80.55,84.74 -13.17,60.04 -12.37,57.17 "/>
              <polygon id="11" className="fil201" points="80.55,84.74 -12.53,57.75 -11.67,54.9 "/>
              <polygon id="12" className="fil201" points="80.55,84.74 -11.84,55.47 -10.91,52.65 "/>
              <polygon id="13" className="fil202" points="80.55,84.74 -11.09,53.21 -10.09,50.41 "/>
              <polygon id="14" className="fil202" points="80.55,84.74 -10.29,50.97 -9.22,48.2 "/>
              <polygon id="15" className="fil202" points="80.55,84.74 -9.44,48.75 -8.3,46.01 "/>
              <polygon id="16" className="fil203" points="80.55,84.74 -8.53,46.56 -7.32,43.84 "/>
              <polygon id="17" className="fil203" points="80.55,84.74 -7.56,44.38 -6.29,41.69 "/>
              <polygon id="18" className="fil204" points="80.55,84.74 -6.55,42.23 -5.21,39.57 "/>
              <polygon id="19" className="fil204" points="80.55,84.74 -5.48,40.11 -4.08,37.48 "/>
              <polygon id="20" className="fil204" points="80.55,84.74 -4.35,38.01 -2.89,35.42 "/>
              <polygon id="21" className="fil205" points="80.55,84.74 -3.18,35.94 -1.65,33.39 "/>
              <polygon id="22" className="fil205" points="80.55,84.74 -1.96,33.9 -0.37,31.39 "/>
              <polygon id="23" className="fil205" points="80.55,84.74 -0.69,31.89 0.97,29.42 "/>
              <polygon id="24" className="fil205" points="80.55,84.74 0.63,29.91 2.35,27.48 "/>
              <polygon id="25" className="fil206" points="80.55,84.74 2,27.97 3.77,25.58 "/>
              <polygon id="26" className="fil206" points="80.55,84.74 3.42,26.06 5.25,23.71 "/>
              <polygon id="27" className="fil207" points="80.55,84.74 4.88,24.18 6.77,21.88 "/>
              <polygon id="28" className="fil207" points="80.55,84.74 6.39,22.34 8.33,20.09 "/>
              <polygon id="29" className="fil207" points="80.55,84.74 7.95,20.54 9.94,18.34 "/>
              <polygon id="30" className="fil208" points="80.55,84.74 9.54,18.78 11.6,16.63 "/>
              <polygon id="31" className="fil208" points="80.55,84.74 11.18,17.06 13.29,14.95 "/>
              <polygon id="32" className="fil209" points="80.55,84.74 12.87,15.37 15.02,13.32 "/>
              <polygon id="33" className="fil209" points="80.55,84.74 14.59,13.73 16.79,11.74 "/>
              <polygon id="34" className="fil209" points="80.55,84.74 16.35,12.14 18.6,10.19 "/>
              <polygon id="35" className="fil209" points="80.55,84.74 18.15,10.58 20.45,8.7 "/>
              <polygon id="36" className="fil209" points="80.55,84.74 19.99,9.07 22.34,7.25 "/>
              <polygon id="37" className="fil209" points="80.55,84.74 21.87,7.61 24.25,5.84 "/>
              <polygon id="38" className="fil210" points="80.55,84.74 23.78,6.19 26.21,4.48 "/>
              <polygon id="39" className="fil211" points="80.55,84.74 25.72,4.82 28.19,3.17 "/>
              <polygon id="40" className="fil212" points="80.55,84.74 27.7,3.5 30.21,1.91 "/>
              <polygon id="41" className="fil212" points="80.55,84.74 29.71,2.23 32.26,0.7 "/>
              <polygon id="42" className="fil212" points="80.55,84.74 31.75,1.01 34.34,-0.46 "/>
              <polygon id="43" className="fil212" points="80.55,84.74 33.82,-0.17 36.44,-1.57 "/>
              <polygon id="44" className="fil212" points="80.55,84.74 35.92,-1.29 38.57,-2.62 "/>
              <polygon id="45" className="fil212" points="80.55,84.74 38.04,-2.36 40.73,-3.63 "/>
              <polygon id="46" className="fil212" points="80.55,84.74 40.19,-3.37 42.91,-4.58 "/>
              <polygon id="47" className="fil213" points="80.55,84.74 42.37,-4.34 45.11,-5.47 "/>
              <polygon id="48" className="fil214" points="80.55,84.74 44.56,-5.25 47.34,-6.32 "/>
              <polygon id="49" className="fil215" points="80.55,84.74 46.78,-6.1 49.58,-7.1 "/>
              <polygon id="50" className="fil215" points="80.55,84.74 49.02,-6.9 51.85,-7.84 "/>
              <polygon id="51" className="fil215" points="80.55,84.74 51.28,-7.65 54.13,-8.51 "/>
              <polygon id="52" className="fil215" points="80.55,84.74 53.56,-8.34 56.42,-9.13 "/>
              <polygon id="53" className="fil215" points="80.55,84.74 55.85,-8.98 58.73,-9.7 "/>
              <polygon id="54" className="fil216" points="80.55,84.74 58.16,-9.55 61.06,-10.21 "/>
              <polygon id="55" className="fil216" points="80.55,84.74 60.48,-10.07 63.39,-10.65 "/>
              <polygon id="56" className="fil216" points="80.55,84.74 62.81,-10.54 65.74,-11.05 "/>
              <polygon id="57" className="fil217" points="80.55,84.74 65.15,-10.95 68.09,-11.38 "/>
              <polygon id="58" className="fil217" points="80.55,84.74 67.51,-11.29 70.46,-11.66 "/>
              <polygon id="59" className="fil217" points="80.55,84.74 69.87,-11.59 72.83,-11.88 "/>
              <polygon id="60" className="fil218" points="80.55,84.74 72.23,-11.82 75.2,-12.04 "/>
              <polygon id="61" className="fil219" points="80.55,84.74 74.61,-11.99 77.58,-12.14 "/>
              <polygon id="62" className="fil219" points="80.55,84.74 76.98,-12.11 79.96,-12.18 "/>
              <polygon id="63" className="fil219" points="80.55,84.74 79.36,-12.17 82.33,-12.17 "/>
              <polygon id="64" className="fil219" points="80.55,84.74 81.74,-12.17 84.71,-12.1 "/>
              <polygon id="65" className="fil220" points="80.55,84.74 84.12,-12.11 87.09,-11.96 "/>
              <polygon id="66" className="fil220" points="80.55,84.74 86.49,-11.99 89.46,-11.78 "/>
              <polygon id="67" className="fil220" points="80.55,84.74 88.87,-11.82 91.82,-11.53 "/>
              <polygon id="68" className="fil220" points="80.55,84.74 91.23,-11.59 94.18,-11.22 "/>
              <polygon id="69" className="fil221" points="80.55,84.74 93.59,-11.29 96.53,-10.86 "/>
              <polygon id="70" className="fil221" points="80.55,84.74 95.95,-10.95 98.88,-10.44 "/>
              <polygon id="71" className="fil221" points="80.55,84.74 98.29,-10.54 101.21,-9.96 "/>
              <polygon id="72" className="fil222" points="80.55,84.74 100.62,-10.07 103.52,-9.42 "/>
              <polygon id="73" className="fil222" points="80.55,84.74 102.94,-9.55 105.83,-8.83 "/>
              <polygon id="74" className="fil223" points="80.55,84.74 105.25,-8.98 108.12,-8.18 "/>
              <polygon id="75" className="fil223" points="80.55,84.74 107.54,-8.34 110.39,-7.48 "/>
              <polygon id="76" className="fil224" points="80.55,84.74 109.82,-7.65 112.64,-6.72 "/>
              <polygon id="77" className="fil224" points="80.55,84.74 112.08,-6.9 114.88,-5.9 "/>
              <polygon id="78" className="fil224" points="80.55,84.74 114.32,-6.1 117.09,-5.03 "/>
              <polygon id="79" className="fil224" points="80.55,84.74 116.54,-5.25 119.28,-4.11 "/>
              <polygon id="80" className="fil224" points="80.55,84.74 118.73,-4.34 121.45,-3.13 "/>
              <polygon id="81" className="fil224" points="80.55,84.74 120.91,-3.37 123.6,-2.1 "/>
              <polygon id="82" className="fil224" points="80.55,84.74 123.06,-2.36 125.71,-1.02 "/>
              <polygon id="83" className="fil225" points="80.55,84.74 125.18,-1.29 127.81,0.12 "/>
              <polygon id="84" className="fil225" points="80.55,84.74 127.28,-0.17 129.87,1.3 "/>
              <polygon id="85" className="fil225" points="80.55,84.74 129.35,1.01 131.9,2.54 "/>
              <polygon id="86" className="fil225" points="80.55,84.74 131.39,2.23 133.9,3.82 "/>
              <polygon id="87" className="fil225" points="80.55,84.74 133.4,3.5 135.87,5.15 "/>
              <polygon id="88" className="fil225" points="80.55,84.74 135.38,4.82 137.81,6.54 "/>
              <polygon id="89" className="fil225" points="80.55,84.74 137.32,6.19 139.71,7.97 "/>
              <polygon id="90" className="fil225" points="80.55,84.74 139.23,7.61 141.58,9.44 "/>
              <polygon id="91" className="fil226" points="80.55,84.74 141.11,9.07 143.41,10.96 "/>
              <polygon id="92" className="fil227" points="80.55,84.74 142.95,10.58 145.2,12.53 "/>
              <polygon id="93" className="fil227" points="80.55,84.74 144.75,12.14 146.95,14.13 "/>
              <polygon id="94" className="fil177" points="80.55,84.74 146.51,13.73 148.66,15.78 "/>
              <polygon id="95" className="fil177" points="80.55,84.74 148.23,15.37 150.34,17.48 "/>
              <polygon id="96" className="fil177" points="80.55,84.74 149.92,17.06 151.97,19.21 "/>
              <polygon id="97" className="fil177" points="80.55,84.74 151.56,18.78 153.55,20.98 "/>
              <polygon id="98" className="fil228" points="80.55,84.74 153.15,20.54 155.1,22.79 "/>
              <polygon id="99" className="fil228" points="80.55,84.74 154.71,22.34 156.59,24.64 "/>
              <polygon id="100" className="fil228" points="80.55,84.74 156.22,24.18 158.05,26.53 "/>
              <polygon id="101" className="fil229" points="80.55,84.74 157.68,26.06 159.45,28.44 "/>
              <polygon id="102" className="fil229" points="80.55,84.74 159.1,27.97 160.81,30.4 "/>
              <polygon id="103" className="fil229" points="80.55,84.74 160.47,29.91 162.12,32.38 "/>
              <polygon id="104" className="fil229" points="80.55,84.74 161.79,31.89 163.38,34.4 "/>
              <polygon id="105" className="fil179" points="80.55,84.74 163.06,33.9 164.59,36.45 "/>
              <polygon id="106" className="fil179" points="80.55,84.74 164.28,35.94 165.75,38.53 "/>
              <polygon id="107" className="fil179" points="80.55,84.74 165.46,38.01 166.86,40.63 "/>
              <polygon id="108" className="fil179" points="80.55,84.74 166.58,40.11 167.91,42.76 "/>
              <polygon id="109" className="fil180" points="80.55,84.74 167.65,42.23 168.92,44.92 "/>
              <polygon id="110" className="fil180" points="80.55,84.74 168.66,44.38 169.87,47.1 "/>
              <polygon id="111" className="fil180" points="80.55,84.74 169.63,46.56 170.76,49.3 "/>
              <polygon id="112" className="fil230" points="80.55,84.74 170.54,48.75 171.61,51.53 "/>
              <polygon id="113" className="fil230" points="80.55,84.74 171.39,50.97 172.39,53.77 "/>
              <polygon id="114" className="fil230" points="80.55,84.74 172.19,53.21 173.13,56.04 "/>
              <polygon id="115" className="fil230" points="80.55,84.74 172.94,55.47 173.8,58.32 "/>
              <polygon id="116" className="fil182" points="80.55,84.74 173.63,57.75 174.42,60.61 "/>
              <polygon id="117" className="fil182" points="80.55,84.74 174.26,60.04 174.99,62.92 "/>
              <polygon id="118" className="fil183" points="80.55,84.74 174.84,62.35 175.49,65.25 "/>
              <polygon id="119" className="fil183" points="80.55,84.74 175.36,64.67 175.94,67.58 "/>
              <polygon id="120" className="fil184" points="80.55,84.74 175.83,67 176.34,69.93 "/>
              <polygon id="121" className="fil184" points="80.55,84.74 176.24,69.34 176.67,72.29 "/>
              <polygon id="122" className="fil184" points="80.55,84.74 176.58,71.7 176.95,74.65 "/>
              <polygon id="123" className="fil184" points="80.55,84.74 176.87,74.06 177.17,77.02 "/>
              <polygon id="124" className="fil184" points="80.55,84.74 177.11,76.43 177.33,79.39 "/>
              <polygon id="125" className="fil184" points="80.55,84.74 177.28,78.8 177.43,81.77 "/>
              <polygon id="126" className="fil184" points="80.55,84.74 177.4,81.17 177.47,84.14 "/>
              <polygon id="127" className="fil186" points="80.55,84.74 177.46,83.55 177.46,86.52 "/>
              <polygon id="128" className="fil186" points="80.55,84.74 177.46,85.93 177.39,88.9 "/>
              <polygon id="129" className="fil186" points="80.55,84.74 177.4,88.31 177.25,91.28 "/>
              <polygon id="130" className="fil186" points="80.55,84.74 177.28,90.68 177.06,93.65 "/>
              <polygon id="131" className="fil186" points="80.55,84.74 177.11,93.05 176.82,96.01 "/>
              <polygon id="132" className="fil186" points="80.55,84.74 176.87,95.42 176.51,98.37 "/>
              <polygon id="133" className="fil186" points="80.55,84.74 176.58,97.78 176.15,100.72 "/>
              <polygon id="134" className="fil187" points="80.55,84.74 176.24,100.14 175.73,103.07 "/>
              <polygon id="135" className="fil187" points="80.55,84.74 175.83,102.48 175.25,105.4 "/>
              <polygon id="136" className="fil231" points="80.55,84.74 175.36,104.81 174.71,107.71 "/>
              <polygon id="137" className="fil231" points="80.55,84.74 174.84,107.13 174.12,110.02 "/>
              <polygon id="138" className="fil188" points="80.55,84.74 174.26,109.44 173.47,112.31 "/>
              <polygon id="139" className="fil188" points="80.55,84.74 173.63,111.73 172.77,114.58 "/>
              <polygon id="140" className="fil188" points="80.55,84.74 172.94,114.01 172.01,116.83 "/>
              <polygon id="141" className="fil188" points="80.55,84.74 172.19,116.27 171.19,119.07 "/>
              <polygon id="142" className="fil189" points="80.55,84.74 171.39,118.51 170.32,121.28 "/>
              <polygon id="143" className="fil189" points="80.55,84.74 170.54,120.73 169.4,123.47 "/>
              <polygon id="144" className="fil189" points="80.55,84.74 169.63,122.92 168.42,125.64 "/>
              <polygon id="145" className="fil232" points="80.55,84.74 168.66,125.1 167.39,127.79 "/>
              <polygon id="146" className="fil232" points="80.55,84.74 167.65,127.25 166.31,129.9 "/>
              <polygon id="147" className="fil232" points="80.55,84.74 166.58,129.37 165.17,132 "/>
              <polygon id="148" className="fil232" points="80.55,84.74 165.46,131.47 163.99,134.06 "/>
              <polygon id="149" className="fil191" points="80.55,84.74 164.28,133.54 162.75,136.09 "/>
              <polygon id="150" className="fil191" points="80.55,84.74 163.06,135.58 161.47,138.09 "/>
              <polygon id="151" className="fil191" points="80.55,84.74 161.79,137.59 160.13,140.06 "/>
              <polygon id="152" className="fil191" points="80.55,84.74 160.47,139.57 158.75,142 "/>
              <polygon id="153" className="fil233" points="80.55,84.74 159.1,141.51 157.32,143.9 "/>
              <polygon id="154" className="fil233" points="80.55,84.74 157.68,143.42 155.85,145.77 "/>
              <polygon id="155" className="fil233" points="80.55,84.74 156.22,145.3 154.33,147.6 "/>
              <polygon id="156" className="fil193" points="80.55,84.74 154.71,147.14 152.76,149.39 "/>
              <polygon id="157" className="fil193" points="80.55,84.74 153.15,148.94 151.16,151.14 "/>
              <polygon id="158" className="fil193" points="80.55,84.74 151.56,150.7 149.51,152.85 "/>
              <polygon id="159" className="fil193" points="80.55,84.74 149.92,152.42 147.81,154.53 "/>
              <polygon id="160" className="fil234" points="80.55,84.74 148.23,154.1 146.08,156.16 "/>
              <polygon id="161" className="fil234" points="80.55,84.74 146.51,155.75 144.31,157.74 "/>
              <polygon id="162" className="fil235" points="80.55,84.74 144.75,157.34 142.5,159.28 "/>
              <polygon id="163" className="fil236" points="80.55,84.74 142.95,158.9 140.65,160.78 "/>
              <polygon id="164" className="fil236" points="80.55,84.74 141.11,160.41 138.77,162.23 "/>
              <polygon id="165" className="fil236" points="80.55,84.74 139.23,161.87 136.85,163.64 "/>
              <polygon id="166" className="fil236" points="80.55,84.74 137.32,163.29 134.89,165 "/>
              <polygon id="167" className="fil236" points="80.55,84.74 135.38,164.66 132.91,166.31 "/>
              <polygon id="168" className="fil236" points="80.55,84.74 133.4,165.98 130.89,167.57 "/>
              <polygon id="169" className="fil236" points="80.55,84.74 131.39,167.25 128.84,168.78 "/>
              <polygon id="170" className="fil236" points="80.55,84.74 129.35,168.47 126.76,169.94 "/>
              <polygon id="171" className="fil237" points="80.55,84.74 127.28,169.64 124.66,171.05 "/>
              <polygon id="172" className="fil237" points="80.55,84.74 125.18,170.77 122.53,172.1 "/>
              <polygon id="173" className="fil237" points="80.55,84.74 123.06,171.84 120.37,173.11 "/>
              <polygon id="174" className="fil237" points="80.55,84.74 120.91,172.85 118.19,174.06 "/>
              <polygon id="175" className="fil237" points="80.55,84.74 118.73,173.82 115.99,174.95 "/>
              <polygon id="176" className="fil237" points="80.55,84.74 116.54,174.73 113.76,175.8 "/>
              <polygon id="177" className="fil237" points="80.55,84.74 114.32,175.58 111.52,176.58 "/>
              <polygon id="178" className="fil238" points="80.55,84.74 112.08,176.38 109.25,177.32 "/>
              <polygon id="179" className="fil238" points="80.55,84.74 109.82,177.13 106.97,177.99 "/>
              <polygon id="180" className="fil239" points="80.55,84.74 107.54,177.82 104.68,178.61 "/>
              <polygon id="181" className="fil239" points="80.55,84.74 105.25,178.46 102.37,179.18 "/>
              <polygon id="182" className="fil240" points="80.55,84.74 102.94,179.03 100.04,179.68 "/>
              <polygon id="183" className="fil240" points="80.55,84.74 100.62,179.55 97.71,180.13 "/>
              <polygon id="184" className="fil240" points="80.55,84.74 98.29,180.02 95.36,180.53 "/>
              <polygon id="185" className="fil241" points="80.55,84.74 95.95,180.42 93,180.86 "/>
              <polygon id="186" className="fil241" points="80.55,84.74 93.59,180.77 90.64,181.14 "/>
              <polygon id="187" className="fil241" points="80.55,84.74 91.23,181.07 88.27,181.36 "/>
              <polygon id="188" className="fil241" points="80.55,84.74 88.87,181.3 85.9,181.52 "/>
              <polygon id="189" className="fil242" points="80.55,84.74 86.49,181.47 83.52,181.62 "/>
              <polygon id="190" className="fil242" points="80.55,84.74 84.12,181.59 81.14,181.66 "/>
              <polygon id="191" className="fil242" points="80.55,84.74 81.74,181.65 78.77,181.65 "/>
              <polygon id="192" className="fil242" points="80.55,84.74 79.36,181.65 76.39,181.58 "/>
              <polygon id="193" className="fil243" points="80.55,84.74 76.98,181.59 74.01,181.44 "/>
              <polygon id="194" className="fil244" points="80.55,84.74 74.61,181.47 71.64,181.25 "/>
              <polygon id="195" className="fil244" points="80.55,84.74 72.23,181.3 69.28,181.01 "/>
              <polygon id="196" className="fil244" points="80.55,84.74 69.87,181.07 66.92,180.7 "/>
              <polygon id="197" className="fil245" points="80.55,84.74 67.51,180.77 64.57,180.34 "/>
              <polygon id="198" className="fil245" points="80.55,84.74 65.15,180.42 62.22,179.92 "/>
              <polygon id="199" className="fil245" points="80.55,84.74 62.81,180.02 59.89,179.44 "/>
              <polygon id="200" className="fil246" points="80.55,84.74 60.48,179.55 57.58,178.9 "/>
              <polygon id="201" className="fil246" points="80.55,84.74 58.16,179.03 55.27,178.31 "/>
              <polygon id="202" className="fil246" points="80.55,84.74 55.85,178.46 52.98,177.66 "/>
              <polygon id="203" className="fil246" points="80.55,84.74 53.56,177.82 50.71,176.96 "/>
              <polygon id="204" className="fil246" points="80.55,84.74 51.28,177.13 48.46,176.2 "/>
              <polygon id="205" className="fil247" points="80.55,84.74 49.02,176.38 46.22,175.38 "/>
              <polygon id="206" className="fil248" points="80.55,84.74 46.78,175.58 44.01,174.51 "/>
              <polygon id="207" className="fil249" points="80.55,84.74 44.56,174.73 41.82,173.59 "/>
              <polygon id="208" className="fil249" points="80.55,84.74 42.37,173.82 39.65,172.61 "/>
              <polygon id="209" className="fil249" points="80.55,84.74 40.19,172.85 37.5,171.58 "/>
              <polygon id="210" className="fil249" points="80.55,84.74 38.04,171.84 35.39,170.5 "/>
              <polygon id="211" className="fil249" points="80.55,84.74 35.92,170.77 33.29,169.36 "/>
              <polygon id="212" className="fil249" points="80.55,84.74 33.82,169.64 31.23,168.18 "/>
              <polygon id="213" className="fil249" points="80.55,84.74 31.75,168.47 29.2,166.94 "/>
              <polygon id="214" className="fil250" points="80.55,84.74 29.71,167.25 27.2,165.66 "/>
              <polygon id="215" className="fil251" points="80.55,84.74 27.7,165.98 25.23,164.33 "/>
              <polygon id="216" className="fil252" points="80.55,84.74 25.72,164.66 23.29,162.94 "/>
              <polygon id="217" className="fil252" points="80.55,84.74 23.78,163.29 21.39,161.51 "/>
              <polygon id="218" className="fil252" points="80.55,84.74 21.87,161.87 19.52,160.04 "/>
              <polygon id="219" className="fil252" points="80.55,84.74 19.99,160.41 17.69,158.52 "/>
              <polygon id="220" className="fil252" points="80.55,84.74 18.15,158.9 15.9,156.95 "/>
              <polygon id="221" className="fil252" points="80.55,84.74 16.35,157.34 14.15,155.35 "/>
              <polygon id="222" className="fil253" points="80.55,84.74 14.59,155.75 12.44,153.7 "/>
              <polygon id="223" className="fil253" points="80.55,84.74 12.87,154.1 10.76,152 "/>
              <polygon id="224" className="fil254" points="80.55,84.74 11.18,152.42 9.13,150.27 "/>
              <polygon id="225" className="fil254" points="80.55,84.74 9.54,150.7 7.55,148.5 "/>
              <polygon id="226" className="fil254" points="80.55,84.74 7.95,148.94 6,146.69 "/>
              <polygon id="227" className="fil255" points="80.55,84.74 6.39,147.14 4.51,144.84 "/>
              <polygon id="228" className="fil255" points="80.55,84.74 4.88,145.3 3.05,142.95 "/>
              <polygon id="229" className="fil256" points="80.55,84.74 3.42,143.42 1.65,141.03 "/>
              <polygon id="230" className="fil256" points="80.55,84.74 2,141.51 0.29,139.08 "/>
              <polygon id="231" className="fil256" points="80.55,84.74 0.63,139.57 -1.02,137.1 "/>
              <polygon id="232" className="fil256" points="80.55,84.74 -0.69,137.59 -2.28,135.08 "/>
              <polygon id="233" className="fil257" points="80.55,84.74 -1.96,135.58 -3.49,133.03 "/>
              <polygon id="234" className="fil257" points="80.55,84.74 -3.18,133.54 -4.65,130.95 "/>
              <polygon id="235" className="fil257" points="80.55,84.74 -4.35,131.47 -5.76,128.85 "/>
              <polygon id="236" className="fil258" points="80.55,84.74 -5.48,129.37 -6.81,126.72 "/>
              <polygon id="237" className="fil258" points="80.55,84.74 -6.55,127.25 -7.82,124.56 "/>
              <polygon id="238" className="fil259" points="80.55,84.74 -7.56,125.1 -8.77,122.38 "/>
              <polygon id="239" className="fil259" points="80.55,84.74 -8.53,122.92 -9.66,120.18 "/>
              <polygon id="240" className="fil259" points="80.55,84.74 -9.44,120.73 -10.51,117.95 "/>
              <polygon id="241" className="fil260" points="80.55,84.74 -10.29,118.51 -11.29,115.71 "/>
              <polygon id="242" className="fil260" points="80.55,84.74 -11.09,116.27 -12.03,113.44 "/>
              <polygon id="243" className="fil260" points="80.55,84.74 -11.84,114.01 -12.7,111.16 "/>
              <polygon id="244" className="fil261" points="80.55,84.74 -12.53,111.73 -13.32,108.87 "/>
              <polygon id="245" className="fil261" points="80.55,84.74 -13.17,109.44 -13.89,106.56 "/>
              <polygon id="246" className="fil261" points="80.55,84.74 -13.74,107.13 -14.39,104.23 "/>
              <polygon id="247" className="fil261" points="80.55,84.74 -14.26,104.81 -14.84,101.9 "/>
              <polygon id="248" className="fil261" points="80.55,84.74 -14.73,102.48 -15.24,99.55 "/>
              <polygon id="249" className="fil262" points="80.55,84.74 -15.14,100.14 -15.57,97.19 "/>
              <polygon id="250" className="fil263" points="80.55,84.74 -15.48,97.78 -15.85,94.83 "/>
              <polygon id="251" className="fil264" points="80.55,84.74 -15.78,95.42 -16.07,92.46 "/>
              <polygon id="252" className="fil264" points="80.55,84.74 -16.01,93.05 -16.23,90.09 "/>
              <polygon id="253" className="fil264" points="80.55,84.74 -16.18,90.68 -16.33,87.71 "/>
              <polygon id="254" className="fil264" points="80.55,84.74 -16.3,88.31 -16.37,85.33 "/>
              <polygon id="255" className="fil197" points="80.55,84.74 -16.36,85.93 -16.36,83.55 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id2)'}}>
              <polygon className="fil265" points="80.55,84.74 126.4,-5.57 129.69,-3.82 "/>
              <polygon id="1" className="fil266" points="80.55,84.74 129.14,-4.11 131.85,-2.59 "/>
              <polygon id="2" className="fil266" points="80.55,84.74 131.31,-2.89 133.98,-1.3 "/>
              <polygon id="3" className="fil266" points="80.55,84.74 133.44,-1.62 136.07,0.03 "/>
              <polygon id="4" className="fil266" points="80.55,84.74 135.55,-0.3 138.14,1.42 "/>
              <polygon id="5" className="fil267" points="80.55,84.74 137.62,1.08 140.16,2.86 "/>
              <polygon id="6" className="fil267" points="80.55,84.74 139.66,2.5 142.16,4.35 "/>
              <polygon id="7" className="fil267" points="80.55,84.74 141.65,3.98 144.11,5.88 "/>
              <polygon id="8" className="fil267" points="80.55,84.74 143.62,5.5 146.03,7.47 "/>
              <polygon id="9" className="fil268" points="80.55,84.74 145.54,7.08 147.9,9.1 "/>
              <polygon id="10" className="fil268" points="80.55,84.74 147.43,8.69 149.74,10.77 "/>
              <polygon id="11" className="fil268" points="80.55,84.74 149.28,10.36 151.53,12.49 "/>
              <polygon id="12" className="fil268" points="80.55,84.74 151.08,12.07 153.28,14.26 "/>
              <polygon id="13" className="fil269" points="80.55,84.74 152.84,13.82 154.99,16.06 "/>
              <polygon id="14" className="fil269" points="80.55,84.74 154.56,15.61 156.66,17.91 "/>
              <polygon id="15" className="fil269" points="80.55,84.74 156.24,17.45 158.27,19.8 "/>
              <polygon id="16" className="fil269" points="80.55,84.74 157.86,19.33 159.84,21.73 "/>
              <polygon id="17" className="fil270" points="80.55,84.74 159.45,21.25 161.37,23.69 "/>
              <polygon id="18" className="fil270" points="80.55,84.74 160.98,23.2 162.84,25.69 "/>
              <polygon id="19" className="fil270" points="80.55,84.74 162.47,25.2 164.26,27.73 "/>
              <polygon id="20" className="fil270" points="80.55,84.74 163.9,27.22 165.64,29.8 "/>
              <polygon id="21" className="fil271" points="80.55,84.74 165.29,29.29 166.96,31.91 "/>
              <polygon id="22" className="fil271" points="80.55,84.74 166.63,31.38 168.23,34.04 "/>
              <polygon id="23" className="fil271" points="80.55,84.74 167.91,33.51 169.45,36.21 "/>
              <polygon id="24" className="fil271" points="80.55,84.74 169.14,35.67 170.61,38.41 "/>
              <polygon id="25" className="fil272" points="80.55,84.74 170.32,37.86 171.72,40.63 "/>
              <polygon id="26" className="fil272" points="80.55,84.74 171.44,40.08 172.78,42.88 "/>
              <polygon id="27" className="fil272" points="80.55,84.74 172.51,42.32 173.78,45.16 "/>
              <polygon id="28" className="fil272" points="80.55,84.74 173.52,44.59 174.72,47.46 "/>
              <polygon id="29" className="fil273" points="80.55,84.74 174.48,46.88 175.61,49.78 "/>
              <polygon id="30" className="fil273" points="80.55,84.74 175.38,49.2 176.44,52.12 "/>
              <polygon id="31" className="fil273" points="80.55,84.74 176.22,51.54 177.21,54.49 "/>
              <polygon id="32" className="fil273" points="80.55,84.74 177.01,53.9 177.92,56.87 "/>
              <polygon id="33" className="fil274" points="80.55,84.74 177.74,56.27 178.58,59.26 "/>
              <polygon id="34" className="fil274" points="80.55,84.74 178.41,58.67 179.17,61.68 "/>
              <polygon id="35" className="fil274" points="80.55,84.74 179.02,61.08 179.71,64.11 "/>
              <polygon id="36" className="fil274" points="80.55,84.74 179.57,63.5 180.18,66.55 "/>
              <polygon id="37" className="fil275" points="80.55,84.74 180.06,65.94 180.6,69 "/>
              <polygon id="38" className="fil275" points="80.55,84.74 180.49,68.38 180.96,71.46 "/>
              <polygon id="39" className="fil275" points="80.55,84.74 180.86,70.84 181.25,73.92 "/>
              <polygon id="40" className="fil275" points="80.55,84.74 181.18,73.31 181.49,76.4 "/>
              <polygon id="41" className="fil276" points="80.55,84.74 181.43,75.78 181.66,78.88 "/>
              <polygon id="42" className="fil276" points="80.55,84.74 181.61,78.26 181.78,81.36 "/>
              <polygon id="43" className="fil276" points="80.55,84.74 181.74,80.74 181.83,83.85 "/>
              <polygon id="44" className="fil276" points="80.55,84.74 181.81,83.23 181.82,86.33 "/>
              <polygon id="45" className="fil277" points="80.55,84.74 181.82,85.71 181.75,88.82 "/>
              <polygon id="46" className="fil277" points="80.55,84.74 181.76,88.2 181.62,91.3 "/>
              <polygon id="47" className="fil277" points="80.55,84.74 181.65,90.68 181.43,93.78 "/>
              <polygon id="48" className="fil277" points="80.55,84.74 181.47,93.16 181.18,96.25 "/>
              <polygon id="49" className="fil278" points="80.55,84.74 181.24,95.63 180.86,98.72 "/>
              <polygon id="50" className="fil278" points="80.55,84.74 180.94,98.1 180.49,101.17 "/>
              <polygon id="51" className="fil278" points="80.55,84.74 180.58,100.56 180.06,103.62 "/>
              <polygon id="52" className="fil278" points="80.55,84.74 180.16,103.01 179.56,106.06 "/>
              <polygon id="53" className="fil279" points="80.55,84.74 179.68,105.45 179.01,108.48 "/>
              <polygon id="54" className="fil279" points="80.55,84.74 179.14,107.87 178.4,110.89 "/>
              <polygon id="55" className="fil279" points="80.55,84.74 178.55,110.29 177.73,113.28 "/>
              <polygon id="56" className="fil279" points="80.55,84.74 177.89,112.69 177,115.66 "/>
              <polygon id="57" className="fil280" points="80.55,84.74 177.17,115.07 176.21,118.02 "/>
              <polygon id="58" className="fil280" points="80.55,84.74 176.4,117.43 175.36,120.36 "/>
              <polygon id="59" className="fil280" points="80.55,84.74 175.57,119.77 174.46,122.67 "/>
              <polygon id="60" className="fil280" points="80.55,84.74 174.68,122.09 173.5,124.97 "/>
              <polygon id="61" className="fil281" points="80.55,84.74 173.74,124.39 172.49,127.23 "/>
              <polygon id="62" className="fil281" points="80.55,84.74 172.74,126.67 171.42,129.48 "/>
              <polygon id="63" className="fil281" points="80.55,84.74 171.68,128.92 170.29,131.69 "/>
              <polygon id="64" className="fil281" points="80.55,84.74 170.57,131.14 169.11,133.88 "/>
              <polygon id="65" className="fil282" points="80.55,84.74 169.4,133.33 167.88,136.04 "/>
              <polygon id="66" className="fil282" points="80.55,84.74 168.18,135.5 166.59,138.17 "/>
              <polygon id="67" className="fil282" points="80.55,84.74 166.91,137.63 165.26,140.26 "/>
              <polygon id="68" className="fil282" points="80.55,84.74 165.59,139.74 163.87,142.33 "/>
              <polygon id="69" className="fil283" points="80.55,84.74 164.21,141.81 162.43,144.35 "/>
              <polygon id="70" className="fil283" points="80.55,84.74 162.79,143.84 160.94,146.34 "/>
              <polygon id="71" className="fil283" points="80.55,84.74 161.31,145.85 159.4,148.3 "/>
              <polygon id="72" className="fil283" points="80.55,84.74 159.79,147.81 157.82,150.22 "/>
              <polygon id="73" className="fil284" points="80.55,84.74 158.21,149.73 156.19,152.09 "/>
              <polygon id="74" className="fil284" points="80.55,84.74 156.6,151.62 154.52,153.93 "/>
              <polygon id="75" className="fil284" points="80.55,84.74 154.93,153.47 152.8,155.72 "/>
              <polygon id="76" className="fil285" points="80.55,84.74 153.22,155.27 151.03,157.47 "/>
              <polygon id="77" className="fil285" points="80.55,84.74 151.47,157.03 149.23,159.18 "/>
              <polygon id="78" className="fil285" points="80.55,84.74 149.67,158.75 147.38,160.84 "/>
              <polygon id="79" className="fil285" points="80.55,84.74 147.84,160.43 145.49,162.46 "/>
              <polygon id="80" className="fil286" points="80.55,84.74 145.96,162.05 143.56,164.03 "/>
              <polygon id="81" className="fil286" points="80.55,84.74 144.04,163.64 141.6,165.55 "/>
              <polygon id="82" className="fil286" points="80.55,84.74 142.09,165.17 139.6,167.03 "/>
              <polygon id="83" className="fil286" points="80.55,84.74 140.09,166.66 137.56,168.45 "/>
              <polygon id="84" className="fil287" points="80.55,84.74 138.07,168.09 135.49,169.83 "/>
              <polygon id="85" className="fil287" points="80.55,84.74 136,169.48 133.38,171.15 "/>
              <polygon id="86" className="fil287" points="80.55,84.74 133.91,170.82 131.25,172.42 "/>
              <polygon id="87" className="fil287" points="80.55,84.74 131.78,172.1 129.08,173.64 "/>
              <polygon id="88" className="fil288" points="80.55,84.74 129.62,173.33 126.88,174.8 "/>
              <polygon id="89" className="fil288" points="80.55,84.74 127.43,174.51 124.66,175.91 "/>
              <polygon id="90" className="fil288" points="80.55,84.74 125.21,175.63 122.41,176.97 "/>
              <polygon id="91" className="fil288" points="80.55,84.74 122.97,176.7 120.13,177.97 "/>
              <polygon id="92" className="fil289" points="80.55,84.74 120.7,177.71 117.83,178.91 "/>
              <polygon id="93" className="fil289" points="80.55,84.74 118.41,178.67 115.51,179.8 "/>
              <polygon id="94" className="fil289" points="80.55,84.74 116.09,179.57 113.17,180.63 "/>
              <polygon id="95" className="fil289" points="80.55,84.74 113.75,180.42 110.8,181.4 "/>
              <polygon id="96" className="fil290" points="80.55,84.74 111.39,181.2 108.42,182.11 "/>
              <polygon id="97" className="fil290" points="80.55,84.74 109.02,181.93 106.02,182.77 "/>
              <polygon id="98" className="fil290" points="80.55,84.74 106.62,182.6 103.61,183.36 "/>
              <polygon id="99" className="fil290" points="80.55,84.74 104.21,183.21 101.18,183.9 "/>
              <polygon id="100" className="fil291" points="80.55,84.74 101.79,183.76 98.74,184.37 "/>
              <polygon id="101" className="fil291" points="80.55,84.74 99.35,184.25 96.29,184.79 "/>
              <polygon id="102" className="fil291" points="80.55,84.74 96.91,184.68 93.83,185.15 "/>
              <polygon id="103" className="fil291" points="80.55,84.74 94.45,185.05 91.37,185.44 "/>
              <polygon id="104" className="fil292" points="80.55,84.74 91.98,185.36 88.89,185.68 "/>
              <polygon id="105" className="fil292" points="80.55,84.74 89.51,185.61 86.41,185.85 "/>
              <polygon id="106" className="fil292" points="80.55,84.74 87.03,185.8 83.93,185.96 "/>
              <polygon id="107" className="fil292" points="80.55,84.74 84.55,185.93 81.44,186.02 "/>
              <polygon id="108" className="fil293" points="80.55,84.74 82.06,186 78.96,186.01 "/>
              <polygon id="109" className="fil293" points="80.55,84.74 79.58,186.01 76.47,185.94 "/>
              <polygon id="110" className="fil293" points="80.55,84.74 77.09,185.95 73.99,185.81 "/>
              <polygon id="111" className="fil293" points="80.55,84.74 74.61,185.84 71.51,185.62 "/>
              <polygon id="112" className="fil294" points="80.55,84.74 72.13,185.66 69.04,185.37 "/>
              <polygon id="113" className="fil294" points="80.55,84.74 69.66,185.42 66.57,185.05 "/>
              <polygon id="114" className="fil294" points="80.55,84.74 67.19,185.13 64.12,184.68 "/>
              <polygon id="115" className="fil294" points="80.55,84.74 64.73,184.77 61.67,184.25 "/>
              <polygon id="116" className="fil295" points="80.55,84.74 62.28,184.35 59.23,183.75 "/>
              <polygon id="117" className="fil295" points="80.55,84.74 59.84,183.87 56.81,183.2 "/>
              <polygon id="118" className="fil295" points="80.55,84.74 57.41,183.33 54.4,182.59 "/>
              <polygon id="119" className="fil295" points="80.55,84.74 55,182.74 52.01,181.92 "/>
              <polygon id="120" className="fil296" points="80.55,84.74 52.6,182.08 49.63,181.19 "/>
              <polygon id="121" className="fil296" points="80.55,84.74 50.22,181.37 47.27,180.4 "/>
              <polygon id="122" className="fil296" points="80.55,84.74 47.86,180.59 44.93,179.55 "/>
              <polygon id="123" className="fil296" points="80.55,84.74 45.52,179.76 42.62,178.65 "/>
              <polygon id="124" className="fil296" points="80.55,84.74 43.2,178.87 40.32,177.69 "/>
              <polygon id="125" className="fil296" points="80.55,84.74 40.9,177.93 38.06,176.68 "/>
              <polygon id="126" className="fil296" points="80.55,84.74 38.62,176.93 35.81,175.6 "/>
              <polygon id="127" className="fil296" points="80.55,84.74 36.37,175.87 33.6,174.48 "/>
              <polygon id="128" className="fil296" points="80.55,84.74 34.15,174.76 31.41,173.3 "/>
              <polygon id="129" className="fil296" points="80.55,84.74 31.96,173.59 29.25,172.07 "/>
              <polygon id="130" className="fil296" points="80.55,84.74 29.79,172.37 27.12,170.78 "/>
              <polygon id="131" className="fil297" points="80.55,84.74 27.66,171.1 25.03,169.44 "/>
              <polygon id="132" className="fil297" points="80.55,84.74 25.55,169.78 22.96,168.06 "/>
              <polygon id="133" className="fil297" points="80.55,84.74 23.48,168.4 20.94,166.62 "/>
              <polygon id="134" className="fil297" points="80.55,84.74 21.45,166.98 18.94,165.13 "/>
              <polygon id="135" className="fil297" points="80.55,84.74 19.45,165.5 16.99,163.6 "/>
              <polygon id="136" className="fil297" points="80.55,84.74 17.48,163.98 15.07,162.01 "/>
              <polygon id="137" className="fil297" points="80.55,84.74 15.56,162.4 13.2,160.38 "/>
              <polygon id="138" className="fil297" points="80.55,84.74 13.67,160.79 11.36,158.71 "/>
              <polygon id="139" className="fil297" points="80.55,84.74 11.82,159.12 9.57,156.99 "/>
              <polygon id="140" className="fil297" points="80.55,84.74 10.02,157.41 7.82,155.22 "/>
              <polygon id="141" className="fil297" points="80.55,84.74 8.26,155.66 6.11,153.42 "/>
              <polygon id="142" className="fil297" points="80.55,84.74 6.54,153.86 4.44,151.57 "/>
              <polygon id="143" className="fil297" points="80.55,84.74 4.86,152.03 2.83,149.68 "/>
              <polygon id="144" className="fil297" points="80.55,84.74 3.23,150.15 1.26,147.75 "/>
              <polygon id="145" className="fil297" points="80.55,84.74 1.65,148.23 -0.27,145.79 "/>
              <polygon id="146" className="fil297" points="80.55,84.74 0.12,146.28 -1.74,143.79 "/>
              <polygon id="147" className="fil297" points="80.55,84.74 -1.37,144.28 -3.16,141.75 "/>
              <polygon id="148" className="fil297" points="80.55,84.74 -2.8,142.26 -4.54,139.68 "/>
              <polygon id="149" className="fil297" points="80.55,84.74 -4.19,140.19 -5.86,137.57 "/>
              <polygon id="150" className="fil298" points="80.55,84.74 -5.53,138.1 -7.13,135.44 "/>
              <polygon id="151" className="fil298" points="80.55,84.74 -6.81,135.97 -8.35,133.27 "/>
              <polygon id="152" className="fil298" points="80.55,84.74 -8.04,133.81 -9.51,131.07 "/>
              <polygon id="153" className="fil298" points="80.55,84.74 -9.22,131.62 -10.62,128.85 "/>
              <polygon id="154" className="fil298" points="80.55,84.74 -10.34,129.4 -11.68,126.6 "/>
              <polygon id="155" className="fil298" points="80.55,84.74 -11.41,127.16 -12.68,124.32 "/>
              <polygon id="156" className="fil298" points="80.55,84.74 -12.42,124.89 -13.62,122.02 "/>
              <polygon id="157" className="fil298" points="80.55,84.74 -13.38,122.6 -14.51,119.7 "/>
              <polygon id="158" className="fil298" points="80.55,84.74 -14.28,120.28 -15.34,117.36 "/>
              <polygon id="159" className="fil298" points="80.55,84.74 -15.13,117.94 -16.11,114.99 "/>
              <polygon id="160" className="fil298" points="80.55,84.74 -15.91,115.58 -16.82,112.61 "/>
              <polygon id="161" className="fil298" points="80.55,84.74 -16.64,113.21 -17.48,110.22 "/>
              <polygon id="162" className="fil298" points="80.55,84.74 -17.31,110.81 -18.07,107.8 "/>
              <polygon id="163" className="fil298" points="80.55,84.74 -17.92,108.4 -18.61,105.37 "/>
              <polygon id="164" className="fil298" points="80.55,84.74 -18.47,105.98 -19.08,102.93 "/>
              <polygon id="165" className="fil298" points="80.55,84.74 -18.96,103.54 -19.5,100.48 "/>
              <polygon id="166" className="fil298" points="80.55,84.74 -19.39,101.1 -19.86,98.02 "/>
              <polygon id="167" className="fil298" points="80.55,84.74 -19.76,98.64 -20.15,95.56 "/>
              <polygon id="168" className="fil298" points="80.55,84.74 -20.08,96.17 -20.39,93.08 "/>
              <polygon id="169" className="fil299" points="80.55,84.74 -20.32,93.7 -20.56,90.6 "/>
              <polygon id="170" className="fil299" points="80.55,84.74 -20.52,91.22 -20.68,88.12 "/>
              <polygon id="171" className="fil299" points="80.55,84.74 -20.64,88.74 -20.73,85.63 "/>
              <polygon id="172" className="fil299" points="80.55,84.74 -20.71,86.25 -20.72,83.15 "/>
              <polygon id="173" className="fil299" points="80.55,84.74 -20.72,83.77 -20.65,80.66 "/>
              <polygon id="174" className="fil299" points="80.55,84.74 -20.66,81.28 -20.52,78.18 "/>
              <polygon id="175" className="fil299" points="80.55,84.74 -20.55,78.8 -20.33,75.7 "/>
              <polygon id="176" className="fil299" points="80.55,84.74 -20.37,76.32 -20.08,73.23 "/>
              <polygon id="177" className="fil299" points="80.55,84.74 -20.14,73.85 -19.76,70.76 "/>
              <polygon id="178" className="fil299" points="80.55,84.74 -19.84,71.38 -19.39,68.3 "/>
              <polygon id="179" className="fil299" points="80.55,84.74 -19.48,68.92 -18.96,65.86 "/>
              <polygon id="180" className="fil299" points="80.55,84.74 -19.06,66.47 -18.46,63.42 "/>
              <polygon id="181" className="fil299" points="80.55,84.74 -18.58,64.03 -17.91,61 "/>
              <polygon id="182" className="fil299" points="80.55,84.74 -18.04,61.6 -17.3,58.59 "/>
              <polygon id="183" className="fil299" points="80.55,84.74 -17.45,59.19 -16.63,56.19 "/>
              <polygon id="184" className="fil299" points="80.55,84.74 -16.79,56.79 -15.9,53.82 "/>
              <polygon id="185" className="fil299" points="80.55,84.74 -16.08,54.41 -15.11,51.46 "/>
              <polygon id="186" className="fil299" points="80.55,84.74 -15.3,52.05 -14.26,49.12 "/>
              <polygon id="187" className="fil299" points="80.55,84.74 -14.47,49.71 -13.36,46.81 "/>
              <polygon id="188" className="fil300" points="80.55,84.74 -13.58,47.39 -12.4,44.51 "/>
              <polygon id="189" className="fil300" points="80.55,84.74 -12.64,45.09 -11.39,42.24 "/>
              <polygon id="190" className="fil300" points="80.55,84.74 -11.64,42.81 -10.32,40 "/>
              <polygon id="191" className="fil300" points="80.55,84.74 -10.58,40.56 -9.19,37.79 "/>
              <polygon id="192" className="fil300" points="80.55,84.74 -9.47,38.34 -8.01,35.6 "/>
              <polygon id="193" className="fil300" points="80.55,84.74 -8.3,36.15 -6.78,33.44 "/>
              <polygon id="194" className="fil300" points="80.55,84.74 -7.08,33.98 -5.49,31.31 "/>
              <polygon id="195" className="fil300" points="80.55,84.74 -5.81,31.84 -4.16,29.21 "/>
              <polygon id="196" className="fil300" points="80.55,84.74 -4.49,29.74 -2.77,27.15 "/>
              <polygon id="197" className="fil300" points="80.55,84.74 -3.11,27.67 -1.33,25.13 "/>
              <polygon id="198" className="fil300" points="80.55,84.74 -1.69,25.64 0.16,23.13 "/>
              <polygon id="199" className="fil300" points="80.55,84.74 -0.21,23.63 1.69,21.18 "/>
              <polygon id="200" className="fil300" points="80.55,84.74 1.31,21.67 3.28,19.26 "/>
              <polygon id="201" className="fil300" points="80.55,84.74 2.88,19.75 4.91,17.39 "/>
              <polygon id="202" className="fil300" points="80.55,84.74 4.5,17.86 6.58,15.55 "/>
              <polygon id="203" className="fil300" points="80.55,84.74 6.17,16.01 8.3,13.76 "/>
              <polygon id="204" className="fil300" points="80.55,84.74 7.88,14.21 10.07,12.01 "/>
              <polygon id="205" className="fil300" points="80.55,84.74 9.63,12.45 11.87,10.3 "/>
              <polygon id="206" className="fil300" points="80.55,84.74 11.43,10.73 13.72,8.64 "/>
              <polygon id="207" className="fil258" points="80.55,84.74 13.26,9.05 15.61,7.02 "/>
              <polygon id="208" className="fil258" points="80.55,84.74 15.14,7.43 17.54,5.45 "/>
              <polygon id="209" className="fil258" points="80.55,84.74 17.06,5.84 19.5,3.93 "/>
              <polygon id="210" className="fil258" points="80.55,84.74 19.01,4.31 21.5,2.45 "/>
              <polygon id="211" className="fil258" points="80.55,84.74 21,2.82 23.54,1.03 "/>
              <polygon id="212" className="fil258" points="80.55,84.74 23.03,1.39 25.61,-0.35 "/>
              <polygon id="213" className="fil258" points="80.55,84.74 25.1,-0 27.72,-1.67 "/>
              <polygon id="214" className="fil258" points="80.55,84.74 27.19,-1.34 29.85,-2.94 "/>
              <polygon id="215" className="fil258" points="80.55,84.74 29.32,-2.62 32.02,-4.16 "/>
              <polygon id="216" className="fil258" points="80.55,84.74 31.48,-3.85 34.22,-5.32 "/>
              <polygon id="217" className="fil258" points="80.55,84.74 33.67,-5.03 36.44,-6.43 "/>
              <polygon id="218" className="fil258" points="80.55,84.74 35.89,-6.15 38.69,-7.49 "/>
              <polygon id="219" className="fil258" points="80.55,84.74 38.13,-7.22 40.97,-8.49 "/>
              <polygon id="220" className="fil258" points="80.55,84.74 40.4,-8.23 43.27,-9.43 "/>
              <polygon id="221" className="fil258" points="80.55,84.74 42.69,-9.19 45.59,-10.32 "/>
              <polygon id="222" className="fil258" points="80.55,84.74 45.01,-10.09 47.93,-11.15 "/>
              <polygon id="223" className="fil258" points="80.55,84.74 47.35,-10.93 50.3,-11.92 "/>
              <polygon id="224" className="fil258" points="80.55,84.74 49.71,-11.72 52.68,-12.63 "/>
              <polygon id="225" className="fil258" points="80.55,84.74 52.08,-12.45 55.08,-13.29 "/>
              <polygon id="226" className="fil261" points="80.55,84.74 54.48,-13.12 57.49,-13.88 "/>
              <polygon id="227" className="fil261" points="80.55,84.74 56.89,-13.73 59.92,-14.42 "/>
              <polygon id="228" className="fil261" points="80.55,84.74 59.31,-14.28 62.35,-14.89 "/>
              <polygon id="229" className="fil261" points="80.55,84.74 61.75,-14.77 64.81,-15.31 "/>
              <polygon id="230" className="fil261" points="80.55,84.74 64.19,-15.2 67.27,-15.67 "/>
              <polygon id="231" className="fil261" points="80.55,84.74 66.65,-15.57 69.73,-15.96 "/>
              <polygon id="232" className="fil261" points="80.55,84.74 69.12,-15.88 72.21,-16.2 "/>
              <polygon id="233" className="fil261" points="80.55,84.74 71.59,-16.14 74.69,-16.37 "/>
              <polygon id="234" className="fil261" points="80.55,84.74 74.07,-16.32 77.17,-16.49 "/>
              <polygon id="235" className="fil261" points="80.55,84.74 76.55,-16.45 79.66,-16.54 "/>
              <polygon id="236" className="fil261" points="80.55,84.74 79.04,-16.52 82.14,-16.53 "/>
              <polygon id="237" className="fil261" points="80.55,84.74 81.52,-16.53 84.63,-16.46 "/>
              <polygon id="238" className="fil261" points="80.55,84.74 84.01,-16.47 87.11,-16.33 "/>
              <polygon id="239" className="fil261" points="80.55,84.74 86.49,-16.36 89.59,-16.14 "/>
              <polygon id="240" className="fil261" points="80.55,84.74 88.97,-16.18 92.06,-15.89 "/>
              <polygon id="241" className="fil261" points="80.55,84.74 91.44,-15.94 94.53,-15.57 "/>
              <polygon id="242" className="fil261" points="80.55,84.74 93.91,-15.65 96.98,-15.2 "/>
              <polygon id="243" className="fil261" points="80.55,84.74 96.37,-15.29 99.43,-14.77 "/>
              <polygon id="244" className="fil261" points="80.55,84.74 98.82,-14.87 101.87,-14.27 "/>
              <polygon id="245" className="fil264" points="80.55,84.74 101.26,-14.39 104.29,-13.72 "/>
              <polygon id="246" className="fil264" points="80.55,84.74 103.69,-13.85 106.7,-13.11 "/>
              <polygon id="247" className="fil264" points="80.55,84.74 106.1,-13.26 109.09,-12.44 "/>
              <polygon id="248" className="fil264" points="80.55,84.74 108.49,-12.6 111.47,-11.71 "/>
              <polygon id="249" className="fil264" points="80.55,84.74 110.88,-11.88 113.83,-10.92 "/>
              <polygon id="250" className="fil264" points="80.55,84.74 113.24,-11.11 116.17,-10.07 "/>
              <polygon id="251" className="fil264" points="80.55,84.74 115.58,-10.28 118.48,-9.17 "/>
              <polygon id="252" className="fil264" points="80.55,84.74 117.9,-9.39 120.78,-8.21 "/>
              <polygon id="253" className="fil264" points="80.55,84.74 120.2,-8.45 123.04,-7.2 "/>
              <polygon id="254" className="fil264" points="80.55,84.74 122.47,-7.45 125.29,-6.13 "/>
              <polygon id="255" className="fil265" points="80.55,84.74 124.72,-6.39 126.95,-5.28 "/>
            </g>
          </g>
          <circle className="fil301" cx="80.55" cy="84.74" r="51.73"/>
          <path className="fil302" d="M121.13 84.76c0,11.18 -3.96,20.71 -11.89,28.62 -7.92,7.9 -17.49,11.85 -28.69,11.85 -11.2,0 -20.77,-3.95 -28.69,-11.85 -7.93,-7.9 -11.89,-17.44 -11.89,-28.62 0,-11.18 3.96,-20.73 11.89,-28.63 7.92,-7.92 17.49,-11.87 28.69,-11.87 11.2,0 20.77,3.95 28.69,11.87 7.93,7.9 11.89,17.46 11.89,28.63zm-55.5 21.67c0,0.74 -0.26,1.36 -0.79,1.89 -0.53,0.52 -1.15,0.78 -1.89,0.78 -0.75,0 -1.38,-0.26 -1.9,-0.78 -0.52,-0.52 -0.78,-1.15 -0.78,-1.89 0,-0.74 0.26,-1.37 0.78,-1.89 0.52,-0.52 1.15,-0.78 1.9,-0.78 0.73,0 1.36,0.26 1.89,0.78 0.53,0.52 0.79,1.15 0.79,1.89zm48.87 -21.67c0,-9.37 -3.31,-17.36 -9.95,-23.99 -4.97,-4.97 -10.72,-8.09 -17.26,-9.33 0.14,0.12 0.27,0.25 0.4,0.38 2.01,1.99 3,4.39 3,7.19 0,2.8 -0.99,5.19 -3,7.17 -2,1.99 -4.38,2.98 -7.17,2.98 -2.83,0 -5.23,-0.99 -7.21,-2.98 -1.96,-1.98 -2.96,-4.37 -2.96,-7.17 0,-2.8 0.99,-5.21 2.96,-7.19 0.12,-0.12 0.25,-0.24 0.37,-0.36 -6.49,1.26 -12.21,4.36 -17.17,9.3 -6.63,6.63 -9.96,14.62 -9.96,23.99 0,9.37 3.33,17.35 9.96,23.96 6.65,6.62 14.65,9.93 24.04,9.93 9.39,0 17.39,-3.31 24,-9.93 6.63,-6.6 9.95,-14.59 9.95,-23.96zm-52.66 7.59c0,1.47 -0.52,2.73 -1.58,3.77 -1.06,1.05 -2.31,1.57 -3.77,1.57 -1.49,0 -2.75,-0.52 -3.8,-1.57 -1.03,-1.04 -1.56,-2.3 -1.56,-3.77 0,-1.48 0.52,-2.74 1.56,-3.79 1.04,-1.03 2.31,-1.56 3.8,-1.56 1.47,0 2.72,0.52 3.77,1.56 1.06,1.05 1.58,2.31 1.58,3.79zm6.08 -19.4c0,2.07 -0.73,3.82 -2.21,5.28 -1.48,1.46 -3.23,2.2 -5.28,2.2 -2.09,0 -3.85,-0.73 -5.32,-2.2 -1.45,-1.46 -2.18,-3.22 -2.18,-5.28 0,-2.07 0.73,-3.84 2.18,-5.3 1.46,-1.45 3.23,-2.18 5.32,-2.18 2.05,0 3.81,0.73 5.28,2.18 1.48,1.46 2.21,3.23 2.21,5.3zm20.43 39.73l-15.64 0 0 -41.04 15.64 0 0.01 41.04z"/>
        </g>
      </g>
    </svg>
  );
};
