import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface IAprazamentoListagem {
  id: number,
  nome: string,
  ativo: boolean,
}

export const aprazamentoListarAPI = async (): Promise<IAprazamentoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IAprazamentoListagem[]> = await axiosAPI.get(
      'aprazamento',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectAprazamentoAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IAprazamentoListagem[]> = await axiosAPI.get(
      'aprazamento',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const aprazamentoCreateAPI = async (nome: string, ativo: boolean): Promise<IAprazamentoListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'aprazamento',
      { nome, ativo, id_empresa: user.idEmpresa },
  )

  return response.data
}

export const aprazamentoUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
): Promise<IAprazamentoListagem[]> => {
  const response = await axiosAPI.put(
      'aprazamento/edit',
      {id, nome, ativo},
  )

  return response.data
}

export const aprazamentoDeleteAPI = async (id: number): Promise<IAprazamentoListagem[]> => {
  const response = await axiosAPI.delete('aprazamento/delete', { params: { id } })

  return response.data
}
