import React, {createContext, ReactElement, ReactNode, useState} from 'react';

import { IDashboardFiltros, TipoFiltroPeriodoDashboardEnum } from '../../../api/dashboard';

interface TDashboardContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  filtros: IDashboardFiltros,
  setFiltros: (newFiltros: IDashboardFiltros) => void,
  selecionado: string | null,
  setSelecionado: (newSelecionado: string | null) => void,
}

interface TDashboardProviderProps {
  children: ReactElement | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  filtros: {
    tipoFiltro: TipoFiltroPeriodoDashboardEnum.PERIODO_DIAS,
    periodoDe: new Date(),
    periodoAte: new Date(),
    enfermeiro: -1,
    paciente: -1
  },
  setFiltros: () => {
    throw new Error('setFiltros não implementada');
  },
  selecionado: 'diagnosticoenfermagem',
  setSelecionado: () => {
    throw new Error('setFiltros não implementada');
  }
};

export const DashboardContext = createContext<TDashboardContext>(defaultState);

export const DashboardProvider = ({children}: TDashboardProviderProps) => {
  const [filtros, setFiltros] = useState<IDashboardFiltros>(defaultState.filtros);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<string | null>(defaultState.selecionado)

  const providerValues: TDashboardContext = {
    carregando,
    setCarregando,
    filtros,
    setFiltros,
    selecionado,
    setSelecionado,
  };

  return (
    <DashboardContext.Provider value={providerValues}>
      {children}
    </DashboardContext.Provider>
  );
};
