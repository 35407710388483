import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IRespiracaoListagem } from '../../../../api/cadastro/respiracoesAPI';
import { respiracaoListarAPI } from '../../../../api/cadastro/respiracoesAPI';

interface TRespiracoesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  respiracoes: IRespiracaoListagem[],
  setRespiracoes: (newRespiracoes: IRespiracaoListagem[]) => void,
  selecionado: IRespiracaoListagem | null,
  setSelecionado: (novoSelecionado: IRespiracaoListagem | null) => void,
  loadRespiracoes: () => void
}

interface TRespiracoesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  respiracoes: [],
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  setRespiracoes: () => {
    throw new Error('setRespiracoes não implementada');
  },
  loadRespiracoes: () => {
    throw new Error('loadRespiracoes não implementada');
  },
};

export const RespiracoesContext = createContext<TRespiracoesContext>(defaultState);

export const RespiracoesProvider = ({children}: TRespiracoesProviderProps) => {
  const [respiracoes, setRespiracoes] = useState<IRespiracaoListagem[]>(defaultState.respiracoes);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IRespiracaoListagem | null>(defaultState.selecionado)

  const loadRespiracoes = () => {
    setCarregando(true)
    respiracaoListarAPI()
        .then((data) => setRespiracoes(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadRespiracoes();
  }, []);

  const providerValues: TRespiracoesContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    respiracoes,
    setRespiracoes,
    loadRespiracoes,
  };

  return (
    <RespiracoesContext.Provider value={providerValues}>
      {children}
    </RespiracoesContext.Provider>
  );
};
