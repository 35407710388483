import React, {CSSProperties, FocusEvent, FormEvent, useEffect, useState} from 'react';
import {FaAsterisk} from 'react-icons/fa';
import {SelectStyled} from '../styles';

const NULL_NUMBER = -1;

interface SelectAsyncProps {
  id: string,
  label?: string,
  name?: string,
  className?: string,
  extraOption?: ISelectChoice,
  emptyLabel?: string,
  required?: boolean,
  disabled?: boolean,
  type?: string,
  value: number | string,
  style?: CSSProperties,
  styleLabel?: CSSProperties,
  getSelectedData?: (data: any) => void,
  onChange: (e: FormEvent<HTMLSelectElement>) => void,
  onBlur?: (e: FocusEvent<any, Element>) => void,
  api: (filter?: string) => Promise<ISelectChoice[] | null>,
}

export interface ISelectChoice {
  [x: string]: any,
  label: string,
  value?: number | string,
}

export const SelectAsync = (props: SelectAsyncProps) => {
  const [options, setOptions] = useState<ISelectChoice[]>([]);
  const [keySelect, setKeySelect] = useState<number>(0);

  useEffect(() => {
    props.api()
        .then((data) => {
          if (data) {
            setOptions(data);
            setKeySelect((prev) => prev + 1);
          }
        }).catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (props.getSelectedData) {
      props.getSelectedData(options.find((op) => Number(op.value) === Number(props.value)));
    }
  }, [props.value]);

  return (
    <div style={{   display: 'flex', flexDirection: 'column', width: '100%', color: props.type==="branco"?"#fff" : '#304439' }}>
      {props.label && (
        <label htmlFor={props.id} className="form-label" style={props.styleLabel}>
          {props.label} {props.required && !props.disabled ? (<sup><FaAsterisk color='#FF264D' size={8} /></sup>) : ''}
        </label>
      )}
      <SelectStyled
        key={keySelect}
        id={props.id}
        name={props.name}
        className={props.className}
        style={props.style}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
      >
        {props.emptyLabel && (
          <option
            value={NULL_NUMBER}
            key={`${props.emptyLabel}`}
            style={{color: '#808080'}}
          >
            {props.emptyLabel}
          </option>
        )}
        {props.extraOption && (
          <option
            value={props.extraOption.value || NULL_NUMBER}
            key={`${props.extraOption.label}-${props.extraOption.value}`}
          >
            {props.extraOption.label}
          </option>
        )}
        {options && options.map((op) => (
          <option value={op.value} key={`${op.label}-${op.value}`}>{op.label}</option>
        ))}
      </SelectStyled>
    </div>
  );
};

SelectAsync.propsDefault = {
  disabled: false,
  required: false,
  style: {},
};
