import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioSexualidadeForm,
  prontuarioSexualidadeCreateAPI,
} from '../../../../../api/prontuario/prontuarioSexualidadeAPI'
import { Switch } from '../../../../../components'
import { CheckboxStyled, InputStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { verificarAvaliadoNoDia } from '../../util'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

interface IProntuarioSexualidadeProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioSexualidade = (props: IProntuarioSexualidadeProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.SEXUALIDADE)

  const initialValues: IProntuarioSexualidadeForm = {
    prejudicada: prontuario?.prontuarioSexualidade[0]?.prejudicada || null,
    motivo: prontuario?.prontuarioSexualidade[0]?.motivo || null,
    naoAplica: prontuario?.prontuarioSexualidade[0]?.naoAplica || false,
    avaliado:  new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioSexualidade[0]?.avaliado),
    alterado: prontuario?.prontuarioSexualidade[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioSexualidadeForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioSexualidadeCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de sexualidade incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('comunicacaoEducacao'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioComunicacaoEducacao[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioSexualidade[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('sexualidade')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Sexualidade</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'sexualidade') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'sexualidade') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={2}>
                  <Switch
                    label='Prejudicada'
                    name='prejudicada'
                    checked={values.prejudicada || false}
                    onChange={(value) => setFieldValue('prejudicada', value, false)}
                  />
                </Col>

                <Col md={10}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='motivo'>
                      Motivo
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='motivo'
                      name='motivo'
                      value={values.motivo || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Switch
                        label='Não avaliado'
                        name='stAvaliado'
                        checked={values.stAvaliado || false}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('avaliado', new Date(), false)
                          } else {
                            setFieldValue('avaliado', null, false)
                          }
                          setFieldValue('stAvaliado', value, false)
                        }}
                      />

                      <div style={{marginBottom: '16px', display: 'flex', marginTop: '28px', marginLeft: '18px'}}>
                        <CheckboxStyled
                          className="form-check-input form-control"
                          style={{ marginRight: '6px' }}
                          type="checkbox"
                          id="naoAplica"
                          name='naoAplica'
                          checked={values.naoAplica}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <label className="form-check-label form-label" htmlFor="naoAplica">
                          Não se aplica
                        </label>
                      </div>
                    </div>
                    { carregando ? (
                      <Spinner animation="border" style={{ marginTop: '27px'}} />
                    ) : (
                      <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar
                        </div>
                      </Button>
                    )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioSexualidade }
export default ProntuarioSexualidade
