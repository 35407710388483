import React from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { NavHeader } from '../../components'

const HomeApp = () => {
  const location = useLocation()

  return (
    <div style={{padding: '0', margin: 0, width: '100%', height: '100%'}}>
      <NavHeader />

      {location.pathname === '/' && (
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ marginTop: '26px' }}>
            Bem-vindos!
          </h2> 
          <h5>
            Vamos juntos transformar os indicadores de saúde por meio de um registro seguro!
          </h5>
        </div>
      )}

      <Outlet />
    </div>
  )
}

export { HomeApp }
export default HomeApp
