import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk } from 'react-icons/fa'

import { CheckboxStyled, InputStyled } from '../../../../components/styles'
import { IResultadosForm } from '../Resultados'
import { SelectGrupo } from '../../../../components'

type Props = {}

const ResultadosFormCampos = (props: Props) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext<IResultadosForm>()

  return (
    <>
      <Row style={{ marginTop: '22px' }}>
        <Col md={8}>
          <div>
            <label className="form-check-label form-label" htmlFor='nome'>
              Descrição <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='descricao'
              name='descricao'
              value={values.descricao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.descricao && touched.descricao && (
              <div style={{color: '#FF264D'}}>{errors.descricao}</div>
            )}
          </div>
        </Col>

        <Col md={4}>
          <div>
            <label className="form-check-label form-label" htmlFor='valorPadrao'>
              Valor padrão <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='number'
              className='form-input form-control w-100'
              id='valorPadrao'
              name='valorPadrao'
              value={values.valorPadrao}
              min={1}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.valorPadrao && touched.valorPadrao && (
              <div style={{color: '#FF264D'}}>{errors.valorPadrao}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: '8px' }}>
        <Col md={6}>
          <SelectGrupo
            value={values.grupo}
            onChange={handleChange}
          />
        </Col>

        <Col md={3}>
          <div style={{ marginBottom: '16px', display: 'flex', marginTop: '32px' }}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="inferidoCheckbox"
              name='inferido'
              checked={values.inferido}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="inferidoCheckbox">
              Inferido
            </label>
          </div>
        </Col>

        <Col md={3}>
          <div style={{marginBottom: '16px', display: 'flex', marginTop: '32px' }}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="ativoCheckbox"
              name='ativo'
              checked={values.ativo}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="ativoCheckbox">
              Ativo
            </label>
          </div>
        </Col>
      </Row>
    </>
  )
}

export { ResultadosFormCampos }
export default ResultadosFormCampos
