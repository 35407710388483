import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import {
  IProntuarioPlanoCuidadoAtividadeForm,
  IProntuarioPlanoCuidadoForm,
  IProntuarioPlanoCuidadoListagem,
  IProntuarioPlanoCuidadoResultadoForm,
} from './types'

export const prontuarioPlanoCuidadoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioPlanoCuidadoForm,
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'prontuarioplanocuidado',
      {
        id_prontuario: idProntuario,
        grupo: values.grupo,
        feedbacklike: values.feedbackLike,
        feedbackdeslike: values.feedbackDeslike,
        feedbackolho: values.feedbackOlho,
        feedbacklupa: values.feedbackLupa,
        id_diagnostico: values.idDiagnostico,
        id_usuario: user.id,
        inserido: new Date()
      },
  )
}

export const prontuarioPlanoCuidadoUpdateAPI = async (
  idPlanoCuidado: number,
  feedbacklike?: boolean,
  feedbackdeslike?: boolean,
  feedbackolho?: boolean,
  feedbacklupa?: boolean,
): Promise<void> => {
  await axiosAPI.put(
      'prontuarioplanocuidado/edit',
      {
        id: idPlanoCuidado,
        feedbacklike,
        feedbackdeslike,
        feedbackolho,
        feedbacklupa,
      },
  )
}

export const prontuarioPlanoCuidadoDeleteAPI = async (
  idPlanoCuidado: number,
): Promise<void> => {
  await axiosAPI.delete(
      'prontuarioplanocuidado/delete',
      {
        params: {
          id: idPlanoCuidado,
        }
      },
  )
}

export const prontuarioPlanoCuidadoResultadoCreateAPI = async (
  idProntuarioPlanoCuidado: number,
  values: IProntuarioPlanoCuidadoResultadoForm,
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'prontuarioplanocuidadoresultado',
      {
        grupo: values.grupo,
        valor: values.valor,
        esperado: values.esperado,
        inserido: new Date(),
        id_diagnostico: values.idDiagnostico,
        id_resultado: values.idResultado,
        id_usuario:  user.id,
        id_prontuario_plano_cuidado: idProntuarioPlanoCuidado,
        id_indicador: values.idIndicador,
      },
  )
}

export const prontuarioPlanoCuidadoAtividadeCreateAPI = async (
  idProntuarioPlanoCuidado: number,
  values: IProntuarioPlanoCuidadoAtividadeForm,
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'prontuarioplanocuidadoatividade',
      {
        id_prontuario_plano_cuidado: idProntuarioPlanoCuidado,
        grupo: values.grupo,
        id_diagnostico: values.idDiagnostico,
        id_intervencao: values.idIntervencao,
        id_atividade: values.idAtividade,
        id_aprazamento: values.idAprazamento,
        id_usuario:  user.id,
        inserido: new Date()
      },
  )
}

export const prontuarioPlanoCuidadoAtividadeEditAPI = async (
  id: number,
  values: IProntuarioPlanoCuidadoAtividadeForm
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.put(
      'prontuarioplanocuidadoatividade/edit',
      {
        id,
        grupo: values.grupo,
        id_diagnostico: values.idDiagnostico,
        id_intervencao: values.idIntervencao,
        id_atividade: values.idAtividade,
        id_aprazamento: values.idAprazamento,
        id_usuario:  user.id,
      },
  )
}

export const prontuarioPlanoCuidadoResultadoEditAPI = async (
  id: number,
  values: IProntuarioPlanoCuidadoResultadoForm
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.put(
      'prontuarioplanocuidadoresultado/edit',
      {
        id,
        grupo: values.grupo,
        valor: values.valor,
        esperado: values.esperado,
        id_diagnostico: values.idDiagnostico,
        id_resultado: values.idResultado,
        id_usuario:  user.id,
        id_indicador: values.idIndicador,
      },
  )
}

export const prontuarioPlanoCuidadoAtividadeDeleteAPI = async (
  id: number,
): Promise<void> => {
  await axiosAPI.delete(`prontuarioplanocuidadoatividade/delete?id=${id}`)
}

export const prontuarioPlanoCuidadoResultadoDeleteAPI = async (
  id: number,
): Promise<void> => {
  await axiosAPI.delete(`prontuarioplanocuidadoresultado/delete?id=${id}`)
}

export type {
  IProntuarioPlanoCuidadoForm,
  IProntuarioPlanoCuidadoListagem,
  IProntuarioPlanoCuidadoResultadoForm,
  IProntuarioPlanoCuidadoAtividadeForm,
}
