import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { AtividadesProvider } from './context';
import { AtividadesContainerForm } from './AtividadesContainerForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../api/prontuario/types';

export interface IAtividadesForm {
  descricao: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  intervencao: number | -1,
}

const Atividades = () => {
  return (
    <AtividadesProvider>
      <DivMainStyled>
        <DivCardStyled>
          <AtividadesContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </AtividadesProvider>
  )
}

export { Atividades }
export default Atividades
