import { axiosAPI } from '../axiosAPI'
import { parseEdemaLocalDescFrontToBack } from './parser'
import {
  PulsacaoPerifericaEnum,
  PulsoPerifericoEnum,
  IProntuarioFuncaoCardiovascularForm,
  IProntuarioFuncaoCardiovascularListagem,
} from './types'

export const prontuarioFuncaoCardiovascularCreateAPI = async (
  idProntuario: number,
  values: IProntuarioFuncaoCardiovascularForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariofucaocardiovascular',
      {
        id_prontuario: idProntuario,
        pulso_periferico: values.pulsoPeriferico,
        pulsacao_periferica: values.pulsacaoPeriferica,
        tem_edema: values.temEdema,
        auscuta_cardiaca: values.auscutaCardiaca,
        avaliado: values.avaliado,
        alterado: values.alterado,
        itens: values.edemasLocalDesc.map(ed => parseEdemaLocalDescFrontToBack(ed))
      },
  )
}

export type {
  IProntuarioFuncaoCardiovascularForm,
  IProntuarioFuncaoCardiovascularListagem
}

export {
  PulsacaoPerifericaEnum,
  PulsoPerifericoEnum,
}
