import { IEdemaListagem } from "../../cadastro/edemasAPI";

export enum PulsoPerifericoEnum {
  FINO = 'FINO',
  CHEIO = 'CHEIO',
  FRACO = 'FRACO',
  AUSENTE = 'AUSENTE',
}

export enum PulsacaoPerifericaEnum {
  MAIOR_3S = 'MAIOR_3S',
  MENOR_3S = 'MENOR_3S',
  NAO_AVALIADO = 'NAO_AVALIADO',
}

export interface IProntuarioFuncaoCardiovascularListagem {
  id: number;
  pulsoPeriferico: PulsoPerifericoEnum;
  pulsacaoPeriferica: PulsacaoPerifericaEnum;
  temEdema: boolean;
  auscutaCardiaca: string | null;
  avaliado: Date | null;
  alterado: boolean | null;
  edemaLocalDesc: IEdemaLocalDescList[];
}

export interface IProntuarioFuncaoCardiovascularListagemBackend {
  id: number;
  pulso_periferico: PulsoPerifericoEnum;
  pulsacao_periferica: PulsacaoPerifericaEnum;
  tem_edema: boolean;
  auscuta_cardiaca: string | null;
  avaliado: Date | null;
  alterado: boolean | null;
  FuncaoCardiovascularEdema: IEdemaLocalDescListBackend[];
}

export interface IEdemaLocalDescList {
  descricao: string,
  local: string,
  idEdema: number,
}

export interface IEdemaLocalDescListBackend {
  descricao: string,
  edema: IEdemaListagem,
  id_edema: number,
}

export interface IProntuarioFuncaoCardiovascularForm {
  pulsoPeriferico: PulsoPerifericoEnum;
  pulsacaoPeriferica: PulsacaoPerifericaEnum;
  temEdema: boolean;
  auscutaCardiaca: string | null;
  edemasLocalDesc: IEdemaLocalDescList[];
  avaliado: Date | null;
  stAvaliado: boolean | null;
  alterado: boolean | null;
}
