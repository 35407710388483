import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IPacienteListagem, IPacientesFiltros } from '../../../api/pacientes/types';
import { pacienteEmAtendimentoListarAPI } from '../../../api/pacientes';

interface TPacientesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  pacientes: IPacienteListagem[],
  setPacientes: (newPacientes: IPacienteListagem[]) => void,
  loadPacientes: (filtros: IPacientesFiltros) => void,
  filtros: IPacientesFiltros,
  setFiltros: (novosFiltros: IPacientesFiltros) => void
}

interface TPacientesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  pacientes: [],
  setPacientes: () => {
    throw new Error('setPacientes não implementada');
  },
  loadPacientes: () => {
    throw new Error('loadPacientes não implementada');
  },
  filtros: { nome: '', saiu: false, clinicaMedica: false, clinicaCirurgica: false, uti: false, },
  setFiltros: () => {
    throw new Error('setFiltros não implementada');
  },
};

export const PacientesContext = createContext<TPacientesContext>(defaultState);

export const PacientesProvider = ({children}: TPacientesProviderProps) => {
  const [pacientes, setPacientes] = useState<IPacienteListagem[]>(defaultState.pacientes);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [filtros, setFiltros] = useState<IPacientesFiltros>(defaultState.filtros)

  const loadPacientes = (filtros: IPacientesFiltros) => {
    setCarregando(true)
    pacienteEmAtendimentoListarAPI(filtros)
        .then((data) => setPacientes(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadPacientes(filtros);
  }, [filtros]);

  const providerValues: TPacientesContext = {
    carregando,
    setCarregando,
    pacientes,
    setPacientes,
    loadPacientes,
    filtros,
    setFiltros,
  };

  return (
    <PacientesContext.Provider value={providerValues}>
      {children}
    </PacientesContext.Provider>
  );
};
