import React, { useContext, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

import { leitoCreateAPI, leitoUpdateAPI } from '../../../api/cadastro/leitosAPI'
import { ILeitosForm } from './Leitos';
import { LeitosBotoes, LeitosFormCampos, LeitosListagem } from './components';
import { LeitosContext } from './context';
import { GoPencil } from 'react-icons/go';
import { DivTitulo } from '../styles';
import { RiAddCircleLine } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';

interface ILeitosConatinerForm {
  modal?: boolean,
  atualizarLeitos?: () => void,
}

const LeitosContainerForm = (props: ILeitosConatinerForm) => {
  const { carregando, loadLeitos, setCarregando, selecionado, setSelecionado } = useContext(LeitosContext);
  const [keyForm, setKeyForm] = useState<number>(0)
  const [modalLeitoAberto, setModalLeitoAberto] = useState<boolean>(false)

  const initialValues: ILeitosForm = {
    nome: selecionado?.nome || '',
    ativo: selecionado?.ativo === false ? false : true,
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').required('Campo obrigatório!'),
    ativo: Yup.bool(),
  })

  useEffect(() => {
    setKeyForm(prev => prev + 1)
  }, [selecionado])

  const handleSubmit = (
    values: ILeitosForm,
    actions: FormikHelpers<ILeitosForm>
  ) => {
    setCarregando(true)
    if (!selecionado) {
      leitoCreateAPI(values.nome, values.ativo)
        .then(() => {
          loadLeitos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Leito criado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    } else {
      leitoUpdateAPI(selecionado.id, values.nome, values.ativo)
        .then(() => {
          setSelecionado(null)
          loadLeitos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Leito editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      {!props.modal ? (
        <>
          <DivTitulo style={{ marginTop: '18px' }}>
            <GoPencil color='#304439' size={24} style={{ marginRight: '8px' }} />
            Cadastro - Leitos
          </DivTitulo>

          <Formik
            key={keyForm}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <BlockUi tag="div" blocking={carregando}>
              <LeitosFormCampos />

              <LeitosBotoes />

              <LeitosListagem />
            </BlockUi>
          </Formik>
        </>
      ) : (
        <>
          <RiAddCircleLine
            color='#304439'
            size={26}
            style={{ marginRight: '4px', marginTop: '30px' }}
            onClick={() => setModalLeitoAberto(true)}
          />

          {modalLeitoAberto && (
            <Formik
              key={keyForm}
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              <Modal
                show={modalLeitoAberto}
                onHide={() => {
                  if (props.atualizarLeitos) props.atualizarLeitos()
                  setModalLeitoAberto(false)
                }}
              >
                <Modal.Header>
                  <Modal.Title>
                   Leitos
                  </Modal.Title>
                </Modal.Header>

                <BlockUi tag="div" blocking={carregando}>
                  <LeitosFormCampos />

                  <LeitosBotoes />

                  <LeitosListagem />
                </BlockUi>
              </Modal>
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export { LeitosContainerForm }
export default LeitosContainerForm
