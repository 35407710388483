import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { differenceInDays, format, isAfter, sub } from 'date-fns';

import { PacientesContext } from '../context';
import PacienteAcoes from './PacienteAcoes';
import { Selos } from '../prontuario/components/helper';

interface IProps {
  alterado: boolean;
}

const DivCardStyled = styled.div<IProps>`
  background-color: ${(props: IProps) => props.alterado ? '#FD2424' : '#fff'};
  padding: 5px 16px;
  margin-bottom: 8px;
  color: ${(props: IProps) => props.alterado ? '#fff' : '#304439'};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  border-radius: 12px;
`

const PacientesListagem = () => {
  const [keyTabela, setKeyTabela] = useState<number>(0)
  const { pacientes } = useContext(PacientesContext);

  useEffect(() => {
    setKeyTabela((prev) => prev + 1)
  }, [pacientes])

  const getIdade = (nascimento: Date) => {
    const today = new Date();
    let age = today.getFullYear() - nascimento.getFullYear();
    const m = today.getMonth() - nascimento.getMonth();
    
    if (m < 0 || (m === 0 && today.getDate() < nascimento.getDate())) {
        age--;
    }
    
    return (`(${age} anos)`);
  }

  const verificarUltimaAtualizacao = (dataUltimaAlteracao: Date | undefined): boolean => {
    if (dataUltimaAlteracao) {
      const agoraMenos24Horas = sub(new Date(), { hours: 24 })
      
      return isAfter(agoraMenos24Horas, dataUltimaAlteracao)
    }
    return false
  }

  return (
    <div key={keyTabela} style={{ width: '595px', margin: 'auto', marginBottom: '80px' }}>
      {pacientes && pacientes.length > 0 && pacientes.map(paciente => {
        return (
          <>
            {paciente.prontuario && paciente.prontuario.length > 0 && (
              <DivCardStyled key={paciente.id} alterado={verificarUltimaAtualizacao(paciente.prontuario[0].alterado)}>
                <div style={{ fontWeight: '700', fontSize: '18px', lineHeight: '20px' }}>
                  {paciente.nome}
                   
                </div>
            
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex' }}>
                    {/* <div style={{ marginRight: '15px' }}>
                      <FaRegIdCard size={50} />
                    </div> */}

                    <div>
                      <div>
                        Leito: {paciente.prontuario[0].leito?.nome == null ? '0' :
                         paciente.prontuario[0].leito?.nome ? paciente.prontuario[0].leito?.nome: 
                         ''}
                      </div>
                      {/* <div>
                        <Selos prontuario={paciente.prontuario[0]} colorido />
                      </div> */}
                      <div>
                      Dias: {differenceInDays(new Date(), paciente.prontuario[0].dhAdmissao ? new Date(paciente.prontuario[0].dhAdmissao) : new Date())}
                    </div>
                    </div>
                  </div>

                  {paciente.prontuario[0].dhAdmissao ? (
                    <div>
                      <div>
                        Entrada: {format(new Date(paciente.prontuario[0].dhAdmissao), 'dd/MM/yyyy')}
                      </div>

                      <div>
                        {`
                          Data nascimento:  ${paciente.dataNascimento
                            ? `${format(new Date(paciente.dataNascimento), 'dd/MM/yyyy')} ${getIdade(paciente.dataNascimento)}`
                            : ''}
                        `}    
                      </div>
                    </div>
                  ) : ( <div /> )}

                  <div>
                    {/* <div>
                      Dias: {differenceInDays(new Date(), paciente.prontuario[0].dhAdmissao ? new Date(paciente.prontuario[0].dhAdmissao) : new Date())}
                    </div> */}
                     <div> 
                          Riscos: 
                        <Selos prontuario={paciente.prontuario[0]} colorido />
                      </div>
                    
                      {/*<div> Alterado: {paciente.alterado ? 'Sim' : 'Não'}    </div>*/}
                   
                  </div>


                  {/* {paciente.todosGruposAvaliados ? (
                    <IoShieldCheckmarkOutline size={32} />
                  ) : (
                    <div />
                  )} */}

                  <PacienteAcoes paciente={paciente} />
                </div>
              </DivCardStyled>
            )}
          </>
        )
      })}
    </div>
  )
}

export { PacientesListagem }
export default PacientesListagem
