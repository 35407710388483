import { AxiosResponse } from 'axios'
import { format } from 'date-fns';

import { ISelectChoice } from '../../components';
import { parseProntuarioBackToFront } from '../prontuario/parser';
import { IProntuarioListagem, IProntuarioListagemBackend } from '../prontuario/types';
import { axiosAPI } from '../axiosAPI'
import { IPlanoCuidadosRelatorio, IPlanoCuidadosRelatorioBackend, IRelatorioAltaBackend, IRelatorioPassagemPlantaoBackend } from './types';
import { parseProntuarioPlanoCuidadoRelatorioBackToFront } from './parse';

export const relatorioAlta = async (idPaciente: number): Promise<IRelatorioAltaBackend> => {
  const response: AxiosResponse<IRelatorioAltaBackend> = await axiosAPI.get(
      'relatorio/relatorioalta',
      { params: { id_paciente: idPaciente } },
  )

  return response.data
}

export const relatorioPassagemPlantao = async (idPaciente: number[], selecionarTodos: boolean): Promise<IRelatorioPassagemPlantaoBackend[]> => {
  const response: AxiosResponse<IRelatorioPassagemPlantaoBackend[]> = await axiosAPI.get(
      'relatorio/relatoriopassagemplantao',
      { params: { pacientes: idPaciente.join(','),  adicionar_todos_pacientes: selecionarTodos } },
  )

  return response.data
}

export const relatorioPlanoCuidados = async (idPaciente: number): Promise<IPlanoCuidadosRelatorio> => {
  const response: AxiosResponse<IPlanoCuidadosRelatorioBackend> = await axiosAPI.get(
      'relatorio/relatorioplanocuidado',
      { params: { id_paciente: idPaciente } },
  )

  return parseProntuarioPlanoCuidadoRelatorioBackToFront(response.data)
}

export const relatorioProntuario = async (idPaciente: number): Promise<IProntuarioListagem> => {
  const response: AxiosResponse<IProntuarioListagemBackend> = await axiosAPI.get(
      'relatorio/relatorioprontuario',
      { params: { id_paciente: idPaciente } },
  )

  return parseProntuarioBackToFront(response.data)
}

interface ISelectDatasRelatorioProntuarioBackend {
  data: string,
}

export const getDatasSelectProntuario = async (idPaciente: number): Promise<ISelectChoice[]> => {
  const response: AxiosResponse<ISelectDatasRelatorioProntuarioBackend[]> = await axiosAPI.get(
      'relatorio/relatoriohistorico/selectdatas',
      { params: { id_paciente: idPaciente } },
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: format(new Date(`${item.data} `), 'dd/MM/yyyy'), value: item.data}
    })
  }

  return response.data
}

export const relatorioHistoricoProntuario = async (idPaciente: number, data: string): Promise<IProntuarioListagem> => {
  const response: AxiosResponse<IProntuarioListagemBackend> = await axiosAPI.get(
      'relatorio/relatoriohistorico/printhistorico',
      { params: { id_paciente: idPaciente, data } },
  )

  return parseProntuarioBackToFront(response.data)
}
