import React, { useContext, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';
import { GoPencil } from 'react-icons/go';
import { RiAddCircleLine } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';

import { aprazamentoCreateAPI, aprazamentoUpdateAPI } from '../../../api/cadastro/aprazamentoAPI';
import { DivTitulo } from '../styles';
import { IAprazamentosForm } from './Aprazamentos';
import { AprazamentosBotoes, AprazamentosFormCampos, AprazamentosListagem } from './components';
import { AprazamentosContext } from './context';

interface IAprazamentosConatinerForm {
  modal?: boolean,
  atualizarAprazamentos?: () => void,
}

const AprazamentosContainerForm = (props: IAprazamentosConatinerForm) => {
  const { carregando, loadAprazamentos, setCarregando, selecionado, setSelecionado } = useContext(AprazamentosContext);
  const [keyForm, setKeyForm] = useState<number>(0)
  const [modalAprazamentoAberto, setModalAprazamentoAberto] = useState<boolean>(false)

  const initialValues: IAprazamentosForm = {
    nome: selecionado?.nome || '',
    ativo: selecionado?.ativo === false ? false : true,
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').required('Campo obrigatório!'),
    ativo: Yup.bool(),
  })

  useEffect(() => {
    setKeyForm(prev => prev + 1)
  }, [selecionado])

  const handleSubmit = (
    values: IAprazamentosForm,
    actions: FormikHelpers<IAprazamentosForm>
  ) => {
    setCarregando(true)
    if (!selecionado) {
      aprazamentoCreateAPI(values.nome, values.ativo)
        .then(() => {
          loadAprazamentos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Aprazamento criado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    } else {
      aprazamentoUpdateAPI(selecionado.id, values.nome, values.ativo)
        .then(() => {
          setSelecionado(null)
          loadAprazamentos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Aprazamento editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      {!props.modal ? (
        <>
          <DivTitulo style={{ marginTop: '18px' }}>
            <GoPencil color='#304439' size={24} style={{ marginRight: '8px' }} />
            Cadastro - Aprazamentos
          </DivTitulo>

          <Formik
            key={keyForm}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            <BlockUi tag="div" blocking={carregando}>
              <AprazamentosFormCampos />

              <AprazamentosBotoes />

              <AprazamentosListagem />
            </BlockUi>
          </Formik>
        </>
      ) : (
        <>
          <RiAddCircleLine
            color='#304439'
            size={26}
            style={{ marginRight: '4px', marginTop: '30px' }}
            onClick={() => setModalAprazamentoAberto(true)}
          />

          {modalAprazamentoAberto && (
            <Formik
              key={keyForm}
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              <Modal
                show={modalAprazamentoAberto}
                onHide={() => {
                  if (props.atualizarAprazamentos) props.atualizarAprazamentos()
                  setModalAprazamentoAberto(false)
                }}
              >
                <Modal.Header>
                  <Modal.Title>
                   Aprazamentos
                  </Modal.Title>
                </Modal.Header>

                <BlockUi tag="div" blocking={carregando}>
                  <AprazamentosFormCampos />

                  <AprazamentosBotoes />

                  <AprazamentosListagem />
                </BlockUi>
              </Modal>
            </Formik>
          )}
        </>
      )}
    </>
  )
}

export { AprazamentosContainerForm }
export default AprazamentosContainerForm
