import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';
import {
  intervencaoListarAPI,
  IIntervencaoListagem,
} from '../../../../api/cadastro/intervencoesAPI';



interface TIntervencoesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  intervencoes: IIntervencaoListagem[],
  setIntervencoes: (newIntervencoes: IIntervencaoListagem[]) => void,
  selecionado: IIntervencaoListagem | null,
  setSelecionado: (novoSelecionado: IIntervencaoListagem | null) => void,
  loadIntervencoes: () => void
}

interface TIntervencoesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  intervencoes: [],
  loadIntervencoes: () => {
    throw new Error('loadIntervencoes não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  setIntervencoes: () => {
    throw new Error('setIntervencoes não implementada');
  },
};

export const IntervencoesContext = createContext<TIntervencoesContext>(defaultState);

export const IntervencoesProvider = ({children}: TIntervencoesProviderProps) => {
  const [intervencoes, setIntervencoes] = useState<IIntervencaoListagem[]>(defaultState.intervencoes);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IIntervencaoListagem | null>(defaultState.selecionado)

  const loadIntervencoes = () => {
    setCarregando(true)
    intervencaoListarAPI()
        .then((data) => setIntervencoes(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadIntervencoes();
  }, []);

  const providerValues: TIntervencoesContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    intervencoes,
    setIntervencoes,
    loadIntervencoes,
  };

  return (
    <IntervencoesContext.Provider value={providerValues}>
      {children}
    </IntervencoesContext.Provider>
  );
};
