import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IUsuarioPontoListagem, pontoProfissionalListarAPI } from '../../../../../api/profissionais/pontoProfissionalAPI';

interface TPassagemPlantaoContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  ultimoPonto: IUsuarioPontoListagem | null,
  setUltimoUsuarioPonto: (newPonto: IUsuarioPontoListagem) => void,
  loadUltimoPonto: () => void
}

interface TPassagemPlantaoProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  ultimoPonto: null,
  setUltimoUsuarioPonto: () => {
    throw new Error('setUltimoUsuarioPonto não implementada');
  },
  loadUltimoPonto: () => {
    throw new Error('loadUltimoPonto não implementada');
  },
};

export const PassagemPlantaoContext = createContext<TPassagemPlantaoContext>(defaultState);

export const PassagemPlantaoProvider = ({children}: TPassagemPlantaoProviderProps) => {
  const [ultimoPonto, setUltimoUsuarioPonto] = useState<IUsuarioPontoListagem | null>(defaultState.ultimoPonto);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)

  const loadUltimoPonto = () => {
    setCarregando(true)
    pontoProfissionalListarAPI()
        .then((data) => setUltimoUsuarioPonto(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadUltimoPonto();
  }, []);

  const providerValues: TPassagemPlantaoContext = {
    carregando,
    setCarregando,
    ultimoPonto,
    setUltimoUsuarioPonto,
    loadUltimoPonto,
  };

  return (
    <PassagemPlantaoContext.Provider value={providerValues}>
      {children}
    </PassagemPlantaoContext.Provider>
  );
};
