import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { ResultadosContext } from '../context';
import { parseGrupoPlanoCuidado } from '../../../pacientes/prontuario/components/plano_cuidados/util';
import { TableContainer, TableStyled } from '../../../../components/styles';

const ResultadosListagem = () => {
  const { resultados, selecionado, setSelecionado } = useContext(ResultadosContext);

  return (
    <>
      {resultados && resultados.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Resultado de enfermagem.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {resultados && resultados.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: '140px' }}>Valor padrão</th>
                <th>Grupo</th>
                <th style={{ width: '8px' }}>Inferido</th>
                <th style={{ width: '8px' }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {resultados && resultados.length > 0 && resultados.map((resultado => (
                <tr
                  key={resultado.id}
                  style={{ border: selecionado?.id === resultado.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(resultado)}
                >
                  <td>{resultado.descricao}</td>
                  <td>{resultado.valorPadrao}</td>
                  <td>{!!resultado.grupo && resultado.grupo !== -1 ? parseGrupoPlanoCuidado(resultado.grupo) : ''}</td>
                  <td>{resultado.inferido ? 'Sim' : 'Não'}</td>
                  <td>{resultado.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { ResultadosListagem }
export default ResultadosListagem
