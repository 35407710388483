import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk } from 'react-icons/fa'

import { CheckboxStyled, InputStyled } from '../../../../components/styles'
import { IAtividadesForm } from '../Atividades'
import { SelectGrupo } from '../../../../components'
import { SelectAsync } from '../../../../components/select-async'
import { getAllToSelectIntervencaoAPI } from '../../../../api/cadastro/intervencoesAPI'

type Props = {}

const AtividadesFormCampos = (props: Props) => {
  const [keyGrupoAlterou, setKeyGrupoAlterou] = useState<number>(0)
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext<IAtividadesForm>()

  useEffect(() => {
    setKeyGrupoAlterou(prev => prev + 1)
  }, [values.grupo])

  return (
    <>
      <Row style={{ marginTop: '22px' }}>
        <Col md={10}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='descricao'>
              Descrição <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='descricao'
              name='descricao'
              value={values.descricao}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.descricao && touched.descricao && (
              <div style={{color: '#FF264D'}}>{errors.descricao}</div>
            )}
          </div>
        </Col>

        <Col md={2}>
          <div style={{marginBottom: '16px', display: 'flex', marginTop: '28px'}}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="ativoCheckbox"
              name='ativo'
              checked={values.ativo}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="ativoCheckbox">
              Ativo
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <SelectGrupo
            value={values.grupo}
            onChange={handleChange}
          />
        </Col>

        <Col md={6}>
          <SelectAsync
            key={keyGrupoAlterou}
            id='intervencao'
            label='Intervenção'
            value={values.intervencao || -1}
            emptyLabel='Selecione...'
            onChange={handleChange}
            api={() => getAllToSelectIntervencaoAPI(values.grupo)}
          />
        </Col>
      </Row>
    </>
  )
}

export { AtividadesFormCampos }
export default AtividadesFormCampos
