import { AxiosResponse } from 'axios'
import { axiosAPI } from './axiosAPI'
import { IUser } from '../context/auth_provider/types'

export const loginRequest = async (email: string, password: string) => {
  const request = await axiosAPI.post('session', {email, senha: password})

  return request.data
}

export interface IEsqueciSenhaForm {
  email: string
}

export const esqueciSenhaAPI = async (values: IEsqueciSenhaForm): Promise<any> => {
  try{
    const response: AxiosResponse<IUser> = await axiosAPI.post(
    'forgotpassword',
    {  email: values.email,  }
    )
    return response.data
  }
  catch(e){
    console.log(e)
  }
  
}
