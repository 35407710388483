import React, { useContext } from 'react'
import { Formik, FormikHelpers } from 'formik'
import Swal from 'sweetalert2'

import {
  GrupoProntuarioPlanoCuidadoEnum,
  IProntuarioPlanoCuidadoResultado
} from '../../../../../../../api/prontuario/types'
import { ProntuarioContext } from '../../../../context'
import {
  prontuarioPlanoCuidadoResultadoCreateAPI,
  IProntuarioPlanoCuidadoResultadoForm,
  prontuarioPlanoCuidadoResultadoEditAPI,
} from '../../../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import PlanoCuidadoResultadosFormCampos from './PlanoCuidadoResultadosFormCampos'

interface IPlanoCuidadoResultadosFormProps {
  idPlanoCuidado: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
  editar?: boolean,
  dadosResultados?: IProntuarioPlanoCuidadoResultado,
  aoFinalizar?: () => void,
}

const PlanoCuidadoResultadosForm = (props: IPlanoCuidadoResultadosFormProps) => {
  const { prontuario, loadProntuario } = useContext(ProntuarioContext)

  const initialValues: IProntuarioPlanoCuidadoResultadoForm = {
    grupo: props.grupo,
    idDiagnostico: props.idDiagnostico,
    idResultado: props.editar && props.dadosResultados ? props.dadosResultados.idResultado : -1,
    idIndicador: props.editar && props.dadosResultados ? props.dadosResultados.idIndicador : -1,
    valor: props.editar && props.dadosResultados ? props.dadosResultados.valor : null,
    esperado: props.editar && props.dadosResultados ? props.dadosResultados.esperado : null,
  }

  const handleSubmit = (
    values: IProntuarioPlanoCuidadoResultadoForm,
    actions: FormikHelpers<IProntuarioPlanoCuidadoResultadoForm>
  ) => {
    if (prontuario) {
      if (!props.editar) {
        prontuarioPlanoCuidadoResultadoCreateAPI(props.idPlanoCuidado, values)
          .then(() => {
            loadProntuario(prontuario.id)
            actions.resetForm()
            Swal.fire({
              icon: 'success',
              title: 'Ok...',
              text: 'Resultado inserida com sucesso!',
              confirmButtonColor: '#1A8C01',
            })
          })
          .catch(err => {
            console.log(err)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.error,
            });
          })
      } else if (props.dadosResultados) {
        prontuarioPlanoCuidadoResultadoEditAPI(props.dadosResultados.id, values)
          .then(() => {
            if (prontuario) loadProntuario(prontuario.id)
            actions.resetForm()
            if (props.aoFinalizar) props.aoFinalizar()
            Swal.fire({
              icon: 'success',
              title: 'Ok...',
              text: 'Resultado editado com sucesso!',
              confirmButtonColor: '#1A8C01',
            })
          })
          .catch(err => {
            console.log(err)
  
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.error,
            });
          })
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      
      <PlanoCuidadoResultadosFormCampos
        editar={props.editar}
      />
    </Formik>
  )
}

export { PlanoCuidadoResultadosForm }
export default PlanoCuidadoResultadosForm
