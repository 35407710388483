import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'

const ProntuarioRelatorio = () => {
  const { prontuario } = useContext(ProntuarioContext);
  const navigate = useNavigate()

  return (
    <>
      {prontuario?.paciente?.id && (
        <>
          <Button style={{ background: '#03549E', opacity: '0.7', marginLeft: '8px' }}
            variant='secondary'
            onClick={() => navigate(
              '/prontuario/impressao',
              { state: { pacienteId: prontuario?.paciente?.id, pacienteNome: prontuario?.paciente?.nome } }
            )}
          >
            Imprimir
          </Button>
        </>
      )}
    </>
  )
}

export { ProntuarioRelatorio }
export default ProntuarioRelatorio
