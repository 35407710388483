import React from 'react';
import { useField } from 'formik';
import AsyncSelect from 'react-select/async';
// import {SingleValue} from 'react-select/dist/declarations/src';

import { ISelectChoice } from '../select-async';

interface PropsType {
  [x: string]: any,
  name: string,
  strong?: boolean,
  colorLabel?: string,
  colorOption?: string,
  desabilitado?: boolean,
  api: (filter?: string) => Promise<ISelectChoice[] | null>,
  onChange: (valor: any) => void,
}

const SelectSearchAsync = ({
  label,
  ...props
}: PropsType) => {
  const [field] = useField({name: props.name});

  const loadOptions = (
      inputValue: string,
      callback: (options: ISelectChoice[]) => void,
  ) => {
    props.api(inputValue)
        .then((data) => {
          if (data) {
            callback(data);
          }
        }).catch((err) => console.log(err));
  };

  return (
    <div>
      <label style={{ color: props.colorLabel || '' }}>
        {props.strong ? (
          <strong>{label}</strong>
        ) : (
          <>{label}</>
        )}
      </label>

      <AsyncSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isDisabled ? '#696E63' : '#FFFFFF',
            color: '#000',
            padding: 8,
            borderRadius: 12,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: '#000',
          }),
          input: (provided, state) => ({
            ...provided,
            color: '#000',
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            color: '#FFF7E6',
            backgroundColor: '#FFFFFF',
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: props.colorOption || '#FFF7E6',
            backgroundColor: state.isFocused ? '#817f7f' : '#FFFFFF',
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '8px',
          }),
        }}
        defaultOptions
        placeholder={props.placeholder}
        loadOptions={loadOptions}
        noOptionsMessage={() => <>{props.noOptionsMessage}</>}
        loadingMessage={() => <>Carregando...</>}
        name={field.name}
        isClearable
        isDisabled={props.desabilitado}
        onChange={props.onChange}
      />
    </div>
  );
};

export { SelectSearchAsync }
export default SelectSearchAsync;
