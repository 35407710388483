import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../prontuario/types'

export interface IDiagnosticoListagem {
  id: number,
  descricao: string,
  inferido: boolean,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

export const diagnosticoListarAPI = async (): Promise<IDiagnosticoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IDiagnosticoListagem[]> = await axiosAPI.get(
      'diagnostico',
      { params: { id_empresa: user.idEmpresa } },
  )
  console.log( response.data)
  return response.data
}

export const getAllToSelectDiagnosticoAPI = async (grupo: GrupoProntuarioPlanoCuidadoEnum | -1): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IDiagnosticoListagem[]> = await axiosAPI.get(
      'diagnostico',
      { params: { id_empresa: user.idEmpresa, grupo: grupo !== -1 ? grupo : undefined } }
  )

  if (response.data) {
    let x = 0
    return response.data.map((item, index) => { 
      console.log({"itemDescricao":item.descricao})
      console.log({"valor: ": item.id })
      return {label: item.descricao, value: index}
    })
  }

  return response.data
}


export const diagnosticoCreateAPI = async (
  descricao: string,
  inferido: boolean,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
): Promise<IDiagnosticoListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'diagnostico',
      { descricao, inferido, ativo, id_empresa: user.idEmpresa, grupo: grupo !== -1 ? grupo : null },
  )

  return response.data
}

export const diagnosticoUpdateAPI = async (
    id: number,
    descricao: string,
    inferido: boolean,
    ativo: boolean,
    grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
): Promise<IDiagnosticoListagem[]> => {
  const response = await axiosAPI.put(
      'diagnostico/edit',
      {id, descricao, ativo, inferido, grupo: grupo !== -1 ? grupo : null},
  )

  return response.data
}

export const diagnosticoDeleteAPI = async (id: number): Promise<IDiagnosticoListagem[]> => {
  const response = await axiosAPI.delete('diagnostico/delete', { params: { id } })

  return response.data
}
