import { AxiosResponse } from 'axios'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { IProntuarioListagem } from '../prontuario'
import { IProfissionalListagem, IProfissionalListagemBackend, parseProfissionalBackToFront } from './profissionaisAPI'
import { parseProntuarioBackToFront } from '../prontuario/parser'
import { IProntuarioListagemBackend } from '../prontuario/types'

export interface IUsuarioPontoProntuario {
  id: number,
  descricao: string,
  prontuario: IProntuarioListagem,
  idProntuario: number,
  idUsuarioPonto: number,
}

export interface IUsuarioPontoListagem {
  id: number,
  inicio: Date,
  fim: Date | null,
  usuario: IProfissionalListagem,
  idUsuario: number,
  usuarioPontoProntuario: IUsuarioPontoProntuario[],
}

interface IUsuarioPontoProntuarioBackend {
  id: number,
  descricao: string,
  prontuario: IProntuarioListagemBackend,
  id_prontuario: number,
  id_usuario_ponto: number,
}

interface IUsuarioPontoListagemBackend {
  id: number,
  inicio: string,
  fim: string | null,
  usuario: IProfissionalListagemBackend,
  id_usuario: number,
  UsuarioPontoProntuario: IUsuarioPontoProntuarioBackend[],
}

interface IUsuarioPontoProntuarioForm {
  prontuarioId: number,
  observacoes: string | null,
  pacienteNome: string,
  pacienteId: number,
}

export interface IBaterPontoForm {
  inicio: Date;
  fim: Date | null;
  usuarioPontoProntuario: IUsuarioPontoProntuarioForm[],
  adicionarTodos: boolean;
}

const parseUsuarioPontoProntuario = (dadosBackend: IUsuarioPontoProntuarioBackend): IUsuarioPontoProntuario => ({
  id: dadosBackend.id,
  descricao: dadosBackend.descricao,
  prontuario: parseProntuarioBackToFront(dadosBackend.prontuario),
  idProntuario: dadosBackend.id_prontuario,
  idUsuarioPonto: dadosBackend.id_usuario_ponto,
})

const parseUsuarioPontoBackToFront = (dadosBackend: IUsuarioPontoListagemBackend): IUsuarioPontoListagem => ({
  id: dadosBackend.id,
  inicio: new Date(dadosBackend.inicio),
  fim: dadosBackend.fim ? new Date(dadosBackend.fim) : null,
  usuario: parseProfissionalBackToFront(dadosBackend.usuario),
  idUsuario: dadosBackend.id_usuario,
  usuarioPontoProntuario: dadosBackend.UsuarioPontoProntuario?.map(upp => parseUsuarioPontoProntuario(upp)),

})

export const pontoProfissionalListarAPI = async (): Promise<IUsuarioPontoListagem | null> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IUsuarioPontoListagemBackend> = await axiosAPI.get(
      'userponto/ultimoponto',
      { params: { id_empresa: user.idEmpresa, id_usuario: user.id } },
  )

  return response.data ? parseUsuarioPontoBackToFront(response.data) : null
}

export const pontoProfissionalCriarAPI = async (
  values: IBaterPontoForm
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'userponto',
      {
        id_usuario: user.id,
        inicio: values.inicio,
        fim: values.fim,
        ponto_usuario_prontuarios: values.usuarioPontoProntuario
      }
  )
}

export const pontoProfissionalEditarAPI = async (
  pontoId: number,
  inicio?: Date,
  fim?: Date
): Promise<void> => {
  await axiosAPI.put(
      'userponto/edit',
      {
        id: pontoId,
        inicio,
        fim,
      }
  )
}

export const pontoProfissionalProntuarioCriarAPI = async (
  prontuarioId: number,
  usuarioPontoId: number
): Promise<void> => {
  await axiosAPI.post(
      'usuariopontoprontuario',
      {
        id_prontuario: prontuarioId,
        id_usuario_ponto: usuarioPontoId,
      }
  )
}

export const pontoProfissionalProntuarioEditarAPI = async (
  pontoProfissionalProntuarioId: number,
  observaoes: string
): Promise<void> => {
  await axiosAPI.put(
      'usuariopontoprontuario/edit',
      {
        id: pontoProfissionalProntuarioId,
        descricao: observaoes,
      }
  )
}

export const pontoProfissionalProntuarioDeleteAPI = async (
  pontoProfissionalProntuarioId: number,
): Promise<void> => {
  await axiosAPI.delete(
      `usuariopontoprontuario/delete?id=${pontoProfissionalProntuarioId}`,
  )
}

// export const profissionalCreateAPI = async (
//   nome: string,
//   ativo: boolean,
//   senha: string,
//   tipo: ProfissionalEnum,
//   pontos: number,
//   acessoUti: boolean,
//   aptidoes: string[],
//   contato?: string | null,
//   email?: string | null,
//   urlAvatar?: string | null,
//   cpfCnpj?: string | null,
// ): Promise<void> => {
//   const user = getUserLocalStorage()
//   await axiosAPI.post(
//       'users',
//       {
//         id_empresa: user.idEmpresa,
//         nome,
//         ativo,
//         senha,
//         tipo,
//         pontos,
//         acesso_uti: acessoUti,
//         contato,
//         email,
//         urlAvatar,
//         cpfCnpj,
//         aptidoes,
//       },
//   )
// }

// export const profissionalUpdateAPI = async (
//     id: number,
//     nome: string,
//     ativo: boolean,
//     senha: string,
//     tipo: ProfissionalEnum,
//     pontos: number,
//     acessoUti: boolean,
//     aptidoes: string[],
//     contato?: string | null,
//     email?: string | null,
//     urlAvatar?: string | null,
//     cpfCnpj?: string | null,
// ): Promise<void> => {
//   const user = getUserLocalStorage()

//   await axiosAPI.put(
//       'users/edit',
//       {
//         id,
//         nome,
//         ativo,
//         tipo,
//         pontos,
//         acesso_uti: acessoUti,
//         contato,
//         email,
//         urlAvatar,
//         cpfCnpj,
//         aptidoes,
//         id_empresa: user.idEmpresa,
//       },
//   )
// }
