import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { Col, Row, Spinner } from 'react-bootstrap'
import { FaSave } from 'react-icons/fa'

import { prontuarioRegistroComplementarPAVCreateAPI } from '../../../../../../api/prontuario/prontuarioRegistroComplementarAPI'
import { IProntuarioRegistroComplementarPAVForm, SimNaoNaoAplicaEnum, SimNaoNaoSimplesEnum } from '../../../../../../api/prontuario/types'
import Button from '../../../../../../components/Button'
import { ProntuarioContext } from '../../../context'
import { Switch } from '../../../../../../components'

const RegistroComplementarPAV = () => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);

  const initialValues: IProntuarioRegistroComplementarPAVForm = {
    higieneOralRealizada: prontuario?.prontuarioRegistroComplementarPAV[0]?.higieneOralRealizada || SimNaoNaoAplicaEnum.NAO_APLICA,
    cabeceiraLeito_30_45: prontuario?.prontuarioRegistroComplementarPAV[0]?.cabeceiraLeito_30_45 || SimNaoNaoAplicaEnum.NAO_APLICA,
    ventilorioDesprezados: prontuario?.prontuarioRegistroComplementarPAV[0]?.ventilorioDesprezados || SimNaoNaoAplicaEnum.NAO_APLICA,
    higieneMaos: prontuario?.prontuarioRegistroComplementarPAV[0]?.higieneMaos || SimNaoNaoAplicaEnum.NAO_APLICA,
    totFixado: prontuario?.prontuarioRegistroComplementarPAV[0]?.totFixado || SimNaoNaoAplicaEnum.NAO_APLICA,
    doencaPulmonar: prontuario?.prontuarioRegistroComplementarPAV[0]?.doencaPulmonar || false,
    rxTorax: prontuario?.prontuarioRegistroComplementarPAV[0]?.rxTorax || false,
    filtradoOpacificacao: prontuario?.prontuarioRegistroComplementarPAV[0]?.filtradoOpacificacao || SimNaoNaoAplicaEnum.NAO_APLICA,
    teveFebreMaior_38: prontuario?.prontuarioRegistroComplementarPAV[0]?.teveFebreMaior_38 || SimNaoNaoSimplesEnum.NAO,
    leocopeniaMenor_4000: prontuario?.prontuarioRegistroComplementarPAV[0]?.leocopeniaMenor_4000 || SimNaoNaoSimplesEnum.NAO,
    leococitoseMaior_12000: prontuario?.prontuarioRegistroComplementarPAV[0]?.leococitoseMaior_12000 || SimNaoNaoSimplesEnum.NAO,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioRegistroComplementarPAVForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioRegistroComplementarPAVCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de PAV associado a VM incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }
  return (
    <div style={{ marginTop: '16px'}}>
      <Formik
        key={formKey}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
          <>
            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              A higiene oral foi realizada nas últimas 24h?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneOralRealizadaSIM'
                  name='higieneOralRealizada'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.higieneOralRealizada === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneOralRealizadaSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneOralRealizadaNAO'
                  name='higieneOralRealizada'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.higieneOralRealizada === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneOralRealizadaNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneOralRealizadaNAO_APLICA'
                  name='higieneOralRealizada'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.higieneOralRealizada === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneOralRealizadaNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              A cabeceira do leito encontra-se entre 30 a 45° nas últimas 24h?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cabeceiraLeito_30_45SIM'
                  name='cabeceiraLeito_30_45'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.cabeceiraLeito_30_45 === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cabeceiraLeito_30_45SIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cabeceiraLeito_30_45NAO'
                  name='cabeceiraLeito_30_45'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.cabeceiraLeito_30_45 === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cabeceiraLeito_30_45NAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cabeceiraLeito_30_45NAO_APLICA'
                  name='cabeceiraLeito_30_45'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.cabeceiraLeito_30_45 === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cabeceiraLeito_30_45NAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Os condensados no sistema ventilatório foram desprezados?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='ventilorioDesprezadosSIM'
                  name='ventilorioDesprezados'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.ventilorioDesprezados === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="ventilorioDesprezadosSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='ventilorioDesprezadosNAO'
                  name='ventilorioDesprezados'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.ventilorioDesprezados === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="ventilorioDesprezadosNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='ventilorioDesprezadosNAO_APLICA'
                  name='ventilorioDesprezados'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.ventilorioDesprezados === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="ventilorioDesprezadosNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Foi realizada higiene das mãos antes da manipulação do TOT?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneMaosSIM'
                  name='higieneMaos'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.higieneMaos === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneMaosSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneMaosNAO'
                  name='higieneMaos'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.higieneMaos === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneMaosNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='higieneMaosNAO_APLICA'
                  name='higieneMaos'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.higieneMaos === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="higieneMaosNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O TOT está fixado adequadamente?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='totFixadoSIM'
                  name='totFixado'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.totFixado === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="totFixadoSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='totFixadoNAO'
                  name='totFixado'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.totFixado === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="totFixadoNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='totFixadoNAO_APLICA'
                  name='totFixado'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.totFixado === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="totFixadoNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Sinais e sintomas relacionados a ICS:
            </div>
            <div style={{ fontWeight: '500' }}>
              1.Paciente com doença pulmonar ou cardíaca de base?
            </div>
            <Row>
              <Col md={2}>
                <Switch
                  label=''
                  name='doencaPulmonar'
                  checked={values.doencaPulmonar || false}
                  onChange={(value) => setFieldValue('doencaPulmonar', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              2. Fez raio-x de tórax?
            </div>
            <Row>
              <Col md={2}>
                <Switch
                  label=''
                  name='rxTorax'
                  checked={values.rxTorax || false}
                  onChange={(value) => setFieldValue('rxTorax', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              3. Se sim na opção anterior, apresentou infiltrado, opacificação ou cavitação?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='filtradoOpacificacaoSIM'
                  name='filtradoOpacificacao'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.filtradoOpacificacao === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="filtradoOpacificacaoSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='filtradoOpacificacaoNAO'
                  name='filtradoOpacificacao'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.filtradoOpacificacao === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="filtradoOpacificacaoNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='filtradoOpacificacaoNAO_APLICA'
                  name='filtradoOpacificacao'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.filtradoOpacificacao === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="filtradoOpacificacaoNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '500', marginTop: '16px' }}>
              {`4. Teve febre (temp > 38°C)?`}
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='teveFebreMaior_38SIM'
                  name='teveFebreMaior_38'
                  value={SimNaoNaoSimplesEnum.SIM}
                  checked={values.teveFebreMaior_38 === SimNaoNaoSimplesEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="teveFebreMaior_38SIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='teveFebreMaior_38NAO'
                  name='teveFebreMaior_38'
                  value={SimNaoNaoSimplesEnum.NAO}
                  checked={values.teveFebreMaior_38 === SimNaoNaoSimplesEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="teveFebreMaior_38NAO">
                  NÃO
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '500', marginTop: '16px' }}>
              {`5. Tem Leucopenia < 4000 cel/mm³?`}
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='leocopeniaMenor_4000SIM'
                  name='leocopeniaMenor_4000'
                  value={SimNaoNaoSimplesEnum.SIM}
                  checked={values.leocopeniaMenor_4000 === SimNaoNaoSimplesEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="leocopeniaMenor_4000SIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='leocopeniaMenor_4000NAO'
                  name='leocopeniaMenor_4000'
                  value={SimNaoNaoSimplesEnum.NAO}
                  checked={values.leocopeniaMenor_4000 === SimNaoNaoSimplesEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="leocopeniaMenor_4000NAO">
                  NÃO
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              {`6. Tem Leucocitose > 12000 cel/mm³?`}
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='leococitoseMaior_12000SIM'
                  name='leococitoseMaior_12000'
                  value={SimNaoNaoSimplesEnum.SIM}
                  checked={values.leococitoseMaior_12000 === SimNaoNaoSimplesEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="leococitoseMaior_12000SIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='leococitoseMaior_12000NAO'
                  name='leococitoseMaior_12000'
                  value={SimNaoNaoSimplesEnum.NAO}
                  checked={values.leococitoseMaior_12000 === SimNaoNaoSimplesEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="leococitoseMaior_12000NAO">
                  NÃO
                </label>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
              { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  </div>
                  )}
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  )
}

export { RegistroComplementarPAV }
export default RegistroComplementarPAV
