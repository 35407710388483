import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface IEmpresaListagemBackend {
  id: number,
  nome: string,
  ativo: boolean,
  cnpj: string,
  ie?: string | null,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  url_canal_suporte?: string | null
}

export interface IEmpresaListagem {
  id: number,
  nome: string,
  ativo: boolean,
  cnpj: string,
  ie?: string | null,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  urlCanalSuporte?: string | null
}

const parseEmpresaBackToFront = (dadoBack: IEmpresaListagemBackend): IEmpresaListagem => ({
  id: dadoBack.id,
  nome: dadoBack.nome,
  ativo: dadoBack.ativo,
  cnpj: dadoBack.cnpj,
  ie: dadoBack.ie,
  contato: dadoBack.contato,
  email: dadoBack.email,
  urlAvatar: dadoBack.urlAvatar,
  urlCanalSuporte: dadoBack.url_canal_suporte,
})

export const empresaListarAPI = async (): Promise<IEmpresaListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IEmpresaListagemBackend[]> = await axiosAPI.get(
      'empresa',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data.map(dado => parseEmpresaBackToFront(dado))
}

export const getAllToSelectEmpresaAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IEmpresaListagem[]> = await axiosAPI.get(
      'empresa',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const empresaCreateAPI = async (
  nome: string,
  ativo: boolean,
  cnpj: string,
  ie?: string | null,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  urlCanalSuporte?: string | null
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'empresa',
      {
        nome,
        ativo,
        id_empresa: user.idEmpresa,
        cnpj,
        ie,
        contato,
        email,
        urlAvatar,
        url_canal_suporte: urlCanalSuporte,
      },
  )
}

export const empresaUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
    cnpj: string,
    ie?: string | null,
    contato?: string | null,
    email?: string | null,
    urlAvatar?: string | null,
    urlCanalSuporte?: string | null
): Promise<void> => {
  await axiosAPI.put(
      'empresa/edit',
      {
        id,
        nome,
        ativo,
        cnpj,
        ie,
        contato,
        email,
        urlAvatar,
        url_canal_suporte: urlCanalSuporte,
      },
  )
}

export const empresaDeleteAPI = async (id: number): Promise<void> => {
  await axiosAPI.delete('empresa/delete', { params: { id } })
}
