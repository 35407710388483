import React, { FormEvent, useContext } from 'react'

import { CheckboxStyled, InputStyled } from '../../../components/styles'
import { PacientesContext } from '../context'
import styled from 'styled-components'

const DivStyledCheckbox = styled.div `
  display: flex;
  align-items: center;
  margin-right: 12px;
`
const PacientesFiltros = () => {
  const { filtros, setFiltros } = useContext(PacientesContext);

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const name: string = e.currentTarget.getAttribute('name') as string;
    const value = ['saiu', 'clinicaMedica', 'clinicaCirurgica', 'uti'].includes(name) ?
      e.currentTarget.checked : e.currentTarget.value;
    setFiltros({...filtros, [name]: value});
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'left' }}>
      <DivStyledCheckbox>
        <CheckboxStyled
          className="form-check-input form-control"
          style={{ marginRight: '6px' }}
          type="checkbox"
          id="saiuCheckbox"
          name='saiu'
          checked={filtros.saiu}
          onChange={(value) => handleInputOnChange(value)}
        />

        <label className="form-check-label form-label mb-0" htmlFor="ativoCheckbox">
          Saiu / Alta
        </label>
      </DivStyledCheckbox>

      <DivStyledCheckbox>
        <CheckboxStyled
          className="form-check-input form-control"
          style={{ marginRight: '6px' }}
          type="checkbox"
          id="clinicaMedicaCheckbox"
          name='clinicaMedica'
          checked={filtros.clinicaMedica}
          onChange={(value) => handleInputOnChange(value)}
        />

        <label className="form-check-label form-label mb-0" htmlFor="ativoCheckbox">
          Clín. médica
        </label>
      </DivStyledCheckbox>

      <DivStyledCheckbox>
        <CheckboxStyled
          className="form-check-input form-control"
          style={{ marginRight: '6px' }}
          type="checkbox"
          id="clinicaCirurgicaCheckbox"
          name='clinicaCirurgica'
          checked={filtros.clinicaCirurgica}
          onChange={(value) => handleInputOnChange(value)}
        />

        <label className="form-check-label form-label mb-0" htmlFor="ativoCheckbox">
          Clín. cirúrgica
        </label>
      </DivStyledCheckbox>

      <DivStyledCheckbox>
        <CheckboxStyled
          className="form-check-input form-control"
          style={{ marginRight: '6px' }}
          type="checkbox"
          id="utiCheckbox"
          name='uti'
          checked={filtros.uti}
          onChange={(value) => handleInputOnChange(value)}
        />

        <label className="form-check-label form-label mb-0" htmlFor="ativoCheckbox">
          UTI
        </label>
      </DivStyledCheckbox>
      
      <InputStyled
        placeholder='Pesquise pelo nome do paciente'
        style={{ width: '350px' }}
        name='nome'
        value={filtros.nome}
        onChange={(value) => handleInputOnChange(value)}
      />
    </div>
  )
}

export { PacientesFiltros }
export default PacientesFiltros