import {
  IEdemaLocalDescList,
  IEdemaLocalDescListBackend,
  IProntuarioFuncaoCardiovascularListagem,
  IProntuarioFuncaoCardiovascularListagemBackend,
} from "../types";

export const parseProntuarioFuncaoCardiovascularBackToFront = (
  dadosBackend: IProntuarioFuncaoCardiovascularListagemBackend
): IProntuarioFuncaoCardiovascularListagem => ({
  id: dadosBackend.id,
  pulsoPeriferico: dadosBackend.pulso_periferico,
  pulsacaoPeriferica: dadosBackend.pulsacao_periferica,
  temEdema: dadosBackend.tem_edema,
  auscutaCardiaca: dadosBackend.auscuta_cardiaca,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
  edemaLocalDesc: dadosBackend.FuncaoCardiovascularEdema?.map(ed => parseEdemaLocalDescBackToFront(ed))
})

export const parseEdemaLocalDescFrontToBack = (
  dadosFrontend: IEdemaLocalDescList
): IEdemaLocalDescListBackend => ({
  descricao: dadosFrontend.descricao,
  edema: {
    id: dadosFrontend.idEdema,
    nome: dadosFrontend.local,
    ativo: true,
  },
  id_edema: dadosFrontend.idEdema,
})

export const parseEdemaLocalDescBackToFront = (
  dadosBackend: IEdemaLocalDescListBackend
): IEdemaLocalDescList => ({
  descricao: dadosBackend.descricao,
  idEdema: dadosBackend.id_edema,
  local: dadosBackend.edema?.nome,
})
