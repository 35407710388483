import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Alert, Card, Image } from 'react-bootstrap'
import * as Yup from 'yup'

import logoLogin from '../../components/logo/sol_login_SOL.png'
import { InputStyled } from '../../components/styles'
import { useAuth } from '../../context/auth_provider/useAuth'
import Button from '../../components/Button'
import EsqueciSenha from './EsqueciSenha'
import { CgPassword } from 'react-icons/cg'

interface ILoginInit {
  email?: string,
  password?: string
}

export const Login = () => {
  const [authError, setAuthError] = useState<boolean>(false)
  const [authKey, setAuthKey] = useState<number>(0)
  const initialValues: ILoginInit = {}
  const auth = useAuth()
  const navigate = useNavigate()
  const [modalEsqueciSenhaAberto, setModalEsqueciSenhaAberto] = useState<boolean>(false) 

  const schema = Yup.object().shape({
    email: Yup.string().email('Digite um email válido!').required('Campo obrigatório!'),
    password: Yup.string().required('Campo obrigatório!'),
  }) 
  const onSubmit = async (values: ILoginInit) => {
    if (values.email && values.password) {
      console.log(values.password)
      try {
        await auth.authenticate(values.email, values.password)
        setAuthError(false)
        setAuthKey((prev) => prev + 1)
        navigate('/', {replace: true})
      } catch (error) {
        setAuthError(true)
        setAuthKey((prev) => prev + 1)
      }
    }
  } 
  return (
    <main style={{
      width: '100%',
      height: '100%',
      margin: '0',
      background: '#a9e2c3' /*'#feffbf'*/,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Card style={{
        width: '300px',
        marginTop: '16px',
        textAlign: 'center',
        padding: '30px 20px',
        backgroundColor: '#304439',
        borderRadius: '10px',
      }}>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            setTouched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
            <Form>
              <Card.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Image src={logoLogin} alt='Logo da empresa' width={170} height={130} style={{ marginBottom: '28px' }} />

                {authError && authKey > 0 && (
                  <Alert variant='danger' key={authKey} style={{padding: '14px', fontSize: '14px'}}>
                    E-mail ou senha inválidos!
                  </Alert>
                )}
                <div style={{marginBottom: '16px'}}>
                  <InputStyled
                    placeholder='Informe seu usuário'
                    type='text'
                    className='form-input form-control'
                    id='email'
                    name='email'
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email && (
                    <div style={{color: '#FF264D'}}>{errors.email}</div>
                  )}
                </div>

                <div style={{marginBottom: '16px'}}>
                  <InputStyled
                    placeholder='Informe sua senha'
                    type='password'
                    className='form-input form-control'
                    id='password'
                    name='password'
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password && (
                    <div style={{color: '#FF264D'}}>{errors.password}</div>
                  )}
                </div>

                <Button
                  variant='primary'
                  style={{width: '190px', marginTop: '20px'}}
                  onClick={() => handleSubmit()}
                >
                  Entrar
                </Button>
                <div
                  style={{ cursor: 'pointer', color: '#1f78b3', fontSize: '12px', marginTop: '6px' }}
                  onClick={() => setModalEsqueciSenhaAberto(true)}
                >
                  Esqueci minha senha
                </div>
              </Card.Body>
            </Form>
          )}}
        </Formik>        
      </Card>
      <EsqueciSenha
          modalAberto={modalEsqueciSenhaAberto}
          fecharModal={() => setModalEsqueciSenhaAberto(false)}
        />
    </main>
  )
}
