import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'
// import { format } from 'date-fns'

import {
  IProntuarioCuidadoCorporalForm,
  prontuarioCuidadoCorporalCreateAPI,
} from '../../../../../api/prontuario/prontuarioCuidadoCorporalAPI'
import { Switch } from '../../../../../components'
// import { TableStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { verificarAvaliadoNoDia } from '../../util'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

interface IProntuarioCuidadoCorporalProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioCuidadoCorporal = (props: IProntuarioCuidadoCorporalProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.CUIDADO_CORPORAL)

  const initialValues: IProntuarioCuidadoCorporalForm = {
    dependencia: prontuario?.prontuarioCuidadoCorporal[0]?.dependencia || null,
    corporalPrejudicada: prontuario?.prontuarioCuidadoCorporal[0]?.corporalPrejudicada || null,
    oralPrejudicada: prontuario?.prontuarioCuidadoCorporal[0]?.oralPrejudicada || null,
    intimaPrejudicada: prontuario?.prontuarioCuidadoCorporal[0]?.intimaPrejudicada || null,
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioCuidadoCorporal[0]?.avaliado),
    alterado: prontuario?.prontuarioCuidadoCorporal[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioCuidadoCorporalForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioCuidadoCorporalCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de cuidao corporal incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('sexualidade'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioCuidadoCorporal[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioCuidadoCorporal[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('cuidadoCorporal')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Cuidado corporal</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'cuidadoCorporal') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'cuidadoCorporal') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={1} style={{ alignSelf: 'flex-end' }}>
                  <Switch
                    label='Não avaliado'
                    name='stAvaliado'
                    checked={values.stAvaliado || false}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('avaliado', new Date(), false)
                      } else {
                        setFieldValue('avaliado', null, false)
                      }
                      setFieldValue('stAvaliado', value, false)
                    }}
                  />
                </Col>

                <Col md={10}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={2}>
                      <Switch
                        label='Dependência p/ autocuidado'
                        name='dependencia'
                        checked={values.dependencia || false}
                        onChange={(value) => setFieldValue('dependencia', value, false)}
                      />
                    </Col>

                    <Col md={2}>
                      <Switch
                        label='Higiene corporal prejudicada'
                        name='corporalPrejudicada'
                        checked={values.corporalPrejudicada || false}
                        onChange={(value) => setFieldValue('corporalPrejudicada', value, false)}
                      />
                    </Col>

                    <Col md={2}>
                      <Switch
                        label='Higiene oral prejudicada'
                        name='oralPrejudicada'
                        checked={values.oralPrejudicada || false}
                        onChange={(value) => setFieldValue('oralPrejudicada', value, false)}
                      />
                    </Col>

                    <Col md={2}>
                      <Switch
                        label='Higiene íntima prejudicada'
                        name='intimaPrejudicada'
                        checked={values.intimaPrejudicada || false}
                        onChange={(value) => setFieldValue('intimaPrejudicada', value, false)}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={1} style={{ alignSelf: 'flex-end' }}>
                  <Switch
                    label='Alterado'
                    name='alterado'
                    checked={values.alterado || false}
                    onChange={(value) => setFieldValue('alterado', value, false)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>           
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>                  
                  </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Formik>

        {/*<hr />

        {prontuario?.prontuarioCuidadoCorporal && prontuario?.prontuarioCuidadoCorporal.length > 0 && (
          <TableStyled>
            <thead>
              <tr>
                <th>Data</th>
                <th>Usuário</th>
                <th>Depend. p/ autocuid.</th>
                <th>Hig. corp. prejudi.</th>
                <th>Hig. oral prejudi.</th>
                <th>Hig. ínt. prejudi. </th>
                <th>Avaliado</th>
                <th>Alterado</th>
              </tr>
            </thead>

            <tbody>
              {prontuario?.prontuarioCuidadoCorporal.map(cuidado => {
                return (
                  <tr key={cuidado.id}>
                    <td style={{ whiteSpace: 'nowrap' }}>{cuidado.avaliado ? format(new Date(cuidado.avaliado), 'dd/MM/yyyy hh:mm:ss') : ''}</td>
                    <td>Usuário</td>
                    <td>{cuidado.dependencia ? 'Sim' : 'Nao'}</td>
                    <td>{cuidado.corporalPrejudicada ? 'Sim' : 'Nao'}</td>
                    <td>{cuidado.oralPrejudicada ? 'Sim' : 'Nao'}</td>
                    <td>{cuidado.intimaPrejudicada ? 'Sim' : 'Nao'}</td>
                    <td>{cuidado.avaliado ? 'Sim' : 'Nao'}</td>
                    <td>{cuidado.alterado ? 'Sim' : 'Nao'}</td>
                  </tr>
                )
              })}
            </tbody>
          </TableStyled>
            )}*/}
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioCuidadoCorporal }
export default ProntuarioCuidadoCorporal
