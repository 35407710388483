import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioFuncaoRespiratoriaForm,
  prontuarioFuncaoRespiratoriaCreateAPI,
} from '../../../../../api/prontuario/prontuarioFuncaoRespiratoriaAPI'
import { Select, Switch } from '../../../../../components'
import { InputStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { SelectAsync } from '../../../../../components/select-async'
import { getAllToSelectRespiracaoAPI } from '../../../../../api/cadastro/respiracoesAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

interface IProntuarioFuncaoRespiratoriaProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioFuncaoRespiratoria = (props: IProntuarioFuncaoRespiratoriaProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.FUNCAO_RESPIRATORIA)

  const initialValues: IProntuarioFuncaoRespiratoriaForm = {
    co2Ambiente: prontuario?.prontuarioFuncaoRespiratoria[0]?.co2Ambiente || null,
    cateterNasal: prontuario?.prontuarioFuncaoRespiratoria[0]?.cateterNasal || null,
    outroDispositivo: prontuario?.prontuarioFuncaoRespiratoria[0]?.outroDispositivo || null,
    cateterNasalLmin: prontuario?.prontuarioFuncaoRespiratoria[0]?.cateterNasalLmin || null,
    mv: prontuario?.prontuarioFuncaoRespiratoria[0]?.mv || null,
    mvPerc: prontuario?.prontuarioFuncaoRespiratoria[0]?.mvPerc || null,
    vni: prontuario?.prontuarioFuncaoRespiratoria[0]?.vni || null,
    vniTipo: prontuario?.prontuarioFuncaoRespiratoria[0]?.vniTipo || null,
    auscutaPulmonar: prontuario?.prontuarioFuncaoRespiratoria[0]?.auscutaPulmonar || null,
    tot: prontuario?.prontuarioFuncaoRespiratoria[0]?.tot || null,
    totNumero: prontuario?.prontuarioFuncaoRespiratoria[0]?.totNumero || null,
    totDias: prontuario?.prontuarioFuncaoRespiratoria[0]?.totDias || null,
    mnrLmin: prontuario?.prontuarioFuncaoRespiratoria[0]?.mnrLmin || null,
    secrecao: prontuario?.prontuarioFuncaoRespiratoria[0]?.secrecao || null,
    secrecaoTipo: prontuario?.prontuarioFuncaoRespiratoria[0]?.secrecaoTipo || null,
    tqt: prontuario?.prontuarioFuncaoRespiratoria[0]?.tqt || null,
    tqtNumero: prontuario?.prontuarioFuncaoRespiratoria[0]?.tqtNumero || null,
    tqtDias: prontuario?.prontuarioFuncaoRespiratoria[0]?.tqtDias || null,
    mnr: prontuario?.prontuarioFuncaoRespiratoria[0]?.mnr || null,
    tosse: prontuario?.prontuarioFuncaoRespiratoria[0]?.tosse || null,
    tosseTipo: prontuario?.prontuarioFuncaoRespiratoria[0]?.tosseTipo || null,
    vmi: prontuario?.prontuarioFuncaoRespiratoria[0]?.vmi || null,
    vmiDesmami: prontuario?.prontuarioFuncaoRespiratoria[0]?.vmiDesmami || null,
    gasometria: prontuario?.prontuarioFuncaoRespiratoria[0]?.gasometria || null,
    ph: prontuario?.prontuarioFuncaoRespiratoria[0]?.ph || null,
    hco3: prontuario?.prontuarioFuncaoRespiratoria[0]?.hco3 || null,
    mviModo: prontuario?.prontuarioFuncaoRespiratoria[0]?.mviModo || null,
    peep: prontuario?.prontuarioFuncaoRespiratoria[0]?.peep || null,
    fio2: prontuario?.prontuarioFuncaoRespiratoria[0]?.fio2 || null,
    frProgramada: prontuario?.prontuarioFuncaoRespiratoria[0]?.frProgramada || null,
    pco2: prontuario?.prontuarioFuncaoRespiratoria[0]?.pco2 || null,
    so2: prontuario?.prontuarioFuncaoRespiratoria[0]?.so2 || null,
    po2: prontuario?.prontuarioFuncaoRespiratoria[0]?.po2 || null,
    idRespiracao: prontuario?.prontuarioFuncaoRespiratoria[0]?.idRespiracao || null,
    avaliado: new Date(),
    stAvaliado: !!prontuario?.prontuarioFuncaoRespiratoria[0]?.avaliado,
    alterado: prontuario?.prontuarioFuncaoRespiratoria[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioFuncaoRespiratoriaForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioFuncaoRespiratoriaCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Função respiratória incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('funcaoCardiovascular'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioFuncaoRespiratoria[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioFuncaoRespiratoria[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('funcaoRespiratoria')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Função respiratória</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'funcaoRespiratoria') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'funcaoRespiratoria') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={3}>
                  <Switch
                    label='O2 ambiente'
                    name='co2Ambiente'
                    checked={values.co2Ambiente || false}
                    onChange={(value) => setFieldValue('co2Ambiente', value, false)}
                  />
                </Col>

                <Col md={4}>
                  <Row>
                    <Col md={5}>
                      <Switch
                        label='Cateter nasal'
                        name='cateterNasal'
                        checked={values.cateterNasal || false}
                        onChange={(value) => setFieldValue('cateterNasal', value, false)}
                      />
                    </Col>

                    <Col md={7}>
                      <div style={{ marginBottom: '16px', marginLeft: '-22px' }}>
                        <label className="form-check-label form-label" htmlFor='cateterNasalLmin'>
                          L/min
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='cateterNasalLmin'
                          name='cateterNasalLmin'
                          value={values.cateterNasalLmin || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={6}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='outroDispositivo'>
                          Outro dispositivo
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='outroDispositivo'
                          name='outroDispositivo'
                          value={values.outroDispositivo || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <SelectAsync
                        id='idRespiracao'
                        label='Respiração'
                        value={values.idRespiracao || -1}
                        onChange={handleChange}
                        api={getAllToSelectRespiracaoAPI}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Row>
                    <Col md={4}>
                      <Switch
                        label='MV'
                        name='mv'
                        checked={values.mv || false}
                        onChange={(value) => setFieldValue('mv', value, false)}
                      />
                    </Col>

                    <Col md={8}>
                      <div style={{marginBottom: '16px', marginLeft: '8px' }}>
                        <label className="form-check-label form-label" htmlFor='mvPerc'>
                          MV(%)
                        </label>

                        <InputStyled
                          type='number'
                          className='form-input form-control w-100'
                          id='mvPerc'
                          name='mvPerc'
                          min={0}
                          max={100}
                          value={values.mvPerc || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={4}>
                  <Row>
                    <Col md={2}>
                      <Switch
                        label='VNI'
                        name='vni'
                        checked={values.vni || false}
                        onChange={(value) => setFieldValue('vni', value, false)}
                      />
                    </Col>

                    <Col md={10}>
                      <div style={{marginBottom: '16px', marginLeft: '32px' }}>
                        <label className="form-check-label form-label" htmlFor='vniTipo'>
                          VNI Tipo
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='vniTipo'
                          name='vniTipo'
                          value={values.vniTipo || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={12}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='auscutaPulmonar'>
                          Ausculta pulmonar
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='auscutaPulmonar'
                          name='auscutaPulmonar'
                          value={values.auscutaPulmonar || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={2}>
                      <Switch
                        label='TOT'
                        name='tot'
                        checked={values.tot || false}
                        onChange={(value) => setFieldValue('tot', value, false)}
                      />
                    </Col>

                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='totNumero'>
                          #TOT n°
                        </label>

                        <InputStyled
                          type='number'
                          className='form-input form-control w-100'
                          id='totNumero'
                          name='totNumero'
                          value={values.totNumero || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='totDias'>
                          TOT dias
                        </label>

                        <InputStyled
                          type='number'
                          className='form-input form-control w-100'
                          id='totDias'
                          name='totDias'
                          value={values.totDias || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={3}>
                      <Switch
                        label='Secreção'
                        name='secrecao'
                        checked={values.secrecao || false}
                        onChange={(value) => setFieldValue('secrecao', value, false)}
                      />
                    </Col>

                    <Col md={9}>
                      <Select
                        label='Tipo'
                        emptyLabel='Selecione...'
                        id='secrecaoTipo'
                        value={values.secrecaoTipo || ''}
                        options={[
                          { label: 'Esbranqueada', value: 'ESBRANQUEADA' },
                          { label: 'Amarelada', value: 'AMARELADA' },
                          { label: 'Esverdeada', value: 'ESVERDEADA' },
                          { label: 'Sanquinolenta', value: 'SANQUINOLENTA' },
                          { label: 'Outros', value: 'OUTROS' },
                        ]}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={2}>
                      <Switch
                        label='TQT'
                        name='tqt'
                        checked={values.tqt || false}
                        onChange={(value) => setFieldValue('tqt', value, false)}
                      />
                    </Col>
                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='tqtNumero'>
                          #TQT n°
                        </label>

                        <InputStyled
                          type='number'
                          className='form-input form-control w-100'
                          id='tqtNumero'
                          name='tqtNumero'
                          value={values.tqtNumero || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='tqtDias'>
                          TQT dias
                        </label>

                        <InputStyled
                          type='number'
                          className='form-input form-control w-100'
                          id='tqtDias'
                          name='tqtDias'
                          value={values.tqtDias || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={3}>
                      <Switch
                        label='Tosse'
                        name='tosse'
                        checked={values.tosse || false}
                        onChange={(value) => setFieldValue('tosse', value, false)}
                      />
                    </Col>

                    <Col md={9}>
                      <Select
                        label='Tipo'
                        emptyLabel='Selecione...'
                        id='tosseTipo'
                        value={values.tosseTipo || ''}
                        options={[
                          { label: 'Seca', value: 'SECA' },
                          { label: 'Cheia c/ expectoração', value: 'CHEIA_C_EXPECTORACAO' },
                          { label: 'Cheia s/ expectoração', value: 'CHEIA_S_EXPECTORACAO' },
                        ]}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={2}>
                      <Switch
                        label='VMI'
                        name='vmi'
                        checked={values.vmi || false}
                        onChange={(value) => setFieldValue('vmi', value, false)}
                      />
                    </Col>

                    <Col md={2}>
                      <Switch
                        label='VMI-Desmame'
                        name='vmiDesmami'
                        labelStyle={{ whiteSpace: 'nowrap' }}
                        checked={values.vmiDesmami || false}
                        onChange={(value) => setFieldValue('vmiDesmami', value, false)}
                      />
                    </Col>

                    <Col md={8}>
                      <Row>
                        <Col md={3} style={{ marginLeft: '28px'}}>
                          <Switch
                            label='MNR'
                            name='mnr'
                            checked={values.mnr || false}
                            onChange={(value) => setFieldValue('mnr', value, false)}
                          />
                        </Col>

                        <Col md={8}>
                          <div style={{marginBottom: '16px'}}>
                            <label className="form-check-label form-label" htmlFor='mnrLmin'>
                              L/min
                            </label>

                            <InputStyled
                              type='text'
                              className='form-input form-control w-100'
                              id='mnrLmin'
                              name='mnrLmin'
                              value={values.mnrLmin || ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={4}>
                      <Switch
                        label='Gasometria'
                        name='gasometria'
                        checked={values.gasometria || false}
                        onChange={(value) => setFieldValue('gasometria', value, false)}
                      />
                    </Col>

                    <Col md={4}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='ph'>
                          Ph
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='ph'
                          name='ph'
                          value={values.ph || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='hco3'>
                          HCO3
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='hco3'
                          name='hco3'
                          value={values.hco3 || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <Row>
                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='mviModo'>
                          VMI Modo
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='mviModo'
                          name='mviModo'
                          value={values.mviModo || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                    
                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='peep'>
                          PEEP
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='peep'
                          name='peep'
                          value={values.peep || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='fio2'>
                          Fio2
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='fio2'
                          name='fio2'
                          value={values.fio2 || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={3}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='frProgramada'>
                          FR program.
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='frProgramada'
                          name='frProgramada'
                          value={values.frProgramada || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={5}>
                  <Row style={{ marginLeft: '12px' }}>
                    <Col md={4}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='pco2'>
                          PCO2
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='pco2'
                          name='pco2'
                          value={values.pco2 || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='so2'>
                          SO2
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='so2'
                          name='so2'
                          value={values.so2 || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='po2'>
                          PO2
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='po2'
                          name='po2'
                          value={values.po2 || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Não avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioFuncaoRespiratoria }
export default ProntuarioFuncaoRespiratoria
