import {
  IFotoAvaliacao,
  IFotoAvaliacaoBackend,
  IIntegridadeFisicaAvaliacao,
  IIntegridadeFisicaAvaliacaoBackend,
  IIntegridadeFisicaTipoLesao,
  IIntegridadeFisicaTipoLesaoBackend,
  IProntuarioIntegridadeFisicaListagem,
  IProntuarioIntegridadeFisicaListagemBackend,
} from "../types";

const parseFotoAvaliacao = (
  dadosBackend: IFotoAvaliacaoBackend
): IFotoAvaliacao => ({
  id: dadosBackend.id,
  fotoEndereco: dadosBackend.foto,
  idIntegridadeFisicaAvaliacao: dadosBackend.id_integridade_fisica_avaliacao,
})

const parseIntegridadeFisicaAvaliacao = (
  dadosBackend: IIntegridadeFisicaAvaliacaoBackend
): IIntegridadeFisicaAvaliacao => ({
  id: dadosBackend.id,
  avaliacao: dadosBackend.avaliacao,
  tamanhoAproximado: dadosBackend.tamanho_aproximado,
  aspecto: dadosBackend.aspecto,
  aspectoOutro: dadosBackend.aspecto_outro,
  quantidade: dadosBackend.quantidade,
  odor: dadosBackend.odor,
  aspectoBorda: dadosBackend.aspecto_borda,
  aspectoLeito: dadosBackend.aspecto_leito,
  sinaisFlogisticos: dadosBackend.sinais_flogisticos,
  higieneLesao: dadosBackend.higiene_lesao,
  higieneLesaoOutro: dadosBackend.higiene_lesao_outro,
  coberturaPrimaria: dadosBackend.cobertura_primaria,
  coberturaSecundaria: dadosBackend.cobertura_secundaria,

  fotos: dadosBackend.fotoAvaliacao.map(i => parseFotoAvaliacao(i)),
})

const parseIntegridadeFisicaTipoLesao = (
  dadosBackend: IIntegridadeFisicaTipoLesaoBackend
): IIntegridadeFisicaTipoLesao => ({
  id: dadosBackend.id,
  idTipoLesao: dadosBackend.id_tipo_lesao,
  grau: dadosBackend.grau,
  tipoLesao: dadosBackend.tipo_lesao,
  integridadeFisicaAvaliacao: dadosBackend.IntegridadeFisicaAvaliacao.length > 0
    ? parseIntegridadeFisicaAvaliacao(dadosBackend.IntegridadeFisicaAvaliacao[0]) : null
})

export const parseProntuarioIntegridadeFisicaBackToFront = (
  dadosBackend: IProntuarioIntegridadeFisicaListagemBackend
): IProntuarioIntegridadeFisicaListagem => ({
  id: dadosBackend.id,
  observacao: dadosBackend.observacao,
  dreno: dadosBackend.dreno,
  drenagemMl: dadosBackend.drenagem_ml,
  local: dadosBackend.local,
  dataInsercao: dadosBackend.data_insercao,
  escalaBraden: dadosBackend.escala_braden,
  percentualSensorial: dadosBackend.percentual_sensorial ? String(dadosBackend.percentual_sensorial) : null,
  umidade: dadosBackend.umidade ? String(dadosBackend.umidade) : null,
  atividade: dadosBackend.atividade ? String(dadosBackend.atividade) : null,
  mobilidade: dadosBackend.mobilidade ? String(dadosBackend.mobilidade) : null,
  nutricao: dadosBackend.nutricao ? String(dadosBackend.nutricao) : null,
  ficcao: dadosBackend.ficcao ? String(dadosBackend.ficcao) : null,
  idTipoPele: dadosBackend.id_tipo_pele,
  integridadeFisicaTipoLesao: dadosBackend.IntegridadeFisicaTipoLesao?.map(i => parseIntegridadeFisicaTipoLesao(i)),
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
  tipoPele: dadosBackend.tipoPele,
})
