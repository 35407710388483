import React from 'react';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';

const PageHeaderStyled = styled('div') `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
`;

const PageHeaderTitleStyled = styled('span') `
  font-weight: 700;
  font-size: 26px;
  line-height: 1ch;
  color: #222222;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px 0px 0px;
`;

interface PageHeaderProps {
  title: string | JSX.Element,

  buttons?: JSX.Element,

  icon?: IconType
}

export const PageHeader = (props: PageHeaderProps) => {
  return (
    <PageHeaderStyled>
      <PageHeaderTitleStyled>
        {props.icon && (
          <props.icon size={26} color='#304439' style={{ marginRight: '12px' }} />
        )}
        {props.title}
      </PageHeaderTitleStyled>
      {props.buttons}
    </PageHeaderStyled>
  );
};
