import React, { useState } from 'react'
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { Modal } from 'react-bootstrap';

import { PlanoCuidadoResultadosForm } from './PlanoCuidadoResultadosForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../../../api/prontuario/types';
import { PlanoCuidadoResultadosTabela } from './PlanoCuidadoResultadosTabela';
import Button from '../../../../../../../components/Button';

interface IPlanoCuidadoResultadosProps {
  idPlanoCuidado: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
}

const PlanoCuidadoResultados = (props: IPlanoCuidadoResultadosProps) => {
  const [modalResultadosAberto, setModalResultadosAberto] = useState<boolean>(false)

  return (
    <>
      <MdFormatListBulletedAdd size={20} style={{ cursor: 'pointer' }} onClick={() => setModalResultadosAberto(true)}/>

      <Modal
        show={modalResultadosAberto}
        size='xl'
        onHide={() => setModalResultadosAberto(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439'}}>
            Resultados do plano de cuidado
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#304439', maxHeight: '420px', overflowY: 'auto' }}>
          <PlanoCuidadoResultadosForm
            idPlanoCuidado={props.idPlanoCuidado}
            grupo={props.grupo}
            idDiagnostico={props.idDiagnostico}
          />

          <PlanoCuidadoResultadosTabela
            idPlanoCuidado={props.idPlanoCuidado}
            grupo={props.grupo}
            idDiagnostico={props.idDiagnostico}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalResultadosAberto(false)}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { PlanoCuidadoResultados }
export default PlanoCuidadoResultados
