import { IProntuarioSexualidadeListagem, IProntuarioSexualidadeListagemBackend } from "../types";

export const parseProntuarioSexualidadeBackToFront = (dadosBackend: IProntuarioSexualidadeListagemBackend): IProntuarioSexualidadeListagem => ({
  id: dadosBackend.id,
  prejudicada: dadosBackend.prejudicada,
  motivo: dadosBackend.motivo,
  naoAplica: dadosBackend.nao_aplica,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
