import { IUser } from './types'

export const setUserLocalStorage = (user: IUser | null) => {
  localStorage.setItem('session', JSON.stringify(user))
}

export const getUserLocalStorage = (): IUser => {
  const userJson: string = localStorage.getItem('session') as string

  return JSON.parse(userJson)
}
