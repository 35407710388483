import { Formik } from 'formik'
import React, { useContext } from 'react'
import BlockUi from 'react-block-ui'
import * as Yup from 'yup'

import { IDashboardFiltros } from '../../../../api/dashboard'
import { DashboardContext } from '../../context'
import DashboardFiltrosCampos from './DashboardFiltrosCampos'

const DashboardFiltros = () => {
  const { carregando, filtros, setFiltros } = useContext(DashboardContext)

  const schema = Yup.object().shape({
    periodoDe: Yup.date().nullable().required('campo obrigatório'),
    periodoAte: Yup.date().nullable().required('campo obrigatório'),
  })

  const handleSubmit = (values: IDashboardFiltros) => {
    setFiltros(values)
  }

  return (
    <div style={{ padding: '12px', background: '#24563b', display: 'flex', flexDirection: 'column', color: '#fff', borderRadius: "8px" }}>
      <Formik
        initialValues={filtros}
        onSubmit={handleSubmit}
        validationSchema={schema}
      > 
        <BlockUi tag="div" blocking={carregando} >
          <DashboardFiltrosCampos />
        </BlockUi>
      </Formik>
    </div>
  )
}

export { DashboardFiltros }
export default DashboardFiltros
