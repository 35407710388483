import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { RespiracoesContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';

const RespiracoesListagem = () => {
  const { respiracoes, selecionado, setSelecionado } = useContext(RespiracoesContext);

  return (
    <>
      {respiracoes && respiracoes.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Atividade.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {respiracoes && respiracoes.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {respiracoes && respiracoes.length > 0 && respiracoes.map((respiracao => (
                <tr
                  key={respiracao.id}
                  style={{ border: selecionado?.id === respiracao.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(respiracao)}
                >
                  <td>{respiracao.nome}</td>
                  <td>{respiracao.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { RespiracoesListagem }
export default RespiracoesListagem
