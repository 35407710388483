import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { DiagnosticosProvider } from './context';
import { DiagnosticosContainerForm } from './DiagnosticosContainerForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../api/prontuario/types';

export interface IDiagnosticosForm {
  descricao: string,
  inferido: boolean,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

const Diagnosticos = () => {
  return (
    <DiagnosticosProvider>
      <DivMainStyled>
        <DivCardStyled>
          <DiagnosticosContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </DiagnosticosProvider>
  )
}

export { Diagnosticos }
export default Diagnosticos
