import React, { useEffect, useState } from 'react'
import { PDFViewer, Document, View, Page, Text, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { isDesktop } from 'react-device-detect'
// import logo from '../../../../../images/solamarelo.png'
// import logoHospital from '../../../../../images/logohospitaltelecila.jpg'
import { IProntuarioListagem } from '../../../../../api/prontuario'
import { relatorioHistoricoProntuario } from '../../../../../api/relatorios/relatoriosAPI'
import Button from '../../../../../components/Button'
import { useAuth } from '../../../../../context/auth_provider/useAuth'
import { IUser } from '../../../../../context/auth_provider/types'
import { calcularRiscoQueda, getRisco } from '../seg_fisica_emocional/RiscoQueda'


const styles = StyleSheet.create({ 
  dataHeader: {
    position: 'absolute',
    top: 2,
    textAlign: 'right',
    right: 5,
    fontSize: 10,
  },

  HeaderStyled: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'row'
  },

  ContainerStyled: {
    border: '1px solid #ddd',
    borderRadius: 8,
    padding: '7 5 5',
    position: 'relative',
    margin: '10 10',
  },

  LabelStyled: {
    fontSize: 10,
    fontWeight: 800,  
  },

  ValueStyled: {
    fontSize: 9,
    fontWeight: 600,
    marginBottom: 8,
  },

  ImagesStyle: {
    position: "absolute",
    top: 12,
    left: 16,
    height: 50,
    width: 60,
    marginLeft: 15,
  },
  ImagesStyle2: {
    position: 'absolute',
    top: 12,
    right: 16,
    height: 50,
    width: 50,
    marginRight: 15,
  },

  Sumario: {
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  SumarioStyled: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },

  DadosSumarioStyled: {
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0,
  },

  TituloStyled: {
    position: 'absolute',
    top: -8,
    left: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 10,
    fontWeight: 700,
  },
  naoHaDados: {
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 16,
    marginBottom: 10,
  },

  TituloTexto: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: 700,
  },
  duasColunas: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', },
  container: {
    marginTop: 0,
    marginBottom: 5,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titulo: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 1,
  },
  tableContainer: {
    width: '50%',
    borderWidth: 1,
    border: '1px solid #ddd',
    borderRadius: 10,
    margin: 2,
  },
  tableContainerTotal: {
    width: '95%',
    borderWidth: 1,
    border: '1px solid #ddd',
    borderRadius: 10,
    margin: 2,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    borderBottomWidth: 1,
    paddingVertical: 1,
    borderBottomColor: '#ddd',
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 8,
  },
  tableData: {
    width: '50%',
    textAlign: 'center',
    fontSize: 8,
  },
})

interface IProntuarioRelatorioCamposProps {
  idPaciente: number,
  data: string,
}

interface IDocumentProps {
  dadosRelatorio?: IProntuarioListagem,
  user: IUser,
  data: string,
}

const Relatorio = (props: IDocumentProps) => { 
  const getPrecaucoes = (valor: string | null | undefined) => {
    if (valor) {
      switch (Number(valor)) {
        case 1:
          return 'Isolamento padrão'

        case 2:
          return 'Isolamento de contato'

        case 3:
          return 'Isolamento respiratorio por gotícula'

        case 4:
          return 'Isolamento respiratorio por aerossol'

        default:
          return '-'
      }
    }
    return '-'
  }

  const dataComUmDiaAdicionado = new Date(props.data);
  dataComUmDiaAdicionado.setDate(dataComUmDiaAdicionado.getDate() + 1);  
  const dataFormatada = format(dataComUmDiaAdicionado, 'dd/MM/yyyy'); 

  return (
    <Document>
      <Page size='A4' style={{ paddingBottom: '50px' }}>
          <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}> 
           <Image src={require("../../../../../images/solamarelo.png")}  style={styles.ImagesStyle} cache />   
        <Text style={styles.dataHeader}>            
            {dataFormatada ? dataFormatada : format((new Date()), 'dd/MM/yyyy hh:mm:ss')}
          </Text>
          <View style={styles.HeaderStyled}>
            <View style={styles.Sumario}>
              <Text style={styles.SumarioStyled}>
                {(props.dadosRelatorio?.dhPlanoCuidado) ? 'EVOLUÇÃO DIÁRIA DE ENF.' : 'ADMISSÃO DE ENFERMAGEM'}
              </Text>
              <Text style={[styles.DadosSumarioStyled, {margin: 8}]}>{props.user?.enderecoEmpresa || '-'}</Text>
              <Text style={styles.DadosSumarioStyled}>{props.user?.contatoEmpresa || '-'}</Text>
          <View style={styles.TituloTexto}>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontSize: '12px' }}>{props.dadosRelatorio?.paciente?.nome}</Text>
              {props.dadosRelatorio?.paciente?.idade && (
                <Text style={{ fontSize: '12px' }}> - {props.dadosRelatorio?.paciente?.idade} anos </Text>
              )}
              <Text style={{ fontSize: '12px' }}> - CPF {props.dadosRelatorio?.paciente?.numeroSUS}</Text>
            </View>
          </View>
            </View>  
          </View>
        {/* 
         
          */}
        </View> 
        
        <Image src={require("../../../../../images/logohospitaltelecila.png")} style={styles.ImagesStyle2} />
        <View  > 
          { 
            props.dadosRelatorio?.prontuarioExameFisico[0]?.hgt ||
            props.dadosRelatorio?.prontuarioExameFisico[0]?.satO2 ||
            props.dadosRelatorio?.prontuarioExameFisico[0]?.fc ||
            props.dadosRelatorio?.prontuarioExameFisico[0]?.fr || 
            props.dadosRelatorio?.prontuarioExameFisico[0]?.temperatura ||
            props.dadosRelatorio?.prontuarioExameFisico[0]?.ssvvPa ||
            props.dadosRelatorio?.prontuarioExameFisico[0]?.estadoGeral ?
            <View style={{ ...styles.ContainerStyled, marginTop: 15, paddingTop: 5 }}>
              <Text style={styles.TituloStyled}>Exame físico</Text>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Estado geral
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.estadoGeral || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    SSVV - PA mmHg
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.ssvvPa || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Temperatura °C
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.temperatura || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    FR rpm
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.fr || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    FC bpm
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.fc || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Sat O2 %
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.satO2 || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    HGT mg/dl
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioExameFisico[0]?.hgt || '-'}
                  </Text>
                </View>
              </View>
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de exame físico </Text>
            // </View>

          }
          {  props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.audicao ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.visao ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.escalaCcpot ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.escalaDor ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.resolutividade ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.medicamentoDor ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.localDor ||
            props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.dor

            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
              <Text style={styles.TituloStyled}>Percepção dos órgãos dos sentidos </Text>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Dor
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.dor ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Local
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.localDor || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Medicamento p/ dor
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.medicamentoDor || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Resolutividade
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.resolutividade ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Escala da dor
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.escalaDor || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Escala critical care pain
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.escalaCcpot || '-'}
                  </Text>
                </View>
                {/* </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}> */}
                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Visão
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.visao || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Audição
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioPercepcaoSentidos[0]?.audicao || '-'}
                  </Text>
                </View>
              </View>
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de percepção dos órgãos dos sentidos </Text>
            // </View>
          }
        </View>
        <View style={styles.duasColunas}>
          {  props.dadosRelatorio?.prontuarioSonoRepouso[0]?.medicamentoDormir ||
            props.dadosRelatorio?.prontuarioSonoRepouso[0]?.motivo ||
            props.dadosRelatorio?.prontuarioSonoRepouso[0]?.sonoAlterado ||
            props.dadosRelatorio?.prontuarioSonoRepouso[0]?.usaMedicamentoDormir ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width: '30%' }}>
              <Text style={styles.TituloStyled}>Sono/Repouso</Text>
              <View style={{ justifyContent: 'space-between', alignItems: 'stretch', margin: '5 5 0' }}>
                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Alterado
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSonoRepouso[0]?.sonoAlterado ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Motivo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSonoRepouso[0]?.motivo || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Uso medicamento
                    {/* {''}para dormir */}
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSonoRepouso[0]?.usaMedicamentoDormir ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Medicamento 
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSonoRepouso[0]?.medicamentoDormir || '-'}
                  </Text>
                </View>
              </View>
            </View>
            :
            <View style={{ ...styles.ContainerStyled, marginTop: 5, paddingTop: 5, width: '30%' }}>
              <Text style={styles.TituloStyled}>Sono/Repouso sem informação!</Text>
            </View>
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de sono/Repouso </Text>
            // </View>
          }
          {  props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.alergias ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.temAlergias ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.agitado ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.agressivo ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.ansiedade ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.medo ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.solicitante ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.tranquilo ||
            props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.naoAplica
            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width: '70%' }}>
              <Text style={styles.TituloStyled}>Segurança física e emocional</Text>
              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Tranquilo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.tranquilo ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Agitado
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.agitado ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Agressivo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.agressivo ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Ansiedade
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.ansiedade ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Medo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.medo ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Solicitante
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.solicitante ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Não se aplica
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.naoAplica ? 'Sim' : 'Não'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Alergias
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.temAlergias ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Alergia - descrição
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.alergias || '-'}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                  {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.precaucoes ?
                    <View>
                      <Text style={styles.LabelStyled}>
                        Precauções
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {getPrecaucoes(props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.precaucoes)}
                      </Text>

                    </View>

                    : null

                  }
                </View>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      {`Risco de queda: ${getRisco(calcularRiscoQueda(
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.historicoQueda,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.diagnosticoSecundario ,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.auxilioDeambulacao,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.terapiaEndovenosa,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.marcha,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.estadoMental
                      ))} (${calcularRiscoQueda(
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.historicoQueda,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.diagnosticoSecundario ,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.auxilioDeambulacao,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.terapiaEndovenosa,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.marcha,
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.estadoMental
                      )})`}                       
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}> 
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.historicoQueda ? "*Histórico de queda: SIM\n" : "*Histórico de queda: NÃO\n"} 
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.diagnosticoSecundario ? "*Diagnostico secundario: SIM\n" : "*Diagnostico secundario: NÃO\n"} 
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.auxilioDeambulacao ? "*Auxílio de ambulacao: " + 
                        props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.auxilioDeambulacao + "\n" : "*Auxílio deambulacao: Nenhum/Acamado...\n"}
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.terapiaEndovenosa ? "*Terapia Endovenosa: SIM\n" : "NÃO\n"}
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.marcha === "0"  ? "*Marcha: Normal/Sem deamb./Acamado...\n" : 
                       props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.marcha === "10" ? "*Marcha: Fraca\n" : 
                       props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.marcha === "20" ? "*Marcha: Comprometida/Cambaleante...\n" : "*Marcha: Normal/Sem deamb./Acamado...\n"}
                      {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.estadoMental === "0"  ? "*Estado mental: Orientado" : 
                       props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.estadoMental === "15" ? "*Estado mental: Superestima capacidade" : 
                        "*Estado mental: Orientado" 
                      }
                    </Text>
                  </View>

              </View>

              {/* <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
              
            </View> */}
              {  props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.acompanhamento ||
                props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.diagnosticoPsiquiatra ||
                props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.adesaoTratamento ||
                props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.caps ||
                props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.relacaoFamilia ?
                <View style={{ ...styles.ContainerStyled, marginTop: 15, paddingTop: 5, marginLeft: 0, marginRight: 0 }}>
                  <Text style={styles.TituloStyled}>Saúde mental {"\n"}</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                    <View>
                      <Text style={styles.LabelStyled}>
                        Acompanhamento
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.acompanhamento || '-'}
                      </Text>
                    </View>
                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        Diagnóstico do psiquiatra
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.diagnosticoPsiquiatra || '-'}
                      </Text>
                    </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        Adesão ao tratamento
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.adesaoTratamento || '-'}
                      </Text>
                    </View>
                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        CAPS
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.caps || '-'}
                      </Text>
                    </View>

                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        Relação com a família
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioSegFisicaEmocional[0]?.relacaoFamilia || '-'}
                      </Text>
                    </View>
                  </View>
                </View>
                :
                <View style={{ marginBottom: 4, marginTop: 8 }}>
                  <Text style={styles.TituloStyled}>Saúde mental não notificada  </Text>
                </View>
              }
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de segurança física e emocional</Text>
            // </View>
          }          
        </View>

        <View style={styles.container}>
          <View style={styles.tableContainer}>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeader}>Classificação risco quedas: Pontos</Text>
              <Text style={styles.tableHeader}>Risco</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>0 a 24</Text>
              <Text style={styles.tableData}>BAIXO</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>25 a 44</Text>
              <Text style={styles.tableData}>MÉDIO</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>≥ 45</Text>
              <Text style={styles.tableData}>ALTO</Text>
            </View>
          </View>
        </View>

        {  props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.consciente ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.torporoso ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.sedacao ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.comatoso ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsao ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsaoEpisodio ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowIgual ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.ramsay ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.rass ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaIsocoricas ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaMioticas ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaFotoreagente ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaNaoFotoreagente ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasDe ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasAte ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.lagoftalmiaOe ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaConjuntivalOd ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.desorientado ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.orientado ||
          props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.inconsciente
          ?
          <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
            <Text style={styles.TituloStyled}>Função neurológica / Avaliação ocular </Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View>
                <Text style={styles.LabelStyled}>
                  Consciente
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.consciente ? 'Sim' : 'Não'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Inconsciente
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.inconsciente ? 'Sim' : 'Não'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Orientado
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.orientado ? 'Sim' : 'Não'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Desorientado
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.desorientado ? 'Sim' : 'Não'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Torporoso
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.torporoso ? 'Sim' : 'Não'}
                </Text>
              </View>
              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Em sedação
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.sedacao ? 'Sim' : 'Não'}
                </Text>
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View  >
                <Text style={styles.LabelStyled}>
                  Comatoso
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.comatoso ? 'Sim' : 'Não'}
                </Text>
              </View>
              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Convulsão
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsao ? 'Sim' : 'Não'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Episódio
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsaoEpisodio || '-'}
                </Text>
              </View>
              {/* </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}> */}
              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Glasgow
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowIgual || '-'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Ramsay
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.ramsay || '-'}
                </Text>
              </View>

              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  RASS
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.rass || '-'}
                </Text>
              </View>
            </View>

            <View style={{ ...styles.ContainerStyled, margin: '10 0', paddingTop: 5 }}>
              <Text style={styles.TituloStyled}>Pupilas</Text>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Isocóricas
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaIsocoricas ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Mióticas
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaMioticas ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Midriáticas
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaMidriaticas ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Fotorreagente
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaFotoreagente ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Não fotorreagente
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaNaoFotoreagente ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Ansocóricas de
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasDe || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Ansocóricas até
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasAte || '-'}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, margin: 0, width: '30%' }}>
                <Text style={{ fontSize: 12 }}>Logoftalmia</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 0 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Olho esquerdo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.lagoftalmiaOe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Olho direito
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.lagoftalmiaOd ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ ...styles.ContainerStyled, marginLeft: 10, paddingTop: 5, margin: 0, width: '30%' }}>
                <Text style={{ fontSize: 12 }}>Hiperemia conjutival</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 0 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Olho esquerdo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.hiperamiaConjuntivalOe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Olho direito
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.hiperamiaConjuntivalOd ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ ...styles.ContainerStyled, marginLeft: 10, paddingTop: 2, margin: 0, width: '30%' }}>
                <Text style={{ fontSize: 12 }}>Secreção mucoide</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 0 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Olho esquerdo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.secrecaoOe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Olho direito
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.secrecaoOd ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 12 }}>
              <View style={{ ...styles.ContainerStyled, paddingTop: 5, margin: 0, width: '28%' }}>
                <Text style={{ fontSize: 12 }}>Edema palpebral</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 0 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Olho esquerdo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaPalpebralOe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Olho direito
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaPalpebralOd ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ ...styles.ContainerStyled, paddingTop: 5, margin: 0, marginLeft: 10, width: '58%' }}>
                <Text style={{ fontSize: 12 }}>Quemose/Edema conjutival</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 0 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Olho esquerdo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaConjuntivalOe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Olho direito
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaConjuntivalOd ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          :
          null
          // <View>
          //   <Text style={styles.naoHaDados}>Não há dados de função neurológica / avaliação ocular </Text>
          // </View>
        }
        <View style={styles.duasColunas}>
          {  props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.escalaBraden ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.tipoPele?.nome ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.observacao ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.drenagemMl ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.local ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.dataInsercao ||
            props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.dreno
            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, width: '30%', paddingTop: 5 }}>
              <View>
                <Text style={styles.TituloStyled}>Integridade física </Text>
              </View>
              <View style={{ margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Pele e mucosa
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.tipoPele?.nome || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Observação
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.observacao || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Dreno
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.dreno ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Drenagem ml
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.drenagemMl || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Local
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.local || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Data da inserção
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.dataInsercao
                      ? format(
                        new Date(props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.dataInsercao),
                        'dd/MM/yyyy hh:mm:ss'
                      )
                      : '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Escala braden
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.escalaBraden || '-'}
                    {"\nPercepção sensorial: "}
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === "1"?"Totalmente limitado"://esta retorando um valor nulo
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === "2"?"Muito limitado"://esta retorando um valor nulo
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === "3"?"Levemente limitado"://esta retorando um valor nulo
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === "4"?"Nenhuma limitação"//esta retorando um valor nulo
                    :null}
                      {"\nAtividade: "}
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === "1"?"Acamado":
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === "2"?"Confinado a cadeira":
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === "3"?"Deambula ocasionalmente":
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === "4"?"Deambula frequentimente"
                    :null}
                      {"\nMobilidade: "}
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === "1"?"Imóvel": 
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === "2"?"Muito limitado":  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === "3"?"Discreta limitação":                  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === "4"?"Sem limitação"
                    :null}
                      {"\nNutrição: "}
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === "1"?"Deficiente": 
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === "2"?"Inadequada":  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === "3"?"Adequada":                  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === "4"?"Excelente"
                    :null}
                      {"\nUmidade: "}
                    {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === "1"?"Excessiva": 
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === "2"?"Muita":  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === "3"?"Ocasional":                  
                      props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === "4"?"Rara"
                    :null}
                  </Text>
                </View>
              </View>
              {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.integridadeFisicaTipoLesao[0] ?
                <View style={{ ...styles.ContainerStyled, marginTop: 16, paddingTop: 5, marginLeft: 0, marginRight: 0 }}>
                  <Text style={styles.TituloStyled}>Lesões</Text>
                  {props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.integridadeFisicaTipoLesao.map(item => (
                    <View key={item.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10 }}>
                      <Text>{item.tipoLesao.nome}  </Text>
                      <Text>{item.grau}</Text>
                    </View>
                  ))}
                </View>
                :
                <View>
                  <Text style={styles.naoHaDados}>Não há lesões </Text>
                </View>

              }
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de integridade física </Text>
            // </View>
          }

          { 
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.co2Ambiente ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.cateterNasal ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.cateterNasalLmin ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.outroDispositivo ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.respiracao?.nome ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mv ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mvPerc ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vni ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vniTipo ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.auscutaPulmonar ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tot ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.totNumero ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.totDias ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqt ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqtNumero ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqtDias ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mnr ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mnrLmin ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecao ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecaoTipo ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tosse ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tosseTipo ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vmi ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vmiDesmami ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecao ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.ph ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.hco3 ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mviModo ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.peep ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.fio2 ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.frProgramada ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.so2 ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.pco2 ||
            props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.po2
            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width: '65%' }}>
              <Text style={styles.TituloStyled}>Função respiratória</Text>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    O2 ambiente
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.co2Ambiente ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Cateter nasal
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.cateterNasal ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    L/min
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.cateterNasalLmin || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Outro dispositivo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.outroDispositivo || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Respiração
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.respiracao?.nome || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    MV
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mv ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    MV (%)
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mvPerc || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    VNI
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vni ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    VNI tipo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vniTipo || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Auscuta pulmonar
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.auscutaPulmonar || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    TOT
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tot ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    # TOT n°
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.totNumero || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    TOT dias
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.totDias || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    TQT
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqt ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    # TQT n°
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqtNumero || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    TQT dias
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tqtDias || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    MNR
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mnr ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    L/min
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mnrLmin || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Secreção
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecao ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    Tipo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecaoTipo || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Tosse
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tosse ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    Tipo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.tosseTipo || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    VMI
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vmi ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    VMI-Desmami
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.vmiDesmami ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Gasometria
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.secrecao ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    Ph
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.ph || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    HCO3
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.hco3 || '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    MVI Modo
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.mviModo || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    PEEP
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.peep || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Fio2
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.fio2 || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    FR progra.
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.frProgramada || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    PCO2
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.pco2 || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    SO2
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.so2 || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 6 }}>
                  <Text style={styles.LabelStyled}>
                    PO2
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioFuncaoRespiratoria[0]?.po2 || '-'}
                  </Text>
                </View>
              </View>
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de função respiratória </Text>
            // </View>
          }
        </View>

        <View style={styles.container}>
          {/*<Text style={styles.titulo}>Escala Braden</Text>*/}
          <View style={styles.tableContainerTotal}>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeader}>Escala Braden: Descrição</Text>
              <Text style={styles.tableHeader}>Soma + 1</Text>
              <Text style={styles.tableHeader}>Soma + 2</Text>
              <Text style={styles.tableHeader}>Soma + 3</Text>
              <Text style={styles.tableHeader}>Soma + 4</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Percepção sensorial</Text>
              <Text style={styles.tableData}>{`Totalmente limitado ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Muito limitado ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Levemente limitado ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Nenhuma limitação ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.percentualSensorial === '4' ? "(X)" : ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Umidade</Text>
              <Text style={styles.tableData}>{`Excessiva ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Muita ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Ocasional ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Rara ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.umidade === '4' ? "(X)" : ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Atividade</Text>
              <Text style={styles.tableData}>{`Acamado ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Confinado a cadeira ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Deambula ocasionalmente ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Deambula frequentimente ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.atividade === '4' ? "(X)" : ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Mobilidade</Text>
              <Text style={styles.tableData}>{`Imóvel ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Muito limitado ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Discreta limitação ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Sem limitação ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.mobilidade === '4' ? "(X)" : ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Nutrição</Text>
              <Text style={styles.tableData}>{`Deficiente ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Inadequada ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Adequada ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Excelente ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.nutricao === '4' ? "(X)" : ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableData}>Ficção e cisalhamento</Text>
              <Text style={styles.tableData}>{`Problema ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.ficcao === '1' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Problema potencial ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.ficcao === '2' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}>{`Sem problema ${props.dadosRelatorio?.prontuarioIntegridadeFisica[0]?.ficcao === '3' ? "(X)" : ""}`}</Text>
              <Text style={styles.tableData}></Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableHeader}>Total</Text>
              <Text style={styles.tableHeader}>Risco brando - 15 a 16</Text>
              <Text style={styles.tableHeader}>Risco moderado - 12 a 14</Text>
              <Text style={styles.tableHeader}>Risco severo{` <`}11</Text>
              <Text style={styles.tableHeader}></Text>
            </View>
          </View>
        </View>

        <View style={styles.duasColunas}>
          <View>
            { 
              props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.pulsoPeriferico ||
              props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.pulsacaoPeriferica ||
              props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.auscutaCardiaca ||
              props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.edemaLocalDesc ||
              props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.pulsoPeriferico
              ?
              <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
                <Text style={styles.TituloStyled}>Função cardiovascular</Text>

                <View style={{  justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Pulsos periféricos
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.pulsoPeriferico || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Perfusão periférica
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.pulsacaoPeriferica || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Ausculta cardíaca
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.auscutaCardiaca || '-'}
                    </Text>
                  </View>
                </View>

                {props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.temEdema && (
                  <View style={{ ...styles.ContainerStyled, marginTop: 16, paddingTop: 5, marginLeft: 0, marginRight: 0 }}>
                    <Text style={styles.TituloStyled}>Edemas</Text>

                    {props.dadosRelatorio?.prontuarioFuncaoCardiovascular[0]?.edemaLocalDesc.map(item => (
                      <View key={item.idEdema} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 12 }}>
                        <Text>{item.local}</Text>
                        <Text>{item.descricao}</Text>
                      </View>
                    ))}
                  </View>
                )}
              </View>
              :
              null
              // <View>
              //   <Text style={styles.naoHaDados}>Não há dados de função cardiovascular</Text>
              // </View>
            }

            { 
              props.dadosRelatorio?.prontuarioNutricao[0]?.aceitacao ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.rha ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.tipo ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.vazaoMl2 ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.tipoSonda ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.vazaoMl ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.dataInsercao ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.npt ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.nptEtapa ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.horarioInicio ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.nptLocal ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.dietaZero ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.dias ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.abdome ||
              props.dadosRelatorio?.prontuarioNutricao[0]?.oral
              ?
              <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
                <Text style={styles.TituloStyled}>Nutrição</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Oral
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.oral ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Aceitação
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.aceitacao || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Tipo
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.tipo || '-'}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Tipo de sonda
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.tipoSonda || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Vazão ml/H
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.vazaoMl || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Data da inserção
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.dataInsercao
                        ? format(
                          new Date(props.dadosRelatorio?.prontuarioNutricao[0]?.dataInsercao),
                          'dd/MM/yyyy hh:mm:ss'
                        )
                        : '-'}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      NPT
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.npt ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      NPT Etapa
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.nptEtapa || '-'}
                    </Text>
                  </View>



                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        Vazão ml/H
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioNutricao[0]?.vazaoMl2 || '-'}
                      </Text>
                    </View> 
                    <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        Horário de Início
                      </Text>
                      {/*<Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioNutricao[0]?.horarioInicio
                          ? format(
                            new Date(props.dadosRelatorio?.prontuarioNutricao[0]?.horarioInicio),
                            'dd/MM/yyyy hh:mm:ss'
                          )
                          : '-'}
                        </Text>*/}
                        <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioNutricao[0]?.horarioInicio
                          ? props.dadosRelatorio?.prontuarioNutricao[0]?.horarioInicio
                          : '-'}
                        </Text>
                    </View>

                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '8 5 0' }}>
                  
                <View style={{ marginLeft: 12 }}>
                      <Text style={styles.LabelStyled}>
                        NPT Local
                      </Text>
                      <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                        {props.dadosRelatorio?.prontuarioNutricao[0]?.nptLocal || '-'}
                      </Text>
                    </View>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Dieta ZERO
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.dietaZero ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 6 }}>
                    <Text style={styles.LabelStyled}>
                      Dias
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.dias || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Abdome
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.abdome || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      RHA
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioNutricao[0]?.rha || '-'}
                    </Text>
                  </View>
                </View>
              </View>
              :
              null
              // <View>
              //   <Text style={styles.naoHaDados}>Não há dados de nutrição</Text>
              // </View>
            }
            {  props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.dependencia ||
              props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.corporalPrejudicada ||
              props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.oralPrejudicada ||
              props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.intimaPrejudicada ?
              <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
                <Text style={styles.TituloStyled}>Cuidado corporal</Text>

                <View style={{ justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Dependência p/ autocuidado
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.dependencia ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Higiene corporal prejudicada
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.corporalPrejudicada ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Higiene oral prejudicada
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.oralPrejudicada ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Higiene íntima prejudicada
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioCuidadoCorporal[0]?.intimaPrejudicada ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>
              :
              null
              // <View>
              //   <Text style={styles.naoHaDados}>Não há dados de cuidado corporal </Text>
              // </View>
            }
             {  props.dadosRelatorio?.prontuarioSexualidade[0]?.prejudicada ||
          props.dadosRelatorio?.prontuarioSexualidade[0]?.motivo
          ?
          <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
            <Text style={styles.TituloStyled}>Sexualidade </Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View>
                <Text style={styles.LabelStyled}>
                  Prejudicada
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioSexualidade[0]?.prejudicada ? 'Sim' : 'Não'}
                </Text>
              </View>
              <View style={{ marginLeft: 12 }}>
                <Text style={styles.LabelStyled}>
                  Motivo
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioSexualidade[0]?.motivo || '-'}
                </Text>
              </View>
            </View>
          </View>
          :
          null
          // <View>
          //   <Text style={styles.naoHaDados}>Não há dados de sexualidade  </Text>
          // </View>
        }
         {  props.dadosRelatorio?.prontuarioReligiosidade[0]?.sentimentoFe ||
              props.dadosRelatorio?.prontuarioReligiosidade[0]?.religiao ||
              props.dadosRelatorio?.prontuarioReligiosidade[0]?.necessidadeSuporte ?
              <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
                <Text style={styles.TituloStyled}>Religiosidade/Espitirualidade</Text> 
                <View style={{ justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Sentimento de fé
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioReligiosidade[0]?.sentimentoFe ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Religião
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioReligiosidade[0]?.religiao || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Necessidade de suporte espiritual
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioReligiosidade[0]?.necessidadeSuporte ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                </View>
              </View>
              :
              null
              // <View>
              //   <Text style={styles.naoHaDados}>Não há dados de religiosidade/espiritualidade </Text>
              // </View>
            }
          </View>
          <View>
            
          { 
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.wc ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.frauda ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svd ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svdLocal ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svdData ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.sva ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.disUrinario ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.outros ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.outrosDescricao ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.limpida ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.concentrada ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.piuria ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.coluria ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.hematuria ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.aspOutros ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.aspOutrosDescricao ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.normal ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.diareia ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.pastoso ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.endurecido ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.constipacao ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.constipacaoDias ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesWc ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesFrauda ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.colostomia ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.ileostomia ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesOutros ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesOutrosDescricao ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.genitaliaIntegra ||
            props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.observacao
            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5 }}>
              <Text style={styles.TituloStyled}>Eliminações fisiológicas </Text>
              <View style={{ ...styles.ContainerStyled, margin: '10 0', paddingTop: 5 }}>
                <Text style={{ fontSize: 12 }}>Urinária</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Wc
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.wc ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Fralda
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.frauda ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      SVD
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svd ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      SVD local
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svdLocal || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      SVD Data
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svdData
                        ? format(
                          new Date(props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.svdData),
                          'dd/MM/yyyy hh:mm:ss'
                        )
                        : '-'}
                    </Text>
                  </View>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      SVA
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.sva ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Disp. urinário
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.disUrinario || '-'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.outros ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro descrição
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.outrosDescricao || '-'}
                    </Text>
                  </View>
                </View>

                <Text style={{ fontSize: 12, marginTop: 12 }}>Aspecto:</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Límpida
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.limpida ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Concent.
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.concentrada ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Piúria
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.piuria ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Colúria
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.coluria ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}> 
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Hematúria
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.hematuria ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.aspOutros ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro descrição
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.aspOutrosDescricao || '-'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{ ...styles.ContainerStyled, margin: '10 0', paddingTop: 5, marginTop: 20 }}>
                <Text style={{ fontSize: 12 }}>Intestinal</Text>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Wc
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesWc ? 'Sim' : 'Não'}
                    </Text>
                  </View> 
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Fralda
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesFrauda ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Colostomia
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.colostomia ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Ileostomia
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.ileostomia ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesOutros ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Outro descrição
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.intesOutrosDescricao || '-'}
                    </Text>
                  </View>
                </View>

                <Text style={{ fontSize: 12, marginTop: 12 }}>Aspecto:</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Normal
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.normal ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Diarréia
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.diareia ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Pastoso
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.pastoso ? 'Sim' : 'Não'}
                    </Text>
                  </View>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}> 
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Endurecida
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.endurecido ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Constipação
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.constipacao ? 'Sim' : 'Não'}
                    </Text>
                  </View> 
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Constipação dias
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.constipacaoDias || '-'}
                    </Text>
                  </View>  
                </View>
              </View>

              <View style={{ ...styles.ContainerStyled, margin: '10 0', paddingTop: 5 }}>
                <Text style={{ fontSize: 12 }}>Genitália:</Text>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                  <View>
                    <Text style={styles.LabelStyled}>
                      Íntegra
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.genitaliaIntegra ? 'Sim' : 'Não'}
                    </Text>
                  </View>

                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.LabelStyled}>
                      Observação
                    </Text>
                    <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                      {props.dadosRelatorio?.prontuarioEliminacaoFisio[0]?.observacao || '-'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de eliminações fisiológicas </Text>
            // </View>
          }
           {props.dadosRelatorio?.prontuarioControleInfusao ?
          <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width: '90%', }}>
            <Text style={styles.TituloStyled}>Controle de infusões</Text>
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ marginLeft: 12 }}>
              <Text style={styles.LabelStyled}>Tipo de infusão</Text>
            </View>
              <View style={{ marginLeft: 12 }}>
              <Text style={styles.LabelStyled}>Descricao</Text>
              </View>
              <View style={{ marginLeft: 12, marginRight: 12 }}>
              <Text style={styles.LabelStyled}>Ml/h</Text>
              </View>
            </View>
          
            {props.dadosRelatorio?.prontuarioControleInfusao.map(item => (
              <View key={item.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10 }}>
                <View style={{ marginLeft: 12 }}>
                  <Text>{item.tipoInfusao.nome}</Text>
                </View>
                <View style={{ marginLeft: 12 }}>
                <Text>{item.descricao}</Text>
                </View>
                <View style={{ marginLeft: 12, marginRight: 12 }}> 
                <Text>{item.mlh}</Text>
                </View>
              </View>
            ))}
          </View>
          :
          null
          // <View>
          //   <Text style={styles.naoHaDados}>Nenhum dado de controle de infusão disponível </Text>
          // </View>
        }
          </View>
        </View> 

        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', }}> 
          {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.dispositivo ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.descricao ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.local ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.data ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.bic ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.hidratacao ||
            props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.atb ||
            (props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.terapiaEndovenosaATB && props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.terapiaEndovenosaATB.length > 0)
            ?
            <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width:"70%" }}>
              <Text style={styles.TituloStyled}>Terapia endovenosa</Text>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    Dispositivos
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.dispositivo?.nome || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Descrição
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.descricao || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Local
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.local || '-'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Data da inserção
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.data
                      ? format(
                        new Date(props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.data),
                        'dd/MM/yyyy hh:mm:ss'
                      )
                      : '-'}
                  </Text>
                </View>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '18 5 0' }}>
                <View>
                  <Text style={styles.LabelStyled}>
                    BIC
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.bic ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Hidratação
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.hidratacao ? 'Sim' : 'Não'}
                  </Text>
                </View>

                <View style={{ marginLeft: 12 }}>
                  <Text style={styles.LabelStyled}>
                    Antibióticos
                  </Text>
                  <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                    {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.atb ? 'Sim' : 'Não'}
                  </Text>
                </View>
              </View>
              {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.atb ?
                <View style={{ ...styles.ContainerStyled, marginTop: 16, paddingTop: 5, marginLeft: 0, marginRight: 0 }}>
                  <Text style={styles.TituloStyled}>Antibióticos - Qual e quantos dias</Text>
                  {props.dadosRelatorio?.prontuarioTerapiaEndovenosa[0]?.terapiaEndovenosaATB.map(item => (
                    <View key={item.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 8 }}>
                      <Text>{item.descricao}</Text>
                      <Text>{item.dias}</Text>
                    </View>
                  ))}
                </View>
                :
                null
              }
            </View>
            :
            null
            // <View>
            //   <Text style={styles.naoHaDados}>Não há dados de terapia endovenosa </Text>
            // </View>
          }
          
        {  props.dadosRelatorio?.prontuarioRegistroComplementar[0]?.eventosAdversos ||
          props.dadosRelatorio?.prontuarioRegistroComplementar[0]?.ocorrencias ?
          <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 5, width:"50%" }}>
            <Text style={styles.TituloStyled}>Registro complementar</Text>
            <View style={{ justifyContent: 'flex-start', margin: '5 5 0', flexWrap: 'wrap' }}>
              <View style={{ marginLeft: 6 }}>
                <Text style={styles.LabelStyled}>
                  Registro de eventos adversos
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {props.dadosRelatorio?.prontuarioRegistroComplementar[0]?.eventosAdversos || '-'}
                </Text>
              </View>
              <View style={{ marginLeft: 6 }}>
                <Text style={styles.LabelStyled}>
                  Registro de ocorrências
                </Text>
                <Text style={{ ...styles.ValueStyled, marginBottom: 0, }}>
                  {props.dadosRelatorio?.prontuarioRegistroComplementar[0]?.ocorrencias || '-'}
                </Text>
              </View>
            </View>
          </View>
          :
          null
          // <View>
          //   <Text style={styles.naoHaDados}>Não há registro complementar disponível. </Text>
          // </View>
        }
       
        </View>

        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Registro complementar - ITU Associado ao udo de SVD</Text>
          { props.dadosRelatorio?.prontuarioRegistroComplementarITU !== undefined && 
            props.dadosRelatorio?.prontuarioRegistroComplementarITU?.length > 0 ?
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View>
                <Text style={styles.LabelStyled}>
                {`${'Qual a necessidade da SVD? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].svn ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].svn : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'SVD - Outro: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].svnEspecificar ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].svnEspecificar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'Sistema de drenagem está mantido fechado? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].sistemaDrenagem ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].sistemaDrenagem : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'O fluxo urinário está mantido livre, fixado adequadamente e com o sistema coletor sem dobras ou acotovelamentos? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].fluxoUninario ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].fluxoUninario : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'O cateter está fixado em local adequado e sem tração? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].cateterFixado ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].cateterFixado : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'Foi realizada higiene perineal e do meatro uretral adequadamente nas últimas 24 h? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].realizadaHigiene ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].realizadaHigiene : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'O limite da capacidade da bolsa coletora está até 2/3 do seu volume? ' } ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].limiteCapacidadeBolsa ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].limiteCapacidadeBolsa : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'A uretra está íntegra? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].uretaIntegra ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].uretaIntegra : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'Especificar: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].uretaIntegraEspecificar ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].uretaIntegraEspecificar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'A urina está com aspecto normal? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].urinaAspectoNormal ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].urinaAspectoNormal : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'Especificar: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].urinaAspectoEspecificar ? props.dadosRelatorio?.prontuarioRegistroComplementarITU[0].urinaAspectoEspecificar : '-'}`}
                </Text>
              </View>
            </View>
            : null 
          }
        </View>

        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Registro complementar - ICS Associado ao udo de SVD</Text>
          { props.dadosRelatorio?.prontuarioRegistroComplementarCVC !== undefined && 
            props.dadosRelatorio?.prontuarioRegistroComplementarCVC?.length > 0 ?
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View>
                <Text style={styles.LabelStyled}>
                {`${'Os conectores e equipo necessitam ser trocados? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].conectoresPrecisamTrocar ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].conectoresPrecisamTrocar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'O curativo venoso central necessita ser trocado? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].curativoPrecisamTrocar ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].curativoPrecisamTrocar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'Os equipos estão com data? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].equipamentosComData ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].equipamentosComData : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'O curativo apresenta sujidade ou descolamento da pele? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].curativoPrecisamTrocar ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].curativoPrecisamTrocar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>Registro do curativo do CVC:</Text>
                <Text style={styles.LabelStyled}>1. Higienização das mãos e Paramentação:</Text>
                <Text style={styles.LabelStyled}>
                {`${'  Higienização das mãos: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].higenienizacaoMaos ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].higenienizacaoMaos : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Uso de Gorro e Máscara: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].usoGorroMascara ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].usoGorroMascara : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>2. Técnica asséptica:</Text>
                <Text style={styles.LabelStyled}>
                {`${'  SF 0,9%: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].sf0_09 ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].sf0_09 : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Clorexidina Alcoólica ou aquosa: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].clorexina ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].clorexina : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>3. Cobertura:</Text>
                <Text style={styles.LabelStyled}>
                {`${'  Gaze: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].gaze ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].gaze : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Filme transparente: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].filmeTransparente ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].filmeTransparente : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>4. Motivo da troca:</Text>
                <Text style={styles.LabelStyled}>
                {`${'  Mal aderido: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].malAderido ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].malAderido : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Presença de sangue: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaSangue ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaSangue : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Presença de exsudato: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaExsudato ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaExsudato : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Troca de rotina: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].trocaRotina ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].trocaRotina : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Opção por Filme: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].opcaoFilme ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].opcaoFilme : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>5. Aspecto Sítio:</Text>
                <Text style={styles.LabelStyled}>
                {`${'  Sem sinais flogísticos: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].semSinaisFlosgisticos ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].semSinaisFlosgisticos : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                {`${'  Presença de sinais flogísticos: '} ${props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaSinaisFlosgisticos ? props.dadosRelatorio?.prontuarioRegistroComplementarCVC[0].presencaSinaisFlosgisticos : '-'}`}
                </Text>
              </View>
            </View>
            : null 
          }
        </View>

        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Registro complementar - PAV Associado ao VM</Text>
          { props.dadosRelatorio?.prontuarioRegistroComplementarPAV !== undefined && 
            props.dadosRelatorio?.prontuarioRegistroComplementarPAV?.length > 0 ?
            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '5 5 0' }}>
              <View>
                <Text style={styles.LabelStyled}>
                  {`${'A higiene oral foi realizada nas últimas 24h? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].higieneOralRealizada ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].higieneOralRealizada : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'A cabeceira do leito encontra-se entre 30 a 45° nas últimas 24h? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].cabeceiraLeito_30_45 ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].cabeceiraLeito_30_45 : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'Os condensados no sistema ventilatório foram desprezados? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].ventilorioDesprezados ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].ventilorioDesprezados : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'Foi realizada higiene das mãos antes da manipulação do TOT? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].higieneMaos ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].higieneMaos : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'O TOT está fixado adequadamente? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].totFixado ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].totFixado : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>Sinais e sintomas relacionados a ICS:</Text>
                <Text style={styles.LabelStyled}>
                  {`${'  1.Paciente com doença pulmonar ou cardíaca de base? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].doencaPulmonar ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].doencaPulmonar : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'  2. Fez raio-x de tórax? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].rxTorax ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].rxTorax : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'  3. Se sim na opção anterior, apresentou infiltrado, opacificação ou cavitação? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].filtradoOpacificacao ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].filtradoOpacificacao : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'  4. Teve febre (temp > 38°C)? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].teveFebreMaior_38 ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].teveFebreMaior_38 : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'  5. Tem Leucopenia < 4000 cel/mm³? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].leocopeniaMenor_4000 ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].leocopeniaMenor_4000 : '-'}`}
                </Text>
                <Text style={styles.LabelStyled}>
                  {`${'6. Tem Leucocitose > 12000 cel/mm³? '} ${props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].leococitoseMaior_12000 ? props.dadosRelatorio?.prontuarioRegistroComplementarPAV[0].leococitoseMaior_12000 : '-'}`}
                </Text>
              </View>
            </View>
            : null 
          }
        </View>

        <Text style={{ textAlign: 'center', marginBottom: 4 }}>
          _______________________________
        </Text>
        <Text style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', marginBottom: 4 }}>
          {props.user.nome || ''}
        </Text>

        <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
          Assinatura/Carimbo
        </Text>
      </Page>
    </Document>
  )
}

const ProntuarioRelatorioCampos = (props: IProntuarioRelatorioCamposProps) => {
  const [dadosRelatorio, setDadosRelatorio] = useState<IProntuarioListagem>()
  const { user } = useAuth()

  const getDadosRelatorio = () => {
    relatorioHistoricoProntuario(props.idPaciente, props.data)
      .then(resultado => setDadosRelatorio(resultado))
      .catch(console.error)
  }

  useEffect(() => {
    getDadosRelatorio()
  }, [props.idPaciente])

  return (
    <>
      <Button variant='new' style={{ marginBottom: '16px' }}>
        <PDFDownloadLink
          document={
            <Relatorio dadosRelatorio={dadosRelatorio} user={user} data={props.data} />
          }
          fileName="rel-prontuario.pdf"
          style={{ textDecoration: 'none', color: '#fff' }}
        >
          {({ loading }) =>
            loading ? 'Gerando seu documento...' : 'Fazer download do relatório do prontuário!'
          }
        </PDFDownloadLink>
      </Button>

      {isDesktop && (
        <PDFViewer width='100%' height='80%' showToolbar>
          <Relatorio dadosRelatorio={dadosRelatorio} user={user} data={props.data} />
        </PDFViewer>
      )}
    </>
  )
}

export { ProntuarioRelatorioCampos }
export default ProntuarioRelatorioCampos
