import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { LesoesContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';

const LesoesListagem = () => {
  const { lesoes, selecionado, setSelecionado } = useContext(LesoesContext);

  return (
    <>
      {lesoes && lesoes.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Atividade.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {lesoes && lesoes.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
                <th style={{ width: 200 }}>Lesão por pressão</th>
              </tr>
            </thead>

            <tbody>
              {lesoes && lesoes.length > 0 && lesoes.map((lesao => (
                <tr
                  key={lesao.id}
                  style={{ border: selecionado?.id === lesao.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(lesao)}
                >
                  <td>{lesao.nome}</td>
                  <td>{lesao.ativo ? 'Sim' : 'Não'}</td>
                  <td>{lesao.lesaoPressao ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { LesoesListagem }
export default LesoesListagem
