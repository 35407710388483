import styled from 'styled-components';
import DatePicker from 'react-datepicker';

interface IDatePickerStyledProps {
  disabled?: boolean
}

export const DatePickerStyled = styled(DatePicker)<IDatePickerStyledProps>`
  background-color: #FFFFFF;
  width: 100%;
  color: #000;
  border: 1px solid #ced4da;
  padding: 9.5px 14px;
  border-radius: 12px;
  
  background-color: ${(props: IDatePickerStyledProps) => props.disabled ?  '#E5E4E2' : '#FFFFFF'};

  ::placeholder {
    color: #000;
  }

  :focus {
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    color: #000;
  }

  :checked {
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    color: #000;
  }
`;
