import React, { useContext } from 'react'
import { FaUser } from 'react-icons/fa'

import { Page } from '../../../components/page'
import { Selos } from './components/helper'
import { ProntuarioContext } from './context'

const PageHeaderProntuario = () => {
  const { prontuario } = useContext(ProntuarioContext)

  return (
    <Page.Header
      icon={FaUser}
      title={<>
        <span>Prontuário - {prontuario?.paciente?.nome}</span>

        <div style={{ marginLeft: '200px' }}>
          <Selos prontuario={prontuario} colorido />
        </div>
      </>}
      buttons={<></>}
    />
  )
}

export default PageHeaderProntuario