import styled from 'styled-components';

export const TextAreaStyled = styled.textarea`
  background-color: #fff;
  color: #222222;
  border: 1px solid #ced4da;

  :focus {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #222222;
  }

  :checked {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #222222;
  }

  :disabled {
    background-color: #E5E4E2;
    color: #FFF7E6
  }
`;
