import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import { MdSick } from 'react-icons/md'
import Swal from 'sweetalert2'

import isolamento from '../../../../../images/isolamento.png'
import {
  IProntuarioSegFisicaEmocionalForm,
  prontuarioSegFisicaEmocionalCreateAPI,
} from '../../../../../api/prontuario/prontuarioSegFisicaEmocionalAPI'
import { InputStyled } from '../../../../../components/styles'
import { Select, Switch } from '../../../../../components'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import RiscoQueda from './RiscoQueda'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { verificarAvaliadoNoDia } from '../../util'
import Checkbox from '../../../../../components/checkbox/Checkbox'

interface IProntuarioSegFisicaEmocionalProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioSegFisicaEmocional = (props: IProntuarioSegFisicaEmocionalProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.SEG_FISICA_EMOCIONAL)

  const initialValues: IProntuarioSegFisicaEmocionalForm = {
    tranquilo: prontuario?.prontuarioSegFisicaEmocional[0]?.tranquilo || null,
    agitado: prontuario?.prontuarioSegFisicaEmocional[0]?.agitado || null,
    agressivo: prontuario?.prontuarioSegFisicaEmocional[0]?.agressivo || null,
    ansiedade: prontuario?.prontuarioSegFisicaEmocional[0]?.ansiedade || null,
    medo: prontuario?.prontuarioSegFisicaEmocional[0]?.medo || null,
    solicitante: prontuario?.prontuarioSegFisicaEmocional[0]?.solicitante || null,
    naoAplica: prontuario?.prontuarioSegFisicaEmocional[0]?.naoAplica || null,
    temAlergias: prontuario?.prontuarioSegFisicaEmocional[0]?.temAlergias || null,
    alergias: prontuario?.prontuarioSegFisicaEmocional[0]?.alergias || null,
    precaucoes: prontuario?.prontuarioSegFisicaEmocional[0]?.precaucoes || null,
    historicoQueda: prontuario?.prontuarioSegFisicaEmocional[0]?.historicoQueda || null,
    diagnosticoSecundario: prontuario?.prontuarioSegFisicaEmocional[0]?.diagnosticoSecundario || null,
    terapiaEndovenosa: prontuario?.prontuarioSegFisicaEmocional[0]?.terapiaEndovenosa || null,
    auxilioDeambulacao: prontuario?.prontuarioSegFisicaEmocional[0]?.auxilioDeambulacao || null,
    marcha: prontuario?.prontuarioSegFisicaEmocional[0]?.marcha || null,
    estadoMental: prontuario?.prontuarioSegFisicaEmocional[0]?.estadoMental || null,
    acompanhamento: prontuario?.prontuarioSegFisicaEmocional[0]?.acompanhamento || null,
    caps: prontuario?.prontuarioSegFisicaEmocional[0]?.caps || null,
    diagnosticoPsiquiatra: prontuario?.prontuarioSegFisicaEmocional[0]?.diagnosticoPsiquiatra || null,
    adesaoTratamento: prontuario?.prontuarioSegFisicaEmocional[0]?.adesaoTratamento || null,
    relacaoFamilia: prontuario?.prontuarioSegFisicaEmocional[0]?.relacaoFamilia || null,
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioSegFisicaEmocional[0]?.avaliado),
    alterado: prontuario?.prontuarioSegFisicaEmocional[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioSegFisicaEmocionalForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioSegFisicaEmocionalCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Segurança física e emocional incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('funNeuroAvaOcular'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioSegFisicaEmocional[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioSegFisicaEmocional[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('segFisicaEmocional')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Segurança física e emocional</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'segFisicaEmocional') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'segFisicaEmocional') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}  >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Tranquilo'
                    name='tranquilo'
                    checked={values.tranquilo || false}
                    onChange={(value) => setFieldValue('tranquilo', value, false)}
                  />
                </Col>

                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Agitado'
                    name='agitado'
                    checked={values.agitado || false}
                    onChange={(value) => setFieldValue('agitado', value, false)}
                  />
                </Col>

                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Agressivo'
                    name='agressivo'
                    checked={values.agressivo || false}
                    onChange={(value) => setFieldValue('agressivo', value, false)}
                  />
                </Col>

                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Ansiedade'
                    name='ansiedade'
                    checked={values.ansiedade || false}
                    onChange={(value) => setFieldValue('ansiedade', value, false)}
                  />
                </Col>

                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Medo'
                    name='medo'
                    checked={values.medo || false}
                    onChange={(value) => setFieldValue('medo', value, false)}
                  />
                </Col>

                <Col md={1} style={{ marginRight: '3vw' }}>
                  <Switch
                    label='Solicitante'
                    name='solicitante'
                    checked={values.solicitante || false}
                    onChange={(value) => setFieldValue('solicitante', value, false)}
                  />
                </Col>

                <Col md={4}>
                  <Checkbox
                    id='naoAplica'
                    label='Não se aplica'
                    checked={values.naoAplica || false}
                    onChange={(value) => setFieldValue('naoAplica', value, false)}
                  />
                </Col>               
              </Row>

              <Row>
                <Col md={1}>
                  <Switch
                    label='Alergias'
                    name='temAlergias'
                    checked={values.temAlergias || false}
                    onChange={(value) => setFieldValue('temAlergias', value, false)}
                  />
                </Col>

                <Col md={11}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='alergias'>
                      Alergia - Descrição
                    </label>
                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <InputStyled
                        type='text'
                        className='form-input form-control w-100'
                        id='alergias'
                        name='alergias'
                        value={values.alergias || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      {(values.alergias || values.temAlergias) && (<MdSick color='#FD2424' style={{ marginLeft: '8px' }} size={26}/>)}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div style={{ marginBottom: '16px' }}>
                    <Select
                      name='precaucoes'
                      id='precaucoes'
                      label='Precauções'
                      value={values.precaucoes || ''}
                      icone={
                        values.precaucoes && Number(values.precaucoes) > 1 ? (<img src={isolamento} alt='Imagem do profissional' height={35} width={35} />) : (<></>)}
                      options={[
                        { label: '', value: '' },
                        { label: 'Isolamento padrão', value: 1 },
                        { label: 'Isolamento de contato', value: 2 },
                        { label: 'Isolamento respiratorio por gotícula', value: 3 },
                        { label: 'Isolamento respiratorio por aerossol', value: 4 },
                      ]}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='diagnosticoPsiquiatra'>
                      Risco de queda
                    </label>

                    <RiscoQueda
                      valoresIniciais={{
                        historicoQueda: values.historicoQueda,
                        diagnosticoSecundario: values.diagnosticoSecundario,
                        terapiaEndovenosa: values.terapiaEndovenosa,
                        auxilioDeambulacao: values.auxilioDeambulacao,
                        marcha: values.marcha,
                        estadoMental: values.estadoMental,
                      }}
                      aoFinalizar={(dados) => {
                        setFieldValue('historicoQueda', dados.historicoQueda, false)
                        setFieldValue('diagnosticoSecundario', dados.diagnosticoSecundario, false)
                        setFieldValue('terapiaEndovenosa', dados.terapiaEndovenosa, false)
                        setFieldValue('auxilioDeambulacao', dados.auxilioDeambulacao, false)
                        setFieldValue('marcha', dados.marcha, false)
                        setFieldValue('estadoMental', dados.estadoMental, false)
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ border: '1px solid #304439', borderRadius: '8px', marginBottom: '16px' }}>
                <div style={{ padding: '8px', color: '#304439', fontSize: '22px' }}>Saúde mental</div>
                <Col md={3}>
                  <div style={{ marginBottom: '16px' }}>
                    <Select
                      name='acompanhamento'
                      id='acompanhamento'
                      label='Acompanhamento'
                      value={values.acompanhamento || ''}
                      options={[
                        { label: 'Não tem', value: 'NAOTEM' },
                        { label: 'PSF', value: 'PSF' },
                        { label: 'CAPS', value: 'CAPS' },
                      ]}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='diagnosticoPsiquiatra'>
                      Diagnóstico do psiquiatra
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='diagnosticoPsiquiatra'
                      name='diagnosticoPsiquiatra'
                      value={values.diagnosticoPsiquiatra || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='adesaoTratamento'>
                      Adesão ao tratamento
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='adesaoTratamento'
                      name='adesaoTratamento'
                      value={values.adesaoTratamento || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='caps'>
                      CAPS
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='caps'
                      name='caps'
                      value={values.caps || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='relacaoFamilia'>
                      Relação com a família
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='relacaoFamilia'
                      name='relacaoFamilia'
                      value={values.relacaoFamilia || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioSegFisicaEmocional }
export default ProntuarioSegFisicaEmocional
