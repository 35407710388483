import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { Select, Switch } from '../../../../../components'
import { IProntuarioFuncaoNeuroAvaOcularForm } from '../../../../../api/prontuario/types'
import { InputStyled } from '../../../../../components/styles'

type Props = {}

const FuncaoNeuroForm = (props: Props) => {
  const { handleBlur, handleChange, setFieldValue, values } = useFormikContext<IProntuarioFuncaoNeuroAvaOcularForm>()

  useEffect(() => {
    const calcGlasgowIgual = () => {
      let soma = 0
      if (values.glasgowAo) soma += Number(values.glasgowAo)
      if (values.glasgowRv) soma += Number(values.glasgowRv)
      if (values.glasgowRm) soma += Number(values.glasgowRm)
      if (values.glasgowRp) soma += Number(values.glasgowRp)
    
      return soma
    }

    setFieldValue('glasgowIgual', calcGlasgowIgual(), false)
  }, [
    values.glasgowAo,
    values.glasgowRm,
    values.glasgowRp,
    values.glasgowRv,
    setFieldValue
  ])

  return (
    <>
      <Row>
        <Col md={1} style={{ marginRight: '16px' }}>
          <Switch
            label='Consciente'
            name='consciente'
            checked={values.consciente || false}
            onChange={(value) => setFieldValue('consciente', value, false)}
          />
        </Col>

        <Col md={2}>
          <Switch
            label='Inconsciente'
            name='inconsciente'
            checked={values.inconsciente || false}
            onChange={(value) => setFieldValue('inconsciente', value, false)}
          />
        </Col>

        <Col md={1} style={{ marginRight: '16px' }}>
          <Switch
            label='Orientado'
            name='orientado'
            checked={values.orientado || false}
            onChange={(value) => setFieldValue('orientado', value, false)}
          />
        </Col>

        <Col md={2}>
          <Switch
            label='Desorientado'
            name='desorientado'
            checked={values.desorientado || false}
            onChange={(value) => setFieldValue('desorientado', value, false)}
          />
        </Col>

        <Col md={1} style={{ marginRight: '16px' }}>
          <Switch
            label='Torporoso'
            name='torporoso'
            checked={values.torporoso || false}
            onChange={(value) => setFieldValue('torporoso', value, false)}
          />
        </Col>

        <Col md={1} style={{ marginRight: '16px' }}>
          <Switch
            label='Em sedação'
            name='sedacao'
            labelStyle={{ whiteSpace: 'nowrap' }}
            checked={values.sedacao || false}
            onChange={(value) => setFieldValue('sedacao', value, false)}
          />
        </Col>

        <Col md={1}>
          <Switch
            label='Comatoso'
            name='comatoso'
            checked={values.comatoso || false}
            onChange={(value) => setFieldValue('comatoso', value, false)}
          />
        </Col>
      </Row>

      <Row>
        <Col md={1}>
          <Switch
            label='Convulsao'
            name='convulsao'
            checked={values.convulsao || false}
            onChange={(value) => setFieldValue('convulsao', value, false)}
          />
        </Col>

        <Col md={3}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='convulsaoEpisodio'>
              Episódio
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='convulsaoEpisodio'
              name='convulsaoEpisodio'
              value={values.convulsaoEpisodio || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={2} style={{ paddingRight: '0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='glasgowAo'
              id='glasgowAo'
              label='RV'
              value={values.glasgowAo || ''}
              options={[
                { label: '', value: '' },
                { label: '1 - Nenhuma', value: 1 },
                { label: '2 - À dor', value: 2 },
                { label: '3 - À voz', value: 3 },
                { label: '4 - Espontânea', value: 4 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1} style={{ paddingRight: '0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='glasgowRv'
              id='glasgowRv'
              label='+ RV'
              value={values.glasgowRv || ''}
              options={[
                { label: '', value: '' },
                { label: '1 - Nenhuema', value: 1 },
                { label: '2 - Palavras incompreensíveis', value: 2 },
                { label: '3 - Palavras inapropriadas', value: 3 },
                { label: '4 - Confusa', value: 4 },
                { label: '5 - Orientada', value: 5 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1} style={{ paddingRight: '0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='glasgowRm'
              id='glasgowRm'
              label='+ RM'
              value={values.glasgowRm || ''}
              options={[
                { label: '', value: '' },
                { label: '1 - Nenhuma', value: 1 },
                { label: '2 - Extensão anormal', value: 2 },
                { label: '3 - Flexão normal', value: 3 },
                { label: '4 - Movimento de retirada', value: 4 },
                { label: '5 - Localiza a dor', value: 5 },
                { label: '6 - Obedece a comandos', value: 6 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1} style={{ paddingRight: '0px' }}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='glasgowRp'
              id='glasgowRp'
              label='- RP'
              value={values.glasgowRp || ''}
              options={[
                { label: '', value: '' },
                { label: '0 - Reação bilateral ao estímulo', value: 0 },
                { label: '1 - Apenas uma reage ao estímulo luminoso', value: -1 },
                { label: '2 - Nenhuma', value: -2 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1}>
          <div style={{ marginBottom: '16px' }}>
            <label className="form-check-label form-label" htmlFor='glasgowIgual'>
              =
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='glasgowIgual'
              name='glasgowIgual'
              value={values.glasgowIgual || ''}
              disabled
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col> 

        <Col md={1}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='ramsay'
              id='ramsay'
              label='Ramsay'
              value={values.ramsay || ''}
              options={[
                { label: '', value: '' },
                { label: '1 - Ansioso', value: 1 },
                { label: '2 - Acordado / tranquilo', value: 2 },
                { label: '3 - Sonolento', value: 3 },
                { label: '4 - Acorda ao estímulo leve', value: 4 },
                { label: '5 - Abre olhos com dor', value: 5 },
                { label: '6 - Sem resposta', value: 6 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='rass'
              id='rass'
              label='RASS'
              value={values.rass || ''}
              options={[
                { label: '', value: '' },
                { label: '5 - Incapaz de ser despertado', value: -5 },
                { label: '4 - Sedação profunda', value: -4 },
                { label: '3 - Sedação moderada', value: -3 },
                { label: '2 - Sedação leve', value: -2 },
                { label: '1 - Sonolento', value: -1 },
                { label: '+0 - Alerta, calmo', value: 0 },
                { label: '+1 - Inquieto', value: 1 },
                { label: '+2 - Agitado', value: 2 },
                { label: '+3 - Muito agitado', value: 3 },
                { label: '+4 - Agressino', value: 4 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export { FuncaoNeuroForm }
export default FuncaoNeuroForm