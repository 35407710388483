import React, { ChangeEvent, useContext, useState } from 'react'
import { BiMessageRoundedDetail } from 'react-icons/bi'
import { Col, Modal, Row } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { IUsuarioPontoProntuario, pontoProfissionalProntuarioEditarAPI } from '../../../../../../api/profissionais/pontoProfissionalAPI'
import { TextAreaStyled } from '../../../../../../components/styles'
import Button from '../../../../../../components/Button'
import { PassagemPlantaoContext } from '../../context'


interface IUsuarioPontoProntuarioEditObservacaoProps {
  usuarioPontoProntuario: IUsuarioPontoProntuario
}

const UsuarioPontoProntuarioEditObservacao = (props: IUsuarioPontoProntuarioEditObservacaoProps) => {
  const [modalMessagemAberto, setModalMessagemAberto] = useState<boolean>(false)
  const [observacoes, setObservacoes] = useState<string>(props.usuarioPontoProntuario.descricao || '')
  const { loadUltimoPonto } = useContext(PassagemPlantaoContext)

  const handleInputOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setObservacoes(value);
  };

  const handleSubmit = () => {
    pontoProfissionalProntuarioEditarAPI(props.usuarioPontoProntuario.id, observacoes)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Ok...',
          text: 'Salvo com sucesso!',
          confirmButtonColor: '#1A8C01',
        })
        setModalMessagemAberto(false)
      })
      .catch((err) => console.error(err))
      .finally(() => loadUltimoPonto())
  }

  return (
    <>
      <BiMessageRoundedDetail size={26} color='#304439' style={{ cursor: 'pointer' }} onClick={() => setModalMessagemAberto(true)}/>

      <Modal show={modalMessagemAberto} onHide={() => setModalMessagemAberto(false)}>
        <Modal.Header style={{ color: '#304439' }}>
          <Modal.Title>
            Observações do paciente
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#304439' }}>
          <Row>
            <Col>
              <div style={{marginBottom: '16px'}}>
                <label className="form-check-label form-label" htmlFor='observacoes'>
                  Observações
                </label>

                <TextAreaStyled
                  className='form-input form-control w-100'
                  id='observacoes'
                  name='observacoes'
                  value={observacoes}
                  rows={3}
                  onChange={(e) => handleInputOnChange(e)}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => handleSubmit()}
          >
            Salvar
          </Button>

          <Button
            variant='danger'
            onClick={() => {
              setObservacoes('')
              setModalMessagemAberto(false)
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { UsuarioPontoProntuarioEditObservacao }
export default UsuarioPontoProntuarioEditObservacao
