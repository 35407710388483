import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { ILeitoListagem } from '../../../../api/cadastro/leitosAPI';
import { leitoListarAPI } from '../../../../api/cadastro/leitosAPI';

interface TLeitosContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  leitos: ILeitoListagem[],
  setLeitos: (newLeitos: ILeitoListagem[]) => void,
  selecionado: ILeitoListagem | null,
  setSelecionado: (novoSelecionado: ILeitoListagem | null) => void,
  loadLeitos: () => void
}

interface TLeitosProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  leitos: [],
  setLeitos: () => {
    throw new Error('setLeitos não implementada');
  },
  loadLeitos: () => {
    throw new Error('loadLeitos não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const LeitosContext = createContext<TLeitosContext>(defaultState);

export const LeitosProvider = ({children}: TLeitosProviderProps) => {
  const [leitos, setLeitos] = useState<ILeitoListagem[]>(defaultState.leitos);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<ILeitoListagem | null>(defaultState.selecionado)

  const loadLeitos = () => {
    setCarregando(true)
    leitoListarAPI()
        .then((data) => setLeitos(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadLeitos();
  }, []);

  const providerValues: TLeitosContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    leitos,
    setLeitos,
    loadLeitos,
  };

  return (
    <LeitosContext.Provider value={providerValues}>
      {children}
    </LeitosContext.Provider>
  );
};
