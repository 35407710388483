import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Form, Formik, FormikHelpers } from 'formik'
import { Button } from 'react-bootstrap'
import * as Yup from 'yup'
import Swal from 'sweetalert2'

import { IEsqueciSenhaForm, esqueciSenhaAPI } from '../../api/loginAPI'
import { InputStyled } from '../../components/styles'

interface IEsqueciSenhaProps {
  modalAberto: boolean,
  fecharModal: () => void,
}

const EsqueciSenha = (props: IEsqueciSenhaProps) => {
  const initialValues: IEsqueciSenhaForm = { email: '' }

  const schema = Yup.object().shape({
    email: Yup.string().nullable().required('Campo obrigatório!'),
  })

  const handleSubmit = (values: IEsqueciSenhaForm, actions: FormikHelpers<IEsqueciSenhaForm>) => {
    console.log(values.email);
    // try{ 
    //   esqueciSenhaAPI(values)
    //     .then(() => {
    //       actions.resetForm() 
    //       Swal.fire({
    //         icon: 'success',
    //         text: 'Enviamos um token pro seu email. Utilize-o para fazer login!',
    //         confirmButtonColor: '#1A8C01',
    //       })
    //       props.fecharModal()
    //     })
    //     .catch(err => console.error(err))
    // }catch(e){
    //   console.log
    // }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ submitForm, handleBlur, handleChange, errors, touched, values }) => (
        <Form >
          <Modal
            show={props.modalAberto}
            style={{ marginTop: '70px' }}
            onHide={() => props.fecharModal()}
          >
            <Modal.Header style={{background: '#283444'}}>
              <Modal.Title>Recuperação de senha</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{background: '#3e5470', width: '100%', display: 'flex'}}>
            <Row>
              <Col md={12} >
              <InputStyled
                type='email'
                className='form-input form-control w-100'
                id='email'
                name='email'
                placeholder='E-mail'
                value={values.email || ''}
                onBlur={handleBlur}
                onChange={handleChange}
              />

                {errors.email && touched.email && (
                  <div style={{ color: '#FF264D', marginBottom: '16px', marginLeft: '8px' }}>
                    {errors.email}
                  </div>
                )}
              </Col>
            </Row>
            </Modal.Body>

            <Modal.Footer style={{background: '#3e5470'}}>
              <Button
                variant='primary'
                style={{ width: '190px', marginTop: '12px' }}
                onClick={submitForm}
              >
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export { EsqueciSenha }
export default EsqueciSenha
