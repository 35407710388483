import {
  IProntuarioRegistroComplementarCVCListagem,
  IProntuarioRegistroComplementarCVCListagemBackend,
  IProntuarioRegistroComplementarITUListagem,
  IProntuarioRegistroComplementarITUListagemBackend,
  IProntuarioRegistroComplementarListagem,
  IProntuarioRegistroComplementarListagemBackend,
  IProntuarioRegistroComplementarPAVListagem,
  IProntuarioRegistroComplementarPAVListagemBackend,
} from '../types'

export const parseProntuarioRegistroComplementarBackToFront = (
  dadosBackend: IProntuarioRegistroComplementarListagemBackend
): IProntuarioRegistroComplementarListagem => ({
  id: dadosBackend.id,
  eventosAdversos: dadosBackend.eventos_adversos,
  ocorrencias: dadosBackend.ocorrencias,
})

export const parseProntuarioRegistroComplementarITUBackToFront = (
  dadosBackend: IProntuarioRegistroComplementarITUListagemBackend
): IProntuarioRegistroComplementarITUListagem => ({
  id: dadosBackend.id,
  svn: dadosBackend.svn,
  svnEspecificar: dadosBackend.svn_especificar,
  sistemaDrenagem: dadosBackend.sistema_drenagem,
  bolsaColetora: dadosBackend.bolsa_coletora,
  fluxoUninario: dadosBackend.fluxo_uninario,
  cateterFixado: dadosBackend.cateter_fixado,
  realizadaHigiene: dadosBackend.realizada_higiene,
  limiteCapacidadeBolsa: dadosBackend.limite_capacidade_bolsa,
  uretaIntegra: dadosBackend.ureta_integra,
  uretaIntegraEspecificar: dadosBackend.ureta_integra_especificar,
  urinaAspectoNormal: dadosBackend.urina_aspecto_normal,
  urinaAspectoEspecificar: dadosBackend.urina_aspecto_especificar,
})

export const parseProntuarioRegistroComplementarCVCBackToFront = (
  dadosBackend: IProntuarioRegistroComplementarCVCListagemBackend
): IProntuarioRegistroComplementarCVCListagem => ({
  id: dadosBackend.id,
  conectoresPrecisamTrocar: dadosBackend.conectores_precisam_trocar,
  curativoPrecisamTrocar: dadosBackend.curativo_precisam_trocar,
  equipamentosComData: dadosBackend.equipamentos_com_data,
  fluxoUninario: dadosBackend.fluxo_uninario,
  curativoSujo: dadosBackend.curativo_sujo,
  higenienizacaoMaos: dadosBackend.higenienizacao_maos,
  usoGorroMascara: dadosBackend.uso_gorro_mascara,
  sf0_09: dadosBackend.sf0_09,
  clorexina: dadosBackend.clorexina,
  gaze: dadosBackend.gaze,
  filmeTransparente: dadosBackend.filme_transparente,
  malAderido: dadosBackend.mal_aderido,
  presencaSangue: dadosBackend.presenca_sangue,
  presencaExsudato: dadosBackend.presenca_exsudato,
  trocaRotina: dadosBackend.troca_rotina,
  opcaoFilme: dadosBackend.opcao_filme,
  semSinaisFlosgisticos: dadosBackend.sem_sinais_flosgisticos,
  presencaSinaisFlosgisticos: dadosBackend.presenca_sinais_flosgisticos,
})

export const parseProntuarioRegistroComplementarPAVBackToFront = (
  dadosBackend: IProntuarioRegistroComplementarPAVListagemBackend
): IProntuarioRegistroComplementarPAVListagem => ({
  id: dadosBackend.id,
  higieneOralRealizada: dadosBackend.higiene_oral_realizada,
  cabeceiraLeito_30_45: dadosBackend.cabeceira_leito_30_45,
  ventilorioDesprezados: dadosBackend.ventilorio_desprezados,
  higieneMaos: dadosBackend.higiene_maos,
  totFixado: dadosBackend.tot_fixado,
  doencaPulmonar: dadosBackend.doenca_pulmonar,
  rxTorax: dadosBackend.rx_torax,
  filtradoOpacificacao: dadosBackend.filtrado_opacificacao,
  teveFebreMaior_38: dadosBackend.teve_febre_maior_38,
  leocopeniaMenor_4000: dadosBackend.leocopenia_menor_4000,
  leococitoseMaior_12000: dadosBackend.leococitose_maior_12000,
})
