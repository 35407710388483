import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { LesoesProvider } from './context';
import { LesoesContainerForm } from './LesoesContainerForm';

export interface ILesoesForm {
  nome: string,
  ativo: boolean,
  lesaoPressao: boolean,
}

const Lesoes = () => {
  return (
    <LesoesProvider>
      <DivMainStyled>
        <DivCardStyled>
          <LesoesContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </LesoesProvider>
  )
}

export { Lesoes }
export default Lesoes
