// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #A9E2C3;
}

#root {
  margin: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal {
  --bs-modal-bg: #feffbf;
  --bs-modal-color: white;
}

.react-date-picker__wrapper {
  border: none !important;
}

.form-range {
  height: 5px;
}

.tabs-sol {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #222222;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #222222 #304439 #304439;
  --bs-nav-link-hover-color: #222222;
  --bs-nav-tabs-link-active-color: #222222;
  --bs-nav-link-color: #222222;
  --bs-nav-tabs-link-active-bg: #feffbf;
  --bs-nav-tabs-link-active-border-color: #222222 #304439 #C19B13;
}

.react-datepicker-wrapper {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;EACZ;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE;aACW;AACb;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,mCAAmC;EACnC,qCAAqC;EACrC,8DAA8D;EAC9D,kCAAkC;EAClC,wCAAwC;EACxC,4BAA4B;EAC5B,qCAAqC;EACrC,+DAA+D;AACjE;;AAEA;EACE,WAAW;AACb","sourcesContent":["html {\n  margin: 0;\n  width: 100%;\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  width: 100%;\n  height: 100%;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #A9E2C3;\n}\n\n#root {\n  margin: 0;\n  width: 100%;\n  height: 100%;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.modal {\n  --bs-modal-bg: #feffbf;\n  --bs-modal-color: white;\n}\n\n.react-date-picker__wrapper {\n  border: none !important;\n}\n\n.form-range {\n  height: 5px;\n}\n\n.tabs-sol {\n  --bs-nav-tabs-border-width: 1px;\n  --bs-nav-tabs-border-color: #222222;\n  --bs-nav-tabs-border-radius: 0.375rem;\n  --bs-nav-tabs-link-hover-border-color: #222222 #304439 #304439;\n  --bs-nav-link-hover-color: #222222;\n  --bs-nav-tabs-link-active-color: #222222;\n  --bs-nav-link-color: #222222;\n  --bs-nav-tabs-link-active-bg: #feffbf;\n  --bs-nav-tabs-link-active-border-color: #222222 #304439 #C19B13;\n}\n\n.react-datepicker-wrapper {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
