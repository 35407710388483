import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const CurrencyInputStyled = styled(MaskedInput)`
  background-color: #212529;
  color: #ffffff;
  border: 1px solid #ced4da;

  :focus {
    background-color: #212529;
    border: 1px solid #ced4da;
    color: #ffffff;
  }

  :checked {
    background-color: #212529;
    border: 1px solid #ced4da;
    color: #ffffff;
  }

  :disabled {
    background-color: #696E63;
    color: #FFF7E6
  }
`;

const defaultMaskOptions = {
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
};

export const CurrencyInput = ({...inputProps}) => {
  const currencyMask = createNumberMask(defaultMaskOptions);

  return <CurrencyInputStyled mask={currencyMask} {...inputProps} />;
};

export default CurrencyInput;
