import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../prontuario/types'

export interface IIntervencaoListagem {
  id: number,
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

export const intervencaoListarAPI = async (): Promise<IIntervencaoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IIntervencaoListagem[]> = await axiosAPI.get(
      'intervencao',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectIntervencaoAPI = async (grupo: GrupoProntuarioPlanoCuidadoEnum | -1): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IIntervencaoListagem[]> = await axiosAPI.get(
      'intervencao',
      { params: { id_empresa: user.idEmpresa, grupo: grupo !== -1 ? grupo : undefined } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const intervencaoCreateAPI = async (nome: string, ativo: boolean, grupo: GrupoProntuarioPlanoCuidadoEnum | -1): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'intervencao',
      { nome, ativo, id_empresa: user.idEmpresa, grupo: grupo !== -1 ? grupo : null },
  )
}

export const intervencaoUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
    grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
): Promise<void> => {
  await axiosAPI.put(
      'intervencao/edit',
      {id, nome, ativo, grupo: grupo !== -1 ? grupo : null},
  )
}

export const intervencaoDeleteAPI = async (id: number): Promise<IIntervencaoListagem[]> => {
  const response = await axiosAPI.delete('intervencao/delete', { params: { id } })

  return response.data
}
