import { IProntuarioPercepcaoSentidosListagem, IProntuarioPercepcaoSentidosListagemBackend } from "../types";

export const parseProntuarioPercepcaoSentidosBackToFront = (dadosBackend: IProntuarioPercepcaoSentidosListagemBackend): IProntuarioPercepcaoSentidosListagem => ({
  id: dadosBackend.id,
  dor: dadosBackend.dor,
  localDor: dadosBackend.local_dor,
  escalaDor: dadosBackend.escala_dor,
  medicamentoDor: dadosBackend.medicamento_dor,
  resolutividade: dadosBackend.resolutividade,
  escalaCcpot: dadosBackend.escala_ccpot,
  expressaoFacial: dadosBackend.expressao_facial,
  tensaoMuscular: dadosBackend.tensao_muscular,
  movimentoCorporal: dadosBackend.movimento_corporal,
  adaptacaoVentilador: dadosBackend.adaptacao_ventilador,
  visao: dadosBackend.visao,
  audicao: dadosBackend.audicao,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
