import { parsePacienteSemVinculoBackToFront } from '../../pacientes/parser'
import { IProntuarioListagem, IProntuarioListagemBackend } from '../types'
import { parseProntuarioComunicacaoEducacaoBackToFront } from './parseProntuarioComunicacaoEducacao'
import { parseProntuarioControleInfusaoBackToFront } from './parseProntuarioControleInfusao'
import { parseProntuarioCuidadoCorporalBackToFront } from './parseProntuarioCuidadoCorporal'
import { parseProntuarioEliminacoesFisiologicasBackToFront } from './parseProntuarioEliminacoesFisiologicas'
import { parseProntuarioExameFisicoBackToFront } from './parseProntuarioExameFisico'
import { parseProntuarioFuncaoCardiovascularBackToFront } from './parseProntuarioFuncaoCardiovascular'
import { parseProntuarioFuncaoNeuroAvaOcularBackToFront } from './parseProntuarioFuncaoNeuroAvaOcular'
import { parseProntuarioFuncaoRespiratoriaBackToFront } from './parseProntuarioFuncaoRespiratoria'
import { parseProntuarioIntegridadeFisicaBackToFront } from './parseProntuarioIntegridadeFisica'
import { parseProntuarioNutricaoBackToFront } from './parseProntuarioNutricao'
import { parseProntuarioPercepcaoSentidosBackToFront } from './parseProntuarioPercepcaoSentidos'
import { parseProntuarioPlanoCuidadoBackToFront } from './parseProntuarioPlanoCuidado'
import {
  parseProntuarioRegistroComplementarBackToFront,
  parseProntuarioRegistroComplementarCVCBackToFront,
  parseProntuarioRegistroComplementarITUBackToFront,
  parseProntuarioRegistroComplementarPAVBackToFront,
} from './parseProntuarioRegistroComplementar'
import { parseProntuarioReligiosidadeBackToFront } from './parseProntuarioReligiosidade'
import { parseProntuarioSegFisicaEmocionalBackToFront } from './parseProntuarioSegFisicaEmocional'
import { parseProntuarioSexualidadeBackToFront } from './parseProntuarioSexualidade'
import { parseProntuarioSonoRepousoBackToFront } from './parseProntuarioSonoRepouso'
import { parseProntuarioTerapiaEndovenosaBackToFront } from './parseProntuarioTerapiaEndovenosa'

export const parseProntuarioBackToFront = (dadosBackend: IProntuarioListagemBackend): IProntuarioListagem => ({
  id: dadosBackend.id,
  dhAdmissao: dadosBackend.dh_admissao,
  dhSaida: dadosBackend.dh_saida,
  cliMedica: dadosBackend.cli_medica,
  cliCirurgica: dadosBackend.cli_cirurgica,
  uti: dadosBackend.uti,
  procResidencia: dadosBackend.proc_residencia,
  procRua: dadosBackend.proc_rua,
  procServSaude: dadosBackend.proc_serv_saude,
  procSetor: dadosBackend.proc_setor,
  procSetorNome: dadosBackend.proc_setor_nome,
  isolProtetor: dadosBackend.isol_protetor,
  isolRespiratorio: dadosBackend.isol_respiratorio,
  isolContato: dadosBackend.isol_contato,
  isolVigilancia: dadosBackend.isol_vigilancia,
  riscoLesaoPressao: dadosBackend.risco_lesao_pressao,
  riscoRessecamentoOcular: dadosBackend.risco_ressecamento_ocular,
  riscoAlergia: dadosBackend.risco_alergia,
  riscoQueda: dadosBackend.risco_queda,
  riscoContagio: dadosBackend.risco_contagio,
  queixaPrincipal: dadosBackend.queixa_principal,
  comorbidades: dadosBackend.comorbidades,
  medicacaoUso: dadosBackend.medicacao_uso,
  registroComplementar: dadosBackend.registro_complementar,
  idLeito: dadosBackend.id_leito,
  leito: dadosBackend.leito,
  idPaciente: dadosBackend.id_paciente,
  paciente: dadosBackend.paciente ? parsePacienteSemVinculoBackToFront(dadosBackend.paciente) : undefined,
  idEmpresa: dadosBackend.id_empresa,
  nomeProntuario: dadosBackend.nome_prontuario,
  alterado: dadosBackend.alterado ? new Date(dadosBackend.alterado) : undefined,
  dhPlanoCuidado: dadosBackend.dh_plano_cuidado ? new Date(dadosBackend.dh_plano_cuidado) : undefined,
  prontuarioExameFisico: dadosBackend.ProntuarioExameFisico?.map(i => parseProntuarioExameFisicoBackToFront(i)) || [],
  prontuarioPercepcaoSentidos: dadosBackend.ProntuarioPercepcaoSentidos?.map(i => parseProntuarioPercepcaoSentidosBackToFront(i)) || [],
  prontuarioSonoRepouso: dadosBackend.ProntuarioSonoRepouso?.map(i => parseProntuarioSonoRepousoBackToFront(i)) || [],
  prontuarioSegFisicaEmocional: dadosBackend.ProntuarioSegEmocional?.map(i => parseProntuarioSegFisicaEmocionalBackToFront(i)) || [],
  prontuarioFuncaoNeuroAvaOcular: dadosBackend.ProntuarioAvaliacaoNeuro?.map(i => parseProntuarioFuncaoNeuroAvaOcularBackToFront(i)) || [],
  prontuarioCuidadoCorporal: dadosBackend.ProntuarioCuidadoCorporal?.map(i => parseProntuarioCuidadoCorporalBackToFront(i)) || [],
  prontuarioSexualidade: dadosBackend.ProntuarioSexualidade?.map(i => parseProntuarioSexualidadeBackToFront(i)) || [],
  prontuarioReligiosidade: dadosBackend.ProntuarioReligiosidade?.map(i => parseProntuarioReligiosidadeBackToFront(i)) || [],
  prontuarioComunicacaoEducacao: dadosBackend.ProntuarioComunicacaoEducacao?.map(i => parseProntuarioComunicacaoEducacaoBackToFront(i)) || [],
  prontuarioNutricao: dadosBackend.ProntuarioNutricao?.map(i => parseProntuarioNutricaoBackToFront(i)) || [],
  prontuarioFuncaoRespiratoria: dadosBackend.ProntuarioFuncaoRespiratoria?.map(i => parseProntuarioFuncaoRespiratoriaBackToFront(i)) || [],
  prontuarioFuncaoCardiovascular: dadosBackend.ProntuarioFuncaoCardiovascular?.map(i => parseProntuarioFuncaoCardiovascularBackToFront(i)) || [],
  prontuarioIntegridadeFisica: dadosBackend.ProntuarioIntegridadeFisica?.map(i => parseProntuarioIntegridadeFisicaBackToFront(i)) || [],
  prontuarioTerapiaEndovenosa: dadosBackend.ProntuarioTerapiaEndovenosa?.map(i => parseProntuarioTerapiaEndovenosaBackToFront(i)) || [],
  prontuarioEliminacaoFisio: dadosBackend.ProntuarioEliminacaoFisio?.map(i => parseProntuarioEliminacoesFisiologicasBackToFront(i)) || [],
  prontuarioControleInfusao: dadosBackend.ProntuarioControleInfusao?.map(i => parseProntuarioControleInfusaoBackToFront(i)) || [],
  prontuarioRegistroComplementar: dadosBackend.ProntuarioRegistroComplementar?.map(i => parseProntuarioRegistroComplementarBackToFront(i)) || [],
  prontuarioRegistroComplementarCVC: dadosBackend.ProntuarioRegistroComplementarCVC?.map(i => parseProntuarioRegistroComplementarCVCBackToFront(i)) || [],
  prontuarioRegistroComplementarITU: dadosBackend.ProntuarioRegistroComplementarITU?.map(i => parseProntuarioRegistroComplementarITUBackToFront(i)) || [],
  prontuarioRegistroComplementarPAV: dadosBackend.ProntuarioRegistroComplementarPAV?.map(i => parseProntuarioRegistroComplementarPAVBackToFront(i)) || [],
  prontuarioPlanoCuidado: dadosBackend.ProntuarioPlanoCuidado?.map(i => parseProntuarioPlanoCuidadoBackToFront(i)) || [],
})
