
import { parseISO } from 'date-fns'
import { parseProntuarioBackToFront } from '../../prontuario/parser'
import { IPacienteListagem, IPacienteListagemBackend, IPacienteListagemSemVinculo, IPacienteListagemSemVinculoBackend } from '../types'

export const parsePacienteBackToFront = (dadosBackend: IPacienteListagemBackend): IPacienteListagem => ({
  id: dadosBackend?.id,
  nome: dadosBackend?.nome,
  sexo: dadosBackend?.sexo,
  idade: dadosBackend?.idade,
  dataNascimento: dadosBackend?.data_nascimento ? parseISO(dadosBackend.data_nascimento) : null,
  email: dadosBackend?.email,
  telefone: dadosBackend?.telefone,
  contato: dadosBackend?.contato,
  estadoCivil: dadosBackend?.estado_civil,
  numeroSUS: dadosBackend?.numero_sus,
  prontuario: dadosBackend?.Prontuario?.map(pront => parseProntuarioBackToFront(pront)) || [],
  alterado: dadosBackend?.alterado,
  todosGruposAvaliados: dadosBackend?.todosGruposAvaliados,
  plantoes: dadosBackend?.plantoes,
})

export const parsePacienteSemVinculoBackToFront = (dadosBackend: IPacienteListagemSemVinculoBackend): IPacienteListagemSemVinculo => ({
  id: dadosBackend.id,
  nome: dadosBackend.nome,
  sexo: dadosBackend.sexo,
  idade: dadosBackend.idade,
  dataNascimento: dadosBackend.data_nascimento ? parseISO(dadosBackend.data_nascimento) : null,
  email: dadosBackend.email,
  telefone: dadosBackend.telefone,
  contato: dadosBackend.contato,
  estadoCivil: dadosBackend.estado_civil,
  numeroSUS: dadosBackend.numero_sus,
  alterado: dadosBackend.alterado,
  todosGruposAvaliados: dadosBackend.todosGruposAvaliados,
  plantoes: dadosBackend.plantoes,
})
