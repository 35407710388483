import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import Swal from 'sweetalert2'

import {
  IProntuarioFuncaoNeuroAvaOcularForm,
  prontuarioFuncaoNeuroAvaOcularCreateAPI,
} from '../../../../../api/prontuario/prontuarioFuncaoNeuroAvaOcularAPI'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import FunNeuroAvaOcularBotoes from './FunNeuroAvaOcularBotoes'
import FuncaoNeuroForm from './FuncaoNeuroForm'
import AvaliacaoOcularForm from './AvaliacaoOcularForm'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { verificarAvaliadoNoDia } from '../../util'
import { Spinner } from 'react-bootstrap'

interface IProntuarioFuncaoNeuroAvaOcularProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioFuncaoNeuroAvaOcular = (props: IProntuarioFuncaoNeuroAvaOcularProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.FUNCAO_NEUROLOGICA)

  const initialValues: IProntuarioFuncaoNeuroAvaOcularForm = {
    consciente: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.consciente || null,
    inconsciente: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.inconsciente || null,
    orientado: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.orientado || null,
    desorientado: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.desorientado || null,
    torporoso: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.torporoso || null,
    sedacao: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.sedacao || null,
    comatoso: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.comatoso || null,
    glasgowAo: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowAo || null,
    glasgowRv: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowRv || null,
    glasgowRm: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowRm || null,
    glasgowRp: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowRp || null,
    glasgowIgual: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.glasgowIgual || null,
    ramsay: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.ramsay || null,
    rass: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.rass || null,
    convulsao: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsao || null,
    convulsaoEpisodio: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.convulsaoEpisodio || null,
    pupilaIsocoricas: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaIsocoricas || null,
    pupilaMioticas: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaMioticas || null,
    pupilaMidriaticas: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaMidriaticas || null,
    pupilaFotoreagente: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaFotoreagente || null,
    pupilaNaoFotoreagente: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaNaoFotoreagente || null,
    pupilaAncoricasDe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasDe || null,
    pupilaAncoricasAte: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.pupilaAncoricasAte || null,
    lagoftalmiaOd: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.lagoftalmiaOd || null,
    lagoftalmiaOe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.lagoftalmiaOe || null,
    hiperamiaConjuntivalOd: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.hiperamiaConjuntivalOd || null,
    hiperamiaConjuntivalOe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.hiperamiaConjuntivalOe || null,
    secrecaoOd: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.secrecaoOd || null,
    secrecaoOe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.secrecaoOe || null,
    edemaPalpebralOd: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaPalpebralOd || null,
    edemaPalpebralOe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaPalpebralOe || null,
    edemaConjuntivalOd: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaConjuntivalOd || null,
    edemaConjuntivalOe: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.edemaConjuntivalOe || null,
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.avaliado),
    alterado: prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioFuncaoNeuroAvaOcularForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioFuncaoNeuroAvaOcularCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Função neurológica / Avaliação ocular incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('integridadeFisica'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioFuncaoNeuroAvaOcular[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('funNeuroAvaOcular')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Função neurológica / Avaliação ocular</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'funNeuroAvaOcular') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'funNeuroAvaOcular') ? '' : 'none'}`}}>
      { carregando ? (
          <Spinner animation="border" style={{ marginTop: '27px'}} />
        ) : (
          <Formik
            key={formKey}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            <>
              <FuncaoNeuroForm />
              <AvaliacaoOcularForm />
              <FunNeuroAvaOcularBotoes />
            </>
          </Formik>
        )}
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioFuncaoNeuroAvaOcular }
export default ProntuarioFuncaoNeuroAvaOcular
