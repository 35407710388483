import styled from 'styled-components';

export const SelectStyled = styled.select`
  background-color: #FFFFFF;
  width: 100%;
  color: #000;
  border: 1px solid #ced4da;
  padding: 12px 14px;
  border-radius: 12px;
  
  ::placeholder {
    color: #000;
  }

  :focus {
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    color: #000;
  }

  :checked {
    background-color: #FFFFFF;
    border: 1px solid #ced4da;
    color: #000;
  }

  :disabled {
    background-color: #F2F2F2;
    color: #000
  }
`;
