import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { DispositivosContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';


const DispositivosListagem = () => {
  const { dispositivos, selecionado, setSelecionado } = useContext(DispositivosContext);

  return (
    <>
      {dispositivos && dispositivos.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Dispositivo.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {dispositivos && dispositivos.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {dispositivos && dispositivos.length > 0 && dispositivos.map((dispositivo => (
                <tr
                  key={dispositivo.id}
                  style={{ border: selecionado?.id === dispositivo.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(dispositivo)}
                >
                  <td>{dispositivo.nome}</td>
                  <td>{dispositivo.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { DispositivosListagem }
export default DispositivosListagem
