import React from 'react';
import {Navigate} from 'react-router-dom';
import {getUserLocalStorage} from '../../context/auth_provider/util';

export const ProtectedOnlyGestor = ({children}: { children: JSX.Element }) => {
  const user = getUserLocalStorage();

  if (user && user.tipo === 'GESTOR') {
    return children;
  }
  return (<>
    <Navigate to='/' />
  </>);
};
