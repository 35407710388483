import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FaSave } from 'react-icons/fa'
import { BsFillEyeFill } from 'react-icons/bs'
import { useFormikContext } from 'formik'

import { Switch } from '../../../../../components'
import Button from '../../../../../components/Button'
import { IProntuarioFuncaoNeuroAvaOcularForm } from '../../../../../api/prontuario/types'

type Props = {}

const FunNeuroAvaOcularBotoes = (props: Props) => {
  const { setFieldValue, submitForm, values } = useFormikContext<IProntuarioFuncaoNeuroAvaOcularForm>()

  return (
    <Row>
      <Col md={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Switch
            label='Não avaliado'
            name='stAvaliado'
            checked={values.stAvaliado || false}
            onChange={(value) => {
              if (value) {
                setFieldValue('avaliado', new Date(), false)
              } else {
                setFieldValue('avaliado', null, false)
              }
              setFieldValue('stAvaliado', value, false)
            }}
          />

          <Button onClick={() => submitForm()} style={{ height: '28px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <FaSave size={20} style={{ marginRight: '8px',}} />
              Salvar
            </div>
          </Button>

          <div style={{ color: '', display: 'flex' }}>
            {(values.lagoftalmiaOd || values.lagoftalmiaOe || values.hiperamiaConjuntivalOd || values.hiperamiaConjuntivalOe ||
            values.secrecaoOd || values.secrecaoOe || values.edemaConjuntivalOd || values.edemaConjuntivalOe || values.edemaPalpebralOd ||
            values.edemaPalpebralOe) && (
              <BsFillEyeFill size={24} style={{ marginRight: '8px', marginTop: '38px', color: '#FD2424' }} />
            )}
            
            <Switch
              label='Alterado'
              name='alterado'
              checked={values.alterado || false}
              onChange={(value) => setFieldValue('alterado', value, false)}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export { FunNeuroAvaOcularBotoes }
export default FunNeuroAvaOcularBotoes
