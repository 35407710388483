import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../../../../../../components/Button'
import { ProntuarioContext } from '../../../../context'
import { PlanoCuidadosRelatorioCampos } from './PlanoCuidadosRelatorioCampos'

interface IPlanoCuidadosRelatorioProps {
  idPaciente: number,
}

const PlanoCuidadosRelatorio = (props: IPlanoCuidadosRelatorioProps) => {
  const [modalRepostOpen, setModalRepostOpen] = useState<boolean>(false)
  const { prontuario } = useContext(ProntuarioContext)

  return (
    <>
      <Button style={{ background: '#03549E', opacity: '0.7', marginLeft: '8px' }}
        variant='secondary'
        onClick={() => setModalRepostOpen(true)}>
            Imprimir
      </Button> 
      <Modal
        size='lg'
        show={modalRepostOpen}
        onHide={() => setModalRepostOpen(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Relatório...
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: '95vh'}}>
          {props.idPaciente !== -1 ? (
            <PlanoCuidadosRelatorioCampos idPaciente={props.idPaciente} />
          ) : prontuario?.idPaciente && (
            <PlanoCuidadosRelatorioCampos idPaciente={prontuario?.idPaciente} />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export { PlanoCuidadosRelatorio }
export default PlanoCuidadosRelatorio
