import { FaArrowLeft } from 'react-icons/fa'
import colors from '../../../colors'
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

        interface ProntuarioProps {
            dados: {
                Leito: number;
                Paciente: string;
                Idade: number;
                Admissão: string;
                DiasInternação: number;
                QueixaPrincipal: string;
                SinaisVitais: string;
                EscalaDor: number;
                Precauções: number;
                RiscoQueda: string;
                Consciência: string | null;
                Consciente: string;
                Inconsciente: string;
                Orientado: string;
                GLASGOWRAMSAYRASS: string | null;
                Glasgow: number;
                RAMSAY: string;
                RASS: string;
                EscalaBraden: string;
                Edema: string | null;
                ANASASRCANutrição: string | null;
                Oral: string;
                Aceitação: string;
                Tipo: string;
                Lesão: string | null;
                Eliminações: string;
                ConstipaçãoDias: number;
                Antibióticos: string | null;
                ControleInfusões: string;
                local: string;
                Riscos: string | null;
                Prontuário: number;
                Complementa: string | null;
            };
        }
        
        export default  function Resumo(){
            const navigation = useNavigate()
    const dados = {
        Leito: 3,
        Paciente: "José Augusto Torres",
        Idade: 29,
        Admissão: "15/02/2024 08:02:29",
        DiasInternação: 187,
        QueixaPrincipal: "dor torácica", 
        SinaisVitais: "170X90", 
        EscalaDor: 6, 
        Precauções: 1,
        RiscoQueda: "Não",
        Consciência: null,
        Consciente: "Sim",
        Inconsciente: "Não",
        Orientado: "Não",
        GLASGOWRAMSAYRASS: null, 
        Glasgow: 14, 
        RAMSAY: "Não informado", 
        RASS: "Não informado",
        EscalaBraden: "RISCO_MODERADO_12_14",
        Edema: null, 
        ANASASRCANutrição: null, 
        Oral: "Não", 
        Aceitação: "RUIM", 
        Tipo: "Não informado",
        Lesão: null, Eliminações: "SVD: Sim. SVD Local: uretral Constipação: Não.", ConstipaçãoDias: 0,
        Antibióticos: null,
        ControleInfusões: "teste Dreno e" ,local: "Não",
        Riscos: null,
        Prontuário: 6,
        Complementa: null 
    } 
            return (
                <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f9', height: "95%" }}>
                   <div style={{display: 'flex', marginBottom: 30}}> 
                     <button
                onClick={()=>{navigation("/pacientes") }} 
                style={{
                    padding: '10px 15px',
                    fontSize: '16px',
                    backgroundColor: '#13C9AB',
                    color: 'black',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer', 
                }}
                >
                <FaArrowLeft size={24} color={"white"} />
            </button>
            <h2 style={{ fontSize: '22px', margin: '10px', fontWeight: 700 }}>
             Resumo do Prontuário Médico
            </h2>
                </div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '15px', paddingLeft:10, paddingRight: 10 }}>
                <div style={{ fontSize: '19px' }}>
                    <strong>Leito:</strong> {dados.Leito}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Paciente:</strong> {dados.Paciente}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Idade:</strong> {dados.Idade}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Admissão:</strong> {dados.Admissão}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Dias de Internação:</strong> {dados.DiasInternação}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Queixa Principal:</strong> {dados.QueixaPrincipal}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Sinais Vitais:</strong> {dados.SinaisVitais}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Escala de Dor:</strong> {dados.EscalaDor}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Precauções:</strong> {dados.Precauções}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Risco de Queda:</strong> {dados.RiscoQueda}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Consciência:</strong> {dados.Consciência ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Consciente:</strong> {dados.Consciente}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Inconsciente:</strong> {dados.Inconsciente}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Orientado:</strong> {dados.Orientado}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Glasgow:</strong> {dados.Glasgow}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>RAMSAY:</strong> {dados.RAMSAY}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>RASS:</strong> {dados.RASS}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Escala Braden:</strong> {dados.EscalaBraden}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Edema:</strong> {dados.Edema ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Nutrição:</strong> {dados.ANASASRCANutrição ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Oral:</strong> {dados.Oral}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Aceitação:</strong> {dados.Aceitação}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Lesão:</strong> {dados.Lesão ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Eliminações:</strong> {dados.Eliminações}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Constipação (Dias):</strong> {dados.ConstipaçãoDias}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Antibióticos:</strong> {dados.Antibióticos ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Controle de Infusões:</strong> {dados.ControleInfusões}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Local:</strong> {dados.local}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Riscos:</strong> {dados.Riscos ?? 'Não informado'}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Prontuário:</strong> {dados.Prontuário}</div>
                <div style={{ fontSize: '19px' }}>
                    <strong>Complementa:</strong> {dados.Complementa ?? 'Não informado'}</div>
            </div>
        </div>
            );
        }
         
        
/* 
import { useEffect, useState,   } from "react";
import { IBaterPontoForm } from '../../api/profissionais/pontoProfissionalAPI'
import { IRelatorioPassagemPlantaoBackend } from '../../api/relatorios/types'
import { useAuth } from "../../context/auth_provider/useAuth";
import { relatorioPassagemPlantao } from "../../api/relatorios/relatoriosAPI";
import { IUser } from "../../context/auth_provider/types";

interface IPassagemPlantaoRelatorioCamposProps {
    idsPacientes: number[],
    adicionarTodos: boolean,
    dadosPassagemPlantao?: IBaterPontoForm,
  }
  
interface IDocumentProps {
    dadosRelatorio: IRelatorioPassagemPlantaoBackend[],
    user: IUser,
    dadosPassagemPlantao?: IBaterPontoForm,
  }
export default  function Resumo(){   
    // const [dados, setDados  ] =  useState(null)
    //     const getDadosRelatorio = () => {
    //       relatorioPassagemPlantao([1], false)
    //         .then((resultado) => console.log(resultado))
    //         .catch(console.error)
    //     } 
    //     useEffect(() => {
    //       getDadosRelatorio() 
    //     }, []) 
        return  <>
        <div> 
            SALVEEE 
        </div>
        </> 
    // PassagemPlantaoRelatorioCampos(idsPacientes: [1],
    //     adicionarTodos: false,
    //     dadosPassagemPlantao?: {
    //         inicio: Date,
    //         fim: Date | null,
    //         usuarioPontoProntuario:[ 
    //             prontuarioId: number,
    //             observacoes: "string" | null,
    //             pacienteNome: "string",
    //             pacienteId: number,],
    //             adicionarTodos: false;
    //     },)
    return(<>
         
    </>)
}*/