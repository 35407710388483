import React from 'react';
import {Navigate} from 'react-router-dom';
import {isExpired} from 'react-jwt';
import {useAuth} from '../../context/auth_provider/useAuth';

export const ProtectedLayout = ({children}: { children: JSX.Element }) => {
  const { user } = useAuth();
  let token = '';

  if (!user?.token && !token && isExpired(token)) {
    return (
      <>
        <Navigate to='/login' />
      </>
    );
  }

  return children;
};
