import React, { useContext, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui'
import Swal from 'sweetalert2'
import { FaPlus } from 'react-icons/fa'
import { GoPencil } from 'react-icons/go'
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { IProfissionalListagem, ProfissionalEnum, profissionalCreateAPI, profissionalUpdateAPI } from '../../../api/profissionais'
import Button from '../../../components/Button'
import { IProfissionaisForm } from '../Profissionais'
import { ProfissionaisFormBotoes, ProfissionaisFormCampos } from '.'
import { ProfissionaisContext } from '../context'

interface IProfissionaisModalFormProps {
  profissional?: IProfissionalListagem,
  modoEdicao?: boolean,
}

const ModalBodyStyled = styled(Modal.Body)`
  padding: 12px 26px;
  max-height: 400px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #bebcbc;
    border-radius: 4px;
  }
`

const ProfissionaisModalForm = (props: IProfissionaisModalFormProps) => {
  const { carregando, loadProfissionais, setCarregando, filtros } = useContext(ProfissionaisContext)

  const [modalAberto, setModalAberto] = useState<boolean>(false)

  const initialValues: IProfissionaisForm = {
    nome: props.profissional?.nome || '',
    ativo: props.profissional?.ativo === false ? false : true,
    senha: props.profissional?.senha || '',
    tipo: props.profissional?.tipo || ProfissionalEnum.ENFERMEIRO,
    pontos: props.profissional?.pontos || 0,
    acessoUti: props.profissional?.acessoUti === false ? false : true,
    contato: props.profissional?.contato,
    email: props.profissional?.email,
    urlAvatar: props.profissional?.urlAvatar,
    cpfCnpj: props.profissional?.cpfCnpj || '',
    aptidoes: props.profissional?.usuarioAptidao?.map(item => item.descricao) || [],
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').required('Campo obrigatório!'),
    email: Yup.string().email('Email inválido').required('Campo obrigatório!'),
    senha: Yup.string().min(6, 'Muito curto!')
      .when(
        [],
        { 
          is: () => !props.modoEdicao,
          then: Yup.string().min(6, 'Muito curto!').nullable().required('Campo obrigatório!')
        }
      ),
    cpfCnpj: Yup.string()
      .uppercase()
      .nullable()
      .min(11, 'Muito curto!')
      .max(14, 'Muito longo!')
      .test(
          'cpf_validation',
          'CPF/CNPJ inválido!',
          (value) => value ? cpf.isValid(value) || cnpj.isValid(value) : false,
      )
      .required('Campo obrigatório!'),
    acessoUti: Yup.bool(),
    ativo: Yup.bool(),
  })

  const handleSubmit = (
    values: IProfissionaisForm,
    actions: FormikHelpers<IProfissionaisForm>
  ) => {
    setCarregando(true)
    if (!props.profissional) {
      profissionalCreateAPI(
        values.nome,
        values.ativo,
        values.senha,
        values.tipo,
        values.pontos,
        values.acessoUti,
        values.aptidoes,
        values.contato,
        values.email,
        values.urlAvatar,
        values.cpfCnpj,
      )
        .then(() => {
          loadProfissionais(filtros)
          actions.resetForm()
          setModalAberto(false)
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Profissional criado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          })
        })
        .finally(() => setCarregando(false))
    } else {
      profissionalUpdateAPI(
        props.profissional.id,
        (values.nome? values.nome : "" ),
        values.ativo,
        values.senha,
        values.tipo,
        values.pontos,
        values.acessoUti,
        values.aptidoes,
        values.contato,
        values.email,
        values.urlAvatar,
        values.cpfCnpj,
      )
        .then(() => {
          loadProfissionais(filtros)
          actions.resetForm()
          setModalAberto(false)
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Profissional editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          })
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      {props.modoEdicao ? (
        <GoPencil onClick={() => setModalAberto(true)} color='#304439' size={22} style={{ cursor: 'pointer' }} />
      ) : (
        <Button
          variant='new'
          onClick={() => setModalAberto(true)}
        >
          <FaPlus color='white' size={12} style={{ marginRight: '8px' }} />
          Novo
        </Button>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Modal
          show={modalAberto}
          size='lg'
          onHide={() => setModalAberto(false)}
        >
          <Modal.Header>
            <Modal.Title style={{ color: '#304439' }}>
            {!props.modoEdicao ? (
              <>
                Novo profissional
              </>
              ) : (
                <>
                  Editar profissional - {props.profissional?.nome}
                </>
              )}
            </Modal.Title>
          </Modal.Header>

          <ModalBodyStyled>
            <BlockUi tag="div" blocking={carregando}>
              <ProfissionaisFormCampos />
            </BlockUi>
          </ModalBodyStyled>

          <Modal.Footer>
            <ProfissionaisFormBotoes
              modoEdicao={props.modoEdicao}
              setModalAberto={(novoValor) => setModalAberto(novoValor)}
            />
          </Modal.Footer>
        </Modal>
      </Formik>
    </>
  )
}

export { ProfissionaisModalForm }
export default ProfissionaisModalForm
