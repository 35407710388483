import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { YouTubePlaylist } from "@codesweetly/react-youtube-playlist";
import { Page } from '../../components/page'

const AjudaPlaylistCanal = () => {
  const { state } = useLocation()
  const [playlist, setPlaylist] = useState<string>('')

  useEffect(() => {
    setPlaylist(state.playlistId)
  }, [state.playlistId])

  return (
    <Page>
      <Page.Header
        icon={FaRegQuestionCircle}
        title={`Ajuda - Playlist do canal - ${state.tituloTela}`}
        buttons={<></>}
      />

      <Page.Body>
        {state.playlistId && (
          <YouTubePlaylist
            apiKey="AIzaSyDcGBwBDTymwe2vwSGSh8SWQcmKg16NDgE"
            playlistId={playlist}
            uniqueName={state.tituloTela}
          />
        )}
      </Page.Body>
    </Page>
  )
}

export { AjudaPlaylistCanal }
export default AjudaPlaylistCanal
