import { AxiosResponse } from 'axios'
import { axiosAPI } from '../axiosAPI'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { format } from 'date-fns'

export enum TipoFiltroPeriodoDashboardEnum {
  PERIODO_DIAS = 'periodo_dias',
  PERIODO_MESES = 'periodo_meses'
}

export interface ITabelaDashbard {
  periodo: string | null,
  descricao: string | null,
  valor: string | null,
  ano: string,
  ABR?: string | null,
  AGO?: string | null,
  DEZ?: string | null,
  FEV?: string | null,
  JAN?: string | null,
  JUL?: string | null,
  JUN?: string | null,
  MAI?: string | null,
  MAR?: string | null,
  NOV?: string | null,
  OUT?: string | null,
  SET?: string | null,
  pacientes?: string | null,
}

export interface IDashboard {
  grafico: (number | string[])[],
  tabela?: ITabelaDashbard[],
}

export interface IDashboardFiltros {
  tipoFiltro: TipoFiltroPeriodoDashboardEnum,
  periodoDe: Date,
  periodoAte: Date,
  enfermeiro: number,
  paciente: number
}

export const getDashboard = async (
  rota: string,
  filtros: IDashboardFiltros
): Promise<IDashboard> => {
  const user = getUserLocalStorage()
  const request: AxiosResponse<IDashboard> = await axiosAPI.get(
    `dashboard/${rota}`,
    {
      params: {
        de: format(filtros.periodoDe, 'yyyy-MM-dd'),
        ate: format(filtros.periodoAte, 'yyyy-MM-dd'),
        idEmpresa: user.idEmpresa,
        tipoFiltro: filtros.tipoFiltro,
        pacienteId: filtros.paciente <= 0 ? undefined : filtros.paciente,
        enfermeiroId: filtros.enfermeiro <= 0 ? undefined : filtros.enfermeiro,
      }
    }
  )

  return request.data
}
