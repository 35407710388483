import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IProfissionaisFiltros, IProfissionalListagem, profissionalListarAPI } from '../../../api/profissionais';

interface TProfissionaisContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  profissionais: IProfissionalListagem[],
  setProfissionais: (newProfissionais: IProfissionalListagem[]) => void,
  loadProfissionais: (filtros: IProfissionaisFiltros) => void,
  filtros: { nome: string },
  setFiltros: (novosFiltros: IProfissionaisFiltros) => void
}

interface TProfissionaisProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  profissionais: [],
  setProfissionais: () => {
    throw new Error('setProfissionais não implementada');
  },
  loadProfissionais: () => {
    throw new Error('loadProfissionais não implementada');
  },
  filtros: { nome: '' },
  setFiltros: () => {
    throw new Error('setFiltros não implementada');
  },
};

export const ProfissionaisContext = createContext<TProfissionaisContext>(defaultState);

export const ProfissionaisProvider = ({children}: TProfissionaisProviderProps) => {
  const [profissionais, setProfissionais] = useState<IProfissionalListagem[]>(defaultState.profissionais);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [filtros, setFiltros] = useState<IProfissionaisFiltros>(defaultState.filtros)

  const loadProfissionais = (filtros: IProfissionaisFiltros) => {
    setCarregando(true)
    profissionalListarAPI(filtros)
        .then((data) => setProfissionais(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadProfissionais(filtros);
  }, [filtros]);

  const providerValues: TProfissionaisContext = {
    carregando,
    setCarregando,
    profissionais,
    setProfissionais,
    loadProfissionais,
    filtros,
    setFiltros,
  };

  return (
    <ProfissionaisContext.Provider value={providerValues}>
      {children}
    </ProfissionaisContext.Provider>
  );
};
