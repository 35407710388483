import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import { Alert, Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import styled from 'styled-components'
import Swal from 'sweetalert2'
import { HiOutlineTrash } from 'react-icons/hi'
import { SelectSearchAsync } from '../../../../../../../components/select-search-async'
import { getAllToSelectPacienteEmAntendimentoAPI } from '../../../../../../../api/pacientes'
import { IBaterPontoForm } from '../../../../../../../api/profissionais/pontoProfissionalAPI'
import { TableStyled } from '../../../../../../../components/styles'
import UsuarioPontoProntuarioObservacao from './UsuarioPontoProntuarioObservacao'
import Checkbox from '../../../../../../../components/checkbox/Checkbox'
import { ISelectChoice } from '../../../../../../../components/select-async'


const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`
interface IUsuarioPontoProntuarioFormProps {
  handleFecharModal: () => void,
  setPacientes: (ids: number) => void,
  removerPacientes: () => void
}

interface IPacienteForm {
  prontuarioId: number,
  pacienteNome: string,
  pacienteId: number,
}

const UsuarioPontoProntuarioForm = (props: IUsuarioPontoProntuarioFormProps) => {
  const [pacienteSelected, setPacienteSelected] = useState<IPacienteForm | null>(null)
  const [todosPacientes, setTodosPacientes] = useState<ISelectChoice[]>([])
  
  const { values, handleChange, setFieldValue } = useFormikContext<IBaterPontoForm>()

  const getPacientes = () => {
    getAllToSelectPacienteEmAntendimentoAPI()
      .then(dadosTodosClientes => setTodosPacientes(dadosTodosClientes))
  }

  useEffect(() => {
    getPacientes()
  }, [])
  
  useEffect(() => {
    props.removerPacientes()
    if (values.adicionarTodos) {
      setFieldValue(
        'usuarioPontoProntuario',
        todosPacientes.map(paciente => ({
          prontuarioId: paciente.prontuarioId,
          pacienteNome: paciente.pacienteNome,
          pacienteId: paciente.value,
          observacoes: ''
        })),
        false
      )
      todosPacientes.map(paciente => {
        if (paciente.value) {
          props.setPacientes(Number(paciente.value))
        }
      })
    } else {
      setFieldValue(
        'usuarioPontoProntuario',
        [],
        false
      )
    }
  }, [values.adicionarTodos])

  const handleInserirNovoPaciente = () => {
    const prontuariosIncluidos = values.usuarioPontoProntuario.map(i => i.prontuarioId)
    if (pacienteSelected?.prontuarioId && prontuariosIncluidos?.includes(pacienteSelected.prontuarioId)) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops',
        text: 'Paciente já vinculado',
        confirmButtonColor: '#304439',
      })
      return
    }

    if (pacienteSelected?.prontuarioId && pacienteSelected.prontuarioId > 0) {
      setFieldValue(
        'usuarioPontoProntuario',
        [
          ...values.usuarioPontoProntuario,
          {
            prontuarioId: pacienteSelected.prontuarioId,
            pacienteNome: pacienteSelected.pacienteNome,
            pacienteId: pacienteSelected.pacienteId,
            observacoes: ''
          }
      ],
        false,
      )

      props.setPacientes(pacienteSelected.pacienteId)
    }
  }

  const handleDeleteUsuarioPontoProntuario = (prontuarioId: number, pacienteId: number) => {
    const novosValores = values.usuarioPontoProntuario.filter(i => i.prontuarioId !== prontuarioId)
    setFieldValue(
      'usuarioPontoProntuario',
      novosValores,
      false
    )

    props.setPacientes(pacienteId)
  }

  return (
    <>      
      <Row>
        <Col md={12}>
          <Checkbox
            id='adicionarTodos'
            label='Adicionar todos pacientes na passagem de plantão'
            checked={values.adicionarTodos}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <RowStyled>
        <Col md={11}>
          <SelectSearchAsync
            label='Selecione um paciente'
            colorLabel='#fff'
            colorOption='#304439'
            color
            api={getAllToSelectPacienteEmAntendimentoAPI}
            name='paciente'
            placeholder='Pesquise pelo nome do paciente...'
            noOptionsMessage='Não foi encontrado nenhum paciente'
            strong
            desabilitado={values.adicionarTodos}
            onChange={(selected) => {
              if (selected && selected.prontuarioId) {
                setPacienteSelected({
                  prontuarioId: selected?.prontuarioId,
                  pacienteNome: selected?.pacienteNome,
                  pacienteId: selected?.value
                })
              }
            }}
          />
        </Col>

        {values.adicionarTodos ? (
          <Col md={1}>
          <MdFormatListBulletedAdd
            color='#6b6b6b'
            size={44}
            style={{ marginTop: '32px', cursor: 'pointer' }}
          />
        </Col>
        ) : (
          <Col md={1}>
            <MdFormatListBulletedAdd
              color='#fff'
              size={44}
              style={{ marginTop: '32px', cursor: 'pointer' }}
              onClick={() => handleInserirNovoPaciente()}
            />
          </Col>
        )}
      </RowStyled>

      {values.usuarioPontoProntuario && values.usuarioPontoProntuario.length > 0 ? (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.usuarioPontoProntuario.map((usuarioPontoProntuario, index) => (
              <tr key={index}>
                <td>{usuarioPontoProntuario.pacienteNome} - Prontuário: {usuarioPontoProntuario.prontuarioId}</td>
                <td style={{ width: '1px', padding: '10px' }}>
                  <UsuarioPontoProntuarioObservacao prontuarioId={usuarioPontoProntuario.prontuarioId}/>
                </td>
                <td style={{ width: '1px', padding: '10px 20px 10px 5px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={26}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDeleteUsuarioPontoProntuario(usuarioPontoProntuario.prontuarioId, usuarioPontoProntuario.pacienteId)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      ) : (
        <Alert variant='light' style={{ marginTop: '12px' }}>
          Ainda não foram incluídos pacientes. Inclua o primeiro preenchendo os dados acima.
        </Alert>
      )}
    </>
  )
}

export { UsuarioPontoProntuarioForm }
export default UsuarioPontoProntuarioForm
