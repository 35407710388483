import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk } from 'react-icons/fa'

import { CheckboxStyled, InputStyled } from '../../../../components/styles'
import { ILesoesForm } from '../Lesoes'

type Props = {}

const LesoesFormCampos = (props: Props) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext<ILesoesForm>()

  return (
    <>
      <Row style={{ marginTop: '22px' }}>
        <Col md={10}>
          <div>
            <label className="form-check-label form-label" htmlFor='nome'>
              Nome <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='nome'
              name='nome'
              value={values.nome}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={2}>
          <div style={{display: 'flex', marginTop: '28px'}}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="ativoCheckbox"
              name='ativo'
              checked={values.ativo}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="ativoCheckbox">
              Ativo
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{marginBottom: '16px', display: 'flex', marginTop: '18px'}}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="lesaoPressaoCheckbox"
              name='lesaoPressao'
              checked={values.lesaoPressao}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="lesaoPressaoCheckbox">
              Lesão por pressão
            </label>
          </div>
        </Col>
      </Row>
    </>
  )
}

export { LesoesFormCampos }
export default LesoesFormCampos
