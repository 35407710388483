import { Table } from 'react-bootstrap';
import styled from 'styled-components';

interface ITableStyled {
  semMargemVertical?: boolean,
  menor?: boolean,
}

export const TableStyled = styled(Table)<ITableStyled>`
  th, td {
    border-bottom: 1px solid #c9b18c;
    padding: ${(props) => props.menor ? '10px 5px': '10px 20px'};
  }

  background: rgba(255, 255, 255, 0.5);
  margin-top: ${(props) => props.semMargemVertical ? '0px' : '32px'};
  margin-bottom: ${(props) => props.semMargemVertical ? '0px' : ''};
  padding: ${(props) => props.semMargemVertical ? '' : '20px'};
  border-radius: 10px;
  width: 100%;
`

export const TableContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  margin-top: 32px;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #bebcbc;
    border-radius: 4px;
  }
`
