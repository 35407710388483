import React, { useContext, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import { IPacienteListagem, pacienteUpdateAPI } from '../../../api/pacientes'

import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { DropdownItemStyled } from './PacienteAcoes'
import { PacientesFormCamposInfo } from './PacientesFormCamposInfo'
import Button from '../../../components/Button'
import { getTipoProcedencia, getTipoSituacao } from '../prontuario/components'
import { cpf } from 'cpf-cnpj-validator'
import BlockUi from 'react-block-ui'
import { PacientesContext } from '../context'
import Swal from 'sweetalert2'

export interface IPacienteInfoForm {
  nome: string | null,
  numeroSUS: string | null,
  sexo?: string | null,
  dataNascimento?: Date | null,
  email?: string | null,
  telefone?: string | null,
  contato?: string | null,
  estadoCivil?: string | null,
  idProntuario?: number | null,
  idLeito?: number | null,
  dhAdmissao?: Date | null,
  tipoSituacao?: string | null,
  tipoProcedencia?: string | null,
  procResidencia: boolean,
  procRua: boolean,
  procServSaude: boolean,
  procSetor: boolean,
  procSetorNome: string | null,
  isolProtetor?: boolean,
  isolRespiratorio?: boolean,
  isolContato?: boolean,
  isolVigilancia?: boolean,
  riscoQueda?: boolean,
  riscoLesaoPressao?: boolean,
  riscoAlergia?: boolean,
  riscoRessecamentoOcular?: boolean,
  queixaPrincipal: string | null,
  comorbidades: string | null,
  medicacaoUso: string | null,
  ocorrencias: string | null,
  eventosAdversos: string | null,
}

interface IPacientesModalInfoProps {
  paciente: IPacienteListagem,
}

const ModalBodyStyled = styled(Modal.Body)`
  padding: 12px 26px;
  max-height: 420px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #bebcbc;
    border-radius: 4px;
  }
`

const PacientesModalInfo   = (props: IPacientesModalInfoProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false)
  const [modoEdicao, setModoEdicao] = useState<boolean>(false)
  const [mensagem, setMensagem] = useState("")

  const { carregando, loadPacientes, setCarregando, filtros } = useContext(PacientesContext)

  const initialValues: IPacienteInfoForm = {
    nome: props.paciente?.nome || null,
    sexo: props.paciente?.sexo || null,
    dataNascimento: props.paciente?.dataNascimento ? new Date(props.paciente?.dataNascimento) : null,
    email: props.paciente?.email || null,
    telefone: props.paciente?.telefone || null,
    contato: props.paciente?.contato || null,
    estadoCivil: props.paciente?.estadoCivil || null,
    numeroSUS: props.paciente?.numeroSUS || null,
    idProntuario: props.paciente.prontuario[0].id,
    idLeito: props.paciente.prontuario[0].idLeito,
    dhAdmissao: props.paciente.prontuario[0].dhAdmissao ? new Date(props.paciente.prontuario[0].dhAdmissao) : null,
    tipoSituacao: getTipoSituacao(props.paciente.prontuario[0]),
    tipoProcedencia: getTipoProcedencia(props.paciente.prontuario[0]),
    procResidencia: props.paciente.prontuario[0]?.procResidencia || false,
    procRua: props.paciente.prontuario[0]?.procRua || false,
    procServSaude: props.paciente.prontuario[0]?.procServSaude || false,
    procSetor: props.paciente.prontuario[0]?.procSetor || false,
    procSetorNome: props.paciente.prontuario[0]?.procSetorNome || null,
    isolProtetor: props.paciente.prontuario[0]?.isolProtetor || false,
    isolRespiratorio: props.paciente.prontuario[0]?.isolRespiratorio || false,
    isolContato: props.paciente.prontuario[0]?.isolContato || false,
    isolVigilancia: props.paciente.prontuario[0]?.isolVigilancia || false,
    riscoQueda: props.paciente.prontuario[0]?.riscoQueda || false,
    riscoLesaoPressao: props.paciente.prontuario[0]?.riscoLesaoPressao || false,
    riscoAlergia: props.paciente.prontuario[0]?.riscoAlergia || false,
    riscoRessecamentoOcular: props.paciente.prontuario[0]?.riscoRessecamentoOcular || false,
    queixaPrincipal: props.paciente.prontuario[0]?.queixaPrincipal || null,
    comorbidades: props.paciente.prontuario[0]?.comorbidades || null,
    medicacaoUso: props.paciente.prontuario[0]?.medicacaoUso || null,
    ocorrencias: props.paciente.prontuario[0]?.prontuarioRegistroComplementar[0]?.ocorrencias || null,
    eventosAdversos: props.paciente.prontuario[0]?.prontuarioRegistroComplementar[0]?.eventosAdversos || null,
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').nullable().required('Campo obrigatório!'),
    numeroSUS: Yup.string()
      .uppercase()
      .nullable()
      .min(11, 'Muito curto!')
      .max(14, 'Muito longo!')
      .test(
          'cpf_validation',
          'CPF inválido!',
          (value) => {
            return value ? cpf.isValid(value) : false
          },
      )
      .required('Campo obrigatório!'),
    email: Yup.string().email('Email inválido').nullable(),
  })

  const handleSubmit = (
    values: IPacienteInfoForm,
    actions: FormikHelpers<IPacienteInfoForm>
  ) => {
    setMensagem('')
    if(values.idLeito == null ){
        setMensagem("Insira o numero do leito do paciente");

    }

    else if (values.nome && values.numeroSUS) {
      setCarregando(true)
      pacienteUpdateAPI(
        props.paciente.id,
        props.paciente.prontuario[0].id,
        values
      )
        .then(() => {
          loadPacientes(filtros)
          actions.resetForm()
          setModalAberto(false)
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Paciente editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          })
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      <DropdownItemStyled
        style={{ marginTop: '8px' }}
        disabled={!props.paciente?.prontuario[0].dhAdmissao}
        onClick={() => setModalAberto(true)}
      >
        Informação
      </DropdownItemStyled>

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, resetForm }) => (
          <Modal
            show={modalAberto}
            size='lg'
            onHide={() => setModalAberto(false)}
          >
            <Modal.Header>
              <Modal.Title style={{ color: '#304439' }}>
                Informações do paciente
              </Modal.Title>
            </Modal.Header>

            <ModalBodyStyled>
              <div style={{ color: '#304439', backgroundColor: '#EDE38E', padding: '12px 18px' }}>
                <BlockUi tag="div" blocking={carregando}>
                  <PacientesFormCamposInfo modoEdicao={modoEdicao} />
                </BlockUi>
              </div>
            </ModalBodyStyled>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
            <Modal.Footer>
              <Button
                variant='secondary'
                style={{marginRight: '6px'}}
                onClick={() => {
                  resetForm()
                  setModoEdicao(false)
                  setModalAberto(false)
                }}
              >
                {modoEdicao ? ('Cancelar') : ('Fechar')}
              </Button>

              {!modoEdicao ? (
                <Button variant='primary' onClick={() => setModoEdicao(true)}>Editar</Button>
                ) : (
                <Button variant='primary' onClick={handleSubmit}>Salvar</Button>
              )}
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export { PacientesModalInfo   }
export default PacientesModalInfo  
