import styled from 'styled-components'

export const DivMainStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`

export const DivCardStyled = styled.div`
  width: 588px;
  min-height: 394px;
  background-color: #EDE38E;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 40px;
`

export const DivCardItemStyled = styled.div`
  width: 213px;
  padding: 6px;
  background-color: #222222;
  border-radius: 20px;
  text-align: center;
`

export const DivTitulo = styled.div`
  color: #222222;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
`