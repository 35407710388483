import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface ILeitoListagem {
  id: number,
  nome: string,
  ativo: boolean,
}

export const leitoListarAPI = async (): Promise<ILeitoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<ILeitoListagem[]> = await axiosAPI.get(
      'leito',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectLeitoAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<ILeitoListagem[]> = await axiosAPI.get(
      'leito',
      { params: { id_empresa: user.idEmpresa } }
  )
  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return []
}


export const leitoCreateAPI = async (nome: string, ativo: boolean): Promise<ILeitoListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'leito',
      { nome, ativo, id_empresa: user.idEmpresa },
  )

  return response.data
}

export const leitoUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
): Promise<ILeitoListagem[]> => {
  const response = await axiosAPI.put(
      'leito/edit',
      {id, nome, ativo},
  )

  return response.data
}

export const leitoDeleteAPI = async (id: number): Promise<ILeitoListagem[]> => {
  const response = await axiosAPI.delete('leito/delete', { params: { id } })

  return response.data
}
