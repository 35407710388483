import React from 'react'

import PlanoCuidadoForm from './PlanoCuidadoForm'
import { Page } from '../../../../../components/page'
import PlanoCuidadoDadosPaciente from './PlanoCuidadoDadosPaciente'
import { ProntuarioProvider } from '../../context'
import PlanoCuidadoTabela from './PlanoCuidadoTabela'
import { PlanoCuidadoBotoesHeader } from './components'

const PlanoCuidados = () => {
  return (
    <ProntuarioProvider>
      <Page>
        <Page.Header
          title='Plano de cuidados'
          buttons={<PlanoCuidadoBotoesHeader />}
        />

        <Page.Body>
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <PlanoCuidadoDadosPaciente />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '80%', marginTop: '26px' }}>
                <PlanoCuidadoForm />
                
                <PlanoCuidadoTabela />
              </div>
            </div>
          </>
        </Page.Body>
      </Page>
    </ProntuarioProvider>
  )
}

export { PlanoCuidados }
export default PlanoCuidados
