import { IProntuarioExameFisicoListagem, IProntuarioExameFisicoListagemBackend } from "../types";

export const parseProntuarioExameFisicoBackToFront = (dadosBackend: IProntuarioExameFisicoListagemBackend): IProntuarioExameFisicoListagem => ({
  id: dadosBackend.id,
  estadoGeral: dadosBackend.estado_geral,
  ssvvPa: dadosBackend.ssvv_pa,
  temperatura: dadosBackend.temperatura,
  fr: dadosBackend.fr,
  fc: dadosBackend.fc,
  satO2: dadosBackend.sat_o2,
  hgt: dadosBackend.hgt,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
