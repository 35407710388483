import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { EmpresasProvider } from './context';
import { EmpresasContainerForm } from './EmpresasContainerForm';

export interface IEmpresasForm {
  nome: string,
  ativo: boolean,
  cnpj: string,
  ie?: string | null,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  urlCanalSuporte?: string | null
}

const Empresas = () => {
  return (
    <EmpresasProvider>
      <DivMainStyled>
        <DivCardStyled style={{ width: '700px' }}>
          <EmpresasContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </EmpresasProvider>
  )
}

export { Empresas }
export default Empresas
