import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'

import { IAvaliacaoLesaoFotoForm, IProntuarioIntegridadeFisicaForm } from '../../../../../../api/prontuario/types'
import { InputStyled, TableStyled } from '../../../../../../components/styles'
import { useFormikContext } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import VerFoto from './VerFoto'


interface IFotosAvaliacaoProps {
  indiceLesao: number
}

const FotosAvaliacao = (props: IFotosAvaliacaoProps) => {
  const [foto, setFoto] = useState<IAvaliacaoLesaoFotoForm>({
    id: Math.random() * -1,
    foto: null,
    fotoEndereco: '',
  })
  const [keyFoto, setKeyFoto] = useState<number>(0)
  const { values, setFieldValue } = useFormikContext<IProntuarioIntegridadeFisicaForm>()

  const handleInserirFoto = () => {
    if (foto.foto) {
      setKeyFoto(prev => prev + 1)
      const novoValor: IAvaliacaoLesaoFotoForm[] = values.lesoes[props.indiceLesao].avaliacao.fotos
      novoValor.push(foto)

      setFieldValue(`lesoes[${props.indiceLesao}].avaliacao.fotos`, novoValor, false)
      setFoto({
        id: (Math.random() * (-1)),
        foto: null,
        fotoEndereco: '',
      })
    }
  }

  const removerAptidao = (id: number) => {
    const novoValor: IAvaliacaoLesaoFotoForm[] = values.lesoes[props.indiceLesao].avaliacao.fotos.filter(i => i.id !== id)
    setFieldValue(`lesoes[${props.indiceLesao}].avaliacao.fotos`, novoValor, false)
  }

  return (
    <>
      <Row>
        <Col md={11}>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
            <label className="form-check-label form-label" htmlFor='foto'>
              Foto
            </label>
            <InputStyled
              key={keyFoto}
              type='file'
              accept="application/pdf|image/*"
              className='form-input form-control'
              id='foto'
              name='foto'
              onChange={(e: any) => {
                setFoto(prev => ({
                  ...prev,
                  foto: e.target.files[0],
                }));
              }}
            />
          </div>
        </Col>

        <Col md={1}>
          <MdFormatListBulletedAdd
            size={35}
            style={{ marginTop: '46px', cursor: 'pointer' }}
            onClick={() => handleInserirFoto()}
          />
        </Col>
      </Row>

      {values.lesoes[props.indiceLesao].avaliacao.fotos && values.lesoes[props.indiceLesao].avaliacao.fotos.length > 0 && (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.lesoes[props.indiceLesao].avaliacao.fotos.map((foto, index) => (
              <tr key={index}>
                <td style={{ width: '1px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={22}
                    style={{ cursor: 'pointer' }}
                    onClick={() => removerAptidao(foto.id)}
                  />
                </td>
                <td>
                  {foto.fotoEndereco && (<>{foto.fotoEndereco}</>)}
                  {foto.foto && (<>{foto.foto.name}</>)}
                </td>

                {foto.fotoEndereco && (
                  <td>
                    <VerFoto enderecoFoto={foto.fotoEndereco} />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableStyled>
      )}
    </>
  )
}

export { FotosAvaliacao }
export default FotosAvaliacao
