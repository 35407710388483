import { IProntuarioFuncaoNeuroAvaOcularListagem, IProntuarioFuncaoNeuroAvaOcularListagemBackend } from "../types";

export const parseProntuarioFuncaoNeuroAvaOcularBackToFront = (
  dadosBackend: IProntuarioFuncaoNeuroAvaOcularListagemBackend
): IProntuarioFuncaoNeuroAvaOcularListagem => ({
  id: dadosBackend.id,
  consciente: dadosBackend.consciente,
  inconsciente: dadosBackend.inconsciente,
  orientado: dadosBackend.orientado,
  desorientado: dadosBackend.desorientado,
  torporoso: dadosBackend.torporoso,
  sedacao: dadosBackend.sedacao,
  comatoso: dadosBackend.comatoso,
  glasgowAo: dadosBackend.glasgow_ao,
  glasgowRv: dadosBackend.glasgow_rv,
  glasgowRm: dadosBackend.glasgow_rm,
  glasgowRp: dadosBackend.glasgow_rp,
  glasgowIgual: dadosBackend.glasgow_igual,
  ramsay: dadosBackend.ramsay,
  rass: dadosBackend.rass,
  convulsao: dadosBackend.convulsao,
  convulsaoEpisodio: dadosBackend.convulsao_episodio,
  pupilaIsocoricas: dadosBackend.pupila_isocoricas,
  pupilaMioticas: dadosBackend.pupila_mioticas,
  pupilaMidriaticas: dadosBackend.pupila_midriaticas,
  pupilaFotoreagente: dadosBackend.pupila_fotoreagente,
  pupilaNaoFotoreagente: dadosBackend.pupila_nao_fotoreagente,
  pupilaAncoricasDe: dadosBackend.pupila_ancoricas_de,
  pupilaAncoricasAte: dadosBackend.pupila_ancoricas_ate,
  lagoftalmiaOd: dadosBackend.lagoftalmia_od,
  lagoftalmiaOe: dadosBackend.lagoftalmia_oe,
  hiperamiaConjuntivalOd: dadosBackend.hiperamia_conjuntival_od,
  hiperamiaConjuntivalOe: dadosBackend.hiperamia_conjuntival_oe,
  secrecaoOd: dadosBackend.secrecao_od,
  secrecaoOe: dadosBackend.secrecao_oe,
  edemaPalpebralOd: dadosBackend.edema_palpebral_od,
  edemaPalpebralOe: dadosBackend.edema_palpebral_oe,
  edemaConjuntivalOd: dadosBackend.edema_conjuntival_od,
  edemaConjuntivalOe: dadosBackend.edema_conjuntival_oe,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
