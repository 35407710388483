import React, { useContext, useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { IProntuarioPlanoCuidadoAtividadeForm,} from '../../../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import { SelectAsync } from '../../../../../../../components/select-async'
import { getAllToSelectDiagnosticoAPI } from '../../../../../../../api/cadastro/diagnosticosAPI'
import { getAllToSelectAtividadeAPI } from '../../../../../../../api/cadastro/atividadesAPI'
import { getAllToSelectIntervencaoAPI } from '../../../../../../../api/cadastro/intervencoesAPI'
import { getAllToSelectAprazamentoAPI } from '../../../../../../../api/cadastro/aprazamentoAPI'
import { SelectGrupo } from '../../../../../../../components'
import { getApenasAlterados } from '../../../helper/getGruposAlterados'
import { ProntuarioContext } from '../../../../context'
import Button from '../../../../../../../components/Button'

interface IPlanoCuidadoAtividadesFormCamposProps {
  editar?: boolean,
}

const PlanoCuidadoAtividadesFormCampos = (props: IPlanoCuidadoAtividadesFormCamposProps) => {
  const [keyAtividade, setKeyAtividade] = useState<number>(0)
  const [mensagem, setMensagem] = useState("")
  const [keyGrupoAlterou, setKeyGrupoAlterou] = useState<number>(0)
  const { values, handleChange, submitForm } = useFormikContext<IProntuarioPlanoCuidadoAtividadeForm>()
  const { prontuario } = useContext(ProntuarioContext) 
  useEffect(() => {
    setKeyAtividade(prev => prev + 1)
  }, [values.idIntervencao])

  useEffect(() => {
    setKeyGrupoAlterou(prev => prev + 1)
  }, [values.grupo])

  return (
    <>
      <Row>
        <Col md={12}>
          <SelectGrupo
            value={values.grupo}
            gruposAlterados={getApenasAlterados(prontuario)}
            onChange={handleChange} />
        </Col>
      </Row> 
      <Row>
        <Col md={6}>
          <SelectAsync
            key={keyGrupoAlterou}
            id='idDiagnostico'
            label='Diagnóstico de enfermagem'
            emptyLabel='Selecione...'
            value={values.idDiagnostico || -1}
            onChange={handleChange}
            api={() => getAllToSelectDiagnosticoAPI(values.grupo)}
          />
        </Col> 
        <Col md={6}>
          <SelectAsync
            key={keyGrupoAlterou}
            id='idIntervencao'
            label='Intervenção'
            emptyLabel='Selecione...'
            value={values.idIntervencao || -1}
            onChange={handleChange}
            api={() => getAllToSelectIntervencaoAPI(values.grupo)}
          />
        </Col>
      </Row> 
      <Row style={{ marginTop: '16px', marginBottom: '16px' }}>
        <Col md={6}>
          <SelectAsync
            key={`${keyAtividade}-${keyGrupoAlterou}`}
            id='idAtividade'
            label='Atividade'
            emptyLabel='Selecione...'
            value={values.idAtividade || -1}
            onChange={handleChange}
            api={() => getAllToSelectAtividadeAPI(values.grupo, values.idIntervencao)}
          />
        </Col> 
        <Col md={props.editar ? 6 : 5}>
          <SelectAsync
            id='idAprazamento'
            label='Aprazamento'
            emptyLabel='Selecione...'
            value={values.idAprazamento || -1}
            onChange={handleChange}
            api={getAllToSelectAprazamentoAPI} />
        </Col> 
        {!props.editar && (
          <Col md={1}>
            <MdFormatListBulletedAdd
              size={34}
              style={{ marginTop: '27px', cursor: 'pointer' }}
              color='#304439'
              onClick={() => {
                if (values.idDiagnostico != -1) {
                  if (values.idIntervencao != -1) {
                    if (values.idAtividade != -1) {
                      if (values.idAprazamento != -1) {
                        setMensagem("")
                        submitForm()
                      }
                      else {
                        setMensagem("Insira o Aprazamento");
                      }
                    }
                    else {
                      setMensagem("Insira o atividade");
                    } 
                  }
                  else {
                    setMensagem("Insira a Intervencao");
                  }
                }
                else {
                  setMensagem("Insira o diagnostico");
                } 
              }
              } 
            />
          </Col>
        )}
      </Row>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
      {props.editar && (
        <Row>
          <Col md={12} style={{ textAlign: 'right' }}>
            <Button onClick={() => submitForm()}>Gravar</Button>
          </Col>
        </Row>
      )}
    </>
  )
} 
export { PlanoCuidadoAtividadesFormCampos }
export default PlanoCuidadoAtividadesFormCampos
