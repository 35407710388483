import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IResultadoListagem } from '../../../../api/cadastro/resultadosAPI';
import { resultadoListarAPI } from '../../../../api/cadastro/resultadosAPI';

interface TResultadosContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  resultados: IResultadoListagem[],
  setResultados: (newResultados: IResultadoListagem[]) => void,
  selecionado: IResultadoListagem | null,
  setSelecionado: (novoSelecionado: IResultadoListagem | null) => void,
  loadResultados: () => void
}

interface TResultadosProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  resultados: [],
  setResultados: () => {
    throw new Error('setResultados não implementada');
  },
  loadResultados: () => {
    throw new Error('loadResultados não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const ResultadosContext = createContext<TResultadosContext>(defaultState);

export const ResultadosProvider = ({children}: TResultadosProviderProps) => {
  const [resultados, setResultados] = useState<IResultadoListagem[]>(defaultState.resultados);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IResultadoListagem | null>(defaultState.selecionado)

  const loadResultados = () => {
    setCarregando(true)
    resultadoListarAPI()
        .then((data) => setResultados(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadResultados();
  }, []);

  const providerValues: TResultadosContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    resultados,
    setResultados,
    loadResultados,
  };

  return (
    <ResultadosContext.Provider value={providerValues}>
      {children}
    </ResultadosContext.Provider>
  );
};
