import React from 'react'
import { useFormikContext } from 'formik'
import { Col, Row } from 'react-bootstrap'

import { IDashboardFiltros } from '../../../../api/dashboard/dashboardAPI'
import { getAllToSelectPacienteAPI } from '../../../../api/pacientes'
import { getAllToSelectProfissionalAPI } from '../../../../api/profissionais'
import { DateTime } from '../../../../components'
import { SelectAsync } from '../../../../components/select-async'
import Button from '../../../../components/Button'
import styled from 'styled-components';

const DashboardFiltrosCampos = () => {
  const { values, handleBlur, handleChange, handleSubmit } = useFormikContext<IDashboardFiltros>()

  return (
    <div >
      <Row>
        <Col md={3} style={{ fontSize: '18px', fontWeight: 600 }}>FILTROS</Col> 
        <Col md={4}>
          <div style={{ display: 'flex' ,alignItems: 'center', }}>
            <input
              type='radio'
              style={{ marginRight: '8px' }}
              id='periodo_dias'
              name='tipoFiltro'
              value={'periodo_dias'}
              checked={values.tipoFiltro === 'periodo_dias'}
              onBlur={handleBlur}
              onChange={(value) => handleChange(value)}
              /> 
            <label className="form-check-label form-label mb-0" htmlFor="periodo_dias">
              Filtrar por período de dias
            </label>  
          </div>
        </Col>

        <Col md={5}>
          <div style={{ display: 'flex' ,alignItems: 'center', }}>
            <input
              type='radio'
              style={{ marginRight: '8px' }}
              id='periodo_meses'
              name='tipoFiltro'
              value={'periodo_meses'}
              checked={values.tipoFiltro === 'periodo_meses'}
              onBlur={handleBlur}
              onChange={(value) => handleChange(value)}
            /> 
            <label className="form-check-label form-label mb-0" htmlFor="periodo_meses">
              Filtrar por período de meses
            </label>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: '16px', color: "#fff" }}>
        <Col md={2} >   
          <DateTime 
              type={"branco"}
              label='Período de'
              id='periodoDe'
              dateFormat="dd/MM/yyyy"
              selected={values.periodoDe} 
              required 
          />
   
        </Col>

        <Col md={2} >
          <DateTime
            type={"branco"}
            label='Período até'
            id='periodoAte'
            dateFormat="dd/MM/yyyy"
            selected={values.periodoAte}
            required
          />
        </Col>

        {/* <Col md={3}>
          <SelectAsync
            id='enfermeiro'
            label='Enfermeiro'
            emptyLabel='Selecione...'
            value={values.enfermeiro || -1}
            onChange={handleChange}
            api={getAllToSelectProfissionalAPI}
          />
        </Col> */}

        <Col md={3} >
          <SelectAsync
            type={"branco"}
            id='paciente'
            label='Paciente'
            emptyLabel='Selecione...'
            value={values.paciente || -1}
            onChange={handleChange}
            api={getAllToSelectPacienteAPI}
          />
        </Col>

        <Col md={1}>
          <Button style={{ marginTop: '32px' }} onClick={handleSubmit}>
            Atualizar
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export { DashboardFiltrosCampos }
export default DashboardFiltrosCampos
