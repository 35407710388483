import React, { useContext } from 'react'
import { FaRegIdBadge } from 'react-icons/fa'
import { Selos } from '../helper'
import { ProntuarioContext } from '../../context'

type Props = {}

const PlanoCuidadoDadosPaciente = (props: Props) => {
  const { prontuario } = useContext(ProntuarioContext)

  return (
    <div style={{ display: 'flex', color: '#304439' }}>
      <FaRegIdBadge size={30} />

      <div style={{ marginLeft: '10px' }}>
        <div style={{ fontSize: '18px', fontWeight: '500' }}>{prontuario?.paciente?.nome || 'sdfsdf'}</div>

        <Selos prontuario={prontuario} colorido />
      </div>
    </div>
  )
}

export { PlanoCuidadoDadosPaciente }
export default PlanoCuidadoDadosPaciente
