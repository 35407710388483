import { axiosAPI } from '../axiosAPI'
import { IProntuarioFuncaoRespiratoriaForm, IProntuarioFuncaoRespiratoriaListagem } from './types'

export const prontuarioFuncaoRespiratoriaCreateAPI = async (
  idProntuario: number,
  values: IProntuarioFuncaoRespiratoriaForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariofuncaorespiratoria',
      {
        id_prontuario: idProntuario,
        co2_ambiente: values.co2Ambiente,
        cateter_nasal: values.cateterNasal,
        outro_dispositivo: values.outroDispositivo,
        cateter_nasal_lmin: values.cateterNasalLmin,
        mv: values.mv,
        mv_perc: values.mvPerc,
        vni: values.vni,
        vni_tipo: values.vniTipo,
        auscuta_pulmonar: values.auscutaPulmonar,
        tot: values.tot,
        tot_numero: values.totNumero,
        tot_dias: values.totDias,
        mnr_lmin: values.mnrLmin,
        secrecao: values.secrecao,
        secrecao_tipo: values.secrecaoTipo,
        tqt: values.tqt,
        tqt_numero: values.tqtNumero,
        tqt_dias: values.tqtDias,
        mnr: values.mnr,
        tosse: values.tosse,
        tosse_tipo: values.tosseTipo,
        vmi: values.vmi,
        vmi_desmami: values.vmiDesmami,
        gasometria: values.gasometria,
        ph: values.ph,
        hco3: values.hco3,
        mvi_modo: values.mviModo,
        peep: values.peep,
        fio2: values.fio2,
        fr_programada: values.frProgramada,
        pco2: values.pco2,
        so2: values.so2,
        po2: values.po2,
        id_respiracao: values.idRespiracao,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioFuncaoRespiratoriaForm, IProntuarioFuncaoRespiratoriaListagem }
