import { ChangeEventHandler, FocusEventHandler } from 'react'
import { TextAreaStyled } from '../styles'

interface ITextAreaProps {
  id: string,
  label?: string,
  value?: string | number,
  placeholder?: string,
  linhas?: number,
  style?: object,
  onChange: ChangeEventHandler<HTMLTextAreaElement>,
  onBlur?: FocusEventHandler<HTMLTextAreaElement>,
}

const TextArea = (props: ITextAreaProps) => {
  return (
    <div style={{ marginBottom: '8px' }}>
      {props.label && (
        <label className="form-input-label form-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <TextAreaStyled
        className="form-input form-control"
        style={{ ...props.style, marginRight: '6px' }}
        id={props.id}
        name={props.id}
        value={props.value}
        placeholder={props.placeholder}
        rows={props.linhas || 2}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  )
}

export { TextArea }
export default TextArea
