import { parsePacienteBackToFront } from '../pacientes/parser'
import { parseProntuarioPlanoCuidadoBackToFront } from '../prontuario/parser/parseProntuarioPlanoCuidado'
import { IPlanoCuidadosRelatorio, IPlanoCuidadosRelatorioBackend } from './types'

export const parseProntuarioPlanoCuidadoRelatorioBackToFront = (
  dadosBackend: IPlanoCuidadosRelatorioBackend
): IPlanoCuidadosRelatorio => ({
  prontuarioPlanoCuidado: dadosBackend.ProntuarioPlanoCuidado?.map(dado => parseProntuarioPlanoCuidadoBackToFront(dado)),
  alterado: dadosBackend.alterado,
  cliCirurgica: dadosBackend.cli_cirurgica,
  cliMedica: dadosBackend.cli_medica,
  comorbidades: dadosBackend.comorbidades,
  dhAdmissao: dadosBackend.dh_admissao,
  dhPlanoCuidado: dadosBackend.dh_plano_cuidado,
  dhSaida: dadosBackend.dh_saida,
  diasInternacao: dadosBackend.dias_internacao,
  id: dadosBackend.id,
  idEmpresa: dadosBackend.id_empresa,
  idLeito: dadosBackend.id_leito,
  idPaciente: dadosBackend.id_paciente,
  isolContato: dadosBackend.isol_contato,
  isolProtetor: dadosBackend.isol_protetor,
  isolRespiratorio: dadosBackend.isol_respiratorio,
  isolVigilancia: dadosBackend.isol_vigilancia,
  leito: dadosBackend.leito,
  medicacaoUso: dadosBackend.medicacao_uso,
  nomeProntuario: dadosBackend.nome_prontuario,
  motivoAlta: dadosBackend.motivo_alta,
  observacaoAlta: dadosBackend.observacao_alta,
  paciente: parsePacienteBackToFront(dadosBackend.paciente),
  procResidencia: dadosBackend.proc_residencia,
  procRua: dadosBackend.proc_rua,
  procServSaude: dadosBackend.proc_serv_saude,
  procSetor: dadosBackend.proc_setor,
  procSetorNome: dadosBackend.proc_setor_nome,
  queixaPrincipal: dadosBackend.queixa_principal,
  registroComplementar: dadosBackend.registro_complementar,
  riscoAlergia: dadosBackend.risco_alergia,
  riscoContagio: dadosBackend.risco_contagio,
  riscoLesaoPressao: dadosBackend.risco_lesao_pressao,
  riscoQueda: dadosBackend.risco_queda,
  riscoRessecamentoOcular: dadosBackend.risco_ressecamento_ocular,
  uti: dadosBackend.uti,
})