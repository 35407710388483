import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { ProtectedLayout } from './components/protected-routes'

import { AuthProvider } from './context/auth_provider'
import {
  Login,
  HomeApp,
  Cadastros,
  Edemas,
  Atividades,
  Leitos,
  Lesoes,
  Respiracoes,
  TiposInfusoes,
  Intervencoes,
  TiposPele,
  Dispositivos,
  Diagnosticos,
  Resultados,
  Empresas,
  Profissionais,
  Pacientes,
  Prontuario,
  AjudaPlaylistCanal,
  Indicadores,
  Aprazamentos,
  PlanoCuidados,
  ProntuarioRelatorioPage,
  AreaGestor,
    
} from './pages'
import { useAuth } from './context/auth_provider/useAuth'
import { ProtectedOnlyGestor } from './components/protected-routes/ProtectedOnlyGestor'
import { DashboardDados } from './pages/area_gestor/dashboard'
import Resumo from './pages/resumo'

/**
 * Componente que cuida das rotas principais.
 * @return {JSX.Element}
 */
function AppRouter() {
  const { user } = useAuth()

  const someData = "Some data to pass";
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<ProtectedLayout><HomeApp /></ProtectedLayout>}>
            <Route path='cadastros' element={<Cadastros />} />
            <Route path='atividade' element={<Atividades />} />
            <Route path='leito' element={<Leitos />} />
            <Route path='edema_localizacao' element={<Edemas />} />
            <Route path='respiracao' element={<Respiracoes />} />
            <Route path='tipo_lesao' element={<Lesoes />} />
            <Route path='tipos_infusoes' element={<TiposInfusoes />} />
            <Route path='intervencao' element={<Intervencoes />} />
            <Route path='dispositivos' element={<Dispositivos />} />
            <Route path='tipos_pele' element={<TiposPele />} />
            <Route path='diagnosticos' element={<Diagnosticos />} />
            <Route path='resultados_enfermagem' element={<Resultados />} />
            <Route path='empresas' element={<Empresas />} />
            <Route path='profissionais' element={<Profissionais />} />
            <Route path='pacientes' element={<Pacientes />} />
            <Route path='resumo/:data'  element={<Resumo />} />
            <Route path='indicadores' element={<Indicadores />} />
            <Route path='aprazamentos' element={<Aprazamentos />} />
            <Route path='pacientes/prontuario/:id' element={<Prontuario />} />
            <Route path='pacientes/prontuario/:id/plano_cuidado' element={<PlanoCuidados />} />
            <Route path='ajuda-playlist' element={<AjudaPlaylistCanal />} />
            <Route path='prontuario/impressao' element={<ProntuarioRelatorioPage />} />
            <Route path='area_gestao' element={<ProtectedOnlyGestor><AreaGestor /></ProtectedOnlyGestor>} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route
            path='*'
            element={<Navigate to='/' />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default AppRouter
