import { ILesaoListagem } from "../../cadastro/lesoesAPI";
import { ITipoPeleListagem } from "../../cadastro/tiposPeleAPI";


export enum AspectoLesaoEnum {
  SAEROSO = 'SAEROSO',
  SEROSANGUINOLENTO = 'SEROSANGUINOLENTO',
  SANGUINOLENTO = 'SANGUINOLENTO',
  PUROLENTO = 'PUROLENTO',
  AUSENTE = 'AUSENTE',
  OUTRO = 'OUTRO',
}

export enum QuantidadeLesaoEnum {
  PEQUENA = 'PEQUENA',
  MEDIA = 'MEDIA',
  ALTA = 'ALTA',
  AUSENTE = 'AUSENTE',
}

export enum OdorLesaoEnum {
  PRESENTE = 'PRESENTE',
  AUSENTE = 'AUSENTE',
}

export enum AspectoBordaLesaoEnum {
  EPITELIZADA = 'EPITELIZADA',
  MACERADA = 'MACERADA',
  HIPERQUERATOSE = 'HIPERQUERATOSE',
  HIPEREMIA = 'HIPEREMIA',
}

export enum AspectoLeitoLesaoEnum {
  GRANULACAO = 'GRANULACAO',
  NECROSE_COAGULACAO = 'NECROSE_COAGULACAO',
  NECROSE_LIQUEFACAO = 'NECROSE_LIQUEFACAO',
  FIBRINA = 'FIBRINA',
  BIOFILME = 'BIOFILME',
}

export enum SinaisFlogisticosLeitoLesaoEnum {
  PRESENTE = 'PRESENTE',
  AUSENTE = 'AUSENTE',
}

export enum HigieneLesaoLeitoLesaoEnum {
  PHMB = 'PHMB',
  SF_09 = 'SF_09',
  CLOREXIDINA = 'CLOREXIDINA',
  OUTRO = 'OUTRO',
}

export interface IFotoAvaliacao {
  id: number,
  idIntegridadeFisicaAvaliacao: number,
  fotoEndereco: string | null,
}

export interface IFotoAvaliacaoBackend {
  id: number,
  id_integridade_fisica_avaliacao: number,
  foto: string | null,
}


export interface IIntegridadeFisicaAvaliacao {
  id: number;
  avaliacao: string;
  tamanhoAproximado: string | null;
  aspecto: AspectoLesaoEnum | null;
  aspectoOutro: string | null;
  quantidade: QuantidadeLesaoEnum | null;
  odor: OdorLesaoEnum | null;
  aspectoBorda: AspectoBordaLesaoEnum | null;
  aspectoLeito: AspectoLeitoLesaoEnum | null;
  sinaisFlogisticos: SinaisFlogisticosLeitoLesaoEnum | null;
  higieneLesao: HigieneLesaoLeitoLesaoEnum | null;
  higieneLesaoOutro: string | null;
  coberturaPrimaria: string | null;
  coberturaSecundaria: string | null;

  fotos: IFotoAvaliacao[];
}

export interface IIntegridadeFisicaAvaliacaoBackend {
  id: number;
  avaliacao: string;
  tamanho_aproximado: string | null;
  aspecto: AspectoLesaoEnum | null;
  aspecto_outro: string | null;
  quantidade: QuantidadeLesaoEnum | null;
  odor: OdorLesaoEnum | null;
  aspecto_borda: AspectoBordaLesaoEnum | null;
  aspecto_leito: AspectoLeitoLesaoEnum | null;
  sinais_flogisticos: SinaisFlogisticosLeitoLesaoEnum | null;
  higiene_lesao: HigieneLesaoLeitoLesaoEnum | null;
  higiene_lesao_outro: string | null;
  cobertura_primaria: string | null;
  cobertura_secundaria: string | null;

  fotoAvaliacao: IFotoAvaliacaoBackend[];
}

export enum EscalaBradenEnum {
  RISCO_BRANDO_15_16 = 'RISCO_BRANDO_15_16',
  RISCO_MODERADO_12_14 = 'RISCO_MODERADO_12_14',
  RISCO_SEVERO_MENOR_11 = 'RISCO_SEVERO_MENOR_11',
}

export interface IIntegridadeFisicaTipoLesao {
  id: number;
  idTipoLesao: number;
  tipoLesao: ILesaoListagem;
  integridadeFisicaAvaliacao: IIntegridadeFisicaAvaliacao | null;
  grau: string;
}

export interface IIntegridadeFisicaTipoLesaoBackend {
  id: number;
  id_tipo_lesao: number;
  tipo_lesao: ILesaoListagem;
  IntegridadeFisicaAvaliacao: IIntegridadeFisicaAvaliacaoBackend[];
  grau: string;
}

export interface IProntuarioIntegridadeFisicaListagem {
  id: number;
  observacao: string | null;
  dreno: boolean | null;
  drenagemMl: string | null;
  local: string | null;
  dataInsercao: Date | null;
  escalaBraden: EscalaBradenEnum;
  percentualSensorial: string | null;
  umidade: string | null;
  atividade: string | null;
  mobilidade: string | null;
  nutricao: string | null;
  ficcao: string | null;
  idTipoPele: number;
  integridadeFisicaTipoLesao: IIntegridadeFisicaTipoLesao[];
  avaliado: Date | null;
  alterado: boolean | null;
  tipoPele: ITipoPeleListagem | null;
}

export interface IProntuarioIntegridadeFisicaListagemBackend {
  id: number;
  observacao: string | null;
  dreno: boolean | null;
  drenagem_ml: string | null;
  local: string | null;
  data_insercao: Date | null;
  escala_braden: EscalaBradenEnum;
  percentual_sensorial: number | null;
  umidade: number | null;
  atividade: number | null;
  mobilidade: number | null;
  nutricao: number | null;
  ficcao: number | null;
  id_tipo_pele: number;
  IntegridadeFisicaTipoLesao: IIntegridadeFisicaTipoLesaoBackend[];
  avaliado: Date | null;
  alterado: boolean | null;
  tipoPele: ITipoPeleListagem | null;
}

export interface IAvaliacaoLesaoFotoForm {
  id: number;
  fotoEndereco: string;
  foto: File | null;
}

export interface IAvaliacaoLesaoForm {
  avaliacao: string;
  tamanhoAproximado: string | null;
  aspecto: AspectoLesaoEnum | null;
  aspectoOutro: string | null;
  quantidade: QuantidadeLesaoEnum | null;
  odor: OdorLesaoEnum | null;
  aspectoBorda: AspectoBordaLesaoEnum | null;
  aspectoLeito: AspectoLeitoLesaoEnum | null;
  sinaisFlogisticos: SinaisFlogisticosLeitoLesaoEnum | null;
  higieneLesao: HigieneLesaoLeitoLesaoEnum | null;
  higieneLesaoOutro: string | null;
  coberturaPrimaria: string | null;
  coberturaSecundaria: string | null;
  fotos: IAvaliacaoLesaoFotoForm[];
}

export interface ILesaoForm {
  id: number;
  grau: string;
  idTipoLesao: number;
  descricao: string;
  avaliacao: IAvaliacaoLesaoForm;
}

export interface IProntuarioIntegridadeFisicaForm {
  observacao: string | null;
  dreno: boolean | null;
  drenagemMl: string | null;
  local: string | null;
  dataInsercao: Date | null;
  escalaBraden: EscalaBradenEnum | null;
  percentualSensorial: string | null;
  umidade: string | null;
  atividade: string | null;
  mobilidade: string | null;
  nutricao: string | null;
  ficcao: string | null;
  idTipoPele: number | null;
  lesoes: ILesaoForm[];
  avaliado: Date | null;
  stAvaliado: boolean | null;
  alterado: boolean | null;
}
