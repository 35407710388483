import { axiosAPI } from '../axiosAPI'
import { IProntuarioCuidadoCorporalForm, IProntuarioCuidadoCorporalListagem } from './types'

export const prontuarioCuidadoCorporalCreateAPI = async (
  idProntuario: number,
  values: IProntuarioCuidadoCorporalForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuariocuidadocorporal',
      {
        id_prontuario: idProntuario,
        intima_prejudicada: values.intimaPrejudicada,
        oral_prejudicada: values.oralPrejudicada,
        corporal_prejudicada: values.corporalPrejudicada,
        dependencia: values.dependencia,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioCuidadoCorporalForm, IProntuarioCuidadoCorporalListagem }
