import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { LeitosContext } from '../context';
import { TableContainer, TableStyled } from '../../../../components/styles';

const LeitosListagem = () => {
  const { leitos, selecionado, setSelecionado } = useContext(LeitosContext);

  return (
    <>
      {leitos && leitos.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Leito.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {leitos && leitos.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {leitos && leitos.length > 0 && leitos.map((leito => (
                <tr
                  key={leito.id}
                  style={{ border: selecionado?.id === leito.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(leito)}
                >
                  <td>{leito.nome}</td>
                  <td>{leito.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { LeitosListagem }
export default LeitosListagem
