import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { IntervencoesContext } from '../context';
import { parseGrupoPlanoCuidado } from '../../../pacientes/prontuario/components/plano_cuidados/util';
import { TableContainer, TableStyled } from '../../../../components/styles';

const IntervencoesListagem = () => {
  const { intervencoes, selecionado, setSelecionado } = useContext(IntervencoesContext);

  return (
    <>
      {intervencoes && intervencoes.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua a primeira intervenção.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {intervencoes && intervencoes.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Grupo</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {intervencoes && intervencoes.length > 0 && intervencoes.map((intervencao => (
                <tr
                  key={intervencao.id}
                  style={{ border: selecionado?.id === intervencao.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(intervencao)}
                >
                  <td>{intervencao.nome}</td>
                  <td>{!!intervencao.grupo && intervencao.grupo !== -1 ? parseGrupoPlanoCuidado(intervencao.grupo) : ''}</td>
                  <td>{intervencao.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { IntervencoesListagem }
export default IntervencoesListagem
