import { useContext } from 'react'
import styled from 'styled-components' 
import { DashboardContext } from '../../context'
import { opcoesDashboard } from './opcoesDashboard'

interface ISpanLinkProps {
  estaSelecionado: boolean,
}

const SpanLink = styled.span<ISpanLinkProps>`
  color: ${(props) => props.estaSelecionado ? '#000' : '#fff'};
  cursor: pointer;
 
`
const DivOpcao = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  padding: 6px 0;
`

const DashboardMenu = () => {
  const { selecionado, setSelecionado } = useContext(DashboardContext)
  const ultimoElemento = opcoesDashboard.length
  console.log(ultimoElemento)
  return (
    <div style={{ width: '300px', background: '#24563b', display: 'flex', flexDirection: 'column', borderRadius: '8px' }}>
      <div style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid #fff', padding: '6px 0', color: '#fff', fontSize: '22px', fontWeight: 600 }}>
        Opções
      </div> 
      {opcoesDashboard.map((item, index) => (
        <DivOpcao key={`opção-${item.rota}`} onClick={() => setSelecionado(item.rota)} style={{backgroundColor: selecionado === item.rota ? '#9fb7aa' : '#24563b', borderRadius:  ultimoElemento-1 === index ? "8px" : "0" }}>
          {/*selecionado === item.rota && (<FaCheck color='#6A16F4' size={20} style={{ marginRight: '12px'}} />)*/}
          <SpanLink style={{fontWeight: selecionado === item.rota ?  '700' :'500', width: '100%'}} estaSelecionado={selecionado === item.rota}>{item.titulo}</SpanLink>
        </DivOpcao>
      ))}
    </div>
  )
} 
export { DashboardMenu }
export default DashboardMenu
