import React, { useContext, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

import { dispositivoCreateAPI, dispositivoUpdateAPI } from '../../../api/cadastro/dispositivosAPI'
import { IDispositivosForm } from './Dispositivos';
import { DispositivosBotoes, DispositivosFormCampos, DispositivosListagem } from './components';
import { DispositivosContext } from './context';
import { GoPencil } from 'react-icons/go';
import { DivTitulo } from '../styles';

const DispositivosContainerForm = () => {
  const { carregando, loadDispositivos, setCarregando, selecionado, setSelecionado } = useContext(DispositivosContext);
  const [keyForm, setKeyForm] = useState<number>(0)

  const initialValues: IDispositivosForm = {
    nome: selecionado?.nome || '',
    ativo: selecionado?.ativo === false ? false : true,
  }

  const schema = Yup.object().shape({
    nome: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').required('Campo obrigatório!'),
    ativo: Yup.bool(),
  })

  useEffect(() => {
    setKeyForm(prev => prev + 1)
  }, [selecionado])

  const handleSubmit = (
    values: IDispositivosForm,
    actions: FormikHelpers<IDispositivosForm>
  ) => {
    setCarregando(true)
    if (!selecionado) {
      dispositivoCreateAPI(values.nome, values.ativo)
        .then(() => {
          loadDispositivos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dispositivo criado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    } else {
      dispositivoUpdateAPI(selecionado.id, values.nome, values.ativo)
        .then(() => {
          setSelecionado(null)
          loadDispositivos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dispositivo editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      <DivTitulo style={{ marginTop: '18px' }}>
        <GoPencil color='#304439' size={24} style={{ marginRight: '8px' }} />
        Cadastro - Dispositivos
      </DivTitulo>

      <Formik
        key={keyForm}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <BlockUi tag="div" blocking={carregando}>
          <DispositivosFormCampos />

          <DispositivosBotoes />

          <DispositivosListagem />
        </BlockUi>
      </Formik>
    </>
  )
}

export { DispositivosContainerForm }
export default DispositivosContainerForm
