import React, { useEffect, useState } from 'react'
import { PDFViewer, Document, View, Page, Text, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { isDesktop } from 'react-device-detect'
 
import logoHospital from '../../../../images/logohospitaltelecila.jpg'
import logo from '../../../../images/sol_logo.png'
import alergia from '../../../../images/alergia.png'
import isolamento from '../../../../images/isolamentoPreto.png'
import lesao from '../../../../images/lesao.png'
import ocular from '../../../../images/ocular.png'
import queda from '../../../../images/queda.png'
import { relatorioAlta } from '../../../../api/relatorios/relatoriosAPI'
import { useAuth } from '../../../../context/auth_provider/useAuth'
import { IRelatorioAltaBackend } from '../../../../api/relatorios/types'
import Button from '../../../../components/Button'
import { IUser } from '../../../../context/auth_provider/types'
//import { useAuth } from '../../context/auth_provider/useAuth'
// import { useAuth } from '../../../../context/auth_provider/useAuth'

const styles = StyleSheet.create({
  dataHeader: {
    position: 'absolute',
    top: 2,
    textAlign: 'right',
    right: 5,
    fontSize: 10,
  },

  HeaderStyled: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'row'
  },

  ContainerStyled: {
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: '15 10 10',
    position: 'relative',
    margin: '20 20',
  },

  LabelStyled: {
    fontSize: 10,
    fontWeight: 400,
    color: "black"
  },
  
  ValueStyled: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 8,
  }  , 
  ImagesStyle: {
    height: 50,
    width: 60,
    marginLeft: 15,
  },
  ImagesStyle2: {
    position: 'absolute',
    top: 2,
    right: 8,
    height: 50,
    width: 50,
    marginRight: 15,
  },

  Sumario: {
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  SumarioStyled: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },

  DadosSumarioStyled: {
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 0,
  },

  TituloStyled: {
    position: 'absolute',
    top: -8,
    left: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 700,
  }
})

interface IPacienteAltaRelatorioCamposProps {
  idPaciente: number,
  textoOBS: string | null,
}

interface IDocumentProps {
  dadosRelatorio?: IRelatorioAltaBackend,
  textoOBS: string | null,
  user: IUser,
}

const Relatorio = (props: IDocumentProps) => {
  
  const { user } = useAuth()

  return (
    <Document>
      <Page size='A4' style={{ paddingBottom: '50px' }}>
        <View fixed>
          <Text style={styles.dataHeader}>
            {format((new Date()), 'dd/MM/yyyy hh:mm:ss')}
          </Text> 
          <View style={styles.HeaderStyled}>
            {/* <Image src={logo} style={styles.ImagesStyle} cache />  */}
            <View style={styles.Sumario}>
              <Text style={styles.SumarioStyled}>SUMÁRIO</Text>
              <Text style={styles.DadosSumarioStyled}>{props.user?.enderecoEmpresa || '-'}</Text>
              <Text style={styles.DadosSumarioStyled}>{props.user?.contatoEmpresa || '-'}</Text> 

              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 0, marginBottom: 0 }}>
                  <Text style={{ ...styles.TituloStyled, color: '#C4C4C4' }}>Prontuário</Text> 
                  <Text style={{ fontSize: '10px', minWidth: 80 }}>{props.dadosRelatorio?.id}</Text>
                </View>

                <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0 }}>
                  <Text style={{ ...styles.TituloStyled, color: '#C4C4C4' }}>Leito</Text>

                  <Text style={{ fontSize: '10px', minWidth: 80 }}>{props.dadosRelatorio?.leito?.nome || ''}</Text>
                </View>

                <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0 }}>
                  <Text style={{ ...styles.TituloStyled, color: '#C4C4C4' }}>Riscos</Text>

                  <View style={{ display: 'flex', flexDirection: 'row', minWidth: 80 }}>
                    {/* {props.dadosRelatorio?.risco_lesao_pressao && (
                      <Image src={lesao}  style={{ width: '10px', height:"12px", marginRight: '10px' }} />
                    )}

                    {props.dadosRelatorio?.risco_ressecamento_ocular && (
                      <Image src={ocular}  style={{ width: '10px', height:"12px", marginRight: '10px' }} />
                    )}

                    {props.dadosRelatorio?.risco_alergia && (
                      <Image src={alergia}  style={{ width: '10px', height:"12px", marginRight: '10px' }} />
                    )}

                    {props.dadosRelatorio?.risco_queda && (
                      <Image src={queda}  style={{ width: '10px', height:"12px", marginRight: '10px' }} />
                    )}

                    {props.dadosRelatorio?.risco_contagio && (
                      <Image src={isolamento}  style={{ width: '10px', height:"12px", marginRight: '10px' }} />
                    )} */}
                  </View>
                </View>
              </View>
            </View>
                          
            {/* <Image src={logoHospital} style={styles.ImagesStyle2} cache /> */}
          </View>

          <View style={styles.ContainerStyled}>
            <Text style={styles.TituloStyled}>Paciente</Text>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Text style={{ fontSize: '10px' }}>{props.dadosRelatorio?.paciente.nome}</Text>
              <Text style={{ fontSize: '12px' }}>Contato: {props.dadosRelatorio?.paciente.contato}</Text>
            </View>

            <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '4px'}}>
              <Text style={{ fontSize: '12px' }}>
                Data de admissão: {props.dadosRelatorio?.dh_admissao ? format((new Date(props.dadosRelatorio?.dh_admissao)), 'dd/MM/yyyy hh:mm') : ''}
              </Text>
              <Text style={{ fontSize: '12px' }}>Dias de internação: {props.dadosRelatorio?.dias_internacao}</Text>
              <Text style={{ fontSize: '12px' }}>CPF: {props.dadosRelatorio?.paciente.numero_sus}</Text>
            </View>
          </View>
        </View> 
        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Antibióticos</Text> 
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '5 5 0' }}>
            <View>
              <Text style={styles.LabelStyled}>
                Quais e quantos dias
              </Text>
              {props.dadosRelatorio?.ProntuarioTerapiaEndovenosa.map(antibiotico => (
                <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
                  {`${antibiotico.antibioticos || ''} - ${antibiotico.descricao || ''}`}
                </Text>
              ))}
            </View>
          </View>
        </View>

        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Complementos</Text>

          <View style={{ margin: '5 5 0' }}>
            <Text style={styles.LabelStyled}>
              Eventos adversos
            </Text>
            <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
              {props.dadosRelatorio?.ProntuarioRegistroComplementar[0]?.eventos_adversos || '-'}
            </Text>
          </View>

          <View style={{ margin: '5 5 0' }}>
            <Text style={styles.LabelStyled}>
              Ocorrências
            </Text>
            <Text style={{ ...styles.ValueStyled, marginBottom: 0 }}>
              {props.dadosRelatorio?.ProntuarioRegistroComplementar[0]?.ocorrencias || '-'}
            </Text>
          </View>
        </View>

        <View style={{ ...styles.ContainerStyled, marginTop: 0, paddingTop: 10 }}>
          <Text style={styles.TituloStyled}>Texto livre</Text>

          <View style={{ margin: '5 5 0' }}>
            <Text style={{ ...styles.ValueStyled, marginBottom: 0, minHeight: '50px' }}>
              {props.textoOBS}
            </Text>
          </View>
        </View>

        <Text style={{ textAlign: 'center', marginBottom: 4 }}>
          _______________________________
        </Text>

        <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
          {user?.nome||'-'}
        </Text>
        <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
          Assinatura/Carimbo
        </Text>
      </Page>
    </Document>
  )
}

const PacienteAltaRelatorioCampos = (props: IPacienteAltaRelatorioCamposProps) => {
  const [dadosRelatorio, setDadosRelatorio] = useState<IRelatorioAltaBackend>()
  const { user } = useAuth()

  const getDadosRelatorio = () => {
    relatorioAlta(props.idPaciente)
      .then(resultado => setDadosRelatorio(resultado))
      .catch(console.error)
  }

  useEffect(() => {
    getDadosRelatorio()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idPaciente])

  return (
    <>
      <Button variant='new' style={{ marginBottom: '16px' }}>
        <PDFDownloadLink
          document={
            <Relatorio dadosRelatorio={dadosRelatorio} textoOBS={props.textoOBS} user={user} />
          }
          fileName="rel-alta.pdf"
          style={{ textDecoration: 'none', color: '#fff' }}
        >
          {({ loading }) =>
            loading ? 'Gerando seu documento...' : 'Fazer download do relatório de alta!'
          }
        </PDFDownloadLink>
      </Button>

      {isDesktop && (
        <PDFViewer width='100%' height='80%' showToolbar>
          <Relatorio dadosRelatorio={dadosRelatorio} textoOBS={props.textoOBS} user={user} />
        </PDFViewer>
      )}
    </>
  )
}

export {PacienteAltaRelatorioCampos }
export default PacienteAltaRelatorioCampos
