import React from 'react'
import { Container, Image, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { MdPersonalInjury, MdPerson4 , MdPersonPin} from 'react-icons/md'
import { HiOutlineSun } from 'react-icons/hi'
import { GoGraph, GoPencil } from 'react-icons/go'

import { useAuth } from '../../context/auth_provider/useAuth'

export const NavHeader = () => {
  const { logout, user } = useAuth()

  return (
    <Container
      fluid
      style={{padding: '0'}}
      className='sticky-top w-100'
    >
      <Navbar
        expand="lg"
        variant="dark"
        style={{backgroundColor: '#24563b', padding: '15px 190px'}}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <Link
            style={{ textDecoration: 'none', color: 'white' }}
            to={`/profissional/${user.id}`}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {user.urlAvatar
                ? (
                  <Image src={'user.urlAvatar'} />
                ) : (
                  <MdPersonPin size={70} style={{ marginRight: '4px' }} />
                )
              }

              <div>
                <div>Bem vindo,</div>
                <div>{user.nome}</div>
                
                <div style={{ marginTop: '14px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '12px' }}>{user.tipo || 'Gestor'}</div>
                  <div style={{
                    color: '#FFF501',
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '20px',
                    letterSpacing: '0px',
                    marginRight: '2px'
                    }}
                  >
                    {user.pontos || 0}
                  </div>
                  <HiOutlineSun color='#FFF501' size={33} />
                </div>
              </div>
            </div>
          </Link>

          <div>
            <Link
              to="/profissionais"
              style={{marginRight: '25px', textDecoration: 'none', color: 'white'}}
            >
              <MdPerson4 size={21} style={{ marginRight: '4px' }} />
              Profissionais
            </Link> 
            <Link
              to="/pacientes"
              style={{marginRight: '25px', textDecoration: 'none', color: 'white'}}
            >
              <MdPersonalInjury size={21} style={{ marginRight: '4px' }} />
              Pacientes
            </Link>
            
            {user.tipo === 'GESTOR' && (
              <Link
                to="/area_gestao"
                style={{marginRight: '25px', textDecoration: 'none', color: 'white'}}
              >
                <GoGraph size={21} style={{ marginRight: '4px' }} />
                Gestão
              </Link>
            )}

            {['GESTOR', 'ADMIN'].includes(user.tipo)  && (
              <Link
                to="/cadastros"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <GoPencil size={21} style={{ marginRight: '4px' }} />
                Cadastros
              </Link>
            )}
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ color: 'white' }}>{user.nomeEmpresa || 'Hospital estadual'}</div>

            <Link
              to="/login"
              onClick={() => logout()}
              style={{marginLeft: '8px', textDecoration: 'none', color: 'white'}}
            >
              <RiLogoutCircleRLine size={21} />
            </Link>
          </div>
        </div>
      </Navbar>
    </Container>
  )
}
