
import { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

import { ProntuarioRelatorioCampos } from './ProntuarioRelatorioCampos'
import { useLocation } from 'react-router-dom'
import { SelectAsync } from '../../../../../components/select-async'
import { getDatasSelectProntuario } from '../../../../../api/relatorios/relatoriosAPI'
import Button from '../../../../../components/Button'
import styled from 'styled-components'

const SubTitleStyled = styled('div') `
  font-weight: 700;
  font-size: 20px;
  line-height: 1ch;
  color: #222222;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 8px 26px 0px;
`; 
const HeaderTitleStyled = styled('div') `
  font-weight: 700;
  font-size: 26px;
  line-height: 1ch;
  color: #222222;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px 0px 0px;
`;

const CardContainer = styled('div') `
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 26px;
  background-color: #EDE38E;
  padding: 26px;
`;

const ProntuarioRelatorioPage = () => {
  const [modalRelatorioAberto, setModalRelatorioAberto] = useState<boolean>(false)
  const [data, setData] = useState<string>('')
  const location = useLocation()
  const { state } = location
 
  return (
    <>
      <CardContainer>
        <HeaderTitleStyled>Impressão do prontuário de enfermagem</HeaderTitleStyled>
        <SubTitleStyled>{state.pacienteNome}</SubTitleStyled> 
        <Row>
          <Col md={6}>
            <SelectAsync
              id='historico'
              label='Historico'
              emptyLabel='Selecione...'
              value={data || ''}
              onChange={(e) => {   
                const valor = e.currentTarget?.value
                setData(valor)
              }}
              api={() => getDatasSelectProntuario(state.pacienteId)}
            />
          </Col> 
          <Col md={6}>
            <Button
              style={{ marginTop: '32px' }}
              onClick={() => setModalRelatorioAberto(true)} >
              Confirmar
            </Button>
          </Col>
        </Row>
      </CardContainer> 
      <Modal
        size='lg'
        show={modalRelatorioAberto}
        onHide={() => setModalRelatorioAberto(false)} >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Relatório do prontuário de enfermagem
          </Modal.Title>
        </Modal.Header> 
        <Modal.Body style={{ height: '90vh'}}>
          <ProntuarioRelatorioCampos
            idPaciente={state.pacienteId}
            data={data}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { ProntuarioRelatorioPage }
export default ProntuarioRelatorioPage
