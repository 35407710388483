import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IProntuarioListagem, prontuarioGetByIdAPI } from '../../../../api/prontuario';
import { useLocation } from 'react-router-dom';

interface TProntuarioContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  prontuario: IProntuarioListagem | null,
  setProntuario: (newProntuario: IProntuarioListagem) => void,
  loadProntuario: (id: number) => void,
}

interface TProntuarioProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  prontuario: null,
  setProntuario: () => {
    throw new Error('setProntuario não implementada');
  },
  loadProntuario: () => {
    throw new Error('loadProntuario não implementada');
  }
};

export const ProntuarioContext = createContext<TProntuarioContext>(defaultState);

export const ProntuarioProvider = ({children}: TProntuarioProviderProps) => {
  const [prontuario, setProntuario] = useState<IProntuarioListagem | null>(defaultState.prontuario);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const location = useLocation()

  const loadProntuario = (id: number) => {
    setCarregando(true)
    prontuarioGetByIdAPI(id)
        .then((data) => {
          setProntuario(data)
        })
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    if (location && location.state && location.state.prontuarioId) {
      loadProntuario(location.state.prontuarioId)
    }
  }, [location])

  const providerValues: TProntuarioContext = {
    carregando,
    setCarregando,
    prontuario,
    setProntuario,
    loadProntuario,
  };

  return (
    <ProntuarioContext.Provider value={providerValues}>
      {children}
    </ProntuarioContext.Provider>
  );
};
