import { AxiosResponse } from 'axios'
import { axiosAPI } from '../axiosAPI'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { ISelectChoice } from '../../components'

interface IEmpresa {
    ativo: boolean,
    bairro: string | null,
    cep: string | null,
    cidade: string | null,
    cnpj: string,
    complemento: string | null,
    contato: string | null,
    data_cadastro: null,
    email: string | null,
    endereco: string | null,
    fantasia: string | null,
    id: number,
    ie: string | null,
    nome: string,
    numero: string | null,
    responsavel: string | null,
    uf: string | null,
    urlAvatar: string | null,
    url_canal_suporte: string | null,
}

interface IMensagensBackend {
    mensagens: {
        id: number,
        data_envio: string,
        data_recebida: string,
        assunto: string,
        descricao: string,
        problema: boolean,
        sugestao: boolean,
        lida: boolean,
        empresa: IEmpresa,
        destinatario: number,
        remetente: number
    }[],
    totalMensagensEnviadas: number,
    totalMensagensNaoLidas: number,
}

export interface IMensagem {
    id: number,
    dataEnvio: string,
    dataRecebida: string,
    assunto: string,
    descricao: string,
    problema: boolean,
    sugestao: boolean,
    lida: boolean,
    empresa: IEmpresa,
    destinatario: number,
    remetente: number
}

export interface IMensagens {
    mensagens: IMensagem[],
    totalMensagensEnviadas: number,
    totalMensagensNaoLidas: number,
}

export const getMensagens = async (
    enviada: boolean, recebida: boolean
): Promise<IMensagens> => {
  const user = getUserLocalStorage()
  const request: AxiosResponse<IMensagensBackend> = await axiosAPI.get(
    'mensagem',
    {
        params: {
            destinatario: recebida ? user.id : undefined,
            remetente: enviada ? user.id : undefined          
        }
    }
  )

  return {
    ...request.data,
    mensagens: request.data.mensagens.map(i => ({
        ...i,
        dataEnvio: i.data_envio,
        dataRecebida: i.data_recebida,
        empresa: i.empresa, 
    }))
  }
}

export const marcarMensagemComoLida = async (idMensagem: number): Promise<void> => {
    await axiosAPI.put(
        'mensagem/lida',
        { idMensagem }
    )
}

export interface IMensagemForm {
    assunto: string | null,
    descricao: string | null,
    tipoMensagem: 'sugestao' | 'problema'
  }

export const mensagemEnviar = async (values: IMensagemForm): Promise<void> => {
    const user = getUserLocalStorage()
    await axiosAPI.post(
        'mensagem',
        { 
            assunto: values.assunto,
            descricao: values.descricao,
            data_envio: new Date(),
            destinatarios: -1,
            remetente: user.id,
            sugestao: values.tipoMensagem === 'sugestao',
            problema: values.tipoMensagem === 'problema'
        }
    )
}

export const getGestores = async (): Promise<ISelectChoice[]> => {
  const response: AxiosResponse<ISelectChoice[]> = await axiosAPI.get('gestores')

  return response.data
}
