import React, { useContext } from 'react'
import { BiCalendarExclamation } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'

type Props = {}

const PlanoCuidadoBotao = (props: Props) => {
  const navigate = useNavigate()
  const { prontuario } = useContext(ProntuarioContext);

  return (
    <Button
      variant='primary'
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => navigate(
        'plano_cuidado',
        { state: { prontuarioId: prontuario?.id } }
      )}
    >
      <BiCalendarExclamation size={26} style={{ marginRight: '8px' }} />
      Plano de cuidados
    </Button>
  )
}

export { PlanoCuidadoBotao }
export default PlanoCuidadoBotao
