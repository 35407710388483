import { IProntuarioSegFisicaEmocionalListagem, IProntuarioSegFisicaEmocionalListagemBackend } from '../types'

export const parseProntuarioSegFisicaEmocionalBackToFront = (dadosBackend: IProntuarioSegFisicaEmocionalListagemBackend): IProntuarioSegFisicaEmocionalListagem => ({
  id: dadosBackend.id,
  tranquilo: dadosBackend.tranquilo,
  agitado: dadosBackend.agitado,
  agressivo: dadosBackend.agressivo,
  ansiedade: dadosBackend.ansiedade,
  medo: dadosBackend.medo,
  solicitante: dadosBackend.solicitante,
  naoAplica: dadosBackend.nao_aplica,
  temAlergias: dadosBackend.alergia,
  alergias: dadosBackend.alergias,
  precaucoes: dadosBackend.precaucoes,
  historicoQueda: dadosBackend.historico_queda,
  diagnosticoSecundario: dadosBackend.diagnostico_secundario,
  terapiaEndovenosa: dadosBackend.terapia_endovenosa,
  auxilioDeambulacao: dadosBackend.auxilio_deambulacao,
  marcha: dadosBackend.marcha,
  estadoMental: dadosBackend.estado_mental,
  acompanhamento: dadosBackend.acompanhamento,
  caps: dadosBackend.caps,
  diagnosticoPsiquiatra: dadosBackend.diagnostico_psiquiatra,
  adesaoTratamento: dadosBackend.adesao_tratamento,
  relacaoFamilia: dadosBackend.relacao_familia,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
