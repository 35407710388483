import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'

interface IVerFotoProps {
  enderecoFoto: string
}

const VerFoto = (props: IVerFotoProps) => {
  const [modalFotoAberta, setModalFotoAberta] = useState<boolean>(false)

  return (
    <>
      <FaEye style={{ cursor: 'pointer', color: '#304439' }} onClick={() => setModalFotoAberta(true)}/>

      <Modal show={modalFotoAberta} onHide={() => setModalFotoAberta(false)}>
        <Modal.Body>
          <img
            alt='imagem'
            src={`http://localhost:3333/${props.enderecoFoto}`}
            width='100%'
            height='460px'
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { VerFoto }
export default VerFoto
