import React, { FormEvent, useContext } from 'react'

import { InputStyled } from '../../../components/styles'
import { ProfissionaisContext } from '../context'

const ProfissionaisFiltros = () => {
  const { filtros, setFiltros } = useContext(ProfissionaisContext);

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const name: string = e.currentTarget.getAttribute('name') as string;
    const value = name === 'active' ? e.currentTarget.checked : e.currentTarget.value;
    setFiltros({...filtros, [name]: value});
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <InputStyled
        placeholder='Pesquise pelo nome do profissional'
        style={{ width: '350px' }}
        name='nome'
        value={filtros.nome}
        onChange={(value) => handleInputOnChange(value)}
      />
    </div>
  )
}

export { ProfissionaisFiltros }
export default ProfissionaisFiltros