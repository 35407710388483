import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { EdemasProvider } from './context';
import { EdemasContainerForm } from './EdemasContainerForm';

export interface IEdemasForm {
  nome: string,
  ativo: boolean,
}

const Edemas = () => {
  return (
    <EdemasProvider>
      <DivMainStyled>
        <DivCardStyled>
          <EdemasContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </EdemasProvider>
  )
}

export { Edemas }
export default Edemas
