import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { ILesaoListagem } from '../../../../api/cadastro/lesoesAPI';
import { lesaoListarAPI } from '../../../../api/cadastro/lesoesAPI';

interface TLesoesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  lesoes: ILesaoListagem[],
  setLesoes: (newLesoes: ILesaoListagem[]) => void,
  selecionado: ILesaoListagem | null,
  setSelecionado: (novoSelecionado: ILesaoListagem | null) => void,
  loadLesoes: () => void
}

interface TLesoesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  lesoes: [],
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  setLesoes: () => {
    throw new Error('setLesoes não implementada');
  },
  loadLesoes: () => {
    throw new Error('loadLesoes não implementada');
  },
};

export const LesoesContext = createContext<TLesoesContext>(defaultState);

export const LesoesProvider = ({children}: TLesoesProviderProps) => {
  const [lesoes, setLesoes] = useState<ILesaoListagem[]>(defaultState.lesoes);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<ILesaoListagem | null>(defaultState.selecionado)

  const loadLesoes = () => {
    setCarregando(true)
    lesaoListarAPI()
        .then((data) => setLesoes(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadLesoes();
  }, []);

  const providerValues: TLesoesContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    lesoes,
    setLesoes,
    loadLesoes,
  };

  return (
    <LesoesContext.Provider value={providerValues}>
      {children}
    </LesoesContext.Provider>
  );
};
