import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { Col, Row, Spinner } from 'react-bootstrap'
import { FaSave } from 'react-icons/fa'

import { prontuarioRegistroComplementarITUCreateAPI } from '../../../../../../api/prontuario/prontuarioRegistroComplementarAPI'
import { CateterLocalAdequaoEnum, IProntuarioRegistroComplementarITUForm, ItuNecessidadeEnum, SimNaoNaoAplicaEnum, SimNaoNaoSimplesEnum } from '../../../../../../api/prontuario/types'
import Button from '../../../../../../components/Button'
import { InputStyled } from '../../../../../../components/styles'
import { ProntuarioContext } from '../../../context'

const   RegistroComplementarITU = () => {
  const [formKey, setFormKey] = useState<number>(0)
  const [mensagem, setMensagem] = useState("")
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);

  const initialValues: IProntuarioRegistroComplementarITUForm = {
    svn: prontuario?.prontuarioRegistroComplementarITU[0]?.svn || ItuNecessidadeEnum.BALANCO_HIBRIDO || "",
    svnEspecificar: prontuario?.prontuarioRegistroComplementarITU[0]?.svnEspecificar || "",
    sistemaDrenagem: prontuario?.prontuarioRegistroComplementarITU[0]?.sistemaDrenagem || SimNaoNaoAplicaEnum.NAO_APLICA || ""  ,
    bolsaColetora: prontuario?.prontuarioRegistroComplementarITU[0]?.bolsaColetora || SimNaoNaoAplicaEnum.NAO_APLICA || ""  ,
    fluxoUninario: prontuario?.prontuarioRegistroComplementarITU[0]?.fluxoUninario || SimNaoNaoAplicaEnum.NAO_APLICA || ""  ,
    cateterFixado: prontuario?.prontuarioRegistroComplementarITU[0]?.cateterFixado || CateterLocalAdequaoEnum.NAO_APLICA || ""  ,
    realizadaHigiene: prontuario?.prontuarioRegistroComplementarITU[0]?.realizadaHigiene || SimNaoNaoAplicaEnum.NAO_APLICA || ""  ,
    limiteCapacidadeBolsa: prontuario?.prontuarioRegistroComplementarITU[0]?.limiteCapacidadeBolsa || SimNaoNaoAplicaEnum.NAO_APLICA|| "",
    uretaIntegra: prontuario?.prontuarioRegistroComplementarITU[0]?.uretaIntegra || SimNaoNaoSimplesEnum.NAO || "",
    uretaIntegraEspecificar: prontuario?.prontuarioRegistroComplementarITU[0]?.uretaIntegraEspecificar || "",
    urinaAspectoNormal: prontuario?.prontuarioRegistroComplementarITU[0]?.urinaAspectoNormal || SimNaoNaoSimplesEnum.NAO || "",
    urinaAspectoEspecificar: prontuario?.prontuarioRegistroComplementarITU[0]?.urinaAspectoEspecificar || "",
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioRegistroComplementarITUForm) => {
    if(values.svn == null){
      setMensagem("Infome o  SVD " + values.svn) 
    }else if(values.sistemaDrenagem == null){
      setMensagem("Infome o sistema de drenagem") 
    }else if(values.bolsaColetora == null){
      setMensagem("Infome sobre a bolsa coletora"+ values.bolsaColetora)
    }else if(values.fluxoUninario == null){
      setMensagem("Infome sobre o fluxo urinario")
    }else if(values.cateterFixado == null){
      setMensagem("Infome sobre o cateter")
    }else if(values.realizadaHigiene == null){  
      setMensagem("Infome sobre a higiene perineal e do meatro uretral")
    }else if(values.limiteCapacidadeBolsa == null){
      setMensagem("Infome sobre a higiene perineal e do meatro uretral")
    }else if(values.uretaIntegra == null){
      setMensagem("Infome sobre a capacidade da bolsa coletora ")
    }else if(values.uretaIntegraEspecificar == null){
      setMensagem( "Informe se a uretra está íntegra?")
    }else if(values.urinaAspectoNormal == null){
      setMensagem("Infome se a urina está com aspecto normal?")
    }else{  
    if (prontuario?.id) {
      // setMensagem("Teoricamente okay" + 
      //   "\n values.svn: " + values.svn+  "\n" +
      //   "\n values.sistemaDrenagem: " + values.sistemaDrenagem + "\n" +
      //   "\n values.bolsaColetora: " + values.bolsaColetora + "\n" +
      //   "\n values.fluxoUninario: " + values.fluxoUninario +"\n" +
      //   "\n values.cateterFixado: " + values.cateterFixado +"\n" +
      //   "\n values.realizadaHigiene: " + values.realizadaHigiene +"\n" +
      //   "\n values.limiteCapacidadeBolsa: " + values.limiteCapacidadeBolsa + "\n" +
      //   "\n values.uretaIntegra: " + values.uretaIntegra +"\n" +
      //   "\n values.uretaIntegraEspecificar: " + values.uretaIntegraEspecificar +"\n"+ 
      //   "\n values.urinaAspectoNormal: " + values.urinaAspectoNormal+"\n" +
      //   "\n values.uretaIntegraEspecificar: " + values.uretaIntegraEspecificar+"\n"  
      //   )
      setCarregando(true)
      prontuarioRegistroComplementarITUCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de ITU associado ao uso de SVD incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err) 
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    } 
  }
  }
  return (
    <div style={{ marginTop: '16px'}}>
      <Formik
        key={formKey}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
          <>
            <div style={{ fontWeight: '700' }}>Qual a necessidade da SVD?</div>
            <Row>
              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='BALANCO_HIBRIDO'
                  name='svn'
                  value={ItuNecessidadeEnum.BALANCO_HIBRIDO || null}
                  checked={values.svn === ItuNecessidadeEnum.BALANCO_HIBRIDO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="BALANCO_HIBRIDO">
                  Balanço hídrico
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='SEDACAO'
                  name='svn'
                  value={ItuNecessidadeEnum.SEDACAO}
                  checked={values.svn === ItuNecessidadeEnum.SEDACAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="SEDACAO">
                  Sedação
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='POS_OP'
                  name='svn'
                  value={ItuNecessidadeEnum.POS_OP}
                  checked={values.svn === ItuNecessidadeEnum.POS_OP}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="POS_OP">
                  Pós-op.
                </label>
              </Col>
              
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='OUTRO'
                  name='svn'
                  value={ItuNecessidadeEnum.OUTRO}
                  checked={values.svn === ItuNecessidadeEnum.OUTRO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="OUTRO">
                  Outro
                </label>
              </Col> 
              <Col md={3}>
                <div style={{marginBottom: '8px', display: 'flex'}}>
                  <label className="form-check-label form-label me-2" htmlFor='svnEspecificar'>
                    Qual:
                  </label>

                  <InputStyled
                    type='text'
                    className='form-input form-control w-100'
                    id='svnEspecificar'
                    name='svnEspecificar'
                    value={values.svnEspecificar || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <div style={{ fontWeight: '700' }}>Sistema de drenagem está mantido fechado?</div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='SIM'
                  name='sistemaDrenagem'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.sistemaDrenagem === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="SIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='NAO'
                  name='sistemaDrenagem'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.sistemaDrenagem === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}  />  
                <label className="form-check-label form-label mb-0" htmlFor="NAO">
                  Não
                </label>
              </Col> 
              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='NAO_APLICA'
                  name='sistemaDrenagem'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.sistemaDrenagem === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                /> 
                <label className="form-check-label form-label mb-0" htmlFor="NAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              A bolsa coletora está sendo mantida abaixo do nível da bexiga e 10 cm distante do chão?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='bolsaColetoraSim'
                  name='bolsaColetora'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.bolsaColetora === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                /> 
                <label className="form-check-label form-label mb-0" htmlFor="bolsaColetoraSim">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='bolsaColetoraNAO'
                  name='bolsaColetora'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.bolsaColetora === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                /> 
                <label className="form-check-label form-label mb-0" htmlFor="bolsaColetoraNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='bolsaColetoraNAO_APLICA'
                  name='bolsaColetora'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.bolsaColetora === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="bolsaColetoraNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>
            
            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O fluxo urinário está mantido livre, fixado adequadamente e com o sistema coletor sem dobras ou acotovelamentos?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='fluxoUninarioSIM'
                  name='fluxoUninario'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.fluxoUninario === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="fluxoUninarioSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='fluxoUninarioNAO'
                  name='fluxoUninario'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.fluxoUninario === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="fluxoUninarioNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='fluxoUninarioNAO_APLICA'
                  name='fluxoUninario'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.fluxoUninario === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="fluxoUninarioNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O cateter está fixado em local adequado e sem tração?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cateterFixadoSIM'
                  name='cateterFixado'
                  value={CateterLocalAdequaoEnum.SIM}
                  checked={values.cateterFixado === CateterLocalAdequaoEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cateterFixadoSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cateterFixadoNAO'
                  name='cateterFixado'
                  value={CateterLocalAdequaoEnum.NAO}
                  checked={values.cateterFixado === CateterLocalAdequaoEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cateterFixadoNAO">
                  NÃO
                </label>
              </Col>

              <Col md={3}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cateterFixadoSIM_APOS_ORIENTACOES'
                  name='cateterFixado'
                  value={CateterLocalAdequaoEnum.SIM_APOS_ORIENTACOES}
                  checked={values.cateterFixado === CateterLocalAdequaoEnum.SIM_APOS_ORIENTACOES}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cateterFixadoSIM_APOS_ORIENTACOES">
                  SIM APÓS ORIENTAÇÕES
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='cateterFixadoNAO_APLICA'
                  name='cateterFixado'
                  value={CateterLocalAdequaoEnum.NAO_APLICA}
                  checked={values.cateterFixado === CateterLocalAdequaoEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="cateterFixadoNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Foi realizada higiene perineal e do meatro uretral adequadamente nas últimas 24 h?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='realizadaHigieneSIM'
                  name='realizadaHigiene'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.realizadaHigiene === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="realizadaHigieneSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='realizadaHigieneNAO'
                  name='realizadaHigiene'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.realizadaHigiene === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="realizadaHigieneNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='realizadaHigieneNAO_APLICA'
                  name='realizadaHigiene'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.realizadaHigiene === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="realizadaHigieneNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O limite da capacidade da bolsa coletora está até 2/3 do seu volume?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='limiteCapacidadeBolsaSIM'
                  name='limiteCapacidadeBolsa'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.limiteCapacidadeBolsa === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="limiteCapacidadeBolsaSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='limiteCapacidadeBolsaNAO'
                  name='limiteCapacidadeBolsa'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.limiteCapacidadeBolsa === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="limiteCapacidadeBolsaNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='limiteCapacidadeBolsaNAO_APLICA'
                  name='limiteCapacidadeBolsa'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.limiteCapacidadeBolsa === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="limiteCapacidadeBolsaNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>A uretra está íntegra?</div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='uretaIntegraSIM'
                  name='uretaIntegra'
                  value={SimNaoNaoSimplesEnum.SIM}
                  checked={values.uretaIntegra === SimNaoNaoSimplesEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="uretaIntegraSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='uretaIntegraNAO'
                  name='uretaIntegra'
                  value={SimNaoNaoSimplesEnum.NAO}
                  checked={values.uretaIntegra === SimNaoNaoSimplesEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="uretaIntegraNAO">
                  NÃO
                </label>
              </Col>

              <Col md={3}>
                <div style={{marginBottom: '8px', display: 'flex'}}>
                  <label className="form-check-label form-label me-2" htmlFor='uretaIntegraEspecificar'>
                    Especificar:
                  </label>

                  <InputStyled
                    type='text'
                    className='form-input form-control w-100'
                    id='uretaIntegraEspecificar'
                    name='uretaIntegraEspecificar'
                    value={values.uretaIntegraEspecificar || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <div style={{ fontWeight: '700' }}>A urina está com aspecto normal?</div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='urinaAspectoNormalSIM'
                  name='urinaAspectoNormal'
                  value={SimNaoNaoSimplesEnum.SIM}
                  checked={values.urinaAspectoNormal === SimNaoNaoSimplesEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="urinaAspectoNormalSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='urinaAspectoNormalNAO'
                  name='urinaAspectoNormal'
                  value={SimNaoNaoSimplesEnum.NAO}
                  checked={values.urinaAspectoNormal === SimNaoNaoSimplesEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="urinaAspectoNormalNAO">
                  NÃO
                </label>
              </Col>

              <Col md={3}>
                <div style={{marginBottom: '8px', display: 'flex'}}>
                  <label className="form-check-label form-label me-2" htmlFor='urinaAspectoEspecificar'>
                    Especificar:
                  </label>

                  <InputStyled
                    type='text'
                    className='form-input form-control w-100'
                    id='urinaAspectoEspecificar'
                    name='urinaAspectoEspecificar'
                    value={values.urinaAspectoEspecificar || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
              { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button onClick={() =>  {  
                            submitForm() 
                      }}  style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar 
                        </div>
                      </Button>
                    </div>
                  )}
              </Col>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
          </>
        )}
      </Formik>
    </div>
  )
}

export { RegistroComplementarITU }
export default RegistroComplementarITU
