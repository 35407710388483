import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import { MdOutlineOpenInNew } from 'react-icons/md'
import { GiFalling } from 'react-icons/gi'

import Button from '../../../../../components/Button'
import { RiscoQuedaForm } from './RiscoQuedaForm'
import styled from 'styled-components'

export interface IRiscoQuedaForm {
  historicoQueda: boolean | null;
  diagnosticoSecundario: boolean | null;
  terapiaEndovenosa: boolean | null;
  auxilioDeambulacao: string | null;
  marcha: string | null;
  estadoMental: string | null;
}

interface IRiscoQuedaProps {
  aoFinalizar: (dados: IRiscoQuedaForm) => void,
  valoresIniciais: IRiscoQuedaForm
}

export const calcularRiscoQueda = (
  historicoQueda: boolean | null,
  diagnosticoSecundario: boolean | null,
  auxilioDeambulacao: string | null,
  terapiaEndovenosa: boolean | null,
  marcha: string | null,
  estadoMental: string | null,
) => {
  let total: number = 0
  if (historicoQueda) total += 25
  if (diagnosticoSecundario) total += 15
  if (auxilioDeambulacao) total += Number(auxilioDeambulacao)
  if (terapiaEndovenosa) total += 15
  if (marcha) total += Number(marcha)
  if (estadoMental) total += Number(estadoMental)

  return total
}

export const getRisco = (riscoQueda: number) => {
  if (riscoQueda > 24 && riscoQueda < 45) return 'Médio'
  if (riscoQueda > 44) return 'Alto'
  return 'Baixo'
}

const DivSelectStyled = styled.div `
  background-color: #FFFFFF;
  width: 100%;
  color: #000;
  border: 1px solid #ced4da;
  padding: 12px 14px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

const RiscoQueda = (props: IRiscoQuedaProps) => {
  const [modalCCPOTAberto, setModalCCPOTAberto] = useState<boolean>(false)
  const [riscoQueda, setRiscoQueda] = useState<number>(0)

  const initialValues: IRiscoQuedaForm = {
    historicoQueda: props.valoresIniciais.historicoQueda || null,
    diagnosticoSecundario: props.valoresIniciais.diagnosticoSecundario || null,
    auxilioDeambulacao: props.valoresIniciais.auxilioDeambulacao || null,
    terapiaEndovenosa: props.valoresIniciais.terapiaEndovenosa || null,
    marcha: props.valoresIniciais.marcha || null,
    estadoMental: props.valoresIniciais.estadoMental || null,
  }

  const handleSubmit = (values: IRiscoQuedaForm) => {
    props.aoFinalizar(values)
    setModalCCPOTAberto(false)
  }

  useEffect(() => {
    setRiscoQueda(
      calcularRiscoQueda(
        props.valoresIniciais.historicoQueda,
        props.valoresIniciais.diagnosticoSecundario,
        props.valoresIniciais.auxilioDeambulacao,
        props.valoresIniciais.terapiaEndovenosa,
        props.valoresIniciais.marcha,
        props.valoresIniciais.estadoMental,
      )
    )
  }, [props.valoresIniciais])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DivSelectStyled onClick={() => setModalCCPOTAberto(true)}>
          {riscoQueda > 0 ? (<>{getRisco(riscoQueda)}</>) : (<div />)}
          <MdOutlineOpenInNew size={22}/>
        </DivSelectStyled>
        {riscoQueda > 24 && (<GiFalling color='#FD2424' style={{ marginLeft: '8px' }} size={26}/>)}
      </div>

      <Modal show={modalCCPOTAberto} onHide={() => setModalCCPOTAberto(false)} size='lg'>
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Risco de queda
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ submitForm }) => (
            <>
              <Modal.Body>
                <RiscoQuedaForm riscoQueda={riscoQueda} setRiscoQueda={(novoValor) => setRiscoQueda(novoValor)} />
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => submitForm()}>Confirmar</Button>
                <Button variant='secondary' style={{ marginLeft: '12px' }} onClick={() => setModalCCPOTAberto(false)}>Cancelar</Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export { RiscoQueda }
export default RiscoQueda
