import { useEffect, useState } from 'react'
import { PDFViewer, Document, View, Page, Text, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'
import { format } from 'date-fns'
import logo         from '../../../../../../../images/sol_logo.png'
import logoHospital from '../../../../../../../images/logohospitaltelecila.jpg'
import alergia from '../../../../../../../images/alergia.png'
import isolamento from '../../../../../../../images/isolamentoPreto.png'
import lesao from '../../../../../../../images/lesao.png'
import ocular from '../../../../../../../images/ocular.png'
import queda from '../../../../../../../images/queda.png'
import { useAuth } from '../../../../../../../context/auth_provider/useAuth'
import { IPlanoCuidadosRelatorio } from '../../../../../../../api/relatorios/types'
import { relatorioPlanoCuidados } from '../../../../../../../api/relatorios/relatoriosAPI'
import Button from '../../../../../../../components/Button'
import { isDesktop } from 'react-device-detect'
import { IUser } from '../../../../../../../context/auth_provider/types'


const styles = StyleSheet.create({
  dataHeader: {
    position: 'absolute',
    top: 2,
    textAlign: 'right',
    right: 5,
    fontSize: 10,
  },

  HeaderStyled: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'row'
  },

  ContainerStyled: {
    border: '1px solid #ddd',
    borderRadius: 5,
    padding: '15 10 10',
    position: 'relative',
    margin: '20 20',
  },

  LabelStyled: {
    fontSize: 10,
    fontWeight: 400,
  },
  
  ValueStyled: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 8,
  },
  ImagesStyle: {
    position: "absolute",
    top: 12,
    left: 16,
    height: 50,
    width: 60,
    marginLeft: 15,
  },
  ImagesStyle2: {
    position: 'absolute',
    top: 4,
    right: 16,
    height: 50,
    width: 50,
    marginRight: 15,
  },

  Sumario: {
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  SumarioStyled: {
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
  },

  DadosSumarioStyled: {
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: 0,
  },

  TituloStyled: {
    position: 'absolute',
    top: -8,
    left: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: '#fff',
    fontSize: 12,
    fontWeight: 700,
  }
})

interface IPlanoCuidadosRelatorioCamposProps {
  idPaciente: number,
}

interface IDocumentProps {
  dadosRelatorio?: IPlanoCuidadosRelatorio,
  user: IUser,
}

const Relatorio = (props: IDocumentProps) => {
  console.log(props.dadosRelatorio)
  return (
    <Document>
      {props.dadosRelatorio && (
        <Page size='A4' style={{ paddingBottom: '50px' }}>
          <View fixed>
            <Text style={styles.dataHeader}>
              {format((new Date()), 'dd/MM/yyyy hh:mm:ss')}
            </Text> 
            <View style={styles.HeaderStyled}>
            <Image src={require("../../../../../../../images/solamarelo.png")}  style={styles.ImagesStyle} cache />   
              <View style={[styles.Sumario, {flex:1, justifyContent: 'center', alignItems: 'center'}]}>
                <Text style={styles.SumarioStyled}>
                  {(props.dadosRelatorio.dhPlanoCuidado) ? 'PLANO DE CUIDADOS DE ENF.' : 'ADMISSÃO DE ENFERMAGEM'}
                </Text>
                <Text style={styles.DadosSumarioStyled}>{props.user?.enderecoEmpresa || '-'}</Text>
                <Text style={styles.DadosSumarioStyled}>{props.user?.contatoEmpresa || '-'}</Text>

                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                  <View style={{ ...styles.ContainerStyled, marginRight: 0, marginLeft: 8, marginBottom: 0 }}>
                    <Text style={{ ...styles.TituloStyled, color: '#C4C4C4' }}>Riscos</Text>

                    <View style={{ display: 'flex', flexDirection: 'row', minWidth: 80 }}>
                       {props.dadosRelatorio.riscoLesaoPressao && (
                        <Image src={require("../../../../../../../images/lesao.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoRessecamentoOcular && (
                        <Image src={require("../../../../../../../images/ocular.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoAlergia && (
                        <Image src={require("../../../../../../../images/alergia.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoQueda && (
                        <Image src={require("../../../../../../../images/queda.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )}

                      {props.dadosRelatorio.riscoContagio && (
                        <Image src={require("../../../../../../../images/isolamento.png")} style={{ width:15,height:15, marginRight: '10px' }} />
                      )} 
                    </View>
                  </View>
                </View>
              </View>            
              <Image src={require("../../../../../../../images/logohospitaltelecila.png")} style={styles.ImagesStyle2} />
            </View>

            <View style={styles.ContainerStyled}>
              <Text style={styles.TituloStyled}>Paciente</Text>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={{ fontSize: '14px' }}>{props.dadosRelatorio.paciente.nome}</Text>
                <Text style={{ fontSize: '10px' }}>Idade: {props.dadosRelatorio.paciente.idade || ''}</Text>
                <Text style={{ fontSize: '10px' }}>Contato: {props.dadosRelatorio.paciente.contato || ''}</Text>
              </View>

              <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '4px'}}>
                <Text style={{ fontSize: '10px' }}>
                  Data de admissão: {props.dadosRelatorio.dhAdmissao ? format((new Date(props.dadosRelatorio.dhAdmissao)), 'dd/MM/yyyy hh:mm') : ''}
                </Text>
                <Text style={{ fontSize: '10px' }}>Dias de internação: {props.dadosRelatorio.diasInternacao}</Text>
                <Text style={{ fontSize: '10px' }}>Prontuário: {props.dadosRelatorio.id}</Text>
              </View>
            </View>
          </View>
            
          {props.dadosRelatorio.prontuarioPlanoCuidado.map(planoCuidado => (
            <View style={{ ...styles.ContainerStyled, marginTop: '0px' }}>
              <View>
                <Text style={styles.LabelStyled}>
                  Diagnóstico de enfermagem:
                </Text>

                <Text style={styles.ValueStyled}>
                  {planoCuidado.diagnostico.descricao}
                </Text>
              </View>

              <View>
                <Text style={{ ...styles.LabelStyled, marginBottom: '8px' }}>
                  Resultado(s) de enfermagem:
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px'}}>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Descrição</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Indicador</Text>
                  <Text style={{...styles.LabelStyled, width: '100px', textAlign: 'center'}}>Padrão</Text>
                  <Text style={{...styles.LabelStyled, width: '100px', textAlign: 'center'}}>Registrado</Text>
                </View>
                {planoCuidado.prontuarioPlanoCuidadoResultado.map(dado => (
                  <View style={{ display: 'flex', flexDirection: 'row'}} key={dado.id}>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.resultado.descricao}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.indicador.nome}</Text>
                    <Text style={{...styles.ValueStyled, width: '100px', textAlign: 'center'}}>{dado.esperado}</Text>
                    <Text style={{...styles.ValueStyled, width: '100px', textAlign: 'center'}}>{dado.valor}</Text>
                  </View>
                ))}
              </View>

              <View>
                <Text style={{ ...styles.LabelStyled, marginBottom: '8px', marginTop: '12px' }}>
                  Intervenção(ões) de enfermagem:
                </Text>

                <View style={{ display: 'flex', flexDirection: 'row', marginBottom: '4px'}}>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Intervenção</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Atividade</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Aprazamento</Text>
                  <Text style={{...styles.LabelStyled, width: '200px', textAlign: 'center'}}>Registros/OBS</Text>
                </View>
                {planoCuidado.prontuarioPlanoCuidadoAtividade.map(dado => (
                  <View style={{ display: 'flex', flexDirection: 'row'}} key={dado.id}>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.intervencao.nome}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px'}}>{dado.atividade.descricao}</Text>
                    <Text style={{...styles.ValueStyled, width: '200px', textAlign: 'center'}}>{dado.aprazamento.nome}</Text>
                    <Text style={{ width: '200px' }} />
                  </View>
                ))}
              </View>
            </View>
          ))}

          <Text style={{ textAlign: 'center', marginBottom: 4 }}>
            _______________________________
          </Text>
          <Text style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', marginBottom: 4 }}>
            {props.user.nome || ''}
          </Text>

          <Text style={{ textAlign: 'center', marginBottom: 0, fontSize: 10, fontWeight: 400 }}>
            Assinatura/Carimbo
          </Text>
        </Page>
      )}
    </Document>
  )
}

const PlanoCuidadosRelatorioCampos = (props: IPlanoCuidadosRelatorioCamposProps) => {
  const [dadosRelatorio, setDadosRelatorio] = useState<IPlanoCuidadosRelatorio>()
  const { user } = useAuth()

  const getDadosRelatorio = () => {
    relatorioPlanoCuidados(props.idPaciente)
      .then(resultado => setDadosRelatorio(resultado))
      .catch(console.error)
  }

  useEffect(() => {
    getDadosRelatorio()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.idPaciente])

  return (
    <>
      <Button variant='new' style={{ marginBottom: '16px' }}>
        <PDFDownloadLink
          document={
            <Relatorio dadosRelatorio={dadosRelatorio} user={user} />
          }
          fileName="rel-plano-cuidados.pdf"
          style={{ textDecoration: 'none', color: '#fff' }}
        >
          {({ loading }) =>
            loading ? 'Gerando seu documento...' : 'Fazer download do relatório de plano de cuidados!'
          }
        </PDFDownloadLink>
      </Button>

      {isDesktop && (
        <PDFViewer width='100%' height='80%' showToolbar>
          <Relatorio dadosRelatorio={dadosRelatorio} user={user} />
        </PDFViewer>
      )}
    </>
  )
}

export {PlanoCuidadosRelatorioCampos }
export default PlanoCuidadosRelatorioCampos
