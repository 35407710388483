import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IAtividadeListagem } from '../../../../api/cadastro/atividadesAPI';
import { atividadeListarAPI } from '../../../../api/cadastro/atividadesAPI';

interface TAtividadesContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  atividades: IAtividadeListagem[],
  setAtividades: (newAtividades: IAtividadeListagem[]) => void,
  selecionado: IAtividadeListagem | null,
  setSelecionado: (novoSelecionado: IAtividadeListagem | null) => void,
  loadAtividades: () => void
}

interface TAtividadesProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  atividades: [],
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  setAtividades: () => {
    throw new Error('setAtividades não implementada');
  },
  loadAtividades: () => {
    throw new Error('loadAtividades não implementada');
  },
};

export const AtividadesContext = createContext<TAtividadesContext>(defaultState);

export const AtividadesProvider = ({children}: TAtividadesProviderProps) => {
  const [atividades, setAtividades] = useState<IAtividadeListagem[]>(defaultState.atividades);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IAtividadeListagem | null>(defaultState.selecionado)

  const loadAtividades = () => {
    setCarregando(true)
    atividadeListarAPI()
        .then((data) => setAtividades(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadAtividades();
  }, []);

  const providerValues: TAtividadesContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    atividades,
    setAtividades,
    loadAtividades,
  };

  return (
    <AtividadesContext.Provider value={providerValues}>
      {children}
    </AtividadesContext.Provider>
  );
};
