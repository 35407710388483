import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { ResultadosProvider } from './context';
import { ResultadosContainerForm } from './ResultadosContainerForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../api/prontuario/types';

export interface IResultadosForm {
  descricao: string,
  valorPadrao: number,
  inferido: boolean,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

const Resultados = () => {
  return (
    <ResultadosProvider>
      <DivMainStyled>
        <DivCardStyled>
          <ResultadosContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </ResultadosProvider>
  )
}

export { Resultados }
export default Resultados
