import { useContext, useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'

import { DashboardContext } from '../../context'
import { IDashboard, getDashboard } from '../../../../api/dashboard'
import { opcoesDashboard } from './opcoesDashboard'
import { TableStyled } from '../../../../components/styles'

const DashboardDados = () => {
  const dashboardGrande = ['diagnosticoenfermagem', 'utilizacaovm']
  const dashboardNovaTabela = [
    'diagnosticoenfermagem', 'intervencoesenfermagem', 'resultadoenfermagem',
    'efetividadeprevencaolpp',
    'utilizacaocvc',
    'utilizacaosvd',
    'utilizacaosne',
    'riscoquedas',
    'riscolpp',
    'riscoressecamento',
    'taxaalergia',
    'taxaisolamento',
    'taxaeventosadversos'
  ]
  
  const { filtros, setCarregando, selecionado } = useContext(DashboardContext)
  const [dadosDashboard, setDadosDashboard] = useState<IDashboard>()

  const valPadrao = "Os dados serão agrupados por dia ou por meses do ano dependendo do filtro escolhido";

  useEffect(() => {
    const buscarDados = () => {
      if (selecionado) {
        setCarregando(true)
        getDashboard(selecionado, filtros)
          .then(dados => setDadosDashboard(dados))
          .catch(err => {
            console.error(err)
            setDadosDashboard(undefined)
          })
          .finally(() => setCarregando(false))
      }
    }

    buscarDados()
  }, [selecionado, filtros])
  
  const options = {
    chart: {
      title: `${selecionado ? opcoesDashboard.filter(item => item.rota === selecionado)[0]?.titulo : ''}`,
      subtitle: `${selecionado ? opcoesDashboard.filter(item => item.rota === selecionado)[0]?.subTitulo : valPadrao}`,
    },
    legend: 'none', // Remove a legenda do gráfico
  };

  
  return (
    <div style={{ marginTop: '26px', width: '100%' }}>
      {(dadosDashboard && dadosDashboard.grafico.length > 1) ? (
        <Chart
          chartType="Bar"
          width="100%"
          height={dashboardGrande.includes(selecionado || '') ? "400px" : "400px"}
          style={{ background: '#212529' }}
          loader={<div>Carregando dados</div>}
          data={dadosDashboard?.grafico}
          options={options}
        />
      ) : ('Não há dados para exibição com os filtros selecionados!')}

      {dadosDashboard && dadosDashboard.tabela && (
        <>
          {dashboardNovaTabela.includes(selecionado || '')
          ? (
            <TableStyled style={{ marginTop: '40px' }} menor>
              <thead>
                <tr style={{ fontSize: '10px' }}>
                  <th style={{ textAlign: 'center'}} >Descrição</th>
                  <th style={{ textAlign: 'center'}} >Ano</th>
                  <th style={{ textAlign: 'center'}} >Total pacientes</th>
                  <th style={{ textAlign: 'center'}} >JAN Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >FEV Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >MAR Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >ABR Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >MAI Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >JUN Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >JUL Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >AGO Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >SET Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >OUT Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >NOV Absoluto/percentual</th>
                  <th style={{ textAlign: 'center'}} >DEZ Absoluto/percentual</th>
                </tr>
              </thead>  

              <tbody>
                {dadosDashboard.tabela.map((dado, indice) => (
                  <tr key={indice} style={{ fontSize: '12px' }}>
                    <td>{dado.descricao}</td>
                    <td>{dado.ano}</td>
                    <td>{dado.pacientes}</td>
                    <td>{dado.JAN}</td>
                    <td>{dado.FEV}</td>
                    <td>{dado.MAR}</td>
                    <td>{dado.ABR}</td>
                    <td>{dado.MAI}</td>
                    <td>{dado.JUN}</td>
                    <td>{dado.JUL}</td>
                    <td>{dado.AGO}</td>
                    <td>{dado.SET}</td>
                    <td>{dado.OUT}</td>
                    <td>{dado.NOV}</td>
                    <td>{dado.DEZ}</td>
                  </tr>
                ))}
              </tbody>
            </TableStyled>
          ) : (
            <TableStyled style={{ marginTop: '40px' }}>
              <thead>
                <tr>
                  <th>Data/Período</th>
                  <th>Descrição</th>
                  {
                  selecionado &&
                  opcoesDashboard.filter(item => item.rota === selecionado)[0]?.soPercentual
                  ? <th>Percentual</th>
                  : <th>Valor</th>
                }
                </tr>
              </thead>  

              <tbody>
                {dadosDashboard.tabela.map((dado, indice) => (
                  <tr key={indice}>
                    <td>{dado.periodo}</td>
                    <td>{dado.descricao}</td>
                    <td>{dado.valor}</td>
                  </tr>
                ))}
              </tbody>
            </TableStyled>
          )}
        </>
      )}
    </div>
  )
}

export { DashboardDados }
export default DashboardDados
