import React, { FormEvent, useState } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { HiOutlineTrash } from 'react-icons/hi'

import { ILesaoForm, IProntuarioIntegridadeFisicaForm } from '../../../../../../api/prontuario/types'
import { InputStyled, TableStyled } from '../../../../../../components/styles'
import { SelectAsync } from '../../../../../../components/select-async'
import { getAllToSelectLesaoAPI } from '../../../../../../api/cadastro/lesoesAPI'
import AvaliacaoLesao from './AvaliacaoLesao'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`

const Lesoes = () => {
  const [ liberaGrau, setLiberaGrau] = useState(false)
  const [ mensagem,   setMensagem]   = useState("")
  const [lesaoLocalDesc, setLesaoLocalDesc] = useState<ILesaoForm>({
    id: (Math.random() * (-1)),
    idTipoLesao: -1,
    grau: '',
    descricao: '',
    avaliacao: {
      avaliacao: '',
      tamanhoAproximado: null,
      aspecto: null,
      aspectoOutro: null,
      quantidade: null,
      odor: null,
      aspectoBorda: null,
      aspectoLeito: null,
      sinaisFlogisticos: null,
      higieneLesao: null,
      higieneLesaoOutro: null,
      coberturaPrimaria: null,
      coberturaSecundaria: null,

      fotos: []
    }
  })

  const { values, setFieldValue } = useFormikContext<IProntuarioIntegridadeFisicaForm>()

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setLesaoLocalDesc(prev => ({
      ...prev,
      grau: value
    }));
  };

  const handleInserirLesaoLocalDesc = () => {
    if (lesaoLocalDesc.idTipoLesao !== -1) {
      const novoValor: ILesaoForm[] = values.lesoes
      novoValor.push(lesaoLocalDesc) 
      setFieldValue('lesoes', novoValor, false)
      setLesaoLocalDesc({
        id: (Math.random() * (-1)),
        idTipoLesao: -1,
        grau: '',
        descricao: '',
        avaliacao: {
          avaliacao: '',
          tamanhoAproximado: null,
          aspecto: null,
          aspectoOutro: null,
          quantidade: null,
          odor: null,
          aspectoBorda: null,
          aspectoLeito: null,
          sinaisFlogisticos: null,
          higieneLesao: null,
          higieneLesaoOutro: null,
          coberturaPrimaria: null,
          coberturaSecundaria: null,
    
          fotos: []
        }
      })
    }
  }

  const removerAptidao = (id: number) => {
    const novoValor: ILesaoForm[] = values.lesoes.filter(i => i.id !== id)
    setFieldValue('lesoes', novoValor, false)
  }

  return (
    <div>
      <RowStyled>
        <Col md={5}>
          <SelectAsync
            id='idTipoLesao'
            label='Tipo de lesão'
            emptyLabel='Selecione...'
            styleLabel={{ color: '#fff' }}
            value={lesaoLocalDesc.idTipoLesao || -1}
            onChange={(e) => {   
              if(e.currentTarget?.value == "-1" ){ 
                setLiberaGrau(false)
              }else{
                setLiberaGrau(true)
              }

              const valor = e.currentTarget?.value
              const label = e.currentTarget?.selectedOptions[0].textContent
              setLesaoLocalDesc(prev => ({
                ...prev,
                idTipoLesao: valor ? Number(valor) : -1,
                descricao: label || ''
              }))
            }}
            api={getAllToSelectLesaoAPI}
          />
        </Col>

        <Col md={5}>
          <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
            <label className="form-check-label form-label" htmlFor='grau'>
              Grau/Local
            </label>
            <InputStyled
              style={{ width: '100%' }}
              id='grau'
              name='grau'
              value={lesaoLocalDesc.grau}
              onChange={e => handleInputOnChange(e)}
              disabled={!liberaGrau}
            />
          </div>
        </Col>

        <Col md={2}>
      
          <MdFormatListBulletedAdd
            color='#fff'
            size={40}
            style={{ marginTop: '32px', cursor: 'pointer' }}
            onClick={() =>{
              if(lesaoLocalDesc.idTipoLesao != -1) {
                setLiberaGrau(false)
                handleInserirLesaoLocalDesc()
              }
              else{
                setMensagem("Inseri o tipo de lesão")
              }
            }}
              
          />
        </Col>
      </RowStyled>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <label htmlFor="" style={{padding: "8px", color: "red", textAlign: "center", fontFamily:'initial', fontWeight:"700" , fontSize: "18px"}}>
                  {mensagem}
                </label>
            </div>
      {values.lesoes && values.lesoes.length > 0 && (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.lesoes.map((lesao, index) => (
              <tr key={index}>
                <td style={{ width: '1px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={22}
                    style={{ cursor: 'pointer' }}
                    onClick={() => removerAptidao(lesao.id)}
                  />
                </td>
                <td>{lesao.descricao}</td>
                <td>{lesao.grau}</td>
                <td style={{ width: '1px' }}>
                  <AvaliacaoLesao
                    idTipoLesao={lesao.idTipoLesao}
                    indice={index}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      )}

    </div>
  )
}

export { Lesoes }
export default Lesoes
