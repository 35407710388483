import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IEdemaListagem } from '../../../../api/cadastro/edemasAPI';
import { edemaListarAPI } from '../../../../api/cadastro/edemasAPI';

interface TEdemasContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  edemas: IEdemaListagem[],
  setEdemas: (newEdemas: IEdemaListagem[]) => void,
  selecionado: IEdemaListagem | null,
  setSelecionado: (novoSelecionado: IEdemaListagem | null) => void,
  loadEdemas: () => void
}

interface TEdemasProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  edemas: [],
  setEdemas: () => {
    throw new Error('setEdemas não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
  loadEdemas: () => {
    throw new Error('loadEdemas não implementada');
  },
};

export const EdemasContext = createContext<TEdemasContext>(defaultState);

export const EdemasProvider = ({children}: TEdemasProviderProps) => {
  const [edemas, setEdemas] = useState<IEdemaListagem[]>(defaultState.edemas);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IEdemaListagem | null>(defaultState.selecionado)

  const loadEdemas = () => {
    setCarregando(true)
    edemaListarAPI()
        .then((data) => setEdemas(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadEdemas();
  }, []);

  const providerValues: TEdemasContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    edemas,
    setEdemas,
    loadEdemas,
  };

  return (
    <EdemasContext.Provider value={providerValues}>
      {children}
    </EdemasContext.Provider>
  );
};
