import React from 'react'
import { createContext, useState } from 'react'

import { loginRequest } from '../../api/loginAPI'
import { IAuthProvider, IContext, IUser } from './types'
import { getUserLocalStorage, setUserLocalStorage } from './util'

export const AuthContext = createContext<IContext>({} as IContext)

export const AuthProvider = ({children}: IAuthProvider) => {
  const userLS = getUserLocalStorage()
  const [user, setUser] = useState<IUser>(userLS)

  const authenticate = async (email: string, password: string) => {
    const response = await loginRequest(email, password)
    setUser(response)
    setUserLocalStorage(response)
  }

  const logout = () => {
    setUserLocalStorage(null)
  }

  return (
    <AuthContext.Provider value={{ user, authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
