import React, { useContext } from 'react'
import { Alert} from 'react-bootstrap';

import { DiagnosticosContext } from '../context';
import { parseGrupoPlanoCuidado } from '../../../pacientes/prontuario/components/plano_cuidados/util';
import { TableContainer, TableStyled } from '../../../../components/styles';

const DiagnosticosListagem = () => {
  const { diagnosticos, selecionado, setSelecionado } = useContext(DiagnosticosContext);

  return (
    <>
      {diagnosticos && diagnosticos.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Diagnóstico de enfermagem.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {diagnosticos && diagnosticos.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Grupo</th>
                <th style={{ width: 100 }}>Inferido</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {diagnosticos && diagnosticos.length > 0 && diagnosticos.map((diagnostico => (
                <tr
                  key={diagnostico.id}
                  style={{ border: selecionado?.id === diagnostico.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(diagnostico)}
                >
                  <td>{diagnostico.descricao}</td>
                  <td>{!!diagnostico.grupo && diagnostico.grupo !== -1 ? parseGrupoPlanoCuidado(diagnostico.grupo) : ''}</td>
                  <td>{diagnostico.inferido ? 'Sim' : 'Não'}</td>
                  <td>{diagnostico.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { DiagnosticosListagem }
export default DiagnosticosListagem
