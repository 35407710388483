import { IProntuarioComunicacaoEducacaoListagem, IProntuarioComunicacaoEducacaoListagemBackend } from "../types";

export const parseProntuarioComunicacaoEducacaoBackToFront = (dadosBackend: IProntuarioComunicacaoEducacaoListagemBackend): IProntuarioComunicacaoEducacaoListagem => ({
  id: dadosBackend.id,
  comunicacao: dadosBackend.comunicacao,
  conheceProblemaSaude: dadosBackend.conhece_problema_saude,
  afonia: dadosBackend.afonia,
  dislalia: dadosBackend.dislalia,
  disartria: dadosBackend.disartria,
  disfasia: dadosBackend.disfasia,
  afasia: dadosBackend.afasia,
  semAlteracao: dadosBackend.sem_alteracao,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
