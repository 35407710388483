import styled from 'styled-components';

export const CheckboxStyled = styled.input`
  height: 25px;
  background-color: #212529;
  color: #ffffff;
  border: 1px solid #ced4da;

  :focus {
    background-color: #212529;
    border: 1px solid #ced4da;
  }

  :checked {
    background-color: #212529;
    border: 1px solid #ced4da;
  }
`;
