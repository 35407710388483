import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { LeitosProvider } from './context';
import { LeitosContainerForm } from './LeitosContainerForm';

export interface ILeitosForm {
  nome: string,
  ativo: boolean,
}

const Leitos = () => {
  return (
    <LeitosProvider>
      <DivMainStyled>
        <DivCardStyled>
          <LeitosContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </LeitosProvider>
  )
}

export { Leitos }
export default Leitos
