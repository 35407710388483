import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { FaProcedures, FaUserNurse, FaUserTie } from 'react-icons/fa';
import { RiNurseFill } from 'react-icons/ri';

import { ProfissionaisContext } from '../context';
import { ProfissionalEnum } from '../../../api/profissionais';
import { ProfissionaisModalForm } from './ProfissionaisModalForm';
import { HiOutlineSun } from 'react-icons/hi';
import { MdAlarm } from 'react-icons/md';

interface IDivCardProps {
  abriuProntuarioHoje: boolean,
}

const DivCardStyled = styled.div <IDivCardProps>`
  background-color: ${(props: IDivCardProps) => props.abriuProntuarioHoje ? '#fff': '#FD2424'};
  padding: 5px 16px;
  margin-bottom: 8px;
  color: ${(props: IDivCardProps) => props.abriuProntuarioHoje ? '#304439': '#fff'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px; 
`

const ProfissionaisListagem = () => {
  const [keyTabela, setKeyTabela] = useState<number>(0)
  const { profissionais } = useContext(ProfissionaisContext);

  useEffect(() => {
    setKeyTabela((prev) => prev + 1)
  }, [profissionais])

  const getUsuarioIcone = (tipo: ProfissionalEnum) => {
    if (tipo === ProfissionalEnum.GESTOR) {
      return (<FaUserTie size={50} />)
    } else if (tipo === ProfissionalEnum.ENFERMEIRO) {
      return (<FaUserNurse size={50} />)
    }

    return (<RiNurseFill size={50} />)
  }

  const getTipo = (tipo: ProfissionalEnum) => {
    if (tipo === ProfissionalEnum.GESTOR) {
      return 'Gestor'
    } else if (tipo === ProfissionalEnum.ENFERMEIRO) {
      return 'Enferm.'
    }

    return 'Técnic.'
  }

  return (
    <div key={keyTabela} style={{ width: '595px', margin: 'auto', marginBottom: '80px' , borderRadius: "12px"  }}>
      {profissionais && profissionais.length > 0 && profissionais.map(profissional => (
        <DivCardStyled key={profissional.id} abriuProntuarioHoje={profissional.abriuProntuarioHoje} >
          <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
            <div style={{ marginRight: '15px' }}>
              {profissional.urlAvatar ? (
                <img src={profissional.urlAvatar} alt='Imagem do profissional' height={50} width={50} />
              ) : getUsuarioIcone(profissional.tipo)}
              <div>{getTipo(profissional.tipo)}</div>
            </div>

            <div>
              <div style={{ fontWeight: '700', fontSize: '18px', lineHeight: '20px'}}>{profissional.nome}</div>
              <div>{profissional.contato || '(xx) xxxxx-xxxx'}</div>
              <div>{profissional.email}</div>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '18px' }}>
              <FaProcedures color={`${profissional.acessoUti ? '#304439' : '#e2c8a1'}`} size={30} />
            </div>

            <div style={{ marginRight: '18px', display: 'flex', alignItems: 'center' }}>
              <div style={{
                color: '#304439',
                fontSize: '18px',
                fontWeight: '700',
                lineHeight: '20px',
                letterSpacing: '0px',
                marginRight: '2px'
                }}
              >
                {profissional.pontos || 0}
              </div>

              <div>
                <HiOutlineSun color='#304439' size={33} />
              </div>
            </div>
            <div style={{ marginRight: '18px' }}>
              <MdAlarm color={profissional.bateuPontoHoje ? '#304439' : '#e0da13'} size={30} />
            </div>
            <div>
              <ProfissionaisModalForm modoEdicao profissional={profissional} />
            </div>
          </div>
        </DivCardStyled>
      ))}
    </div>
  )
}

export { ProfissionaisListagem }
export default ProfissionaisListagem
