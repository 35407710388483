/* eslint-disable max-len */
import React from 'react';
import './logo2Css.css';

export const Logo2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="18.6579mm"
      height="26.9865mm"
      version="1.1"
      viewBox="0 0 1849.48 2675.06"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        width: '50px',
        height: '50px',
      }}
    >
      <defs>
        <clipPath id="id0">
          <path d="M906.76 722.35c349.68,0 633.15,283.47 633.15,633.16 0,349.68 -283.47,633.15 -633.15,633.15 -349.68,0 -633.15,-283.47 -633.15,-633.15 0,-349.69 283.47,-633.16 633.15,-633.16zm0 94.49c297.5,0 538.67,241.17 538.67,538.67 0,297.5 -241.17,538.66 -538.67,538.66 -297.5,0 -538.67,-241.16 -538.67,-538.66 0,-297.5 241.17,-538.67 538.67,-538.67z"/>
        </clipPath>
        <clipPath id="id1">
          <path d="M906.76 767.11c324.96,0 588.39,263.44 588.39,588.4 0,324.95 -263.43,588.39 -588.39,588.39 -324.96,0 -588.39,-263.44 -588.39,-588.39 0,-324.96 263.43,-588.4 588.39,-588.4zm0 49.73c297.5,0 538.67,241.17 538.67,538.67 0,297.5 -241.17,538.66 -538.67,538.66 -297.5,0 -538.67,-241.16 -538.67,-538.66 0,-297.5 241.17,-538.67 538.67,-538.67z"/>
        </clipPath>
        <clipPath id="id2">
          <path d="M906.76 816.84c297.5,0 538.67,241.17 538.67,538.67 0,297.5 -241.17,538.66 -538.67,538.66 -297.5,0 -538.67,-241.16 -538.67,-538.66 0,-297.5 241.17,-538.67 538.67,-538.67z"/>
        </clipPath>
        <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="-0" y1="1337.54" x2="1849.48" y2="1337.54">
          <stop offset="0" style={{stopOpacity: '1', stopColor: '#4E6187'}}/>
          <stop offset="1" style={{stopOpacity: '1', stopColor: '#243963'}}/>
        </linearGradient>
        <linearGradient id="id4" gradientUnits="userSpaceOnUse" xlinkHref="#id3" x1="462.56" y1="1355.51" x2="1350.95" y2="1355.51">
        </linearGradient>
      </defs>
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2111861223968">
          <rect className="fil0" x="-0" y="0" width="1849.48" height="2675.06"/>
          <path className="fil1" d="M906.76 627.87c401.86,0 727.63,325.77 727.63,727.63 0,191.42 -73.96,365.55 -194.81,495.45l-64.52 -69.35c102.42,-112.49 164.85,-262 164.85,-426.1 0,-349.69 -283.47,-633.16 -633.15,-633.16 -147.71,0 -283.59,50.58 -391.29,135.37l-64.52 -69.35c124.73,-100.38 283.23,-160.49 455.81,-160.49z"/>
          <path className="fil1" d="M1321 1906.93c-54.28,40.74 -114.77,73.83 -179.79,97.63 -73.16,26.76 -152.09,41.74 -234.38,42.56l-6.89 0.07c-188.25,0 -358.52,-77.06 -482.2,-201.19 -123.63,-124.08 -200.68,-295.22 -202.56,-483.46l-0.07 -7.04c0,-90.73 17.51,-177.43 49.31,-256.88 28.9,-72.2 69.62,-138.4 119.75,-196.19 -3.56,-5.81 -5.62,-12.64 -5.62,-19.96 0,-21.12 17.13,-38.24 38.25,-38.24 21.12,0 38.25,17.12 38.25,38.24 0,21.12 -17.13,38.25 -38.25,38.25 -7.44,0 -14.4,-2.13 -20.28,-5.82 -48.47,56.1 -87.87,120.27 -115.87,190.22 -30.96,77.36 -48,161.85 -48,250.37l0 6.9c1.83,183.46 76.96,350.3 197.49,471.27 120.48,120.91 286.37,195.97 469.8,195.97l6.75 0c80.2,-0.8 157.16,-15.41 228.55,-41.53 63.32,-23.18 122.28,-55.44 175.24,-95.19 -1.66,-4.03 -2.57,-8.46 -2.57,-13.09 0,-19.1 15.48,-34.59 34.59,-34.59 19.1,0 34.59,15.48 34.59,34.59 0,19.1 -15.48,34.59 -34.59,34.59 -8.12,0 -15.59,-2.8 -21.49,-7.48z"/>
          <g>
            <g style={{clipPath: 'url(#id0)'}}>
              <polygon className="fil2" points="906.76,1355.51 1842.51,1269.14 1845.05,1303.65 "/>
              <polygon id="1" className="fil3" points="906.76,1355.51 1844.63,1297.89 1846.04,1326.68 "/>
              <polygon id="2" className="fil4" points="906.76,1355.51 1845.76,1320.92 1846.46,1349.74 "/>
              <polygon id="3" className="fil5" points="906.76,1355.51 1846.32,1343.98 1846.32,1372.81 "/>
              <polygon id="4" className="fil6" points="906.76,1355.51 1846.32,1367.04 1845.62,1395.86 "/>
              <polygon id="5" className="fil7" points="906.76,1355.51 1845.76,1390.09 1844.34,1418.89 "/>
              <polygon id="6" className="fil8" points="906.76,1355.51 1844.63,1413.13 1842.51,1441.88 "/>
              <polygon id="7" className="fil9" points="906.76,1355.51 1842.93,1436.13 1840.11,1464.82 "/>
              <polygon id="8" className="fil9" points="906.76,1355.51 1840.67,1459.08 1837.14,1487.69 "/>
              <polygon id="9" className="fil10" points="906.76,1355.51 1837.85,1481.96 1833.61,1510.48 "/>
              <polygon id="10" className="fil11" points="906.76,1355.51 1834.46,1504.78 1829.53,1533.18 "/>
              <polygon id="11" className="fil12" points="906.76,1355.51 1830.52,1527.5 1824.89,1555.77 "/>
              <polygon id="12" className="fil12" points="906.76,1355.51 1826.02,1550.12 1819.71,1578.25 "/>
              <polygon id="13" className="fil13" points="906.76,1355.51 1820.97,1572.62 1813.96,1600.59 "/>
              <polygon id="14" className="fil14" points="906.76,1355.51 1815.36,1594.99 1807.67,1622.77 "/>
              <polygon id="15" className="fil15" points="906.76,1355.51 1809.21,1617.22 1800.84,1644.81 "/>
              <polygon id="16" className="fil16" points="906.76,1355.51 1802.52,1639.29 1793.48,1666.66 "/>
              <polygon id="17" className="fil17" points="906.76,1355.51 1795.28,1661.18 1785.57,1688.32 "/>
              <polygon id="18" className="fil18" points="906.76,1355.51 1787.51,1682.9 1777.14,1709.8 "/>
              <polygon id="19" className="fil19" points="906.76,1355.51 1779.21,1704.41 1768.18,1731.05 "/>
              <polygon id="20" className="fil20" points="906.76,1355.51 1770.39,1725.72 1758.7,1752.07 "/>
              <polygon id="21" className="fil20" points="906.76,1355.51 1761.04,1746.8 1748.71,1772.86 "/>
              <polygon id="22" className="fil21" points="906.76,1355.51 1751.18,1767.66 1738.22,1793.4 "/>
              <polygon id="23" className="fil21" points="906.76,1355.51 1740.81,1788.25 1727.22,1813.67 "/>
              <polygon id="24" className="fil22" points="906.76,1355.51 1729.94,1808.58 1715.73,1833.67 "/>
              <polygon id="25" className="fil23" points="906.76,1355.51 1718.58,1828.66 1703.76,1853.38 "/>
              <polygon id="26" className="fil24" points="906.76,1355.51 1706.72,1848.43 1691.3,1872.79 "/>
              <polygon id="27" className="fil24" points="906.76,1355.51 1694.38,1867.92 1678.36,1891.89 "/>
              <polygon id="28" className="fil25" points="906.76,1355.51 1681.56,1887.09 1664.97,1910.66 "/>
              <polygon id="29" className="fil25" points="906.76,1355.51 1668.29,1905.95 1651.11,1929.1 "/>
              <polygon id="30" className="fil26" points="906.76,1355.51 1654.55,1924.47 1636.82,1947.19 "/>
              <polygon id="31" className="fil27" points="906.76,1355.51 1640.37,1942.65 1622.08,1964.94 "/>
              <polygon id="32" className="fil28" points="906.76,1355.51 1625.74,1960.48 1606.9,1982.3 "/>
              <polygon id="33" className="fil28" points="906.76,1355.51 1610.67,1977.94 1591.31,1999.3 "/>
              <polygon id="34" className="fil29" points="906.76,1355.51 1595.19,1995.03 1575.3,2015.91 "/>
              <polygon id="35" className="fil29" points="906.76,1355.51 1579.28,2011.73 1558.9,2032.11 "/>
              <polygon id="36" className="fil30" points="906.76,1355.51 1562.97,2028.04 1542.09,2047.91 "/>
              <polygon id="37" className="fil31" points="906.76,1355.51 1546.27,2043.94 1524.91,2063.3 "/>
              <polygon id="38" className="fil32" points="906.76,1355.51 1529.18,2059.42 1507.35,2078.26 "/>
              <polygon id="39" className="fil32" points="906.76,1355.51 1511.72,2074.49 1489.44,2092.78 "/>
              <polygon id="40" className="fil33" points="906.76,1355.51 1493.89,2089.12 1471.17,2106.85 "/>
              <polygon id="41" className="fil34" points="906.76,1355.51 1475.71,2103.31 1452.55,2120.48 "/>
              <polygon id="42" className="fil35" points="906.76,1355.51 1457.19,2117.04 1433.62,2133.65 "/>
              <polygon id="43" className="fil36" points="906.76,1355.51 1438.34,2130.33 1414.36,2146.34 "/>
              <polygon id="44" className="fil37" points="906.76,1355.51 1419.16,2143.13 1394.8,2158.56 "/>
              <polygon id="45" className="fil38" points="906.76,1355.51 1399.68,2155.48 1374.95,2170.3 "/>
              <polygon id="46" className="fil39" points="906.76,1355.51 1379.89,2167.33 1354.82,2181.54 "/>
              <polygon id="47" className="fil40" points="906.76,1355.51 1359.83,2178.7 1334.41,2192.28 "/>
              <polygon id="48" className="fil40" points="906.76,1355.51 1339.49,2189.57 1313.74,2202.53 "/>
              <polygon id="49" className="fil41" points="906.76,1355.51 1318.89,2199.93 1292.83,2212.27 "/>
              <polygon id="50" className="fil42" points="906.76,1355.51 1298.05,2209.8 1271.69,2221.47 "/>
              <polygon id="51" className="fil43" points="906.76,1355.51 1276.96,2219.14 1250.33,2230.18 "/>
              <polygon id="52" className="fil43" points="906.76,1355.51 1255.65,2227.97 1228.76,2238.35 "/>
              <polygon id="53" className="fil44" points="906.76,1355.51 1234.14,2236.26 1207,2245.98 "/>
              <polygon id="54" className="fil45" points="906.76,1355.51 1212.42,2244.04 1185.05,2253.08 "/>
              <polygon id="55" className="fil46" points="906.76,1355.51 1190.52,2251.27 1162.94,2259.64 "/>
              <polygon id="56" className="fil47" points="906.76,1355.51 1168.46,2257.96 1140.67,2265.65 "/>
              <polygon id="57" className="fil48" points="906.76,1355.51 1146.23,2264.12 1118.27,2271.12 "/>
              <polygon id="58" className="fil49" points="906.76,1355.51 1123.86,2269.72 1095.73,2276.03 "/>
              <polygon id="59" className="fil50" points="906.76,1355.51 1101.36,2274.77 1073.09,2280.39 "/>
              <polygon id="60" className="fil51" points="906.76,1355.51 1078.74,2279.26 1050.34,2284.2 "/>
              <polygon id="61" className="fil51" points="906.76,1355.51 1056.02,2283.21 1027.5,2287.44 "/>
              <polygon id="62" className="fil52" points="906.76,1355.51 1033.21,2286.6 1004.59,2290.13 "/>
              <polygon id="63" className="fil52" points="906.76,1355.51 1010.32,2289.42 981.63,2292.24 "/>
              <polygon id="64" className="fil53" points="906.76,1355.51 987.36,2291.68 958.62,2293.8 "/>
              <polygon id="65" className="fil54" points="906.76,1355.51 964.37,2293.37 935.57,2294.79 "/>
              <polygon id="66" className="fil55" points="906.76,1355.51 941.33,2294.51 912.51,2295.21 "/>
              <polygon id="67" className="fil55" points="906.76,1355.51 918.28,2295.08 889.45,2295.08 "/>
              <polygon id="68" className="fil56" points="906.76,1355.51 895.22,2295.08 866.4,2294.36 "/>
              <polygon id="69" className="fil56" points="906.76,1355.51 872.16,2294.51 843.37,2293.09 "/>
              <polygon id="70" className="fil57" points="906.76,1355.51 849.13,2293.37 820.38,2291.25 "/>
              <polygon id="71" className="fil58" points="906.76,1355.51 826.14,2291.68 797.44,2288.85 "/>
              <polygon id="72" className="fil59" points="906.76,1355.51 803.18,2289.42 774.57,2285.89 "/>
              <polygon id="73" className="fil59" points="906.76,1355.51 780.29,2286.59 751.77,2282.37 "/>
              <polygon id="74" className="fil60" points="906.76,1355.51 757.48,2283.21 729.08,2278.28 "/>
              <polygon id="75" className="fil60" points="906.76,1355.51 734.76,2279.26 706.48,2273.64 "/>
              <polygon id="76" className="fil61" points="906.76,1355.51 712.14,2274.76 684.01,2268.45 "/>
              <polygon id="77" className="fil62" points="906.76,1355.51 689.64,2269.72 661.68,2262.71 "/>
              <polygon id="78" className="fil63" points="906.76,1355.51 667.27,2264.11 639.48,2256.43 "/>
              <polygon id="79" className="fil64" points="906.76,1355.51 645.04,2257.96 617.46,2249.6 "/>
              <polygon id="80" className="fil65" points="906.76,1355.51 622.98,2251.26 595.6,2242.22 "/>
              <polygon id="81" className="fil66" points="906.76,1355.51 601.08,2244.04 573.93,2234.32 "/>
              <polygon id="82" className="fil67" points="906.76,1355.51 579.36,2236.26 552.47,2225.89 "/>
              <polygon id="83" className="fil68" points="906.76,1355.51 557.84,2227.97 531.22,2216.93 "/>
              <polygon id="84" className="fil68" points="906.76,1355.51 536.54,2219.13 510.18,2207.46 "/>
              <polygon id="85" className="fil69" points="906.76,1355.51 515.46,2209.79 489.4,2197.47 "/>
              <polygon id="86" className="fil70" points="906.76,1355.51 494.61,2199.93 468.86,2186.97 "/>
              <polygon id="87" className="fil71" points="906.76,1355.51 474.01,2189.57 448.58,2175.98 "/>
              <polygon id="88" className="fil71" points="906.76,1355.51 453.67,2178.69 428.59,2164.48 "/>
              <polygon id="89" className="fil72" points="906.76,1355.51 433.61,2167.32 408.89,2152.5 "/>
              <polygon id="90" className="fil72" points="906.76,1355.51 413.82,2155.47 389.47,2140.04 "/>
              <polygon id="91" className="fil73" points="906.76,1355.51 394.34,2143.12 370.37,2127.12 "/>
              <polygon id="92" className="fil74" points="906.76,1355.51 375.17,2130.32 351.6,2113.71 "/>
              <polygon id="93" className="fil75" points="906.76,1355.51 356.31,2117.03 333.16,2099.87 "/>
              <polygon id="94" className="fil75" points="906.76,1355.51 337.79,2103.3 315.06,2085.56 "/>
              <polygon id="95" className="fil76" points="906.76,1355.51 319.61,2089.11 297.33,2070.82 "/>
              <polygon id="96" className="fil76" points="906.76,1355.51 301.78,2074.48 279.95,2055.66 "/>
              <polygon id="97" className="fil77" points="906.76,1355.51 284.32,2059.41 262.96,2040.05 "/>
              <polygon id="98" className="fil78" points="906.76,1355.51 267.23,2043.93 246.36,2024.05 "/>
              <polygon id="99" className="fil79" points="906.76,1355.51 250.53,2028.03 230.15,2007.64 "/>
              <polygon id="100" className="fil80" points="906.76,1355.51 234.22,2011.72 214.35,1990.84 "/>
              <polygon id="101" className="fil81" points="906.76,1355.51 218.33,1995.02 198.97,1973.66 "/>
              <polygon id="102" className="fil82" points="906.76,1355.51 202.83,1977.93 184.01,1956.1 "/>
              <polygon id="103" className="fil83" points="906.76,1355.51 187.77,1960.47 169.49,1938.18 "/>
              <polygon id="104" className="fil84" points="906.76,1355.51 173.14,1942.64 155.41,1919.91 "/>
              <polygon id="105" className="fil84" points="906.76,1355.51 158.95,1924.46 141.78,1901.31 "/>
              <polygon id="106" className="fil85" points="906.76,1355.51 145.22,1905.94 128.62,1882.36 "/>
              <polygon id="107" className="fil86" points="906.76,1355.51 131.94,1887.08 115.92,1863.11 "/>
              <polygon id="108" className="fil87" points="906.76,1355.51 119.12,1867.91 103.71,1843.56 "/>
              <polygon id="109" className="fil87" points="906.76,1355.51 106.79,1848.42 91.97,1823.7 "/>
              <polygon id="110" className="fil88" points="906.76,1355.51 94.93,1828.65 80.72,1803.56 "/>
              <polygon id="111" className="fil89" points="906.76,1355.51 83.56,1808.57 69.97,1783.15 "/>
              <polygon id="112" className="fil90" points="906.76,1355.51 72.69,1788.24 59.73,1762.49 "/>
              <polygon id="113" className="fil91" points="906.76,1355.51 62.32,1767.64 50,1741.58 "/>
              <polygon id="114" className="fil92" points="906.76,1355.51 52.47,1746.79 40.78,1720.44 "/>
              <polygon id="115" className="fil93" points="906.76,1355.51 43.12,1725.71 32.09,1699.08 "/>
              <polygon id="116" className="fil94" points="906.76,1355.51 34.3,1704.4 23.92,1677.51 "/>
              <polygon id="117" className="fil94" points="906.76,1355.51 25.99,1682.88 16.29,1655.74 "/>
              <polygon id="118" className="fil94" points="906.76,1355.51 18.23,1661.17 9.18,1633.8 "/>
              <polygon id="119" className="fil95" points="906.76,1355.51 10.99,1639.28 2.63,1611.69 "/>
              <polygon id="120" className="fil95" points="906.76,1355.51 4.29,1617.2 -3.39,1589.43 "/>
              <polygon id="121" className="fil96" points="906.76,1355.51 -1.85,1594.98 -8.86,1567.01 "/>
              <polygon id="122" className="fil97" points="906.76,1355.51 -7.45,1572.6 -13.77,1544.48 "/>
              <polygon id="123" className="fil98" points="906.76,1355.51 -12.51,1550.11 -18.13,1521.83 "/>
              <polygon id="124" className="fil98" points="906.76,1355.51 -17.01,1527.49 -21.94,1499.08 "/>
              <polygon id="125" className="fil99" points="906.76,1355.51 -20.96,1504.76 -25.18,1476.25 "/>
              <polygon id="126" className="fil99" points="906.76,1355.51 -24.34,1481.95 -27.86,1453.35 "/>
              <polygon id="127" className="fil100" points="906.76,1355.51 -27.16,1459.07 -29.99,1430.38 "/>
              <polygon id="128" className="fil100" points="906.76,1355.51 -29.42,1436.12 -31.54,1407.37 "/>
              <polygon id="129" className="fil101" points="906.76,1355.51 -31.12,1413.12 -32.53,1384.32 "/>
              <polygon id="130" className="fil101" points="906.76,1355.51 -32.25,1390.08 -32.95,1361.27 "/>
              <polygon id="131" className="fil102" points="906.76,1355.51 -32.81,1367.03 -32.81,1338.2 "/>
              <polygon id="132" className="fil103" points="906.76,1355.51 -32.81,1343.97 -32.11,1315.14 "/>
              <polygon id="133" className="fil104" points="906.76,1355.51 -32.25,1320.91 -30.83,1292.12 "/>
              <polygon id="134" className="fil104" points="906.76,1355.51 -31.12,1297.88 -28.99,1269.13 "/>
              <polygon id="135" className="fil105" points="906.76,1355.51 -29.42,1274.88 -26.6,1246.19 "/>
              <polygon id="136" className="fil105" points="906.76,1355.51 -27.16,1251.93 -23.63,1223.31 "/>
              <polygon id="137" className="fil105" points="906.76,1355.51 -24.34,1229.04 -20.1,1200.53 "/>
              <polygon id="138" className="fil106" points="906.76,1355.51 -20.95,1206.23 -16.02,1177.83 "/>
              <polygon id="139" className="fil107" points="906.76,1355.51 -17.01,1183.51 -11.38,1155.23 "/>
              <polygon id="140" className="fil108" points="906.76,1355.51 -12.51,1160.88 -6.2,1132.76 "/>
              <polygon id="141" className="fil109" points="906.76,1355.51 -7.45,1138.38 -0.45,1110.42 "/>
              <polygon id="142" className="fil110" points="906.76,1355.51 -1.85,1116.02 5.84,1088.24 "/>
              <polygon id="143" className="fil111" points="906.76,1355.51 4.3,1093.79 12.67,1066.2 "/>
              <polygon id="144" className="fil112" points="906.76,1355.51 10.99,1071.72 20.03,1044.35 "/>
              <polygon id="145" className="fil112" points="906.76,1355.51 18.23,1049.82 27.94,1022.68 "/>
              <polygon id="146" className="fil113" points="906.76,1355.51 26,1028.12 36.37,1001.21 "/>
              <polygon id="147" className="fil114" points="906.76,1355.51 34.3,1006.6 45.33,979.96 "/>
              <polygon id="148" className="fil115" points="906.76,1355.51 43.12,985.29 54.81,958.94 "/>
              <polygon id="149" className="fil115" points="906.76,1355.51 52.47,964.21 64.8,938.15 "/>
              <polygon id="150" className="fil116" points="906.76,1355.51 62.33,943.36 75.3,917.61 "/>
              <polygon id="151" className="fil117" points="906.76,1355.51 72.7,922.75 86.29,897.34 "/>
              <polygon id="152" className="fil118" points="906.76,1355.51 83.57,902.42 97.78,877.33 "/>
              <polygon id="153" className="fil119" points="906.76,1355.51 94.93,882.35 109.76,857.63 "/>
              <polygon id="154" className="fil120" points="906.76,1355.51 106.79,862.57 122.21,838.22 "/>
              <polygon id="155" className="fil121" points="906.76,1355.51 119.13,843.09 135.15,819.12 "/>
              <polygon id="156" className="fil122" points="906.76,1355.51 131.95,823.92 148.54,800.34 "/>
              <polygon id="157" className="fil123" points="906.76,1355.51 145.22,805.06 162.4,781.91 "/>
              <polygon id="158" className="fil123" points="906.76,1355.51 158.96,786.54 176.69,763.82 "/>
              <polygon id="159" className="fil124" points="906.76,1355.51 173.15,768.36 191.44,746.07 "/>
              <polygon id="160" className="fil124" points="906.76,1355.51 187.77,750.53 206.61,728.71 "/>
              <polygon id="161" className="fil125" points="906.76,1355.51 202.84,733.07 222.2,711.71 "/>
              <polygon id="162" className="fil126" points="906.76,1355.51 218.33,715.98 238.21,695.1 "/>
              <polygon id="163" className="fil127" points="906.76,1355.51 234.23,699.27 254.62,678.89 "/>
              <polygon id="164" className="fil127" points="906.76,1355.51 250.54,682.97 271.42,663.09 "/>
              <polygon id="165" className="fil128" points="906.76,1355.51 267.24,667.07 288.61,647.71 "/>
              <polygon id="166" className="fil128" points="906.76,1355.51 284.33,651.59 306.16,632.75 "/>
              <polygon id="167" className="fil129" points="906.76,1355.51 301.79,636.52 324.07,618.23 "/>
              <polygon id="168" className="fil130" points="906.76,1355.51 319.62,621.89 342.34,604.15 "/>
              <polygon id="169" className="fil131" points="906.76,1355.51 337.8,607.7 360.96,590.53 "/>
              <polygon id="170" className="fil131" points="906.76,1355.51 356.32,593.96 379.89,577.37 "/>
              <polygon id="171" className="fil132" points="906.76,1355.51 375.18,580.68 399.15,564.67 "/>
              <polygon id="172" className="fil133" points="906.76,1355.51 394.35,567.87 418.71,552.45 "/>
              <polygon id="173" className="fil134" points="906.76,1355.51 413.84,555.53 438.56,540.71 "/>
              <polygon id="174" className="fil135" points="906.76,1355.51 433.62,543.68 458.7,529.47 "/>
              <polygon id="175" className="fil136" points="906.76,1355.51 453.68,532.32 479.11,518.73 "/>
              <polygon id="176" className="fil137" points="906.76,1355.51 474.02,521.44 499.77,508.48 "/>
              <polygon id="177" className="fil138" points="906.76,1355.51 494.62,511.07 520.68,498.74 "/>
              <polygon id="178" className="fil139" points="906.76,1355.51 515.47,501.21 541.82,489.53 "/>
              <polygon id="179" className="fil139" points="906.76,1355.51 536.55,491.86 563.18,480.83 "/>
              <polygon id="180" className="fil140" points="906.76,1355.51 557.86,483.04 584.75,472.66 "/>
              <polygon id="181" className="fil140" points="906.76,1355.51 579.37,474.74 606.51,465.03 "/>
              <polygon id="182" className="fil141" points="906.76,1355.51 601.09,466.97 628.46,457.93 "/>
              <polygon id="183" className="fil142" points="906.76,1355.51 622.99,459.74 650.57,451.37 "/>
              <polygon id="184" className="fil143" points="906.76,1355.51 645.05,453.05 672.84,445.35 "/>
              <polygon id="185" className="fil143" points="906.76,1355.51 667.28,446.89 695.24,439.89 "/>
              <polygon id="186" className="fil144" points="906.76,1355.51 689.65,441.29 717.78,434.97 "/>
              <polygon id="187" className="fil144" points="906.76,1355.51 712.15,436.23 740.42,430.61 "/>
              <polygon id="188" className="fil145" points="906.76,1355.51 734.77,431.74 763.17,426.81 "/>
              <polygon id="189" className="fil146" points="906.76,1355.51 757.49,427.8 786.01,423.57 "/>
              <polygon id="190" className="fil147" points="906.76,1355.51 780.3,424.42 808.92,420.89 "/>
              <polygon id="191" className="fil147" points="906.76,1355.51 803.19,421.59 831.89,418.77 "/>
              <polygon id="192" className="fil148" points="906.76,1355.51 826.15,419.33 854.89,417.21 "/>
              <polygon id="193" className="fil148" points="906.76,1355.51 849.14,417.64 877.94,416.22 "/>
              <polygon id="194" className="fil149" points="906.76,1355.51 872.18,416.5 901,415.79 "/>
              <polygon id="195" className="fil150" points="906.76,1355.51 895.23,415.93 924.06,415.93 "/>
              <polygon id="196" className="fil151" points="906.76,1355.51 918.29,415.93 947.11,416.65 "/>
              <polygon id="197" className="fil152" points="906.76,1355.51 941.35,416.51 970.14,417.92 "/>
              <polygon id="198" className="fil153" points="906.76,1355.51 964.38,417.64 993.13,419.76 "/>
              <polygon id="199" className="fil154" points="906.76,1355.51 987.38,419.33 1016.07,422.16 "/>
              <polygon id="200" className="fil155" points="906.76,1355.51 1010.33,421.59 1038.94,425.12 "/>
              <polygon id="201" className="fil156" points="906.76,1355.51 1033.22,424.42 1061.74,428.64 "/>
              <polygon id="202" className="fil156" points="906.76,1355.51 1056.03,427.8 1084.44,432.72 "/>
              <polygon id="203" className="fil157" points="906.76,1355.51 1078.75,431.74 1107.03,437.36 "/>
              <polygon id="204" className="fil158" points="906.76,1355.51 1101.37,436.24 1129.5,442.56 "/>
              <polygon id="205" className="fil159" points="906.76,1355.51 1123.87,441.29 1151.83,448.3 "/>
              <polygon id="206" className="fil159" points="906.76,1355.51 1146.24,446.9 1174.03,454.58 "/>
              <polygon id="207" className="fil160" points="906.76,1355.51 1168.47,453.05 1196.05,461.42 "/>
              <polygon id="208" className="fil161" points="906.76,1355.51 1190.53,459.75 1217.91,468.79 "/>
              <polygon id="209" className="fil162" points="906.76,1355.51 1212.44,466.97 1239.58,476.69 "/>
              <polygon id="210" className="fil163" points="906.76,1355.51 1234.15,474.74 1261.04,485.12 "/>
              <polygon id="211" className="fil164" points="906.76,1355.51 1255.67,483.05 1282.29,494.08 "/>
              <polygon id="212" className="fil165" points="906.76,1355.51 1276.97,491.87 1303.33,503.55 "/>
              <polygon id="213" className="fil166" points="906.76,1355.51 1298.06,501.22 1324.12,513.54 "/>
              <polygon id="214" className="fil167" points="906.76,1355.51 1318.9,511.07 1344.65,524.04 "/>
              <polygon id="215" className="fil167" points="906.76,1355.51 1339.5,521.44 1364.93,535.03 "/>
              <polygon id="216" className="fil168" points="906.76,1355.51 1359.84,532.32 1384.92,546.53 "/>
              <polygon id="217" className="fil169" points="906.76,1355.51 1379.9,543.69 1404.64,558.51 "/>
              <polygon id="218" className="fil170" points="906.76,1355.51 1399.69,555.54 1424.04,570.97 "/>
              <polygon id="219" className="fil170" points="906.76,1355.51 1419.17,567.88 1443.14,583.89 "/>
              <polygon id="220" className="fil171" points="906.76,1355.51 1438.35,580.69 1461.91,597.29 "/>
              <polygon id="221" className="fil171" points="906.76,1355.51 1457.2,593.97 1480.36,611.14 "/>
              <polygon id="222" className="fil172" points="906.76,1355.51 1475.72,607.71 1498.45,625.45 "/>
              <polygon id="223" className="fil173" points="906.76,1355.51 1493.9,621.9 1516.18,640.19 "/>
              <polygon id="224" className="fil174" points="906.76,1355.51 1511.73,636.53 1533.56,655.36 "/>
              <polygon id="225" className="fil174" points="906.76,1355.51 1529.19,651.6 1550.55,670.95 "/>
              <polygon id="226" className="fil175" points="906.76,1355.51 1546.28,667.08 1567.15,686.95 "/>
              <polygon id="227" className="fil175" points="906.76,1355.51 1562.98,682.98 1583.37,703.37 "/>
              <polygon id="228" className="fil176" points="906.76,1355.51 1579.29,699.28 1599.17,720.17 "/>
              <polygon id="229" className="fil177" points="906.76,1355.51 1595.19,715.99 1614.55,737.35 "/>
              <polygon id="230" className="fil178" points="906.76,1355.51 1610.68,733.08 1629.5,754.9 "/>
              <polygon id="231" className="fil178" points="906.76,1355.51 1625.74,750.54 1644.03,772.83 "/>
              <polygon id="232" className="fil179" points="906.76,1355.51 1640.37,768.37 1658.11,791.1 "/>
              <polygon id="233" className="fil179" points="906.76,1355.51 1654.56,786.55 1671.73,809.7 "/>
              <polygon id="234" className="fil180" points="906.76,1355.51 1668.29,805.07 1684.89,828.64 "/>
              <polygon id="235" className="fil181" points="906.76,1355.51 1681.57,823.93 1697.59,847.89 "/>
              <polygon id="236" className="fil182" points="906.76,1355.51 1694.39,843.1 1709.81,867.45 "/>
              <polygon id="237" className="fil183" points="906.76,1355.51 1706.72,862.58 1721.54,887.31 "/>
              <polygon id="238" className="fil184" points="906.76,1355.51 1718.58,882.37 1732.79,907.45 "/>
              <polygon id="239" className="fil185" points="906.76,1355.51 1729.95,902.43 1743.54,927.86 "/>
              <polygon id="240" className="fil186" points="906.76,1355.51 1740.82,922.77 1753.78,948.52 "/>
              <polygon id="241" className="fil187" points="906.76,1355.51 1751.19,943.37 1763.51,969.43 "/>
              <polygon id="242" className="fil187" points="906.76,1355.51 1761.04,964.22 1772.73,990.58 "/>
              <polygon id="243" className="fil188" points="906.76,1355.51 1770.39,985.3 1781.42,1011.94 "/>
              <polygon id="244" className="fil189" points="906.76,1355.51 1779.21,1006.61 1789.59,1033.5 "/>
              <polygon id="245" className="fil190" points="906.76,1355.51 1787.52,1028.13 1797.22,1055.27 "/>
              <polygon id="246" className="fil190" points="906.76,1355.51 1795.29,1049.83 1804.33,1077.21 "/>
              <polygon id="247" className="fil191" points="906.76,1355.51 1802.52,1071.73 1810.88,1099.32 "/>
              <polygon id="248" className="fil192" points="906.76,1355.51 1809.22,1093.81 1816.9,1121.59 "/>
              <polygon id="249" className="fil193" points="906.76,1355.51 1815.36,1116.03 1822.37,1143.99 "/>
              <polygon id="250" className="fil194" points="906.76,1355.51 1820.97,1138.4 1827.29,1166.53 "/>
              <polygon id="251" className="fil195" points="906.76,1355.51 1826.02,1160.9 1831.64,1189.18 "/>
              <polygon id="252" className="fil196" points="906.76,1355.51 1830.52,1183.52 1835.45,1211.92 "/>
              <polygon id="253" className="fil197" points="906.76,1355.51 1834.47,1206.24 1838.69,1234.75 "/>
              <polygon id="254" className="fil197" points="906.76,1355.51 1837.85,1229.05 1841.38,1257.66 "/>
              <polygon id="255" className="fil2" points="906.76,1355.51 1840.67,1251.94 1842.93,1274.89 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id1)'}}>
              <polygon className="fil198" points="906.76,1355.51 74.51,1350.39 75.27,1319.76 "/>
              <polygon id="1" className="fil198" points="906.76,1355.51 75.14,1324.88 76.4,1299.37 "/>
              <polygon id="2" className="fil198" points="906.76,1355.51 76.14,1304.48 78.02,1279.01 "/>
              <polygon id="3" className="fil199" points="906.76,1355.51 77.65,1284.11 80.14,1258.69 "/>
              <polygon id="4" className="fil200" points="906.76,1355.51 79.65,1263.78 82.77,1238.44 "/>
              <polygon id="5" className="fil201" points="906.76,1355.51 82.15,1243.51 85.89,1218.26 "/>
              <polygon id="6" className="fil201" points="906.76,1355.51 85.14,1223.3 89.51,1198.15 "/>
              <polygon id="7" className="fil201" points="906.76,1355.51 88.64,1203.18 93.61,1178.14 "/>
              <polygon id="8" className="fil201" points="906.76,1355.51 92.62,1183.15 98.21,1158.24 "/>
              <polygon id="9" className="fil201" points="906.76,1355.51 97.09,1163.22 103.3,1138.45 "/>
              <polygon id="10" className="fil202" points="906.76,1355.51 102.06,1143.41 108.87,1118.81 "/>
              <polygon id="11" className="fil202" points="906.76,1355.51 107.5,1123.72 114.92,1099.3 "/>
              <polygon id="12" className="fil202" points="906.76,1355.51 113.43,1104.18 121.44,1079.94 "/>
              <polygon id="13" className="fil203" points="906.76,1355.51 119.84,1084.79 128.44,1060.75 "/>
              <polygon id="14" className="fil203" points="906.76,1355.51 126.72,1065.56 135.91,1041.74 "/>
              <polygon id="15" className="fil203" points="906.76,1355.51 134.07,1046.5 143.84,1022.91 "/>
              <polygon id="16" className="fil204" points="906.76,1355.51 141.89,1027.63 152.24,1004.3 "/>
              <polygon id="17" className="fil204" points="906.76,1355.51 150.17,1008.96 161.08,985.88 "/>
              <polygon id="18" className="fil205" points="906.76,1355.51 158.9,990.5 170.38,967.69 "/>
              <polygon id="19" className="fil205" points="906.76,1355.51 168.08,972.26 180.11,949.74 "/>
              <polygon id="20" className="fil205" points="906.76,1355.51 177.71,954.24 190.29,932.02 "/>
              <polygon id="21" className="fil206" points="906.76,1355.51 187.77,936.47 200.9,914.57 "/>
              <polygon id="22" className="fil206" points="906.76,1355.51 198.27,918.95 211.93,897.38 "/>
              <polygon id="23" className="fil206" points="906.76,1355.51 209.21,901.7 223.39,880.47 "/>
              <polygon id="24" className="fil206" points="906.76,1355.51 220.55,884.71 235.26,863.84 "/>
              <polygon id="25" className="fil207" points="906.76,1355.51 232.31,868.02 247.52,847.51 "/>
              <polygon id="26" className="fil207" points="906.76,1355.51 244.47,851.61 260.19,831.48 "/>
              <polygon id="27" className="fil208" points="906.76,1355.51 257.04,835.51 273.24,815.78 "/>
              <polygon id="28" className="fil208" points="906.76,1355.51 270,819.72 286.67,800.39 "/>
              <polygon id="29" className="fil208" points="906.76,1355.51 283.34,804.26 300.49,785.34 "/>
              <polygon id="30" className="fil209" points="906.76,1355.51 297.06,789.12 314.67,770.64 "/>
              <polygon id="31" className="fil209" points="906.76,1355.51 311.14,774.33 329.2,756.27 "/>
              <polygon id="32" className="fil210" points="906.76,1355.51 325.58,759.89 344.08,742.29 "/>
              <polygon id="33" className="fil210" points="906.76,1355.51 340.37,745.8 359.29,728.66 "/>
              <polygon id="34" className="fil210" points="906.76,1355.51 355.51,732.09 374.84,715.41 "/>
              <polygon id="35" className="fil210" points="906.76,1355.51 370.97,718.75 390.71,702.56 "/>
              <polygon id="36" className="fil210" points="906.76,1355.51 386.76,705.79 406.89,690.09 "/>
              <polygon id="37" className="fil210" points="906.76,1355.51 402.86,693.23 423.37,678.01 "/>
              <polygon id="38" className="fil211" points="906.76,1355.51 419.26,681.06 440.14,666.35 "/>
              <polygon id="39" className="fil212" points="906.76,1355.51 435.97,669.3 457.2,655.11 "/>
              <polygon id="40" className="fil213" points="906.76,1355.51 452.95,657.95 474.52,644.29 "/>
              <polygon id="41" className="fil213" points="906.76,1355.51 470.2,647.03 492.1,633.9 "/>
              <polygon id="42" className="fil213" points="906.76,1355.51 487.72,636.53 509.93,623.94 "/>
              <polygon id="43" className="fil213" points="906.76,1355.51 505.49,626.46 528,614.42 "/>
              <polygon id="44" className="fil213" points="906.76,1355.51 523.5,616.83 546.31,605.35 "/>
              <polygon id="45" className="fil213" points="906.76,1355.51 541.74,607.64 564.83,596.73 "/>
              <polygon id="46" className="fil213" points="906.76,1355.51 560.21,598.91 583.56,588.57 "/>
              <polygon id="47" className="fil214" points="906.76,1355.51 578.89,590.63 602.47,580.87 "/>
              <polygon id="48" className="fil215" points="906.76,1355.51 597.75,582.82 621.57,573.63 "/>
              <polygon id="49" className="fil216" points="906.76,1355.51 616.81,575.47 640.85,566.87 "/>
              <polygon id="50" className="fil216" points="906.76,1355.51 636.04,568.59 660.28,560.58 "/>
              <polygon id="51" className="fil216" points="906.76,1355.51 655.43,562.18 679.87,554.77 "/>
              <polygon id="52" className="fil216" points="906.76,1355.51 674.98,556.26 699.58,549.45 "/>
              <polygon id="53" className="fil216" points="906.76,1355.51 694.67,550.8 719.43,544.6 "/>
              <polygon id="54" className="fil217" points="906.76,1355.51 714.47,545.85 739.38,540.25 "/>
              <polygon id="55" className="fil217" points="906.76,1355.51 734.41,541.37 759.44,536.39 "/>
              <polygon id="56" className="fil217" points="906.76,1355.51 754.44,537.38 779.59,533.02 "/>
              <polygon id="57" className="fil218" points="906.76,1355.51 774.56,533.89 799.81,530.15 "/>
              <polygon id="58" className="fil218" points="906.76,1355.51 794.76,530.9 820.1,527.77 "/>
              <polygon id="59" className="fil218" points="906.76,1355.51 815.03,528.39 840.44,525.89 "/>
              <polygon id="60" className="fil219" points="906.76,1355.51 835.35,526.39 860.82,524.52 "/>
              <polygon id="61" className="fil220" points="906.76,1355.51 855.73,524.89 881.23,523.64 "/>
              <polygon id="62" className="fil220" points="906.76,1355.51 876.13,523.89 901.65,523.27 "/>
              <polygon id="63" className="fil220" points="906.76,1355.51 896.55,523.39 922.07,523.39 "/>
              <polygon id="64" className="fil220" points="906.76,1355.51 916.97,523.39 942.49,524.01 "/>
              <polygon id="65" className="fil221" points="906.76,1355.51 937.39,523.89 962.89,525.14 "/>
              <polygon id="66" className="fil221" points="906.76,1355.51 957.79,524.89 983.25,526.77 "/>
              <polygon id="67" className="fil221" points="906.76,1355.51 978.16,526.39 1003.56,528.9 "/>
              <polygon id="68" className="fil221" points="906.76,1355.51 998.48,528.39 1023.82,531.52 "/>
              <polygon id="69" className="fil222" points="906.76,1355.51 1018.75,530.9 1044.01,534.65 "/>
              <polygon id="70" className="fil222" points="906.76,1355.51 1038.95,533.89 1064.11,538.25 "/>
              <polygon id="71" className="fil222" points="906.76,1355.51 1059.08,537.38 1084.12,542.37 "/>
              <polygon id="72" className="fil223" points="906.76,1355.51 1079.11,541.37 1104.02,546.97 "/>
              <polygon id="73" className="fil223" points="906.76,1355.51 1099.04,545.85 1123.8,552.04 "/>
              <polygon id="74" className="fil224" points="906.76,1355.51 1118.85,550.8 1143.46,557.61 "/>
              <polygon id="75" className="fil224" points="906.76,1355.51 1138.53,556.26 1162.97,563.67 "/>
              <polygon id="76" className="fil225" points="906.76,1355.51 1158.08,562.18 1182.33,570.19 "/>
              <polygon id="77" className="fil225" points="906.76,1355.51 1177.47,568.59 1201.51,577.19 "/>
              <polygon id="78" className="fil225" points="906.76,1355.51 1196.7,575.47 1220.52,584.66 "/>
              <polygon id="79" className="fil225" points="906.76,1355.51 1215.76,582.82 1239.34,592.6 "/>
              <polygon id="80" className="fil225" points="906.76,1355.51 1234.62,590.63 1257.97,600.98 "/>
              <polygon id="81" className="fil225" points="906.76,1355.51 1253.3,598.91 1276.38,609.83 "/>
              <polygon id="82" className="fil225" points="906.76,1355.51 1271.77,607.64 1294.57,619.12 "/>
              <polygon id="83" className="fil226" points="906.76,1355.51 1290.01,616.83 1312.53,628.87 "/>
              <polygon id="84" className="fil226" points="906.76,1355.51 1308.02,626.46 1330.23,639.05 "/>
              <polygon id="85" className="fil226" points="906.76,1355.51 1325.79,636.53 1347.69,649.65 "/>
              <polygon id="86" className="fil226" points="906.76,1355.51 1343.31,647.03 1364.88,660.68 "/>
              <polygon id="87" className="fil226" points="906.76,1355.51 1360.56,657.95 1381.8,672.13 "/>
              <polygon id="88" className="fil226" points="906.76,1355.51 1377.54,669.3 1398.42,684 "/>
              <polygon id="89" className="fil226" points="906.76,1355.51 1394.25,681.06 1414.75,696.27 "/>
              <polygon id="90" className="fil226" points="906.76,1355.51 1410.65,693.23 1430.78,708.93 "/>
              <polygon id="91" className="fil227" points="906.76,1355.51 1426.75,705.79 1446.49,721.98 "/>
              <polygon id="92" className="fil228" points="906.76,1355.51 1442.54,718.75 1461.87,735.43 "/>
              <polygon id="93" className="fil228" points="906.76,1355.51 1458.01,732.09 1476.92,749.23 "/>
              <polygon id="94" className="fil178" points="906.76,1355.51 1473.14,745.8 1491.63,763.41 "/>
              <polygon id="95" className="fil178" points="906.76,1355.51 1487.93,759.89 1505.98,777.94 "/>
              <polygon id="96" className="fil178" points="906.76,1355.51 1502.37,774.33 1519.98,792.82 "/>
              <polygon id="97" className="fil178" points="906.76,1355.51 1516.46,789.12 1533.6,808.04 "/>
              <polygon id="98" className="fil229" points="906.76,1355.51 1530.17,804.26 1546.85,823.59 "/>
              <polygon id="99" className="fil229" points="906.76,1355.51 1543.51,819.72 1559.71,839.46 "/>
              <polygon id="100" className="fil229" points="906.76,1355.51 1556.47,835.51 1572.18,855.64 "/>
              <polygon id="101" className="fil230" points="906.76,1355.51 1569.04,851.61 1584.24,872.12 "/>
              <polygon id="102" className="fil230" points="906.76,1355.51 1581.2,868.02 1595.9,888.89 "/>
              <polygon id="103" className="fil230" points="906.76,1355.51 1592.97,884.71 1607.15,905.94 "/>
              <polygon id="104" className="fil230" points="906.76,1355.51 1604.31,901.7 1617.96,923.27 "/>
              <polygon id="105" className="fil180" points="906.76,1355.51 1615.24,918.95 1628.36,940.85 "/>
              <polygon id="106" className="fil180" points="906.76,1355.51 1625.74,936.47 1638.32,958.69 "/>
              <polygon id="107" className="fil180" points="906.76,1355.51 1635.81,954.24 1647.84,976.76 "/>
              <polygon id="108" className="fil180" points="906.76,1355.51 1645.43,972.26 1656.91,995.06 "/>
              <polygon id="109" className="fil181" points="906.76,1355.51 1654.61,990.5 1665.53,1013.57 "/>
              <polygon id="110" className="fil181" points="906.76,1355.51 1663.34,1008.96 1673.69,1032.3 "/>
              <polygon id="111" className="fil181" points="906.76,1355.51 1671.62,1027.63 1681.4,1051.22 "/>
              <polygon id="112" className="fil231" points="906.76,1355.51 1679.44,1046.5 1688.63,1070.32 "/>
              <polygon id="113" className="fil231" points="906.76,1355.51 1686.79,1065.56 1695.39,1089.59 "/>
              <polygon id="114" className="fil231" points="906.76,1355.51 1693.67,1084.79 1701.68,1109.03 "/>
              <polygon id="115" className="fil231" points="906.76,1355.51 1700.08,1104.18 1707.49,1128.61 "/>
              <polygon id="116" className="fil183" points="906.76,1355.51 1706.01,1123.72 1712.82,1148.34 "/>
              <polygon id="117" className="fil183" points="906.76,1355.51 1711.45,1143.41 1717.66,1168.18 "/>
              <polygon id="118" className="fil184" points="906.76,1355.51 1716.42,1163.22 1722.01,1188.13 "/>
              <polygon id="119" className="fil184" points="906.76,1355.51 1720.89,1183.15 1725.87,1208.19 "/>
              <polygon id="120" className="fil185" points="906.76,1355.51 1724.87,1203.18 1729.24,1228.34 "/>
              <polygon id="121" className="fil185" points="906.76,1355.51 1728.37,1223.3 1732.12,1248.56 "/>
              <polygon id="122" className="fil185" points="906.76,1355.51 1731.37,1243.51 1734.49,1268.85 "/>
              <polygon id="123" className="fil185" points="906.76,1355.51 1733.86,1263.78 1736.37,1289.18 "/>
              <polygon id="124" className="fil185" points="906.76,1355.51 1735.87,1284.11 1737.75,1309.56 "/>
              <polygon id="125" className="fil185" points="906.76,1355.51 1737.37,1304.48 1738.62,1329.97 "/>
              <polygon id="126" className="fil185" points="906.76,1355.51 1738.37,1324.88 1739,1350.39 "/>
              <polygon id="127" className="fil187" points="906.76,1355.51 1738.87,1345.29 1738.87,1370.82 "/>
              <polygon id="128" className="fil187" points="906.76,1355.51 1738.87,1365.72 1738.24,1391.24 "/>
              <polygon id="129" className="fil187" points="906.76,1355.51 1738.37,1386.14 1737.11,1411.63 "/>
              <polygon id="130" className="fil187" points="906.76,1355.51 1737.37,1406.54 1735.49,1431.99 "/>
              <polygon id="131" className="fil187" points="906.76,1355.51 1735.87,1426.9 1733.37,1452.32 "/>
              <polygon id="132" className="fil187" points="906.76,1355.51 1733.86,1447.23 1730.74,1472.57 "/>
              <polygon id="133" className="fil187" points="906.76,1355.51 1731.37,1467.5 1727.62,1492.76 "/>
              <polygon id="134" className="fil188" points="906.76,1355.51 1728.37,1487.7 1724,1512.86 "/>
              <polygon id="135" className="fil188" points="906.76,1355.51 1724.87,1507.83 1719.9,1532.87 "/>
              <polygon id="136" className="fil232" points="906.76,1355.51 1720.89,1527.86 1715.3,1552.77 "/>
              <polygon id="137" className="fil232" points="906.76,1355.51 1716.42,1547.78 1710.21,1572.56 "/>
              <polygon id="138" className="fil189" points="906.76,1355.51 1711.45,1567.6 1704.64,1592.2 "/>
              <polygon id="139" className="fil189" points="906.76,1355.51 1706.01,1587.29 1698.59,1611.71 "/>
              <polygon id="140" className="fil189" points="906.76,1355.51 1700.08,1606.82 1692.07,1631.07 "/>
              <polygon id="141" className="fil189" points="906.76,1355.51 1693.67,1626.22 1685.07,1650.26 "/>
              <polygon id="142" className="fil190" points="906.76,1355.51 1686.79,1645.45 1677.6,1669.27 "/>
              <polygon id="143" className="fil190" points="906.76,1355.51 1679.44,1664.5 1669.67,1688.1 "/>
              <polygon id="144" className="fil190" points="906.76,1355.51 1671.62,1683.38 1661.27,1706.71 "/>
              <polygon id="145" className="fil233" points="906.76,1355.51 1663.34,1702.04 1652.43,1725.13 "/>
              <polygon id="146" className="fil233" points="906.76,1355.51 1654.61,1720.51 1643.13,1743.32 "/>
              <polygon id="147" className="fil233" points="906.76,1355.51 1645.43,1738.76 1633.4,1761.27 "/>
              <polygon id="148" className="fil233" points="906.76,1355.51 1635.81,1756.77 1623.22,1778.99 "/>
              <polygon id="149" className="fil192" points="906.76,1355.51 1625.74,1774.53 1612.61,1796.44 "/>
              <polygon id="150" className="fil192" points="906.76,1355.51 1615.24,1792.06 1601.58,1813.63 "/>
              <polygon id="151" className="fil192" points="906.76,1355.51 1604.31,1809.31 1590.12,1830.54 "/>
              <polygon id="152" className="fil192" points="906.76,1355.51 1592.97,1826.3 1578.27,1847.16 "/>
              <polygon id="153" className="fil234" points="906.76,1355.51 1581.2,1842.99 1565.99,1863.5 "/>
              <polygon id="154" className="fil234" points="906.76,1355.51 1569.04,1859.4 1553.32,1879.53 "/>
              <polygon id="155" className="fil234" points="906.76,1355.51 1556.47,1875.5 1540.27,1895.24 "/>
              <polygon id="156" className="fil194" points="906.76,1355.51 1543.51,1891.29 1526.84,1910.62 "/>
              <polygon id="157" className="fil194" points="906.76,1355.51 1530.17,1906.75 1513.03,1925.67 "/>
              <polygon id="158" className="fil194" points="906.76,1355.51 1516.46,1921.89 1498.86,1940.37 "/>
              <polygon id="159" className="fil194" points="906.76,1355.51 1502.37,1936.68 1484.32,1954.74 "/>
              <polygon id="160" className="fil235" points="906.76,1355.51 1487.93,1951.12 1469.43,1968.72 "/>
              <polygon id="161" className="fil235" points="906.76,1355.51 1473.14,1965.2 1454.22,1982.35 "/>
              <polygon id="162" className="fil236" points="906.76,1355.51 1458.01,1978.92 1438.68,1995.6 "/>
              <polygon id="163" className="fil237" points="906.76,1355.51 1442.54,1992.26 1422.81,2008.46 "/>
              <polygon id="164" className="fil237" points="906.76,1355.51 1426.75,2005.22 1406.63,2020.92 "/>
              <polygon id="165" className="fil237" points="906.76,1355.51 1410.65,2017.78 1390.14,2033 "/>
              <polygon id="166" className="fil237" points="906.76,1355.51 1394.25,2029.95 1373.37,2044.65 "/>
              <polygon id="167" className="fil237" points="906.76,1355.51 1377.54,2041.71 1356.32,2055.89 "/>
              <polygon id="168" className="fil237" points="906.76,1355.51 1360.56,2053.06 1338.99,2066.72 "/>
              <polygon id="169" className="fil237" points="906.76,1355.51 1343.31,2063.98 1321.41,2077.11 "/>
              <polygon id="170" className="fil237" points="906.76,1355.51 1325.79,2074.48 1303.58,2087.07 "/>
              <polygon id="171" className="fil238" points="906.76,1355.51 1308.02,2084.55 1285.51,2096.59 "/>
              <polygon id="172" className="fil238" points="906.76,1355.51 1290.01,2094.18 1267.2,2105.66 "/>
              <polygon id="173" className="fil238" points="906.76,1355.51 1271.77,2103.37 1248.68,2114.28 "/>
              <polygon id="174" className="fil238" points="906.76,1355.51 1253.3,2112.1 1229.96,2122.44 "/>
              <polygon id="175" className="fil238" points="906.76,1355.51 1234.62,2120.38 1211.04,2130.14 "/>
              <polygon id="176" className="fil238" points="906.76,1355.51 1215.76,2128.19 1191.94,2137.38 "/>
              <polygon id="177" className="fil238" points="906.76,1355.51 1196.7,2135.54 1172.66,2144.14 "/>
              <polygon id="178" className="fil239" points="906.76,1355.51 1177.47,2142.42 1153.23,2150.43 "/>
              <polygon id="179" className="fil239" points="906.76,1355.51 1158.08,2148.82 1133.65,2156.24 "/>
              <polygon id="180" className="fil240" points="906.76,1355.51 1138.53,2154.75 1113.93,2161.56 "/>
              <polygon id="181" className="fil240" points="906.76,1355.51 1118.85,2160.2 1094.08,2166.41 "/>
              <polygon id="182" className="fil241" points="906.76,1355.51 1099.04,2165.16 1074.13,2170.76 "/>
              <polygon id="183" className="fil241" points="906.76,1355.51 1079.11,2169.64 1054.07,2174.62 "/>
              <polygon id="184" className="fil241" points="906.76,1355.51 1059.08,2173.63 1033.92,2177.99 "/>
              <polygon id="185" className="fil242" points="906.76,1355.51 1038.95,2177.12 1013.7,2180.86 "/>
              <polygon id="186" className="fil242" points="906.76,1355.51 1018.75,2180.11 993.41,2183.24 "/>
              <polygon id="187" className="fil242" points="906.76,1355.51 998.48,2182.62 973.07,2185.11 "/>
              <polygon id="188" className="fil242" points="906.76,1355.51 978.16,2184.62 952.69,2186.49 "/>
              <polygon id="189" className="fil243" points="906.76,1355.51 957.79,2186.12 932.28,2187.37 "/>
              <polygon id="190" className="fil243" points="906.76,1355.51 937.39,2187.12 911.86,2187.74 "/>
              <polygon id="191" className="fil243" points="906.76,1355.51 916.97,2187.62 891.44,2187.62 "/>
              <polygon id="192" className="fil243" points="906.76,1355.51 896.55,2187.62 871.02,2187 "/>
              <polygon id="193" className="fil244" points="906.76,1355.51 876.13,2187.12 850.62,2185.87 "/>
              <polygon id="194" className="fil245" points="906.76,1355.51 855.73,2186.12 830.26,2184.24 "/>
              <polygon id="195" className="fil245" points="906.76,1355.51 835.35,2184.62 809.95,2182.11 "/>
              <polygon id="196" className="fil245" points="906.76,1355.51 815.03,2182.62 789.69,2179.48 "/>
              <polygon id="197" className="fil246" points="906.76,1355.51 794.76,2180.11 769.51,2176.37 "/>
              <polygon id="198" className="fil246" points="906.76,1355.51 774.56,2177.12 749.4,2172.75 "/>
              <polygon id="199" className="fil246" points="906.76,1355.51 754.44,2173.63 729.39,2168.64 "/>
              <polygon id="200" className="fil247" points="906.76,1355.51 734.41,2169.64 709.49,2164.05 "/>
              <polygon id="201" className="fil247" points="906.76,1355.51 714.47,2165.16 689.71,2158.97 "/>
              <polygon id="202" className="fil247" points="906.76,1355.51 694.67,2160.2 670.05,2153.39 "/>
              <polygon id="203" className="fil247" points="906.76,1355.51 674.98,2154.75 650.54,2147.35 "/>
              <polygon id="204" className="fil247" points="906.76,1355.51 655.43,2148.82 631.19,2140.82 "/>
              <polygon id="205" className="fil248" points="906.76,1355.51 636.04,2142.42 612,2133.82 "/>
              <polygon id="206" className="fil249" points="906.76,1355.51 616.81,2135.54 592.99,2126.35 "/>
              <polygon id="207" className="fil250" points="906.76,1355.51 597.75,2128.19 574.17,2118.41 "/>
              <polygon id="208" className="fil250" points="906.76,1355.51 578.89,2120.38 555.54,2110.03 "/>
              <polygon id="209" className="fil250" points="906.76,1355.51 560.21,2112.1 537.13,2101.17 "/>
              <polygon id="210" className="fil250" points="906.76,1355.51 541.74,2103.37 518.94,2091.89 "/>
              <polygon id="211" className="fil250" points="906.76,1355.51 523.5,2094.18 500.99,2082.14 "/>
              <polygon id="212" className="fil250" points="906.76,1355.51 505.49,2084.55 483.28,2071.97 "/>
              <polygon id="213" className="fil250" points="906.76,1355.51 487.72,2074.48 465.82,2061.36 "/>
              <polygon id="214" className="fil251" points="906.76,1355.51 470.2,2063.98 448.63,2050.32 "/>
              <polygon id="215" className="fil252" points="906.76,1355.51 452.95,2053.06 431.72,2038.87 "/>
              <polygon id="216" className="fil253" points="906.76,1355.51 435.97,2041.71 415.09,2027.01 "/>
              <polygon id="217" className="fil253" points="906.76,1355.51 419.26,2029.95 398.76,2014.74 "/>
              <polygon id="218" className="fil253" points="906.76,1355.51 402.86,2017.78 382.74,2002.08 "/>
              <polygon id="219" className="fil253" points="906.76,1355.51 386.76,2005.22 367.02,1989.02 "/>
              <polygon id="220" className="fil253" points="906.76,1355.51 370.97,1992.26 351.64,1975.58 "/>
              <polygon id="221" className="fil253" points="906.76,1355.51 355.51,1978.92 336.59,1961.77 "/>
              <polygon id="222" className="fil254" points="906.76,1355.51 340.37,1965.2 321.88,1947.6 "/>
              <polygon id="223" className="fil254" points="906.76,1355.51 325.58,1951.12 307.53,1933.07 "/>
              <polygon id="224" className="fil255" points="906.76,1355.51 311.14,1936.68 293.53,1918.19 "/>
              <polygon id="225" className="fil255" points="906.76,1355.51 297.06,1921.89 279.91,1902.97 "/>
              <polygon id="226" className="fil255" points="906.76,1355.51 283.34,1906.75 266.66,1887.42 "/>
              <polygon id="227" className="fil256" points="906.76,1355.51 270,1891.29 253.8,1871.55 "/>
              <polygon id="228" className="fil256" points="906.76,1355.51 257.04,1875.5 241.33,1855.37 "/>
              <polygon id="229" className="fil257" points="906.76,1355.51 244.47,1859.4 229.27,1838.89 "/>
              <polygon id="230" className="fil257" points="906.76,1355.51 232.31,1842.99 217.61,1822.12 "/>
              <polygon id="231" className="fil257" points="906.76,1355.51 220.55,1826.3 206.36,1805.07 "/>
              <polygon id="232" className="fil257" points="906.76,1355.51 209.21,1809.31 195.55,1787.74 "/>
              <polygon id="233" className="fil258" points="906.76,1355.51 198.27,1792.06 185.15,1770.16 "/>
              <polygon id="234" className="fil258" points="906.76,1355.51 187.77,1774.53 175.2,1752.32 "/>
              <polygon id="235" className="fil258" points="906.76,1355.51 177.71,1756.77 165.67,1734.25 "/>
              <polygon id="236" className="fil259" points="906.76,1355.51 168.08,1738.76 156.6,1715.95 "/>
              <polygon id="237" className="fil259" points="906.76,1355.51 158.9,1720.51 147.99,1697.43 "/>
              <polygon id="238" className="fil260" points="906.76,1355.51 150.17,1702.04 139.82,1678.71 "/>
              <polygon id="239" className="fil260" points="906.76,1355.51 141.89,1683.38 132.12,1659.79 "/>
              <polygon id="240" className="fil260" points="906.76,1355.51 134.07,1664.5 124.88,1640.68 "/>
              <polygon id="241" className="fil261" points="906.76,1355.51 126.72,1645.45 118.12,1621.41 "/>
              <polygon id="242" className="fil261" points="906.76,1355.51 119.84,1626.22 111.83,1601.98 "/>
              <polygon id="243" className="fil261" points="906.76,1355.51 113.43,1606.82 106.03,1582.4 "/>
              <polygon id="244" className="fil262" points="906.76,1355.51 107.5,1587.29 100.69,1562.67 "/>
              <polygon id="245" className="fil262" points="906.76,1355.51 102.06,1567.6 95.85,1542.83 "/>
              <polygon id="246" className="fil262" points="906.76,1355.51 97.09,1547.78 91.5,1522.87 "/>
              <polygon id="247" className="fil262" points="906.76,1355.51 92.62,1527.86 87.64,1502.82 "/>
              <polygon id="248" className="fil262" points="906.76,1355.51 88.64,1507.83 84.27,1482.67 "/>
              <polygon id="249" className="fil263" points="906.76,1355.51 85.14,1487.7 81.4,1462.45 "/>
              <polygon id="250" className="fil264" points="906.76,1355.51 82.15,1467.5 79.02,1442.16 "/>
              <polygon id="251" className="fil265" points="906.76,1355.51 79.65,1447.23 77.14,1421.82 "/>
              <polygon id="252" className="fil265" points="906.76,1355.51 77.65,1426.9 75.77,1401.44 "/>
              <polygon id="253" className="fil265" points="906.76,1355.51 76.14,1406.54 74.89,1381.03 "/>
              <polygon id="254" className="fil265" points="906.76,1355.51 75.14,1386.14 74.51,1360.61 "/>
              <polygon id="255" className="fil198" points="906.76,1355.51 74.64,1365.72 74.64,1345.29 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id2)'}}>
              <polygon className="fil266" points="906.76,1355.51 1300.45,580.05 1328.73,595.06 "/>
              <polygon id="1" className="fil267" points="906.76,1355.51 1324.02,592.57 1347.26,605.65 "/>
              <polygon id="2" className="fil267" points="906.76,1355.51 1342.61,603.03 1365.53,616.68 "/>
              <polygon id="3" className="fil267" points="906.76,1355.51 1360.94,613.96 1383.52,628.17 "/>
              <polygon id="4" className="fil267" points="906.76,1355.51 1379.01,625.33 1401.23,640.09 "/>
              <polygon id="5" className="fil268" points="906.76,1355.51 1396.79,637.13 1418.64,652.44 "/>
              <polygon id="6" className="fil268" points="906.76,1355.51 1414.27,649.37 1435.74,665.22 "/>
              <polygon id="7" className="fil268" points="906.76,1355.51 1431.44,662.04 1452.52,678.4 "/>
              <polygon id="8" className="fil268" points="906.76,1355.51 1448.3,675.13 1468.97,692 "/>
              <polygon id="9" className="fil269" points="906.76,1355.51 1464.84,688.63 1485.09,706 "/>
              <polygon id="10" className="fil269" points="906.76,1355.51 1481.03,702.53 1500.85,720.39 "/>
              <polygon id="11" className="fil269" points="906.76,1355.51 1496.88,716.81 1516.26,735.16 "/>
              <polygon id="12" className="fil269" points="906.76,1355.51 1512.39,731.49 1531.3,750.3 "/>
              <polygon id="13" className="fil270" points="906.76,1355.51 1527.51,746.54 1545.96,765.81 "/>
              <polygon id="14" className="fil270" points="906.76,1355.51 1542.27,761.95 1560.24,781.68 "/>
              <polygon id="15" className="fil270" points="906.76,1355.51 1556.65,777.73 1574.13,797.89 "/>
              <polygon id="16" className="fil270" points="906.76,1355.51 1570.63,793.85 1587.61,814.43 "/>
              <polygon id="17" className="fil271" points="906.76,1355.51 1584.21,810.32 1600.69,831.3 "/>
              <polygon id="18" className="fil271" points="906.76,1355.51 1597.39,827.11 1613.35,848.49 "/>
              <polygon id="19" className="fil271" points="906.76,1355.51 1610.15,844.22 1625.57,865.98 "/>
              <polygon id="20" className="fil271" points="906.76,1355.51 1622.49,861.63 1637.36,883.77 "/>
              <polygon id="21" className="fil272" points="906.76,1355.51 1634.39,879.35 1648.72,901.85 "/>
              <polygon id="22" className="fil272" points="906.76,1355.51 1645.86,897.34 1659.64,920.19 "/>
              <polygon id="23" className="fil272" points="906.76,1355.51 1656.88,915.62 1670.1,938.79 "/>
              <polygon id="24" className="fil272" points="906.76,1355.51 1667.45,934.16 1680.09,957.66 "/>
              <polygon id="25" className="fil273" points="906.76,1355.51 1677.56,952.96 1689.62,976.76 "/>
              <polygon id="26" className="fil273" points="906.76,1355.51 1687.2,971.99 1698.67,996.08 "/>
              <polygon id="27" className="fil273" points="906.76,1355.51 1696.38,991.26 1707.26,1015.63 "/>
              <polygon id="28" className="fil273" points="906.76,1355.51 1705.09,1010.75 1715.36,1035.37 "/>
              <polygon id="29" className="fil274" points="906.76,1355.51 1713.3,1030.44 1722.97,1055.32 "/>
              <polygon id="30" className="fil274" points="906.76,1355.51 1721.04,1050.34 1730.1,1075.44 "/>
              <polygon id="31" className="fil274" points="906.76,1355.51 1728.28,1070.41 1736.72,1095.72 "/>
              <polygon id="32" className="fil274" points="906.76,1355.51 1735.03,1090.66 1742.84,1116.17 "/>
              <polygon id="33" className="fil275" points="906.76,1355.51 1741.28,1111.06 1748.46,1136.76 "/>
              <polygon id="34" className="fil275" points="906.76,1355.51 1747.03,1131.62 1753.58,1157.48 "/>
              <polygon id="35" className="fil275" points="906.76,1355.51 1752.27,1152.31 1758.19,1178.32 "/>
              <polygon id="36" className="fil275" points="906.76,1355.51 1757,1173.12 1762.27,1199.28 "/>
              <polygon id="37" className="fil276" points="906.76,1355.51 1761.22,1194.04 1765.85,1220.32 "/>
              <polygon id="38" className="fil276" points="906.76,1355.51 1764.93,1215.06 1768.91,1241.44 "/>
              <polygon id="39" className="fil276" points="906.76,1355.51 1768.11,1236.16 1771.45,1262.64 "/>
              <polygon id="40" className="fil276" points="906.76,1355.51 1770.79,1257.33 1773.47,1283.88 "/>
              <polygon id="41" className="fil277" points="906.76,1355.51 1772.94,1278.57 1774.97,1305.17 "/>
              <polygon id="42" className="fil277" points="906.76,1355.51 1774.56,1299.85 1775.94,1326.49 "/>
              <polygon id="43" className="fil277" points="906.76,1355.51 1775.66,1321.16 1776.39,1347.84 "/>
              <polygon id="44" className="fil277" points="906.76,1355.51 1776.25,1342.5 1776.32,1369.18 "/>
              <polygon id="45" className="fil278" points="906.76,1355.51 1776.3,1363.85 1775.72,1390.52 "/>
              <polygon id="46" className="fil278" points="906.76,1355.51 1775.83,1385.18 1774.6,1411.83 "/>
              <polygon id="47" className="fil278" points="906.76,1355.51 1774.85,1406.5 1772.96,1433.11 "/>
              <polygon id="48" className="fil278" points="906.76,1355.51 1773.34,1427.79 1770.79,1454.35 "/>
              <polygon id="49" className="fil279" points="906.76,1355.51 1771.3,1449.03 1768.1,1475.52 "/>
              <polygon id="50" className="fil279" points="906.76,1355.51 1768.75,1470.22 1764.9,1496.62 "/>
              <polygon id="51" className="fil279" points="906.76,1355.51 1765.67,1491.34 1761.18,1517.64 "/>
              <polygon id="52" className="fil279" points="906.76,1355.51 1762.07,1512.38 1756.94,1538.56 "/>
              <polygon id="53" className="fil280" points="906.76,1355.51 1757.97,1533.32 1752.19,1559.37 "/>
              <polygon id="54" className="fil280" points="906.76,1355.51 1753.35,1554.16 1746.94,1580.06 "/>
              <polygon id="55" className="fil280" points="906.76,1355.51 1748.22,1574.87 1741.17,1600.61 "/>
              <polygon id="56" className="fil280" points="906.76,1355.51 1742.59,1595.46 1734.9,1621.01 "/>
              <polygon id="57" className="fil281" points="906.76,1355.51 1736.44,1615.9 1728.14,1641.26 "/>
              <polygon id="58" className="fil281" points="906.76,1355.51 1729.8,1636.18 1720.88,1661.32 "/>
              <polygon id="59" className="fil281" points="906.76,1355.51 1722.66,1656.3 1713.13,1681.22 "/>
              <polygon id="60" className="fil281" points="906.76,1355.51 1715.04,1676.23 1704.89,1700.9 "/>
              <polygon id="61" className="fil282" points="906.76,1355.51 1706.92,1695.97 1696.18,1720.39 "/>
              <polygon id="62" className="fil282" points="906.76,1355.51 1698.33,1715.51 1686.99,1739.65 "/>
              <polygon id="63" className="fil282" points="906.76,1355.51 1689.26,1734.82 1677.32,1758.68 "/>
              <polygon id="64" className="fil282" points="906.76,1355.51 1679.71,1753.91 1667.19,1777.48 "/>
              <polygon id="65" className="fil283" points="906.76,1355.51 1669.7,1772.76 1656.61,1796.01 "/>
              <polygon id="66" className="fil283" points="906.76,1355.51 1659.23,1791.36 1645.57,1814.27 "/>
              <polygon id="67" className="fil283" points="906.76,1355.51 1648.31,1809.69 1634.09,1832.28 "/>
              <polygon id="68" className="fil283" points="906.76,1355.51 1636.94,1827.76 1622.18,1849.98 "/>
              <polygon id="69" className="fil284" points="906.76,1355.51 1625.12,1845.53 1609.83,1867.39 "/>
              <polygon id="70" className="fil284" points="906.76,1355.51 1612.88,1863.01 1597.05,1884.49 "/>
              <polygon id="71" className="fil284" points="906.76,1355.51 1600.21,1880.19 1583.86,1901.27 "/>
              <polygon id="72" className="fil284" points="906.76,1355.51 1587.13,1897.05 1570.27,1917.72 "/>
              <polygon id="73" className="fil285" points="906.76,1355.51 1573.64,1913.59 1556.26,1933.83 "/>
              <polygon id="74" className="fil285" points="906.76,1355.51 1559.74,1929.79 1541.88,1949.6 "/>
              <polygon id="75" className="fil285" points="906.76,1355.51 1545.44,1945.64 1527.11,1965.01 "/>
              <polygon id="76" className="fil286" points="906.76,1355.51 1530.77,1961.13 1511.96,1980.04 "/>
              <polygon id="77" className="fil286" points="906.76,1355.51 1515.73,1976.27 1496.45,1994.71 "/>
              <polygon id="78" className="fil286" points="906.76,1355.51 1500.3,1991.03 1480.59,2008.99 "/>
              <polygon id="79" className="fil286" points="906.76,1355.51 1484.53,2005.4 1464.38,2022.88 "/>
              <polygon id="80" className="fil287" points="906.76,1355.51 1468.4,2019.38 1447.83,2036.36 "/>
              <polygon id="81" className="fil287" points="906.76,1355.51 1451.95,2032.97 1430.95,2049.43 "/>
              <polygon id="82" className="fil287" points="906.76,1355.51 1435.16,2046.14 1413.77,2062.09 "/>
              <polygon id="83" className="fil287" points="906.76,1355.51 1418.05,2058.9 1396.28,2074.32 "/>
              <polygon id="84" className="fil288" points="906.76,1355.51 1400.63,2071.24 1378.49,2086.12 "/>
              <polygon id="85" className="fil288" points="906.76,1355.51 1382.92,2083.14 1360.42,2097.48 "/>
              <polygon id="86" className="fil288" points="906.76,1355.51 1364.92,2094.6 1342.07,2108.38 "/>
              <polygon id="87" className="fil288" points="906.76,1355.51 1346.64,2105.63 1323.46,2118.84 "/>
              <polygon id="88" className="fil289" points="906.76,1355.51 1328.1,2116.19 1304.61,2128.84 "/>
              <polygon id="89" className="fil289" points="906.76,1355.51 1309.31,2126.3 1285.51,2138.37 "/>
              <polygon id="90" className="fil289" points="906.76,1355.51 1290.26,2135.96 1266.18,2147.43 "/>
              <polygon id="91" className="fil289" points="906.76,1355.51 1270.99,2145.13 1246.64,2156.01 "/>
              <polygon id="92" className="fil290" points="906.76,1355.51 1251.51,2153.83 1226.89,2164.11 "/>
              <polygon id="93" className="fil290" points="906.76,1355.51 1231.81,2162.06 1206.95,2171.72 "/>
              <polygon id="94" className="fil290" points="906.76,1355.51 1211.92,2169.79 1186.83,2178.84 "/>
              <polygon id="95" className="fil290" points="906.76,1355.51 1191.84,2177.04 1166.54,2185.47 "/>
              <polygon id="96" className="fil291" points="906.76,1355.51 1171.6,2183.78 1146.09,2191.6 "/>
              <polygon id="97" className="fil291" points="906.76,1355.51 1151.19,2190.03 1125.5,2197.22 "/>
              <polygon id="98" className="fil291" points="906.76,1355.51 1130.64,2195.78 1104.78,2202.33 "/>
              <polygon id="99" className="fil291" points="906.76,1355.51 1109.95,2201.01 1083.93,2206.93 "/>
              <polygon id="100" className="fil292" points="906.76,1355.51 1089.14,2205.75 1062.99,2211.03 "/>
              <polygon id="101" className="fil292" points="906.76,1355.51 1068.22,2209.98 1041.94,2214.6 "/>
              <polygon id="102" className="fil292" points="906.76,1355.51 1047.2,2213.67 1020.82,2217.66 "/>
              <polygon id="103" className="fil292" points="906.76,1355.51 1026.09,2216.86 999.63,2220.21 "/>
              <polygon id="104" className="fil293" points="906.76,1355.51 1004.92,2219.53 978.37,2222.22 "/>
              <polygon id="105" className="fil293" points="906.76,1355.51 983.69,2221.68 957.09,2223.71 "/>
              <polygon id="106" className="fil293" points="906.76,1355.51 962.4,2223.31 935.76,2224.69 "/>
              <polygon id="107" className="fil293" points="906.76,1355.51 941.09,2224.42 914.43,2225.14 "/>
              <polygon id="108" className="fil294" points="906.76,1355.51 919.76,2224.99 893.08,2225.06 "/>
              <polygon id="109" className="fil294" points="906.76,1355.51 898.42,2225.05 871.74,2224.47 "/>
              <polygon id="110" className="fil294" points="906.76,1355.51 877.08,2224.59 850.43,2223.35 "/>
              <polygon id="111" className="fil294" points="906.76,1355.51 855.77,2223.6 829.15,2221.7 "/>
              <polygon id="112" className="fil295" points="906.76,1355.51 834.47,2222.08 807.92,2219.54 "/>
              <polygon id="113" className="fil295" points="906.76,1355.51 813.23,2220.05 786.74,2216.85 "/>
              <polygon id="114" className="fil295" points="906.76,1355.51 792.04,2217.49 765.64,2213.65 "/>
              <polygon id="115" className="fil295" points="906.76,1355.51 770.92,2214.42 744.62,2209.93 "/>
              <polygon id="116" className="fil296" points="906.76,1355.51 749.88,2210.83 723.7,2205.69 "/>
              <polygon id="117" className="fil296" points="906.76,1355.51 728.93,2206.71 702.89,2200.95 "/>
              <polygon id="118" className="fil296" points="906.76,1355.51 708.1,2202.09 682.21,2195.68 "/>
              <polygon id="119" className="fil296" points="906.76,1355.51 687.38,2196.97 661.66,2189.92 "/>
              <polygon id="120" className="fil297" points="906.76,1355.51 666.8,2191.33 641.25,2183.66 "/>
              <polygon id="121" className="fil297" points="906.76,1355.51 646.36,2185.19 621,2176.89 "/>
              <polygon id="122" className="fil297" points="906.76,1355.51 626.08,2178.55 600.93,2169.63 "/>
              <polygon id="123" className="fil297" points="906.76,1355.51 605.97,2171.42 581.05,2161.88 "/>
              <polygon id="124" className="fil297" points="906.76,1355.51 586.03,2163.78 561.35,2153.64 "/>
              <polygon id="125" className="fil297" points="906.76,1355.51 566.29,2155.67 541.87,2144.93 "/>
              <polygon id="126" className="fil297" points="906.76,1355.51 546.76,2147.07 522.61,2135.73 "/>
              <polygon id="127" className="fil297" points="906.76,1355.51 527.44,2138 503.58,2126.07 "/>
              <polygon id="128" className="fil297" points="906.76,1355.51 508.35,2128.45 484.79,2115.94 "/>
              <polygon id="129" className="fil297" points="906.76,1355.51 489.5,2118.44 466.25,2105.36 "/>
              <polygon id="130" className="fil297" points="906.76,1355.51 470.9,2107.97 447.98,2094.33 "/>
              <polygon id="131" className="fil298" points="906.76,1355.51 452.57,2097.05 429.99,2082.84 "/>
              <polygon id="132" className="fil298" points="906.76,1355.51 434.51,2085.68 412.29,2070.92 "/>
              <polygon id="133" className="fil298" points="906.76,1355.51 416.73,2073.88 394.88,2058.57 "/>
              <polygon id="134" className="fil298" points="906.76,1355.51 399.25,2061.63 377.77,2045.79 "/>
              <polygon id="135" className="fil298" points="906.76,1355.51 382.07,2048.97 361,2032.61 "/>
              <polygon id="136" className="fil298" points="906.76,1355.51 365.21,2035.88 344.54,2019.01 "/>
              <polygon id="137" className="fil298" points="906.76,1355.51 348.68,2022.38 328.42,2005.01 "/>
              <polygon id="138" className="fil298" points="906.76,1355.51 332.48,2008.49 312.66,1990.62 "/>
              <polygon id="139" className="fil298" points="906.76,1355.51 316.63,1994.2 297.25,1975.85 "/>
              <polygon id="140" className="fil298" points="906.76,1355.51 301.13,1979.52 282.21,1960.7 "/>
              <polygon id="141" className="fil298" points="906.76,1355.51 286,1964.47 267.55,1945.2 "/>
              <polygon id="142" className="fil298" points="906.76,1355.51 271.24,1949.06 253.27,1929.33 "/>
              <polygon id="143" className="fil298" points="906.76,1355.51 256.87,1933.28 239.38,1913.12 "/>
              <polygon id="144" className="fil298" points="906.76,1355.51 242.88,1917.16 225.9,1896.58 "/>
              <polygon id="145" className="fil298" points="906.76,1355.51 229.3,1900.69 212.82,1879.71 "/>
              <polygon id="146" className="fil298" points="906.76,1355.51 216.12,1883.9 200.18,1862.52 "/>
              <polygon id="147" className="fil298" points="906.76,1355.51 203.36,1866.79 187.94,1845.02 "/>
              <polygon id="148" className="fil298" points="906.76,1355.51 191.03,1849.38 176.15,1827.24 "/>
              <polygon id="149" className="fil298" points="906.76,1355.51 179.12,1831.67 164.79,1809.17 "/>
              <polygon id="150" className="fil299" points="906.76,1355.51 167.65,1813.67 153.87,1790.82 "/>
              <polygon id="151" className="fil299" points="906.76,1355.51 156.63,1795.39 143.43,1772.22 "/>
              <polygon id="152" className="fil299" points="906.76,1355.51 146.06,1776.84 133.42,1753.35 "/>
              <polygon id="153" className="fil299" points="906.76,1355.51 135.95,1758.05 123.9,1734.25 "/>
              <polygon id="154" className="fil299" points="906.76,1355.51 126.31,1739.02 114.84,1714.93 "/>
              <polygon id="155" className="fil299" points="906.76,1355.51 117.13,1719.75 106.25,1695.38 "/>
              <polygon id="156" className="fil299" points="906.76,1355.51 108.42,1700.26 98.15,1675.64 "/>
              <polygon id="157" className="fil299" points="906.76,1355.51 100.21,1680.56 90.54,1655.69 "/>
              <polygon id="158" className="fil299" points="906.76,1355.51 92.47,1660.67 83.41,1635.58 "/>
              <polygon id="159" className="fil299" points="906.76,1355.51 85.23,1640.6 76.79,1615.29 "/>
              <polygon id="160" className="fil299" points="906.76,1355.51 78.48,1620.34 70.67,1594.84 "/>
              <polygon id="161" className="fil299" points="906.76,1355.51 72.23,1599.94 65.05,1574.25 "/>
              <polygon id="162" className="fil299" points="906.76,1355.51 66.48,1579.39 59.93,1553.52 "/>
              <polygon id="163" className="fil299" points="906.76,1355.51 61.24,1558.7 55.32,1532.69 "/>
              <polygon id="164" className="fil299" points="906.76,1355.51 56.51,1537.89 51.24,1511.73 "/>
              <polygon id="165" className="fil299" points="906.76,1355.51 52.29,1516.97 47.66,1490.7 "/>
              <polygon id="166" className="fil299" points="906.76,1355.51 48.58,1495.95 44.6,1469.57 "/>
              <polygon id="167" className="fil299" points="906.76,1355.51 45.4,1474.85 42.06,1448.38 "/>
              <polygon id="168" className="fil299" points="906.76,1355.51 42.72,1453.67 40.04,1427.13 "/>
              <polygon id="169" className="fil300" points="906.76,1355.51 40.58,1432.44 38.54,1405.84 "/>
              <polygon id="170" className="fil300" points="906.76,1355.51 38.95,1411.16 37.57,1384.51 "/>
              <polygon id="171" className="fil300" points="906.76,1355.51 37.85,1389.85 37.12,1363.17 "/>
              <polygon id="172" className="fil300" points="906.76,1355.51 37.26,1368.5 37.19,1341.83 "/>
              <polygon id="173" className="fil300" points="906.76,1355.51 37.21,1347.16 37.79,1320.5 "/>
              <polygon id="174" className="fil300" points="906.76,1355.51 37.68,1325.83 38.92,1299.18 "/>
              <polygon id="175" className="fil300" points="906.76,1355.51 38.67,1304.51 40.55,1277.89 "/>
              <polygon id="176" className="fil300" points="906.76,1355.51 40.18,1283.22 42.72,1256.66 "/>
              <polygon id="177" className="fil300" points="906.76,1355.51 42.21,1261.97 45.41,1235.49 "/>
              <polygon id="178" className="fil300" points="906.76,1355.51 44.77,1240.79 48.61,1214.38 "/>
              <polygon id="179" className="fil300" points="906.76,1355.51 47.84,1219.67 52.34,1193.37 "/>
              <polygon id="180" className="fil300" points="906.76,1355.51 51.44,1198.63 56.57,1172.45 "/>
              <polygon id="181" className="fil300" points="906.76,1355.51 55.54,1177.69 61.32,1151.64 "/>
              <polygon id="182" className="fil300" points="906.76,1355.51 60.16,1156.85 66.57,1130.95 "/>
              <polygon id="183" className="fil300" points="906.76,1355.51 65.29,1136.13 72.34,1110.4 "/>
              <polygon id="184" className="fil300" points="906.76,1355.51 70.93,1115.54 78.61,1090 "/>
              <polygon id="185" className="fil300" points="906.76,1355.51 77.07,1095.1 85.37,1069.76 "/>
              <polygon id="186" className="fil300" points="906.76,1355.51 83.71,1074.82 92.63,1049.69 "/>
              <polygon id="187" className="fil300" points="906.76,1355.51 90.85,1054.71 100.38,1029.79 "/>
              <polygon id="188" className="fil301" points="906.76,1355.51 98.47,1034.78 108.62,1010.1 "/>
              <polygon id="189" className="fil301" points="906.76,1355.51 106.59,1015.04 117.34,990.62 "/>
              <polygon id="190" className="fil301" points="906.76,1355.51 115.18,995.5 126.52,971.36 "/>
              <polygon id="191" className="fil301" points="906.76,1355.51 124.25,976.18 136.19,952.32 "/>
              <polygon id="192" className="fil301" points="906.76,1355.51 133.8,957.1 146.32,933.54 "/>
              <polygon id="193" className="fil301" points="906.76,1355.51 143.81,938.25 156.9,915 "/>
              <polygon id="194" className="fil301" points="906.76,1355.51 154.28,919.65 167.94,896.73 "/>
              <polygon id="195" className="fil301" points="906.76,1355.51 165.2,901.31 179.42,878.73 "/>
              <polygon id="196" className="fil301" points="906.76,1355.51 176.57,883.25 191.34,861.03 "/>
              <polygon id="197" className="fil301" points="906.76,1355.51 188.39,865.48 203.69,843.62 "/>
              <polygon id="198" className="fil301" points="906.76,1355.51 200.63,847.99 216.46,826.52 "/>
              <polygon id="199" className="fil301" points="906.76,1355.51 213.3,830.82 229.66,809.74 "/>
              <polygon id="200" className="fil301" points="906.76,1355.51 226.38,813.95 243.26,793.29 "/>
              <polygon id="201" className="fil301" points="906.76,1355.51 239.88,797.42 257.25,777.18 "/>
              <polygon id="202" className="fil301" points="906.76,1355.51 253.77,781.22 271.64,761.41 "/>
              <polygon id="203" className="fil301" points="906.76,1355.51 268.07,765.37 286.4,746 "/>
              <polygon id="204" className="fil301" points="906.76,1355.51 282.74,749.88 301.55,730.97 "/>
              <polygon id="205" className="fil301" points="906.76,1355.51 297.78,734.74 317.06,716.29 "/>
              <polygon id="206" className="fil301" points="906.76,1355.51 313.21,719.98 332.92,702.02 "/>
              <polygon id="207" className="fil259" points="906.76,1355.51 328.98,705.61 349.13,688.13 "/>
              <polygon id="208" className="fil259" points="906.76,1355.51 345.11,691.63 365.69,674.65 "/>
              <polygon id="209" className="fil259" points="906.76,1355.51 361.56,678.04 382.56,661.58 "/>
              <polygon id="210" className="fil259" points="906.76,1355.51 378.35,664.87 399.75,648.92 "/>
              <polygon id="211" className="fil259" points="906.76,1355.51 395.46,652.11 417.23,636.69 "/>
              <polygon id="212" className="fil259" points="906.76,1355.51 412.88,639.77 435.02,624.89 "/>
              <polygon id="213" className="fil259" points="906.76,1355.51 430.59,627.86 453.1,613.53 "/>
              <polygon id="214" className="fil259" points="906.76,1355.51 448.59,616.41 471.44,602.63 "/>
              <polygon id="215" className="fil259" points="906.76,1355.51 466.87,605.38 490.05,592.17 "/>
              <polygon id="216" className="fil259" points="906.76,1355.51 485.41,594.82 508.9,582.18 "/>
              <polygon id="217" className="fil259" points="906.76,1355.51 504.2,584.7 528,572.64 "/>
              <polygon id="218" className="fil259" points="906.76,1355.51 523.25,575.05 547.33,563.58 "/>
              <polygon id="219" className="fil259" points="906.76,1355.51 542.52,565.88 566.87,555 "/>
              <polygon id="220" className="fil259" points="906.76,1355.51 562.01,557.18 586.63,546.9 "/>
              <polygon id="221" className="fil259" points="906.76,1355.51 581.7,548.96 606.56,539.29 "/>
              <polygon id="222" className="fil259" points="906.76,1355.51 601.59,541.22 626.68,532.17 "/>
              <polygon id="223" className="fil259" points="906.76,1355.51 621.67,533.98 646.98,525.54 "/>
              <polygon id="224" className="fil259" points="906.76,1355.51 641.91,527.23 667.43,519.42 "/>
              <polygon id="225" className="fil259" points="906.76,1355.51 662.32,520.98 688.01,513.79 "/>
              <polygon id="226" className="fil262" points="906.76,1355.51 682.87,515.23 708.73,508.68 "/>
              <polygon id="227" className="fil262" points="906.76,1355.51 703.56,509.99 729.58,504.08 "/>
              <polygon id="228" className="fil262" points="906.76,1355.51 724.37,505.26 750.52,499.98 "/>
              <polygon id="229" className="fil262" points="906.76,1355.51 745.29,501.04 771.57,496.4 "/>
              <polygon id="230" className="fil262" points="906.76,1355.51 766.31,497.34 792.69,493.35 "/>
              <polygon id="231" className="fil262" points="906.76,1355.51 787.42,494.14 813.88,490.81 "/>
              <polygon id="232" className="fil262" points="906.76,1355.51 808.59,491.48 835.14,488.79 "/>
              <polygon id="233" className="fil262" points="906.76,1355.51 829.82,489.33 856.43,487.29 "/>
              <polygon id="234" className="fil262" points="906.76,1355.51 851.11,487.7 877.75,486.32 "/>
              <polygon id="235" className="fil262" points="906.76,1355.51 872.42,486.59 899.08,485.87 "/>
              <polygon id="236" className="fil262" points="906.76,1355.51 893.75,486.01 920.43,485.95 "/>
              <polygon id="237" className="fil262" points="906.76,1355.51 915.09,485.96 941.77,486.54 "/>
              <polygon id="238" className="fil262" points="906.76,1355.51 936.43,486.42 963.08,487.66 "/>
              <polygon id="239" className="fil262" points="906.76,1355.51 957.76,487.41 984.36,489.31 "/>
              <polygon id="240" className="fil262" points="906.76,1355.51 979.04,488.93 1005.59,491.47 "/>
              <polygon id="241" className="fil262" points="906.76,1355.51 1000.28,490.96 1026.77,494.15 "/>
              <polygon id="242" className="fil262" points="906.76,1355.51 1021.47,493.52 1047.87,497.36 "/>
              <polygon id="243" className="fil262" points="906.76,1355.51 1042.6,496.59 1068.9,501.08 "/>
              <polygon id="244" className="fil262" points="906.76,1355.51 1063.63,500.18 1089.81,505.31 "/>
              <polygon id="245" className="fil265" points="906.76,1355.51 1084.58,504.29 1110.62,510.06 "/>
              <polygon id="246" className="fil265" points="906.76,1355.51 1105.41,508.91 1131.31,515.33 "/>
              <polygon id="247" className="fil265" points="906.76,1355.51 1126.13,514.04 1151.85,521.09 "/>
              <polygon id="248" className="fil265" points="906.76,1355.51 1146.71,519.68 1172.26,527.35 "/>
              <polygon id="249" className="fil265" points="906.76,1355.51 1167.15,525.82 1192.51,534.12 "/>
              <polygon id="250" className="fil265" points="906.76,1355.51 1187.43,532.46 1212.58,541.38 "/>
              <polygon id="251" className="fil265" points="906.76,1355.51 1207.55,539.59 1232.46,549.13 "/>
              <polygon id="252" className="fil265" points="906.76,1355.51 1227.48,547.23 1252.16,557.37 "/>
              <polygon id="253" className="fil265" points="906.76,1355.51 1247.22,555.33 1271.64,566.08 "/>
              <polygon id="254" className="fil265" points="906.76,1355.51 1266.75,563.94 1290.91,575.28 "/>
              <polygon id="255" className="fil266" points="906.76,1355.51 1286.07,573.01 1305.16,582.55 "/>
            </g>
          </g>
          <circle className="fil302" cx="906.76" cy="1355.5" r="444.19"/>
          <path className="fil303" d="M1255.18 1355.64c0,95.96 -33.99,177.87 -102.1,245.71 -67.97,67.83 -150.18,101.76 -246.32,101.76 -96.14,0 -178.35,-33.93 -246.32,-101.76 -68.12,-67.84 -102.1,-149.75 -102.1,-245.71 0,-95.96 33.98,-178.01 102.1,-245.85 67.97,-67.99 150.18,-101.91 246.32,-101.91 96.14,0 178.35,33.92 246.32,101.91 68.11,67.84 102.1,149.89 102.1,245.85zm-476.55 186.08c0,6.33 -2.24,11.72 -6.77,16.21 -4.53,4.49 -9.9,6.73 -16.21,6.73 -6.4,0 -11.82,-2.24 -16.31,-6.73 -4.43,-4.49 -6.67,-9.87 -6.67,-16.21 0,-6.33 2.24,-11.76 6.67,-16.25 4.49,-4.44 9.9,-6.68 16.31,-6.68 6.3,0 11.68,2.24 16.21,6.68 4.53,4.49 6.77,9.91 6.77,16.25zm419.61 -186.08c0,-80.45 -28.46,-149.03 -85.4,-205.99 -42.66,-42.7 -92.07,-69.44 -148.19,-80.09 1.17,1.05 2.33,2.13 3.47,3.25 17.22,17.05 25.73,37.7 25.73,61.77 0,24.06 -8.51,44.53 -25.73,61.57 -17.21,17.05 -37.62,25.58 -61.58,25.58 -24.32,0 -44.9,-8.53 -61.93,-25.58 -16.86,-17.04 -25.39,-37.51 -25.39,-61.57 0,-24.07 8.52,-44.72 25.39,-61.77 1.05,-1.05 2.13,-2.06 3.21,-3.05 -55.71,10.78 -104.82,37.46 -147.44,79.9 -56.93,56.97 -85.55,125.54 -85.55,205.99 0,80.45 28.62,149.02 85.55,205.7 57.08,56.83 125.77,85.24 206.38,85.24 80.61,0 149.3,-28.41 206.08,-85.24 56.94,-56.68 85.4,-125.25 85.4,-205.7zm-452.2 65.22c0,12.66 -4.48,23.43 -13.54,32.39 -9.06,8.98 -19.81,13.47 -32.41,13.47 -12.8,0 -23.64,-4.49 -32.6,-13.47 -8.87,-8.96 -13.35,-19.74 -13.35,-32.39 0,-12.68 4.48,-23.53 13.35,-32.51 8.96,-8.88 19.81,-13.36 32.6,-13.36 12.61,0 23.35,4.48 32.41,13.36 9.06,8.98 13.54,19.84 13.54,32.51zm52.19 -166.61c0,17.73 -6.27,32.81 -18.96,45.36 -12.69,12.57 -27.73,18.85 -45.37,18.85 -17.91,0 -33.1,-6.28 -45.65,-18.85 -12.42,-12.55 -18.7,-27.63 -18.7,-45.36 0,-17.73 6.27,-32.94 18.7,-45.51 12.55,-12.43 27.74,-18.71 45.65,-18.71 17.64,0 32.68,6.27 45.37,18.71 12.69,12.57 18.96,27.78 18.96,45.51zm175.46 341.12l-134.31 0 0 -352.43 134.26 0 0.05 352.43z"/>
        </g>
      </g>
    </svg>
  );
};
