import { IProntuarioNutricaoListagem, IProntuarioNutricaoListagemBackend } from "../types";

export const parseProntuarioNutricaoBackToFront = (dadosBackend: IProntuarioNutricaoListagemBackend): IProntuarioNutricaoListagem => ({
  id: dadosBackend.id,
  oral: dadosBackend.oral,
  aceitacao: dadosBackend.aceitacao,
  tipo: dadosBackend.tipo,
  vazaoMl: dadosBackend.vazao_ml,
  tipoSonda: dadosBackend.tipo_sonda,
  dataInsercao: dadosBackend.data_insercao,
  npt: dadosBackend.npt,
  nptEtapa: dadosBackend.npt_etapa,
  vazaoMl2: dadosBackend.vazao_ml2,
  horarioInicio: dadosBackend.horario_inicio,
  nptLocal: dadosBackend.npt_local,
  dietaZero: dadosBackend.dieta_zero,
  dias: dadosBackend.dias,
  abdome: dadosBackend.abdome,
  rha: dadosBackend.rha,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
})
