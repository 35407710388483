import React from 'react'

import { Select } from '../select/Select'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../api/prontuario/types'
import { opcoesGrupo } from '../../pages/pacientes/prontuario/components/plano_cuidados/util'

interface ISelectGrupoProps {
  value: GrupoProntuarioPlanoCuidadoEnum | -1,
  gruposAlterados?: GrupoProntuarioPlanoCuidadoEnum[],
  onChange: (e: React.FormEvent<HTMLSelectElement>) => void
}

const SelectGrupo = (props: ISelectGrupoProps) => {
  return (
    <Select
      id='grupo'
      label='Grupo'
      value={props.value || -1}
      options={opcoesGrupo}
      onlyOptions={props.gruposAlterados}
      onChange={props.onChange}
    />
  )
}

export { SelectGrupo }
export default SelectGrupo
