import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { IIndicadorListagem } from '../../../../api/cadastro/indicadoresAPI';
import { indicadorListarAPI } from '../../../../api/cadastro/indicadoresAPI';

interface TIndicadoresContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  indicadores: IIndicadorListagem[],
  setIndicadores: (newIndicadores: IIndicadorListagem[]) => void,
  selecionado: IIndicadorListagem | null,
  setSelecionado: (novoSelecionado: IIndicadorListagem | null) => void,
  loadIndicadores: () => void
}

interface TIndicadoresProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  indicadores: [],
  setIndicadores: () => {
    throw new Error('setIndicadores não implementada');
  },
  loadIndicadores: () => {
    throw new Error('loadIndicadores não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const IndicadoresContext = createContext<TIndicadoresContext>(defaultState);

export const IndicadoresProvider = ({children}: TIndicadoresProviderProps) => {
  const [indicadores, setIndicadores] = useState<IIndicadorListagem[]>(defaultState.indicadores);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IIndicadorListagem | null>(defaultState.selecionado)

  const loadIndicadores = () => {
    setCarregando(true)
    indicadorListarAPI()
        .then((data) => setIndicadores(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadIndicadores();
  }, []);

  const providerValues: TIndicadoresContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    indicadores,
    setIndicadores,
    loadIndicadores,
  };

  return (
    <IndicadoresContext.Provider value={providerValues}>
      {children}
    </IndicadoresContext.Provider>
  );
};
