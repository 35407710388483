import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, ListGroup, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { GiFalling } from 'react-icons/gi'
import { BsBandaid, BsFillEyeFill } from 'react-icons/bs'
import { MdSick } from 'react-icons/md'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import { getAllToSelectLeitoAPI } from '../../../../../api/cadastro/leitosAPI'
import { IProntuarioDadosAdmissaoForm, IProntuarioListagem, prontuarioUpdateAPI } from '../../../../../api/prontuario'
import Button from '../../../../../components/Button'
import { DateTime } from '../../../../../components'
import { CheckboxStyled, InputStyled } from '../../../../../components/styles'
import { SelectAsync } from '../../../../../components/select-async'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivComponentsStyled, DivInternaStyled, ListGroupHeadStyled, ListGroupItemStyled } from '../styles'

interface IProntuarioDadosAdmissaoProps {
  accordionOpen: string[],
  setAccordionOpen: (d: string) => void,
}

export const getTipoSituacao = (prontuario: IProntuarioListagem | null) => {
  if (prontuario) {
    if (prontuario.cliMedica) {
      return 'cliMedica'
    }
    if (prontuario.cliCirurgica) {
      return 'cliCirurgica'
    }
    if (prontuario.uti) {
      return 'uti'
    }
    // if (prontuario.reinternacao) {
    //   return 'reinternacao'
    // }
  }
  return null
}

export const getTipoProcedencia = (prontuario: IProntuarioListagem | null) => {
  if (prontuario) {
    if (prontuario.procResidencia) {
      return 'procResidencia'
    }
    if (prontuario.procRua) {
      return 'procRua'
    }
    if (prontuario.procServSaude) {
      return 'procServSaude'
    }
    if (prontuario.procSetor) {
      return 'procSetor'
    }
  }
  return null
}

const ProntuarioDadosAdmissao = (props: IProntuarioDadosAdmissaoProps) => {
  // const [leitoKey, setLeitoKey] = useState<number>(0)
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);

  const initialValues: IProntuarioDadosAdmissaoForm = {
    nomeProntuario: prontuario?.nomeProntuario || null,
    dhAdmissao: prontuario?.dhAdmissao ? new Date(prontuario.dhAdmissao) : new Date(),
    idLeito: prontuario?.idLeito || null,
    tipoSituacao: getTipoSituacao(prontuario),
    tipoProcedencia: getTipoProcedencia(prontuario),
    procSetorNome: prontuario?.procSetorNome || null,
    isolProtetor: prontuario?.isolProtetor || false,
    isolRespiratorio: prontuario?.isolRespiratorio || false,
    isolContato: prontuario?.isolContato || false,
    isolVigilancia: prontuario?.isolVigilancia || false,
    riscoQueda: prontuario?.riscoQueda || false,
    riscoLesaoPressao: prontuario?.riscoLesaoPressao || false,
    riscoAlergia: prontuario?.riscoAlergia || false,
    riscoRessecamentoOcular: prontuario?.riscoRessecamentoOcular || false,
    queixaPrincipal: prontuario?.queixaPrincipal || null,
    comorbidades: prontuario?.comorbidades || null,
    medicacaoUso: prontuario?.medicacaoUso || null,
    // ocorrencias: prontuario?.ocorrencias || null,
    // eventosAdversos: prontuario?.eventosAdversos || null,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioDadosAdmissaoForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioUpdateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados admissão alterados com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      <DivAccordionStyled
        alterado={false}
        onClick={() => props.setAccordionOpen('dadosAdmissao')}
      >
        <div></div>
        <div>Dados admissão</div>
        <div style={{ cursor: 'pointer' }}>
          {props.accordionOpen.find(i => i === 'dadosAdmissao') ? (
            <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
          ) : (
            <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
          )}
        </div>
      </DivAccordionStyled>


      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'dadosAdmissao') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={4}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='nomeProntuario'>
                      # Prontuário
                    </label> 
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='nomeProntuario'
                      name='nomeProntuario'
                      value={values.nomeProntuario || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <DateTime
                    id='dhAdmissao'
                    label='Data/hora admissão'
                    maxDate={new Date()}
                    selected={values.dhAdmissao}
                    dateFormat="dd/MM/yyyy h:mm aa"
                    showTimeInput
                  />
                </Col>

                <Col md={4}>
                  <div style={{ display: 'flex' }}>
                    {/* <LeitosContainerForm modal atualizarLeitos={() => setLeitoKey((prev) => prev + 1)} /> */}

                    <SelectAsync
                      // key={leitoKey}
                      id='idLeito'
                      label='Leito'
                      value={values.idLeito || -1}
                      onChange={handleChange}
                      api={getAllToSelectLeitoAPI}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <ListGroup>
                    <ListGroupHeadStyled>
                      Situação
                    </ListGroupHeadStyled>

                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='cliMedica'
                          name='tipoSituacao'
                          value={'cliMedica'}
                          checked={values.tipoSituacao === 'cliMedica'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('cliMedica', true, false)
                            setFieldValue('cliCirurgica', false, false)
                            setFieldValue('uti', false, false)
                            setFieldValue('reinternacao', false, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="cliMedica">
                          Clín. médica
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>

                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='cliCirurgica'
                          name='tipoSituacao'
                          value={'cliCirurgica'}
                          checked={values.tipoSituacao === 'cliCirurgica'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('cliMedica', false, false)
                            setFieldValue('cliCirurgica', true, false)
                            setFieldValue('uti', false, false)
                            setFieldValue('reinternacao', false, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="cliCirurgica">
                          Clín. cirúrgica
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>

                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='uti'
                          name='tipoSituacao'
                          value={'uti'}
                          checked={values.tipoSituacao === 'uti'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('cliMedica', false, false)
                            setFieldValue('cliCirurgica', false, false)
                            setFieldValue('uti', true, false)
                            setFieldValue('reinternacao', false, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="uti">
                          UTI
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>

                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='reinternacao'
                          name='tipoSituacao'
                          value={'reinternacao'}
                          checked={values.tipoSituacao === 'reinternacao'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('cliMedica', false, false)
                            setFieldValue('cliCirurgica', false, false)
                            setFieldValue('uti', false, false)
                            setFieldValue('reinternacao', true, false)
                            handleChange(value)
                          }}
                        />
                          Reinternação
                        <label className="form-check-label form-label mb-0" htmlFor="reinternacao">
                        
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                  </ListGroup>
                </Col>

                <Col md={4}>
                  <ListGroup>
                    <ListGroupHeadStyled>
                      Procedência
                    </ListGroupHeadStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='procResidencia'
                          name='tipoProcedencia'
                          value={'procResidencia'}
                          checked={values.tipoProcedencia === 'procResidencia'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('procResidencia', true, false)
                            setFieldValue('procRua', false, false)
                            setFieldValue('procServSaude', false, false)
                            setFieldValue('procSetor', false, false)
                            setFieldValue('procSetorNome', null, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="residencia">
                          Residência
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='procRua'
                          name='tipoProcedencia'
                          value={'procRua'}
                          checked={values.tipoProcedencia === 'procRua'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('procResidencia', false, false)
                            setFieldValue('procRua', true, false)
                            setFieldValue('procServSaude', false, false)
                            setFieldValue('procSetor', false, false)
                            setFieldValue('procSetorNome', null, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="procRua">
                          Rua
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='procServSaude'
                          name='tipoProcedencia'
                          value={'procServSaude'}
                          checked={values.tipoProcedencia === 'procServSaude'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('residencia', false, false)
                            setFieldValue('procRua', false, false)
                            setFieldValue('procServSaude', true, false)
                            setFieldValue('procSetor', false, false)
                            setFieldValue('procSetorNome', null, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="procServSaude">
                          Serviço de saúde
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>

                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <input
                          type='radio'
                          style={{ marginRight: '8px' }}
                          id='procSetor'
                          name='tipoProcedencia'
                          value={'procSetor'}
                          checked={values.tipoProcedencia === 'procSetor'}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue('residencia', false, false)
                            setFieldValue('procRua', false, false)
                            setFieldValue('procServSaude', false, false)
                            setFieldValue('procSetor', true, false)
                            handleChange(value)
                          }}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="procSetor">
                          Setor
                        </label>

                        <div style={{ marginLeft: '12px' }}>
                          <InputStyled
                            name='procSetorNome'
                            id='procSetorNome'
                            placeholder='Setor?'
                            value={values.procSetorNome || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={values.tipoProcedencia !== 'procSetor'}
                          />
                        </div>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                  </ListGroup>
                </Col>

                <Col md={4}>
                  <ListGroup>
                    <ListGroupHeadStyled>
                      Isolamento
                    </ListGroupHeadStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <CheckboxStyled
                          className="form-check-input form-control"
                          style={{ marginRight: '6px' }}
                          type="checkbox"
                          id="isolProtetorCheckbox"
                          name='isolProtetor'
                          checked={values.isolProtetor}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="isolProtetorCheckbox">
                          Protetor
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <CheckboxStyled
                          className="form-check-input form-control"
                          style={{ marginRight: '6px' }}
                          type="checkbox"
                          id="isolRespiratorioCheckbox"
                          name='isolRespiratorio'
                          checked={values.isolRespiratorio}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="isolRespiratorioCheckbox">
                          Respiratório
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <CheckboxStyled
                          className="form-check-input form-control"
                          style={{ marginRight: '6px' }}
                          type="checkbox"
                          id="isolContatoCheckbox"
                          name='isolContato'
                          checked={values.isolContato}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="isolContatoCheckbox">
                          Contato
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                    <ListGroupItemStyled>
                      <DivComponentsStyled>
                        <CheckboxStyled
                          className="form-check-input form-control"
                          style={{ marginRight: '6px' }}
                          type="checkbox"
                          id="isolVigilanciaCheckbox"
                          name='isolVigilancia'
                          checked={values.isolVigilancia}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />

                        <label className="form-check-label form-label mb-0" htmlFor="isolVigilanciaCheckbox">
                          Vigilância
                        </label>
                      </DivComponentsStyled>
                    </ListGroupItemStyled>
                  </ListGroup>
                </Col>
              </Row>

              <div style={{ fontSize: '22px', fontWeight: '700' }}>
                Risco
              </div>
              <Row>
                <Col md={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <DivComponentsStyled>
                    <CheckboxStyled
                      className="form-check-input form-control"
                      style={{ marginRight: '6px' }}
                      type="checkbox"
                      id="riscoQuedaCheckbox"
                      name='riscoQueda'
                      checked={values.riscoQueda}
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <label className="form-check-label form-label mb-0" htmlFor="riscoQuedaCheckbox">
                      <GiFalling /> Queda
                    </label>
                  </DivComponentsStyled>

                  <DivComponentsStyled>
                    <CheckboxStyled
                      className="form-check-input form-control"
                      style={{ marginRight: '6px' }}
                      type="checkbox"
                      id="riscoLesaoPressaoCheckbox"
                      name='riscoLesaoPressao'
                      checked={values.riscoLesaoPressao}
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <label className="form-check-label form-label mb-0" htmlFor="riscoLesaoPressaoCheckbox">
                      <BsBandaid /> Lesão pressão
                    </label>
                  </DivComponentsStyled>

                  <DivComponentsStyled>
                    <CheckboxStyled
                      className="form-check-input form-control"
                      style={{ marginRight: '6px' }}
                      type="checkbox"
                      id="riscoAlergiaCheckbox"
                      name='riscoAlergia'
                      checked={values.riscoAlergia}
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <label className="form-check-label form-label mb-0" htmlFor="riscoAlergiaCheckbox">
                      <MdSick /> Alergia
                    </label>
                  </DivComponentsStyled>

                  <DivComponentsStyled>
                    <CheckboxStyled
                      className="form-check-input form-control"
                      style={{ marginRight: '6px' }}
                      type="checkbox"
                      id="riscoRessecamentoOcularCheckbox"
                      name='riscoRessecamentoOcular'
                      checked={values.riscoRessecamentoOcular}
                      disabled
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <label className="form-check-label form-label mb-0" htmlFor="riscoRessecamentoOcularCheckbox">
                      <BsFillEyeFill /> Ressecamento ocular
                    </label>
                  </DivComponentsStyled>
                </Col>
              </Row>

              <Row style={{ marginTop: '14px' }}>
                <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='queixaPrincipal'>
                      Queixa principal
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='queixaPrincipal'
                      name='queixaPrincipal'
                      value={values.queixaPrincipal || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='comorbidades'>
                      Comorbidades
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='comorbidades'
                      name='comorbidades'
                      value={values.comorbidades || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='medicacaoUso'>
                      Medicação em uso
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='medicacaoUso'
                      name='medicacaoUso'
                      value={values.medicacaoUso || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={() => submitForm()}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioDadosAdmissao }
export default ProntuarioDadosAdmissao
