import React, { useContext, useState } from 'react'
import { format } from 'date-fns'
import { Alert, Modal } from 'react-bootstrap'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'

import {
  GrupoProntuarioPlanoCuidadoEnum,
  IProntuarioPlanoCuidadoAtividade,
} from '../../../../../../../api/prontuario/types'
import { ProntuarioContext } from '../../../../context'
import { TableStyled } from '../../../../../../../components/styles'
import { parseGrupoPlanoCuidado } from '../../util'
import PlanoCuidadoAtividadesForm from './PlanoCuidadoAtividadesForm'
import { prontuarioPlanoCuidadoAtividadeDeleteAPI } from '../../../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import Swal from 'sweetalert2'
import { ModalPadrao } from '../../../../../../../components/modal'

interface IPlanoCuidadoAtividadesTabelaProps {
  idPlanoCuidado: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
}

const PlanoCuidadoAtividadesTabela = (props: IPlanoCuidadoAtividadesTabelaProps) => {
  const [dadosAtividade, setDadosAtividade] = useState<IProntuarioPlanoCuidadoAtividade>()
  const [modalEdicaoAberto, setModalEdicaoAberto] = useState<boolean>(false)
  const [modalConfirmacao, setModalConfirmacao] = useState<boolean>(false)
  const { prontuario, loadProntuario } = useContext(ProntuarioContext)
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.id === props.idPlanoCuidado)
  const solucao = {
    "INTEGRIDADE_FISICA": [
      "LESÃO POR PRESSÃO NO ADULTO",
      "PROTEÇÃO INEFICAZ",
      "RISCO DE INFECÇÃO",
      "RISCO DE INFECÇÃO NO SÍTIO CIRÚRGICO",
      "INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "RISCO DE INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INTEGRIDADADE DA PELE PREJUDICADA",
      "INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE LESÃO POR PRESSÃO NO ADULTO",
      "MOBILIDADE NO LEITO PREJUDICADA",
      "MOBILIDADE FÍSICA PREJUDICADA",
      "MOBILIDADE PREJUDICADA COM CADEIRA DE RODAS",
      "CAPACIDADE DE TRANSFERÊNCIA PREJUDICADA",
      "SENTAR-SE PREJUDICADO",
      "LESÃO POR PRESSÃO NO ADULTO",
      "PROTEÇÃO INEFICAZ",
      "RISCO DE INFECÇÃO",
      "RISCO DE INFECÇÃO NO SÍTIO CIRÚRGICO",
      "INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "RISCO DE INTEGRIDADE DA MEMBRANA MUCOSA ORAL PREJUDICADA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INTEGRIDADADE DA PELE PREJUDICADA",
      "INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE INTEGRIDADE TISSULAR PREJUDICADA",
      "RISCO DE LESÃO POR PRESSÃO NO ADULTO",
      "MOBILIDADE NO LEITO PREJUDICADA",
      "MOBILIDADE FÍSICA PREJUDICADA",
      "MOBILIDADE PREJUDICADA COM CADEIRA DE RODAS",
      "CAPACIDADE DE TRANSFERÊNCIA PREJUDICADA",
      "SENTAR-SE PREJUDICADO"
    ],
    "FUNCAO_RESPIRATORIA": [
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "TROCA DE GASES PREJUDICADA",
      "RESPOSTA DISFUNCIONAL AO DESMAME VENTILATÓRIO DO ADULTO",
      "VENTILAÇÃO ESPONTÂNEA",
      "DESOBSTRUÇÃO INEFICAZ DE VIAS AÉREAS",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "RISCO DE ASPIRAÇÃO",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "TROCA DE GASES PREJUDICADA",
      "RESPOSTA DISFUNCIONAL AO DESMAME VENTILATÓRIO DO ADULTO",
      "VENTILAÇÃO ESPONTÂNEA",
      "DESOBSTRUÇÃO INEFICAZ DE VIAS AÉREAS",
      "PADRÃO RESPIRATÓRIO INEFICAZ",
      "RISCO DE ASPIRAÇÃO"
    ],
    "SEG_FISICA_EMOCIONAL": [
      "RISCO DE QUEDAS NO ADULTO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS",
      "PROCESSOS FAMILIARES INTERROMPIDOS",
      "ANSIEDADE",
      "ANSIEDADE RELACIONADA A MORTE",
      "MEDO",
      "RISCO DE REAÇÃO ALÉRGICA",
      "CONTROLE EMOCIONAL LÁBIL",
      "DESESPERANÇA",
      "SÍNDROME DE ESTRESSE POR MUDANÇA",
      "SÍNDROME PÓS-TRAUMA",
      "TRISTEZA CRÔNICA",
      "RISCO DE QUEDAS NO ADULTO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS",
      "PROCESSOS FAMILIARES INTERROMPIDOS",
      "ANSIEDADE",
      "ANSIEDADE RELACIONADA A MORTE",
      "MEDO",
      "RISCO DE REAÇÃO ALÉRGICA",
      "CONTROLE EMOCIONAL LÁBIL",
      "DESESPERANÇA",
      "SÍNDROME DE ESTRESSE POR MUDANÇA",
      "SÍNDROME PÓS-TRAUMA",
      "TRISTEZA CRÔNICA"
    ],
    "EXAME_FISICO": [
      "RISCO DE GLICEMIA INSTÁVEL",
      "HIPOTERMIA",
      "HIPERTERMIA",
      "RISCO DE GLICEMIA INSTÁVEL",
      "HIPOTERMIA",
      "HIPERTERMIA",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL"
    ],
    "PERCEPCAO_SENTIDOS": [
      "DOR AGUDA",
      "DOR CRÔNICA",
      "CONFORTO PREJUDICADO",
      "DOR AGUDA",
      "DOR CRÔNICA",
      "CONFORTO PREJUDICADO"
    ],
    "SONO_REPOUSO": [
      "INSÔNIA",
      "DISTÚRBIO NO PADRÃO DE SONO",
      "PRIVAÇÃO DE SONO",
      "INSÔNIA",
      "DISTÚRBIO NO PADRÃO DE SONO",
      "PRIVAÇÃO DE SONO"
    ],
    "FUNCAO_NEUROLOGICA": [
      "CONFUSÃO AGUDA",
      "RISCO DE CONFUSÃO AGUDA",
      "CONFUSÃO CRÔNICA",
      "MEMÓRIA PREJUDICADA",
      "RISCO DE RESSECAMENTO OCULAR",
      "AUTOGESTÃO INEFICAZ DO RESSECAMENTO OCULAR",
      "RISCO DE LESÃO NA CÓRNEA",
      "CONFUSÃO AGUDA",
      "RISCO DE CONFUSÃO AGUDA",
      "CONFUSÃO CRÔNICA",
      "MEMÓRIA PREJUDICADA",
      "RISCO DE RESSECAMENTO OCULAR",
      "AUTOGESTÃO INEFICAZ DO RESSECAMENTO OCULAR",
      "RISCO DE LESÃO NA CÓRNEA"
    ],
    "FUNCAO_CARDIOVASCULAR": [
      "RISCO DE CHOQUE",
      "VOLUME DE LÍQUIDOS EXCESSIVO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE FUNÇÃO CARDIOVASCULAR PREJUDICADA",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "RISCO DE CHOQUE",
      "VOLUME DE LÍQUIDOS EXCESSIVO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE DÉBITO CARDÍACO DIMINUÍDO",
      "RISCO DE FUNÇÃO CARDIOVASCULAR PREJUDICADA",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ"
    ],
    "CUIDADO_CORPORAL": [
      "DÉFICIT NO AUTOCUIDADO PARA O BANHO",
      "DÉFICIT NO AUTOCUIDADO PARA O BANHO",
      "DÉFICIT NO AUTOCUIDADO PARA ALIMENTAÇÃO",
      "DÉFICIT NO AUTOCUIDADO PARA HIGIENE ÍNTIMA",
      "DÉFICIT NO AUTOCUIDADO PARA VESTIR-SE",
      "DISPOSIÇÃO PARA AUTOCUIDADO MELHORADA",
      "AUTONEGLIGÊNCIA"
    ],
    "REGISTRO_COMPLEMENTAR": [
      "SÍNDROME DE ABSTINÊNCIA DE SUBSTÂNCI AGUDA",
      "RISCO DE COMPORTAMENTO SUICIDA",
      "SÍNDROME DE ABSTINÊNCIA DE SUBSTÂNCI AGUDA",
      "RISCO DE COMPORTAMENTO SUICIDA",
      "RISCO DE VÍNCULO PREJUDICADO",
      "PROCESSOS FAMILIARES DISFUNCIONAIS ",
      "RECUPERAÇÃO CIRÚRGICA RETARDADA ",
      "RISCO DE RECUPERAÇÃO CIRÚRGICA RETARDADA"
    ],
    "ELIMINACAO_FISIOLOGICA": [
      "RETENÇÃO URINÁRIA",
      "RETENÇÃO URINÁRIA",
      "CONSTIPAÇÃO",
      "Risco de constipação ",
      "RISCO DE LESÃO NO TRATO URINÁRIO",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "RISCO DE VOLUME DE LÍQUIDOS DEFICIENTE",
      "RISCO DE DESEQUILÍBRIO DIMINUÍDO",
      "ELIMINAÇÃO URINÁRIA PREJUDICADA",
      "INCONTINÊNCIA URINÁRIA MISTA",
      "RISCO DE RETENÇÃO URINÁRIA",
      "DIARREIA",
      "MOTILIDADE GASTROINTESTINAL PREJUDICADA",
      "RISCO DE MOTILIDADE GASTROINTESTINAL PREJUDICADA"
    ],
    "NUTRICAO": [
      "Nutrição desequilibrada: menor que as necessidades corporais ",
      "Volume de líquidos deficiente ",
      "Deglutição prejudicada ",
      "Risco de glicemia instável ",
      "NUTRIÇÃO DESEQUILIBRADA: MENOR QUE AS NECESSIDADES CORPORAIS",
      "VOLUME DE LÍQUIDOS DEFICIENTE",
      "DEGLUTIÇÃO PREJUDICADA",
      "RISCO DE GLICEMIA INSTÁVEL",
      "RISCO DE ASPIRAÇÃO ",
      "NÁUSEA ",
      "DISPOSIÇÃO PARA NUTRIÇÃO MELHORADA",
      "RISCO DE VOLUME DE LÍQUIDO DEFICIENTE ",
      "RISCO DE DESEQUILÍBRIO ELETROLÍTICO"
    ],
    "SEXUALIDADE": [
      "DISFUNÇÃO SEXUAL"
    ],
    "COMUNICACAO_EDUCACAO": [
      "DISPOSIÇÃO PARA LETRAMENTO EM SAÚDE MELHORADO",
      "CONHECIMENTO DEFICIENTE",
      "COMUNICAÇÃO VERBAL PREJUDICADA",
      "DISPOSIÇÃO PARA COMUNICAÇÃO MELHORADA"
    ],
    "RELIGIOSIDADE_ESPIRITUALIDADE": [
      "RELIGIOSIDADE PREJUDICADA",
      "RISCO DE RELIGIOSIDADE PREJUDICADA",
      "DISPOSIÇÃO PARA RELIGIOSIDADE MELHORADA",
      "SOFRIMENTO ESPIRITUAL",
      "RISCO DE SOFRIMENTO ESPIRITUAL "
    ],
    "TERAPIA_ENDOVENOSA": [
      "RISCO DE TRAUMA VASCULAR",
      "RISCO DE REAÇÃO ALÉRGICA",
      "INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE INFECÇÃO"
    ],
    "CONTROLE_INFUSAO": [
      "RISCO DE SANGRAMENTO ",
      "RISCO DE TRAUMA VASCULAR",
      "RISCO DE REAÇÃO ALÉRGICA",
      "INTEGRIDADE DA PELE PREJUDICADA ",
      "RISCO DE INTEGRIDADE DA PELE PREJUDICADA",
      "RISCO DE CHOQUE",
      "RISCO DE PERFUSÃO TISSULAR CARDÍACA DIMINUÍDA",
      "RISCO DE PERFUSÃO TISSULAR CEREBRAL INEFICAZ",
      "PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ ",
      "RISCO DE PERFUSÃO TISSULAR PERIFÉRICA INEFICAZ",
      "RISCO DE PRESSÃO ARTERIAL INSTÁVEL",
      "RISCO DE TROMBOSE",
      "RISCO DE FUNÇÃO HEPÁTICA PREJUDICADA",
      "RISCO DE DESEQUILÍBRIO ELETROLÍTICO "
    ]
  }
  return (
    <>
      {planoCuidado && planoCuidado.prontuarioPlanoCuidadoAtividade.length > 0 ? (
        <TableStyled style={{ fontSize: '12px', color: '#304439' }}>
          <thead>
            <tr>
              <th>Grupo</th>
              <th>Diagnóstico</th>
              <th>Intervenção</th>
              <th>Atividade</th>
              <th>Aprazamento</th>
              <th>Inserido por</th>
              <th>Data</th>
              <th style={{ width: '1px' }} />
            </tr>
          </thead>
          <tbody> 
            {planoCuidado.prontuarioPlanoCuidadoAtividade.map(atividade => (
              <tr>
                <td>
                  {parseGrupoPlanoCuidado(atividade.grupo)}
                </td>
                <td>
                {solucao[atividade.grupo][atividade.idDiagnostico]}   
                </td>
                <td>
                  {atividade.intervencao.nome}
                </td>
                <td>
                  {atividade.atividade?.descricao}
                </td>
                <td>
                  {atividade.aprazamento.nome}
                </td>
                <td>
                  {atividade.usuario.nome}
                </td>
                <td>
                  {`${atividade.inserido ? format(new Date(atividade.inserido), 'dd/MM/yyyy') : ''}`}
                </td>

                <td style={{ cursor: 'pointer' }}>
                  <div style={{ display: 'flex' }}>
                    <FaEdit
                      size={18}
                      style={{ marginRight: '4px' }}
                      onClick={() => {
                        setDadosAtividade(atividade)
                        setModalEdicaoAberto(true)
                      }}
                    />

                    {/* <FaTrashAlt
                      size={18}
                      color='red'
                      onClick={() => {
                        prontuarioPlanoCuidadoAtividadeDeleteAPI(atividade.id)
                          .then(() => {
                            if (prontuario) loadProntuario(prontuario.id)
                            Swal.fire({
                              icon: 'success',
                              title: 'Ok...',
                              text: 'Atividade apagada com sucesso!',
                              confirmButtonColor: '#1A8C01',
                            })
                          })
                          .catch(err => {
                            console.log(err) 
                            Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: err.response.data.error,
                            });
                          })
                      }}
                    /> */}

                    <FaTrashAlt
                      size={18}
                      color='red'
                      onClick={() => {
                        setModalConfirmacao(true)
                      }}
                    />
                  
                <ModalPadrao key={0} acao={() => {
                        prontuarioPlanoCuidadoAtividadeDeleteAPI(atividade.id)
                          .then(() => {
                            if (prontuario) loadProntuario(prontuario.id)
                            Swal.fire({
                              icon: 'success',
                              title: 'Ok...',
                              text: 'Atividade apagada com sucesso!',
                              confirmButtonColor: '#1A8C01',
                            })
                          })
                          .catch(err => {
                            console.log(err) 
                            Swal.fire({
                              icon: 'error',
                              title: 'Oops...',
                              text: err.response.data.error,
                            });
                          })
                      }} fechaModal={setModalConfirmacao} titulo='Confirmação de Exclusão' descricao='Tem certeza de que deseja excluir esta atividade?'  visivelModal={modalConfirmacao} idApagar={0}
                  />


                    
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      ) : (
        <Alert>Inclua a primeira atividade para o plano de cuidados</Alert>
      )}

      <Modal
        show={modalEdicaoAberto}
        onHide={() => setModalEdicaoAberto(false)}
        centered
        animation
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439'}}>
            Edição de atividade
          </Modal.Title>
        </Modal.Header> 
        <Modal.Body>
          <PlanoCuidadoAtividadesForm
            idPlanoCuidado={props.idPlanoCuidado}
            grupo={props.grupo}
            idDiagnostico={props.idDiagnostico}
            dadosAtividade={dadosAtividade}
            aoFinalizar={() => setModalEdicaoAberto(false)}
            editar
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { PlanoCuidadoAtividadesTabela }
export default PlanoCuidadoAtividadesTabela
