import React from 'react'
import { useFormikContext } from 'formik'
import { Col, Row } from 'react-bootstrap';

import { IBaterPontoEditForm } from '../../PassagemPlantao';
import { DateTime } from '../../../../../../components';

const BaterPontoEditForm = () => {
  const { values } = useFormikContext<IBaterPontoEditForm>()

  return (
    <>
      <Row>
        <Col md={6}>
          <DateTime
            label='Início'
            id='inicio'
            dateFormat="dd/MM/yyyy h:mm aa"
            maxDate={new Date()}
            selected={values.inicio}
            showTimeInput
          />
        </Col>

        <Col md={6}>
          <DateTime
            label='Fim'
            id='fim'
            dateFormat="Pp"
            className='form-input form-control'
            maxDate={new Date()}
            selected={values.fim}
            showTimeInput
          />
        </Col>
      </Row>
    </>
  )
}

export { BaterPontoEditForm }
export default BaterPontoEditForm
