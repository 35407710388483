import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Col, Row, Table } from 'react-bootstrap'

import { Select, Switch } from '../../../../../components'
import { InputStyled } from '../../../../../components/styles'
import { IRiscoQuedaForm, calcularRiscoQueda } from './RiscoQueda'

interface IRiscoQuedaFormProps {
  riscoQueda: number,
  setRiscoQueda: (novoValor: number) => void,
}

const RiscoQuedaForm = (props: IRiscoQuedaFormProps) => {
  const { values, setFieldValue, handleChange } = useFormikContext<IRiscoQuedaForm>()

  useEffect(() => {
    props.setRiscoQueda(
      calcularRiscoQueda(
        values.historicoQueda,
        values.diagnosticoSecundario,
        values.auxilioDeambulacao,
        values.terapiaEndovenosa,
        values.marcha,
        values.estadoMental,
      )
    )
  }, [values])

  return (
    <>
      <Row>
        <Col md={3}>
          <Switch
            label='Histórico de queda'
            name='historicoQueda'
            checked={values.historicoQueda || false}
            onChange={(value) => setFieldValue('historicoQueda', value, false)}
          />
        </Col>

        <Col md={3}>
          <Switch
            label='Diagnóstico secundario'
            name='diagnosticoSecundario'
            checked={values.diagnosticoSecundario || false}
            onChange={(value) => setFieldValue('diagnosticoSecundario', value, false)}
          />
        </Col>

        <Col md={3}>
          <div>
            <Select
              name='auxilioDeambulacao'
              id='auxilioDeambulacao'
              label='Auxílio na deambulação'
              value={values.auxilioDeambulacao || ''}
              options={[
                { label: 'Nenhum / Acamado / Auxílio por profissional da saúde', value: 0 },
                { label: 'Muletas / Bengala / Andador', value: 15 },
                { label: 'Mobiliário / Paredes', value: 30 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={3}>
          <Switch
            label='Terapia endovenosa'
            name='terapiaEndovenosa'
            checked={values.terapiaEndovenosa || false}
            onChange={(value) => setFieldValue('terapiaEndovenosa', value, false)}
          />
        </Col>

        <Col md={3}> 
          <div>
            <Select
              name='marcha'
              id='marcha'
              label='Marcha'
              value={values.marcha || ''}
              options={[
                { label: 'Normal / Sem deambulação / Acamado / Cadeira de rodas', value: 0 },
                { label: 'Fraca', value: 10 },
                { label: 'Comprometida / Cambaleante', value: 20 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={3}>
          <div style={{ marginBottom: '16px' }}>
            <Select
              name='estadoMental'
              id='estadoMental'
              label='Estado mental'
              value={values.estadoMental || ''}
              options={[
                { label: 'Orientado / Capaz quanto a sua capacidades / Limitação', value: 0 },
                { label: 'Superestima capacidade / Esquece limitações', value: 15 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{ margin: '10px 150px' }}>
            <div style={{ border: '1px solid #304439', borderRadius: '18px' }}>
              <div style={{ textAlign: 'center', color: '#304439', borderBottom: '1px solid #304439', padding: '12px', fontWeight: '700' }}>
                Classificação de risco para quedas
              </div>

              <Table style={{ color: '#304439', padding: '20px' }}>
                <thead>
                  <tr>
                    <th>Pontos</th>
                    <th style={{ width: '100px', textAlign: 'center' }}>Risco</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>0 a 24</td>
                    <td style={{ textAlign: 'center' }}>BAIXO</td>
                  </tr>

                  <tr>
                    <td>25 a 44</td>
                    <td style={{ textAlign: 'center' }}>MÉDIO</td>
                  </tr>

                  <tr>
                    <td>{`>/ 45`}</td>
                    <td style={{ textAlign: 'center' }}>ALTO</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{marginBottom: '16px', width: '20%', marginLeft: 'auto', marginRight: 'auto' }}>
            <label className="form-check-label form-label" htmlFor='riscoQueda' style={{ color: '#304439'}}>
              Soma total
            </label>

            <InputStyled
              type='number'
              className='form-input form-control w-100'
              id='riscoQueda'
              name='riscoQueda'
              value={String(props.riscoQueda) || ''}
              disabled
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export { RiscoQuedaForm }
export default RiscoQuedaForm
