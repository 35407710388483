import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { Col, Row, Spinner } from 'react-bootstrap'
import { FaSave } from 'react-icons/fa'

import { prontuarioRegistroComplementarCVCCreateAPI } from '../../../../../../api/prontuario/prontuarioRegistroComplementarAPI'
import { IProntuarioRegistroComplementarCVCForm, SimNaoNaoAplicaEnum } from '../../../../../../api/prontuario/types'
import Button from '../../../../../../components/Button'
import { TextAreaStyled } from '../../../../../../components/styles'
import { ProntuarioContext } from '../../../context'
import { Switch } from '../../../../../../components'

const RegistroComplementarCVC = () => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);

  const initialValues: IProntuarioRegistroComplementarCVCForm = {
    conectoresPrecisamTrocar: prontuario?.prontuarioRegistroComplementarCVC[0]?.conectoresPrecisamTrocar || SimNaoNaoAplicaEnum.NAO_APLICA,
    curativoPrecisamTrocar: prontuario?.prontuarioRegistroComplementarCVC[0]?.curativoPrecisamTrocar || SimNaoNaoAplicaEnum.NAO_APLICA,
    equipamentosComData: prontuario?.prontuarioRegistroComplementarCVC[0]?.equipamentosComData || SimNaoNaoAplicaEnum.NAO_APLICA,
    fluxoUninario: prontuario?.prontuarioRegistroComplementarCVC[0]?.fluxoUninario || SimNaoNaoAplicaEnum.NAO_APLICA,
    curativoSujo: prontuario?.prontuarioRegistroComplementarCVC[0]?.curativoSujo || SimNaoNaoAplicaEnum.NAO_APLICA,
    higenienizacaoMaos: prontuario?.prontuarioRegistroComplementarCVC[0]?.higenienizacaoMaos || false,
    usoGorroMascara: prontuario?.prontuarioRegistroComplementarCVC[0]?.usoGorroMascara || false,
    sf0_09: prontuario?.prontuarioRegistroComplementarCVC[0]?.sf0_09 || false,
    clorexina: prontuario?.prontuarioRegistroComplementarCVC[0]?.clorexina || false,
    gaze: prontuario?.prontuarioRegistroComplementarCVC[0]?.gaze || false,
    filmeTransparente: prontuario?.prontuarioRegistroComplementarCVC[0]?.malAderido || false,
    malAderido: prontuario?.prontuarioRegistroComplementarCVC[0]?.malAderido || false,
    presencaSangue: prontuario?.prontuarioRegistroComplementarCVC[0]?.presencaSangue || false,
    presencaExsudato: prontuario?.prontuarioRegistroComplementarCVC[0]?.presencaExsudato || false,
    trocaRotina: prontuario?.prontuarioRegistroComplementarCVC[0]?.trocaRotina || false,
    opcaoFilme: prontuario?.prontuarioRegistroComplementarCVC[0]?.opcaoFilme || false,
    semSinaisFlosgisticos: prontuario?.prontuarioRegistroComplementarCVC[0]?.semSinaisFlosgisticos || false,
    presencaSinaisFlosgisticos: prontuario?.prontuarioRegistroComplementarCVC[0]?.presencaSinaisFlosgisticos || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioRegistroComplementarCVCForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioRegistroComplementarCVCCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de ICS associado ao uso CVC incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }
  return (
    <div style={{ marginTop: '16px'}}>
      <Formik
        key={formKey}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
          <>
            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Os conectores e equipo necessitam ser trocados?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='conectoresPrecisamTrocarSIM'
                  name='conectoresPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.conectoresPrecisamTrocar === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="conectoresPrecisamTrocarSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='conectoresPrecisamTrocarNAO'
                  name='conectoresPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.conectoresPrecisamTrocar === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="conectoresPrecisamTrocarNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='conectoresPrecisamTrocarNAO_APLICA'
                  name='conectoresPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.conectoresPrecisamTrocar === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="conectoresPrecisamTrocarNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O curativo venoso central necessita ser trocado? 
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoPrecisamTrocarSIM'
                  name='curativoPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.curativoPrecisamTrocar === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoPrecisamTrocarSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoPrecisamTrocarNAO'
                  name='curativoPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.curativoPrecisamTrocar === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoPrecisamTrocarNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoPrecisamTrocarNAO_APLICA'
                  name='curativoPrecisamTrocar'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.curativoPrecisamTrocar === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoPrecisamTrocarNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Os equipos estão com data?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='equipamentosComDataSIM'
                  name='equipamentosComData'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.equipamentosComData === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="equipamentosComDataSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='equipamentosComDataNAO'
                  name='equipamentosComData'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.equipamentosComData === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="equipamentosComDataNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='equipamentosComDataNAO_APLICA'
                  name='equipamentosComData'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.equipamentosComData === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="equipamentosComDataNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              O curativo apresenta sujidade ou descolamento da pele?
            </div>
            <Row>
              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoSujoSIM'
                  name='curativoSujo'
                  value={SimNaoNaoAplicaEnum.SIM}
                  checked={values.curativoSujo === SimNaoNaoAplicaEnum.SIM}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoSujoSIM">
                  SIM
                </label>
              </Col>

              <Col md={1}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoSujoNAO'
                  name='curativoSujo'
                  value={SimNaoNaoAplicaEnum.NAO}
                  checked={values.curativoSujo === SimNaoNaoAplicaEnum.NAO}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoSujoNAO">
                  NÃO
                </label>
              </Col>

              <Col md={2}>
                <input
                  type='radio'
                  style={{ marginRight: '8px' }}
                  id='curativoSujoNAO_APLICA'
                  name='curativoSujo'
                  value={SimNaoNaoAplicaEnum.NAO_APLICA}
                  checked={values.curativoSujo === SimNaoNaoAplicaEnum.NAO_APLICA}
                  onChange={handleChange}
                />

                <label className="form-check-label form-label mb-0" htmlFor="curativoSujoNAO_APLICA">
                  NÃO SE APLICA
                </label>
              </Col>
            </Row>

            <div style={{ fontWeight: '700', marginTop: '16px' }}>
              Registro do curativo do CVC:
            </div>
            <div style={{ fontWeight: '500' }}>
              1. Higienização das mãos e Paramentação: 
            </div>
            <Row>
              <Col md={3}>
                <Switch
                  label='Higienização das mãos'
                  name='higenienizacaoMaos'
                  checked={values.higenienizacaoMaos || false}
                  onChange={(value) => setFieldValue('higenienizacaoMaos', value, false)}
                />
              </Col>

              <Col md={3}>
                <Switch
                  label='Uso de Gorro e Máscara'
                  name='usoGorroMascara'
                  checked={values.usoGorroMascara || false}
                  onChange={(value) => setFieldValue('usoGorroMascara', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              2. Técnica asséptica:
            </div>
            <Row>
              <Col md={2}>
                <Switch
                  label='SF 0,9%'
                  name='sf0_09'
                  checked={values.sf0_09 || false}
                  onChange={(value) => setFieldValue('sf0_09', value, false)}
                />
              </Col>

              <Col md={4}>
                <Switch
                  label='Clorexidina Alcoólica ou aquosa'
                  name='clorexina'
                  checked={values.clorexina || false}
                  onChange={(value) => setFieldValue('clorexina', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              3. Cobertura:
            </div>
            <Row>
              <Col md={2}>
                <Switch
                  label='Gaze'
                  name='gaze'
                  checked={values.gaze || false}
                  onChange={(value) => setFieldValue('gaze', value, false)}
                />
              </Col>

              <Col md={4}>
                <Switch
                  label='Filme transparente'
                  name='filmeTransparente'
                  checked={values.filmeTransparente || false}
                  onChange={(value) => setFieldValue('filmeTransparente', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              4. Motivo da troca:
            </div>
            <Row>
              <Col md={2}>
                <Switch
                  label='Mal aderido'
                  name='malAderido'
                  checked={values.malAderido || false}
                  onChange={(value) => setFieldValue('malAderido', value, false)}
                />
              </Col>

              <Col md={2}>
                <Switch
                  label='Presença de sangue'
                  name='presencaSangue'
                  checked={values.presencaSangue || false}
                  onChange={(value) => setFieldValue('presencaSangue', value, false)}
                />
              </Col>

              <Col md={2}>
                <Switch
                  label='Presença de exsudato'
                  name='presencaExsudato'
                  checked={values.presencaExsudato || false}
                  onChange={(value) => setFieldValue('presencaExsudato', value, false)}
                />
              </Col>

              <Col md={2}>
                <Switch
                  label='Troca de rotina'
                  name='trocaRotina'
                  checked={values.trocaRotina || false}
                  onChange={(value) => setFieldValue('trocaRotina', value, false)}
                />
              </Col>

              <Col md={2}>
                <Switch
                  label='Opção por Filme'
                  name='opcaoFilme'
                  checked={values.opcaoFilme || false}
                  onChange={(value) => setFieldValue('opcaoFilme', value, false)}
                />
              </Col>
            </Row>

            <div style={{ fontWeight: '500' }}>
              5. Aspecto Sítio:
            </div>
            <Row>
              <Col md={3}>
                <Switch
                  label='Sem sinais flogísticos'
                  name='semSinaisFlosgisticos'
                  checked={values.semSinaisFlosgisticos || false}
                  onChange={(value) => setFieldValue('semSinaisFlosgisticos', value, false)}
                />
              </Col>

              <Col md={4}>
                <Switch
                  label='Presença de sinais flogísticos'
                  name='presencaSinaisFlosgisticos'
                  checked={values.presencaSinaisFlosgisticos || false}
                  onChange={(value) => setFieldValue('presencaSinaisFlosgisticos', value, false)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
              { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar
                        </div>
                      </Button>
                    </div>
                  )}
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  )
}

export { RegistroComplementarCVC }
export default RegistroComplementarCVC
