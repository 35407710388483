import { axiosAPI } from '../axiosAPI'
import { IProntuarioFuncaoNeuroAvaOcularForm, IProntuarioFuncaoNeuroAvaOcularListagem } from './types'

export const prontuarioFuncaoNeuroAvaOcularCreateAPI = async (
  idProntuario: number,
  values: IProntuarioFuncaoNeuroAvaOcularForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioavaliacaoneuro',
      {
        id_prontuario: idProntuario,
        consciente: values.consciente,
        inconsciente: values.inconsciente,
        orientado: values.orientado,
        desorientado: values.desorientado,
        torporoso: values.torporoso,
        sedacao: values.sedacao,
        comatoso: values.comatoso,
        glasgow_ao: values.glasgowAo,
        glasgow_rv: values.glasgowRv,
        glasgow_rm: values.glasgowRm,
        glasgow_rp: values.glasgowRp,
        glasgow_igual: values.glasgowIgual,
        ramsay: values.ramsay,
        rass: values.rass,
        convulsao: values.convulsao,
        convulsao_episodio: values.convulsaoEpisodio,
        pupila_isocoricas: values.pupilaIsocoricas,
        pupila_mioticas: values.pupilaMioticas,
        pupila_midriaticas: values.pupilaMidriaticas,
        pupila_fotoreagente: values.pupilaFotoreagente,
        pupila_nao_fotoreagente: values.pupilaNaoFotoreagente,
        pupila_ancoricas_de: values.pupilaAncoricasDe,
        pupila_ancoricas_ate: values.pupilaAncoricasAte,
        lagoftalmia_od: values.lagoftalmiaOd,
        lagoftalmia_oe: values.lagoftalmiaOe,
        hiperamia_conjuntival_od: values.hiperamiaConjuntivalOd,
        hiperamia_conjuntival_oe: values.hiperamiaConjuntivalOe,
        secrecao_od: values.secrecaoOd,
        secrecao_oe: values.secrecaoOe,
        edema_palpebral_od: values.edemaPalpebralOd,
        edema_palpebral_oe: values.edemaPalpebralOe,
        edema_conjuntival_od: values.edemaConjuntivalOd,
        edema_conjuntival_oe: values.edemaConjuntivalOe,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioFuncaoNeuroAvaOcularForm, IProntuarioFuncaoNeuroAvaOcularListagem }
