import { CKEditor } from '@ckeditor/ckeditor5-react'
import { EventInfo,  } from '@ckeditor/ckeditor5-utils'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useFormikContext } from 'formik'

interface ITextHTMLProps {
  id: string,
  label?: string,
  value?: string | null,
  placeholder?: string,
  linhas?: number,
  style?: object,
}

const TextHTML = (props: ITextHTMLProps) => {
  const { setFieldValue } = useFormikContext()

  return (
    <div style={{ marginBottom: '8px' }}>
      {props.label && (
        <label className="form-input-label form-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <CKEditor
        editor={ ClassicEditor }
        data={props.value}
        onChange={(event: EventInfo<string, unknown>, editor: ClassicEditor) => {
            setFieldValue(props.id, editor.getData(), false)
        } }
      />
    </div>
  )
}

export { TextHTML }
export default TextHTML
