import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { TiposInfusoesProvider } from './context';
import { TiposInfusoesContainerForm } from './TiposInfusoesContainerForm';

export interface ITiposInfusoesForm {
  nome: string,
  ativo: boolean,
}

const TiposInfusoes = () => {
  return (
    <TiposInfusoesProvider>
      <DivMainStyled>
        <DivCardStyled>
          <TiposInfusoesContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </TiposInfusoesProvider>
  )
}

export { TiposInfusoes }
export default TiposInfusoes
