import React from 'react'
import ReactSwitch from 'react-switch'
import { CheckboxStyled } from '../styles/Checkbox';

interface ISwitchProps {
  name: string;
  label: string;
  checked: boolean;
  labelStyle?: object;
  onChange: (e: boolean) => void
}

const Switch = (props: ISwitchProps) => {
  function teste(value:any){
    props.onChange(!props.checked)

  }
  return (
    <div style={{marginBottom: '16px', display: 'flex', flexDirection: 'row',}}>
    <CheckboxStyled
      className="form-check-input form-control"
      style={{ marginRight: '6px', backgroundColor: props.checked? "green" :"gray" }}
      type="checkbox"
      id="isolProtetorCheckbox"
      name='isolProtetor'
      checked={props.checked}
      disabled={false} 
      onChange={teste}  /> 
    <label className="form-check-label form-label" htmlFor={props.name} style={{  color: '#304439', marginTop: 4}}>
      {props.label}
    </label>    
  </div>
  )
  // <div style={{marginBottom: '16px', display: 'flex', flexDirection: 'column'}}>
  //   <label className="form-check-label form-label" htmlFor={props.name} style={{ ...props.labelStyle, color: '#304439' }}>
  //     {props.label}
  //   </label>

  //   <ReactSwitch
  //     offColor='#fff'
  //     onColor="#fff"
  //     offHandleColor='#ff9900'
  //     onHandleColor="#304439"
  //     handleDiameter={24}
  //     uncheckedIcon={false}
  //     checkedIcon={false}
  //     boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
  //     activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
  //     height={34}
  //     width={70}
  //     id={props.name}
  //     name={props.name}
  //     checked={props.checked}
  //     onChange={props.onChange}
  //   />
  // </div>
}

export { Switch }
export default Switch
