import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface IDispositivoListagem {
  id: number,
  nome: string,
  ativo: boolean,
}

export const dispositivoListarAPI = async (): Promise<IDispositivoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IDispositivoListagem[]> = await axiosAPI.get(
      'dispositivo',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectDispositivoAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IDispositivoListagem[]> = await axiosAPI.get(
      'dispositivo',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const dispositivoCreateAPI = async (nome: string, ativo: boolean): Promise<IDispositivoListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'dispositivo',
      { nome, ativo, id_empresa: user.idEmpresa },
  )

  return response.data
}

export const dispositivoUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
): Promise<IDispositivoListagem[]> => {
  const response = await axiosAPI.put(
      'dispositivo/edit',
      {id, nome, ativo},
  )

  return response.data
}

export const dispositivoDeleteAPI = async (id: number): Promise<IDispositivoListagem[]> => {
  const response = await axiosAPI.delete('dispositivo/delete', { params: { id } })

  return response.data
}
