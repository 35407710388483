import React, { useContext, useEffect, useState } from 'react'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import BlockUi from 'react-block-ui';
import Swal from 'sweetalert2';

import { diagnosticoCreateAPI, diagnosticoUpdateAPI } from '../../../api/cadastro/diagnosticosAPI'
import { IDiagnosticosForm } from './Diagnosticos';
import { DiagnosticosBotoes, DiagnosticosFormCampos, DiagnosticosListagem } from './components';
import { DiagnosticosContext } from './context';
import { GoPencil } from 'react-icons/go';
import { DivTitulo } from '../styles';

const DiagnosticosContainerForm = () => {
  const { carregando, loadDiagnosticos, setCarregando, selecionado, setSelecionado } = useContext(DiagnosticosContext);
  const [keyForm, setKeyForm] = useState<number>(0)

  const initialValues: IDiagnosticosForm = {
    descricao: selecionado?.descricao || '',
    inferido: selecionado?.inferido === false ? false : true,
    ativo: selecionado?.ativo === false ? false : true,
    grupo: selecionado?.grupo || -1,
  }

  const schema = Yup.object().shape({
    descricao: Yup.string().min(1, 'Muito curto!').max(250, 'Muito longo!').required('Campo obrigatório!'),
    inferido: Yup.bool(),
    ativo: Yup.bool(),
  })

  useEffect(() => {
    setKeyForm(prev => prev + 1)
  }, [selecionado])

  const handleSubmit = (
    values: IDiagnosticosForm,
    actions: FormikHelpers<IDiagnosticosForm>
  ) => {
    setCarregando(true)
    if (!selecionado) {
      diagnosticoCreateAPI(values.descricao, values.inferido, values.ativo, values.grupo)
        .then(() => {
          loadDiagnosticos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Diagnóstico de enfermagem criado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    } else {
      diagnosticoUpdateAPI(selecionado.id, values.descricao, values.inferido, values.ativo, values.grupo)
        .then(() => {
          setSelecionado(null)
          loadDiagnosticos()
          actions.resetForm()
  
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Diagnóstico de enfermagem editado com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)
  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <>
      <DivTitulo style={{ marginTop: '18px' }}>
        <GoPencil color='#304439' size={24} style={{ marginRight: '8px' }} />
        Cadastro - Diagnósticos de enfermagem
      </DivTitulo>

      <Formik
        key={keyForm}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <BlockUi tag="div" blocking={carregando}>
          <DiagnosticosFormCampos />

          <DiagnosticosBotoes />

          <DiagnosticosListagem />
        </BlockUi>
      </Formik>
    </>
  )
}

export { DiagnosticosContainerForm }
export default DiagnosticosContainerForm
