import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioEliminacoesFisiologicasForm,
  prontuarioEliminacoesFisiologicasCreateAPI,
} from '../../../../../api/prontuario/prontuarioEliminacoesFisiologicasAPI'
import { DateTime, Switch } from '../../../../../components'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { verificarAvaliadoNoDia } from '../../util'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { InputStyled, TextAreaStyled } from '../../../../../components/styles'

interface IProntuarioEliminacoesFisiologicasProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioEliminacoesFisiologicas = (props: IProntuarioEliminacoesFisiologicasProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.ELIMINACAO_FISIOLOGICA)

  const initialValues: IProntuarioEliminacoesFisiologicasForm = {
    wc: prontuario?.prontuarioEliminacaoFisio[0]?.wc || null,
    frauda: prontuario?.prontuarioEliminacaoFisio[0]?.frauda || null,
    svd: prontuario?.prontuarioEliminacaoFisio[0]?.svd || null,
    svdLocal: prontuario?.prontuarioEliminacaoFisio[0]?.svdLocal || null,
    svdData: prontuario?.prontuarioEliminacaoFisio[0]?.svdData ? new Date(prontuario?.prontuarioEliminacaoFisio[0]?.svdData) : null,
    sva: prontuario?.prontuarioEliminacaoFisio[0]?.sva || null,
    disUrinario: prontuario?.prontuarioEliminacaoFisio[0]?.disUrinario || null,
    outros: prontuario?.prontuarioEliminacaoFisio[0]?.outros || null,
    outrosDescricao: prontuario?.prontuarioEliminacaoFisio[0]?.outrosDescricao || null,
    limpida: prontuario?.prontuarioEliminacaoFisio[0]?.limpida || null,
    concentrada: prontuario?.prontuarioEliminacaoFisio[0]?.concentrada || null,
    piuria: prontuario?.prontuarioEliminacaoFisio[0]?.piuria || null,
    coluria: prontuario?.prontuarioEliminacaoFisio[0]?.coluria || null,
    hematuria: prontuario?.prontuarioEliminacaoFisio[0]?.hematuria || null,
    aspOutros: prontuario?.prontuarioEliminacaoFisio[0]?.aspOutros || null,
    aspOutrosDescricao: prontuario?.prontuarioEliminacaoFisio[0]?.aspOutrosDescricao || null,
    intesWc: prontuario?.prontuarioEliminacaoFisio[0]?.intesWc || null,
    intesFrauda: prontuario?.prontuarioEliminacaoFisio[0]?.intesFrauda || null,
    colostomia: prontuario?.prontuarioEliminacaoFisio[0]?.colostomia || null,
    ileostomia: prontuario?.prontuarioEliminacaoFisio[0]?.ileostomia || null,
    intesOutros: prontuario?.prontuarioEliminacaoFisio[0]?.intesOutros || null,
    intesOutrosDescricao: prontuario?.prontuarioEliminacaoFisio[0]?.intesOutrosDescricao || null,
    genitaliaIntegra: prontuario?.prontuarioEliminacaoFisio[0]?.genitaliaIntegra || null,
    normal: prontuario?.prontuarioEliminacaoFisio[0]?.normal || null,
    diareia: prontuario?.prontuarioEliminacaoFisio[0]?.diareia || null,
    pastoso: prontuario?.prontuarioEliminacaoFisio[0]?.pastoso || null,
    endurecido: prontuario?.prontuarioEliminacaoFisio[0]?.endurecido || null,
    constipacao: prontuario?.prontuarioEliminacaoFisio[0]?.constipacao || null,
    constipacaoDias: prontuario?.prontuarioEliminacaoFisio[0]?.constipacaoDias || null,
    observacao: prontuario?.prontuarioEliminacaoFisio[0]?.observacao || null,
    descricaoUrinaria: prontuario?.prontuarioEliminacaoFisio[0]?.descricaoUrinaria || null,
    descricaoIntestinal: prontuario?.prontuarioEliminacaoFisio[0]?.descricaoIntestinal || null,
    avaliado:  new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioEliminacaoFisio[0]?.avaliado),
    alterado: prontuario?.prontuarioEliminacaoFisio[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioEliminacoesFisiologicasForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioEliminacoesFisiologicasCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Eliminações fisiológicas incluídas com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('cuidadoCorporal'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
        {(!props.apenasAlterados || prontuario?.prontuarioEliminacaoFisio[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioEliminacaoFisio[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('eliminacoesFisiologicas')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Eliminações fisiológicas</div> 
            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'eliminacoesFisiologicas') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
        )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'eliminacoesFisiologicas') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={12}>
                  <div style={{ border: '1px solid #fff', borderRadius: '12px', width: '100%', padding: '6px' }}>
                    <div style={{ color: '#304439', fontSize: '18px', fontWeight: 700 }}>
                      Urinária
                    </div>
                    
                    <Row>
                      <Col md={2}>
                        <Switch
                          label='Wc'
                          name='wc'
                          checked={values.wc || false}
                          onChange={(value) => setFieldValue('wc', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Fralda'
                          name='frauda'
                          checked={values.frauda || false}
                          onChange={(value) => setFieldValue('frauda', value, false)}
                        />
                      </Col>

                      <Col md={1}>
                        <Switch
                          label='SVD'
                          name='svd'
                          checked={values.svd || false}
                          onChange={(value) => setFieldValue('svd', value, false)}
                        />
                      </Col>

                      <Col md={3}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='svdLocal'>
                            SVD Local
                          </label> 
                          <InputStyled
                            type='text'
                            className='form-input form-control w-100'
                            id='svdLocal'
                            name='svdLocal'
                            value={values.svdLocal || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <DateTime
                          label='SVD Data'
                          id='svdData'
                          maxDate={new Date()}
                          selected={values.svdData}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={2}>
                        <Switch
                          label='SVA'
                          name='sva'
                          checked={values.sva || false}
                          onChange={(value) => setFieldValue('sva', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Disp. Urinário'
                          name='disUrinario'
                          checked={values.disUrinario || false}
                          onChange={(value) => setFieldValue('disUrinario', value, false)}
                        />
                      </Col>

                      <Col md={1}>
                        <Switch
                          label='Outro'
                          name='outros'
                          checked={values.outros || false}
                          onChange={(value) => setFieldValue('outros', value, false)}
                        />
                      </Col>

                      <Col md={3}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='outrosDescricao'>
                            Outro descrição
                          </label>

                          <InputStyled
                            type='text'
                            className='form-input form-control w-100'
                            id='outrosDescricao'
                            name='outrosDescricao'
                            value={values.outrosDescricao || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div style={{ color: '#304439', fontSize: '18px', fontWeight: 700 }}>Aspecto:</div>

                    <Row>
                      <Col md={2}>
                        <Switch
                          label='Límpida'
                          name='limpida'
                          checked={values.limpida || false}
                          onChange={(value) => setFieldValue('limpida', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Concent.'
                          name='concentrada'
                          checked={values.concentrada || false}
                          onChange={(value) => setFieldValue('concentrada', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Piúria'
                          name='piuria'
                          checked={values.piuria || false}
                          onChange={(value) => setFieldValue('piuria', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Colúria'
                          name='coluria'
                          checked={values.coluria || false}
                          onChange={(value) => setFieldValue('coluria', value, false)}
                        />
                      </Col>

                      <Col md={1}>
                        <Switch
                          label='Outro'
                          name='aspOutros'
                          checked={values.aspOutros || false}
                          onChange={(value) => setFieldValue('aspOutros', value, false)}
                        />
                      </Col>

                      <Col md={3}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='aspOutrosDescricao'>
                            Outro descrição
                          </label>

                          <InputStyled
                            type='text'
                            className='form-input form-control w-100'
                            id='aspOutrosDescricao'
                            name='aspOutrosDescricao'
                            value={values.aspOutrosDescricao || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='descricaoUrinaria'>
                            Descrição geral
                          </label>

                          <TextAreaStyled
                            className='form-input form-control w-100'
                            id='descricaoUrinaria'
                            name='descricaoUrinaria'
                            value={values.descricaoUrinaria || ''}
                            rows={2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: '16px' }}>
                <Col md={9}>
                  <div style={{ border: '1px solid #fff', borderRadius: '12px', width: '100%', padding: '6px' }}>
                    <div style={{ color: '#304439', fontSize: '18px', fontWeight: 700 }}>Intestinal</div>
                    
                    <Row>
                      <Col md={2}>
                        <Switch
                          label='Wc'
                          name='itesWc'
                          checked={values.intesWc || false}
                          onChange={(value) => setFieldValue('intesWc', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Fralda'
                          name='intesFrauda'
                          checked={values.intesFrauda || false}
                          onChange={(value) => setFieldValue('intesFrauda', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Colostomia'
                          name='colostomia'
                          checked={values.colostomia || false}
                          onChange={(value) => setFieldValue('colostomia', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Ileostomia'
                          name='ileostomia'
                          checked={values.ileostomia || false}
                          onChange={(value) => setFieldValue('ileostomia', value, false)}
                        />
                      </Col>

                      <Col md={1}>
                        <Switch
                          label='Outro'
                          name='intesOutros'
                          checked={values.intesOutros || false}
                          onChange={(value) => setFieldValue('intesOutros', value, false)}
                        />
                      </Col>

                      <Col md={3}>
                        <div style={{marginBottom: '16px', marginLeft: '22px'}}>
                          <label className="form-check-label form-label" htmlFor='intesOutrosDescricao'>
                            Outro descrição
                          </label>

                          <InputStyled
                            type='text'
                            className='form-input form-control w-100'
                            id='intesOutrosDescricao'
                            name='intesOutrosDescricao'
                            value={values.intesOutrosDescricao || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <div style={{ color: '#304439', fontSize: '18px', fontWeight: 700 }}>Aspecto:</div>

                    <Row>
                      <Col md={2}>
                        <Switch
                          label='Normal'
                          name='normal'
                          checked={values.normal || false}
                          onChange={(value) => setFieldValue('normal', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Diarréia'
                          name='diareia'
                          checked={values.diareia || false}
                          onChange={(value) => setFieldValue('diareia', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Pastoso'
                          name='pastoso'
                          checked={values.pastoso || false}
                          onChange={(value) => setFieldValue('pastoso', value, false)}
                        />
                      </Col>

                      <Col md={2}>
                        <Switch
                          label='Endurecisa'
                          name='endurecido'
                          checked={values.endurecido || false}
                          onChange={(value) => setFieldValue('endurecido', value, false)}
                        />
                      </Col>

                      <Col md={1}>
                        <Switch
                          label='Constipação'
                          name='constipacao'
                          checked={values.constipacao || false}
                          onChange={(value) => setFieldValue('constipacao', value, false)}
                        />
                      </Col>

                      <Col md={3}>
                        <div style={{marginBottom: '16px', marginLeft: '36px'}}>
                          <label className="form-check-label form-label" htmlFor='constipacaoDias'>
                            Dias
                          </label>

                          <InputStyled
                            type='number'
                            className='form-input form-control w-100'
                            id='constipacaoDias'
                            name='constipacaoDias'
                            value={values.constipacaoDias || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='descricaoIntestinal'>
                            Descrição geral
                          </label>

                          <TextAreaStyled
                            className='form-input form-control w-100'
                            id='descricaoIntestinal'
                            name='descricaoIntestinal'
                            value={values.descricaoIntestinal || ''}
                            rows={2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col md={3}>
                <div style={{ border: '1px solid #fff', borderRadius: '12px', width: '100%', padding: '6px' }}>
                    <div style={{ color: '#304439', fontSize: '18px', fontWeight: 700 }}>Genitália</div>
                    
                    <Row>
                      <Col md={12}>
                        <Switch
                          label='Integra'
                          name='genitaliaIntegra'
                          checked={values.genitaliaIntegra || false}
                          onChange={(value) => setFieldValue('genitaliaIntegra', value, false)}
                        />
                      </Col>

                      <Col md={12}>
                        <div style={{marginBottom: '16px'}}>
                          <label className="form-check-label form-label" htmlFor='observacao'>
                            Observação
                          </label>

                          <InputStyled
                            type='text'
                            className='form-input form-control w-100'
                            id='observacao'
                            name='observacao'
                            value={values.observacao || ''}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />
                    { carregando ? (
                      <Spinner animation="border" style={{ marginTop: '27px'}} />
                    ) : (
                      <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar
                        </div>
                      </Button>
                    )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioEliminacoesFisiologicas }
export default ProntuarioEliminacoesFisiologicas
