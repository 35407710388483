import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk, FaProcedures } from 'react-icons/fa'
import { HiOutlineSun } from 'react-icons/hi'

import { ProfissionalEnum } from '../../../api/profissionais'
import { CheckboxStyled, InputStyled } from '../../../components/styles'
import { IProfissionaisForm } from '../Profissionais'
import { ProfissionaisFormCampoAptidoes } from './ProfissionaisFormCampoAptidoes'

const ProfissionaisFormCampos = () => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext<IProfissionaisForm>()

  return (
    <div style={{ color: '#304439', backgroundColor: '#EDE38E', padding: '12px 18px' }}>
      <Row>
        <Col md={10}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='nome'>
              Nome <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='nome'
              name='nome'
              value={values.nome}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={2}>
          <div style={{marginBottom: '16px', display: 'flex', marginTop: '28px' }}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="ativo"
              name='ativo'
              checked={values.ativo}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="ativo">
              Ativo
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='cpfCnpj'>
              CPF / CNPJ <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='cpfCnpj'
              name='cpfCnpj'
              value={values.cpfCnpj || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.cpfCnpj && touched.cpfCnpj && (
              <div style={{color: '#FF264D'}}>{errors.cpfCnpj}</div>
            )}
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='contato'>
              Contato
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='contato'
              name='contato'
              value={values.contato || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={9}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='email'>
              E-mail <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='email'
              name='email'
              value={values.email || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.email && touched.email && (
              <div style={{color: '#FF264D'}}>{errors.email}</div>
            )}
          </div>
        </Col>

        <Col md={2} style={{ paddingRight: '0px' }}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='pontos'>
              SOLs
            </label>

            <InputStyled
              type='number'
              className='form-input form-control w-100'
              id='pontos'
              name='pontos'
              value={values.pontos || 0}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={1} style={{ paddingTop: '38px', paddingLeft: '6px' }}>
          <HiOutlineSun color='#304439' size={30} />
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <div style={{marginBottom: '8px', display: 'flex'}}>
            <div style={{ marginRight: '16px' }}>
              <input
                type='radio'
                style={{ marginRight: '8px' }}
                id='gestor'
                name='tipo'
                value={ProfissionalEnum.GESTOR}
                checked={values.tipo === ProfissionalEnum.GESTOR}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <label className="form-check-label form-label" htmlFor="gestor">
                Gestor
              </label>
            </div>

            <div style={{ marginRight: '16px' }}>
              <input
                type='radio'
                style={{ marginRight: '8px' }}
                id='enfermeiro'
                name='tipo'
                value={ProfissionalEnum.ENFERMEIRO}
                checked={values.tipo === ProfissionalEnum.ENFERMEIRO}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <label className="form-check-label form-label" htmlFor="enfermeiro">
                Enfermeiro
              </label>
            </div>

            <div style={{ marginRight: '16px' }}>
              <input
                type='radio'
                style={{ marginRight: '8px' }}
                id='tecnico'
                name='tipo'
                value={ProfissionalEnum.TECNICO}
                checked={values.tipo === ProfissionalEnum.TECNICO}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <label className="form-check-label form-label" htmlFor="tecnico">
                Técnico
              </label>
            </div>
          </div>
        </Col>

        <Col md={3} style={{ paddingRight: '0px' }}>
          <div style={{marginBottom: '16px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="acessoUtiCheckbox"
              name='acessoUti'
              checked={values.acessoUti}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="acessoUtiCheckbox" style={{ marginBottom: '0px'}}>
              Acesso UTI
            </label>
          </div>
        </Col>

        <Col md={1}>
              <FaProcedures color='#304439' size={30} />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='senha'>
              Senha <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='password'
              className='form-input form-control w-100'
              id='senha'
              name='senha'
              value={values.senha || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='urlAvatar'>
              Avatar url
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='urlAvatar'
              name='urlAvatar'
              value={values.urlAvatar || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <ProfissionaisFormCampoAptidoes />
    </div>
  )
}

export { ProfissionaisFormCampos }
export default ProfissionaisFormCampos
