import React, { useContext, useEffect, useState } from 'react'
import { CgArrowRightR } from 'react-icons/cg'
import { Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import Swal from 'sweetalert2'

import { IBaterPontoForm, pontoProfissionalCriarAPI, pontoProfissionalEditarAPI } from '../../../../api/profissionais/pontoProfissionalAPI'
import { getUserLocalStorage } from '../../../../context/auth_provider/util'
import Button from '../../../../components/Button'
import { UsuarioPontoProntuarioEditForm } from './components/edicao/UsuarioPontoProntuarioEditForm'
import { BaterPontoEditForm, BaterPontoForm } from './components'
import { PassagemPlantaoContext } from './context'
import { UsuarioPontoProntuarioForm } from './components/insercao/components'
import { PassagemPlantaoRelatorio } from './components/relatorio'

export interface IBaterPontoEditForm {
  inicio: Date;
  fim?: Date;
  adicionarTodos: boolean;
}

const PassagemPlantao = () => {
  const [modalPassagemPlantaoAberto, setModalPassagemPlantaoAberto] = useState<boolean>(false)
  const [pacientesIds, setPacientesIds] = useState<number[]>([])
  const { ultimoPonto, loadUltimoPonto } = useContext(PassagemPlantaoContext)
  const user = getUserLocalStorage()

  useEffect(() => {
    if (ultimoPonto) {
      setPacientesIds(ultimoPonto.usuarioPontoProntuario.map(i => i.prontuario.idPaciente))
    }
  }, [ultimoPonto])

  const initialValues: IBaterPontoForm = {
    inicio: new Date(),
    fim: null,
    usuarioPontoProntuario: [],
    adicionarTodos: false,
  }

  const handleSubmit = (values: IBaterPontoForm) => {
    pontoProfissionalCriarAPI(values)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Ok...',
          text: 'Ponto batido com sucesso!',
          confirmButtonColor: '#1A8C01',
        })
        setModalPassagemPlantaoAberto(false)
      })
      .catch((err) => console.error(err))
      .finally(() => loadUltimoPonto())
  }

  const initialValuesEditar: IBaterPontoEditForm = {
    inicio: ultimoPonto?.inicio || new Date(),
    fim: undefined,
    adicionarTodos: false,
  }

  const handleSubmitEditar = (values: IBaterPontoEditForm) => {
    if (ultimoPonto) {
      pontoProfissionalEditarAPI(ultimoPonto?.id, values.inicio, values.fim)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Alterações realizadas com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
          setModalPassagemPlantaoAberto(false)
        })
        .catch((err) => console.error(err))
        .finally(() => loadUltimoPonto())
    }
  }

  console.log('ultimoponto', ultimoPonto)

  return (
    <>
      <Button
        style={{ display: 'flex', alignItems: 'center', background: "#24563b" }}
        onClick={() => setModalPassagemPlantaoAberto(true)}
      >
        <CgArrowRightR style={{ marginRight: '8px' }} size={22} />
        Passagem de plantão
      </Button>

      <Modal
        size='lg'
        show={modalPassagemPlantaoAberto}
        onHide={() => setModalPassagemPlantaoAberto(false)}
      >
        <Modal.Header style={{ color: '#304439' }}>
          <Modal.Title>
            <div>
              <div>Profissional - Entrada e saída (passagem de plantão)</div>

              <div style={{ fontSize: '18px' }}>{user.nome}</div>
            </div>
          </Modal.Title>
        </Modal.Header>

        {((!ultimoPonto || (!!ultimoPonto.inicio && !!ultimoPonto.fim))) && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, resetForm, submitForm }) => (
              <>
                <Modal.Body style={{ color: '#304439' }} key={`ultimo-ponto${ultimoPonto?.inicio || '0'}`}>
                  <BaterPontoForm />

                  <UsuarioPontoProntuarioForm
                    setPacientes={(id) => {
                      setPacientesIds(prev => {
                        if (prev.includes(id)) {
                          return prev.filter(p => p !== id)
                        }
                        return [...prev, id]
                      })
                    }}
                    removerPacientes={() => setPacientesIds([])}
                    handleFecharModal={() => setModalPassagemPlantaoAberto(false)}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={submitForm}
                  >
                    Confirmar
                  </Button>

                  <Button
                    variant='danger'
                    onClick={() => {
                      resetForm()
                      setModalPassagemPlantaoAberto(false)
                    }}
                  >
                    Cancelar
                  </Button>

                  <PassagemPlantaoRelatorio
                    idsPacientes={pacientesIds}
                    adicionarTodos={values.adicionarTodos}
                    dadosPassagemPlantao={values}
                  />
                </Modal.Footer>
              </>
            )}
          </Formik>
        )}

        {!!ultimoPonto && !ultimoPonto.fim && (
          <Formik
            initialValues={initialValuesEditar}
            onSubmit={handleSubmitEditar}
          >
            {({ values, resetForm, submitForm }) => (
              <>
                <Modal.Body style={{ color: '#304439' }} key={`ultimo-ponto${ultimoPonto?.inicio || '0'}`}>
                  <BaterPontoEditForm />

                  <UsuarioPontoProntuarioEditForm handleFecharModal={() => setModalPassagemPlantaoAberto(false)} />
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    style={{ marginRight: '8px' }}
                    onClick={submitForm}
                  >
                    Confirmar
                  </Button>

                  <Button
                    variant='danger'
                    onClick={() => {
                      resetForm()
                      setModalPassagemPlantaoAberto(false)
                    }}
                  >
                    Cancelar
                  </Button>

                  <PassagemPlantaoRelatorio
                    idsPacientes={pacientesIds}
                    adicionarTodos={values.adicionarTodos}
                  />
                </Modal.Footer>
              </>
            )}
          </Formik>
        )}
      </Modal>
    </>
  )
}

export { PassagemPlantao }
export default PassagemPlantao
