import { parserResultadoBackToFront } from '../../cadastro/resultadosAPI'
import {
  IProntuarioPlanoCuidadoAtividade,
  IProntuarioPlanoCuidadoAtividadeBackend,
  IProntuarioPlanoCuidadoListagem,
  IProntuarioPlanoCuidadoListagemBackend,
  IProntuarioPlanoCuidadoResultado,
  IProntuarioPlanoCuidadoResultadoBackend,
} from '../types'

export const parseProntuarioPlanoCuidadoResultadoBackToFront = (
  dadosBackend: IProntuarioPlanoCuidadoResultadoBackend
): IProntuarioPlanoCuidadoResultado => ({
  id: dadosBackend.id,
  grupo: dadosBackend.grupo,
  valor: dadosBackend.valor,
  esperado: dadosBackend.esperado,
  inserido: dadosBackend.inserido,
  diagnostico: dadosBackend.diagnostico,
  idDiagnostico: dadosBackend.id_diagnostico,
  resultado: parserResultadoBackToFront(dadosBackend.resultado),
  idResultado: dadosBackend.id_resultado,
  usuario: dadosBackend.usuario,
  idUsuario: dadosBackend.id_usuario,
  indicador: dadosBackend.indicador,
  idIndicador: dadosBackend.id_indicador,
})

export const parseProntuarioPlanoCuidadoAtividadeBackToFront = (
  dadosBackend: IProntuarioPlanoCuidadoAtividadeBackend
): IProntuarioPlanoCuidadoAtividade => ({
  id: dadosBackend.id,
  grupo: dadosBackend.grupo,
  inserido: dadosBackend.inserido,
  diagnostico: dadosBackend.diagnostico,
  idDiagnostico: dadosBackend.id_diagnostico,
  intervencao: dadosBackend.intervencao,
  idIntervencao: dadosBackend.id_intervencao,
  atividade: dadosBackend.atividade,
  idAtividade: dadosBackend.id_atividade,
  aprazamento: dadosBackend.aprazamento,
  idAprazamento: dadosBackend.id_aprazamento,
  usuario: dadosBackend.usuario,
  idUsuario: dadosBackend.id_usuario,
})

export const parseProntuarioPlanoCuidadoBackToFront = (dadosBackend: IProntuarioPlanoCuidadoListagemBackend): IProntuarioPlanoCuidadoListagem => ({
  id: dadosBackend.id,
  grupo: dadosBackend.grupo,
  feedbackLike: dadosBackend.feedbacklike,
  feedbackDeslike: dadosBackend.feedbackdeslike,
  feedbackOlho: dadosBackend.feedbackolho,
  feedbackLupa: dadosBackend.feedbacklupa,
  inserido: dadosBackend.inserido,
  diagnostico: dadosBackend.diagnostico,
  idDiagnostico: dadosBackend.id_diagnostico,
  usuario: dadosBackend.usuario,
  idUsuario: dadosBackend.id_usuario,
  prontuarioPlanoCuidadoResultado: dadosBackend.ProntuarioPlanoCuidadoResultado?.map(dado => parseProntuarioPlanoCuidadoResultadoBackToFront(dado)),
  prontuarioPlanoCuidadoAtividade: dadosBackend.ProntuarioPlanoCuidadoAtividade?.map(dado => parseProntuarioPlanoCuidadoAtividadeBackToFront(dado)),
})
