import React, {ReactElement} from 'react'
import {FaAsterisk} from 'react-icons/fa'
import DatePicker from 'react-datepicker'
import { useFormikContext } from 'formik' 
import {registerLocale} from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { InputStyled } from '../styles'

interface DatePickerProps {
  id: string,
  label: string,
  name?: string,
  className?: string,
  emptyLabel?: string,
  required?: boolean,
  disabled?: boolean,
  icone?: ReactElement,
  dateFormat?: string,
  showTimeInput?: boolean,
  selected?: Date | null,
  maxDate?: Date,
  type?: string,
}

export const DateTime = (props: DatePickerProps) => {
  const { setFieldValue } = useFormikContext()
  registerLocale('ptBR', ptBR);
  return (
    <div style={{   display: 'flex', flexDirection: 'column', width: '100%', color: '#304439', marginBottom: '16px' }}>
      <label htmlFor={props.id} className="form-label" style={ props.type === "branco"?{color:"white"}:{}}>
        {props.label} {props.required && !props.disabled ? (<sup><FaAsterisk color='#FF264D' size={8} /></sup>) : ''}
      </label>

      <div style={{   display: 'flex', alignItems: 'center', width: '' }}>
        <DatePicker
          id={props.id}
          name={props.id}
          className='form-input form-control'
          dateFormat={props.dateFormat || "dd/MM/yyyy"}
          customInput={<InputStyled />}
          maxDate={props.maxDate}
          selected={props.selected}
          disabled={props.disabled}
          showTimeInput={props.showTimeInput}
          locale="ptBR"
          timeFormat="p"
          onChange={(novaData: Date) => setFieldValue(`${props.id}`, novaData, false)}
        />

        {props.icone && (
          <div style={{ marginLeft: '12px' }}>
            {props.icone}
          </div>
        )}
      </div>
    </div>
  )
}

DateTime.propsDefault = {
  disabled: false,
  required: false,
  maxDate: undefined,
  showTimeSelect: false,
}
