import React from 'react'
import { useFormikContext } from 'formik'
import { Col, Row } from 'react-bootstrap'

import { IProntuarioFuncaoNeuroAvaOcularForm } from '../../../../../api/prontuario/types'
import { Switch } from '../../../../../components'
import { InputStyled } from '../../../../../components/styles'

const AvaliacaoOcularForm = () => {
  const { handleBlur, handleChange, setFieldValue, values } = useFormikContext<IProntuarioFuncaoNeuroAvaOcularForm>()
  
  return (
    <>
      <Row style={{ marginBottom: '18px'}}>
        <Col md={9}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Pupilas</div>
            <Row>
              <Col md={1} style={{ marginRight: '26px' }}>
                <Switch
                  label='Isocóricas'
                  name='pupilaIsocoricas'
                  checked={values.pupilaIsocoricas || false}
                  onChange={(value) => setFieldValue('pupilaIsocoricas', value, false)}
                />
              </Col>
              <Col md={1} style={{ marginRight: '26px' }}>
                <Switch
                  label='Mióticas'
                  name='pupilaMioticas'
                  checked={values.pupilaMioticas || false}
                  onChange={(value) => setFieldValue('pupilaMioticas', value, false)}
                />
              </Col>
              <Col md={2} style={{ marginRight: '26px' }}>
                <Switch
                  label='Midriáticas'
                  name='pupilaMidriaticas'
                  checked={values.pupilaMidriaticas || false}
                  onChange={(value) => setFieldValue('pupilaMidriaticas', value, false)}
                />
              </Col>
              <Col md={1} style={{ marginRight: '56px' }}>
                <Switch
                  label='Fotorreagente'
                  name='pupilaFotoreagente'
                  checked={values.pupilaFotoreagente || false}
                  onChange={(value) => setFieldValue('pupilaFotoreagente', value, false)}
                />
              </Col>
              <Col md={3}>
                <Switch
                  label='Não fotorreagente'
                  name='pupilaNaoFotoreagente'
                  checked={values.pupilaNaoFotoreagente || false}
                  onChange={(value) => setFieldValue('pupilaNaoFotoreagente', value, false)}
                />
              </Col>
              <Col md={3}>
                <div style={{marginBottom: '16px'}}>
                  <label className="form-check-label form-label" htmlFor='pupilaAncoricasDe'>
                    Anisocórica 
                  </label>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='pupilaAncoricasDe'
                      name='pupilaAncoricasDe'
                      value={values.pupilaAncoricasDe || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />

                    <div style={{ color: '#304439', marginLeft: '8px', marginRight: '8px' }}>{`>`}</div>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='pupilaAncoricasAte'
                      name='pupilaAncoricasAte'
                      value={values.pupilaAncoricasAte || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Lagoftalmia</div>
            <Row>
              <Col md={6}>
                <Switch
                  label='OD'
                  name='lagoftalmiaOd'
                  checked={values.lagoftalmiaOd || false}
                  onChange={(value) => setFieldValue('lagoftalmiaOd', value, false)}
                />
              </Col>
              <Col md={5}>
                <Switch
                  label='OE'
                  name='lagoftalmiaOe'
                  checked={values.lagoftalmiaOe || false}
                  onChange={(value) => setFieldValue('lagoftalmiaOe', value, false)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row style={{ marginBottom: '18px'}}>
        <Col md={3}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Hiperemia conjutival</div>
            <Row>
              <Col md={6}>
                <Switch
                  label='OD'
                  name='hiperamiaConjuntivalOd'
                  checked={values.hiperamiaConjuntivalOd || false}
                  onChange={(value) => setFieldValue('hiperamiaConjuntivalOd', value, false)}
                />
              </Col>
              <Col md={5}>
                <Switch
                  label='OE'
                  name='hiperamiaConjuntivalOe'
                  checked={values.hiperamiaConjuntivalOe || false}
                  onChange={(value) => setFieldValue('hiperamiaConjuntivalOe', value, false)}
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Secreção mucoide</div>
            <Row>
              <Col md={6}>
                <Switch
                  label='OD'
                  name='secrecaoOd'
                  checked={values.secrecaoOd || false}
                  onChange={(value) => setFieldValue('secrecaoOd', value, false)}
                />
              </Col>
              <Col md={5}>
                <Switch
                  label='OE'
                  name='secrecaoOe'
                  checked={values.secrecaoOe || false}
                  onChange={(value) => setFieldValue('secrecaoOe', value, false)}
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Edema palpebral</div>
            <Row>
              <Col md={6}>
                <Switch
                  label='OD'
                  name='edemaPalpebralOd'
                  checked={values.edemaPalpebralOd || false}
                  onChange={(value) => setFieldValue('edemaPalpebralOd', value, false)}
                />
              </Col>
              <Col md={5}>
                <Switch
                  label='OE'
                  name='edemaPalpebralOe'
                  checked={values.edemaPalpebralOe || false}
                  onChange={(value) => setFieldValue('edemaPalpebralOe', value, false)}
                />
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px' }}>
            <div>Quemose/Edema conjutival</div>
            <Row>
              <Col md={6}>
                <Switch
                  label='OD'
                  name='edemaConjuntivalOd'
                  checked={values.edemaConjuntivalOd || false}
                  onChange={(value) => setFieldValue('edemaConjuntivalOd', value, false)}
                />
              </Col>
              <Col md={5}>
                <Switch
                  label='OE'
                  name='edemaConjuntivalOe'
                  checked={values.edemaConjuntivalOe || false}
                  onChange={(value) => setFieldValue('edemaConjuntivalOe', value, false)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{ border: '1px solid #fff', borderRadius: '12px', padding: '6px', marginBottom: '26px' }}>
            Legendas: <strong>OD</strong> - Olho direito / <strong>OE</strong> - Olho Esquerdo
          </div>
        </Col>
      </Row>
    </>
  )
}

export { AvaliacaoOcularForm }
export default AvaliacaoOcularForm