import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { AprazamentosProvider } from './context';
import { AprazamentosContainerForm } from './AprazamentosContainerForm';

export interface IAprazamentosForm {
  nome: string,
  ativo: boolean,
}

const Aprazamentos = () => {
  return (
    <AprazamentosProvider>
      <DivMainStyled>
        <DivCardStyled>
          <AprazamentosContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </AprazamentosProvider>
  )
}

export { Aprazamentos }
export default Aprazamentos
