import { IAprazamentoListagem } from '../../cadastro/aprazamentoAPI'
import { IAtividadeListagem } from '../../cadastro/atividadesAPI'
import { IDiagnosticoListagem } from '../../cadastro/diagnosticosAPI'
import { IIndicadorListagem } from '../../cadastro/indicadoresAPI'
import { IIntervencaoListagem } from '../../cadastro/intervencoesAPI'
import { IResultadoListagem, IResultadoListagemBackend } from '../../cadastro/resultadosAPI'
import { IProfissionalListagem } from '../../profissionais'


export enum GrupoProntuarioPlanoCuidadoEnum {
  EXAME_FISICO = 'EXAME_FISICO',
  PERCEPCAO_SENTIDOS = 'PERCEPCAO_SENTIDOS',
  SONO_REPOUSO = 'SONO_REPOUSO',
  SEG_FISICA_EMOCIONAL = 'SEG_FISICA_EMOCIONAL',
  FUNCAO_NEUROLOGICA = 'FUNCAO_NEUROLOGICA',
  INTEGRIDADE_FISICA = 'INTEGRIDADE_FISICA',
  FUNCAO_RESPIRATORIA = 'FUNCAO_RESPIRATORIA',
  FUNCAO_CARDIOVASCULAR = 'FUNCAO_CARDIOVASCULAR',
  NUTRICAO = 'NUTRICAO',
  ELIMINACAO_FISIOLOGICA = 'ELIMINACAO_FISIOLOGICA',
  CUIDADO_CORPORAL = 'CUIDADO_CORPORAL',
  SEXUALIDADE = 'SEXUALIDADE',
  COMUNICACAO_EDUCACAO = 'COMUNICACAO_EDUCACAO',
  RELIGIOSIDADE_ESPIRITUALIDADE = 'RELIGIOSIDADE_ESPIRITUALIDADE',
  TERAPIA_ENDOVENOSA = 'TERAPIA_ENDOVENOSA',
  CONTROLE_INFUSAO = 'CONTROLE_INFUSAO',
  REGISTRO_COMPLEMENTAR = 'REGISTRO_COMPLEMENTAR',
  
}

export interface IProntuarioPlanoCuidadoResultado {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  valor: number;
  esperado: number;
  inserido: Date;
  diagnostico: IDiagnosticoListagem;
  idDiagnostico: number;
  resultado: IResultadoListagem;
  idResultado: number;
  usuario: IProfissionalListagem;
  idUsuario: number;
  indicador: IIndicadorListagem;
  idIndicador: number;
}

export interface IProntuarioPlanoCuidadoAtividade {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  inserido: Date;
  diagnostico: IDiagnosticoListagem;
  idDiagnostico: number;
  intervencao: IIntervencaoListagem;
  idIntervencao: number;
  atividade: IAtividadeListagem;
  idAtividade: number;
  aprazamento: IAprazamentoListagem;
  idAprazamento: number;
  usuario: IProfissionalListagem;
  idUsuario: number;
}

export interface IProntuarioPlanoCuidadoListagem {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  feedbackLike: boolean;
  feedbackDeslike: boolean;
  feedbackOlho: boolean;
  feedbackLupa: boolean;
  inserido: Date | null;
  diagnostico: IDiagnosticoListagem;
  idDiagnostico: number;
  usuario: IProfissionalListagem;
  idUsuario: number;
  prontuarioPlanoCuidadoResultado: IProntuarioPlanoCuidadoResultado[];
  prontuarioPlanoCuidadoAtividade: IProntuarioPlanoCuidadoAtividade[];
}

export interface IProntuarioPlanoCuidadoResultadoBackend {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  valor: number;
  esperado: number;
  inserido: Date;
  diagnostico: IDiagnosticoListagem;
  id_diagnostico: number;
  resultado: IResultadoListagemBackend;
  id_resultado: number;
  usuario: IProfissionalListagem;
  id_usuario: number;
  indicador: IIndicadorListagem;
  id_indicador: number;
}

export interface IProntuarioPlanoCuidadoAtividadeBackend {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  inserido: Date;
  diagnostico: IDiagnosticoListagem;
  id_diagnostico: number;
  intervencao: IIntervencaoListagem;
  id_intervencao: number;
  atividade: IAtividadeListagem;
  id_atividade: number;
  aprazamento: IAprazamentoListagem;
  id_aprazamento: number;
  usuario: IProfissionalListagem;
  id_usuario: number;
}

export interface IProntuarioPlanoCuidadoListagemBackend {
  id: number;
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  feedbacklike: boolean;
  feedbackdeslike: boolean;
  feedbackolho: boolean;
  feedbacklupa: boolean;
  inserido: Date | null;
  diagnostico: IDiagnosticoListagem;
  id_diagnostico: number;
  usuario: IProfissionalListagem;
  id_usuario: number;
  ProntuarioPlanoCuidadoResultado: IProntuarioPlanoCuidadoResultadoBackend[];
  ProntuarioPlanoCuidadoAtividade: IProntuarioPlanoCuidadoAtividadeBackend[];
}

export interface IProntuarioPlanoCuidadoForm {
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1;
  feedbackLike: boolean;
  feedbackDeslike: boolean;
  feedbackOlho: boolean;
  feedbackLupa: boolean;
  idDiagnostico: number;
}

export interface IProntuarioPlanoCuidadoResultadoForm {
  grupo: GrupoProntuarioPlanoCuidadoEnum,
  idDiagnostico: number,
  idResultado: number,
  idIndicador: number,
  valor: number | null,
  esperado: number | null,
}

export interface IProntuarioPlanoCuidadoAtividadeForm {
  grupo: GrupoProntuarioPlanoCuidadoEnum;
  idDiagnostico: number;
  idIntervencao: number;
  idAtividade: number;
  idAprazamento: number;
}