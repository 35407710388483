import React, { useContext } from 'react'
import { Formik } from 'formik'

import { IProntuarioPlanoCuidadoForm } from '../../../../../api/prontuario/types'
import { prontuarioPlanoCuidadoCreateAPI } from '../../../../../api/prontuario/prontuarioPlanoCuidadoAPI'
import { ProntuarioContext } from '../../context'
import Swal from 'sweetalert2'
import PlanoCuidadoFormCampos from './PlanoCuidadoFormCampos'

const PlanoCuidadoForm = () => {
  const { prontuario, loadProntuario, setCarregando } = useContext(ProntuarioContext)
  const initialValues: IProntuarioPlanoCuidadoForm = {
    grupo: -1,
    feedbackLike: false,
    feedbackDeslike: false,
    feedbackOlho: false,
    feedbackLupa: false,
    idDiagnostico: -1
  }

  const handleSubmit = (values: IProntuarioPlanoCuidadoForm) => {
    if (prontuario) {
      setCarregando(true)
      prontuarioPlanoCuidadoCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Plano de cuidado inserido com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <PlanoCuidadoFormCampos />
    </Formik>
  )
}

export { PlanoCuidadoForm }
export default PlanoCuidadoForm
