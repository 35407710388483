import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk } from 'react-icons/fa'

import { CheckboxStyled, InputStyled } from '../../../../components/styles'
import { IEmpresasForm } from '../Empresas'

type Props = {}

const EmpresasFormCampos = (props: Props) => {
  const { values, handleBlur, handleChange, errors, touched } = useFormikContext<IEmpresasForm>()

  return (
    <>
      <Row style={{ marginTop: '22px' }}>
        <Col md={10}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='nome'>
              Nome <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='nome'
              name='nome'
              value={values.nome}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={2}>
          <div style={{marginBottom: '16px', display: 'flex', marginTop: '28px'}}>
            <CheckboxStyled
              className="form-check-input form-control"
              style={{ marginRight: '6px' }}
              type="checkbox"
              id="ativoCheckbox"
              name='ativo'
              checked={values.ativo}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <label className="form-check-label form-label" htmlFor="ativoCheckbox">
              Ativo
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='cnpj'>
              CNPJ <sup><FaAsterisk color='#FF264D' size={8} /></sup>
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='cnpj'
              name='cnpj'
              value={values.cnpj}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.cnpj && touched.cnpj && (
              <div style={{color: '#FF264D'}}>{errors.cnpj}</div>
            )}
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='ie'>
              Inscrição estadual
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='ie'
              name='ie'
              value={values.ie || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='contato'>
              Contado
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='contato'
              name='contato'
              value={values.contato || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='email'>
              Email
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='email'
              name='email'
              value={values.email || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div style={{marginBottom: '8px'}}>
            <label className="form-check-label form-label" htmlFor='urlCanalSuporte'>
              URL canal suporte
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='urlCanalSuporte'
              name='urlCanalSuporte'
              value={values.urlCanalSuporte || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={6}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='urlAvatar'>
              URL Avatar
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='urlAvatar'
              name='urlAvatar'
              value={values.urlAvatar || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export { EmpresasFormCampos }
export default EmpresasFormCampos
