
export interface IProntuarioComunicacaoEducacaoListagem {
  id: number;
  comunicacao: ComunicacaoEnum | null;
  conheceProblemaSaude: boolean | null;
  afonia: boolean | null;
  dislalia: boolean | null;
  disartria: boolean | null;
  disfasia: boolean | null;
  afasia: boolean | null;
  semAlteracao: boolean | null;
  avaliado: Date | null;
  alterado: boolean | null;
}

export enum ComunicacaoEnum {
  ADEQUADA = 'ADEQUADA',
  DIFICULDADE = 'DIFICULDADE',
  USO_LINGUEAGEM_VERBAL = 'USO_LINGUEAGEM_VERBAL',
  USO_LINGUEAGEM_NAO_VERBAL = 'USO_LINGUEAGEM_NAO_VERBAL'
}

export interface IProntuarioComunicacaoEducacaoListagemBackend {
  id: number;
  comunicacao: ComunicacaoEnum | null;
  conhece_problema_saude: boolean | null;
  afonia: boolean | null;
  dislalia: boolean | null;
  disartria: boolean | null;
  disfasia: boolean | null;
  afasia: boolean | null;
  sem_alteracao: boolean | null;
  avaliado: Date | null;
  alterado: boolean | null;
}

export interface IProntuarioComunicacaoEducacaoForm {
  comunicacao: ComunicacaoEnum | null;
  conheceProblemaSaude: boolean | null;
  afonia: boolean | null;
  dislalia: boolean | null;
  disartria: boolean | null;
  disfasia: boolean | null;
  afasia: boolean | null;
  semAlteracao: boolean | null;
  avaliado: Date | null;
  stAvaliado: boolean | null;
  alterado: boolean | null;
}