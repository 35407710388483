import React from 'react';
import {Container} from 'react-bootstrap';

import {
  PageHeader,
  PageFilter,
  PageBody,
  PageFooter,
} from './components';

interface IPageProps {
  children: JSX.Element[],
}

export const Page = ({children}: IPageProps) => {
  return (
    <Container style={{padding: '20px 100px' }}>
      {children.map((element) => element)}
    </Container>
  );
};

Page.Header = PageHeader;
Page.Filter = PageFilter;
Page.Body = PageBody;
Page.Footer = PageFooter;
