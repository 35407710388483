import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import { IBaterPontoForm } from '../../../../../../api/profissionais/pontoProfissionalAPI'
import Button from '../../../../../../components/Button'

import { PassagemPlantaoRelatorioCampos } from './PassagemPlantaoRelatorioCampos'

interface IPassagemPlantaoRelatorioProps {
  idsPacientes: number[],
  adicionarTodos: boolean,
  dadosPassagemPlantao?: IBaterPontoForm,
}

const PassagemPlantaoRelatorio = (props: IPassagemPlantaoRelatorioProps) => {
  const [modalRepostOpen, setModalRepostOpen] = useState<boolean>(false)
  return (
    <>
      <Button style={{
        background: `${props.idsPacientes.length < 0 ? 'rgb(5 136 255)' : '#03549E'}`,
        opacity: '0.7',
        marginLeft: '8px',
        color: `${props.idsPacientes.length < 0 ? 'black' : ''}`,
      }}
        variant='secondary'
        onClick={() => setModalRepostOpen(true)}>
            Imprimir
      </Button>

      <Modal
        size='lg'
        show={modalRepostOpen}
        onHide={() => setModalRepostOpen(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Relatório de passagem de plantão
          </Modal.Title>
        </Modal.Header> 
        <Modal.Body style={{ height: '90vh'}}>
          <PassagemPlantaoRelatorioCampos
            idsPacientes={props.idsPacientes}
            adicionarTodos={props.adicionarTodos}
            dadosPassagemPlantao={props.dadosPassagemPlantao}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { PassagemPlantaoRelatorio }
export default PassagemPlantaoRelatorio
