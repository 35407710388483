import React from 'react'
import { FaRegQuestionCircle, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { ProfissionalEnum } from '../../api/profissionais';
import { Page } from '../../components/page';
import { ProfissionaisProvider } from './context';
import { ProfissionaisModalForm } from './components/ProfissionaisModalForm';
import { ProfissionaisFiltros, ProfissionaisListagem } from './components';

export interface IProfissionaisForm {
  nome: string,
  ativo: boolean,
  senha: string,
  tipo: ProfissionalEnum,
  pontos: number,
  acessoUti: boolean,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  cpfCnpj?: string | null,
  aptidoes: string[]
}

const Profissionais = () => {
  const navigate = useNavigate()

  return (
    <ProfissionaisProvider>
      <Page>
        <Page.Header
          icon={FaUser}
          title='Profissionais'
          buttons={<ProfissionaisModalForm />}
        />

        <Page.Filter>
          <ProfissionaisFiltros />
        </Page.Filter>

        <Page.Body >
          <ProfissionaisListagem />
        </Page.Body>

        <Page.Footer>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <div />

            <div >
              <FaRegQuestionCircle
                style={{ cursor: 'pointer' }}
                color='#304439'
                size={30}
                onClick={() => navigate(
                  '/ajuda-playlist',
                  { state: { tituloTela: 'Profissionais', playlistId: 'PLdoiqJ9asaWTCD1dq2DK25Hsn12vREykF' } } //PLt8A7hwno-KHlTxWMJCceMI4kos5P_hvV
                )}
              />
            </div>
          </div>
        </Page.Footer>
      </Page>
    </ProfissionaisProvider>
  )
}

export { Profissionais }
export default Profissionais
