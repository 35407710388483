import { axiosAPI } from '../axiosAPI'
import { IProntuarioExameFisicoForm, IProntuarioExameFisicoListagem } from './types'

export const prontuarioExameFisicoCreateAPI = async (
  idProntuario: number,
  values: IProntuarioExameFisicoForm,
): Promise<void> => {
await axiosAPI.post(
    'prontuarioexamefisico',
    {
      id_prontuario: idProntuario,
      estado_geral: values.estadoGeral,
      ssvv_pa: values.ssvvPa,
      temperatura: values.temperatura,
      fr: values.fr,
      fc: values.fc,
      sat_o2: values.satO2,
      hgt: values.hgt,
      avaliado: values.avaliado,
      alterado: values.alterado,
    },
)
}

export type { IProntuarioExameFisicoForm, IProntuarioExameFisicoListagem }
