import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioExameFisicoForm,
  prontuarioExameFisicoCreateAPI,
} from '../../../../../api/prontuario/prontuarioExameFisicoAPI'
import { InputStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { Select, Switch } from '../../../../../components'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { verificarAvaliadoNoDia } from '../../util'

interface IProntuarioExameFisicoProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: () => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioExameFisico = (props: IProntuarioExameFisicoProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.EXAME_FISICO)

  const initialValues: IProntuarioExameFisicoForm = {
    estadoGeral: prontuario?.prontuarioExameFisico[0]?.estadoGeral || null,
    ssvvPa: prontuario?.prontuarioExameFisico[0]?.ssvvPa || null,
    temperatura: prontuario?.prontuarioExameFisico[0]?.temperatura || null,
    fr: prontuario?.prontuarioExameFisico[0]?.fr || null,
    fc: prontuario?.prontuarioExameFisico[0]?.fc || null,
    satO2: prontuario?.prontuarioExameFisico[0]?.satO2 || null,
    hgt: prontuario?.prontuarioExameFisico[0]?.hgt || null,
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioExameFisico[0]?.avaliado),
    alterado: prontuario?.prontuarioExameFisico[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioExameFisicoForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioExameFisicoCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados exame físico incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('percepcaoSentidos'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioExameFisico[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioExameFisico[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen()}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Exame físico</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'exameFisico') ? (
                <> 
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
                </>
             
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'exameFisico') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <Select
                      label='Estado geral'
                      emptyLabel='Selecione...'
                      id='estadoGeral'
                      value={values.estadoGeral || ''}
                      options={[
                        { label: 'Bom', value: 'BOM' },
                        { label: 'Regular', value: 'REGULAR' },
                        { label: 'Comprometido', value: 'COMPROMETIDO' },
                        { label: 'Grave', value: 'GRAVE' },
                      ]}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='ssvvPa'>
                      SSVV - PA mmHg
                    </label>

                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='ssvvPa'
                      name='ssvvPa'
                      value={values.ssvvPa || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='temperatura'>
                      T °C
                    </label>

                    <InputStyled
                      type='number'
                      className='form-input form-control w-100'
                      id='temperatura'
                      name='temperatura'
                      value={values.temperatura || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='fr'>
                      FR rpm
                    </label>

                    <InputStyled
                      type='number'
                      className='form-input form-control w-100'
                      id='fr'
                      name='fr'
                      value={values.fr || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='fc'>
                      FC bpm
                    </label>

                    <InputStyled
                      type='number'
                      className='form-input form-control w-100'
                      id='fc'
                      name='fc'
                      value={values.fc || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='satO2'>
                      Sat O2 %
                    </label>

                    <InputStyled
                      type='number'
                      className='form-input form-control w-100'
                      id='satO2'
                      name='satO2'
                      value={values.satO2 || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='hgt'>
                      HGT mg/dl
                    </label>

                    <InputStyled
                      type='number'
                      className='form-input form-control w-100'
                      id='hgt'
                      name='hgt'
                      value={values.hgt || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Não avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioExameFisico }
export default ProntuarioExameFisico
