import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  prontuarioIntegridadeFisicaCreateAPI,
  EscalaBradenEnum,
  IProntuarioIntegridadeFisicaForm,
} from '../../../../../api/prontuario/prontuarioIntegridadeFisicaAPI'
import { DateTime, Switch } from '../../../../../components'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { SelectAsync } from '../../../../../components/select-async'
import { getAllToSelectTipoPeleAPI } from '../../../../../api/cadastro/tiposPeleAPI'
import { InputStyled } from '../../../../../components/styles'
import { EscalaBraden, Lesoes } from './components'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { verificarAvaliadoNoDia } from '../../util'

interface IProntuarioIntegridadeFisicaProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioIntegridadeFisica = (props: IProntuarioIntegridadeFisicaProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.INTEGRIDADE_FISICA)
 
  const initialValues: IProntuarioIntegridadeFisicaForm = {
    observacao: prontuario?.prontuarioIntegridadeFisica[0]?.observacao || null,
    dreno: prontuario?.prontuarioIntegridadeFisica[0]?.dreno || null,
    drenagemMl: prontuario?.prontuarioIntegridadeFisica[0]?.drenagemMl || null,
    local: prontuario?.prontuarioIntegridadeFisica[0]?.local || null,
    dataInsercao: prontuario?.prontuarioIntegridadeFisica[0]?.dataInsercao
      ? new Date(prontuario?.prontuarioIntegridadeFisica[0]?.dataInsercao)
      : null,
    escalaBraden: prontuario?.prontuarioIntegridadeFisica[0]?.escalaBraden || EscalaBradenEnum.RISCO_BRANDO_15_16,
    percentualSensorial: prontuario?.prontuarioIntegridadeFisica[0]?.percentualSensorial || '4',
    umidade: prontuario?.prontuarioIntegridadeFisica[0]?.umidade || '4',
    atividade: prontuario?.prontuarioIntegridadeFisica[0]?.atividade || '4',
    mobilidade: prontuario?.prontuarioIntegridadeFisica[0]?.mobilidade || '4',
    nutricao: prontuario?.prontuarioIntegridadeFisica[0]?.nutricao || '4',
    ficcao: prontuario?.prontuarioIntegridadeFisica[0]?.ficcao || '3',
    idTipoPele: prontuario?.prontuarioIntegridadeFisica[0]?.idTipoPele || -1,
    lesoes: prontuario?.prontuarioIntegridadeFisica[0]?.integridadeFisicaTipoLesao.map(i => ({
      id: i.id || Math.random() * -1,
      idTipoLesao: i.idTipoLesao || -1,
      grau: i.grau || '',
      descricao: i.tipoLesao.nome || '',
      avaliacao: {
        avaliacao: i.integridadeFisicaAvaliacao?.avaliacao || '',
        tamanhoAproximado: i.integridadeFisicaAvaliacao?.tamanhoAproximado || null,
        aspecto: i.integridadeFisicaAvaliacao?.aspecto || null,
        aspectoOutro: i.integridadeFisicaAvaliacao?.aspectoOutro || null,
        quantidade: i.integridadeFisicaAvaliacao?.quantidade || null,
        odor: i.integridadeFisicaAvaliacao?.odor || null,
        aspectoBorda: i.integridadeFisicaAvaliacao?.aspectoBorda || null,
        aspectoLeito: i.integridadeFisicaAvaliacao?.aspectoLeito || null,
        sinaisFlogisticos: i.integridadeFisicaAvaliacao?.sinaisFlogisticos || null,
        higieneLesao: i.integridadeFisicaAvaliacao?.higieneLesao || null,
        higieneLesaoOutro: i.integridadeFisicaAvaliacao?.higieneLesaoOutro || null,
        coberturaPrimaria: i.integridadeFisicaAvaliacao?.coberturaPrimaria || null,
        coberturaSecundaria: i.integridadeFisicaAvaliacao?.coberturaSecundaria || null,

        fotos: i.integridadeFisicaAvaliacao?.fotos.map(f => ({
          id: f.id,
          fotoEndereco: f.fotoEndereco || '',
          foto: null
        })) || []
      }
    })) || [],
    avaliado: new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioIntegridadeFisica[0]?.avaliado),
    alterado: prontuario?.prontuarioIntegridadeFisica[0]?.alterado || false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioIntegridadeFisicaForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioIntegridadeFisicaCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Integridade física incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('funcaoRespiratoria'))
        })
        .catch(err => {
          console.log(err)  
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioIntegridadeFisica[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioIntegridadeFisica[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('integridadeFisica')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Integridade Física</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'integridadeFisica') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'integridadeFisica') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col md={5}>
                      <SelectAsync
                        label='Pele e mucosa'
                        emptyLabel='Selecione...'
                        id='idTipoPele'
                        value={values.idTipoPele || -1}
                        api={getAllToSelectTipoPeleAPI}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col md={7}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='observacao'>
                          Observação
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='observacao'
                          name='observacao'
                          value={values.observacao || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <div style={{ border: '1px solid #304439', borderRadius: '16px' }}>
                      <Row>
                        <Col md={3}>
                          <Switch
                            label='Dreno'
                            name='dreno'
                            checked={values.dreno || false}
                            onChange={(value) => setFieldValue('dreno', value, false)}
                          />
                        </Col>

                        <Col md={9}>
                          <div style={{marginBottom: '16px'}}>
                            <label className="form-check-label form-label" htmlFor='drenagemMl'>
                              Drenagem ml
                            </label>

                            <InputStyled
                              type='text'
                              className='form-input form-control w-100'
                              id='drenagemMl'
                              name='drenagemMl'
                              value={values.drenagemMl || ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>

                        <Col md={7}>
                          <div style={{marginBottom: '16px'}}>
                            <label className="form-check-label form-label" htmlFor='local'>
                              Local
                            </label>

                            <InputStyled
                              type='text'
                              className='form-input form-control w-100'
                              id='local'
                              name='local'
                              value={values.local || ''}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>

                        <Col md={5}>
                          <DateTime
                            label='Data de inserção'
                            id='dataInsercao'
                            maxDate={new Date()}
                            selected={values.dataInsercao}
                          />
                        </Col>
                      </Row>
                    </div>

                    <Col md={12}>
                      <EscalaBraden /> 
                    </Col>
                  </Row>
                </Col>

                <Col md={7}>
                  <Row>
                    <Col md={12}>
                        <Lesoes />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => { 
                         submitForm() 
                         
                      }} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioIntegridadeFisica }
export default ProntuarioIntegridadeFisica
