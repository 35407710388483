import React, { useContext, useState } from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { Interface } from 'readline'
 
interface MeuComponenteProps {
  titulo: string;
  descricao: string;
  acao: any;  
  idApagar: number;
  visivelModal: boolean
  fechaModal: any; 
}

export function ModalPadrao(props: MeuComponenteProps){
    const [modalExclusao, setModalEclusao ] = useState(false)
    const [deleteAcao, setDeleteAcao ]      = useState(props.idApagar) 

    return( 
        <Modal
        show={props.visivelModal}
        onHide={() => props.fechaModal(false)}
        centered
        animation  >
         <Modal.Header closeButton>
        <Modal.Title style={{color: "black"}}>{props.titulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{color: "black"}}>
        <p>{props.descricao}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=> props.fechaModal(false)}>
          Cancelar
        </Button> 
        <Button variant="danger" onClick={()=>{ 
          try{
            props.acao(); 
          }catch(e)
          {
            alert(e)
          }
          props.fechaModal(false)      
         }  }>
          Sim, excluir
        </Button>
      </Modal.Footer> 
      </Modal>
        
    )
}