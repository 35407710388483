import React, {createContext, ReactElement, ReactFragment, ReactNode, useEffect, useState} from 'react';

import { aprazamentoListarAPI, IAprazamentoListagem } from '../../../../api/cadastro/aprazamentoAPI';

interface TAprazamentosContext {
  carregando: boolean,
  setCarregando: (novoEstado: boolean) => void,
  aprazamentos: IAprazamentoListagem[],
  setAprazamentos: (newAprazamentos: IAprazamentoListagem[]) => void,
  selecionado: IAprazamentoListagem | null,
  setSelecionado: (novoSelecionado: IAprazamentoListagem | null) => void,
  loadAprazamentos: () => void
}

interface TAprazamentosProviderProps {
  children: ReactElement | ReactFragment | ReactNode
}

const defaultState = {
  carregando: false,
  setCarregando:  () => {
    throw new Error('setCarregando não implementada');
  },
  aprazamentos: [],
  setAprazamentos: () => {
    throw new Error('setAprazamentos não implementada');
  },
  loadAprazamentos: () => {
    throw new Error('loadAprazamentos não implementada');
  },
  selecionado: null,
  setSelecionado: () => {
    throw new Error('setSelecionado não implementada');
  },
};

export const AprazamentosContext = createContext<TAprazamentosContext>(defaultState);

export const AprazamentosProvider = ({children}: TAprazamentosProviderProps) => {
  const [aprazamentos, setAprazamentos] = useState<IAprazamentoListagem[]>(defaultState.aprazamentos);
  const [carregando, setCarregando] = useState<boolean>(defaultState.carregando)
  const [selecionado, setSelecionado] = useState<IAprazamentoListagem | null>(defaultState.selecionado)

  const loadAprazamentos = () => {
    setCarregando(true)
    aprazamentoListarAPI()
        .then((data) => setAprazamentos(data))
        .catch((err) => console.log(err))
        .finally(() => setCarregando(false))
  };

  useEffect(() => {
    loadAprazamentos();
  }, []);

  const providerValues: TAprazamentosContext = {
    selecionado,
    setSelecionado,
    carregando,
    setCarregando,
    aprazamentos,
    setAprazamentos,
    loadAprazamentos,
  };

  return (
    <AprazamentosContext.Provider value={providerValues}>
      {children}
    </AprazamentosContext.Provider>
  );
};
