import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { IEscalaCCPOTForm, calcularEscalaCCPOT } from './EscalaCCPOT'
import { Col, Row, Table } from 'react-bootstrap'
import { Select } from '../../../../../components'
import { InputStyled } from '../../../../../components/styles'

const EscalaCCPOTForm = () => {
  const { values, setFieldValue, handleChange } = useFormikContext<IEscalaCCPOTForm>()

  useEffect(() => {
    setFieldValue(
      'escalaCcpot',
      calcularEscalaCCPOT(
        values.expressaoFacial,
        values.movimentoCorporal,
        values.tensaoMuscular,
        values.adaptacaoVentilador
      ),
      false
    )
  }, [values])

  return (
    <>
      <Row>
        <Col md={3}>
          <div>
            <Select
              name='expressaoFacial'
              id='expressaoFacial'
              label='Expressao facial'
              value={values.expressaoFacial}
              options={[
                { label: 'Relaxada', value: 0 },
                { label: 'Tensa', value: 1 },
                { label: 'Esgar/Careta', value: 2 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={3}>
          <div>
            <Select
              name='movimentoCorporal'
              id='movimentoCorporal'
              label='Movimento corporal'
              value={values.movimentoCorporal}
              options={[
                { label: 'Ausência de movimentos', value: 0 },
                { label: 'Movimentos de proteção', value: 1 },
                { label: 'Inquietação', value: 2 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={3}>
          <div>
            <Select
              name='tensaoMuscular'
              id='tensaoMuscular'
              label='Tensão muscular'
              value={values.tensaoMuscular}
              options={[
                { label: 'Relaxada', value: 0 },
                { label: 'Tenso ou rígido', value: 1 },
                { label: 'Muito tenso ou Muito rígido', value: 2 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={3}>
          <div>
            <Select
              name='adaptacaoVentilador'
              id='adaptacaoVentilador'
              label='Adaptação ao ventilador'
              value={values.adaptacaoVentilador}
              options={[
                { label: 'Tolera', value: 0 },
                { label: 'Tosse', value: 1 },
                { label: 'Luta', value: 2 },
              ]}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{ padding: '20px' }}>
            <div style={{ border: '1px solid #304439', borderRadius: '18px' }}>
              <Table style={{ color: '#304439' }}>
                <thead>
                  <tr>
                    <th style={{ width: '210px' }}>Indicador</th>
                    <th>Item</th>
                    <th>Pontuação</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Expressão facial</td>
                    <td>
                      <div>Relaxada</div>
                      <div>Tensa</div>
                      <div>Esgar/Careta</div>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                    </td>
                  </tr>

                  <tr>
                    <td>Movimentos corporais</td>
                    <td>
                      <div>Ausência de movimentos</div>
                      <div>Movimentos de proteção</div>
                      <div>Inquietação</div>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                    </td>
                  </tr>

                  <tr>
                    <td>Tensão muscular</td>
                    <td>
                      <div>Relaxada</div>
                      <div>Tenso ou rígido</div>
                      <div>Muito tenso ou Muito rígido</div>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>Adaptação ao ventilador</div>
                      <div>(pacientes IOT) /</div>
                      <div>Vocalização</div>
                      <div>(pacientes extubados)</div>
                    </td>
                    <td>
                      <div style={{ fontSize: '12px' }}>Tolera o ventilador ou movimento/fala em um tom nomal ou sem som</div>
                      <div>Tosse mas tolerando o ventilador/suspiros ou gemidor</div>
                      <div>Luta contra o ventilador/choro</div>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <div>0</div>
                      <div>1</div>
                      <div>2</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{marginBottom: '16px', width: '20%', marginLeft: 'auto', marginRight: 'auto' }}>
            <label className="form-check-label form-label" htmlFor='escalaCcpot' style={{ color: '#304439'}}>
              Soma total
            </label>

            <InputStyled
              type='number'
              className='form-input form-control w-100'
              id='escalaCcpot'
              name='escalaCcpot'
              value={String(values.escalaCcpot) || ''}
              disabled
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export { EscalaCCPOTForm }
export default EscalaCCPOTForm
