import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { IntervencoesProvider } from './context';
import { IntervencoesContainerForm } from './IntervencoesContainerForm';
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../api/prontuario/types';

export interface IIntervencoesForm {
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

const Intervencoes = () => {
  return (
    <IntervencoesProvider>
      <DivMainStyled>
        <DivCardStyled>
          <IntervencoesContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </IntervencoesProvider>
  )
}

export { Intervencoes }
export default Intervencoes
