import { IProntuarioFuncaoRespiratoriaListagem, IProntuarioFuncaoRespiratoriaListagemBackend } from "../types";

export const parseProntuarioFuncaoRespiratoriaBackToFront = (dadosBackend: IProntuarioFuncaoRespiratoriaListagemBackend): IProntuarioFuncaoRespiratoriaListagem => ({
  id: dadosBackend.id,
  co2Ambiente: dadosBackend.co2_ambiente,
  cateterNasal: dadosBackend.cateter_nasal,
  outroDispositivo: dadosBackend.outro_dispositivo,
  cateterNasalLmin: dadosBackend.cateter_nasal_lmin,
  mv: dadosBackend.mv,
  mvPerc: dadosBackend.mv_perc,
  vni: dadosBackend.vni,
  vniTipo: dadosBackend.vni_tipo,
  auscutaPulmonar: dadosBackend.auscuta_pulmonar,
  tot: dadosBackend.tot,
  totNumero: dadosBackend.tot_numero,
  totDias: dadosBackend.tot_dias,
  mnrLmin: dadosBackend.mnr_lmin,
  secrecao: dadosBackend.secrecao,
  secrecaoTipo: dadosBackend.secrecao_tipo,
  tqt: dadosBackend.tqt,
  tqtNumero: dadosBackend.tqt_numero,
  tqtDias: dadosBackend.tqt_dias,
  mnr: dadosBackend.mnr,
  tosse: dadosBackend.tosse,
  tosseTipo: dadosBackend.tosse_tipo,
  vmi: dadosBackend.vmi,
  vmiDesmami: dadosBackend.vmi_desmami,
  gasometria: dadosBackend.gasometria,
  ph: dadosBackend.ph,
  hco3: dadosBackend.hco3,
  mviModo: dadosBackend.mvi_modo,
  peep: dadosBackend.peep,
  fio2: dadosBackend.fio2,
  frProgramada: dadosBackend.fr_programada,
  pco2: dadosBackend.pco2,
  so2: dadosBackend.so2,
  po2: dadosBackend.po2,
  idRespiracao: dadosBackend.id_respiracao,
  avaliado: dadosBackend.avaliado,
  alterado: dadosBackend.alterado,
  respiracao: dadosBackend.Respiracao,
})
