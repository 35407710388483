import React from 'react';
import {Navigate} from 'react-router-dom';
import {useJwt} from 'react-jwt';
import {getUserLocalStorage} from '../../context/auth_provider/util';
import {IToken} from '../../context/auth_provider/types';

export const ProtectedOnlyAdmin = ({children}: { children: JSX.Element }) => {
  const user = getUserLocalStorage();

  const {decodedToken} = useJwt<IToken>(user?.token || '');

  if (decodedToken && !decodedToken.admin) {
    return (<>
      <Navigate to='/' />
    </>);
  }

  return children;
};
