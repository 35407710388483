import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { Col, Row, Spinner } from 'react-bootstrap'
import { FaSave } from 'react-icons/fa'

import { prontuarioRegistroComplementarCreateAPI } from '../../../../../../api/prontuario/prontuarioRegistroComplementarAPI'
import { IProntuarioRegistroComplementarForm } from '../../../../../../api/prontuario/types'
import Button from '../../../../../../components/Button'
import { TextAreaStyled } from '../../../../../../components/styles'
import { ProntuarioContext } from '../../../context'

const RegistroComplementarDados = () => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);

  const initialValues: IProntuarioRegistroComplementarForm = {
    eventosAdversos: prontuario?.prontuarioRegistroComplementar[0]?.eventosAdversos || null,
    ocorrencias: prontuario?.prontuarioRegistroComplementar[0]?.ocorrencias || null,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioRegistroComplementarForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioRegistroComplementarCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de registro complementar incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          })
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }
  return (
    <div style={{ marginTop: '16px'}}>
      <Formik
        key={formKey}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
          <>
            <Row>
              <Col md={12}>
                <div style={{marginBottom: '16px'}}>
                  <label className="form-check-label form-label" htmlFor='eventosAdversos'>
                    Eventos adversos
                  </label>

                  <TextAreaStyled
                    className='form-input form-control w-100'
                    id='eventosAdversos'
                    name='eventosAdversos'
                    value={values.eventosAdversos || ''}
                    rows={3}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div style={{marginBottom: '16px'}}>
                  <label className="form-check-label form-label" htmlFor='ocorrencias'>
                    Registro de ocorrências
                  </label>

                  <TextAreaStyled
                    className='form-input form-control w-100'
                    id='ocorrencias'
                    name='ocorrencias'
                    value={values.ocorrencias || ''}
                    rows={3}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
              { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar
                        </div>
                      </Button>
                    </div>
                  )}
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  )
}

export { RegistroComplementarDados }
export default RegistroComplementarDados
