import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { GoPencil } from 'react-icons/go'
import { Link } from 'react-router-dom'

import {
  DivCardItemStyled,
  DivCardStyled,
  DivMainStyled,
  DivTitulo,
} from './styles'

const Cadastros = () => {
  return (
    <DivMainStyled>
      <DivCardStyled>
        <DivTitulo>
          <GoPencil color='#304439' size={24} style={{ marginRight: '8px' }} />
          Cadastros
        </DivTitulo>

        <div style={{ marginTop: '40px' }}>
          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/atividade"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Atividades
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/intervencao"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Intervenção
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/dispositivos"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Dispositivo
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/leito"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Leito
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/diagnosticos"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Diagnóstico de enfermagem
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/tipo_lesao"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Lesão
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/tipos_infusoes"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Tipos de infusões
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/respiracao"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Respiração
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/edema_localizacao"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Edema localização
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/tipos_pele"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Tipo de pele
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/resultados_enfermagem"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Resultados de enfermagem
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/empresas"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Unidade de saúde/Empresa
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: '8px' }}>
            <Col md={6}>
              <Link
                to="/indicadores"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Indicadores
                </DivCardItemStyled>
              </Link>
            </Col>

            <Col md={6}>
              <Link
                to="/aprazamentos"
                style={{textDecoration: 'none', color: 'white'}}
              >
                <DivCardItemStyled>
                  Aprazamentos
                </DivCardItemStyled>
              </Link>
            </Col>
          </Row>
        </div>

      </DivCardStyled>
    </DivMainStyled>
  )
}

export { Cadastros }
export default Cadastros
