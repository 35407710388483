import React from 'react'

import { DivMainStyled, DivCardStyled } from '../styles';
import { DispositivosProvider } from './context';
import { DispositivosContainerForm } from './DispositivosContainerForm';

export interface IDispositivosForm {
  nome: string,
  ativo: boolean,
}

const Dispositivos = () => {
  return (
    <DispositivosProvider>
      <DivMainStyled>
        <DivCardStyled>
          <DispositivosContainerForm />
        </DivCardStyled>
      </DivMainStyled>
    </DispositivosProvider>
  )
}

export { Dispositivos }
export default Dispositivos
