import React, { ChangeEvent, useState } from 'react'
import { BiMessageRoundedDetail } from 'react-icons/bi'
import { Col, Modal, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { IBaterPontoForm } from '../../../../../../../api/profissionais/pontoProfissionalAPI'
import { TextAreaStyled } from '../../../../../../../components/styles'
import Button from '../../../../../../../components/Button'

interface IUsuarioPontoProntuarioObservacaoProps {
  prontuarioId: number
}

const UsuarioPontoProntuarioObservacao = (props: IUsuarioPontoProntuarioObservacaoProps) => {
  const [modalMessagemAberto, setModalMessagemAberto] = useState<boolean>(false)
  const [observacoes, setObservacoes] = useState<string>('')
  const { values, setFieldValue } = useFormikContext<IBaterPontoForm>()

  const handleInputOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setObservacoes(value);
  };

  const handleSubmit = () => {
    let indice = null
    values.usuarioPontoProntuario.forEach((upp, ind) => {
      if (upp.prontuarioId === props.prontuarioId) {
        indice = ind
      }
    })

    setFieldValue(
      `usuarioPontoProntuario[${indice}].observacoes`,
      observacoes,
      false
    )

    setModalMessagemAberto(false)
  }

  return (
    <>
      <BiMessageRoundedDetail size={26} color='#304439' style={{ cursor: 'pointer' }} onClick={() => setModalMessagemAberto(true)}/>

      <Modal show={modalMessagemAberto} onHide={() => setModalMessagemAberto(false)}>
        <Modal.Header style={{ color: '#304439' }}>
          <Modal.Title>
            Observações do paciente
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#304439' }}>
          <Row>
            <Col>
              <div style={{marginBottom: '16px'}}>
                <label className="form-check-label form-label" htmlFor='observacoes'>
                  Observações
                </label>

                <TextAreaStyled
                  className='form-input form-control w-100'
                  id='observacoes'
                  name='observacoes'
                  value={observacoes}
                  rows={3}
                  onChange={(e) => handleInputOnChange(e)}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => handleSubmit()}
          >
            Salvar
          </Button>

          <Button
            variant='danger'
            onClick={() => {
              setModalMessagemAberto(false)
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { UsuarioPontoProntuarioObservacao }
export default UsuarioPontoProntuarioObservacao
