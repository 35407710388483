import { IProntuarioListagem } from '../../../../../api/prontuario'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'

export const getApenasAlterados = (prontuario: IProntuarioListagem | null): GrupoProntuarioPlanoCuidadoEnum[] => {
  const alterados: GrupoProntuarioPlanoCuidadoEnum[] = []
  // if (prontuario) {
  //   if (prontuario.prontuarioComunicacaoEducacao[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.COMUNICACAO_EDUCACAO)
  //     else{
  //     console.log("COMUNICACAO_EDUCACAO")
  // }
  //   if (prontuario.prontuarioCuidadoCorporal[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.CUIDADO_CORPORAL)
  //     else{
  //     console.log("CUIDADO_CORPORAL")
  // }
  //   if (prontuario.prontuarioEliminacaoFisio[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.ELIMINACAO_FISIOLOGICA)
  //     else{
  //     console.log("ELIMINACAO_FISIOLOGICA")
  // }
  //   if (prontuario.prontuarioExameFisico[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.EXAME_FISICO)
  //     else{
  //     console.log("EXAME_FISICO")
  // }
  //   if (prontuario.prontuarioFuncaoCardiovascular[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.FUNCAO_CARDIOVASCULAR)
  //     else{
  //     console.log("FUNCAO_CARDIOVASCULAR")
  // }
  //   if (prontuario.prontuarioFuncaoNeuroAvaOcular[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.FUNCAO_NEUROLOGICA)
  //     else{
  //     console.log("FUNCAO_NEUROLOGICA")
  // }
  //   if (prontuario.prontuarioFuncaoRespiratoria[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.FUNCAO_RESPIRATORIA)
  //     else{
  //     console.log("FUNCAO_RESPIRATORIA")
  // }
  //   if (prontuario.prontuarioIntegridadeFisica[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.INTEGRIDADE_FISICA)
  //     else{
  //     console.log("INTEGRIDADE_FISICA")
  // }
  //   if (prontuario.prontuarioNutricao[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.NUTRICAO)
  //     else{
  //     console.log("NUTRICAO")
  // }
  //   if (prontuario.prontuarioPercepcaoSentidos[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.PERCEPCAO_SENTIDOS)
  //     else{
  //     console.log("PERCEPCAO_SENTIDOS")
  // }
  //   if (prontuario.prontuarioReligiosidade[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.RELIGIOSIDADE_ESPIRITUALIDADE)
  //     else{
  //     console.log("RELIGIOSIDADE_ESPIRITUALIDADE")
  // }
  //   if (prontuario.prontuarioSegFisicaEmocional[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.SONO_REPOUSO)
  //     else{
  //     console.log("SONO_REPOUSO")
  // }
  //   if (prontuario.prontuarioSexualidade[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.SEG_FISICA_EMOCIONAL)
  //     else{
  //     console.log("SEG_FISICA_EMOCIONAL")
  // }
  //   if (prontuario.prontuarioSonoRepouso[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.SEXUALIDADE)
  //     else{
  //     console.log("SEXUALIDADE")
  // }
  //   if (prontuario.prontuarioTerapiaEndovenosa[0]?.alterado) alterados.push(GrupoProntuarioPlanoCuidadoEnum.TERAPIA_ENDOVENOSA)
  //     else{
  //     console.log("TERAPIA_ENDOVENOSA")
  // }
  // }
  alterados.push(
    GrupoProntuarioPlanoCuidadoEnum.COMUNICACAO_EDUCACAO,
    GrupoProntuarioPlanoCuidadoEnum.CUIDADO_CORPORAL,
    GrupoProntuarioPlanoCuidadoEnum.ELIMINACAO_FISIOLOGICA,
    GrupoProntuarioPlanoCuidadoEnum.EXAME_FISICO,
    GrupoProntuarioPlanoCuidadoEnum.FUNCAO_CARDIOVASCULAR,
    GrupoProntuarioPlanoCuidadoEnum.FUNCAO_NEUROLOGICA,
    GrupoProntuarioPlanoCuidadoEnum.FUNCAO_RESPIRATORIA,
    GrupoProntuarioPlanoCuidadoEnum.INTEGRIDADE_FISICA,
    GrupoProntuarioPlanoCuidadoEnum.NUTRICAO,
    GrupoProntuarioPlanoCuidadoEnum.PERCEPCAO_SENTIDOS,
    GrupoProntuarioPlanoCuidadoEnum.RELIGIOSIDADE_ESPIRITUALIDADE,
    GrupoProntuarioPlanoCuidadoEnum.SONO_REPOUSO,
    GrupoProntuarioPlanoCuidadoEnum.SEG_FISICA_EMOCIONAL,
    GrupoProntuarioPlanoCuidadoEnum.SEXUALIDADE,
    GrupoProntuarioPlanoCuidadoEnum.TERAPIA_ENDOVENOSA ,
  )

  return alterados
   
}