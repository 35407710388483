
export enum AceitacaoEnum {
  NENHUM = '-1',
  BOA = 'BOA',
  MEDIA = 'MEDIA',
  RUIM = 'RUIM',
}

export enum TipoSondaEnum {
  NENHUM = '-1',
  SNE =  'SNE',
  SNG =  'SNG',
  SOG =  'SOG',
  JTM =  'JTM',
  GTM =  'GTM',
}

export enum AbdomeEnum {
  NENHUM = '-1',
  PLANO =  'PLANO',
  FLACIDO =  'FLACIDO',
  GLOBOSO =  'GLOBOSO',
}

export enum RhaEnum {
  NENHUM = '-1',
  NORMATIVOS =  'NORMATIVOS',
  HIPERATIVOS =  'HIPERATIVOS',
  HIPOATIVOS =  'HIPOATIVOS',
  AUSENTES =  'AUSENTES',
}

export interface IProntuarioNutricaoListagem {
  id: number;
  oral: boolean | null;
  aceitacao: AceitacaoEnum | null;
  tipo: string | null;
  vazaoMl: string | null;
  tipoSonda: TipoSondaEnum | null;
  dataInsercao: Date | null;
  npt: boolean | null;
  nptEtapa: number | null;
  vazaoMl2: string | null;
  horarioInicio: string | null;
  nptLocal: string | null;
  dietaZero: boolean | null;
  dias: number | null;
  abdome: AbdomeEnum | null;
  rha: RhaEnum | null;
  avaliado: Date | null;
  alterado: boolean | null;
}

export interface IProntuarioNutricaoListagemBackend {
  id: number;
  oral: boolean | null;
  aceitacao: AceitacaoEnum | null;
  tipo: string | null;
  vazao_ml: string | null;
  tipo_sonda: TipoSondaEnum | null;
  data_insercao: Date | null;
  npt: boolean | null;
  npt_etapa: number | null;
  vazao_ml2: string | null;
  horario_inicio: string | null;
  npt_local: string | null;
  dieta_zero: boolean | null;
  dias: number | null;
  abdome: AbdomeEnum | null;
  rha: RhaEnum | null;
  avaliado: Date | null;
  alterado: boolean | null;
}

export interface IProntuarioNutricaoForm {
  oral: boolean | null;
  aceitacao: AceitacaoEnum | null;
  tipo: string | null;
  vazaoMl: string | null;
  tipoSonda: TipoSondaEnum | null;
  dataInsercao: Date | null;
  npt: boolean | null;
  nptEtapa: number | null;
  vazaoMl2: string | null;
  horarioInicio: string | null;
  nptLocal: string | null;
  dietaZero: boolean | null;
  dias: number | null;
  abdome: AbdomeEnum | null;
  rha: RhaEnum | null;
  avaliado: Date | null;
  stAvaliado: boolean | null;
  alterado: boolean | null;
}