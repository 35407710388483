import { AxiosResponse } from 'axios'

import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../prontuario/types'

export interface IResultadoListagem {
  id: number,
  descricao: string,
  inferido: boolean,
  ativo: boolean,
  valorPadrao: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

export interface IResultadoListagemBackend {
  id: number,
  descricao: string,
  inferido: boolean,
  ativo: boolean,
  valor_padrao: number,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
}

export const parserResultadoBackToFront = (dadosBack: IResultadoListagemBackend): IResultadoListagem => ({
  id: dadosBack.id,
  descricao: dadosBack.descricao,
  inferido: dadosBack.inferido,
  ativo: dadosBack.ativo,
  valorPadrao: dadosBack.valor_padrao,
  grupo: dadosBack.grupo || -1,
})

export const resultadoListarAPI = async (): Promise<IResultadoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IResultadoListagemBackend[]> = await axiosAPI.get(
      'resultado',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data.map(dado => (parserResultadoBackToFront(dado)))
}

export const getAllToSelectResultadoAPI = async (grupo: GrupoProntuarioPlanoCuidadoEnum | -1): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IResultadoListagemBackend[]> = await axiosAPI.get(
      'resultado',
      { params: { id_empresa: user.idEmpresa, grupo: grupo !== -1 ? grupo : undefined } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: `${item.descricao} - ${item.valor_padrao}`, value: item.id}
    })
  }

  return response.data
}


export const resultadoCreateAPI = async (
  descricao: string,
  valorPadrao: number,
  inferido: boolean,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'resultado',
      {
        descricao,
        inferido,
        ativo,
        valor_padrao: valorPadrao,
        id_empresa: user.idEmpresa,
        grupo: grupo !== -1 ? grupo : null
      },
  )
}

export const resultadoUpdateAPI = async (
    id: number,
    descricao: string,
    valorPadrao: number,
    inferido: boolean,
    ativo: boolean,
    grupo: GrupoProntuarioPlanoCuidadoEnum | -1
): Promise<void> => {
  await axiosAPI.put(
      'resultado/edit',
      {
        id,
        descricao,
        ativo,
        inferido,
        valor_padrao: valorPadrao,
        grupo: grupo !== -1 ? grupo : null
      },
  )
}

export const resultadoDeleteAPI = async (id: number): Promise<void> => {
  await axiosAPI.delete('resultado/delete', { params: { id } })
}
