import React, { FormEvent, useState } from 'react'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { InputStyled } from '../../../components/styles'
import { TableStyled } from '../../../components/styles/Tabela';
import { IProfissionaisForm } from '../Profissionais'
import { HiOutlineTrash } from 'react-icons/hi'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`

const ProfissionaisFormCampoAptidoes = () => {
  const [aptidao, setAptidao] = useState<string>('')

  const { values, setFieldValue } = useFormikContext<IProfissionaisForm>()

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setAptidao(value);
  };

  const handleInserirAptidao = () => {
    if (aptidao !== '') {
      const novoValorAptidoes: string[] = values.aptidoes.filter(i => i)
      novoValorAptidoes.push(aptidao)
  
      setFieldValue('aptidoes', novoValorAptidoes, false)
      setAptidao('')
    }
  }

  const removerAptidao = (aptidao: string) => {
    const novoValorAptidoes: string[] = values.aptidoes.filter(i => i !== aptidao)
    setFieldValue('aptidoes', novoValorAptidoes, false)
  }

  return (
    <div>
      <RowStyled>
        <Col md={11}>
          <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
            <label className="form-check-label form-label" htmlFor='aptidao'>
              Formação
            </label>
            <InputStyled
              style={{ width: '100%' }}
              value={aptidao}
              onChange={e => handleInputOnChange(e)}
            />
          </div>
        </Col>

        <Col md={1}>
          <MdFormatListBulletedAdd
          color='#fff'
          size={44}
          style={{ marginTop: '32px', cursor: 'pointer' }}
          onClick={() => handleInserirAptidao()}
        />
        </Col>
      </RowStyled>

      {values.aptidoes && values.aptidoes.length > 0 && (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.aptidoes.map((aptidao, index) => (
              <tr key={index}>
                <td>{aptidao}</td>
                <td style={{ width: '1px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={22}
                    onClick={() => removerAptidao(aptidao)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      )}
    </div>
  )
}

export { ProfissionaisFormCampoAptidoes }
export default ProfissionaisFormCampoAptidoes
