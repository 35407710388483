import React from 'react'

import { DashboardDados, DashboardFiltros, DashboardMenu } from './components'

const Dashboard = () => {
  return (
    <>
      <div style={{ display: 'flex', marginLeft: '-150px' }}>
        <DashboardMenu />

        <div style={{ marginLeft: '26px', width: '80%' }}>
          <DashboardFiltros />

          <DashboardDados />
        </div>
      </div>
    </>
  )
}

export { Dashboard }
export default Dashboard
