import React from 'react'
import styled from 'styled-components'

interface IGrupoCampoProps {
  /** Título do grupo */
  titulo: string,

  /** Propriedades de classe **/
  className?: string,

  /** Propriedade que indica que o título é suave */
  tituloLight?: boolean,

  /** Elemento que será o corpo do grupo */
  children: any
}

const ContainerStyled = styled.div`
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  padding: 0.5rem;
  position: relative;
`

interface ITituloStyledProps {
  tituloLight?: boolean
}

const TituloStyled = styled.span<ITituloStyledProps>`
  position: absolute;
  top: -10px;
  padding: 0 0.4rem;
  background-color: #fff;
  font-size: 14px;
  font-weight: 700;
  color: ${(props: ITituloStyledProps) => props.tituloLight ? '#C4C4C4' : ''};
`

const GrupoCampo = (props: IGrupoCampoProps) => {
  return (
    <ContainerStyled className={`mb-3 ${props.className}`}>
      <TituloStyled tituloLight={props.tituloLight}>
        {props.titulo}
      </TituloStyled>

      <div className='px-1 pt-2'>
        {props.children}
      </div>
    </ContainerStyled>
  )
}

export { GrupoCampo }
export default GrupoCampo
