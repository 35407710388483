import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { HiDotsVertical } from 'react-icons/hi'

import { IPacienteListagem } from '../../../api/pacientes'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { PacientesModalInfo } from './PacientesModalInfo'
import PacienteAcaoAltaUnidade from './PacienteAcaoAltaUnidade'

interface IPacienteAcoesProps {
  paciente: IPacienteListagem
}

interface IDropdownItemStyledProps {
  titulo: boolean
}

const CustomToggle = React.forwardRef(({ onClick }: any, ref: any) => (
  <div
    ref={ref}
    style={{ cursor: 'pointer' }}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <HiDotsVertical size={25} />
  </div>
))

export const DropdownItemStyled = styled(Dropdown.Item)<IDropdownItemStyledProps>`
 background-color: ${(props: IDropdownItemStyledProps) => props.titulo ? '#CECECE' : ''};
 text-align: center;
 color: #222222;
 font-weight: ${(props: IDropdownItemStyledProps) => props.titulo ? '700' : '400'};
 font-size: 14px;

 :hover {
    background-color: #CECECE;
    border: none;
    color: #222222;
    cursor:  ${(props: IDropdownItemStyledProps) => props.titulo ? 'auto' : 'pointer'};
  }
`

const PacienteAcoes = (props: IPacienteAcoesProps) => {
  const navigate = useNavigate()

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle} />

      <Dropdown.Menu style={{ paddingTop: '0px', zIndex: 1021 }}>
        <DropdownItemStyled titulo>Opções </DropdownItemStyled>

        <PacientesModalInfo paciente={props.paciente} />

        <Dropdown.Divider />

        <DropdownItemStyled   
          disabled={props.paciente.prontuario[0].dhAdmissao || props.paciente.prontuario[0].dhSaida}       
          onClick={() => navigate(
            `prontuario/${props.paciente.prontuario[0].id}`,
            { state: { prontuarioId: props.paciente.prontuario[0].id, grupo: 'dadosAdmissao' } }
          )}
        >
          Admissão
        </DropdownItemStyled>

        <Dropdown.Divider />

        <DropdownItemStyled
          disabled={!props.paciente.prontuario[0].dhAdmissao}
          onClick={() => navigate(
            `prontuario/${props.paciente.prontuario[0].id}`,
            { state: { prontuarioId: props.paciente.prontuario[0].id } }
          )}
        >
          Evolução diária
        </DropdownItemStyled>

        <Dropdown.Divider />

        <DropdownItemStyled
          disabled={props.paciente.prontuario[0].dhSaida}
          onClick={() => navigate(
            `prontuario/${props.paciente.prontuario[0].id}`,
            { state: { prontuarioId: props.paciente.prontuario[0].id, grupo: 'terapiaEndovenosa' } }
          )}
        >
          Dispositivos invasivos
        </DropdownItemStyled>

        <Dropdown.Divider />

        <DropdownItemStyled
          disabled={props.paciente.prontuario[0].dhSaida}
          onClick={() => navigate(
            `prontuario/${props.paciente.prontuario[0].id}`,
            { state: { prontuarioId: props.paciente.prontuario[0].id, grupo: 'integridadeFisica' } }
          )}
        >
          Acompanhamento de lesões
        </DropdownItemStyled> 
        <Dropdown.Divider />
        { props.paciente.prontuario[0].dhSaida ?    
          <PacienteAcaoAltaUnidade paciente={props.paciente} liberaAlta={false} />
          : <PacienteAcaoAltaUnidade paciente={props.paciente} liberaAlta={true} />
        }  
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { PacienteAcoes }
export default PacienteAcoes