/* eslint-disable max-len */
import React from 'react';
import './logo2Css.css';

export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="23.2834mm"
      height="23.2834mm"
      version="1.1"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        width: '150px',
        height: '150px',
      }}
      viewBox="0 0 975.69 975.69"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="id0">
          <path d="M477.57 136.63c199.64,0 361.48,161.84 361.48,361.48 0,199.64 -161.84,361.48 -361.48,361.48 -199.64,0 -361.48,-161.84 -361.48,-361.48 0,-199.64 161.84,-361.48 361.48,-361.48zm0 53.94c169.85,0 307.54,137.69 307.54,307.54 0,169.85 -137.69,307.54 -307.54,307.54 -169.85,0 -307.54,-137.69 -307.54,-307.54 0,-169.85 137.69,-307.54 307.54,-307.54z"/>
        </clipPath>
        <clipPath id="id1">
          <path d="M477.57 162.18c185.53,0 335.93,150.4 335.93,335.93 0,185.53 -150.4,335.93 -335.93,335.93 -185.53,0 -335.93,-150.4 -335.93,-335.93 0,-185.53 150.4,-335.93 335.93,-335.93zm0 28.39c169.85,0 307.54,137.69 307.54,307.54 0,169.85 -137.69,307.54 -307.54,307.54 -169.85,0 -307.54,-137.69 -307.54,-307.54 0,-169.85 137.69,-307.54 307.54,-307.54z"/>
        </clipPath>
        <clipPath id="id2">
          <path d="M477.57 190.57c169.85,0 307.54,137.69 307.54,307.54 0,169.85 -137.69,307.54 -307.54,307.54 -169.85,0 -307.54,-137.69 -307.54,-307.54 0,-169.85 137.69,-307.54 307.54,-307.54z"/>
        </clipPath>
        <linearGradient id="id3" gradientUnits="userSpaceOnUse" x1="-0" y1="487.84" x2="975.69" y2="487.84">
          <stop offset="0" style={{stopOpacity: '1', stopColor: '#4E6187'}}/>
          <stop offset="1" style={{stopOpacity: '1', stopColor: '#243963'}}/>
        </linearGradient>
        <linearGradient id="id4" gradientUnits="userSpaceOnUse" xlinkHref="#id3" x1="223.97" y1="498.11" x2="731.17" y2="498.11">
        </linearGradient>
      </defs>
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_2111861436288">
          <path className="fil0" d="M110.88 0l753.93 0c61.22,0 110.87,49.65 110.87,110.88l0 753.93c-0,61.22 -49.65,110.87 -110.88,110.87l-753.93 0.01c-61.22,-0.01 -110.87,-49.66 -110.87,-110.88l-0.01 -753.93c0.01,-61.22 49.66,-110.87 110.88,-110.87z"/>
          <path className="fil1" d="M477.57 82.68c229.43,0 415.42,185.99 415.42,415.43 0,109.29 -42.22,208.7 -111.22,282.87l-36.84 -39.59c58.47,-64.22 94.12,-149.59 94.12,-243.28 0,-199.64 -161.84,-361.48 -361.48,-361.48 -84.33,0 -161.91,28.88 -223.4,77.28l-36.84 -39.59c71.22,-57.31 161.71,-91.63 260.24,-91.63z"/>
          <path className="fil1" d="M714.07 812.93c-30.99,23.26 -65.52,42.15 -102.65,55.74 -41.77,15.28 -86.83,23.83 -133.81,24.3l-3.93 0.04c-107.47,0 -204.69,-43.99 -275.3,-114.86 -70.58,-70.84 -114.57,-168.55 -115.65,-276.01l-0.04 -4.02c0,-51.79 9.99,-101.29 28.15,-146.65 16.5,-41.22 39.74,-79.02 68.37,-112.01 -2.04,-3.31 -3.21,-7.22 -3.21,-11.4 0,-12.06 9.78,-21.84 21.84,-21.84 12.06,0 21.84,9.78 21.84,21.84 0,12.06 -9.78,21.84 -21.84,21.84 -4.25,0 -8.23,-1.22 -11.58,-3.32 -27.68,32.02 -50.17,68.67 -66.15,108.6 -17.68,44.16 -27.41,92.4 -27.41,142.94l0 3.94c1.05,104.75 43.94,199.99 112.75,269.05 68.78,69.03 163.5,111.89 268.22,111.89l3.86 0c45.79,-0.46 89.73,-8.8 130.49,-23.71 36.15,-13.23 69.81,-31.65 100.04,-54.34 -0.94,-2.3 -1.47,-4.83 -1.47,-7.48 0,-10.91 8.84,-19.75 19.75,-19.75 10.91,0 19.75,8.84 19.75,19.75 0,10.9 -8.84,19.75 -19.75,19.75 -4.63,0 -8.9,-1.6 -12.27,-4.27z"/>
          <g>
            <g style={{clipPath: 'url(#id0)'}}>
              <polygon className="fil2" points="477.57,498.11 1011.82,448.8 1013.27,468.5 "/>
              <polygon id="1" className="fil3" points="477.57,498.11 1013.03,465.21 1013.84,481.65 "/>
              <polygon id="2" className="fil4" points="477.57,498.11 1013.67,478.36 1014.08,494.82 "/>
              <polygon id="3" className="fil5" points="477.57,498.11 1014,491.53 1014,507.99 "/>
              <polygon id="4" className="fil6" points="477.57,498.11 1014,504.69 1013.59,521.15 "/>
              <polygon id="5" className="fil7" points="477.57,498.11 1013.67,517.86 1012.86,534.29 "/>
              <polygon id="6" className="fil8" points="477.57,498.11 1013.03,531.01 1011.82,547.42 "/>
              <polygon id="7" className="fil9" points="477.57,498.11 1012.06,544.14 1010.45,560.52 "/>
              <polygon id="8" className="fil9" points="477.57,498.11 1010.77,557.24 1008.75,573.58 "/>
              <polygon id="9" className="fil10" points="477.57,498.11 1009.16,570.31 1006.74,586.59 "/>
              <polygon id="10" className="fil11" points="477.57,498.11 1007.22,583.33 1004.41,599.55 "/>
              <polygon id="11" className="fil12" points="477.57,498.11 1004.97,596.31 1001.76,612.45 "/>
              <polygon id="12" className="fil12" points="477.57,498.11 1002.4,609.22 998.8,625.28 "/>
              <polygon id="13" className="fil13" points="477.57,498.11 999.52,622.06 995.52,638.03 "/>
              <polygon id="14" className="fil14" points="477.57,498.11 996.32,634.84 991.93,650.7 "/>
              <polygon id="15" className="fil15" points="477.57,498.11 992.81,647.53 988.03,663.28 "/>
              <polygon id="16" className="fil16" points="477.57,498.11 988.99,660.13 983.82,675.75 "/>
              <polygon id="17" className="fil17" points="477.57,498.11 984.86,672.63 979.31,688.12 "/>
              <polygon id="18" className="fil18" points="477.57,498.11 980.42,685.02 974.5,700.38 "/>
              <polygon id="19" className="fil19" points="477.57,498.11 975.68,697.31 969.38,712.52 "/>
              <polygon id="20" className="fil20" points="477.57,498.11 970.65,709.47 963.97,724.52 "/>
              <polygon id="21" className="fil20" points="477.57,498.11 965.31,721.51 958.27,736.39 "/>
              <polygon id="22" className="fil21" points="477.57,498.11 959.68,733.41 952.28,748.11 "/>
              <polygon id="23" className="fil21" points="477.57,498.11 953.76,745.17 946,759.69 "/>
              <polygon id="24" className="fil22" points="477.57,498.11 947.55,756.78 939.44,771.1 "/>
              <polygon id="25" className="fil23" points="477.57,498.11 941.06,768.24 932.6,782.36 "/>
              <polygon id="26" className="fil24" points="477.57,498.11 934.29,779.53 925.49,793.44 "/>
              <polygon id="27" className="fil24" points="477.57,498.11 927.25,790.66 918.1,804.34 "/>
              <polygon id="28" className="fil25" points="477.57,498.11 919.93,801.61 910.46,815.06 "/>
              <polygon id="29" className="fil25" points="477.57,498.11 912.35,812.37 902.55,825.59 "/>
              <polygon id="30" className="fil26" points="477.57,498.11 904.51,822.94 894.38,835.92 "/>
              <polygon id="31" className="fil27" points="477.57,498.11 896.41,833.32 885.97,846.05 "/>
              <polygon id="32" className="fil28" points="477.57,498.11 888.06,843.5 877.31,855.96 "/>
              <polygon id="33" className="fil28" points="477.57,498.11 879.46,853.47 868.4,865.67 "/>
              <polygon id="34" className="fil29" points="477.57,498.11 870.61,863.23 859.27,875.15 "/>
              <polygon id="35" className="fil29" points="477.57,498.11 861.53,872.76 849.9,884.4 "/>
              <polygon id="36" className="fil30" points="477.57,498.11 852.23,882.07 840.3,893.42 "/>
              <polygon id="37" className="fil31" points="477.57,498.11 842.69,891.15 830.49,902.21 "/>
              <polygon id="38" className="fil32" points="477.57,498.11 832.93,899.99 820.47,910.74 "/>
              <polygon id="39" className="fil32" points="477.57,498.11 822.96,908.59 810.24,919.04 "/>
              <polygon id="40" className="fil33" points="477.57,498.11 812.79,916.95 799.81,927.07 "/>
              <polygon id="41" className="fil34" points="477.57,498.11 802.41,925.05 789.18,934.85 "/>
              <polygon id="42" className="fil35" points="477.57,498.11 791.83,932.89 778.37,942.37 "/>
              <polygon id="43" className="fil36" points="477.57,498.11 781.06,940.47 767.38,949.61 "/>
              <polygon id="44" className="fil37" points="477.57,498.11 770.12,947.79 756.21,956.59 "/>
              <polygon id="45" className="fil38" points="477.57,498.11 758.99,954.83 744.87,963.29 "/>
              <polygon id="46" className="fil39" points="477.57,498.11 747.7,961.6 733.38,969.71 "/>
              <polygon id="47" className="fil40" points="477.57,498.11 736.24,968.09 721.73,975.85 "/>
              <polygon id="48" className="fil40" points="477.57,498.11 724.63,974.3 709.93,981.7 "/>
              <polygon id="49" className="fil41" points="477.57,498.11 712.87,980.22 697.99,987.25 "/>
              <polygon id="50" className="fil42" points="477.57,498.11 700.97,985.84 685.92,992.52 "/>
              <polygon id="51" className="fil43" points="477.57,498.11 688.93,991.18 673.72,997.48 "/>
              <polygon id="52" className="fil43" points="477.57,498.11 676.77,996.22 661.41,1002.14 "/>
              <polygon id="53" className="fil44" points="477.57,498.11 664.48,1000.95 648.99,1006.5 "/>
              <polygon id="54" className="fil45" points="477.57,498.11 652.09,1005.39 636.46,1010.56 "/>
              <polygon id="55" className="fil46" points="477.57,498.11 639.58,1009.52 623.84,1014.3 "/>
              <polygon id="56" className="fil47" points="477.57,498.11 626.98,1013.35 611.12,1017.73 "/>
              <polygon id="57" className="fil48" points="477.57,498.11 614.3,1016.85 598.33,1020.86 "/>
              <polygon id="58" className="fil49" points="477.57,498.11 601.52,1020.06 585.46,1023.66 "/>
              <polygon id="59" className="fil50" points="477.57,498.11 588.68,1022.94 572.54,1026.15 "/>
              <polygon id="60" className="fil51" points="477.57,498.11 575.76,1025.51 559.55,1028.32 "/>
              <polygon id="61" className="fil51" points="477.57,498.11 562.79,1027.76 546.51,1030.18 "/>
              <polygon id="62" className="fil52" points="477.57,498.11 549.77,1029.69 533.43,1031.7 "/>
              <polygon id="63" className="fil52" points="477.57,498.11 536.7,1031.3 520.32,1032.92 "/>
              <polygon id="64" className="fil53" points="477.57,498.11 523.6,1032.59 507.18,1033.8 "/>
              <polygon id="65" className="fil54" points="477.57,498.11 510.47,1033.56 494.03,1034.37 "/>
              <polygon id="66" className="fil55" points="477.57,498.11 497.31,1034.21 480.86,1034.61 "/>
              <polygon id="67" className="fil55" points="477.57,498.11 484.15,1034.53 467.7,1034.53 "/>
              <polygon id="68" className="fil56" points="477.57,498.11 470.98,1034.53 454.53,1034.13 "/>
              <polygon id="69" className="fil56" points="477.57,498.11 457.82,1034.21 441.38,1033.4 "/>
              <polygon id="70" className="fil57" points="477.57,498.11 444.67,1033.56 428.26,1032.35 "/>
              <polygon id="71" className="fil58" points="477.57,498.11 431.54,1032.59 415.16,1030.98 "/>
              <polygon id="72" className="fil59" points="477.57,498.11 418.44,1031.3 402.11,1029.29 "/>
              <polygon id="73" className="fil59" points="477.57,498.11 405.37,1029.69 389.09,1027.28 "/>
              <polygon id="74" className="fil60" points="477.57,498.11 392.35,1027.76 376.13,1024.95 "/>
              <polygon id="75" className="fil60" points="477.57,498.11 379.38,1025.51 363.23,1022.3 "/>
              <polygon id="76" className="fil61" points="477.57,498.11 366.46,1022.94 350.4,1019.33 "/>
              <polygon id="77" className="fil62" points="477.57,498.11 353.61,1020.06 337.65,1016.05 "/>
              <polygon id="78" className="fil63" points="477.57,498.11 340.85,1016.85 324.98,1012.46 "/>
              <polygon id="79" className="fil64" points="477.57,498.11 328.15,1013.34 312.4,1008.56 "/>
              <polygon id="80" className="fil65" points="477.57,498.11 315.56,1009.52 299.93,1004.36 "/>
              <polygon id="81" className="fil66" points="477.57,498.11 303.05,1005.39 287.55,999.84 "/>
              <polygon id="82" className="fil67" points="477.57,498.11 290.66,1000.95 275.3,995.03 "/>
              <polygon id="83" className="fil68" points="477.57,498.11 278.37,996.22 263.17,989.92 "/>
              <polygon id="84" className="fil68" points="477.57,498.11 266.21,991.18 251.16,984.51 "/>
              <polygon id="85" className="fil69" points="477.57,498.11 254.17,985.84 239.29,978.8 "/>
              <polygon id="86" className="fil70" points="477.57,498.11 242.27,980.21 227.57,972.81 "/>
              <polygon id="87" className="fil71" points="477.57,498.11 230.51,974.29 215.99,966.53 "/>
              <polygon id="88" className="fil71" points="477.57,498.11 218.89,968.08 204.58,959.97 "/>
              <polygon id="89" className="fil72" points="477.57,498.11 207.44,961.59 193.32,953.13 "/>
              <polygon id="90" className="fil72" points="477.57,498.11 196.15,954.83 182.24,946.02 "/>
              <polygon id="91" className="fil73" points="477.57,498.11 185.02,947.78 171.34,938.64 "/>
              <polygon id="92" className="fil74" points="477.57,498.11 174.08,940.47 160.62,930.99 "/>
              <polygon id="93" className="fil75" points="477.57,498.11 163.31,932.88 150.09,923.08 "/>
              <polygon id="94" className="fil75" points="477.57,498.11 152.73,925.04 139.76,914.92 "/>
              <polygon id="95" className="fil76" points="477.57,498.11 142.35,916.94 129.63,906.5 "/>
              <polygon id="96" className="fil76" points="477.57,498.11 132.18,908.59 119.71,897.84 "/>
              <polygon id="97" className="fil77" points="477.57,498.11 122.21,899.99 110.01,888.94 "/>
              <polygon id="98" className="fil78" points="477.57,498.11 112.45,891.15 100.53,879.8 "/>
              <polygon id="99" className="fil79" points="477.57,498.11 102.92,882.07 91.28,870.43 "/>
              <polygon id="100" className="fil80" points="477.57,498.11 93.61,872.76 82.26,860.84 "/>
              <polygon id="101" className="fil81" points="477.57,498.11 84.53,863.22 73.48,851.03 "/>
              <polygon id="102" className="fil82" points="477.57,498.11 75.68,853.47 64.94,841 "/>
              <polygon id="103" className="fil83" points="477.57,498.11 67.09,843.5 56.65,830.78 "/>
              <polygon id="104" className="fil84" points="477.57,498.11 58.73,833.32 48.61,820.35 "/>
              <polygon id="105" className="fil84" points="477.57,498.11 50.63,822.94 40.83,809.72 "/>
              <polygon id="106" className="fil85" points="477.57,498.11 42.79,812.36 33.31,798.91 "/>
              <polygon id="107" className="fil86" points="477.57,498.11 35.21,801.6 26.06,787.92 "/>
              <polygon id="108" className="fil87" points="477.57,498.11 27.89,790.65 19.09,776.75 "/>
              <polygon id="109" className="fil87" points="477.57,498.11 20.85,779.53 12.39,765.41 "/>
              <polygon id="110" className="fil88" points="477.57,498.11 14.08,768.23 5.97,753.91 "/>
              <polygon id="111" className="fil89" points="477.57,498.11 7.59,756.78 -0.17,742.26 "/>
              <polygon id="112" className="fil90" points="477.57,498.11 1.38,745.17 -6.01,730.46 "/>
              <polygon id="113" className="fil91" points="477.57,498.11 -4.53,733.41 -11.57,718.52 "/>
              <polygon id="114" className="fil92" points="477.57,498.11 -10.17,721.5 -16.83,706.46 "/>
              <polygon id="115" className="fil93" points="477.57,498.11 -15.5,709.47 -21.8,694.26 "/>
              <polygon id="116" className="fil94" points="477.57,498.11 -20.54,697.3 -26.46,681.95 "/>
              <polygon id="117" className="fil94" points="477.57,498.11 -25.28,685.02 -30.82,669.52 "/>
              <polygon id="118" className="fil94" points="477.57,498.11 -29.71,672.62 -34.87,656.99 "/>
              <polygon id="119" className="fil95" points="477.57,498.11 -33.84,660.12 -38.62,644.37 "/>
              <polygon id="120" className="fil95" points="477.57,498.11 -37.66,647.52 -42.06,631.66 "/>
              <polygon id="121" className="fil96" points="477.57,498.11 -41.18,634.83 -45.17,618.86 "/>
              <polygon id="122" className="fil97" points="477.57,498.11 -44.38,622.06 -47.98,606 "/>
              <polygon id="123" className="fil98" points="477.57,498.11 -47.26,609.21 -50.47,593.07 "/>
              <polygon id="124" className="fil98" points="477.57,498.11 -49.83,596.3 -52.64,580.08 "/>
              <polygon id="125" className="fil99" points="477.57,498.11 -52.08,583.32 -54.49,567.04 "/>
              <polygon id="126" className="fil99" points="477.57,498.11 -54.01,570.3 -56.03,553.96 "/>
              <polygon id="127" className="fil100" points="477.57,498.11 -55.62,557.23 -57.24,540.85 "/>
              <polygon id="128" className="fil100" points="477.57,498.11 -56.92,544.13 -58.12,527.72 "/>
              <polygon id="129" className="fil101" points="477.57,498.11 -57.88,531 -58.69,514.56 "/>
              <polygon id="130" className="fil101" points="477.57,498.11 -58.53,517.85 -58.93,501.4 "/>
              <polygon id="131" className="fil102" points="477.57,498.11 -58.85,504.68 -58.85,488.23 "/>
              <polygon id="132" className="fil103" points="477.57,498.11 -58.85,491.52 -58.44,475.07 "/>
              <polygon id="133" className="fil104" points="477.57,498.11 -58.53,478.36 -57.72,461.92 "/>
              <polygon id="134" className="fil104" points="477.57,498.11 -57.88,465.21 -56.67,448.79 "/>
              <polygon id="135" className="fil105" points="477.57,498.11 -56.91,452.08 -55.3,435.7 "/>
              <polygon id="136" className="fil105" points="477.57,498.11 -55.62,438.97 -53.61,422.64 "/>
              <polygon id="137" className="fil105" points="477.57,498.11 -54.01,425.9 -51.59,409.62 "/>
              <polygon id="138" className="fil106" points="477.57,498.11 -52.08,412.88 -49.26,396.67 "/>
              <polygon id="139" className="fil107" points="477.57,498.11 -49.83,399.91 -46.61,383.77 "/>
              <polygon id="140" className="fil108" points="477.57,498.11 -47.26,387 -43.65,370.94 "/>
              <polygon id="141" className="fil109" points="477.57,498.11 -44.37,374.15 -40.38,358.18 "/>
              <polygon id="142" className="fil110" points="477.57,498.11 -41.18,361.38 -36.78,345.51 "/>
              <polygon id="143" className="fil111" points="477.57,498.11 -37.66,348.69 -32.88,332.94 "/>
              <polygon id="144" className="fil112" points="477.57,498.11 -33.84,336.09 -28.68,320.46 "/>
              <polygon id="145" className="fil112" points="477.57,498.11 -29.71,323.58 -24.17,308.09 "/>
              <polygon id="146" className="fil113" points="477.57,498.11 -25.27,311.19 -19.35,295.84 "/>
              <polygon id="147" className="fil114" points="477.57,498.11 -20.54,298.91 -14.24,283.7 "/>
              <polygon id="148" className="fil115" points="477.57,498.11 -15.5,286.74 -8.83,271.69 "/>
              <polygon id="149" className="fil115" points="477.57,498.11 -10.16,274.7 -3.13,259.83 "/>
              <polygon id="150" className="fil116" points="477.57,498.11 -4.53,262.8 2.87,248.1 "/>
              <polygon id="151" className="fil117" points="477.57,498.11 1.39,251.04 9.15,236.53 "/>
              <polygon id="152" className="fil118" points="477.57,498.11 7.59,239.43 15.71,225.11 "/>
              <polygon id="153" className="fil119" points="477.57,498.11 14.08,227.97 22.54,213.86 "/>
              <polygon id="154" className="fil120" points="477.57,498.11 20.85,216.68 29.66,202.77 "/>
              <polygon id="155" className="fil121" points="477.57,498.11 27.9,205.56 37.04,191.87 "/>
              <polygon id="156" className="fil122" points="477.57,498.11 35.21,194.61 44.69,181.15 "/>
              <polygon id="157" className="fil123" points="477.57,498.11 42.79,183.84 52.6,170.62 "/>
              <polygon id="158" className="fil123" points="477.57,498.11 50.64,173.27 60.76,160.29 "/>
              <polygon id="159" className="fil124" points="477.57,498.11 58.74,162.89 69.18,150.17 "/>
              <polygon id="160" className="fil124" points="477.57,498.11 67.09,152.71 77.84,140.25 "/>
              <polygon id="161" className="fil125" points="477.57,498.11 75.69,142.74 86.74,130.55 "/>
              <polygon id="162" className="fil126" points="477.57,498.11 84.53,132.98 95.88,121.07 "/>
              <polygon id="163" className="fil127" points="477.57,498.11 93.61,123.45 105.25,111.81 "/>
              <polygon id="164" className="fil127" points="477.57,498.11 102.92,114.14 114.84,102.79 "/>
              <polygon id="165" className="fil128" points="477.57,498.11 112.46,105.06 124.65,94.01 "/>
              <polygon id="166" className="fil128" points="477.57,498.11 122.22,96.22 134.68,85.47 "/>
              <polygon id="167" className="fil129" points="477.57,498.11 132.18,87.62 144.91,77.18 "/>
              <polygon id="168" className="fil130" points="477.57,498.11 142.36,79.27 155.34,69.14 "/>
              <polygon id="169" className="fil131" points="477.57,498.11 152.74,71.17 165.96,61.36 "/>
              <polygon id="170" className="fil131" points="477.57,498.11 163.32,63.32 176.77,53.85 "/>
              <polygon id="171" className="fil132" points="477.57,498.11 174.08,55.74 187.77,46.6 "/>
              <polygon id="172" className="fil133" points="477.57,498.11 185.03,48.43 198.93,39.62 "/>
              <polygon id="173" className="fil134" points="477.57,498.11 196.15,41.39 210.27,32.92 "/>
              <polygon id="174" className="fil135" points="477.57,498.11 207.45,34.61 221.76,26.5 "/>
              <polygon id="175" className="fil136" points="477.57,498.11 218.9,28.13 233.42,20.37 "/>
              <polygon id="176" className="fil137" points="477.57,498.11 230.51,21.92 245.21,14.52 "/>
              <polygon id="177" className="fil138" points="477.57,498.11 242.27,16 257.15,8.96 "/>
              <polygon id="178" className="fil139" points="477.57,498.11 254.18,10.37 269.22,3.7 "/>
              <polygon id="179" className="fil139" points="477.57,498.11 266.21,5.03 281.42,-1.27 "/>
              <polygon id="180" className="fil140" points="477.57,498.11 278.38,-0 293.74,-5.93 "/>
              <polygon id="181" className="fil140" points="477.57,498.11 290.66,-4.74 306.16,-10.29 "/>
              <polygon id="182" className="fil141" points="477.57,498.11 303.06,-9.18 318.69,-14.34 "/>
              <polygon id="183" className="fil142" points="477.57,498.11 315.56,-13.31 331.31,-18.09 "/>
              <polygon id="184" className="fil143" points="477.57,498.11 328.16,-17.13 344.02,-21.52 "/>
              <polygon id="185" className="fil143" points="477.57,498.11 340.85,-20.64 356.82,-24.64 "/>
              <polygon id="186" className="fil144" points="477.57,498.11 353.62,-23.84 369.68,-27.45 "/>
              <polygon id="187" className="fil144" points="477.57,498.11 366.47,-26.73 382.61,-29.94 "/>
              <polygon id="188" className="fil145" points="477.57,498.11 379.38,-29.3 395.6,-32.11 "/>
              <polygon id="189" className="fil146" points="477.57,498.11 392.35,-31.55 408.63,-33.96 "/>
              <polygon id="190" className="fil147" points="477.57,498.11 405.38,-33.48 421.71,-35.49 "/>
              <polygon id="191" className="fil147" points="477.57,498.11 418.44,-35.09 434.83,-36.7 "/>
              <polygon id="192" className="fil148" points="477.57,498.11 431.55,-36.38 447.96,-37.59 "/>
              <polygon id="193" className="fil148" points="477.57,498.11 444.68,-37.35 461.12,-38.15 "/>
              <polygon id="194" className="fil149" points="477.57,498.11 457.83,-38 474.28,-38.4 "/>
              <polygon id="195" className="fil150" points="477.57,498.11 470.99,-38.32 487.45,-38.32 "/>
              <polygon id="196" className="fil151" points="477.57,498.11 484.16,-38.32 500.61,-37.91 "/>
              <polygon id="197" className="fil152" points="477.57,498.11 497.32,-38 513.76,-37.19 "/>
              <polygon id="198" className="fil153" points="477.57,498.11 510.47,-37.35 526.89,-36.14 "/>
              <polygon id="199" className="fil154" points="477.57,498.11 523.6,-36.38 539.98,-34.76 "/>
              <polygon id="200" className="fil155" points="477.57,498.11 536.71,-35.09 553.04,-33.08 "/>
              <polygon id="201" className="fil156" points="477.57,498.11 549.77,-33.48 566.05,-31.06 "/>
              <polygon id="202" className="fil156" points="477.57,498.11 562.8,-31.55 579.02,-28.73 "/>
              <polygon id="203" className="fil157" points="477.57,498.11 575.77,-29.3 591.91,-26.08 "/>
              <polygon id="204" className="fil158" points="477.57,498.11 588.68,-26.73 604.75,-23.12 "/>
              <polygon id="205" className="fil159" points="477.57,498.11 601.53,-23.84 617.5,-19.84 "/>
              <polygon id="206" className="fil159" points="477.57,498.11 614.3,-20.64 630.16,-16.25 "/>
              <polygon id="207" className="fil160" points="477.57,498.11 626.99,-17.13 642.74,-12.35 "/>
              <polygon id="208" className="fil161" points="477.57,498.11 639.59,-13.3 655.22,-8.14 "/>
              <polygon id="209" className="fil162" points="477.57,498.11 652.09,-9.18 667.59,-3.63 "/>
              <polygon id="210" className="fil163" points="477.57,498.11 664.49,-4.74 679.85,1.18 "/>
              <polygon id="211" className="fil164" points="477.57,498.11 676.78,-0 691.98,6.3 "/>
              <polygon id="212" className="fil165" points="477.57,498.11 688.94,5.04 703.98,11.71 "/>
              <polygon id="213" className="fil166" points="477.57,498.11 700.98,10.37 715.86,17.41 "/>
              <polygon id="214" className="fil167" points="477.57,498.11 712.88,16 727.58,23.4 "/>
              <polygon id="215" className="fil167" points="477.57,498.11 724.64,21.92 739.15,29.68 "/>
              <polygon id="216" className="fil168" points="477.57,498.11 736.25,28.13 750.57,36.24 "/>
              <polygon id="217" className="fil169" points="477.57,498.11 747.71,34.62 761.83,43.08 "/>
              <polygon id="218" className="fil170" points="477.57,498.11 759,41.39 772.9,50.19 "/>
              <polygon id="219" className="fil170" points="477.57,498.11 770.12,48.43 783.81,57.57 "/>
              <polygon id="220" className="fil171" points="477.57,498.11 781.07,55.75 794.53,65.22 "/>
              <polygon id="221" className="fil171" points="477.57,498.11 791.84,63.33 805.05,73.13 "/>
              <polygon id="222" className="fil172" points="477.57,498.11 802.41,71.17 815.39,81.3 "/>
              <polygon id="223" className="fil173" points="477.57,498.11 812.79,79.27 825.51,89.71 "/>
              <polygon id="224" className="fil174" points="477.57,498.11 822.97,87.62 835.43,98.38 "/>
              <polygon id="225" className="fil174" points="477.57,498.11 832.94,96.23 845.13,107.28 "/>
              <polygon id="226" className="fil175" points="477.57,498.11 842.69,105.07 854.62,116.42 "/>
              <polygon id="227" className="fil175" points="477.57,498.11 852.23,114.14 863.87,125.78 "/>
              <polygon id="228" className="fil176" points="477.57,498.11 861.54,123.46 872.89,135.37 "/>
              <polygon id="229" className="fil177" points="477.57,498.11 870.62,132.99 881.67,145.19 "/>
              <polygon id="230" className="fil178" points="477.57,498.11 879.46,142.75 890.21,155.21 "/>
              <polygon id="231" className="fil178" points="477.57,498.11 888.06,152.72 898.5,165.44 "/>
              <polygon id="232" className="fil179" points="477.57,498.11 896.41,162.9 906.54,175.87 "/>
              <polygon id="233" className="fil179" points="477.57,498.11 904.52,173.28 914.32,186.49 "/>
              <polygon id="234" className="fil180" points="477.57,498.11 912.36,183.85 921.83,197.3 "/>
              <polygon id="235" className="fil181" points="477.57,498.11 919.94,194.61 929.08,208.3 "/>
              <polygon id="236" className="fil182" points="477.57,498.11 927.25,205.56 936.06,219.47 "/>
              <polygon id="237" className="fil183" points="477.57,498.11 934.3,216.69 942.76,230.8 "/>
              <polygon id="238" className="fil184" points="477.57,498.11 941.06,227.98 949.18,242.3 "/>
              <polygon id="239" className="fil185" points="477.57,498.11 947.56,239.44 955.31,253.95 "/>
              <polygon id="240" className="fil186" points="477.57,498.11 953.76,251.05 961.16,265.75 "/>
              <polygon id="241" className="fil187" points="477.57,498.11 959.68,262.81 966.72,277.69 "/>
              <polygon id="242" className="fil187" points="477.57,498.11 965.31,274.71 971.98,289.75 "/>
              <polygon id="243" className="fil188" points="477.57,498.11 970.65,286.75 976.94,301.95 "/>
              <polygon id="244" className="fil189" points="477.57,498.11 975.69,298.91 981.61,314.27 "/>
              <polygon id="245" className="fil190" points="477.57,498.11 980.42,311.2 985.97,326.69 "/>
              <polygon id="246" className="fil190" points="477.57,498.11 984.86,323.59 990.02,339.22 "/>
              <polygon id="247" className="fil191" points="477.57,498.11 988.99,336.09 993.77,351.85 "/>
              <polygon id="248" className="fil192" points="477.57,498.11 992.81,348.69 997.2,364.56 "/>
              <polygon id="249" className="fil193" points="477.57,498.11 996.32,361.38 1000.32,377.35 "/>
              <polygon id="250" className="fil194" points="477.57,498.11 999.52,374.16 1003.13,390.21 "/>
              <polygon id="251" className="fil195" points="477.57,498.11 1002.4,387 1005.61,403.15 "/>
              <polygon id="252" className="fil196" points="477.57,498.11 1004.97,399.91 1007.79,416.13 "/>
              <polygon id="253" className="fil197" points="477.57,498.11 1007.22,412.89 1009.64,429.17 "/>
              <polygon id="254" className="fil197" points="477.57,498.11 1009.16,425.91 1011.17,442.25 "/>
              <polygon id="255" className="fil2" points="477.57,498.11 1010.77,438.98 1012.06,452.08 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id1)'}}>
              <polygon className="fil198" points="477.57,498.11 2.42,495.19 2.85,477.7 "/>
              <polygon id="1" className="fil198" points="477.57,498.11 2.78,480.62 3.49,466.06 "/>
              <polygon id="2" className="fil198" points="477.57,498.11 3.35,468.97 4.43,454.44 "/>
              <polygon id="3" className="fil199" points="477.57,498.11 4.21,457.34 5.64,442.84 "/>
              <polygon id="4" className="fil200" points="477.57,498.11 5.36,445.74 7.14,431.27 "/>
              <polygon id="5" className="fil201" points="477.57,498.11 6.78,434.17 8.92,419.75 "/>
              <polygon id="6" className="fil201" points="477.57,498.11 8.49,422.63 10.98,408.27 "/>
              <polygon id="7" className="fil201" points="477.57,498.11 10.48,411.14 13.33,396.85 "/>
              <polygon id="8" className="fil201" points="477.57,498.11 12.76,399.7 15.95,385.48 "/>
              <polygon id="9" className="fil201" points="477.57,498.11 15.32,388.33 18.86,374.19 "/>
              <polygon id="10" className="fil202" points="477.57,498.11 18.15,377.02 22.04,362.97 "/>
              <polygon id="11" className="fil202" points="477.57,498.11 21.26,365.78 25.49,351.83 "/>
              <polygon id="12" className="fil202" points="477.57,498.11 24.64,354.62 29.22,340.78 "/>
              <polygon id="13" className="fil203" points="477.57,498.11 28.3,343.55 33.21,329.82 "/>
              <polygon id="14" className="fil203" points="477.57,498.11 32.23,332.57 37.48,318.97 "/>
              <polygon id="15" className="fil203" points="477.57,498.11 36.43,321.69 42.01,308.22 "/>
              <polygon id="16" className="fil204" points="477.57,498.11 40.89,310.92 46.8,297.59 "/>
              <polygon id="17" className="fil204" points="477.57,498.11 45.61,300.26 51.85,287.08 "/>
              <polygon id="18" className="fil205" points="477.57,498.11 50.6,289.71 57.15,276.69 "/>
              <polygon id="19" className="fil205" points="477.57,498.11 55.84,279.3 62.71,266.44 "/>
              <polygon id="20" className="fil205" points="477.57,498.11 61.34,269.02 68.53,256.33 "/>
              <polygon id="21" className="fil206" points="477.57,498.11 67.09,258.87 74.58,246.37 "/>
              <polygon id="22" className="fil206" points="477.57,498.11 73.08,248.87 80.88,236.55 "/>
              <polygon id="23" className="fil206" points="477.57,498.11 79.32,239.02 87.42,226.89 "/>
              <polygon id="24" className="fil206" points="477.57,498.11 85.8,229.32 94.19,217.4 "/>
              <polygon id="25" className="fil207" points="477.57,498.11 92.51,219.79 101.2,208.08 "/>
              <polygon id="26" className="fil207" points="477.57,498.11 99.46,210.42 108.43,198.93 "/>
              <polygon id="27" className="fil208" points="477.57,498.11 106.64,201.23 115.88,189.96 "/>
              <polygon id="28" className="fil208" points="477.57,498.11 114.03,192.21 123.55,181.18 "/>
              <polygon id="29" className="fil208" points="477.57,498.11 121.65,183.38 131.44,172.58 "/>
              <polygon id="30" className="fil209" points="477.57,498.11 129.48,174.74 139.53,164.19 "/>
              <polygon id="31" className="fil209" points="477.57,498.11 137.52,166.3 147.83,155.99 "/>
              <polygon id="32" className="fil210" points="477.57,498.11 145.77,158.05 156.32,148 "/>
              <polygon id="33" className="fil210" points="477.57,498.11 154.21,150.01 165.01,140.22 "/>
              <polygon id="34" className="fil210" points="477.57,498.11 162.85,142.18 173.89,132.66 "/>
              <polygon id="35" className="fil210" points="477.57,498.11 171.68,134.56 182.95,125.32 "/>
              <polygon id="36" className="fil210" points="477.57,498.11 180.69,127.17 192.18,118.2 "/>
              <polygon id="37" className="fil210" points="477.57,498.11 189.89,119.99 201.6,111.31 "/>
              <polygon id="38" className="fil211" points="477.57,498.11 199.25,113.05 211.17,104.65 "/>
              <polygon id="39" className="fil212" points="477.57,498.11 208.79,106.33 220.91,98.23 "/>
              <polygon id="40" className="fil213" points="477.57,498.11 218.48,99.85 230.79,92.06 "/>
              <polygon id="41" className="fil213" points="477.57,498.11 228.34,93.62 240.84,86.12 "/>
              <polygon id="42" className="fil213" points="477.57,498.11 238.33,87.62 251.02,80.44 "/>
              <polygon id="43" className="fil213" points="477.57,498.11 248.48,81.87 261.34,75 "/>
              <polygon id="44" className="fil213" points="477.57,498.11 258.77,76.38 271.79,69.82 "/>
              <polygon id="45" className="fil213" points="477.57,498.11 269.18,71.13 282.36,64.9 "/>
              <polygon id="46" className="fil213" points="477.57,498.11 279.72,66.15 293.05,60.24 "/>
              <polygon id="47" className="fil214" points="477.57,498.11 290.38,61.42 303.85,55.85 "/>
              <polygon id="48" className="fil215" points="477.57,498.11 301.16,56.96 314.76,51.71 "/>
              <polygon id="49" className="fil216" points="477.57,498.11 312.04,52.76 325.76,47.86 "/>
              <polygon id="50" className="fil216" points="477.57,498.11 323.01,48.84 336.86,44.26 "/>
              <polygon id="51" className="fil216" points="477.57,498.11 334.09,45.18 348.04,40.95 "/>
              <polygon id="52" className="fil216" points="477.57,498.11 345.25,41.79 359.29,37.91 "/>
              <polygon id="53" className="fil216" points="477.57,498.11 356.48,38.68 370.62,35.14 "/>
              <polygon id="54" className="fil217" points="477.57,498.11 367.79,35.85 382.02,32.66 "/>
              <polygon id="55" className="fil217" points="477.57,498.11 379.17,33.29 393.47,30.45 "/>
              <polygon id="56" className="fil217" points="477.57,498.11 390.61,31.02 404.97,28.53 "/>
              <polygon id="57" className="fil218" points="477.57,498.11 402.1,29.03 416.52,26.89 "/>
              <polygon id="58" className="fil218" points="477.57,498.11 413.63,27.31 428.1,25.53 "/>
              <polygon id="59" className="fil218" points="477.57,498.11 425.2,25.89 439.71,24.46 "/>
              <polygon id="60" className="fil219" points="477.57,498.11 436.81,24.74 451.35,23.67 "/>
              <polygon id="61" className="fil220" points="477.57,498.11 448.44,23.89 463,23.17 "/>
              <polygon id="62" className="fil220" points="477.57,498.11 460.09,23.32 474.66,22.96 "/>
              <polygon id="63" className="fil220" points="477.57,498.11 471.74,23.03 486.32,23.03 "/>
              <polygon id="64" className="fil220" points="477.57,498.11 483.41,23.03 497.98,23.39 "/>
              <polygon id="65" className="fil221" points="477.57,498.11 495.06,23.32 509.62,24.03 "/>
              <polygon id="66" className="fil221" points="477.57,498.11 506.71,23.89 521.25,24.96 "/>
              <polygon id="67" className="fil221" points="477.57,498.11 518.34,24.74 532.84,26.17 "/>
              <polygon id="68" className="fil221" points="477.57,498.11 529.94,25.89 544.41,27.67 "/>
              <polygon id="69" className="fil222" points="477.57,498.11 541.51,27.31 555.93,29.45 "/>
              <polygon id="70" className="fil222" points="477.57,498.11 553.05,29.03 567.41,31.52 "/>
              <polygon id="71" className="fil222" points="477.57,498.11 564.54,31.02 578.84,33.86 "/>
              <polygon id="72" className="fil223" points="477.57,498.11 575.97,33.29 590.2,36.49 "/>
              <polygon id="73" className="fil223" points="477.57,498.11 587.35,35.85 601.49,39.39 "/>
              <polygon id="74" className="fil224" points="477.57,498.11 598.66,38.68 612.71,42.57 "/>
              <polygon id="75" className="fil224" points="477.57,498.11 609.9,41.79 623.85,46.02 "/>
              <polygon id="76" className="fil225" points="477.57,498.11 621.06,45.18 634.9,49.75 "/>
              <polygon id="77" className="fil225" points="477.57,498.11 632.13,48.84 645.86,53.75 "/>
              <polygon id="78" className="fil225" points="477.57,498.11 643.11,52.76 656.71,58.01 "/>
              <polygon id="79" className="fil225" points="477.57,498.11 653.99,56.96 667.46,62.54 "/>
              <polygon id="80" className="fil225" points="477.57,498.11 664.77,61.42 678.09,67.33 "/>
              <polygon id="81" className="fil225" points="477.57,498.11 675.43,66.15 688.6,72.38 "/>
              <polygon id="82" className="fil225" points="477.57,498.11 685.96,71.13 698.98,77.69 "/>
              <polygon id="83" className="fil226" points="477.57,498.11 696.38,76.38 709.23,83.25 "/>
              <polygon id="84" className="fil226" points="477.57,498.11 706.67,81.87 719.35,89.06 "/>
              <polygon id="85" className="fil226" points="477.57,498.11 716.81,87.62 729.32,95.12 "/>
              <polygon id="86" className="fil226" points="477.57,498.11 726.81,93.62 739.13,101.41 "/>
              <polygon id="87" className="fil226" points="477.57,498.11 736.67,99.85 748.78,107.95 "/>
              <polygon id="88" className="fil226" points="477.57,498.11 746.36,106.33 758.28,114.73 "/>
              <polygon id="89" className="fil226" points="477.57,498.11 755.9,113.05 767.6,121.73 "/>
              <polygon id="90" className="fil226" points="477.57,498.11 765.26,119.99 776.75,128.96 "/>
              <polygon id="91" className="fil227" points="477.57,498.11 774.45,127.17 785.72,136.42 "/>
              <polygon id="92" className="fil228" points="477.57,498.11 783.46,134.56 794.5,144.09 "/>
              <polygon id="93" className="fil228" points="477.57,498.11 792.29,142.18 803.1,151.97 "/>
              <polygon id="94" className="fil178" points="477.57,498.11 800.93,150.01 811.49,160.06 "/>
              <polygon id="95" className="fil178" points="477.57,498.11 809.38,158.05 819.69,168.36 "/>
              <polygon id="96" className="fil178" points="477.57,498.11 817.63,166.3 827.68,176.86 "/>
              <polygon id="97" className="fil178" points="477.57,498.11 825.67,174.74 835.46,185.55 "/>
              <polygon id="98" className="fil229" points="477.57,498.11 833.5,183.38 843.02,194.42 "/>
              <polygon id="99" className="fil229" points="477.57,498.11 841.11,192.21 850.36,203.48 "/>
              <polygon id="100" className="fil229" points="477.57,498.11 848.51,201.23 857.48,212.72 "/>
              <polygon id="101" className="fil230" points="477.57,498.11 855.69,210.42 864.37,222.13 "/>
              <polygon id="102" className="fil230" points="477.57,498.11 862.63,219.79 871.02,231.7 "/>
              <polygon id="103" className="fil230" points="477.57,498.11 869.35,229.32 877.44,241.44 "/>
              <polygon id="104" className="fil230" points="477.57,498.11 875.82,239.02 883.62,251.33 "/>
              <polygon id="105" className="fil180" points="477.57,498.11 882.06,248.87 889.56,261.37 "/>
              <polygon id="106" className="fil180" points="477.57,498.11 888.06,258.87 895.24,271.55 "/>
              <polygon id="107" className="fil180" points="477.57,498.11 893.8,269.02 900.68,281.87 "/>
              <polygon id="108" className="fil180" points="477.57,498.11 899.3,279.3 905.86,292.32 "/>
              <polygon id="109" className="fil181" points="477.57,498.11 904.54,289.71 910.78,302.89 "/>
              <polygon id="110" className="fil181" points="477.57,498.11 909.53,300.26 915.44,313.58 "/>
              <polygon id="111" className="fil181" points="477.57,498.11 914.26,310.92 919.84,324.38 "/>
              <polygon id="112" className="fil231" points="477.57,498.11 918.72,321.69 923.96,335.29 "/>
              <polygon id="113" className="fil231" points="477.57,498.11 922.92,332.57 927.83,346.29 "/>
              <polygon id="114" className="fil231" points="477.57,498.11 926.84,343.55 931.42,357.39 "/>
              <polygon id="115" className="fil231" points="477.57,498.11 930.5,354.62 934.73,368.57 "/>
              <polygon id="116" className="fil183" points="477.57,498.11 933.89,365.78 937.78,379.83 "/>
              <polygon id="117" className="fil183" points="477.57,498.11 937,377.02 940.54,391.16 "/>
              <polygon id="118" className="fil184" points="477.57,498.11 939.83,388.33 943.03,402.55 "/>
              <polygon id="119" className="fil184" points="477.57,498.11 942.38,399.7 945.23,414 "/>
              <polygon id="120" className="fil185" points="477.57,498.11 944.66,411.14 947.15,425.51 "/>
              <polygon id="121" className="fil185" points="477.57,498.11 946.65,422.63 948.79,437.05 "/>
              <polygon id="122" className="fil185" points="477.57,498.11 948.36,434.17 950.15,448.63 "/>
              <polygon id="123" className="fil185" points="477.57,498.11 949.79,445.74 951.22,460.24 "/>
              <polygon id="124" className="fil185" points="477.57,498.11 950.93,457.34 952.01,471.88 "/>
              <polygon id="125" className="fil185" points="477.57,498.11 951.79,468.97 952.51,483.53 "/>
              <polygon id="126" className="fil185" points="477.57,498.11 952.37,480.62 952.72,495.19 "/>
              <polygon id="127" className="fil187" points="477.57,498.11 952.65,492.28 952.65,506.85 "/>
              <polygon id="128" className="fil187" points="477.57,498.11 952.65,503.94 952.3,518.51 "/>
              <polygon id="129" className="fil187" points="477.57,498.11 952.37,515.59 951.65,530.15 "/>
              <polygon id="130" className="fil187" points="477.57,498.11 951.79,527.24 950.72,541.78 "/>
              <polygon id="131" className="fil187" points="477.57,498.11 950.93,538.87 949.5,553.38 "/>
              <polygon id="132" className="fil187" points="477.57,498.11 949.79,550.47 948.01,564.94 "/>
              <polygon id="133" className="fil187" points="477.57,498.11 948.36,562.05 946.23,576.47 "/>
              <polygon id="134" className="fil188" points="477.57,498.11 946.65,573.58 944.16,587.95 "/>
              <polygon id="135" className="fil188" points="477.57,498.11 944.66,585.07 941.81,599.37 "/>
              <polygon id="136" className="fil232" points="477.57,498.11 942.38,596.51 939.19,610.73 "/>
              <polygon id="137" className="fil232" points="477.57,498.11 939.83,607.88 936.29,622.03 "/>
              <polygon id="138" className="fil189" points="477.57,498.11 937,619.2 933.11,633.24 "/>
              <polygon id="139" className="fil189" points="477.57,498.11 933.89,630.44 929.65,644.38 "/>
              <polygon id="140" className="fil189" points="477.57,498.11 930.5,641.59 925.93,655.43 "/>
              <polygon id="141" className="fil189" points="477.57,498.11 926.84,652.67 921.94,666.39 "/>
              <polygon id="142" className="fil190" points="477.57,498.11 922.92,663.65 917.67,677.24 "/>
              <polygon id="143" className="fil190" points="477.57,498.11 918.72,674.52 913.14,687.99 "/>
              <polygon id="144" className="fil190" points="477.57,498.11 914.26,685.3 908.35,698.62 "/>
              <polygon id="145" className="fil233" points="477.57,498.11 909.53,695.96 903.3,709.13 "/>
              <polygon id="146" className="fil233" points="477.57,498.11 904.54,706.5 897.99,719.52 "/>
              <polygon id="147" className="fil233" points="477.57,498.11 899.3,716.92 892.43,729.77 "/>
              <polygon id="148" className="fil233" points="477.57,498.11 893.8,727.2 886.62,739.88 "/>
              <polygon id="149" className="fil192" points="477.57,498.11 888.06,737.34 880.57,749.85 "/>
              <polygon id="150" className="fil192" points="477.57,498.11 882.06,747.35 874.26,759.66 "/>
              <polygon id="151" className="fil192" points="477.57,498.11 875.82,757.2 867.73,769.32 "/>
              <polygon id="152" className="fil192" points="477.57,498.11 869.35,766.89 860.96,778.81 "/>
              <polygon id="153" className="fil234" points="477.57,498.11 862.63,776.43 853.95,788.14 "/>
              <polygon id="154" className="fil234" points="477.57,498.11 855.69,785.79 846.72,797.29 "/>
              <polygon id="155" className="fil234" points="477.57,498.11 848.51,794.98 839.27,806.25 "/>
              <polygon id="156" className="fil194" points="477.57,498.11 841.11,804 831.59,815.04 "/>
              <polygon id="157" className="fil194" points="477.57,498.11 833.5,812.83 823.71,823.63 "/>
              <polygon id="158" className="fil194" points="477.57,498.11 825.67,821.47 815.61,832.03 "/>
              <polygon id="159" className="fil194" points="477.57,498.11 817.63,829.92 807.32,840.22 "/>
              <polygon id="160" className="fil235" points="477.57,498.11 809.38,838.16 798.82,848.21 "/>
              <polygon id="161" className="fil235" points="477.57,498.11 800.93,846.2 790.14,855.99 "/>
              <polygon id="162" className="fil236" points="477.57,498.11 792.29,854.03 781.26,863.55 "/>
              <polygon id="163" className="fil237" points="477.57,498.11 783.46,861.65 772.2,870.9 "/>
              <polygon id="164" className="fil237" points="477.57,498.11 774.45,869.05 762.96,878.01 "/>
              <polygon id="165" className="fil237" points="477.57,498.11 765.26,876.22 753.55,884.9 "/>
              <polygon id="166" className="fil237" points="477.57,498.11 755.9,883.16 743.98,891.56 "/>
              <polygon id="167" className="fil237" points="477.57,498.11 746.36,889.88 734.24,897.98 "/>
              <polygon id="168" className="fil237" points="477.57,498.11 736.67,896.36 724.35,904.16 "/>
              <polygon id="169" className="fil237" points="477.57,498.11 726.81,902.6 714.31,910.09 "/>
              <polygon id="170" className="fil237" points="477.57,498.11 716.81,908.59 704.13,915.78 "/>
              <polygon id="171" className="fil238" points="477.57,498.11 706.67,914.34 693.81,921.21 "/>
              <polygon id="172" className="fil238" points="477.57,498.11 696.38,919.84 683.36,926.39 "/>
              <polygon id="173" className="fil238" points="477.57,498.11 685.96,925.08 672.79,931.31 "/>
              <polygon id="174" className="fil238" points="477.57,498.11 675.43,930.06 662.1,935.97 "/>
              <polygon id="175" className="fil238" points="477.57,498.11 664.77,934.79 651.3,940.37 "/>
              <polygon id="176" className="fil238" points="477.57,498.11 653.99,939.25 640.39,944.5 "/>
              <polygon id="177" className="fil238" points="477.57,498.11 643.11,943.45 629.39,948.36 "/>
              <polygon id="178" className="fil239" points="477.57,498.11 632.13,947.38 618.29,951.95 "/>
              <polygon id="179" className="fil239" points="477.57,498.11 621.06,951.03 607.11,955.27 "/>
              <polygon id="180" className="fil240" points="477.57,498.11 609.9,954.42 595.85,958.31 "/>
              <polygon id="181" className="fil240" points="477.57,498.11 598.66,957.53 584.52,961.07 "/>
              <polygon id="182" className="fil241" points="477.57,498.11 587.35,960.36 573.13,963.56 "/>
              <polygon id="183" className="fil241" points="477.57,498.11 575.97,962.92 561.68,965.76 "/>
              <polygon id="184" className="fil241" points="477.57,498.11 564.54,965.19 550.18,967.69 "/>
              <polygon id="185" className="fil242" points="477.57,498.11 553.05,967.19 538.63,969.33 "/>
              <polygon id="186" className="fil242" points="477.57,498.11 541.51,968.9 527.05,970.68 "/>
              <polygon id="187" className="fil242" points="477.57,498.11 529.94,970.33 515.43,971.76 "/>
              <polygon id="188" className="fil242" points="477.57,498.11 518.34,971.47 503.8,972.54 "/>
              <polygon id="189" className="fil243" points="477.57,498.11 506.71,972.33 492.15,973.04 "/>
              <polygon id="190" className="fil243" points="477.57,498.11 495.06,972.9 480.49,973.26 "/>
              <polygon id="191" className="fil243" points="477.57,498.11 483.41,973.18 468.83,973.18 "/>
              <polygon id="192" className="fil243" points="477.57,498.11 471.74,973.18 457.17,972.83 "/>
              <polygon id="193" className="fil244" points="477.57,498.11 460.09,972.9 445.53,972.18 "/>
              <polygon id="194" className="fil245" points="477.57,498.11 448.44,972.33 433.9,971.25 "/>
              <polygon id="195" className="fil245" points="477.57,498.11 436.81,971.47 422.3,970.04 "/>
              <polygon id="196" className="fil245" points="477.57,498.11 425.2,970.33 410.74,968.54 "/>
              <polygon id="197" className="fil246" points="477.57,498.11 413.63,968.9 399.21,966.76 "/>
              <polygon id="198" className="fil246" points="477.57,498.11 402.1,967.19 387.74,964.69 "/>
              <polygon id="199" className="fil246" points="477.57,498.11 390.61,965.19 376.31,962.35 "/>
              <polygon id="200" className="fil247" points="477.57,498.11 379.17,962.92 364.95,959.72 "/>
              <polygon id="201" className="fil247" points="477.57,498.11 367.79,960.36 353.66,956.82 "/>
              <polygon id="202" className="fil247" points="477.57,498.11 356.48,957.53 342.43,953.64 "/>
              <polygon id="203" className="fil247" points="477.57,498.11 345.25,954.42 331.3,950.19 "/>
              <polygon id="204" className="fil247" points="477.57,498.11 334.09,951.03 320.24,946.46 "/>
              <polygon id="205" className="fil248" points="477.57,498.11 323.01,947.38 309.29,942.47 "/>
              <polygon id="206" className="fil249" points="477.57,498.11 312.04,943.45 298.43,938.2 "/>
              <polygon id="207" className="fil250" points="477.57,498.11 301.16,939.25 287.69,933.67 "/>
              <polygon id="208" className="fil250" points="477.57,498.11 290.38,934.79 277.06,928.88 "/>
              <polygon id="209" className="fil250" points="477.57,498.11 279.72,930.06 266.54,923.83 "/>
              <polygon id="210" className="fil250" points="477.57,498.11 269.18,925.08 256.16,918.52 "/>
              <polygon id="211" className="fil250" points="477.57,498.11 258.77,919.84 245.91,912.96 "/>
              <polygon id="212" className="fil250" points="477.57,498.11 248.48,914.34 235.8,907.16 "/>
              <polygon id="213" className="fil250" points="477.57,498.11 238.33,908.59 225.83,901.1 "/>
              <polygon id="214" className="fil251" points="477.57,498.11 228.34,902.6 216.02,894.8 "/>
              <polygon id="215" className="fil252" points="477.57,498.11 218.48,896.36 206.36,888.26 "/>
              <polygon id="216" className="fil253" points="477.57,498.11 208.79,889.88 196.87,881.49 "/>
              <polygon id="217" className="fil253" points="477.57,498.11 199.25,883.16 187.54,874.48 "/>
              <polygon id="218" className="fil253" points="477.57,498.11 189.89,876.22 178.4,867.25 "/>
              <polygon id="219" className="fil253" points="477.57,498.11 180.69,869.05 169.43,859.8 "/>
              <polygon id="220" className="fil253" points="477.57,498.11 171.68,861.65 160.64,852.13 "/>
              <polygon id="221" className="fil253" points="477.57,498.11 162.85,854.03 152.05,844.24 "/>
              <polygon id="222" className="fil254" points="477.57,498.11 154.21,846.2 143.65,836.15 "/>
              <polygon id="223" className="fil254" points="477.57,498.11 145.77,838.16 135.46,827.85 "/>
              <polygon id="224" className="fil255" points="477.57,498.11 137.52,829.92 127.47,819.36 "/>
              <polygon id="225" className="fil255" points="477.57,498.11 129.48,821.47 119.69,810.67 "/>
              <polygon id="226" className="fil255" points="477.57,498.11 121.65,812.83 112.13,801.79 "/>
              <polygon id="227" className="fil256" points="477.57,498.11 114.03,804 104.78,792.73 "/>
              <polygon id="228" className="fil256" points="477.57,498.11 106.64,794.98 97.67,783.49 "/>
              <polygon id="229" className="fil257" points="477.57,498.11 99.46,785.79 90.78,774.09 "/>
              <polygon id="230" className="fil257" points="477.57,498.11 92.51,776.43 84.12,764.51 "/>
              <polygon id="231" className="fil257" points="477.57,498.11 85.8,766.89 77.7,754.77 "/>
              <polygon id="232" className="fil257" points="477.57,498.11 79.32,757.2 71.52,744.88 "/>
              <polygon id="233" className="fil258" points="477.57,498.11 73.08,747.35 65.59,734.84 "/>
              <polygon id="234" className="fil258" points="477.57,498.11 67.09,737.34 59.9,724.66 "/>
              <polygon id="235" className="fil258" points="477.57,498.11 61.34,727.2 54.47,714.34 "/>
              <polygon id="236" className="fil259" points="477.57,498.11 55.84,716.92 49.29,703.9 "/>
              <polygon id="237" className="fil259" points="477.57,498.11 50.6,706.5 44.37,693.32 "/>
              <polygon id="238" className="fil260" points="477.57,498.11 45.61,695.96 39.71,682.63 "/>
              <polygon id="239" className="fil260" points="477.57,498.11 40.89,685.3 35.31,671.83 "/>
              <polygon id="240" className="fil260" points="477.57,498.11 36.43,674.52 31.18,660.93 "/>
              <polygon id="241" className="fil261" points="477.57,498.11 32.23,663.65 27.32,649.92 "/>
              <polygon id="242" className="fil261" points="477.57,498.11 28.3,652.67 23.73,638.83 "/>
              <polygon id="243" className="fil261" points="477.57,498.11 24.64,641.59 20.41,627.65 "/>
              <polygon id="244" className="fil262" points="477.57,498.11 21.26,630.44 17.37,616.39 "/>
              <polygon id="245" className="fil262" points="477.57,498.11 18.15,619.2 14.61,605.06 "/>
              <polygon id="246" className="fil262" points="477.57,498.11 15.32,607.88 12.12,593.66 "/>
              <polygon id="247" className="fil262" points="477.57,498.11 12.76,596.51 9.92,582.21 "/>
              <polygon id="248" className="fil262" points="477.57,498.11 10.48,585.07 8,570.71 "/>
              <polygon id="249" className="fil263" points="477.57,498.11 8.49,573.58 6.35,559.17 "/>
              <polygon id="250" className="fil264" points="477.57,498.11 6.78,562.05 5,547.58 "/>
              <polygon id="251" className="fil265" points="477.57,498.11 5.36,550.47 3.93,535.97 "/>
              <polygon id="252" className="fil265" points="477.57,498.11 4.21,538.87 3.14,524.33 "/>
              <polygon id="253" className="fil265" points="477.57,498.11 3.35,527.24 2.64,512.68 "/>
              <polygon id="254" className="fil265" points="477.57,498.11 2.78,515.59 2.42,501.02 "/>
              <polygon id="255" className="fil198" points="477.57,498.11 2.49,503.94 2.49,492.28 "/>
            </g>
          </g>
          <g>
            <g style={{clipPath: 'url(#id2)'}}>
              <polygon className="fil266" points="477.57,498.11 702.34,55.38 718.49,63.95 "/>
              <polygon id="1" className="fil267" points="477.57,498.11 715.8,62.52 729.07,69.99 "/>
              <polygon id="2" className="fil267" points="477.57,498.11 726.41,68.5 739.5,76.3 "/>
              <polygon id="3" className="fil267" points="477.57,498.11 736.88,74.74 749.77,82.85 "/>
              <polygon id="4" className="fil267" points="477.57,498.11 747.19,81.23 759.88,89.66 "/>
              <polygon id="5" className="fil268" points="477.57,498.11 757.34,87.97 769.82,96.71 "/>
              <polygon id="6" className="fil268" points="477.57,498.11 767.32,94.96 779.58,104 "/>
              <polygon id="7" className="fil268" points="477.57,498.11 777.13,102.19 789.16,111.53 "/>
              <polygon id="8" className="fil268" points="477.57,498.11 786.75,109.66 798.55,119.29 "/>
              <polygon id="9" className="fil269" points="477.57,498.11 796.2,117.37 807.76,127.29 "/>
              <polygon id="10" className="fil269" points="477.57,498.11 805.44,125.3 816.76,135.5 "/>
              <polygon id="11" className="fil269" points="477.57,498.11 814.5,133.46 825.55,143.93 "/>
              <polygon id="12" className="fil269" points="477.57,498.11 823.34,141.84 834.14,152.58 "/>
              <polygon id="13" className="fil270" points="477.57,498.11 831.98,150.43 842.51,161.43 "/>
              <polygon id="14" className="fil270" points="477.57,498.11 840.41,159.23 850.67,170.49 "/>
              <polygon id="15" className="fil270" points="477.57,498.11 848.61,168.24 858.59,179.75 "/>
              <polygon id="16" className="fil270" points="477.57,498.11 856.6,177.45 866.29,189.19 "/>
              <polygon id="17" className="fil271" points="477.57,498.11 864.35,186.85 873.76,198.83 "/>
              <polygon id="18" className="fil271" points="477.57,498.11 871.88,196.43 880.98,208.64 "/>
              <polygon id="19" className="fil271" points="477.57,498.11 879.16,206.2 887.96,218.63 "/>
              <polygon id="20" className="fil271" points="477.57,498.11 886.2,216.14 894.7,228.78 "/>
              <polygon id="21" className="fil272" points="477.57,498.11 893,226.25 901.18,239.1 "/>
              <polygon id="22" className="fil272" points="477.57,498.11 899.55,236.53 907.41,249.57 "/>
              <polygon id="23" className="fil272" points="477.57,498.11 905.84,246.97 913.38,260.2 "/>
              <polygon id="24" className="fil272" points="477.57,498.11 911.87,257.55 919.09,270.96 "/>
              <polygon id="25" className="fil273" points="477.57,498.11 917.64,268.28 924.53,281.87 "/>
              <polygon id="26" className="fil273" points="477.57,498.11 923.15,279.15 929.7,292.9 "/>
              <polygon id="27" className="fil273" points="477.57,498.11 928.39,290.15 934.6,304.06 "/>
              <polygon id="28" className="fil273" points="477.57,498.11 933.36,301.28 939.22,315.33 "/>
              <polygon id="29" className="fil274" points="477.57,498.11 938.05,312.52 943.57,326.72 "/>
              <polygon id="30" className="fil274" points="477.57,498.11 942.47,323.88 947.64,338.21 "/>
              <polygon id="31" className="fil274" points="477.57,498.11 946.6,335.34 951.42,349.79 "/>
              <polygon id="32" className="fil274" points="477.57,498.11 950.46,346.9 954.92,361.46 "/>
              <polygon id="33" className="fil275" points="477.57,498.11 954.03,358.55 958.13,373.22 "/>
              <polygon id="34" className="fil275" points="477.57,498.11 957.31,370.29 961.05,385.05 "/>
              <polygon id="35" className="fil275" points="477.57,498.11 960.3,382.1 963.68,396.95 "/>
              <polygon id="36" className="fil275" points="477.57,498.11 963,393.98 966.01,408.91 "/>
              <polygon id="37" className="fil276" points="477.57,498.11 965.41,405.92 968.06,420.93 "/>
              <polygon id="38" className="fil276" points="477.57,498.11 967.53,417.93 969.8,432.99 "/>
              <polygon id="39" className="fil276" points="477.57,498.11 969.35,429.97 971.25,445.08 "/>
              <polygon id="40" className="fil276" points="477.57,498.11 970.87,442.06 972.41,457.21 "/>
              <polygon id="41" className="fil277" points="477.57,498.11 972.1,454.19 973.26,469.37 "/>
              <polygon id="42" className="fil277" points="477.57,498.11 973.03,466.33 973.81,481.54 "/>
              <polygon id="43" className="fil277" points="477.57,498.11 973.65,478.5 974.07,493.73 "/>
              <polygon id="44" className="fil277" points="477.57,498.11 973.99,490.68 974.03,505.91 "/>
              <polygon id="45" className="fil278" points="477.57,498.11 974.02,502.87 973.69,518.09 "/>
              <polygon id="46" className="fil278" points="477.57,498.11 973.75,515.05 973.05,530.26 "/>
              <polygon id="47" className="fil278" points="477.57,498.11 973.19,527.22 972.11,542.42 "/>
              <polygon id="48" className="fil278" points="477.57,498.11 972.33,539.37 970.87,554.54 "/>
              <polygon id="49" className="fil279" points="477.57,498.11 971.16,551.5 969.34,566.63 "/>
              <polygon id="50" className="fil279" points="477.57,498.11 969.7,563.6 967.51,578.68 "/>
              <polygon id="51" className="fil279" points="477.57,498.11 967.95,575.66 965.39,590.67 "/>
              <polygon id="52" className="fil279" points="477.57,498.11 965.9,587.67 962.97,602.62 "/>
              <polygon id="53" className="fil280" points="477.57,498.11 963.55,599.63 960.26,614.5 "/>
              <polygon id="54" className="fil280" points="477.57,498.11 960.91,611.53 957.25,626.31 "/>
              <polygon id="55" className="fil280" points="477.57,498.11 957.99,623.35 953.96,638.04 "/>
              <polygon id="56" className="fil280" points="477.57,498.11 954.77,635.11 950.38,649.69 "/>
              <polygon id="57" className="fil281" points="477.57,498.11 951.26,646.77 946.52,661.25 "/>
              <polygon id="58" className="fil281" points="477.57,498.11 947.47,658.35 942.38,672.71 "/>
              <polygon id="59" className="fil281" points="477.57,498.11 943.39,669.84 937.95,684.06 "/>
              <polygon id="60" className="fil281" points="477.57,498.11 939.04,681.22 933.25,695.31 "/>
              <polygon id="61" className="fil282" points="477.57,498.11 934.41,692.49 928.28,706.43 "/>
              <polygon id="62" className="fil282" points="477.57,498.11 929.5,703.64 923.02,717.43 "/>
              <polygon id="63" className="fil282" points="477.57,498.11 924.32,714.67 917.51,728.29 "/>
              <polygon id="64" className="fil282" points="477.57,498.11 918.87,725.57 911.73,739.02 "/>
              <polygon id="65" className="fil283" points="477.57,498.11 913.16,736.33 905.68,749.6 "/>
              <polygon id="66" className="fil283" points="477.57,498.11 907.18,746.95 899.38,760.03 "/>
              <polygon id="67" className="fil283" points="477.57,498.11 900.94,757.42 892.83,770.31 "/>
              <polygon id="68" className="fil283" points="477.57,498.11 894.45,767.73 886.02,780.41 "/>
              <polygon id="69" className="fil284" points="477.57,498.11 887.71,777.87 878.97,790.35 "/>
              <polygon id="70" className="fil284" points="477.57,498.11 880.72,787.86 871.68,800.12 "/>
              <polygon id="71" className="fil284" points="477.57,498.11 873.49,797.66 864.15,809.7 "/>
              <polygon id="72" className="fil284" points="477.57,498.11 866.02,807.29 856.38,819.09 "/>
              <polygon id="73" className="fil285" points="477.57,498.11 858.31,816.73 848.39,828.29 "/>
              <polygon id="74" className="fil285" points="477.57,498.11 850.38,825.98 840.18,837.29 "/>
              <polygon id="75" className="fil285" points="477.57,498.11 842.22,835.03 831.75,846.09 "/>
              <polygon id="76" className="fil286" points="477.57,498.11 833.84,843.87 823.1,854.67 "/>
              <polygon id="77" className="fil286" points="477.57,498.11 825.25,852.52 814.24,863.05 "/>
              <polygon id="78" className="fil286" points="477.57,498.11 816.44,860.94 805.19,871.2 "/>
              <polygon id="79" className="fil286" points="477.57,498.11 807.44,869.15 795.93,879.13 "/>
              <polygon id="80" className="fil287" points="477.57,498.11 798.23,877.13 786.49,886.82 "/>
              <polygon id="81" className="fil287" points="477.57,498.11 788.84,884.89 776.85,894.29 "/>
              <polygon id="82" className="fil287" points="477.57,498.11 779.25,892.41 767.04,901.51 "/>
              <polygon id="83" className="fil287" points="477.57,498.11 769.48,899.69 757.05,908.5 "/>
              <polygon id="84" className="fil288" points="477.57,498.11 759.54,906.74 746.9,915.23 "/>
              <polygon id="85" className="fil288" points="477.57,498.11 749.43,913.53 736.58,921.72 "/>
              <polygon id="86" className="fil288" points="477.57,498.11 739.15,920.08 726.11,927.94 "/>
              <polygon id="87" className="fil288" points="477.57,498.11 728.72,926.37 715.48,933.92 "/>
              <polygon id="88" className="fil289" points="477.57,498.11 718.13,932.41 704.71,939.62 "/>
              <polygon id="89" className="fil289" points="477.57,498.11 707.4,938.18 693.81,945.06 "/>
              <polygon id="90" className="fil289" points="477.57,498.11 696.53,943.69 682.78,950.23 "/>
              <polygon id="91" className="fil289" points="477.57,498.11 685.53,948.93 671.62,955.14 "/>
              <polygon id="92" className="fil290" points="477.57,498.11 674.4,953.89 660.34,959.76 "/>
              <polygon id="93" className="fil290" points="477.57,498.11 663.16,958.59 648.96,964.1 "/>
              <polygon id="94" className="fil290" points="477.57,498.11 651.8,963 637.47,968.17 "/>
              <polygon id="95" className="fil290" points="477.57,498.11 640.34,967.14 625.89,971.95 "/>
              <polygon id="96" className="fil291" points="477.57,498.11 628.78,970.99 614.22,975.45 "/>
              <polygon id="97" className="fil291" points="477.57,498.11 617.13,974.56 602.46,978.66 "/>
              <polygon id="98" className="fil291" points="477.57,498.11 605.39,977.84 590.63,981.58 "/>
              <polygon id="99" className="fil291" points="477.57,498.11 593.58,980.83 578.73,984.21 "/>
              <polygon id="100" className="fil292" points="477.57,498.11 581.7,983.54 566.77,986.55 "/>
              <polygon id="101" className="fil292" points="477.57,498.11 569.75,985.94 554.76,988.59 "/>
              <polygon id="102" className="fil292" points="477.57,498.11 557.76,988.06 542.7,990.34 "/>
              <polygon id="103" className="fil292" points="477.57,498.11 545.71,989.88 530.59,991.79 "/>
              <polygon id="104" className="fil293" points="477.57,498.11 533.62,991.4 518.46,992.94 "/>
              <polygon id="105" className="fil293" points="477.57,498.11 521.5,992.63 506.31,993.79 "/>
              <polygon id="106" className="fil293" points="477.57,498.11 509.34,993.56 494.13,994.35 "/>
              <polygon id="107" className="fil293" points="477.57,498.11 497.18,994.19 481.95,994.6 "/>
              <polygon id="108" className="fil294" points="477.57,498.11 485,994.52 469.77,994.56 "/>
              <polygon id="109" className="fil294" points="477.57,498.11 472.81,994.55 457.58,994.22 "/>
              <polygon id="110" className="fil294" points="477.57,498.11 460.63,994.29 445.41,993.58 "/>
              <polygon id="111" className="fil294" points="477.57,498.11 448.46,993.72 433.27,992.64 "/>
              <polygon id="112" className="fil295" points="477.57,498.11 436.3,992.86 421.14,991.4 "/>
              <polygon id="113" className="fil295" points="477.57,498.11 424.17,991.7 409.05,989.87 "/>
              <polygon id="114" className="fil295" points="477.57,498.11 412.08,990.24 397.01,988.04 "/>
              <polygon id="115" className="fil295" points="477.57,498.11 400.02,988.48 385,985.92 "/>
              <polygon id="116" className="fil296" points="477.57,498.11 388.01,986.43 373.06,983.5 "/>
              <polygon id="117" className="fil296" points="477.57,498.11 376.05,984.08 361.18,980.79 "/>
              <polygon id="118" className="fil296" points="477.57,498.11 364.16,981.45 349.37,977.79 "/>
              <polygon id="119" className="fil296" points="477.57,498.11 352.33,978.52 337.64,974.5 "/>
              <polygon id="120" className="fil297" points="477.57,498.11 340.58,975.3 325.99,970.92 "/>
              <polygon id="121" className="fil297" points="477.57,498.11 328.91,971.79 314.43,967.06 "/>
              <polygon id="122" className="fil297" points="477.57,498.11 317.32,968.01 302.97,962.91 "/>
              <polygon id="123" className="fil297" points="477.57,498.11 305.84,963.93 291.62,958.48 "/>
              <polygon id="124" className="fil297" points="477.57,498.11 294.46,959.57 280.38,953.78 "/>
              <polygon id="125" className="fil297" points="477.57,498.11 283.19,954.94 269.25,948.81 "/>
              <polygon id="126" className="fil297" points="477.57,498.11 272.04,950.04 258.26,943.56 "/>
              <polygon id="127" className="fil297" points="477.57,498.11 261.01,944.85 247.39,938.04 "/>
              <polygon id="128" className="fil297" points="477.57,498.11 250.11,939.41 236.66,932.26 "/>
              <polygon id="129" className="fil297" points="477.57,498.11 239.35,933.69 226.08,926.22 "/>
              <polygon id="130" className="fil297" points="477.57,498.11 228.73,927.71 215.65,919.92 "/>
              <polygon id="131" className="fil298" points="477.57,498.11 218.27,921.48 205.38,913.36 "/>
              <polygon id="132" className="fil298" points="477.57,498.11 207.95,914.98 195.26,906.56 "/>
              <polygon id="133" className="fil298" points="477.57,498.11 197.8,908.24 185.33,899.5 "/>
              <polygon id="134" className="fil298" points="477.57,498.11 187.82,901.26 175.56,892.21 "/>
              <polygon id="135" className="fil298" points="477.57,498.11 178.02,894.02 165.98,884.68 "/>
              <polygon id="136" className="fil298" points="477.57,498.11 168.39,886.55 156.59,876.92 "/>
              <polygon id="137" className="fil298" points="477.57,498.11 158.95,878.84 147.39,868.93 "/>
              <polygon id="138" className="fil298" points="477.57,498.11 149.7,870.91 138.39,860.71 "/>
              <polygon id="139" className="fil298" points="477.57,498.11 140.65,862.75 129.59,852.28 "/>
              <polygon id="140" className="fil298" points="477.57,498.11 131.8,854.38 121,843.63 "/>
              <polygon id="141" className="fil298" points="477.57,498.11 123.17,845.78 112.63,834.78 "/>
              <polygon id="142" className="fil298" points="477.57,498.11 114.74,836.98 104.48,825.72 "/>
              <polygon id="143" className="fil298" points="477.57,498.11 106.53,827.97 96.55,816.46 "/>
              <polygon id="144" className="fil298" points="477.57,498.11 98.55,818.77 88.85,807.02 "/>
              <polygon id="145" className="fil298" points="477.57,498.11 90.8,809.37 81.39,797.39 "/>
              <polygon id="146" className="fil298" points="477.57,498.11 83.27,799.78 74.17,787.58 "/>
              <polygon id="147" className="fil298" points="477.57,498.11 75.99,790.01 67.18,777.59 "/>
              <polygon id="148" className="fil298" points="477.57,498.11 68.95,780.07 60.45,767.43 "/>
              <polygon id="149" className="fil298" points="477.57,498.11 62.15,769.96 53.97,757.11 "/>
              <polygon id="150" className="fil299" points="477.57,498.11 55.6,759.68 47.73,746.64 "/>
              <polygon id="151" className="fil299" points="477.57,498.11 49.31,749.25 41.77,736.02 "/>
              <polygon id="152" className="fil299" points="477.57,498.11 43.28,738.66 36.06,725.25 "/>
              <polygon id="153" className="fil299" points="477.57,498.11 37.5,727.93 30.62,714.34 "/>
              <polygon id="154" className="fil299" points="477.57,498.11 31.99,717.06 25.44,703.31 "/>
              <polygon id="155" className="fil299" points="477.57,498.11 26.76,706.06 20.55,692.15 "/>
              <polygon id="156" className="fil299" points="477.57,498.11 21.79,694.94 15.92,680.88 "/>
              <polygon id="157" className="fil299" points="477.57,498.11 17.09,683.69 11.57,669.49 "/>
              <polygon id="158" className="fil299" points="477.57,498.11 12.68,672.33 7.51,658.01 "/>
              <polygon id="159" className="fil299" points="477.57,498.11 8.54,660.87 3.73,646.42 "/>
              <polygon id="160" className="fil299" points="477.57,498.11 4.69,649.31 0.23,634.75 "/>
              <polygon id="161" className="fil299" points="477.57,498.11 1.12,637.66 -2.98,622.99 "/>
              <polygon id="162" className="fil299" points="477.57,498.11 -2.16,625.93 -5.9,611.16 "/>
              <polygon id="163" className="fil299" points="477.57,498.11 -5.15,614.11 -8.53,599.26 "/>
              <polygon id="164" className="fil299" points="477.57,498.11 -7.85,602.23 -10.87,587.3 "/>
              <polygon id="165" className="fil299" points="477.57,498.11 -10.26,590.29 -12.91,575.29 "/>
              <polygon id="166" className="fil299" points="477.57,498.11 -12.38,578.29 -14.65,563.23 "/>
              <polygon id="167" className="fil299" points="477.57,498.11 -14.2,566.24 -16.1,551.13 "/>
              <polygon id="168" className="fil299" points="477.57,498.11 -15.72,554.15 -17.26,539 "/>
              <polygon id="169" className="fil300" points="477.57,498.11 -16.95,542.03 -18.11,526.84 "/>
              <polygon id="170" className="fil300" points="477.57,498.11 -17.88,529.88 -18.67,514.67 "/>
              <polygon id="171" className="fil300" points="477.57,498.11 -18.51,517.71 -18.92,502.48 "/>
              <polygon id="172" className="fil300" points="477.57,498.11 -18.84,505.53 -18.88,490.3 "/>
              <polygon id="173" className="fil300" points="477.57,498.11 -18.87,493.35 -18.54,478.12 "/>
              <polygon id="174" className="fil300" points="477.57,498.11 -18.61,481.16 -17.9,465.95 "/>
              <polygon id="175" className="fil300" points="477.57,498.11 -18.04,468.99 -16.96,453.8 "/>
              <polygon id="176" className="fil300" points="477.57,498.11 -17.18,456.84 -15.73,441.68 "/>
              <polygon id="177" className="fil300" points="477.57,498.11 -16.02,444.71 -14.19,429.59 "/>
              <polygon id="178" className="fil300" points="477.57,498.11 -14.56,432.61 -12.36,417.54 "/>
              <polygon id="179" className="fil300" points="477.57,498.11 -12.8,420.55 -10.24,405.54 "/>
              <polygon id="180" className="fil300" points="477.57,498.11 -10.75,408.54 -7.82,393.6 "/>
              <polygon id="181" className="fil300" points="477.57,498.11 -8.41,396.59 -5.11,381.72 "/>
              <polygon id="182" className="fil300" points="477.57,498.11 -5.77,384.69 -2.11,369.91 "/>
              <polygon id="183" className="fil300" points="477.57,498.11 -2.84,372.86 1.18,358.17 "/>
              <polygon id="184" className="fil300" points="477.57,498.11 0.38,361.11 4.76,346.52 "/>
              <polygon id="185" className="fil300" points="477.57,498.11 3.88,349.44 8.62,334.97 "/>
              <polygon id="186" className="fil300" points="477.57,498.11 7.68,337.86 12.77,323.51 "/>
              <polygon id="187" className="fil300" points="477.57,498.11 11.75,326.38 17.19,312.15 "/>
              <polygon id="188" className="fil301" points="477.57,498.11 16.1,314.99 21.9,300.91 "/>
              <polygon id="189" className="fil301" points="477.57,498.11 20.74,303.73 26.87,289.78 "/>
              <polygon id="190" className="fil301" points="477.57,498.11 25.65,292.58 32.12,278.79 "/>
              <polygon id="191" className="fil301" points="477.57,498.11 30.83,281.55 37.64,267.92 "/>
              <polygon id="192" className="fil301" points="477.57,498.11 36.27,270.65 43.42,257.19 "/>
              <polygon id="193" className="fil301" points="477.57,498.11 41.99,259.89 49.46,246.61 "/>
              <polygon id="194" className="fil301" points="477.57,498.11 47.97,249.27 55.76,236.18 "/>
              <polygon id="195" className="fil301" points="477.57,498.11 54.2,238.8 62.32,225.91 "/>
              <polygon id="196" className="fil301" points="477.57,498.11 60.69,228.49 69.12,215.8 "/>
              <polygon id="197" className="fil301" points="477.57,498.11 67.44,218.34 76.17,205.86 "/>
              <polygon id="198" className="fil301" points="477.57,498.11 74.43,208.35 83.47,196.1 "/>
              <polygon id="199" className="fil301" points="477.57,498.11 81.66,198.55 91,186.52 "/>
              <polygon id="200" className="fil301" points="477.57,498.11 89.13,188.92 98.76,177.12 "/>
              <polygon id="201" className="fil301" points="477.57,498.11 96.83,179.48 106.75,167.93 "/>
              <polygon id="202" className="fil301" points="477.57,498.11 104.77,170.23 114.97,158.92 "/>
              <polygon id="203" className="fil301" points="477.57,498.11 112.93,161.19 123.4,150.12 "/>
              <polygon id="204" className="fil301" points="477.57,498.11 121.31,152.34 132.05,141.54 "/>
              <polygon id="205" className="fil301" points="477.57,498.11 129.9,143.7 140.9,133.17 "/>
              <polygon id="206" className="fil301" points="477.57,498.11 138.7,135.27 149.96,125.01 "/>
              <polygon id="207" className="fil259" points="477.57,498.11 147.71,127.07 159.21,117.09 "/>
              <polygon id="208" className="fil259" points="477.57,498.11 156.91,119.08 168.66,109.39 "/>
              <polygon id="209" className="fil259" points="477.57,498.11 166.31,111.33 178.29,101.93 "/>
              <polygon id="210" className="fil259" points="477.57,498.11 175.9,103.81 188.11,94.7 "/>
              <polygon id="211" className="fil259" points="477.57,498.11 185.66,96.52 198.09,87.72 "/>
              <polygon id="212" className="fil259" points="477.57,498.11 195.61,89.48 208.25,80.98 "/>
              <polygon id="213" className="fil259" points="477.57,498.11 205.72,82.68 218.57,74.5 "/>
              <polygon id="214" className="fil259" points="477.57,498.11 216,76.13 229.04,68.27 "/>
              <polygon id="215" className="fil259" points="477.57,498.11 226.43,69.84 239.67,62.3 "/>
              <polygon id="216" className="fil259" points="477.57,498.11 237.02,63.81 250.43,56.59 "/>
              <polygon id="217" className="fil259" points="477.57,498.11 247.75,58.03 261.34,51.15 "/>
              <polygon id="218" className="fil259" points="477.57,498.11 258.62,52.53 272.37,45.98 "/>
              <polygon id="219" className="fil259" points="477.57,498.11 269.62,47.29 283.53,41.08 "/>
              <polygon id="220" className="fil259" points="477.57,498.11 280.75,42.32 294.8,36.45 "/>
              <polygon id="221" className="fil259" points="477.57,498.11 291.99,37.63 306.19,32.11 "/>
              <polygon id="222" className="fil259" points="477.57,498.11 303.34,33.21 317.67,28.04 "/>
              <polygon id="223" className="fil259" points="477.57,498.11 314.81,29.08 329.26,24.26 "/>
              <polygon id="224" className="fil259" points="477.57,498.11 326.37,25.22 340.93,20.76 "/>
              <polygon id="225" className="fil259" points="477.57,498.11 338.02,21.65 352.69,17.55 "/>
              <polygon id="226" className="fil262" points="477.57,498.11 349.75,18.37 364.52,14.63 "/>
              <polygon id="227" className="fil262" points="477.57,498.11 361.56,15.38 376.42,12 "/>
              <polygon id="228" className="fil262" points="477.57,498.11 373.45,12.68 388.38,9.67 "/>
              <polygon id="229" className="fil262" points="477.57,498.11 385.39,10.27 400.39,7.63 "/>
              <polygon id="230" className="fil262" points="477.57,498.11 397.39,8.15 412.45,5.88 "/>
              <polygon id="231" className="fil262" points="477.57,498.11 409.44,6.34 424.55,4.43 "/>
              <polygon id="232" className="fil262" points="477.57,498.11 421.53,4.81 436.68,3.28 "/>
              <polygon id="233" className="fil262" points="477.57,498.11 433.65,3.58 448.84,2.42 "/>
              <polygon id="234" className="fil262" points="477.57,498.11 445.8,2.65 461.01,1.86 "/>
              <polygon id="235" className="fil262" points="477.57,498.11 457.97,2.02 473.19,1.61 "/>
              <polygon id="236" className="fil262" points="477.57,498.11 470.15,1.69 485.38,1.65 "/>
              <polygon id="237" className="fil262" points="477.57,498.11 482.33,1.66 497.56,1.99 "/>
              <polygon id="238" className="fil262" points="477.57,498.11 494.51,1.93 509.73,2.63 "/>
              <polygon id="239" className="fil262" points="477.57,498.11 506.69,2.49 521.88,3.57 "/>
              <polygon id="240" className="fil262" points="477.57,498.11 518.84,3.36 534.01,4.81 "/>
              <polygon id="241" className="fil262" points="477.57,498.11 530.97,4.52 546.1,6.34 "/>
              <polygon id="242" className="fil262" points="477.57,498.11 543.07,5.98 558.14,8.17 "/>
              <polygon id="243" className="fil262" points="477.57,498.11 555.13,7.73 570.14,10.3 "/>
              <polygon id="244" className="fil262" points="477.57,498.11 567.14,9.78 582.08,12.71 "/>
              <polygon id="245" className="fil265" points="477.57,498.11 579.1,12.13 593.96,15.43 "/>
              <polygon id="246" className="fil265" points="477.57,498.11 590.99,14.76 605.78,18.43 "/>
              <polygon id="247" className="fil265" points="477.57,498.11 602.82,17.69 617.51,21.72 "/>
              <polygon id="248" className="fil265" points="477.57,498.11 614.57,20.91 629.16,25.29 "/>
              <polygon id="249" className="fil265" points="477.57,498.11 626.24,24.42 640.72,29.16 "/>
              <polygon id="250" className="fil265" points="477.57,498.11 637.82,28.21 652.18,33.3 "/>
              <polygon id="251" className="fil265" points="477.57,498.11 649.3,32.28 663.53,37.73 "/>
              <polygon id="252" className="fil265" points="477.57,498.11 660.68,36.64 674.77,42.43 "/>
              <polygon id="253" className="fil265" points="477.57,498.11 671.95,41.27 685.89,47.41 "/>
              <polygon id="254" className="fil265" points="477.57,498.11 683.11,46.18 696.89,52.65 "/>
              <polygon id="255" className="fil266" points="477.57,498.11 694.14,51.36 705.04,56.81 "/>
            </g>
          </g>
          <circle className="fil302" cx="477.57" cy="498.11" r="253.6"/>
          <path className="fil303" d="M680.91 498.19c0,56.01 -19.83,103.8 -59.58,143.39 -39.67,39.59 -87.64,59.39 -143.75,59.39 -56.11,0 -104.08,-19.8 -143.75,-59.39 -39.76,-39.59 -59.58,-87.39 -59.58,-143.39 0,-56.01 19.83,-103.89 59.58,-143.48 39.67,-39.68 87.64,-59.48 143.75,-59.48 56.11,0 104.08,19.8 143.75,59.48 39.75,39.59 59.58,87.48 59.58,143.48zm-278.11 108.6c0,3.7 -1.31,6.84 -3.96,9.45 -2.64,2.62 -5.78,3.93 -9.45,3.93 -3.73,0 -6.9,-1.31 -9.52,-3.93 -2.59,-2.61 -3.89,-5.76 -3.89,-9.45 0,-3.7 1.31,-6.86 3.89,-9.48 2.62,-2.59 5.78,-3.9 9.52,-3.9 3.68,0 6.81,1.31 9.45,3.9 2.65,2.62 3.96,5.79 3.96,9.48zm244.89 -108.6c0,-46.95 -16.62,-86.97 -49.84,-120.22 -24.9,-24.92 -53.73,-40.53 -86.49,-46.74 0.69,0.61 1.36,1.24 2.03,1.9 10.04,9.95 15.02,22 15.02,36.04 0,14.05 -4.97,25.99 -15.02,35.94 -10.04,9.95 -21.95,14.93 -35.94,14.93 -14.19,0 -26.2,-4.98 -36.15,-14.93 -9.84,-9.95 -14.81,-21.89 -14.81,-35.94 0,-14.04 4.97,-26.09 14.81,-36.04 0.62,-0.61 1.24,-1.2 1.88,-1.78 -32.51,6.29 -61.17,21.86 -86.05,46.63 -33.22,33.25 -49.92,73.26 -49.92,120.22 0,46.95 16.7,86.97 49.92,120.04 33.31,33.16 73.4,49.75 120.44,49.75 47.04,0 87.13,-16.58 120.27,-49.75 33.23,-33.08 49.84,-73.09 49.84,-120.04zm-263.91 38.06c0,7.39 -2.61,13.67 -7.9,18.91 -5.28,5.24 -11.56,7.86 -18.92,7.86 -7.47,0 -13.8,-2.61 -19.02,-7.86 -5.18,-5.23 -7.79,-11.52 -7.79,-18.91 0,-7.4 2.61,-13.73 7.79,-18.97 5.23,-5.18 11.56,-7.79 19.02,-7.79 7.35,0 13.63,2.61 18.92,7.79 5.29,5.24 7.9,11.58 7.9,18.97zm30.45 -97.23c0,10.35 -3.66,19.15 -11.06,26.47 -7.4,7.34 -16.18,11 -26.48,11 -10.46,0 -19.31,-3.67 -26.64,-11 -7.25,-7.32 -10.91,-16.12 -10.91,-26.47 0,-10.35 3.66,-19.23 10.91,-26.56 7.32,-7.25 16.18,-10.92 26.64,-10.92 10.3,0 19.08,3.66 26.48,10.92 7.4,7.33 11.06,16.21 11.06,26.56zm102.41 199.08l-78.38 0 0 -205.68 78.35 0 0.03 205.68z"/>
        </g>
      </g>
    </svg>
  );
};
