import { axiosAPI } from '../axiosAPI'
import { IProntuarioReligiosidadeForm, IProntuarioReligiosidadeListagem } from './types'

export const prontuarioReligiosidadeCreateAPI = async (
  idProntuario: number,
  values: IProntuarioReligiosidadeForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioreligiosidade',
      {
        id_prontuario: idProntuario,
        religiao: values.religiao,
        sentimento_fe: values.sentimentoFe,
        necessidade_suporte: values.necessidadeSuporte,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioReligiosidadeForm, IProntuarioReligiosidadeListagem }
