import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  IProntuarioTerapiaEndovenosaForm,
  prontuarioTerapiaEndovenosaCreateAPI,
} from '../../../../../api/prontuario/prontuarioTerapiaEndovenosaAPI'
import { getAllToSelectDispositivoAPI } from '../../../../../api/cadastro/dispositivosAPI'
import { DateTime, Switch } from '../../../../../components'
import { InputStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { verificarAvaliadoNoDia } from '../../util'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { SelectAsync } from '../../../../../components/select-async'
import { AtbQualQtdDias } from './components'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'

interface IProntuarioTerapiaEndovenosaProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioTerapiaEndovenosa = (props: IProntuarioTerapiaEndovenosaProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.TERAPIA_ENDOVENOSA)

  const initialValues: IProntuarioTerapiaEndovenosaForm = {
    antibioticos: prontuario?.prontuarioTerapiaEndovenosa[0]?.antibioticos || null,
    descricao: prontuario?.prontuarioTerapiaEndovenosa[0]?.descricao || null,
    local: prontuario?.prontuarioTerapiaEndovenosa[0]?.local || null,
    data: prontuario?.prontuarioTerapiaEndovenosa[0]?.data ? new Date(prontuario?.prontuarioTerapiaEndovenosa[0]?.data) : null,
    bic: prontuario?.prontuarioTerapiaEndovenosa[0]?.bic || null,
    hidratacao: prontuario?.prontuarioTerapiaEndovenosa[0]?.hidratacao || null,
    atb: prontuario?.prontuarioTerapiaEndovenosa[0]?.atb || null,
    idDispositivo: prontuario?.prontuarioTerapiaEndovenosa[0]?.idDispositivo || -1,
    terapiaEndovenosaATB: prontuario?.prontuarioTerapiaEndovenosa[0]?.terapiaEndovenosaATB || [],
    avaliado:  new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioTerapiaEndovenosa[0]?.avaliado),
    alterado: prontuario?.prontuarioTerapiaEndovenosa[0]?.alterado ||  false,
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioTerapiaEndovenosaForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioTerapiaEndovenosaCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Terapia endovenosa incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('controleInfusao'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioTerapiaEndovenosa[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioTerapiaEndovenosa[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('terapiaEndovenosa')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Terapia endovenosa</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'terapiaEndovenosa') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'terapiaEndovenosa') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                {/* <Col md={12}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='antibioticos'>
                      Antibióticos (Qual(is) e quantos dias)
                    </label>

                    <TextAreaStyled
                      className='form-input form-control w-100'
                      id='antibioticos'
                      name='antibioticos'
                      value={values.antibioticos || ''}
                      rows={3}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col> */}

                <Col md={3}>
                  <SelectAsync
                    id='idDispositivo'
                    label='Dispositivo'
                    emptyLabel='Selecione...'
                    value={values.idDispositivo || -1}
                    onChange={handleChange}
                    api={getAllToSelectDispositivoAPI}
                  />
                </Col>

                <Col md={3}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='descricao'>
                      Descrição
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='descricao'
                      name='descricao'
                      value={values.descricao || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div style={{marginBottom: '16px'}}>
                    <label className="form-check-label form-label" htmlFor='local'>
                      Local
                    </label>
                    
                    <InputStyled
                      type='text'
                      className='form-input form-control w-100'
                      id='local'
                      name='local'
                      value={values.local || ''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <DateTime
                    label='Data inserção'
                    id='data'
                    maxDate={new Date()}
                    selected={values.data}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={2}>
                  <Switch
                    label='BIC'
                    name='bic'
                    checked={values.bic || false}
                    onChange={(value) => setFieldValue('bic', value, false)}
                  />
                </Col>

                <Col md={2}>
                  <Switch
                    label='Hidratação'
                    name='hidratacao'
                    checked={values.hidratacao || false}
                    onChange={(value) => setFieldValue('hidratacao', value, false)}
                  />
                </Col>

                <Col md={1}>
                  <Switch
                    label='ATB'
                    name='atb'
                    checked={values.atb || false}
                    onChange={(value) => setFieldValue('atb', value, false)}
                  />
                </Col>

                <Col md={7}>
                  <AtbQualQtdDias />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />
                    { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                    ) : (
                      <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <FaSave size={20} style={{ marginRight: '8px' }} />
                          Salvar
                        </div>
                      </Button>
                    )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioTerapiaEndovenosa }
export default ProntuarioTerapiaEndovenosa
