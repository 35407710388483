import React, { useContext } from 'react'
import { Alert } from 'react-bootstrap';

import { IndicadoresContext } from '../context';
import { parseGrupoPlanoCuidado } from '../../../pacientes/prontuario/components/plano_cuidados/util';
import { TableContainer, TableStyled } from '../../../../components/styles';

const IndicadoresListagem = () => {
  const { indicadores, selecionado, setSelecionado } = useContext(IndicadoresContext);

  return (
    <>
      {indicadores && indicadores.length === 0 && (
        <div style={{ marginTop: '32px', textAlign: 'center', color: '#000' }}>
          <Alert variant='secondary'>
            <div>Inclua o primeiro Indicador.</div>
            <div>Basta inserir os dados nos campos acima e clicar em Adicionar!</div>
          </Alert>
        </div>
      )}
      {indicadores && indicadores.length > 0 && (
        <TableContainer>
          <TableStyled semMargemVertical>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Grupo</th>
                <th>Resultado</th>
                <th>Valor esperado</th>
                <th style={{ width: 100 }}>Ativo</th>
              </tr>
            </thead>

            <tbody>
              {indicadores && indicadores.length > 0 && indicadores.map((indicador => (
                <tr
                  key={indicador.id}
                  style={{ border: selecionado?.id === indicador.id ? '2px solid #000' : '', cursor: 'pointer' }}
                  onClick={() => setSelecionado(indicador)}
                >
                  <td>{indicador.nome}</td>
                  <td>{!!indicador.grupo && indicador.grupo !== -1 ? parseGrupoPlanoCuidado(indicador.grupo) : ''}</td>
                  <td>{indicador.resultado?.descricao}</td>
                  <td>{indicador.valorEsperado}</td>
                  <td>{indicador.ativo ? 'Sim' : 'Não'}</td>
                </tr>
              )))}
            </tbody>
          </TableStyled>
        </TableContainer>
      )}
    </>
  )
}

export { IndicadoresListagem }
export default IndicadoresListagem
