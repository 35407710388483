import { axiosAPI } from '../axiosAPI'
import { IProntuarioTerapiaEndovenosaForm, IProntuarioTerapiaEndovenosaListagem } from './types'

export const prontuarioTerapiaEndovenosaCreateAPI = async (
  idProntuario: number,
  values: IProntuarioTerapiaEndovenosaForm,
): Promise<void> => {
  await axiosAPI.post(
      'prontuarioterapiaendovenosa',
      {
        id_prontuario: idProntuario,
        antibioticos: values.antibioticos,
        descricao: values.descricao,
        local: values.local,
        data: values.data,
        bic: values.bic,
        hidratacao: values.hidratacao,
        atb: values.atb,
        lista: values.terapiaEndovenosaATB,
        id_dispositivo: values.idDispositivo > 0 ? values.idDispositivo : null,
        avaliado: values.avaliado,
        alterado: values.alterado,
      },
  )
}

export type { IProntuarioTerapiaEndovenosaForm, IProntuarioTerapiaEndovenosaListagem }
