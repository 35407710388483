import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HiArrowRightOnRectangle, HiDocumentDuplicate } from 'react-icons/hi2'
import { Page } from '../../../components/page'
import {
  ProntuarioCuidadoCorporal,
  ProntuarioDadosAdmissao,
  ProntuarioEliminacoesFisiologicas,
  ProntuarioExameFisico,
  ProntuarioFuncaoCardiovascular,
  ProntuarioPercepcaoSentidos,
  ProntuarioReligiosidade,
  ProntuarioSegFisicaEmocional,
  ProntuarioSexualidade,
  ProntuarioSonoRepouso,
  ProntuarioTerapiaEndovenosa,
  ProntuarioFuncaoNeuroAvaOcular,
  ProntuarioIntegridadeFisica,
  ProntuarioFuncaoRespiratoria,
  ProntuarioComunicacaoEducacao,
  ProntuarioNutricao,
  ProntuarioControleInfusao,
  ProntuarioRegistroComplementar,
  ProntuarioRelatorio,
} from './components'
import { ProntuarioProvider } from './context'
import { PlanoCuidadoBotao } from './components/plano_cuidados/PlanoCuidadoBotao'
import PageHeaderProntuario from './PageHeaderProntuario'
import Button from '../../../components/Button'
import { PlanoCuidadosRelatorio } from './components/plano_cuidados/components/relatorio'

const Prontuario = () => {
  const [accordionOpen, setAccordionOpen] = useState<string[]>([])
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location && location.state && location.state.grupo) {
      setAccordionOpen(prev => {
        if (prev.includes(location.state.grupo)) {
          return prev.filter(i => i !== location.state.grupo)
        }
        return [ ...prev, location.state.grupo ]
      })
    }
  }, [location])

  return (
    <ProntuarioProvider>
      <Page>
        <PageHeaderProntuario />

        <Page.Body>
          <div style={{ marginBottom: '24px' }}>
            <ProntuarioDadosAdmissao
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('dadosAdmissao')) {
                  return prev.filter(i => i !== 'dadosAdmissao')
                }
                return [ ...prev, 'dadosAdmissao' ]
              })}
            />

            <ProntuarioExameFisico
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('exameFisico')) {
                  return prev.filter(i => i !== 'exameFisico')
                }
                return [ ...prev, 'exameFisico' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioPercepcaoSentidos
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('percepcaoSentidos')) {
                  return prev.filter(i => i !== 'percepcaoSentidos')
                }
                return [ ...prev, 'percepcaoSentidos' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioSonoRepouso
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('sonoRepouso')) {
                  return prev.filter(i => i !== 'sonoRepouso')
                }
                return [ ...prev, 'sonoRepouso' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioSegFisicaEmocional
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('segFisicaEmocional')) {
                  return prev.filter(i => i !== 'segFisicaEmocional')
                }
                return [ ...prev, 'segFisicaEmocional' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioFuncaoNeuroAvaOcular
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('funNeuroAvaOcular')) {
                  return prev.filter(i => i !== 'funNeuroAvaOcular')
                }
                return [ ...prev, 'funNeuroAvaOcular' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioIntegridadeFisica
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('integridadeFisica')) {
                  return prev.filter(i => i !== 'integridadeFisica')
                }
                return [ ...prev, 'integridadeFisica' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioFuncaoRespiratoria
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('funcaoRespiratoria')) {
                  return prev.filter(i => i !== 'funcaoRespiratoria')
                }
                return [ ...prev, 'funcaoRespiratoria' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioFuncaoCardiovascular
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('funcaoCardiovascular')) {
                  return prev.filter(i => i !== 'funcaoCardiovascular')
                }
                return [ ...prev, 'funcaoCardiovascular' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioNutricao
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('nutricao')) {
                  return prev.filter(i => i !== 'nutricao')
                }
                return [ ...prev, 'nutricao' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioEliminacoesFisiologicas
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('eliminacoesFisiologicas')) {
                  return prev.filter(i => i !== 'eliminacoesFisiologicas')
                }
                return [ ...prev, 'eliminacoesFisiologicas' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioCuidadoCorporal
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('cuidadoCorporal')) {
                  return prev.filter(i => i !== 'cuidadoCorporal')
                }
                return [ ...prev, 'cuidadoCorporal' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioSexualidade
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('sexualidade')) {
                  return prev.filter(i => i !== 'sexualidade')
                }
                return [ ...prev, 'sexualidade' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioComunicacaoEducacao
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('comunicacaoEducacao')) {
                  return prev.filter(i => i !== 'comunicacaoEducacao')
                }
                return [ ...prev, 'comunicacaoEducacao' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioReligiosidade
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('religiosidade')) {
                  return prev.filter(i => i !== 'religiosidade')
                }
                return [ ...prev, 'religiosidade' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioTerapiaEndovenosa
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('terapiaEndovenosa')) {
                  return prev.filter(i => i !== 'terapiaEndovenosa')
                }
                return [ ...prev, 'terapiaEndovenosa' ]
              })}
              abrirProximoGrupo={(grupo) => setAccordionOpen([grupo])}
            />

            <ProntuarioControleInfusao
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('controleInfusao')) {
                  return prev.filter(i => i !== 'controleInfusao')
                }
                return [ ...prev, 'controleInfusao' ]
              })}
            />

            <ProntuarioRegistroComplementar
              accordionOpen={accordionOpen}
              setAccordionOpen={() => setAccordionOpen(prev => {
                if (prev.includes('registroComplementar')) {
                  return prev.filter(i => i !== 'registroComplementar')
                }
                return [ ...prev, 'registroComplementar' ]
              })}
            />
          </div>
          
        </Page.Body>

        <Page.Footer>
          <div style={{ display: 'flex' }}>
            
          <Button
              variant='secondary'
              style={{ marginRight: '12px', display: 'flex', alignItems: 'center', backgroundColor: "#ff4500" }}
              onClick={() =>  alert("Em desenvolvimento")//navigate(`/resumo?props=20`)

               }>
              <HiDocumentDuplicate size={22} style={{ marginRight: '6px' }} />
                Protuario resumido
            </Button> 
            <PlanoCuidadoBotao />

            <Button
              variant='secondary'
              style={{ marginLeft: '12px', display: 'flex', alignItems: 'center' }}
              onClick={() => navigate(-1)}
            >
              <HiArrowRightOnRectangle size={22} style={{ marginRight: '6px' }} />
              Sair
            </Button>

            <ProntuarioRelatorio />
          </div>
        </Page.Footer>

      </Page>
    </ProntuarioProvider>
  )
}

export { Prontuario }
export default Prontuario
