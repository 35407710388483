import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export enum ProfissionalEnum {
  GESTOR = 'GESTOR',
  TECNICO = 'TECNICO',
  ENFERMEIRO = 'ENFERMEIRO'
}

interface IProfissionalAptidao {
  id: number,
  descricao: string,
}

export interface IProfissionalListagem {
  id: number,
  nome: string,
  ativo: boolean,
  senha: string,
  tipo: ProfissionalEnum,
  pontos: number,
  acessoUti: boolean,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  cpfCnpj?: string | null,
  usuarioAptidao: IProfissionalAptidao[],
  abriuProntuarioHoje: boolean,
  bateuPontoHoje: boolean,
}

export interface IProfissionalListagemBackend {
  id: number,
  nome: string,
  ativo: boolean,
  senha: string,
  tipo: ProfissionalEnum,
  pontos: number,
  acesso_uti: boolean,
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  cpf_cnpj?: string | null,
  UsuarioAptidao: IProfissionalAptidao[],
  abriuProntuarioHoje: boolean,
  bateuPontoHoje: boolean,
}

export interface IProfissionaisFiltros {
  nome: string
}

export const parseProfissionalBackToFront = (dadosBackend: IProfissionalListagemBackend): IProfissionalListagem => ({
  id: dadosBackend.id,
  nome: dadosBackend.nome,
  ativo: dadosBackend.ativo,
  senha: dadosBackend.senha,
  tipo: dadosBackend.tipo,
  pontos: dadosBackend.pontos,
  acessoUti: dadosBackend.acesso_uti,
  contato: dadosBackend.contato,
  email: dadosBackend.email,
  urlAvatar: dadosBackend.urlAvatar,
  cpfCnpj: dadosBackend.cpf_cnpj,
  usuarioAptidao: dadosBackend.UsuarioAptidao,
  abriuProntuarioHoje: dadosBackend.abriuProntuarioHoje,
  bateuPontoHoje: dadosBackend.bateuPontoHoje,
})

export const profissionalListarAPI = async (filtros: IProfissionaisFiltros): Promise<IProfissionalListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IProfissionalListagemBackend[]> = await axiosAPI.get(
      'users/list',
      { params: { id_empresa: user.idEmpresa, id_usuario: user.id, nome: filtros.nome } },
  )

  return response.data.map(dado => parseProfissionalBackToFront(dado))
}

export const getAllToSelectProfissionalAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IProfissionalListagem[]> = await axiosAPI.get(
      'users/list',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}

export const profissionalCreateAPI = async (
  nome: string,
  ativo: boolean,
  senha: string,
  tipo: ProfissionalEnum,
  pontos: number,
  acessoUti: boolean,
  aptidoes: string[],
  contato?: string | null,
  email?: string | null,
  urlAvatar?: string | null,
  cpfCnpj?: string | null,
): Promise<void> => {
  const user = getUserLocalStorage()
  await axiosAPI.post(
      'users',
      {
        id_empresa: user.idEmpresa,
        nome,
        ativo,
        senha,
        tipo,
        pontos,
        acesso_uti: acessoUti,
        contato,
        email,
        urlAvatar,
        cpfCnpj,
        aptidoes,
      },
  )
}

export const profissionalUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
    senha: string,
    tipo: ProfissionalEnum,
    pontos: number,
    acessoUti: boolean,
    aptidoes: string[],
    contato?: string | null,
    email?: string | null,
    urlAvatar?: string | null,
    cpfCnpj?: string | null,
): Promise<void> => {
  const user = getUserLocalStorage()

  await axiosAPI.put(
      'users/edit',
      {
        id,
        nome,
        ativo,
        tipo,
        pontos,
        acesso_uti: acessoUti,
        contato,
        email,
        urlAvatar,
        cpfCnpj,
        aptidoes,
        id_empresa: user.idEmpresa,
      },
  )
}
