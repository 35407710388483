import React from 'react'
import { useFormikContext } from 'formik'

import Button from '../../../components/Button'
import { IProfissionaisForm } from '../Profissionais'

interface IProfissionaisFormBotoesProps {
  setModalAberto: (novoValor: boolean) => void,
  modoEdicao?: boolean
}

const ProfissionaisFormBotoes = (props: IProfissionaisFormBotoesProps) => {
  const { handleSubmit } = useFormikContext<IProfissionaisForm>()

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Button
        variant='secondary'
        onClick={() => props.setModalAberto(false)}
      >
        Cancelar
      </Button>

      <Button
        variant='primary'
        onClick={() => handleSubmit()}
      >
        {props.modoEdicao ? 'Editar' : 'Adicionar'}
      </Button>
    </div>
  )
}

export { ProfissionaisFormBotoes }
export default ProfissionaisFormBotoes
