import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../../../../../components/Button'
import { PlanoCuidadosRelatorio } from './relatorio'
import { ProntuarioContext } from '../../../context'

type Props = {}

const PlanoCuidadoBotoesHeader = (props: Props) => {
  const navigate = useNavigate()
  const { prontuario } = useContext(ProntuarioContext)

  return (
    <div>
      <Button variant='secondary' onClick={() => navigate(-1)}>Voltar</Button>
      
      {prontuario?.idPaciente && (
        <PlanoCuidadosRelatorio idPaciente={prontuario.idPaciente} />
      )}
    </div>
  )
}

export { PlanoCuidadoBotoesHeader }
export default PlanoCuidadoBotoesHeader
