import { ListGroup } from 'react-bootstrap'
import styled from 'styled-components'

export const ListGroupHeadStyled = styled(ListGroup.Item) `
  text-align: center;
  background-color: #475F52;
  color: #fff;
  font-weight: 400;
`

export const ListGroupItemStyled = styled(ListGroup.Item) `
  color: #222222;
`

export const DivComponentsStyled = styled.div `
  margin-right: 16px;
  display: flex;
  align-items: center;
`

interface IDivProps {
  alterado: boolean;
}

export const DivAccordionStyled = styled.div <IDivProps> `
  width: 100%;
  background-color: ${(props: IDivProps) => props.alterado ? '#FD2424' : '#304439'};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  min-height: 28px;
  padding: 8px;
`

export const DivInternaStyled = styled.div `
  padding: 18px 34px;
  background-color: #EDE38E;
  /* max-height: 400px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {
    width: 8px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    background: #bebcbc;
    border-radius: 4px;
  } */
`