import React, { useContext } from 'react'
import { useFormikContext } from 'formik'
import Swal from 'sweetalert2'

import { lesaoDeleteAPI } from '../../../../api/cadastro/lesoesAPI'
import Button from '../../../../components/Button'
import { ILesoesForm } from '../Lesoes'
import { LesoesContext } from '../context'

const LesoesBotoes = () => {
  const { handleSubmit } = useFormikContext<ILesoesForm>()

  const { selecionado, loadLesoes, setCarregando, setSelecionado } = useContext(LesoesContext);

  const handleExcluir = (id: number) => {
    if (id < 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Favor selecionar alguma lesão',
      });

      return
    }

    setCarregando(true)

    lesaoDeleteAPI(id)
      .then(() => {
        setSelecionado(null)
        loadLesoes()

        Swal.fire({
          icon: 'success',
          title: 'Ok...',
          text: 'Lesão excluída com sucesso!',
          confirmButtonColor: '#1A8C01',
        })
      })
      .catch(err => {
        console.log(err)

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err.response.data.error,
        });
      })
      .finally(() => setCarregando(false))
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button
        variant='primary'
        onClick={() => handleSubmit()}
      >
        {selecionado ? 'Editar' : 'Adicionar'}
      </Button>

      {selecionado && (
        <Button
          variant='secondary'
          onClick={() => setSelecionado(null)}
        >Cancelar</Button>
      )}

      <Button
        variant='danger'
        onClick={() => {
          if (selecionado?.id) {
            handleExcluir(selecionado.id)
          }
        }}
      >
        Excluir
      </Button>
    </div>
  )
}

export { LesoesBotoes }
export default LesoesBotoes
