import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'

export interface IRespiracaoListagem {
  id: number,
  nome: string,
  ativo: boolean,
}

export const respiracaoListarAPI = async (): Promise<IRespiracaoListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IRespiracaoListagem[]> = await axiosAPI.get(
      'respiracao',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data
}

export const getAllToSelectRespiracaoAPI = async (): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IRespiracaoListagem[]> = await axiosAPI.get(
      'respiracao',
      { params: { id_empresa: user.idEmpresa } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {label: item.nome, value: item.id}
    })
  }

  return response.data
}


export const respiracaoCreateAPI = async (nome: string, ativo: boolean): Promise<IRespiracaoListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'respiracao',
      { nome, ativo, id_empresa: user.idEmpresa },
  )

  return response.data
}

export const respiracaoUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
): Promise<IRespiracaoListagem[]> => {
  const response = await axiosAPI.put(
      'respiracao/edit',
      {id, nome, ativo},
  )

  return response.data
}

export const respiracaoDeleteAPI = async (id: number): Promise<IRespiracaoListagem[]> => {
  const response = await axiosAPI.delete('respiracao/delete', { params: { id } })

  return response.data
}
