import React, { useState } from 'react'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import { FaCalculator } from 'react-icons/fa'

import Button from '../../../../../components/Button'
import EscalaCCPOTForm from './EscalaCCPOTForm'

export interface IEscalaCCPOTForm {
  expressaoFacial: number,
  tensaoMuscular: number,
  movimentoCorporal: number,
  adaptacaoVentilador: number,
  escalaCcpot: number,
}

interface IEscalaCCPOTProps {
  aoFinalizar: (dados: IEscalaCCPOTForm) => void,
  valoresIniciais: IEscalaCCPOTForm
}

export const calcularEscalaCCPOT = (
  expressaoFacial?: number,
  tensaoMuscular?: number,
  movimentoCorporal?: number,
  adaptacaoVentilador?: number
) => {
  let total: number = 0
  if (expressaoFacial) total += Number(expressaoFacial)
  if (tensaoMuscular) total += Number(tensaoMuscular)
  if (movimentoCorporal) total += Number(movimentoCorporal)
  if (adaptacaoVentilador) total += Number(adaptacaoVentilador)

  return total
}

const EscalaCCPOT = (props: IEscalaCCPOTProps) => {
  const [modalCCPOTAberto, setModalCCPOTAberto] = useState<boolean>(false)

  const initialValues: IEscalaCCPOTForm = {
    expressaoFacial: Number(props.valoresIniciais.expressaoFacial) || 0,
    tensaoMuscular: Number(props.valoresIniciais.tensaoMuscular) || 0,
    movimentoCorporal: Number(props.valoresIniciais.movimentoCorporal) || 0,
    adaptacaoVentilador: Number(props.valoresIniciais.adaptacaoVentilador) || 0,
    escalaCcpot: calcularEscalaCCPOT(
      Number(props.valoresIniciais.expressaoFacial) || 0,
      Number(props.valoresIniciais.tensaoMuscular) || 0,
      Number(props.valoresIniciais.movimentoCorporal) || 0,
      Number(props.valoresIniciais.adaptacaoVentilador) || 0
    )
  }

  const handleSubmit = (values: IEscalaCCPOTForm) => {
    props.aoFinalizar(values)
    setModalCCPOTAberto(false)
  }

  return (
    <div>
      <FaCalculator
        size={26}
        color='#304439'
        style={{ cursor: 'pointer' }}
        onClick={() => setModalCCPOTAberto(true)}
      />

      <Modal show={modalCCPOTAberto} onHide={() => setModalCCPOTAberto(false)} size='lg'>
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Escala Critical Care Pain Observation Tool
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ submitForm }) => (
            <>
              <Modal.Body>
                <EscalaCCPOTForm />
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => submitForm()}>Confirmar</Button>
                <Button variant='secondary' style={{ marginLeft: '12px' }} onClick={() => setModalCCPOTAberto(false)}>Cancelar</Button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export { EscalaCCPOT }
export default EscalaCCPOT
