import React, { FormEvent, useState } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { MdFormatListBulletedAdd } from 'react-icons/md'

import { getAllToSelectEdemaAPI } from '../../../../../../api/cadastro/edemasAPI'
import { IEdemaLocalDescList, IProntuarioFuncaoCardiovascularForm } from '../../../../../../api/prontuario/types'
import { InputStyled, TableStyled } from '../../../../../../components/styles'
import { SelectAsync } from '../../../../../../components/select-async'
import { HiOutlineTrash } from 'react-icons/hi'

const RowStyled = styled(Row)`
  padding: 10px 8px;
  margin: 0 0px;
  background-color: #222222;
  display: flex;
  border-radius: 8px;
`

const EdemasLocalDescricao = () => {
  const [edemaLocalDesc, setEdemaLocalDesc] = useState<IEdemaLocalDescList>({
    idEdema: -1,
    local: '',
    descricao: ''
  })

  const { values, setFieldValue } = useFormikContext<IProntuarioFuncaoCardiovascularForm>()

  const handleInputOnChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setEdemaLocalDesc(prev => ({
      ...prev,
      descricao: value
    }));
  };

  const handleInserirEdemaLocalDesc = () => {
    if (edemaLocalDesc.idEdema !== -1) {
      const novoValorEdemasLD: IEdemaLocalDescList[] = values.edemasLocalDesc
      novoValorEdemasLD.push(edemaLocalDesc)
  
      setFieldValue('edemasLocalDesc', novoValorEdemasLD, false)
      setEdemaLocalDesc({
        idEdema: -1,
        local: '',
        descricao: '',
      })
    }
  }

  const removerAptidao = (id: number) => {
    const novoValorAptidoes: IEdemaLocalDescList[] = values.edemasLocalDesc.filter(i => i.idEdema !== id)
    setFieldValue('edemasLocalDesc', novoValorAptidoes, false)
  }

  return (
    <div>
      <RowStyled>
        <Col md={5}>
          <SelectAsync
            id='idEdema'
            label='Edema local'
            emptyLabel='Selecione...'
            styleLabel={{ color: '#fff' }}
            value={edemaLocalDesc.idEdema || -1}
            onChange={(e) => {   
              const valor = e.currentTarget?.value
              const label = e.currentTarget?.selectedOptions[0].textContent
              setEdemaLocalDesc(prev => ({
                ...prev,
                idEdema: valor ? Number(valor) : -1,
                local: label || ''
              }))
            }}
            api={getAllToSelectEdemaAPI}
          />
        </Col>

        <Col md={6}>
          <div style={{ color: '#fff', display: 'flex', flexDirection: 'column' }}>
            <label className="form-check-label form-label" htmlFor='descricao'>
              Edema descrição
            </label>
            <InputStyled
              style={{ width: '100%' }}
              id='descricao'
              name='descricao'
              value={edemaLocalDesc.descricao}
              onChange={e => handleInputOnChange(e)}
            />
          </div>
        </Col>

        <Col md={1}>
          <MdFormatListBulletedAdd
            color='#fff'
            size={44}
            style={{ marginTop: '32px', cursor: 'pointer' }}
            onClick={() => handleInserirEdemaLocalDesc()}
          />
        </Col>
      </RowStyled>

      {values.edemasLocalDesc && values.edemasLocalDesc.length > 0 && (
        <TableStyled style={{ marginTop: '6px' }}>
          <tbody>
            {values.edemasLocalDesc.map((edema, index) => (
              <tr key={index}>
                <td>{edema.local}</td>
                <td>{edema.descricao}</td>
                <td style={{ width: '1px' }}>
                  <HiOutlineTrash
                    color='#FD2424'
                    size={22}
                    style={{ cursor: 'pointer' }}
                    onClick={() => removerAptidao(edema.idEdema)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      )}
    </div>
  )
}

export { EdemasLocalDescricao }
export default EdemasLocalDescricao
