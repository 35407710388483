import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Col, Row, Spinner } from 'react-bootstrap'
import { BsChevronCompactUp, BsChevronCompactDown } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Swal from 'sweetalert2'

import {
  PulsacaoPerifericaEnum,
  PulsoPerifericoEnum,
  IProntuarioFuncaoCardiovascularForm,
  prontuarioFuncaoCardiovascularCreateAPI,
} from '../../../../../api/prontuario/prontuarioFuncaoCardiovascularAPI'
import { Select, Switch } from '../../../../../components'
// import { InputStyled } from '../../../../../components/styles'
import Button from '../../../../../components/Button'
import { ProntuarioContext } from '../../context'
import { verificarAvaliadoNoDia } from '../../util'
import { DivAccordionStyled, DivInternaStyled } from '../styles'
import { InputStyled } from '../../../../../components/styles'
import { EdemasLocalDescricao } from './components'
import { GrupoProntuarioPlanoCuidadoEnum } from '../../../../../api/prontuario/types'
import FeedbackPlanoCuidado from '../plano_cuidados/components/FeedbackPlanoCuidado'

interface IProntuarioFuncaoCardiovascularProps {
  accordionOpen: string[],
  apenasAlterados?: boolean,
  setAccordionOpen: (d: string) => void,
  abrirProximoGrupo: (d: string) => void,
}

const ProntuarioFuncaoCardiovascular = (props: IProntuarioFuncaoCardiovascularProps) => {
  const [formKey, setFormKey] = useState<number>(0)
  const { loadProntuario, prontuario, carregando, setCarregando } = useContext(ProntuarioContext);
  const planoCuidado = prontuario?.prontuarioPlanoCuidado.find(plano => plano.grupo === GrupoProntuarioPlanoCuidadoEnum.FUNCAO_CARDIOVASCULAR)

  const initialValues: IProntuarioFuncaoCardiovascularForm = {
    pulsoPeriferico:  prontuario?.prontuarioFuncaoCardiovascular[0]?.pulsoPeriferico || PulsoPerifericoEnum.AUSENTE,
    pulsacaoPeriferica:  prontuario?.prontuarioFuncaoCardiovascular[0]?.pulsacaoPeriferica || PulsacaoPerifericaEnum.NAO_AVALIADO,
    temEdema:  prontuario?.prontuarioFuncaoCardiovascular[0]?.temEdema || false,
    auscutaCardiaca:  prontuario?.prontuarioFuncaoCardiovascular[0]?.auscutaCardiaca || null,
    avaliado:  new Date(),
    stAvaliado: verificarAvaliadoNoDia(prontuario?.prontuarioFuncaoCardiovascular[0]?.avaliado),
    alterado: prontuario?.prontuarioFuncaoCardiovascular[0]?.alterado || false,
    edemasLocalDesc: prontuario?.prontuarioFuncaoCardiovascular[0]?.edemaLocalDesc || []
  }

  useEffect(() => {
    setFormKey(prev => prev + 1)
  }, [prontuario])

  const handleSubmit = (values: IProntuarioFuncaoCardiovascularForm) => {
    if (prontuario?.id) {
      setCarregando(true)
      prontuarioFuncaoCardiovascularCreateAPI(prontuario.id, values)
        .then(() => {
          loadProntuario(prontuario.id)
          Swal.fire({
            icon: 'success',
            title: 'Ok...',
            text: 'Dados de Função cardiovascular incluídos com sucesso!',
            confirmButtonColor: '#1A8C01',
          }).then(() => props.abrirProximoGrupo('nutricao'))
        })
        .catch(err => {
          console.log(err)

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.response.data.error,
          });
        })
        .finally(() => setCarregando(false))
    }
  }

  return (
    <section style={{ marginBottom: '8px' }}>
      {(!props.apenasAlterados || prontuario?.prontuarioFuncaoCardiovascular[0]?.alterado) && (
        <DivAccordionStyled
          alterado={prontuario?.prontuarioFuncaoCardiovascular[0]?.alterado ? true : false}
          onClick={() => props.setAccordionOpen('funcaoCardiovascular')}
        >
          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '20% 60% 20%', }}>
            {planoCuidado ? (
              <FeedbackPlanoCuidado
                plano={{
                  feedbackLike: planoCuidado.feedbackLike,
                  feedbackDeslike: planoCuidado.feedbackDeslike,
                  feedbackOlho: planoCuidado.feedbackOlho,
                  feedbackLupa: planoCuidado.feedbackLupa,
                }}
              />
            ) : ( <div />)}

            <div style={{ textAlign: 'center'}}>Função cardiovascular</div>

            <div style={{ cursor: 'pointer', textAlign: 'right' }}>
              {props.accordionOpen.find(i => i === 'funcaoCardiovascular') ? (
                <BsChevronCompactUp size={30} style={{ marginRight: '12px' }} />
              ) : (
                <BsChevronCompactDown size={30} style={{ marginRight: '12px' }} />
              )}
            </div>
          </div>
        </DivAccordionStyled>
      )}

      <DivInternaStyled style={{ display: `${props.accordionOpen.find(i => i === 'funcaoCardiovascular') ? '' : 'none'}`}}>
        <Formik
          key={formKey}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, handleBlur, handleChange, setFieldValue, submitForm }) => (
            <>
              <Row>
                <Col md={4}>
                  <Row>
                    <Col md={12}>
                      <Select
                        label='Pulsos periféricos'
                        emptyLabel='Selecione...'
                        id='pulsoPeriferico'
                        value={values.pulsoPeriferico || ''}
                        options={[
                          { label: 'Fino', value: PulsoPerifericoEnum.FINO },
                          { label: 'Cheio', value: PulsoPerifericoEnum.CHEIO },
                          { label: 'Fraco', value: PulsoPerifericoEnum.FRACO },
                          { label: 'Ausente', value: PulsoPerifericoEnum.AUSENTE },
                        ]}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col md={12}>
                      <Select
                        label='Perfusão periférica'
                        emptyLabel='Selecione...'
                        id='pulsacaoPeriferica'
                        value={values.pulsacaoPeriferica || ''}
                        options={[
                          { label: 'Maior que 3S', value: PulsacaoPerifericaEnum.MAIOR_3S },
                          { label: 'Menor que 3S', value: PulsacaoPerifericaEnum.MENOR_3S },
                          { label: 'Não avaliado', value: PulsacaoPerifericaEnum.NAO_AVALIADO },
                        ]}
                        onChange={handleChange}
                      />
                    </Col>

                    <Col md={12}>
                      <div style={{marginBottom: '16px'}}>
                        <label className="form-check-label form-label" htmlFor='auscutaCardiaca'>
                          Ausculta cardíaca
                        </label>

                        <InputStyled
                          type='text'
                          className='form-input form-control w-100'
                          id='auscutaCardiaca'
                          name='auscutaCardiaca'
                          value={values.auscutaCardiaca || ''}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <Switch
                        label='Edema'
                        name='temEdema'
                        checked={values.temEdema || false}
                        onChange={(value) => setFieldValue('temEdema', value, false)}
                      />
                    </Col>

                    <Col md={12}>
                        <EdemasLocalDescricao />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Switch
                      label='Não avaliado'
                      name='stAvaliado'
                      checked={values.stAvaliado || false}
                      onChange={(value) => {
                        if (value) {
                          setFieldValue('avaliado', new Date(), false)
                        } else {
                          setFieldValue('avaliado', null, false)
                        }
                        setFieldValue('stAvaliado', value, false)
                      }}
                    />

                  { carregando ? (
                    <Spinner animation="border" style={{ marginTop: '27px'}} />
                  ) : (
                    <Button onClick={() => submitForm()} style={{ height: '28px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <FaSave size={20} style={{ marginRight: '8px' }} />
                        Salvar
                      </div>
                    </Button>
                  )}

                    <Switch
                      label='Alterado'
                      name='alterado'
                      checked={values.alterado || false}
                      onChange={(value) => setFieldValue('alterado', value, false)}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </DivInternaStyled>
    </section>
  )
}

export { ProntuarioFuncaoCardiovascular }
export default ProntuarioFuncaoCardiovascular
