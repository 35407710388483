import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { SelectAsync } from '../../../../../../components/select-async'
import { AspectoBordaLesaoEnum, AspectoLeitoLesaoEnum, AspectoLesaoEnum, HigieneLesaoLeitoLesaoEnum, IProntuarioIntegridadeFisicaForm, OdorLesaoEnum, QuantidadeLesaoEnum, SinaisFlogisticosLeitoLesaoEnum } from '../../../../../../api/prontuario/types'
import { getAllToSelectLesaoAPI } from '../../../../../../api/cadastro/lesoesAPI'
import { InputStyled } from '../../../../../../components/styles'
import { MdFormatListBulletedAdd } from 'react-icons/md'
import { Select } from '../../../../../../components'
import Button from '../../../../../../components/Button'
import FotosAvaliacao from './FotosAvaliacao'

interface IAvaliacaoLesaoProps {
  idTipoLesao: number,
  indice: number,
}

const AvaliacaoLesao = (props: IAvaliacaoLesaoProps) => {
  const [modalAberto, setModalAberto] = useState<boolean>(false)
  const { values, handleChange, initialValues, setFieldValue } = useFormikContext<IProntuarioIntegridadeFisicaForm>()

  const fecharModal = () => {
    setFieldValue(`lesoes[${props.indice}].avaliacao.aspecto`, initialValues.lesoes[props.indice].avaliacao.aspecto, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.aspectoBorda`, initialValues.lesoes[props.indice].avaliacao.aspectoBorda, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.aspectoLeito`, initialValues.lesoes[props.indice].avaliacao.aspectoLeito, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.aspectoOutro`, initialValues.lesoes[props.indice].avaliacao.aspectoOutro, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.avaliacao`, initialValues.lesoes[props.indice].avaliacao.avaliacao, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.coberturaPrimaria`, initialValues.lesoes[props.indice].avaliacao.coberturaPrimaria, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.coberturaSecundaria`, initialValues.lesoes[props.indice].avaliacao.coberturaSecundaria, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.higieneLesao`, initialValues.lesoes[props.indice].avaliacao.higieneLesao, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.higieneLesaoOutro`, initialValues.lesoes[props.indice].avaliacao.higieneLesaoOutro, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.odor`, initialValues.lesoes[props.indice].avaliacao.odor, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.quantidade`, initialValues.lesoes[props.indice].avaliacao.quantidade, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.sinaisFlogisticos`, initialValues.lesoes[props.indice].avaliacao.sinaisFlogisticos, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.tamanhoAproximado`, initialValues.lesoes[props.indice].avaliacao.tamanhoAproximado, false)
    setFieldValue(`lesoes[${props.indice}].avaliacao.fotos`, initialValues.lesoes[props.indice].avaliacao.fotos, false)
    setModalAberto(false)
  }

  return (
    <div>
      <MdFormatListBulletedAdd
        size={26}
        style={{ cursor: 'pointer' }}
        onClick={() => setModalAberto(true)}
      />

      <Modal
        show={modalAberto}
        onHide={() => fecharModal()}
        size='lg'
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Avaliação da lesão
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ color: '#304439' }}>
          <Row>
            <Col md={8}>
              <SelectAsync
                id='idTipoLesao'
                label='Tipo de lesão selecionada'
                emptyLabel='Selecione...'
                value={props.idTipoLesao || -1}
                onChange={() => false}
                api={getAllToSelectLesaoAPI}
                disabled
              />
            </Col>

            <Col md={4}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="form-check-label form-label" htmlFor='tamanhoAproximado'>
                  Tamanho aproximado da lesão
                </label>
                <InputStyled
                  style={{ width: '100%' }}
                  id={`lesoes[${props.indice}].avaliacao.tamanhoAproximado`}
                  name={`lesoes[${props.indice}].avaliacao.tamanhoAproximado`}
                  value={values.lesoes[props.indice].avaliacao.tamanhoAproximado || ''}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div style={{ border: '1px solid #304439', borderRadius: '16px', padding: '18px', margin: '12px 0px' }}>
                <div style={{ fontSize: '18px', fontWeight: '700', lineHeight: '20px', letterSpacing: '0em', marginBottom: '6px' }}>
                  Exsudato
                </div>

                <Row>
                  <Col md={3}>
                    <Select
                      label='Aspecto'
                      emptyLabel='Selecione...'
                      id={`lesoes[${props.indice}].avaliacao.aspecto`}
                      value={values.lesoes[props.indice].avaliacao.aspecto || ''}
                      options={[
                        { label: 'Ausente', value: AspectoLesaoEnum.AUSENTE },
                        { label: 'Purolento', value: AspectoLesaoEnum.PUROLENTO },
                        { label: 'Seroso', value: AspectoLesaoEnum.SAEROSO },
                        { label: 'Serosanguinolento', value: AspectoLesaoEnum.SEROSANGUINOLENTO },
                        { label: 'Sanguinolento', value: AspectoLesaoEnum.SANGUINOLENTO },
                        { label: 'Outro', value: AspectoLesaoEnum.OUTRO },
                      ]}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col md={3}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label className="form-check-label form-label" htmlFor='aspectoOutro'>
                        Aspecto outro
                      </label>
                      <InputStyled
                        style={{ width: '100%' }}
                        id={`lesoes[${props.indice}].avaliacao.aspectoOutro`}
                        name={`lesoes[${props.indice}].avaliacao.aspectoOutro`}
                        value={values.lesoes[props.indice].avaliacao.aspectoOutro || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <Select
                      label='Quantidade'
                      emptyLabel='Selecione...'
                      id={`lesoes[${props.indice}].avaliacao.quantidade`}
                      value={values.lesoes[props.indice].avaliacao.quantidade || ''}
                      options={[
                        { label: 'Ausente', value: QuantidadeLesaoEnum.AUSENTE },
                        { label: 'Pequena', value: QuantidadeLesaoEnum.PEQUENA },
                        { label: 'Média', value: QuantidadeLesaoEnum.MEDIA },
                        { label: 'Alta', value: QuantidadeLesaoEnum.ALTA },
                      ]}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col md={3}>
                    <Select
                      label='Odor'
                      emptyLabel='Selecione...'
                      id={`lesoes[${props.indice}].avaliacao.odor`}
                      value={values.lesoes[props.indice].avaliacao.odor || ''}
                      options={[
                        { label: 'Ausente', value: OdorLesaoEnum.AUSENTE },
                        { label: 'Presente', value: OdorLesaoEnum.PRESENTE }
                      ]}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md={3}>
                  <Select
                    label='Aspecto da borda'
                    emptyLabel='Selecione...'
                    id={`lesoes[${props.indice}].avaliacao.aspectoBorda`}
                    value={values.lesoes[props.indice].avaliacao.aspectoBorda || ''}
                    options={[
                      { label: 'Epitelizada', value: AspectoBordaLesaoEnum.EPITELIZADA },
                      { label: 'Macerada', value: AspectoBordaLesaoEnum.MACERADA },
                      { label: 'Hiperqueratose', value: AspectoBordaLesaoEnum.HIPERQUERATOSE },
                      { label: 'Hiperemia', value: AspectoBordaLesaoEnum.HIPEREMIA }
                    ]}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={3}>
                  <Select
                    label='Aspecto do leito'
                    emptyLabel='Selecione...'
                    id={`lesoes[${props.indice}].avaliacao.aspectoLeito`}
                    value={values.lesoes[props.indice].avaliacao.aspectoLeito || ''}
                    options={[
                      { label: 'Granulação', value: AspectoLeitoLesaoEnum.GRANULACAO },
                      { label: 'Necrose de coagulação', value: AspectoLeitoLesaoEnum.NECROSE_COAGULACAO },
                      { label: 'Necrose de liquefação', value: AspectoLeitoLesaoEnum.NECROSE_LIQUEFACAO },
                      { label: 'Fibrina', value: AspectoLeitoLesaoEnum.FIBRINA },
                      { label: 'Biofilme', value: AspectoLeitoLesaoEnum.BIOFILME }
                    ]}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={3}>
                  <Select
                    label='Sinais flogísticos'
                    emptyLabel='Selecione...'
                    id={`lesoes[${props.indice}].avaliacao.sinaisFlogisticos`}
                    value={values.lesoes[props.indice].avaliacao.sinaisFlogisticos || ''}
                    options={[
                      { label: 'Ausente', value: SinaisFlogisticosLeitoLesaoEnum.AUSENTE },
                      { label: 'Presente', value: SinaisFlogisticosLeitoLesaoEnum.PRESENTE }
                    ]}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={6}>
                  <Select
                    label='Higiene da lesão'
                    emptyLabel='Selecione...'
                    id={`lesoes[${props.indice}].avaliacao.higieneLesao`}
                    name={values.lesoes[props.indice].avaliacao.higieneLesao || ''}
                    value={values.lesoes[props.indice].avaliacao.higieneLesao || ''}
                    options={[
                      { label: 'PHMB', value: HigieneLesaoLeitoLesaoEnum.PHMB },
                      { label: 'SF 0,9%', value: HigieneLesaoLeitoLesaoEnum.SF_09 },
                      { label: 'Clorexidina', value: HigieneLesaoLeitoLesaoEnum.CLOREXIDINA },
                      { label: 'Outro', value: HigieneLesaoLeitoLesaoEnum.OUTRO }
                    ]}
                    onChange={handleChange}
                  />
                </Col>

                <Col md={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="form-check-label form-label" htmlFor='higieneLesaoOutro'>
                      Higiene da lesão: Outro
                    </label>
                    <InputStyled
                      style={{ width: '100%' }}
                      id={`lesoes[${props.indice}].avaliacao.higieneLesaoOutro`}
                      name={`lesoes[${props.indice}].avaliacao.higieneLesaoOutro`}
                      value={values.lesoes[props.indice].avaliacao.higieneLesaoOutro || ''}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="form-check-label form-label" htmlFor='coberturaPrimaria'>
                      Cobertura primária
                    </label>
                    <InputStyled
                      style={{ width: '100%' }}
                      id={`lesoes[${props.indice}].avaliacao.coberturaPrimaria`}
                      name={`lesoes[${props.indice}].avaliacao.coberturaPrimaria`}
                      value={values.lesoes[props.indice].avaliacao.coberturaPrimaria || ''}
                      onChange={handleChange}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="form-check-label form-label" htmlFor='coberturaSecundaria'>
                      Cobertura secundária
                    </label>
                    <InputStyled
                      style={{ width: '100%' }}
                      id={`lesoes[${props.indice}].avaliacao.coberturaSecundaria`}
                      name={`lesoes[${props.indice}].avaliacao.coberturaSecundaria`}
                      value={values.lesoes[props.indice].avaliacao.coberturaSecundaria || ''}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <FotosAvaliacao indiceLesao={props.indice} />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => fecharModal()}
          >
            Cancelar
          </Button>

          <Button
            style={{ marginLeft: '8px' }}
            onClick={() => setModalAberto(false)}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { AvaliacaoLesao }
export default AvaliacaoLesao
