import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FaAsterisk } from 'react-icons/fa'

import { InputStyled } from '../../../components/styles'
import { DateTime, Select } from '../../../components'
import { IPacienteForm } from './PacientesModalForm'

interface IPacientesFormCamposProps {
  pacienteSelecionado: boolean
}

const PacientesFormCampos = (props: IPacientesFormCamposProps) => {
  const { values, handleBlur, handleChange, errors, touched, resetForm } = useFormikContext<IPacienteForm>()

  useEffect(() => {
    resetForm()
  }, [props.pacienteSelecionado])

  return (
    <div>
      <Row>
        <Col md={8}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='nome'>
              Nome {!props.pacienteSelecionado && <sup><FaAsterisk color='#FF264D' size={8} /></sup>}
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='nome'
              name='nome'
              value={values.nome || ''}
              disabled={props.pacienteSelecionado}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.nome && touched.nome && (
              <div style={{color: '#FF264D'}}>{errors.nome}</div>
            )}
          </div>
        </Col>

        <Col md={4}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='numeroSUS'>
              CPF {!props.pacienteSelecionado && <sup><FaAsterisk color='#FF264D' size={8} /></sup>}
            </label>

            <InputStyled
              type='text'
              className='form-input form-control w-100'
              id='numeroSUS'
              name='numeroSUS'
              value={values.numeroSUS || ''}
              disabled={props.pacienteSelecionado}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.numeroSUS && touched.numeroSUS && (
              <div style={{color: '#FF264D'}}>{errors.numeroSUS}</div>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='email'>
              Email
            </label>

            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='email'
              name='email'
              value={values.email || ''}
              disabled={props.pacienteSelecionado}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col md={4}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='telefone'>
              Telefone
            </label>

            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='telefone'
              name='telefone'
              value={values.telefone || ''}
              disabled={props.pacienteSelecionado}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div style={{marginBottom: '16px'}}>
            <label className="form-check-label form-label" htmlFor='contato'>
              Pessoa p/ contato
            </label>

            <InputStyled
              type='text'
              min={0}
              className='form-input form-control w-100'
              id='contato'
              name='contato'
              value={values.contato || ''}
              disabled={props.pacienteSelecionado}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Row>  

      <Row>
        <Col md={4}>
          <DateTime
            id='dataNascimento'
            label='Data de nascimento'
            maxDate={new Date()}
            selected={values.dataNascimento}
            disabled={props.pacienteSelecionado}
          />
        </Col>

        <Col md={4}>
          <Select
            label='Sexo'
            emptyLabel='Selecione...'
            id='sexo'
            value={values.sexo || ''}
            disabled={props.pacienteSelecionado}
            options={[
              { label: 'Masculino', value: 'masculino' },
              { label: 'Feminino', value: 'feminino' },
            ]}
            onChange={handleChange}
          />
        </Col>

        <Col md={4}>
          <Select
            label='Estado civil'
            emptyLabel='Selecione...'
            id='estadoCivil'
            value={values.estadoCivil || ''}
            disabled={props.pacienteSelecionado}
            options={[
              { label: 'Solteiro', value: 'solteiro' },
              { label: 'Casado', value: 'casado' },
              { label: 'Separado', value: 'separado' },
              { label: 'Divorciado', value: 'divorciado' },
              { label: 'Viúvo', value: 'viuvo' },
            ]}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  )
}

export { PacientesFormCampos }
export default PacientesFormCampos
