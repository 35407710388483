import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import Button from '../../../../components/Button'
import { PacienteAltaRelatorioCampos } from './PacienteAltaRelatorioCampos'

interface IPacienteAltaRelatorioProps {
  idPaciente: number,
  textoOBS: string | null
}

const PacienteAltaRelatorio = (props: IPacienteAltaRelatorioProps) => {
  const [modalRepostOpen, setModalRepostOpen] = useState<boolean>(false)

  return (
    <>
      <Button style={{ background: '#03549E', opacity: '0.7', marginLeft: '8px' }}
        variant='secondary'
        onClick={() => setModalRepostOpen(true)}>
            Imprimir
      </Button>

      <Modal
        size='lg'
        show={modalRepostOpen}
        onHide={() => setModalRepostOpen(false)}
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#304439' }}>
            Relatório de alta
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ height: '450px'}}>
          <PacienteAltaRelatorioCampos
            idPaciente={props.idPaciente}
            textoOBS={props.textoOBS}  
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export { PacienteAltaRelatorio }
export default PacienteAltaRelatorio
