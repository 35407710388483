import { AxiosResponse } from 'axios'
import { ISelectChoice } from '../../components/select-async'
import { getUserLocalStorage } from '../../context/auth_provider/util'
import { axiosAPI } from '../axiosAPI'
import { GrupoProntuarioPlanoCuidadoEnum } from '../prontuario/types'
import { IResultadoListagem, IResultadoListagemBackend, parserResultadoBackToFront } from './resultadosAPI'

export interface IIndicadorListagem {
  id: number,
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  valorEsperado: number,
  resultado: IResultadoListagem | null,
}

export interface IIndicadorListagemBackend {
  id: number,
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  valor_esperado: number,
  resultado: IResultadoListagemBackend | null,
}

export const indicadorListarAPI = async (): Promise<IIndicadorListagem[]> => {
  const user = getUserLocalStorage()
  const response: AxiosResponse<IIndicadorListagemBackend[]> = await axiosAPI.get(
      'indicador',
      { params: { id_empresa: user.idEmpresa } },
  )

  return response.data.map(i => ({
    ...i,
    valorEsperado: i.valor_esperado,
    resultado: i.resultado ? parserResultadoBackToFront(i.resultado) : null
  }))
}

export const getAllToSelectIndicadorAPI = async (grupo: GrupoProntuarioPlanoCuidadoEnum | -1, resultado?: number): Promise<ISelectChoice[]> => {
  const user = getUserLocalStorage()

  const response: AxiosResponse<IIndicadorListagemBackend[]> = await axiosAPI.get(
      'indicador',
      { params: {
        id_empresa: user.idEmpresa,
        grupo: grupo !== -1 ? grupo : undefined,
        id_resultado: resultado && resultado > 0 ? resultado : undefined
      } }
  )

  if (response.data) {
    return response.data.map((item) => {
      return {
        label: item.nome,
        value: item.id,
        valorEsperado: item.valor_esperado
      }
    })
  }

  return []
}


export const indicadorCreateAPI = async (
  nome: string,
  ativo: boolean,
  grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
  valorEsperado?: number,
  resultado?: number,
): Promise<IIndicadorListagem[]> => {
  const user = getUserLocalStorage()
  const response = await axiosAPI.post(
      'indicador',
      {
        nome,
        ativo,
        id_empresa: user.idEmpresa,
        grupo: grupo !== -1 ? grupo : null,
        valor_esperado: valorEsperado || null,
        id_resultado: resultado && resultado > 0 ? resultado : null,
      },
  )

  return response.data
}

export const indicadorUpdateAPI = async (
    id: number,
    nome: string,
    ativo: boolean,
    grupo: GrupoProntuarioPlanoCuidadoEnum | -1,
    valorEsperado?: number,
    resultado?: number,
): Promise<IIndicadorListagem[]> => {
  const response = await axiosAPI.put(
      'indicador/edit',
      {
        id,
        nome,
        ativo,
        grupo: grupo !== -1 ? grupo : null,
        valor_esperado: valorEsperado || null,
        id_resultado: resultado && resultado > 0 ? resultado : null,
      },
  )

  return response.data
}

export const indicadorDeleteAPI = async (id: number): Promise<IIndicadorListagem[]> => {
  const response = await axiosAPI.delete('indicador/delete', { params: { id } })

  return response.data
}
